import SvgIcon from '@mui/material/SvgIcon';

const KiwiModeration = ( props ) => {
	return (
		<SvgIcon width='20' height='18' viewBox=' 0 0 20 18' fill='none' {...props}>
			<path fillRule='evenodd' clipRule='evenodd'
						d='M11.8154 1.05611C11.0075 -0.35204 8.99254 -0.352037 8.18457 1.05612L0.285437 14.8229C-0.524214 16.234 0.484921 18 2.10087 18H17.8991C19.5151 18 20.5242 16.234 19.7146 14.8229L11.8154 1.05611ZM10 6.35861C9.42073 6.35861 8.95113 6.83243 8.95113 7.41692V10.5924C8.95113 11.1768 9.42073 11.6507 10 11.6507C10.5793 11.6507 11.0489 11.1768 11.0489 10.5924V7.41692C11.0489 6.83243 10.5793 6.35861 10 6.35861ZM10 14.8251C9.42073 14.8251 8.95113 14.3513 8.95113 13.7668C8.95113 13.1823 9.42073 12.7085 10 12.7085C10.5793 12.7085 11.0489 13.1823 11.0489 13.7668C11.0489 14.3513 10.5793 14.8251 10 14.8251Z'
						fill='#DF093C' />
		</SvgIcon>
	);
};

export default KiwiModeration;
