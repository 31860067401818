import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreateSurveyPage.module.scss';

import Modal, { ModalTitle } from 'shared/modal';
import Button from 'shared/button';
import { SelectInput } from 'shared/inputs';
import Form from 'shared/form';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { SurveyQuestionType } from 'kiwi-sdk';
import { initQuestionValue, QUESTION_OPTIONS } from './../constant';

const SubmitButton = ({ onSubmit }) => {
  const { handleSubmit } = useFormContext();
  return (
    <Button
      className={styles.addQuestion__btn}
      display="primary"
      onClick={handleSubmit(onSubmit)}
    >
      Add question
    </Button>
  );
};

const AddQuestion = ({ open, onClose }) => {
  const { append } = useFieldArray({
    name: 'questions',
  });

  const handleAddQuestion = ({ type }) => {
    append(initQuestionValue[type]);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle text="New question type" onClose={onClose} />
      <Form
        className={styles.addQuestion__form}
        initialValues={{ type: SurveyQuestionType.RADIO }}
      >
        <SelectInput
          label="Question type"
          renderValue={(value) => QUESTION_OPTIONS[value]}
          options={SurveyQuestionType}
          name="type"
          variant="outlined"
          outlined
          fullWidth
        />
        <SubmitButton onSubmit={handleAddQuestion} />
      </Form>
    </Modal>
  );
};

AddQuestion.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddQuestion;
