import { useMemo } from 'react';

import SearchRequest from './print-request-search';
import QuickFilters from './print-request-quick-filters';
import PrintRequestsTable from './print-requests-table';
import Suspense from 'shared/suspense-list';
import IndeterminateCheckbox from './print-requests-table/request-checkbox/PrintRequestsCheckbox';
import PrintSingleRequestModal from './print-single-request-modal';
import ErrorBoundary from 'shared/error-boundary';

import { useGlobalFilter, usePagination, useSortBy, useTable, useRowSelect } from 'react-table';

import { PrintRequestSectionLayout } from './layout';
import { TableLoader } from 'shared/loaders';
import { getDataByDateRange } from './utils';
import { usePrintRequestsSectionContext } from './hooks';
import { PRINT_REQUESTS_TABLE_COLUMNS } from './print-requests-table/table-columns';

function PrintRequestSection() {
	const { printRequests, isLoading, dateRangeFilter } = usePrintRequestsSectionContext();
	const columns = useMemo(() => PRINT_REQUESTS_TABLE_COLUMNS, []);
	const data = useMemo(() => getDataByDateRange(printRequests?.items, dateRangeFilter?.from, dateRangeFilter?.to), [printRequests, dateRangeFilter]);

	const tableInstance = useTable({
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect, ( hooks ) => {
			hooks.visibleColumns.push(( columns ) => {
				return [
					{
						id: 'selection',
						Header: ( { getToggleAllPageRowsSelectedProps } ) => (
							<div>
								<IndeterminateCheckbox  {...getToggleAllPageRowsSelectedProps()} />
							</div>
						),
						Cell: ( { row } ) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				];
			});
		},
	);

	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<PrintRequestSectionLayout
				SearchComponent={<SearchRequest filter={tableInstance?.state?.globalFilter} setFilter={tableInstance?.setGlobalFilter} />}
				QuickFiltersComponent={<QuickFilters />}
				MainContentComponent={
					<Suspense
						loading={isLoading}
						total={data.length && tableInstance?.pageCount}
						fallback={<TableLoader />}
						noResultText='No print requests found'>
						<PrintRequestsTable requests={printRequests} {...tableInstance} />
					</Suspense>
				}
			/>
			<PrintSingleRequestModal
				selectedFlatRows={tableInstance?.selectedFlatRows}
				toggleAllSelectedRows={tableInstance?.toggleAllPageRowsSelected} />
		</ErrorBoundary>
	);
};

export default PrintRequestSection;
