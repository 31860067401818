import SvgIcon from '@mui/material/SvgIcon';

const Dash = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 3.00024C2.25 2.58603 2.58579 2.25024 3 2.25024H10C10.4142 2.25024 10.75 2.58603 10.75 3.00024V10.0002C10.75 10.4145 10.4142 10.7502 10 10.7502H3C2.58579 10.7502 2.25 10.4145 2.25 10.0002V3.00024ZM3.75 3.75024V9.25024H9.25V3.75024H3.75Z"
        fill="#686873"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3.00024C13.25 2.58603 13.5858 2.25024 14 2.25024H21C21.4142 2.25024 21.75 2.58603 21.75 3.00024V10.0002C21.75 10.4145 21.4142 10.7502 21 10.7502H14C13.5858 10.7502 13.25 10.4145 13.25 10.0002V3.00024ZM14.75 3.75024V9.25024H20.25V3.75024H14.75Z"
        fill="#686873"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 14.0002C13.25 13.586 13.5858 13.2502 14 13.2502H21C21.4142 13.2502 21.75 13.586 21.75 14.0002V21.0002C21.75 21.4145 21.4142 21.7502 21 21.7502H14C13.5858 21.7502 13.25 21.4145 13.25 21.0002V14.0002ZM14.75 14.7502V20.2502H20.25V14.7502H14.75Z"
        fill="#686873"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 14.0002C2.25 13.586 2.58579 13.2502 3 13.2502H10C10.4142 13.2502 10.75 13.586 10.75 14.0002V21.0002C10.75 21.4145 10.4142 21.7502 10 21.7502H3C2.58579 21.7502 2.25 21.4145 2.25 21.0002V14.0002ZM3.75 14.7502V20.2502H9.25V14.7502H3.75Z"
        fill="#686873"
      />
    </SvgIcon>
  );
};

export default Dash;
