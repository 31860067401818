import React from 'react';

import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import ChatIcon from '@mui/icons-material/Chat';

const Thumbnail = (props) => {
  return (
    <>
      {props.type === 'voice' ? (
        <LibraryMusicIcon
          sx={{
            fontSize: '90px',
            m: 1,
            color: '#ffff',
            bgcolor: '#9191a1',
          }}
          color="primary"
        />
      ) : props.type === 'text' ? (
        <ChatIcon
          sx={{
            fontSize: '90px',
            m: 1,
            color: '#ffff',
            bgcolor: '#9191a1',
          }}
          color="primary"
        />
      ) : (
        <img src={props.src} />
      )}
    </>
  );
};

export default Thumbnail;
