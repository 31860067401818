import React, { Fragment } from 'react';

import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';

import Logo from 'assets/Logo';
import If from 'components/if/If';
import NewPasswordInput from './new-password-input';
import AuthInput from 'pages/auth-pages/components/auth-input';
import AuthLoader from 'pages/auth-pages/components/auth-loader';
import PasswordReset from 'pages/auth-pages/components/password-reset';
import SquareBackground from 'pages/auth-pages/components/square-background';
import BackgroundSmudge from 'pages/auth-pages/components/background-smudge';

import { useAuthContext } from 'modules/auth/context';

import {
  FORM_INITIAL_VALUES,
  ResetPasswordFormTypes,
} from './form-initial-values';
import { routes } from 'routing';
import { RESET_PASSWORD_FORM_VALIDATION } from './form-validation';

export default function ResetPasswordPage() {
  const {
    resetPasswordByRequestIdQuery,
    onSetNewPassword,
    cleanUserInfo,
    onLogin,
  }: any = useAuthContext();
  const history = useHistory();
  const showUpdatePswForm =
    !resetPasswordByRequestIdQuery?.isFetching || onLogin.isLoading;

  useEffectOnce(() => {
    if (resetPasswordByRequestIdQuery?.isError) {
      history.replace(routes.login);
      cleanUserInfo();
      console.log(
        'Failure Reason',
        resetPasswordByRequestIdQuery?.failureReason
      );
      toast.error('Something went wrong');
    }
  });

  function onHandleFormSubmit(form: ResetPasswordFormTypes) {
    onSetNewPassword.mutate(
      { form },
      {
        onSuccess: () => {
          history.replace(routes.login);
          toast.success('Your password has been updated correctly');
        },
      },
      {
        onError: (error: any) => {
          console.log('Error from onRequestPasswordReset', error.message);
        },
      }
    );
  }

  return (
    <PasswordReset>
      <SquareBackground />
      <BackgroundSmudge />
      <If condition={showUpdatePswForm} otherwise={<AuthLoader />}>
        <Fragment>
          <PasswordReset.Title>
            <h2 className="text-lg md:text-2xl text-grey-2 mb-4">
              Update your password
            </h2>
            <Logo className="mx-auto h-[30px] w-auto md:h-[40px] md:w-[272px]" />
          </PasswordReset.Title>
          <PasswordReset.FormContainer>
            <PasswordReset.Form
              onSubmit={onHandleFormSubmit}
              initialValues={FORM_INITIAL_VALUES}
              validation={RESET_PASSWORD_FORM_VALIDATION}
            >
              <PasswordReset.NewPasswordInput>
                <NewPasswordInput />
              </PasswordReset.NewPasswordInput>
              <PasswordReset.ConfirmPasswordInput>
                <AuthInput
                  label="Confirm password"
                  name="confirmPassword"
                  placeholder=""
                  autoComplete="confirm-new-password"
                  type="password"
                />
              </PasswordReset.ConfirmPasswordInput>
              <PasswordReset.SubmitButton>
                Update Password
              </PasswordReset.SubmitButton>
            </PasswordReset.Form>
          </PasswordReset.FormContainer>
        </Fragment>
      </If>
    </PasswordReset>
  );
}
