import React from 'react';

import { Switch, Route } from 'react-router-dom';

// import { FormsProvider } from 'modules/forms/context';
import { BulletinsProvider } from 'modules/bulletins/context';
import { AdminSettingsProvider } from 'modules/admin-settings/context';

import UserDetails from 'modules/users-chats/user-details';
// import FormsList from 'modules/forms/forms-list';
// import FormDetails from 'modules/forms/form-details';
import BulletinsList from 'modules/bulletins/bulletins-list';
import SettingsPage from 'modules/admin-settings/settings-page';
import SupportPage from 'modules/support';
import SurveysList from 'modules/surveys/survey-list';
import SurveyCreatePage from 'modules/surveys/create-survey-page';
import SurveyResults from 'modules/surveys/survey-result/SurveyResults';
import DocumentsList from 'modules/documents/documents-list';
import StaffDetails from 'modules/admin-settings/staff-details';
import SearchResults from 'modules/global-search';
import ChatMetrics from 'modules/chats-metrics';
// import Dashboard from 'modules/users-chats/dashboard';
import PrintRequestsSection from 'modules/print-request-section';
// import TransferRequestList from 'modules/transfer-requests';
import ErrorBoundary from 'shared/error-boundary';

import routes from './routes';

import { SurveysProvider } from 'modules/surveys/context';
import { useAuthContext } from 'modules/auth/context';
import { PrintRequestsProvider } from 'modules/print-request-section/context';
// import { TransferRequestsProvider } from 'modules/transfer-requests/context/transferRequestsContext';
import { UserProfileProvider } from 'modules/users-chats/user-details/context/UserProfileContext';
import { ChatMetricsProvider } from '../modules/chats-metrics/context/ChatMetricsContext';

const HomeRouteRender = () => {
	return (
		<Switch>
			<UserProfileProvider>
				<Route path={routes.user} component={UserDetails} exact />
				<Route path={routes.chat} component={UserDetails} exact />
			</UserProfileProvider>
		</Switch>
	);
};

const SearchRouteRender = () => {
	return (
		<Switch>
			<Route path={routes.search} component={SearchResults} exact />
		</Switch>
	);
};

const ChatMetricsRender = () => {
	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<ChatMetricsProvider>
				<Switch>
					<Route path={routes.chatsMetrics} component={ChatMetrics} exact />
				</Switch>
			</ChatMetricsProvider>
		</ErrorBoundary>
	);
};
/* RouterRenders disabled for now
const FormsRouteRender = () => {
	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<FormsProvider>
				<Switch>
					<Route path={routes.forms} component={FormsList} exact />
					<Route path={routes.singleForms} component={FormDetails} exact />
				</Switch>
			</FormsProvider>
		</ErrorBoundary>
	);
};

*/

const BulletinsRouteRender = () => {
	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<BulletinsProvider>
				<BulletinsList />
			</BulletinsProvider>
		</ErrorBoundary>
	);
};

const PrintRequestsRender = () => {
	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<PrintRequestsProvider>
				<Route path={routes.printRequests} component={PrintRequestsSection} exact />
			</PrintRequestsProvider>
		</ErrorBoundary>
	);
};

const SurveysRouteRender = () => {
	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<SurveysProvider>
				<Switch>
					<Route path={routes.surveys} component={SurveysList} exact />
					<Route path={routes.surveysCreate} component={SurveyCreatePage} exact />
					<Route path={routes.surveyResults} component={SurveyResults} exact />
				</Switch>
			</SurveysProvider>
		</ErrorBoundary>
	);
};

const AdminSettingsRouteRender = () => {
	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<AdminSettingsProvider>
				<Switch>
					<Route path={routes.settings} component={SettingsPage} exact />
					<Route path={routes.singleStaff} component={StaffDetails} excat />
				</Switch>
			</AdminSettingsProvider>
		</ErrorBoundary>
	);
};

const routePackages = [
	{
		path: [routes.search],
		render: () => <SearchRouteRender />,
		exact: true,
		requiredPermission: 'SEARCH',
	},

	{
		path: [routes.chatsMetrics],
		render: () => <ChatMetricsRender />,
		exact: true,
		requiredPermission: '',
	},
	{
		path: routes.bulletins,
		render: () => <BulletinsRouteRender />,
		exact: true,
		requiredPermission: 'BULLETIN',
	},
	{
		path: [routes.surveys, routes.surveyResults, routes.surveysCreate],
		render: () => <SurveysRouteRender />,
		exact: true,
		requiredPermission: 'SURVEY',
	},
	{
		path: [routes.printRequests, routes.batchDetails],
		render: () => <PrintRequestsRender />,
		exact: true,
		requiredPermission: '',
	},

	{
		path: routes.documents,
		component: DocumentsList,
		exact: true,
		requiredPermission: 'DOCUMENT',
	},
	{
		path: [routes.settings, routes.singleStaff],
		render: () => <AdminSettingsRouteRender />,
		exact: true,
		requiredPermission: 'SETTING',
	},
];

const homeRoutePackage = {
	path: [routes.user, routes.chat],
	render: () => <HomeRouteRender />,
	exact: true,
};

const supportRoutePackage = {
	path: routes.support,
	component: SupportPage,
	exact: true,
	requiredPermission: '',
};

function handlePermissionCheck( requiredPermission, loosePermissionCheck ) {
	if (Array.isArray(requiredPermission)) {
		return requiredPermission.some(( permission ) =>
			loosePermissionCheck(permission),
		);
	}
	return loosePermissionCheck(requiredPermission);
}

const routerConfig = () => {
	const { loosePermissionCheck } = useAuthContext();
	const routesArray = routePackages.filter(( route ) =>
		handlePermissionCheck(route.requiredPermission, loosePermissionCheck),
	);
	routesArray.push(homeRoutePackage);
	routesArray.push(supportRoutePackage);
	routesArray.push(routePackages[6]);
	return routesArray;
};

export { routes };
export default routerConfig;

/* routes packages disabled for now
	{
		path: [routes.forms, routes.singleForms],
		render: () => <FormsRouteRender />,
		exact: true,
		requiredPermission: 'FORM',
	},
	{
		path: routes.kiwiCop,
		component: () => <div>KiwiCop</div>,
		exact: true,
		requiredPermission: '',
	},

	{
		path: routes.kiosks,
		component: () => <div>Kiosks</div>,
		exact: true,
		requiredPermission: '',
	},
	{
		path: routes.reports,
		component: () => <div>Reports</div>,
		exact: true,
		requiredPermission: 'REPORT',
	},
*/
