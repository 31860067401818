import { Fragment } from 'react';

import SaveElementModal from 'shared/save-element-modal';
import getInitialName from 'shared/save-element-modal/getInitialName';

import { toast } from 'react-toastify';

import { If } from 'shared/utilities';
import { useAuthContext } from 'modules/auth/context';
import { useSaveChat, useToggler } from 'hooks';
import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';

export default function SaveChatModal( props ) {
	const { ElementModalControl, channelInfoData } = props;
	const saveChat = useSaveChat();
	const { user } = useAuthContext();
	const [openSaveChatModal, toggleOpenSaveChatModal] = useToggler(false);

	const formInitialValues = { info: getInitialName('channel', channelInfoData?.members || []) };

	let payload = {
		docUserInfo: {
			data: { ...user },
		},
		ref: {
			id: channelInfoData?.id,
			info: '',
			notes: '',
			owner_id: channelInfoData?.initiator,
		},
	};

	return (
		<Fragment>
			<ElementModalControl onClick={toggleOpenSaveChatModal} />
			<If condition={openSaveChatModal}>
				<SaveElementModal
					open={openSaveChatModal}
					onClose={toggleOpenSaveChatModal}
					title='Save Chat'
					formInitValues={formInitialValues}
					onFormSubmit={onSubmitHandler(payload, saveChat, toggleOpenSaveChatModal)}
				/>
			</If>
		</Fragment>
	);
}

const onSubmitHandler = ( payload, mutation, closeModal ) => ( form ) => {
	return mutation.mutate({
			...payload, ref: {
				...payload.ref,
				info: form.info,
			},
		},
		{
			onSuccess: () => {
				closeModal();
				toast.success('Chat saved successfully');
				queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_BOOKMARKS] });
			},
		},
	);
};
