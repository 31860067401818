import { useMutation } from '@tanstack/react-query';

import { useGraphql } from '../context';

export default function useSaveChat() {
	const api = useGraphql();

	return useMutation({
		mutationFn: ( payload ) => api.controller.doc.bookmarkChannel(payload),
	});
}
