import { Box, Button, Typography } from '@mui/material';

export default function ActionButton( { color, RenderIcon, label, action, disabled } ) {
	const { sxBoxWrapper, sxButton, sxIcon, sxTypography } = useActionButtonsStyles(color);
	return (
		<Box sx={sxBoxWrapper}>
			<Button variant='text' onClick={action} sx={sxButton} disabled={disabled}>
				<RenderIcon sx={sxIcon} />
				<Typography sx={sxTypography}>
					{label}
				</Typography>
			</Button>
		</Box>
	);
}

function useActionButtonsStyles( color ) {
	return {
		sxBoxWrapper: {
			alignItems: 'center',
			cursor: 'pointer',
			display: 'flex',
			justifyContent: 'flex-end',
			marginLeft: 2,
		},
		sxButton: {
			minWidth: 170,
			height: 42,
			borderColor: 'none',
			borderRadius: 4,
			'&.MuiButton-text': {
				textTransform: 'capitalize',
			},
		},
		sxIcon: {
			width: 16,
			height: 16,
			color: color,
		},
		sxTypography: {
			display: 'inline-block',
			color: color,
			marginLeft: 1.5,
		},
	};
}