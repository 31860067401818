import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styles from './BookmarkModal.module.scss';

import Grid from '@mui/material/Grid';
import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';
import Input from './Input';
import * as yup from 'yup';
import getInitialName from './getInitialName';
import renderTitle from './renderTitle';
import useSaveMessage from 'hooks/useSaveMessage';

import { toast } from 'react-toastify';
import { useToggler } from 'hooks';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from '../../auth/context';
import { queryClient } from '../../../utils/queryClient';
import { QUERY_KEYS } from '../../../utils';

const BookmarkModal = ( { type, item, Action, setShowHoover } ) => {
	const { user } = useAuthContext();
	const [open, toggleModal] = useToggler();
	const title = renderTitle(type);
	const saveMessage = useSaveMessage();

	let payload = {
			docUserInfo: {
				data: { ...user },
			},
			ref: {
				ownerId: item.ownerId,
				messageId: item.clientKey,
				channelId: item.chatId,
				info: item.text,
				notes: '',
			},
		}
	;

	const initialValues = useMemo(
		() => ({
			name: getInitialName(type, item),
		}),
		[type, item],
	);

	function onSubmitHandler( form ) {
		return saveMessage.mutate(
			{
				...payload,
				ref: {
					...payload.ref,
					info: form.name,
				},
			},
			{
				onSuccess: () => {
					toggleModal();
					toast.success('The message has been saved successfully');
					queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_BOOKMARKS] });
				},
			},
		);
	}

	return (
		<>
			<Action
				onClick={( evt ) => {
					evt.stopPropagation();
					toggleModal(true);
				}}
				disabled={saveMessage.isLoading}
			/>
			<Modal className={styles.modal} open={open}>
				<ModalTitle text={title} onClose={() => {
					toggleModal();
					setShowHoover && setShowHoover();
				}} />
				<Form initialValues={{ name: getInitialName(type, item) }} validation={validation} onSubmit={onSubmitHandler}>
					<Input name='name' label='Name' />
					<Grid direction='row-reverse' justifyContent='space-between' wrap='nowrap' container>
						<SubmitButton loading={saveMessage.isLoading} />
						<FormLoader loading={saveMessage.isLoading} />
					</Grid>
				</Form>
			</Modal>
		</>
	);
};

export default BookmarkModal;

const validation = yup.object({
	name: yup.string().trim().required(),
});

const SubmitButton = ( { loading } ) => {
	const { formState } = useFormContext();

	return (
		<Button
			display='primary'
			type='submit'
			disabled={loading || !formState.isValid}
		>
			Save
		</Button>
	);
};

// TODO: remove useffect and use useMutation hook
// TODO: rewrite this component in a more compact and readable way