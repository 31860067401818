import React from 'react';
import styles from './NotificationContainer.module.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line no-unused-vars
const CloseButton = ({ closeToast, ...props }) => (
  <button className={styles.notification__closeButton} {...props}>
    <svg
      className={styles.notification__closeButton__icon}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7782 5.6356L18.364 4.22138L12 10.5853L5.63604 4.22138L4.22183 5.6356L10.5858 11.9996L4.22183 18.3635L5.63604 19.7777L12 13.4138L18.364 19.7777L19.7782 18.3635L13.4142 11.9996L19.7782 5.6356Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>
);

const NotificationContainer = () => (
  <ToastContainer
    toastClassName={styles.notification}
    progressClassName={styles.notification__progress}
    position="top-center"
    autoClose={3000}
    closeButton={CloseButton}
    newestOnTop
    draggable
    closeOnClick
    pauseOnHover
  />
);

export default NotificationContainer;
