import { useQuery } from '@tanstack/react-query';
import { useGraphql } from '../../../context';
import {  QUERY_KEYS } from '../../../utils';

export default function useSentimentsAnalysis( userId, rangeDateFilter ) {
	const api = useGraphql();
	const shouldExecuteQuery = Object.entries(rangeDateFilter).every(( [_, value] ) => value !== null);

	function getSentimentsAnalysis() {
		return api.controller.analytics.messageSentimentAnalysis({
			userId: userId,
			dateRange: rangeDateFilter,
		});
	}

	const sentimentsAnalysisQuery = useQuery({
		queryKey: [QUERY_KEYS.SENTIMENT_ANALYSIS, userId, rangeDateFilter],
		queryFn: getSentimentsAnalysis,
		enabled: shouldExecuteQuery,
		keepPreviousData: !shouldExecuteQuery,
	});

	return sentimentsAnalysisQuery;

}
