import React from 'react';
import styles from './CreateSurveyPage.module.scss';

import Grid from '@mui/material/Grid';
import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
// import { SelectInput } from 'shared/inputs';
import Button from 'shared/button';
import RecipientsInput from '../../bulletins/create-bulletin/RecipientsInput';
import { DaysInput } from 'shared/inputs';

// import { useAuthContext } from 'modules/auth/context';
import { useFormContext } from 'react-hook-form';

// import { RECIPIENTS_TYPE } from '../../bulletins/constants';

function SendSurveyModal({ showModal, toggleModal, onHandleModalSubmit }) {
  const { watch } = useFormContext();
  // const { agency } = useAuthContext();
  const recipientsType = watch('recipientsType');

  // const renderValue = (value) => RECIPIENTS_TYPE[value];

  const initialValues = {
    days: 30,
    title: '',
    file: null,
    text: '',
    recipientsType: 'FACILITIES',
    recipients: [],
  };

  const SendBtn = () => {
    return (
      <>
        <Button display="primary" type="submit">
          Send
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal open={showModal} onClose={toggleModal}>
        <ModalTitle text="Send Survey" onClose={toggleModal} />
        <Form initialValues={initialValues} onSubmit={onHandleModalSubmit}>
          <DaysInput
            name="days"
            postLabel="days"
            indefinitelyName="indefinitely"
            label="Valid until"
            min={1}
            max={999}
          />
          <div>
            {recipientsType !== 'ALL' && (
              <>
                <h4>Choose recipients</h4>
                <p>
                  Select the facilities you would like to send the survey to.
                </p>
              </>
            )}
            {/* <SelectInput
              className={styles.recipients__type}
              name="recipientsType"
              label="Send to:"
              options={RECIPIENTS_TYPE}
              renderValue={renderValue}
            /> */}
            <RecipientsInput name="recipients" />
            <Grid
              classes={{ root: styles.actions }}
              justifyContent="flex-end"
              wrap="nowrap"
              columnSpacing="16px"
              container
            >
              <SendBtn />
            </Grid>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default SendSurveyModal;
