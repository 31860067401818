import { Grid, Typography } from '@mui/material';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { useAuthContext } from 'modules/auth/context';

export default function ShowBookmarksOption() {
	const { toggleBookmarksDrawer } = useAuthContext();

	return (
		<>
			<Grid container direction='row' gap={2} onClick={toggleBookmarksDrawer}>
				<BookmarksOutlinedIcon />
				<Typography component='span'>Bookmarks</Typography>
			</Grid>
		</>
	);
}