import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';

import Button from 'shared/button';

import { useIdle } from 'hooks';
import { useAuthContext } from 'modules/auth/context';

export default function AutoDialog({ timeToShowDialog = 59000 }) {
  const { onLogOut } = useAuthContext();
  const { remainingTime, handleResetTimer } = useIdle(
    DEFAULT_IDLE_TIME,
    onLogOut.mutate
  );
  const { sxDialog } = useAutoDialogStyles();

  const minutes = Math.floor(remainingTime / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return (
    <>
      {remainingTime < timeToShowDialog && (
        <Dialog open={remainingTime < timeToShowDialog} sx={sxDialog}>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <Typography>
              Due to inactivity you will be logged out in {minutes} minutes and{' '}
              {seconds} seconds.
            </Typography>
            <Box sx={{ width: '100%' }}></Box>
          </DialogContent>
          <DialogActions>
            <Button
              display="primary"
              onClick={handleResetTimer}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function useAutoDialogStyles() {
  return {
    sxDialog: {
      '& .MuiDialog-paper': {
        borderRadius: '16px',
        padding: 2,
        overflow: 'hidden',
        width: '570px',
      },
    },
  };
}

const DEFAULT_IDLE_TIME = 900000;
