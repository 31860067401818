import { Fragment, useState } from 'react';

import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import { Accordion, AccordionDetails, AccordionSummary, List } from '@mui/material';

import Button from 'shared/button';
import FilterCheckboxItem from './FilterCheckboxItem';

import { useFormContext } from 'react-hook-form';

import { MESSAGE_TYPES_FILTERS } from './CHANNEL_FILTERS';

function FilterForm( props ) {
	const { toggleOpen } = props;

	const { watch } = useFormContext();

	const [expanded, setExpanded] = useState('panel1');

	const formIsDirty = Object.values(watch()).some(( value ) => value === true);

	const handleChange = ( panel ) => ( event, isExpanded ) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Fragment>
			<Accordion elevation={0} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<AccordionSummary
					expandIcon={<ArrowDropDownRoundedIcon sx={Sxs.CollapseArrowIcon} />}
					aria-controls='panel1bh-content'
					id='panel1bh-header'
					sx={{ '&.MuiAccordionSummary-root': { minHeight: 10 }, '& .MuiAccordionSummary-content': { margin: 0 } }}
				>
					<FilterGroupTitle variant='sectionsTitle'>
						Message Type
					</FilterGroupTitle>
				</AccordionSummary>
				<AccordionDetails>
					<List>
						{
							MESSAGE_TYPES_FILTERS.map(( { label, value } ) => (
								<FilterCheckboxItem key={label} label={label} value={value} />))
						}
					</List>
				</AccordionDetails>
			</Accordion>
			<FilterButtonsContainer>
				<Button display='secondary' onClick={toggleOpen}>
					Close
				</Button>
				<Button type='submit' display='primary' disabled={!formIsDirty}>
					Apply
				</Button>
			</FilterButtonsContainer>
		</Fragment>
	);
}

export default FilterForm;

const FilterGroupTitle = styled(Typography)({
	fontWeight: 500,
});

const FilterButtonsContainer = styled('div')({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	gap: '.2rem',
	margin: '1rem',
});

const Sxs = {
	CollapseArrowIcon: {
		color: 'customPalette.grey1',
		width: 24,
	},
	GroupDivider: {
		marginX: '1rem',
	},
};
