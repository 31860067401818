import styled from '@emotion/styled';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden auto;
  padding: 1rem;

  border-collapse: collapse;
  border-radius: 1rem;
`;

export const StyledTable = styled.table`
  flex: 1;
  table-layout: fixed
`;

export const StyledTableHeader = styled.thead`
  z-index: 99;
  height: 48px;
  position: sticky;
  top: 0;

  text-align: left;
  background: #fff;
`;

export const StyledHeaderTh = styled.th`
  flex: 1;
`;

export const StyledBodyTd = styled.td`
  flex: 1;
`;

export const StyledTableBody = styled.tbody`
  overflow: hidden;

  color: #686873;
`;

export const StyledTableRow = styled.tr`
  margin-bottom: 0.5rem;

  max-height: 48px;

  border-collapse: collapse;
  border-bottom: 1px solid #f2f4f4;
  cursor: pointer;

  &:hover {
    background-color: #f2f4f4;
  }
`;

export const HeaderCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
