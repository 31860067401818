import React, { ReactNode } from 'react';

import { twc } from 'react-twc';

import Form from 'shared/form';
import Login from './login';
import { useFormContext } from 'react-hook-form';
import { cn } from '../../../utils/functions';
import PasswordForgot from './password-forgot';

export default function PasswordReset({ children }: PasswordResetProps) {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {children}
    </div>
  );
}

PasswordReset.Title = twc.div`sm:mx-auto sm:w-full sm:max-w-sm text-center`;

PasswordReset.Form = twc(Form)`space-y-6`;

PasswordReset.FormContainer = twc.div`mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-[550px] bg-white px-6 py-6  shadow-lg ring-1 ring-grey-4 sm:rounded-lg md:px-12 md:py-12`;

PasswordReset.SubmitButton = (props: SubmitButtonProps) => {
  const { formState } = useFormContext();

  const isDisable = !formState.isValid;

  return (
    <button
      className={cn(
        'flex w-full h-10 md:h-12 px-5 py-2 md:py-3 justify-center rounded-lg bg-grey-1 text-white text-sm md:text-base font-medium leading-normal border border-grey-1 shadow-sm hover:bg-white hover:text-grey-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition duration-150 ease-in-out',
        {
          'bg-grey-3 border border-grey-4 hover:bg-grey-3 hover:text-white':
            isDisable,
        }
      )}
      type="submit"
      disabled={isDisable}
    >
      {props?.children}
    </button>
  );
};

PasswordReset.NewPasswordInput = twc.div``;

PasswordReset.ConfirmPasswordInput = twc.div``;

interface PasswordResetProps {
  children: ReactNode;
}

interface SubmitButtonProps {
  children: ReactNode;
}
