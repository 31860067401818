import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styles from './EditStaffFacilities.module.scss';

import MuiButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import CustomButton from 'shared/button';
import FormLoader from 'shared/form-loader';
import SelectAllButton from './SelectAllButton';
import FacilitiesFilter from './FacilitiesFilter';
import FacilityCheckbox from './FacilityCheckbox';

import { Subject, from, switchMap, tap } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { DOCUserFacilitiesUpdateCmd } from 'kiwi-sdk';
import { useToggler } from 'hooks';
import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';

import { useAdminSettingsContext } from '../context';

const EditStaffFacilities = ({
  facilities: userFacilities,
  selectedStaff,
  disableEdit,
}) => {
  const { facilities } = useAuthContext();
  const { setSelectedStaff } = useAdminSettingsContext();
  const [showModal, toggleModal] = useToggler();
  const [loading, toggleLoading] = useToggler();
  const [displayFacilities, setFacilities] = useState(facilities);
  const [filter$] = useState(new Subject());
  const [edit$] = useState(new Subject());
  const api = useGraphql();

  function editStaffFacilities({ staff, newFacilities }) {
    return from(
      api.send(
        DOCUserFacilitiesUpdateCmd({
          docUserInfo: { data: { ...staff } },
          newFacilities,
        })
      )
    );
  }

  useEffect(() => {
    const filterSub = filter$
      .pipe(
        debounceTime(500),
        map(({ value, facilities }) => {
          return facilities.filter(({ name }) =>
            name.toLowerCase().includes(value.toLowerCase())
          );
        })
      )
      .subscribe(setFacilities);

    const editSub = edit$
      .pipe(
        tap(toggleLoading),
        switchMap((payload) => {
          return editStaffFacilities(payload).pipe(
            map((res) => [res, payload])
          );
        }),
        map(([res, payload]) => {
          toggleLoading(false);
          if (res.status === 'ERROR') {
            toast.error(res.error);
          } else {
            setSelectedStaff({
              ...payload.staff,
              security: {
                ...payload.staff.security,
                facility: payload.newFacilities,
              },
            });
            toggleModal(false);
          }
        })
      )
      .subscribe();

    return () => {
      filterSub.unsubscribe();
      editSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setFacilities(facilities);
  }, [facilities]);

  useEffect(() => {
    if (!showModal) setFacilities(facilities);
  }, [showModal]);

  const initialValues = useMemo(
    () =>
      facilities.reduce((acc, curr) => {
        if (userFacilities.find(({ id }) => id === curr.id)) {
          acc[curr.id] = true;
        }
        return acc;
      }, {}),
    [facilities, userFacilities]
  );

  const handleSubmit = useCallback(
    (form) => {
      const newFacilities = [];
      Object.entries(form).forEach(([id, value]) => {
        if (!value) {
          return;
        }
        const facility = facilities.find((item) => item.id === id);
        if (facility) {
          newFacilities.push({
            id,
            info: facility.name,
          });
        }
      });
      edit$.next({ staff: selectedStaff, newFacilities });
    },
    [selectedStaff, facilities]
  );

  const handleFilter = useCallback(
    (value) => filter$.next({ value, facilities }),
    [facilities]
  );

  return (
    <>
      <MuiButton
        classes={{ root: styles.button }}
        onClick={toggleModal}
        disabled={disableEdit}
        style={{ fontSize: '14px' }}
      >
        Edit access
      </MuiButton>
      <Modal className={styles.modal} open={showModal} onClose={toggleModal}>
        <ModalTitle
          className={styles.modal__title}
          text="Facilities access"
          onClose={toggleModal}
        />
        <Form
          className={styles.form}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Grid classes={{ root: styles.modal__edit }}>
            <Grid
              classes={{ root: styles.modal__header }}
              justifyContent="space-between"
              alignItems="center"
              container
            >
              <FacilitiesFilter onChange={handleFilter} />
            </Grid>
            <Grid
              classes={{ root: styles.modal__header }}
              justifyContent="space-between"
              alignItems="center"
              container
            >
              <SelectAllButton />
            </Grid>

            <div className={styles.form__content__wrapper}>
              <div className={styles.form__content}>
                {displayFacilities.map(({ id, name }) => (
                  <FacilityCheckbox key={id} name={id} label={name} />
                ))}
              </div>
            </div>
          </Grid>

          <Grid
            classes={{ root: styles.form__actions }}
            direction="row-reverse"
            justifyContent="space-between"
            wrap="nowrap"
            container
          >
            <Grid justifyContent="flex-end" wrap="nowrap" container item>
              <CustomButton
                className={styles.cancelButton}
                onClick={toggleModal}
                display="secondary"
              >
                Cancel
              </CustomButton>
              <CustomButton type="submit" display="primary">
                Save
              </CustomButton>
            </Grid>
            <FormLoader loading={loading} />
          </Grid>
        </Form>
      </Modal>
    </>
  );
};

export default EditStaffFacilities;
