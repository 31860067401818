import React from 'react';
import Button from '@mui/material/Button';
import { useSurveyResultsContext } from 'modules/surveys/context';


const SurveySummary = () => {
  const { showSurveySummary } = useSurveyResultsContext();
  return (
    <Button
      className={'sidebar.search_and_summary'}
      sx={{
        color: '#03A882',
        width: '100%',
        padding: 1.5,
      }}
      size="10px"
      variant="outlined"
      onClick={() => showSurveySummary()}
    >
      Survey Summary
    </Button>
  );
};

export default SurveySummary;
