import React from 'react';
import styles from './AutocompleteSearch.module.scss';

import Modal, { ModalTitle } from 'shared/modal';
import InputAdornment from '@mui/material/InputAdornment';

import InfoIcon from '@mui/icons-material/InfoOutlined';

import { useToggler } from 'hooks';

const TipModal = () => {
  const [showModal, toggleModal] = useToggler();

  return (
    <>
      <InputAdornment position="end" onClick={toggleModal}>
        <InfoIcon className={styles.tipIcon} color="disabled" />
      </InputAdornment>
      <Modal className={styles.tipModal} open={showModal} onClose={toggleModal}>
        <ModalTitle text="Search tips" onClose={toggleModal} />
        <p className={styles.tipModal__text}>
          You can search system users by the following:
        </p>
        <ul className={styles.tipModal__list}>
          <li className={styles.tipModal__list__item}>
            User or inmate first and last name
          </li>
          <li className={styles.tipModal__list__item}>Inmate ID</li>
          <li className={styles.tipModal__list__item}>Phone number</li>

          <li className={styles.tipModal__list__item}>Email address</li>
        </ul>

        <p className={styles.tipModal__text}>
          You can search messages by the following:
        </p>
        <ul className={styles.tipModal__list}>
          <li className={styles.tipModal__list__item}>Keyword</li>
          <li className={styles.tipModal__list__item}>
            Phrase (use quotation marks, e.g. &quot;good morning&quot;)
          </li>
          <li className={styles.tipModal__list__item}>
            User or inmate first and last name
          </li>
          <li className={styles.tipModal__list__item}>Inmate ID</li>
          <li className={styles.tipModal__list__item}>Phone number</li>
        </ul>
      </Modal>
    </>
  );
};

export default TipModal;
