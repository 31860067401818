import React, { useEffect, useState } from 'react';
import styles from './ZohoForm.module.scss';
import cs from 'classnames';

import Form from 'shared/form';

import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { of, Subject } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ZohoCreateTicketCmd } from 'kiwi-sdk';
import { useGraphql } from 'context';
import { useToggler } from 'hooks';
import { useAuthContext } from '../../auth/context';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const validationSchema = yup.object({
	subject: yup
	.string()
	.max(255, 'Field "subject" can be at most 255 characters')
	.required('Subject is a mandatory field '),
	message: yup
	.string()
	.max(65535, 'Field "message" can be at most 65535 characters')
	.required('Message is a mandatory field'),
});

const TextInput = ( { name, placeholder, label } ) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const hasError = !!errors[name];

	return (
		<div
			className={cs(styles.textInput, hasError && styles['textInput--error'])}
		>
			<label>{label}*</label>
			<input
				placeholder={hasError ? errors[name].message : placeholder}
				{...register(name)}
			/>
		</div>
	);
};

const MultilineInput = ( { name, placeholder, label } ) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const hasError = !!errors[name];

	return (
		<div
			className={cs(styles.textInput, hasError && styles['textInput--error'])}
		>
			<label>{label}*</label>
			<textarea
				placeholder={hasError ? errors[name].message : placeholder}
				{...register(name)}
			/>
		</div>
	);
};

const SubmitButton = ( { onSubmit, loading } ) => {
	const {
		formState: { isDirty },
		handleSubmit,
		reset,
	} = useFormContext();

	return (
		<button
			className={styles.submitButton}
			onClick={handleSubmit(onSubmit(reset))}
			disabled={!isDirty || loading}
		>
			Send Message
		</button>
	);
};

const ZohoForm = () => {
	const api = useGraphql();
	const [sendTicket$] = useState(new Subject());
	const [loading, toggleLoading] = useToggler();
	const { user } = useAuthContext();

	const initialValues = {
		subject: '',
		message: '',
		userId: user?.id,
	};

	function sendTicket( payload ) {
		return api.send(ZohoCreateTicketCmd(payload));
	}

	useEffect(() => {
		const sub = sendTicket$
		.pipe(
			switchMap(( { data, callback } ) => {
				toggleLoading(true);
				return sendTicket(data).pipe(map(( data ) => [data, callback]));
			}),
			catchError(( err ) => {
				console.error(err);
				return of({ status: 'ERROR' });
			}),
		)
		.subscribe(( [data, callback] ) => {
			toggleLoading(false);
			if (data?.status === 'ERROR') {
				toast.error('Failed to sent ticket');
				return;
			}
			callback();
			toast.success('Thank you for your message!');
		});

		return () => sub.unsubscribe();
	}, []);

	const handleSubmit = ( callback ) => ( data ) => {
		sendTicket$.next({ data, callback });
	};

	const handleError = ( error ) => {
		Object.keys(error).forEach(( key ) => toast.error(error[key].message));
	};

	return (
		<div className={styles.zohoForm}>
			<div className={styles.zohoForm__container}>
				<h3 className={styles.zohoForm__title}>Contact Us</h3>
				<p className={styles.zohoForm__info}>
					If you have a question or are experiencing an issue, send us a message
					and we&rsquo;ll respond as soon as possible.
				</p>
				<Form
					className={styles.form}
					validation={validationSchema}
					initialValues={initialValues}
					onError={handleError}
					mode='onSubmit'>
					<TextInput
						name='subject'
						label='Subject'
						placeholder='Enter the subject of your message'
					/>
					<MultilineInput
						name='message'
						label='Message'
						placeholder='Type your message here...'
					/>
					{loading ?
						<Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
							<CircularProgress size={48} sx={{ color: 'white', marginRight: 5 }} />
						</Box>
						: <SubmitButton onSubmit={handleSubmit} loading={loading} />}
				</Form>
			</div>
		</div>
	);
};

export default ZohoForm;

