import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styles from './EditStaffRoles.module.scss';

import Grid from '@mui/material/Grid';
import Modal, { ModalTitle } from 'shared/modal';
import CustomButton from 'shared/button';
import Form from 'shared/form';
import FormLoader from 'shared/form-loader';
import RoleCheckbox from './RoleCheckbox';
import SelectAllRolesButton from './SelectAllRolesButton';

import useDocInfo from '../hooks/useDocInfo';

import { Subject, from, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { DOCUserRolesUpdateCmd } from 'kiwi-sdk';
import { useToggler } from 'hooks';
import { useRoleHandler } from '../hooks';
import { useGraphql } from 'context';

import { useAdminSettingsContext } from '../context';

const EditStaffRoles = ({ id, showEdit, toggleEdit }) => {
  const {
    setSelectedStaff,
    selectedStaff,
    rolesArray: rolesList,
    setRolesArray,
  } = useAdminSettingsContext();

  const { roleUsersCountHandler } = useRoleHandler();
  const [loading, toggleLoading] = useToggler();
  const [edit$] = useState(new Subject());
  const { selectedStaffInfo } = useDocInfo(id);
  const api = useGraphql();

  const { firstName, lastName, email, security = {} } = selectedStaffInfo || {};

  const { role: roles = [] } = security;

  function editStaffRoles({ staff, newRoles }) {
    return from(
      api.send(
        DOCUserRolesUpdateCmd({
          docUserInfo: { data: { ...staff } },
          newRoles,
        })
      )
    );
  }
  useEffect(() => {
    const sub = edit$
      .pipe(
        tap(toggleLoading),
        switchMap((payload) => {
          return editStaffRoles(payload).pipe(map((res) => [res, payload]));
        }),
        map(([res, payload]) => {
          toggleLoading(false);
          if (res.status === 'ERROR') {
            toast.error(res.error);
          } else {
            setRolesArray(
              roleUsersCountHandler(res.data?.operations, rolesList)
            );
            setSelectedStaff({
              ...payload.staff,
              security: {
                ...payload.staff.security,
                role: payload.newRoles,
              },
            });
            toggleEdit(false);
          }
        })
      )
      .subscribe();

    return () => sub.unsubscribe();
  }, []);

  const initialValues = useMemo(() => {
    const values = {};
    const allRoles = roles || [];
    rolesList?.forEach(({ id }) => {
      values[id] = allRoles.some((role) => role.id == id);
    });
    return values;
  }, [rolesList, roles]);

  const handleSubmit = useCallback(
    (form) => {
      const newRoles = [];
      Object.entries(form).forEach(([id, value]) => {
        if (!value) {
          return;
        }
        const role = rolesList.find((item) => item.id === id);
        if (role) {
          newRoles.push({
            id,
            info: role.name,
          });
        }
      });
      edit$.next({ staff: selectedStaffInfo, newRoles });
    },
    [selectedStaffInfo, rolesList]
  );

  const UserItem = ({ firstName, lastName, email }) => {
    return (
      <Grid container classes={{ root: styles.form__content__users__item }}>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          {`${firstName} ${lastName}`}
        </Grid>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          {email}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Modal className={styles.modal} open={showEdit} onClose={toggleEdit}>
        <ModalTitle
          className={styles.title}
          text="Change Role"
          onClose={toggleEdit}
        />

        <Form
          className={styles.form}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          <div className={styles.form__content__wrapper}>
            <div className={styles.form__content}>
              <div className={styles.form__content__users}>
                <UserItem
                  key={selectedStaff.id}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                />
              </div>

              <div className={styles.form__content__roles}>
                <SelectAllRolesButton />
                {rolesList?.map(({ id, name }) => (
                  <RoleCheckbox key={id} name={id} label={name} />
                ))}
              </div>
            </div>
          </div>

          <Grid
            classes={{ root: styles.form__actions }}
            direction="row-reverse"
            justifyContent="space-between"
            wrap="nowrap"
            container
          >
            <Grid justifyContent="flex-end" wrap="nowrap" container item>
              <CustomButton
                className={styles.cancelButton}
                onClick={toggleEdit}
                display="secondary"
              >
                Cancel
              </CustomButton>
              <CustomButton type="submit" display="primary">
                Save
              </CustomButton>
            </Grid>
            <FormLoader loading={loading} />
          </Grid>
        </Form>
      </Modal>
    </>
  );
};

export default EditStaffRoles;
