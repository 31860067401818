import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import ListContainer from '../layouts/ListContainer';
import Suspense from 'shared/suspense-list';
import UserCard from 'modules/global-search/components/user-card/UserCard';
import GridLayoutLoader from 'shared/loaders/search-results/GridLayoutLoader';
import VerticalScroller from 'shared/vertical-scroller';
import ErrorBoundary from 'shared/error-boundary';

import { useSearchFnfs } from 'modules/global-search/hooks';
import { useParams } from 'react-router';

export default function FnfsTab() {
	const { searchTerm } = useParams();
	const { data, isFetching, hasNextPage, fetchNextPage } = useSearchFnfs(searchTerm);
	const iterableList = getFlattedList(data);
	const classes = useStyles();

	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<Suspense
				total={iterableList?.length}
				loading={isFetching}
				fallback={<GridLayoutLoader />}
				textNoResults='No Doc found'
			>
				<ListContainer>
					<VerticalScroller
						loadNext={fetchNextPage}
						hasNext={hasNextPage}
						loadingNext={isFetching}
					>
						<Box className={classes.gridContainer}>
							{iterableList.map(( item, index ) => (
								<Box key={index} className={classes.box}>
									<UserCard
										id={item.id}
										eid={item?.eid}
										searchTerm={searchTerm}
										firstName={item.firstName}
										lastName={item.lastName}
										isBlocked={item.status === 'blocked'}
										{...item}
									/>
								</Box>
							))}
						</Box>
					</VerticalScroller>
				</ListContainer>
			</Suspense>
		</ErrorBoundary>

	);
}

const useStyles = makeStyles(( theme ) => ({
	gridContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, 225px [col-start])',
		gridColumnGap: theme.spacing(2),
		gridRowGap: theme.spacing(2),

	},
	box: {
		alignItems: 'flex-start',
		backgroundColor: 'customPalette.grey5',
		display: 'flex',
		justifyContent: 'center',
		height: 225,
		width: 225,
	},
}));

function getFlattedList( data ) {
	if (data?.pages) {
		const list = data.pages.flatMap(( page ) => page?.items.fnf) || [];
		return list.some(( item ) => item !== undefined) ? list : [];
	}
	if (data?.items) {
		const list = data.items.fnf || [];
		return list.some(( item ) => item !== undefined) ? list : [];
	}
	return [];
}


