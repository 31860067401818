import React from 'react';
import cs from 'classnames';
import styles from './MediaModal.module.scss';

import { Alert } from '../chat-details/Icons';
const ModalCaption = ({
  isMessageMine,
  isBlocked,
  hasCaption,
  text,
  ...props
}) => {
  return (
    <div
      className={cs(
        styles.message,
        isMessageMine && styles['message--mine'],
        isBlocked && styles['message--blocked']
      )}
    >
      {hasCaption && <p>{text}</p>}
      {isBlocked && (
        <div className={styles.message__alert}>
          <Alert />
          <span>{props.statusData?.reason}</span>
        </div>
      )}
    </div>
  );
};

export default ModalCaption;
