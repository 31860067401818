import styles from './MediaModal.module.scss';

import Grid from '@mui/material/Grid';

import Thumbnail from './Thumbnail';
import MessageInfo from './MessageInfo';
import BlockFormInputs from './BlockFormInputs';

import { toast } from 'react-toastify';
import { ModalTitle } from 'shared/modal';
import { useBlockImageMessage, useToggler } from 'hooks';
import { useAuthContext } from 'modules/auth/context';
import { formatPhoneNumber, QUERY_KEYS, toTitleCase } from 'utils';
import { queryClient } from 'utils/queryClient';

const initialValues = {
	reason: '',
	note: '',
};

const BlockForm = ( props ) => {
	const {
		isBlocked,
		id,
		owner_eid,
		channel,
		thumbnailSrc,
		owner_info,
		owner_type,
		createdAt,
		onClose,
		mediaType,
	} = props;

	const mediaTypesElements = {
		voice: 'Audio',
		image: 'Photo',
		video: 'Video',
		text: 'Text',
	};

	const { user } = useAuthContext();
	const [loading, toggleLoading] = useToggler();
	const blockImageMessageMutation = useBlockImageMessage(isBlocked);
	const date = new Date(createdAt);

	function handleBlockSubmit( form ) {
		toggleLoading(true);
		blockImageMessageMutation.mutate(
			{
				channelId: channel,
				msgId: id,
				comment: form.note || 'No comment',
				reason: form.reason || '',
				by: {
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
				},
			},
			{
				onSuccess: ( res ) => {
					toast.success(`The ${mediaTypesElements[mediaType]} has been blocked successfully`);
					queryClient.invalidateQueries([QUERY_KEYS.SEARCH_IMAGE_MESSAGES]);
					onClose();
				},
				onError: ( err ) => {
					toast.error(`Failed to block ${mediaTypesElements[mediaType]}`);
					onClose();
				},
			});
	}

	return (
		<>
			<ModalTitle
				className={styles.modal__title}
				text={`${isBlocked ? 'Unblock' : 'Block'} ${
					mediaTypesElements[mediaType]
				}`} />
			<Grid
				classes={{ root: styles.preview }}
				alignItems='center'
				wrap='nowrap'
				container>
				<Thumbnail type={mediaType} src={thumbnailSrc} />
				<MessageInfo
					owner_info={toTitleCase(owner_info)}
					owner_id={formatPhoneNumber(owner_eid)}
					date={date}
					type={mediaType} />
			</Grid>
			<BlockFormInputs
				isBlocked={isBlocked}
				initialValues={initialValues}
				handleBlockSubmit={handleBlockSubmit}
				owner_type={owner_type}
				onClose={onClose}
				loading={loading} />
		</>
	);
};

export default BlockForm;
