import { Fragment } from 'react';

import ChatCharts from './charts';
import ChatChartFilterMenu from './charts/date-filter/ChatChartFilterMenu';
import ChatMetricsLayout from './chat-metrics-layout/ChatMetricsLayout';
import AnalyticsModal from 'shared/analytics-modal/AnalyticsModal';


import useChatMetricsContext from './hooks/useChatMetricsContext';

export default function ChatMetrics() {
	const {
		openFlaggedMessagesModal,
		toggleFlaggedMessagesModal,
		selectedOption,
		dateRangeFilter,
		selectedAnalyticModalConfig,
		sentimentSelectedRangeFilter,
		setSentimentSelectedRangeFilter,
	} = useChatMetricsContext();


	const closeModalAndResetState = () => {
		setSentimentSelectedRangeFilter(null);
		toggleFlaggedMessagesModal();
	};

	return (
		<Fragment>
			<ChatMetricsLayout
				title='Chat Metrics'
				Filters={ChatChartFilterMenu}
				Charts={ChatCharts}
			/>
			<AnalyticsModal
				openModal={openFlaggedMessagesModal}
				toggleModal={closeModalAndResetState}
				currentSelectedOption={selectedOption}
				sectionDateRangeFilter={dateRangeFilter}
				sentimentSelectedRangeFilter={sentimentSelectedRangeFilter}
				selectedAnalyticModalConfig={selectedAnalyticModalConfig}
			/>
		</Fragment>
	);
}
