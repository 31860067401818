import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box } from '@mui/material';

const ChatLoader = props => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<ContentLoader
				viewBox='0 0 400 160'
				height={160}
				width={400}
				backgroundColor='#9191a1'
				foregroundColor='#A2CDB0'
				{...props}
			>
				<circle cx='150' cy='86' r='8' />
				<circle cx='194' cy='86' r='8' />
				<circle cx='238' cy='86' r='8' />
			</ContentLoader>
		</Box>
	);
};

export default ChatLoader;
