import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { of, Subject, from } from 'rxjs';
import { tap, switchMap, catchError, map, pluck } from 'rxjs/operators';
import { SearchDOCQuery } from 'kiwi-sdk';
import { useSearchParams, useToggler } from 'hooks';
import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';

const AdminSettingsContext = createContext({});

const initAdminSettingsContext = () => {
  const api = useGraphql();

  const { paramsController } = useSearchParams();

  const { agency, user, roles } = useAuthContext();

  const [loadDOCList$] = useState(new Subject());

  const nextPage = useRef(undefined);
  const term = useRef('');
  const updateAutocompleteSearchList = useRef(false);
  const updateDisplayedDOCList = useRef(false);

  const [appPermissions, setAppPermissions] = useState([]);
  const [noResultsFromSearch, setNoResultsFromSearch] = useState(false);
  const [noResultSearchInput, setNoResultSearchInput] = useState('');
  const [loading, toggleLoading] = useToggler();

  const [DOCList, setDOCList] = useState([]);
  const [docListByRole, setDocListByRole] = useState([]);
  const [rolesArray, setRolesArray] = useState(roles);

  const [displayedDOCList, setDisplayedDOCList] = useState([]);
  const [autocompleteSearchList, setAutocompleteSearchList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedStaff, setSelectedStaff] = useState({});
  const [displayRoles, setDisplayRoles] = useState(true);
  const [displayStaff, setDisplayStaff] = useState(false);
  const [roleSelected, setRoleSelected] = useState();
  const { strictPermissionCheck } = useAuthContext();
  const reqPermsForEditStaffRole = [
    'DOC#WRITE#addRoleToDOC',
    'DOC#WRITE#removeRoleFromDOC',
  ];
  const reqPermsForEditStaffFacilities = [
    'DOC#WRITE#removeFacilityFromDOC',
    'DOC#WRITE#addFacilityToDOC',
  ];
  const hasPermission = {
    createRole: strictPermissionCheck('AGENCY#WRITE#createAgencyRole'),
    editRole: strictPermissionCheck('AGENCY#WRITE#updateRolePermissions'),
    createAccount: strictPermissionCheck('DOC#WRITE#addDOCUser'),
    editStaffRoles: reqPermsForEditStaffRole.every((perm) =>
      strictPermissionCheck(perm)
    ),
    editStaffFacilities: reqPermsForEditStaffFacilities.every((perm) =>
      strictPermissionCheck(perm)
    ),
  };

  useEffect(() => {
    setRoleSelected(rolesArray[0]);
  }, [rolesArray]);

  function loadAppPermissions() {
    return from(api.controller.app.getPermissions());
  }

  useEffect(() => {
    toggleLoading(true);
    const sub = loadAppPermissions().subscribe({
      next: (res) => {
        setAppPermissions(res.permissions);
        toggleLoading(false);
      },
      error: (err) => {
        console.error(err);
        toggleLoading(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  function loadDocStaff(term, filters) {
    const observer = api
      .send(
        SearchDOCQuery({
          term,
          limit: 40,
          filters,
          nextPage: nextPage.current,
        })
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          return of([]);
        })
      );

    nextPage.current = false;
    return observer;
  }

  useEffect(() => {
    const loadSub = loadDOCList$
      .pipe(
        tap(() => {
          toggleLoading(true);
        }),
        switchMap(({ firstPage, input, filters }) =>
          loadDocStaff(input, filters).pipe(
            tap((res) => {
              nextPage.current = res?.data?.nextPage;
            }),
            pluck('data', 'items', 'doc'),
            map((result) => ({
              result,
              payload: { firstPage, input, filters },
            }))
          )
        )
      )
      .subscribe(({ result = [], payload }) => {
        if (payload.firstPage) {
          setDOCList(result);
        } else {
          setDOCList((state) => [...state, ...result]);
        }

        toggleLoading(false);
      });

    loadDOCList$.next({ firstPage: true });

    return () => {
      loadSub.unsubscribe();
    };
  }, []);

  const loadNextPage = useCallback(() => {
    if (nextPage.current) {
      loadDOCList$.next({ firstPage: false, input: term.current });
    }
  }, [loadDOCList$]);

  const searchDOCStaff = useCallback(
    (input) => {
      nextPage.current = undefined;
      term.current = input;
      setSearchInput(input);
      paramsController.set({ search: input });
      loadDOCList$.next({ firstPage: true, input });
    },
    [loadDOCList$]
  );

  const triggerLoading = useCallback(() => {
    toggleLoading(true);
  });

  const swapSettingsDisplayToRoles = useCallback(() => {
    setDisplayStaff(false);
    setDisplayRoles(true);
  });

  const swapSettingsDisplayToStaff = useCallback(() => {
    setDisplayRoles(false);
    setDisplayStaff(true);
  });

  useEffect(() => {
    setNoResultsFromSearch(!DOCList.length);
    if (updateDisplayedDOCList.current || nextPage.current) {
      updateDisplayedDOCList.current = false;
      noResultsFromSearch
        ? setNoResultSearchInput(searchInput)
        : setDisplayedDOCList(DOCList);
    }
  }, [DOCList]);

  useEffect(() => {
    if (updateAutocompleteSearchList.current) {
      setAutocompleteSearchList(DOCList);
      updateAutocompleteSearchList.current = false;
    }
  }, [DOCList]);

  useEffect(() => {
    const noResultSearchInput = displayedDOCList.length ? '' : searchInput;
    return setNoResultSearchInput(noResultSearchInput);
  }, [displayedDOCList]);

  return {
    appPermissions,
    agency,
    user,
    rolesArray,
    setRolesArray,
    docListByRole,
    setDocListByRole,
    roleSelected,
    setRoleSelected,
    DOCList,
    loading,
    loadNextPage,
    searchInput,
    setSearchInput,
    searchDOCStaff,
    selectedStaff,
    setSelectedStaff,
    displayStaff,
    displayRoles,
    swapSettingsDisplayToRoles,
    swapSettingsDisplayToStaff,
    triggerLoading,
    displayedDOCList,
    setDisplayedDOCList,
    updateDisplayedDOCList,
    updateAutocompleteSearchList,
    autocompleteSearchList,
    noResultsFromSearch,
    setNoResultsFromSearch,
    noResultSearchInput,
    setNoResultSearchInput,
    hasPermission,
  };
};

const AdminSettingsProvider = ({ children, ...props }) => {
  const {
    appPermissions,
    agency,
    user,
    rolesArray,
    setRolesArray,
    docListByRole,
    setDocListByRole,
    roleSelected,
    setRoleSelected,
    DOCList,
    loading,
    loadNextPage,
    searchInput,
    setSearchInput,
    searchDOCStaff,
    selectedStaff,
    setSelectedStaff,
    displayStaff,
    displayRoles,
    swapSettingsDisplayToRoles,
    swapSettingsDisplayToStaff,
    triggerLoading,
    displayedDOCList,
    setDisplayedDOCList,
    updateDisplayedDOCList,
    updateAutocompleteSearchList,
    autocompleteSearchList,
    noResultsFromSearch,
    setNoResultsFromSearch,
    noResultSearchInput,
    setNoResultSearchInput,
    hasPermission,
  } = initAdminSettingsContext(props);

  const contextValue = useMemo(
    () => ({
      appPermissions,
      agency,
      rolesArray,
      setRolesArray,
      user,
      docListByRole,
      setDocListByRole,
      roleSelected,
      setRoleSelected,
      DOCList,
      loading,
      loadNextPage,
      searchInput,
      setSearchInput,
      searchDOCStaff,
      selectedStaff,
      setSelectedStaff,
      displayStaff,
      displayRoles,
      swapSettingsDisplayToRoles,
      swapSettingsDisplayToStaff,
      triggerLoading,
      displayedDOCList,
      setDisplayedDOCList,
      updateDisplayedDOCList,
      updateAutocompleteSearchList,
      autocompleteSearchList,
      noResultsFromSearch,
      setNoResultsFromSearch,
      noResultSearchInput,
      setNoResultSearchInput,
      hasPermission,
    }),
    [
      agency,
      user,
      rolesArray,
      setRolesArray,
      docListByRole,
      setDocListByRole,
      roleSelected,
      setRoleSelected,
      DOCList,
      loading,
      loadNextPage,
      searchInput,
      setSearchInput,
      searchDOCStaff,
      selectedStaff,
      setSelectedStaff,
      displayStaff,
      displayRoles,
      swapSettingsDisplayToRoles,
      swapSettingsDisplayToStaff,
      triggerLoading,
      displayedDOCList,
      setDisplayedDOCList,
      updateDisplayedDOCList,
      updateAutocompleteSearchList,
      autocompleteSearchList,
      noResultsFromSearch,
      setNoResultsFromSearch,
      noResultSearchInput,
      setNoResultSearchInput,
      hasPermission,
    ]
  );

  return (
    <AdminSettingsContext.Provider value={contextValue}>
      {children}
    </AdminSettingsContext.Provider>
  );
};

/**
 * @typedef {object} AdminSettingsContext
 * @property {array} DOCList
 * @property {boolean} loading
 * @property {function} loadNextPage
 * @property {string} searchInput
 * @property {function} setSearchInput
 * @property {function} searchDOCStaff
 * @property {function} swapSettingsDisplayToStaff
 * @property {function} swapSettingsDisplayToRoles
 * @property {function} updateSelectedStaff
 */

/**
 * @returns {AdminSettingsContext}
 */

const useAdminSettingsContext = () => {
  const context = useContext(AdminSettingsContext);

  return context;
};

export { AdminSettingsProvider, useAdminSettingsContext };
