import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Close = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.875 6.50923L13.3842 12L18.875 17.4908L17.4908 18.875L12 13.3842L6.50923 18.875L5.125 17.4908L10.6158 12L5.125 6.50923L6.50923 5.125L12 10.6158L17.4908 5.125L18.875 6.50923Z"
      fill="black"
    />
  </SvgIcon>
);

export default Close;
