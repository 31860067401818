import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useProfileChats() {
	const api = useGraphql();
	const params = useParams();
	const { userId } = params;

	function getUserProfileInfo() {
		return userId.indexOf('FNF^') === 0
			? api.controller.fnf.inmates({ id: userId })
			: api.controller.inmate.fnfs({ id: userId });
	}

	const userChatsQuery = useQuery({
		queryKey: [QUERY_KEYS.USER_CHATS, userId],
		queryFn: getUserProfileInfo,
	});

	
	return userChatsQuery;
}
