import React, { useCallback } from 'react';
import styles from './CreateBulletin.module.scss';

import Button from '@mui/material/Button';
import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Stepper, { StepperContent } from 'shared/stepper';
import { StepperProvider } from 'context';
import TitleStep from './TitleStep';
import RecipientsStep from './RecipientsStep';
import ReviewStep from './ReviewStep';

import { Plus } from 'assets/icons';

import * as yup from 'yup';
import { toast } from 'react-toastify';
import { from } from 'rxjs';
import { SendBulletinCmd } from 'kiwi-sdk';
import { useToggler } from 'hooks';
import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';
import { useBulletinsContext } from 'modules/bulletins/context';
import dayjs from 'dayjs';

const STEPS = [
	{
		id: 'title',
		label: 'Title & text',
		component: TitleStep,
	},
	{
		id: 'recipients',
		label: 'Choose recipients',
		component: RecipientsStep,
	},
	{
		id: 'review',
		label: 'Review',
		component: ReviewStep,
	},
];

const validation = yup.object({
	title: yup.string().max(100).required(),
	file: yup.mixed(),
	text: yup.string().required(),
	recipientsType: yup.string(),
	recipients: yup.array().when('recipientsType', {
		is: 'INMATES',
		then: yup.array().min(1).max(10),
		otherwise: yup.array().when('recipientsType', {
			is: 'FACILITIES',
			then: yup.array().min(1),
			otherwise: yup.array(),
		}),
	}),
});

const initialValues = {
	title: '',
	file: null,
	text: '',
	recipientsType: 'ALL',
	recipients: [],
};

const CreateBulletin = ( { onCreate } ) => {
	const [showModal, toggleModal] = useToggler();
	const [loading, toggleLoading] = useToggler();
	const { user, agency, facilities } = useAuthContext();
	const { setBulletins } = useBulletinsContext();
	const api = useGraphql();

	function createBulletin( { form, user, agency } ) {
		const recipientArray = [];
		switch (form.recipientsType) {
			case 'ALL': {
				form.recipientsType = 'FACILITIES';
				facilities.forEach(( facility ) => {
					recipientArray.push({
						agencyId: facility.agencyId,
						agencyName: facility.agencyName,
						facilityId: facility.id,
						facilityName: facility.name,
						inmateCount: facility.inmateCount,
					});
				});
				break;
			}
			case 'INMATES': {
				form.recipients.forEach(( inmate ) => {
					recipientArray.push({
						inmateId: inmate.id,
						firstName: inmate.firstName,
						lastName: inmate.lastName,
						inmateCount: 1,
						...inmate.location,
					});
				});
				break;
			}
			case 'FACILITIES': {
				form.recipients.forEach(( facility ) => {
					recipientArray.push({
						agencyId: agency.id,
						agencyName: agency.name,
						facilityId: facility.id,
						facilityName: facility.name,
						inmateCount: facility.inmateCount,
					});
				});
				break;
			}
			//case 'HOUSING_UNITS' can be added once the matching functionality is built on the front end
			default: {
				break;
			}
		}
		//need to add a field to the form for selecting a validUntil date
		const validUntilMonth = dayjs().add(30, 'day').toISOString();

		const payload = {
			subject: form.title,
			message: form.text,
			files: form.file ? [form.file] : [],
			sentBy: {
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
			},
			recipients: recipientArray,
			validUntil: validUntilMonth,
		};

		return from(api.send(SendBulletinCmd(payload)));
	}

	const onSubmit = useCallback(
		( form ) => {
			toggleLoading(true);
			createBulletin({ form, user, agency }).subscribe({
				next: ( response ) => {
					onCreate();
					toggleLoading(false);
					setBulletins(( bulletins ) => [response?.data, ...bulletins]);
					toast.success('Announcement was created');
					toggleModal(false);
				},
				error: ( err ) => {
					console.error(err);
					toggleLoading(false);
				},
			});
		},
		[toggleModal, user, agency],
	);

	return (
		<>
			<Button
				classes={{ root: styles.button }}
				onClick={toggleModal}
				startIcon={<Plus />}
				display='secondary'
			>
				New announcement
			</Button>
			<Modal className={styles.modal} open={showModal} onClose={toggleModal}>
				<ModalTitle text='Create announcement' onClose={toggleModal} />
				<StepperProvider steps={STEPS}>
					<Stepper />
					<Form
						initialValues={initialValues}
						validation={validation}
						onSubmit={onSubmit}
					>
						<StepperContent loading={loading} />
					</Form>
				</StepperProvider>
			</Modal>
		</>
	);
};

export default CreateBulletin;
