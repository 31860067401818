import { useAdminSettingsContext } from '../context';

const usePermissionFoils = (childrenArr, rootPermissionValue) => {
  const { roleSelected = {} } = useAdminSettingsContext();

  const rolePermissions = roleSelected.permissions;

  let permissionLevel = 'No Access';

  let foils = [];

  childrenArr.forEach((child) => {
    const foilArr = child?.children || [];
    const checkedChild = foilArr.map((foil) => {
      const hasRootPermission = rolePermissions.includes(rootPermissionValue);
      const hasChildPermission = rolePermissions.includes(
        `${rootPermissionValue}#${child?.value}`
      );
      const hasCustomPermission = rolePermissions.includes(
        `${rootPermissionValue}#${child?.value}#${foil?.value}`
      );
      const hasPermission =
        hasRootPermission || hasChildPermission || hasCustomPermission || false;

      if (permissionLevel === 'No Access') {
        switch (true) {
          case hasRootPermission:
            permissionLevel = 'Full Access';
            break;
          case hasChildPermission && child?.value === 'READ':
            permissionLevel = 'View Only';
            break;
          case hasCustomPermission:
            permissionLevel = 'Custom Access';
            break;
        }
      }

      return { ...foil, isValid: hasPermission, parentValue: child.value };
    });

    foils = [...foils, ...checkedChild];
  });

  return [foils, permissionLevel];
};

export default usePermissionFoils;
