import { Box, Paper } from '@mui/material';

import CardButton from './CardButton';
import CardAvatar from './CardAvatar';
import CardInfo from './CardInfo';

import { generatePath, useHistory } from 'react-router';
import { getUserType } from 'utils';
import { routes } from 'routing';
import { useAvatarPhoto } from 'hooks';

export default function UserCard( props ) {
	const { searchTerm = '', firstName = '', lastName = '', id = '', eid = '', photo, isBlocked, isActive } = props;
	const history = useHistory();
	const { sxBoxWrapper } = useCardStyles();
	const { thumbnailSrc } = useAvatarPhoto(photo);
	const userType = getUserType(id);

	function onHandlerClick( e ) {
		const userRoute = userType === 'DOC' ? `${generatePath(routes.singleStaff, { staffId: id })}` : `${generatePath(routes.user, { userId: id })}`;
		e.stopPropagation();
		history.push(userRoute);
	};

	return (
		<Paper sx={sxBoxWrapper}>
			<Box sx={{ position: 'relative' }}>
				<CardAvatar id={id} firstName={firstName} lastName={lastName} src={thumbnailSrc} isBlocked={isBlocked} isActive={isActive} />
			</Box>
			<Box sx={{ position: 'relative' }}>
				<CardInfo firstName={firstName} lastName={lastName} eid={eid} searchTerm={searchTerm} userType={userType} onClick={onHandlerClick} />
			</Box>
			<Box sx={{ position: 'relative' }}>
				<CardButton label='View Profile' onClick={onHandlerClick} />
			</Box>
		</Paper>
	);
}

function useCardStyles() {
	return {
		sxBoxWrapper: {
			borderRadius: 4,
			height: '100%',
			width: '100%',
		},
	};
};
