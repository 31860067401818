import { Box, Typography } from '@mui/material';
import format from 'date-fns/format';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export default function ImageNotification({ date, isSeen }) {
  const { sxReadMark, sxBox } = useNotificationStyles(isSeen);

  return (
    <Box sx={sxBox}>
      <Typography variant='messageDate' component='span'>
        {format(new Date(date), 'h:mm a')}
      </Typography>
      <DoneAllIcon sx={sxReadMark} />
    </Box>
  );
}

const useNotificationStyles = (isSeen) => ({
  sxBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: 0.5,

  },
  sxReadMark: { color: isSeen ? 'primary.main' : 'grey.main', fontSize: 16 },
});