import { useCallback, useState } from 'react';
import { from } from 'rxjs';
import { toast } from 'react-toastify';

const useMutation = ( {
												sdkMutationCmd,
												setNewState,
												onSuccesText = 'The operation has been successful',
												onErrorText = 'The operation has failed',
												onClose = () => {
												},
											} ) => {
	const [loading, setLoading] = useState(false);

	const fromMutation = ( payload ) => {
		return from(sdkMutationCmd(payload));
	};
	;
	const handleMutation = useCallback(
		( formPayload ) => {
			setLoading(true);
			fromMutation(formPayload).subscribe({
				next: ( res ) => {
					if (setNewState) {
						setNewState(res);
					}
					setLoading(false);
					onClose();
					toast.success(onSuccesText);
				},
				error: ( err ) => {
					console.error(err);
					setLoading(false);
					// onClose();
					toast.error(onErrorText);
				},
			});
		},
		[sdkMutationCmd, setNewState],
	);

	return [loading, handleMutation];
};

export default useMutation;
