import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import format from 'date-fns/format';

import { styled } from '@mui/material/styles';

import useAnalyticsModalContext from 'shared/analytics-modal/hooks/useAnalyticsModalContext';

import { CustomMap, If } from 'shared/utilities';
import { dateRangeBuilder } from 'utils';

export default function AnalyticsModalTitle( props ) {
	const { closeModal, sectionRangeFilter, sentimentRangeFilter, enableDateFilter = false, ...dialogTitleProps } = props;
	const { setListQueryPayload, modalConfig } = useAnalyticsModalContext();
	const showDateFilters = modalConfig?.showDateFilters;
	const currentInterval = !!sentimentRangeFilter ? 'day' : 'range';
	const currentFilter = !!sentimentRangeFilter ? sentimentRangeFilter : sectionRangeFilter;
	const currentDateSubTitle = formatDateRange(currentFilter, currentInterval);


	function actionButtonClickHandler( value ) {
		setListQueryPayload(( payload ) => {
			const newDateRange = dateRangeBuilder({ interval: value });
			const newPayload = { ...payload, dateRange: newDateRange };
			return newPayload;
		});
	}

	return (
		<ModalTitleContainer {...dialogTitleProps}>
			<TitleContainer>
				<div>
					<Typography variant='h6'>
						{modalConfig?.modalName}
					</Typography>
					<If condition={modalConfig?.listRenderItem !== 'channel'}>
						<Typography variant='regularGrey3'>
							{currentDateSubTitle}
						</Typography>
					</If>
				</div>
				<TitleActionsContainer>
					<If condition={showDateFilters}>
						<CustomMap
							list={DATE_QUICK_FILTERS}
							keyExtractor={( item ) => item.label}
							renderItem={DateRangeButton}
							renderItemProps={{ onClickHandler: actionButtonClickHandler }}
							containerSx={Sxs.DateRangeButton}
						/>
					</If>
					<If condition={closeModal}>
						<IconButton aria-label='close' onClick={closeModal}>
							<CloseIcon />
						</IconButton>
					</If>
				</TitleActionsContainer>
			</TitleContainer>
		</ModalTitleContainer>
	);
}

function DateRangeButton( props ) {
	const { item, onClickHandler } = props;
	return (
		<ActionButton onClick={() => onClickHandler(item?.value)}>
			{item?.label}
		</ActionButton>
	);
}

const ModalTitleContainer = styled(DialogTitle)(( { theme } ) => ({
	padding: '16px 24px 0 24px',
}));

const ActionButton = styled(Button)(( { theme } ) => ({
	fontSize: 13,
	borderRadius: theme.spacing(1),
	borderColor: theme.palette.customPalette.grey4,
	color: theme.palette.customPalette.grey4,
	textTransform: 'capitalize',
}));

const TitleContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const TitleActionsContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',

	alignItems: 'center',
	gap: 10,
});

const Sxs = {
	DateRangeButton: {
		display: 'flex',
	},
};

function formatDateRange( range, interval ) {
	const { from, to } = range;
	const start = format(new Date(from), 'MMM dd, yyyy ');
	const end = format(new Date(to), 'MMM dd, yyyy');

	return interval === 'day' ? `For ${start}` : `From ${start} to ${end}`;
}

const DATE_QUICK_FILTERS = [
	{
		label: '24 hours',
		value: 'day',
	},
	{
		label: '7 days',
		value: 'week',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: '3 Months',
		value: 'months',
	},
];


