import { Box, Button } from '@mui/material';

export default function CardButton( { label, onClick } ) {
	const { sxActionsBox } = useCardButtonStyles();
	return (
		<Box sx={sxActionsBox}>
			<Button
				variant='outlined'
				onClick={onClick}
				sx={{
					textTransform: 'none',
					height: 35,
					color: 'customPalette.grey4',
					fontSize: 14,
					fontWeight: 500,
					border: '1px solid',
					borderRadius: 4,
					borderColor: 'customPalette.grey4',
				}}>
				{label}
			</Button>
		</Box>
	);
}

function useCardButtonStyles() {
	return {
		sxActionsBox: {
			borderRadius: '0 0 16px 16px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '60px',
			marginTop: 11.5,
			padding: '0 10px',
			position: 'absolute',
			width: '203px',
		},
	};
};