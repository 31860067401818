import React, { useCallback, useEffect, useState } from 'react';
import styles from './BulletinDetails.module.scss';

import Grid from '@mui/material/Grid';

import format from 'date-fns/format';
import routes from 'routing/routes';
import Button from 'shared/button';

import RecipientsDisplay from 'shared/recipient-accordion';
import ReactToPrint from 'react-to-print';

import { Share, Print, PdfIcon } from 'assets/icons';
import { IconButton } from '@mui/material';
import { useHistory, generatePath } from 'react-router';
import { InmatesRecipient } from './BulletinRecipientTypes';
import { catchError, of, Subject } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { useGraphql } from 'context';

export const BulletinHeader = ({ subject, printableRef }) => {
  return (
    <div className={styles.header}>
      <p className={styles.header__title}>{subject}</p>
      <div className={styles.header__controls}>
        <ReactToPrint
          trigger={() => (
            <IconButton className={styles.header__btn}>
              <Print />
            </IconButton>
          )}
          content={() => printableRef.current}
        />
      </div>
    </div>
  );
};

export const BulletinSender = ({ sentBy, createdAt }) => {
  return (
    <Grid justifyContent="space-between" container>
      <Grid>
        <p className={styles.body__subTitle}>Sender:</p>
        <p
          className={styles.body__user}
          onClick={() =>
            useHistory().push(
              generatePath(routes.singleStaff, { staffId: sentBy.id })
            )
          }
        >{`${sentBy.firstName} ${sentBy.lastName}`}</p>
      </Grid>
      <p className={styles.body__timeStamp}>
        {format(new Date(createdAt), 'h:mm a LLL, d y')}
      </p>
    </Grid>
  );
};

export const BulletinRecipients = ({ sentTo, recipients, routes, history }) => {
  return (
    <div>
      <p className={styles.body__subTitle}>
        <span>{recipients.length > 1 ? 'Recipients:' : 'Recipient:'}</span>
      </p>
      {sentTo == 'Facilities' && <RecipientsDisplay recipients={recipients} />}
      {sentTo == 'Inmates' && (
        <InmatesRecipient
          recipients={recipients}
          routes={routes}
          history={history}
        />
      )}
    </div>
  );
};

export const BulletinMessage = ({ message }) => {
  return <p className={styles.body__message}>{message}</p>;
};

export const BulletinFiles = ({ files }) => {
  return files.length ? <FileItem fileName={files[0]} /> : <></>;
};

export const BulletinFooter = ({ sent, read, onClose }) => {
  return (
    <>
      <div className={styles.body__views}>
        <span>Sent: {sent}</span>
        <span>Seen: {read}</span>
      </div>
      <Button className={styles.button} onClick={onClose} display="secondary">
        Close
      </Button>
    </>
  );
};

const FileItem = ({ fileName }) => {
  const api = useGraphql();
  const [openFile$] = useState(new Subject());
  const splitName = fileName.split('.');
  const type = splitName.pop();
  const name = splitName.join('.');

  function getFilesLinks(fileNames) {
    return api.getBulletinFiles(fileNames).pipe(
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  useEffect(() => {
    const sub = openFile$
      .pipe(
        switchMap(getFilesLinks),
        mergeMap((urls) => urls),
        tap((url) => window.open(url, '_blank'))
      )
      .subscribe({ error: (err) => console.error(err) });

    return () => sub.unsubscribe();
  }, []);

  const openFile = useCallback(() => {
    openFile$.next([fileName]);
  }, []);

  return (
    <div className={styles.file} onClick={openFile}>
      <PdfIcon />
      <div className={styles.file__info}>
        <span className={styles.file__name}>{name}</span>
        <span className={styles.file__ext}>{`.${type}`}</span>
      </div>
    </div>
  );
};
