import React, { useState, useRef, useMemo } from 'react';
import styles from './PrintRequestList.module.scss';

import Grid from '@mui/material/Grid';
import Form from 'shared/form';

import InfiniteScroller from 'shared/infinite-scroller';
import RequestItem from './RequestItem';
import PrintRequestSelection from '../print-request-selection/PrintRequestSelection';
import SortingSelector from 'modules/users-chats/user-details/chats-tab/SortingSelector';

import { AllPrintRequestsCheckbox } from '../PrintRequestsCheckBox';

const PrintRequestsList = (
	{
		loadNextPage,
		loading,
		requests,
	} ) => {
	const [requestsSorting, sortRequests] = useState('Date_DESC');

	const displayPrintsRequests = useMemo(() => {
		const getFullName = ( request ) => `${request.owner_info}`;
		switch (requestsSorting) {
			case 'Name_ASC': {
				return requests.sort(( a, b ) =>
					getFullName(a).localeCompare(getFullName(b)),
				);
			}
			case 'Name_DESC': {
				return requests.sort(
					( a, b ) => -getFullName(a).localeCompare(getFullName(b)),
				);
			}
			case 'Date_ASC': {
				return requests.sort(
					( a, b ) => new Date(a.createdAt) - new Date(b.createdAt),
				);
			}
			case 'Date_DESC': {
				return requests.sort(
					( a, b ) => new Date(b.createdAt) - new Date(a.createdAt),
				);
			}
			default:
				return requests;
		}
	}, [requests, requestsSorting]);

	const list = useRef();
	return (
		<div className={styles.wrapper}>
			<Form
				className={styles.wrapperForm}
				initialValues={initialSelection}
				shouldUnregister={false}
			>
				<div className={styles.subheader}>
					<PrintRequestSelection allRequests={requests} />
					<SortingSelector
						chatSorting={requestsSorting}
						onSort={sortRequests}
					/>
				</div>
				<Grid
					classes={{ root: styles.listHeader }}
					container
					direction='row'
					justifyContent='flex-start'
					alignItems='center'
				>
					<Grid xs={4} item sx={{ cursor: 'pointer' }}>
						<AllPrintRequestsCheckbox />
						Request ID
					</Grid>
					<Grid xs={3} item>
						Type
					</Grid>
					<Grid xs={5} item>
						Created at
					</Grid>
				</Grid>
				<div className={styles.list} ref={list}>
					{displayPrintsRequests.map(( { id, createdAt, inmateEid, type, code, status } ) =>
						status !== 'REJECTED' ? (
							<RequestItem
								key={id}
								id={id}
								type={type}
								code={code}
								inmateEid={inmateEid}
								createdAt={createdAt}
								allRequests={requests}
							/>
						) : null,
					)}
					<InfiniteScroller
						containerRef={list}
						loading={loading}
						onLoadNext={loadNextPage}
					/>
				</div>
			</Form>
		</div>
	);
};

export default PrintRequestsList;

const initialSelection = {};
