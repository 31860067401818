import styles from './accordionDisplay.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Facilities } from 'modules/surveys/survey-list/Icons';

const RecipientsDisplay = ({ recipients }) => {
    const SingleFacility = () => {
      return (
        <p className={styles.singleRecipient}>{recipients[0].facilityName || recipients[0].name}</p> 
      );
    };
  
    const MultipleFacilities = () => {
      return (
        <>
        <Accordion className={styles.accordion} elevation={0} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
        >
            <Facilities />
            <span> {`${recipients.length} Facilities`}</span>
          </AccordionSummary>
          <AccordionDetails >
            {recipients.map((facility) => (
              <li key={facility.facilityId || facility.id}>{facility.facilityName || facility.name}</li>
            ))}
          </AccordionDetails>
    
        </Accordion>
        </>
      );
    };
  
    return (
      <> 
        {recipients.length > 1 ? <MultipleFacilities /> : <SingleFacility />}
      </>
    );
  };

export default RecipientsDisplay;