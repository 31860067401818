import React from 'react';

import componentsFactory from 'utils/functions/componentsFactory';

import { childProps } from './locationProps';
import { queryClient } from 'utils/queryClient';

export default function DataSource(props) {
  const { location, children, ...rest } = props;

  const promptChildProps = childProps[location]({ ...rest });

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { ...promptChildProps });
        }
        return child;
      })}
    </>
  );
}

DataSource.Cache = componentsFactory(DataSource, {
  location: 'cache',
  client: queryClient,
});

DataSource.Server = componentsFactory(DataSource, {
  location: 'server',
  client: queryClient,
});
