import React, { useRef } from 'react';
import styles from './MediaModal.module.scss';
import cs from 'classnames';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import format from 'date-fns/format';
import Avatar from 'shared/avatar';
import ShowInChatButton from './ShowInChatButton';

import PrintTemplate from './PrintTemplate';
import { useReactToPrint } from 'react-to-print';
import { useIsItemSaved } from 'hooks';
import { Print } from 'assets/icons';
import { BookmarkActionIcon, FlagActionIcon } from 'modules/users-chats/chat-details/print-messages/hoover-icons';
import { formatPhoneNumber, toTitleCase } from 'utils';

const ModalHeader = ( { isVideoCall = false, ...props } ) => {
	return isVideoCall ? (
		<VideoAppHeader {...props} />
	) : (
		<MediaModalHeader {...props} />
	);
};
export default ModalHeader;

const MediaModalHeader = ( props ) => {
	const {
		id,
		channel,
		ownerDisplayId = '',
		lastName,
		firstName,
		openUserPage,
		owner_info,
		createdAt,
		toggleOpen,
		mediaType,
		recipient_info,
		recipient_id,
		owner_id,
		owner_eid,
		src,
		clientKey,
		inmateEid,
	} = props;

	console.log('MediaModalHeader props', props);

	const componentToPrintRef = useRef(null);

	const messageBookmarkId = `${id};${props.channel}`;

	const messageCreatedAt = createdAt
		? format(new Date(createdAt), 'MMM d, y, h:mma')
		: 'date not available';

	const isMessageSaved = useIsItemSaved({
		id: messageBookmarkId,
		type: 'message',
	});

	const handlePrint = useReactToPrint({
		content: () => componentToPrintRef.current,
	});

	return (
		<Grid
			classes={{ root: styles.header }}
			justifyContent='space-between'
			alignItems='center'
			wrap='nowrap'
			container>
			<div className={styles.header__info}>
				<Avatar
					className={styles.header__avatar}
					id={owner_id}
					lastName={lastName}
					firstName={firstName} />
				<Box className={styles.header__sentBy} sx={{ paddingX: 1 }}>
					<p onClick={openUserPage}>{toTitleCase(owner_info)}</p>
					<p>{formatPhoneNumber(owner_eid)}</p>
				</Box>
				<div className={styles.header__infoDivider} />
				<div className={styles.header__sentBy}>
					<p>Sent:</p>
					<p>{messageCreatedAt}</p>
				</div>
			</div>

			<div className={styles.header__controls}>
				<ShowInChatButton channel={channel} messageClientKey={clientKey} ownerId={owner_id} toggleOpen={toggleOpen} />
				<div className={styles.header__divider} />

				<BookmarkActionIcon
					className={cs(
						styles.header__action,
						isMessageSaved && styles['header__action__filled'],
					)}
					isItemSaved={isMessageSaved}
					mediaType={mediaType}
					{...props}
				/>

				{props.mediaType === 'image' && (
					<>
						<div className={styles.header__divider} />

						<IconButton className={styles.header__action} onClick={handlePrint}>
							<Print />
						</IconButton>
					</>
				)}
			</div>
			<div style={{ display: 'none' }}>
				<PrintTemplate
					templateRef={componentToPrintRef}
					imageSrc={src}
					date={messageCreatedAt}
					owner={owner_info}
					recipient={recipient_info}
					owner_eid={owner_eid}
				/>
			</div>
		</Grid>
	)
		;
};

const VideoAppHeader = ( props ) => {
	const { endTime = 'Feb 4, 2022, 11:42 AM', id, channel } = props;
	const endDate = format(new Date(endTime), 'MMM d, y, h:mma');
	const messageBookmarkId = `${id};${channel}`;
	const isMessageSaved = useIsItemSaved({
		id: messageBookmarkId,
		type: 'message',
	});
	const [flagModal, toggleFlagModal] = useToggler();

	return (
		<>
			<Grid
				item
				container
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ pb: '40px' }}
			>
				<Grid item className={styles.header__author} xs={6}>
					<p>Videochat finished at</p>
					<p>{endDate}</p>
				</Grid>
				<Grid item className={styles.header__controls}>
					<CustomButton
						className={styles.modal__transcriptButton}
						display='secondary'
					>
						Get Transcription
					</CustomButton>
					<div className={styles.header__divider} />
					<BookmarkActionIcon
						className={cs(
							styles.header__action,
							isMessageSaved && styles['header__action__filled'],
						)}
						isItemSaved={isMessageSaved}
						{...props}
					/>
					<div className={styles.header__divider} />
					<FlagActionIcon toggleFlagModal={toggleFlagModal} {...props} />
					<FlagVideoAppModal
						modal={flagModal}
						toggleModal={toggleFlagModal}
						{...props}
					/>
				</Grid>
			</Grid>
		</>
	);
};
