import Paper from '@mui/material/Paper';

import Sidebar from './shared/sidebar';
import NotificationContainer from './shared/notification-container';
import BookmarkDrawer from 'shared/sidebar/drawer/BookmarkDrawer';
import AutoDialog from 'shared/idle-managment';
import routing from './routing';
import routes from './routing/routes';

import { useAuthContext } from './modules/auth/context';
import { UserProvider } from './modules/users-chats/context';
import { Redirect, Route, Switch } from 'react-router-dom';

export default function ProtectedApp() {
  const { sxPaper } = useProtectedAppStyles();
  const { openBookmarksDrawer, toggleBookmarksDrawer } = useAuthContext();

  return (
    <UserProvider>
      <AutoDialog />
      <Paper elevation={0} sx={sxPaper}>
        <Sidebar />
        <Switch>
          {routing().map((item) => (
            <Route key={item.path} {...item} />
          ))}
          <Redirect to={routes.chatsMetrics} />
        </Switch>
        <BookmarkDrawer
          open={openBookmarksDrawer}
          onClose={toggleBookmarksDrawer}
        />
      </Paper>
    </UserProvider>
  );
}

function useProtectedAppStyles() {
  return {
    sxPaper: {
      overflow: 'hidden',
      width: '100vw',
      height: '100vh',
    },
  };
}
