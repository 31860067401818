import React, { useEffect, useState } from 'react';
import styles from './ChatDetails.module.scss';

import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

const FloatDate = ( { date = '', listRef } ) => {
	const DELAY_TIME = 150;
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const sub = fromEvent(listRef.current, 'scroll')
		.pipe(
			tap(() => setIsVisible(true)),
			debounceTime(DELAY_TIME),
			tap(() => setIsVisible(false)),
		)
		.subscribe();

		return () => {
			sub.unsubscribe();
		};
	}, []);

	return (
		<div className={styles.floatDate}>
			{isVisible && <p className={styles.floatDate__date}>{date}</p>}
		</div>
	);
};

export default FloatDate;
