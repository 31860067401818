import React from 'react';

const UploadFile = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.37947 1.71223C3.80924 1.28246 4.39213 1.04102 4.99992 1.04102H10.8333C10.999 1.04102 11.158 1.10686 11.2752 1.22407L17.1085 7.05741C17.2257 7.17462 17.2916 7.33359 17.2916 7.49935V16.666C17.2916 17.2738 17.0501 17.8567 16.6204 18.2865C16.1906 18.7162 15.6077 18.9577 14.9999 18.9577H4.99992C4.39213 18.9577 3.80924 18.7162 3.37947 18.2865C2.94969 17.8567 2.70825 17.2738 2.70825 16.666V3.33268C2.70825 2.72489 2.94969 2.142 3.37947 1.71223ZM4.99992 2.29102C4.72365 2.29102 4.4587 2.40076 4.26335 2.59611C4.068 2.79146 3.95825 3.05641 3.95825 3.33268V16.666C3.95825 16.9423 4.068 17.2072 4.26335 17.4026C4.4587 17.5979 4.72365 17.7077 4.99992 17.7077H14.9999C15.2762 17.7077 15.5411 17.5979 15.7365 17.4026C15.9318 17.2072 16.0416 16.9423 16.0416 16.666V7.75823L10.5744 2.29102H4.99992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 1.04102C11.1784 1.04102 11.4583 1.32084 11.4583 1.66602V6.87435H16.6666C17.0118 6.87435 17.2916 7.15417 17.2916 7.49935C17.2916 7.84453 17.0118 8.12435 16.6666 8.12435H10.8333C10.4881 8.12435 10.2083 7.84453 10.2083 7.49935V1.66602C10.2083 1.32084 10.4881 1.04102 10.8333 1.04102Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55798 10.3911C9.80205 10.147 10.1978 10.147 10.4419 10.3911L12.1085 12.0577C12.3526 12.3018 12.3526 12.6975 12.1085 12.9416C11.8644 13.1857 11.4687 13.1857 11.2246 12.9416L10.6249 12.3419V15.4163C10.6249 15.7615 10.3451 16.0413 9.99992 16.0413C9.65474 16.0413 9.37492 15.7615 9.37492 15.4163V12.3419L8.77519 12.9416C8.53112 13.1857 8.13539 13.1857 7.89131 12.9416C7.64723 12.6975 7.64723 12.3018 7.89131 12.0577L9.55798 10.3911Z"
      fill="currentColor"
    />
  </svg>
);

export default UploadFile;
