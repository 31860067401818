import { Box, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function UpDownMessageControl({ firstMessage, lastMessage, upActionHandler, downActionHandler }) {
  return <Box ml={3}>
    <IconButton
      onClick={upActionHandler}>
      <KeyboardArrowUpIcon
        sx={{ color: lastMessage ? 'customPalette.grey4' : 'customPalette.grey1' }} />
    </IconButton>
    <IconButton
      onClick={downActionHandler}>
      <KeyboardArrowDownIcon
        sx={{ color: firstMessage ? 'customPalette.grey4' : 'customPalette.grey1' }} />
    </IconButton>
  </Box>;
}