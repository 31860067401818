import {
  Area,
  AreaChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function KiwiAreaChart(props) {
  const {
    chartData,
    dataKey,
    scale,
    clickable = false,
    onHandleAreaClick,
  } = props;

  const formatXAxisTick = (tickItem) => {
    const date = new Date(tickItem);
    const options = { day: '2-digit', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <ResponsiveContainer>
      <AreaChart
        data={chartData}
        margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
        onClick={clickable && onHandleAreaClick}
      >
        <defs>
          <linearGradient id="colorGv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8CC0DE" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#8CC0DE" stopOpacity={0.3} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#5D9C59" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#5D9C59" stopOpacity={0.3} />
          </linearGradient>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#DF2E38" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#DF2E38" stopOpacity={0.3} />
          </linearGradient>
        </defs>
        <XAxis dataKey={dataKey} tickFormatter={formatXAxisTick} />
        <YAxis scale={scale} />
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey="neutral"
          stroke="#8CC0DE"
          fillOpacity={1}
          fill="url(#colorGv)"
          cursor="pointer"
        />
        <Area
          type="monotone"
          dataKey="negative"
          stroke="#DF2E38"
          fillOpacity={1}
          fill="url(#colorUv)"
          cursor="pointer"
        />
        <Area
          type="monotone"
          dataKey="positive"
          stroke="#5D9C59"
          fillOpacity={1}
          fill="url(#colorPv)"
          cursor="pointer"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
