import React from 'react';
import styles from './RoleInput.module.scss';

import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Check from '@mui/icons-material/Check';

import { ROLE_LEVEL } from '../constants';

const SelectInput = ({
  name,
  value,
  onChange,
  formStateObject,
  rootPermissionIsChecked,
}) => {
  return (
    <Grid
      classes={{ root: styles.select }}
      justifyContent="flex-end"
      alignItems="center"
      container
    >
      <Select
        classes={{
          root: styles.select__input,
          icon: styles.select__input__icon,
        }}
        name={name}
        value={value || ''}
        onChange={onChange}
        renderValue={(value) => getAccesOption(value, name, formStateObject)}
        disabled={!rootPermissionIsChecked}
        MenuProps={{
          classes: {
            root: styles.select__menu,
          },
        }}
        displayEmpty
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        {Object.keys(ACCESS_OPTIONS).map((item) => (
          <MenuItem
            classes={{
              root: styles.select__item,
              selected: styles['select__item--selected'],
            }}
            key={item}
            value={item}
          >
            <p className={styles.select__item__label}>{ACCESS_OPTIONS[item]}</p>
            {value === item ? (
              <Check
                classes={{ root: styles.select__item__check }}
                defaultChecked
              />
            ) : null}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default SelectInput;

const getAccesOption = (value, name, formObject) => {
  const hasReadPermission = formObject[`${name}#READ`];

  const hasWritePermission = formObject[`${name}#WRITE`];

  switch (true) {
    case value === 'VIEW_ONLY' ||
      (hasReadPermission &&
        !hasWritePermission &&
        value !== 'FULL_ACCESS' &&
        value !== 'CUSTOM_ACCESS'):
      return 'View only';

    case value === 'CUSTOM_ACCESS':
      return 'Custom access';

    default:
      return 'Full access';
  }
};

const ACCESS_OPTIONS = {
  [ROLE_LEVEL.FULL_ACCESS]: 'Full access',
  [ROLE_LEVEL.VIEW_ONLY]: 'View only',
  [ROLE_LEVEL.CUSTOM_ACCESS]: 'Custom access',
};
