import React, { useEffect, useRef } from 'react';
import styles from './CreateBulletin.module.scss';

import InmateAutocomplete from '../inmate-autocomplete';
import FacilitiesInput from '../facilities-input';

import { House } from 'assets/icons';

import { useFormContext } from 'react-hook-form';

const AllInput = ({ name }) => {
  const { trigger } = useFormContext();

  useEffect(() => {
    trigger(name);
  }, []);

  return (
    <div className={styles.recipients__placeholder}>
      <House className={styles.recipients__placeholder__icon} />
      <p className={styles.recipients__placeholder__text}>Send to all house</p>
    </div>
  );
};

const RecipientsInput = ({ name }) => {
  const { watch, setValue } = useFormContext();
  const recipientsType = watch('recipientsType');
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setValue(name, []);
    }
  }, [recipientsType]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (recipientsType === 'ALL') {
    return <AllInput name={name} />;
  }

  if (recipientsType === 'INMATES') {
    return <InmateAutocomplete name={name} />;
  }

  if (recipientsType === 'FACILITIES') {
    return <FacilitiesInput name={name} />;
  }

  return null;
};

export default RecipientsInput;
