import React from 'react';
import style from './PermissionsSection.module.scss';

import PermissionFoilsList from './PermissionFoilsList';

import { Grid, Divider } from '@mui/material';

import { usePermissionFoils } from '../hooks';

const Permission = ({ permission }) => {
  const permissionsSetTitle = permission.label;
  const childrenPermission = permission.children;

  const [permissionChekedFoils, permissionLevel] = usePermissionFoils(
    childrenPermission,
    permission.value
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={style.permission__header}
      >
        <span className={style.permission__header__title}>
          {permissionsSetTitle}
        </span>
        <span>{permissionLevel}</span>
      </Grid>
      <PermissionFoilsList foils={permissionChekedFoils} />
      <Divider />
    </>
  );
};

export default Permission;
