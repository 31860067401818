import { Stack } from '@mui/material';
import React from 'react';
import styles from '../ResultPageElements.module.scss';

import RatingAnswerType from './answer-display-type/RatingAnswerType';
import VerticalBarResult from './results-display-type/VerticalBarResult';
import getPercentOfTotalResponses from '../../../utils/functions/getPercentOfTotalResponses';

function RatingResults({ options, answers, isSummary }) {
  const answerInfo = {
    count: 0,
    percentOfTotal: 0,
  };

  return (
    <>
      {options.map((option) => {
        answerInfo.percentOfTotal = 0;
        switch (true) {
          case isSummary:
            answerInfo.count =
              answers?.find((answer) => answer.choice === option)?.count ?? 0;
            answerInfo.percentOfTotal = getPercentOfTotalResponses(
              answerInfo.count
            );
            break;
          case !isSummary && answers === option:
            answerInfo.percentOfTotal = 100;
            break;
        }
        return (
          <div className={styles.responses_item__rating} key={option}>
            <Stack alignItems="center" spacing={1.5}>
              <Stack>
                <RatingAnswerType
                  numberOfResponses={answerInfo.count}
                  percentOfTotal={answerInfo.percentOfTotal}
                  displayAggregate={isSummary}
                />
              </Stack>
              <Stack>
                <VerticalBarResult value={answerInfo.percentOfTotal} />
              </Stack>
              <Stack>{option}</Stack>
            </Stack>
          </div>
        );
      })}
    </>
  );
}
export default RatingResults;
