import TextMessage from 'shared/chat-message/text-message/TextMessage';
import ImageMessage from 'shared/chat-message/image-message';
import ChatMessageContainer from 'shared/chat-message/ChatMessageContainer';
import VoiceMessage from 'shared/chat-message/voice-message/VoiceMessage';

import { Case, Switch } from 'shared/utilities';

export default function ChatMessage(props) {
  const { item: messageData, index, list, messageKey, channelId } = props;

  return (
    <ChatMessageContainer
      messageData={messageData}
      prevMessageData={list[index - 1]}
      nextMessageData={list[index + 1]}
      messageAndChannelSelected={{ channelId, messageKey }}
    >
      <Switch>
        <Case condition={messageData.mediaType === 'text'}>
          <TextMessage />
        </Case>
        <Case condition={messageData.mediaType === 'image'}>
          <ImageMessage />
        </Case>
        <Case condition={messageData.mediaType === 'voice'}>
          <VoiceMessage />
        </Case>
      </Switch>
    </ChatMessageContainer>
  );
}
