 import styles from './BlockUser.module.scss';

import Grid from '@mui/material/Grid';
import add from 'date-fns/add';

import Modal from 'shared/modal';
import Form from 'shared/form';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';

import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';
import { SwitchInput, DaysInput, SelectInput, MultilineInput } from 'shared/inputs';
import { ModalTitle, ModalDescription } from 'shared/modal';
import { queryClient } from 'utils/queryClient';
import { useAuthContext } from 'modules/auth/context';
import { useBlockUser } from 'modules/users-chats/hooks';
import { QUERY_KEYS, toTitleCase } from 'utils';

import { USER_CATEGORY, BLOCKING_REASONS } from 'modules/users-chats/constants';

const INITIAL_VALUES = {
	days: 1,
	indefinitely: false,
	note: '',
	reason: '',
};

const BlockUser = ( { open, onClose, isFnf } ) => {
		const { user } = useAuthContext();
		const { userProfileInfo, userProfileChats, toggleBlockUserModal } = useUserProfileContext();
		const blockUser = useBlockUser(isFnf);
		const userName = `${userProfileInfo?.firstName} ${userProfileInfo?.lastName}`;
		const firstChatId = userProfileChats?.[0]?.channelId;

		function handleSubmit( form ) {
			const begins = new Date().toISOString();
			const ends = add(new Date(), form.indefinitely ? { years: 10 } : { days: form.days }).toISOString();
			blockUser.mutate(
				{
					id: userProfileInfo.id,
					firstName: userProfileInfo.firstName,
					lastName: userProfileInfo.lastName,
					comment: form.note,
					reason: form.reason,
					begins: begins,
					ends: ends,
					blockedBy: {
						id: user.id,
						firstName: user.firstName,
						lastName: user.lastName,
					},
				},
				{
					onSuccess: () => {
						toggleBlockUserModal();
						toast.success('User blocked successfully');
						queryClient.setQueryData(
							[QUERY_KEYS.USER_PROFILE_INFO, userProfileInfo.id],
							( oldData ) => ({ ...oldData, isBlocked: true }));
						queryClient.setQueryData(
							[QUERY_KEYS.USER_CHATS, userProfileInfo.id],
							( oldData ) => {
								const newData = oldData?.map(( chat ) => {
									return {
										...chat,
										isChannelBlock: true,
										begins,
										blockedUntil: ends,
										blockedBy: `${user.firstName} ${user.lastName}`,
									};
								});
								return newData;
							});
						queryClient.setQueryData(
							[QUERY_KEYS.CHANNEL_INFO, firstChatId],
							( oldData ) => {
								return {
									...oldData,
									ChannelData: {
										...oldData.ChannelData,
										isChannelBlock: true,
									},
								};
							});
					},
				},
			);
		};

		return (
			<Modal className={styles.modal} open={open} onClose={onClose}>
				<ModalTitle text={`Block ${toTitleCase(userName)}`} />
				<ModalDescription text='You are blocking this user from all chats' />
				<Form
					className={styles.form}
					initialValues={INITIAL_VALUES}
					onSubmit={handleSubmit}
				>
					<DaysInput
						name='days'
						postLabel='days'
						indefinitelyName='indefinitely'
						label={`Block ${toTitleCase(userProfileInfo?.firstName)} until`}
						min={1}
						max={999}
					/>
					<SwitchInput
						className={styles.form__switcher}
						name='indefinitely'
						label='Block indefinitely'
						color='primary'
					/>
					<SelectInput
						name='reason'
						label='Blocking reason'
						placeholder='Choose a reason'
						options={BLOCKING_REASONS}
						variant='outlined'
						outlined
						required
					/>
					<MultilineInput
						className={styles.form__note}
						name='note'
						label='Internal notes'
						placeholder='Type here'
						helperText={`Optional (not visible ${
							userProfileInfo.__typename === USER_CATEGORY.INMATE ? 'to inmate' : 'for the user'
						})`}
						maxLength={500}
					/>
					<Grid
						classes={{ root: styles.form__submit }}
						direction='row-reverse'
						justifyContent='space-between'
						wrap='nowrap'
						container
					>
						<Grid justifyContent='flex-end' wrap='nowrap' container item>
							<Button
								className={styles.button}
								onClick={onClose}
								display='secondary'
								disabled={blockUser.isLoading}
							>
								Cancel
							</Button>
							<SubmitButton loading={blockUser.isLoading} />
						</Grid>
						<FormLoader loading={blockUser.isLoading} />
					</Grid>
				</Form>
			</Modal>
		);
	}
;

export default BlockUser;

const SubmitButton = ( { loading = false } ) => {
	const { formState, getFieldState } = useFormContext();
	const dayState = getFieldState('days');

	return (
		<Button
			className={styles.button}
			type='submit'
			display='primary'
			disabled={!formState?.dirtyFields?.reason || dayState.error || loading}
		>
			Block
		</Button>
	);
};
