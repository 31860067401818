import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DatePicker.module.scss';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { Calendar } from 'assets/icons';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';
import { fromEvent } from 'rxjs';
import { useSurveysContext } from 'modules/surveys/context';


const DatePicker = ({ label, minDate, maxDate, name, dateInputFormat = 'MMM dd, yyyy', disableMask = true, ...props }) => {
  const { register, setValue, watch, trigger } = useFormContext();
  const { setIsDateRangeValid } = useSurveysContext();
  const { ref } = register(name);
  const popper = useRef();

  const [open, toggleOpen] = useToggler();
  const value = watch(name);

  const handleChange = (date) => {
    setValue(name, date);
    trigger(name);
  };

  const handleError = (error) => {
    console.log('error is ', error);
    if(error === null){
      setIsDateRangeValid(true);
      return;
    }
    setIsDateRangeValid(false);
  };

  useEffect(() => {
    const sub = fromEvent(document, 'mousedown').subscribe((event) => {
      if (popper.current && !popper.current.contains(event.target)) {
        toggleOpen(false);
      }
    });

    return () => sub.unsubscribe();
  }, [popper]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        ref={ref}
        value={value ? value : null}
        name={name}
        onChange={handleChange}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        open={open}
        onClose={toggleOpen}
        onOpen={toggleOpen}
        disableMaskedInput={disableMask}
        inputFormat={dateInputFormat}
        onError={(error) => handleError(error)}
        components={{
          OpenPickerIcon: Calendar,
        }}
        PopperProps={{
          disablePortal: true,
          ref: popper,
        }}
        // eslint-disable-next-line no-unused-vars
        renderInput={(params) => {
          return (
            <TextField 
              classes={{ root: styles.input }} 
              {...params} 
              fullWidth
            />
          );
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  name: PropTypes.string.isRequired,
  dateInputFormat: PropTypes.string,
  disableMask: PropTypes.bool,
};

export default DatePicker;
