import { useGraphql } from '../context';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../utils';

export default function UseChannelInfo( channelId = '' ) {
	const api = useGraphql();
	const shouldExecuteQuery = channelId !== '';

	function getChannelInfo() {
		return api.controller.channel.info({ id: channelId });
	}

	const channelInfoQuery = useQuery({
		queryKey: [QUERY_KEYS.CHANNEL_INFO, channelId],
		queryFn: getChannelInfo,
		enabled: shouldExecuteQuery,
	});

	return channelInfoQuery;

}
