import { useMemo } from 'react';

import SaveElementModal from 'shared/save-element-modal';
import getInitialName from 'shared/save-element-modal/getInitialName';

import { toast } from 'react-toastify';
import { useChatContext } from 'modules/users-chats/context';
import { useUserProfileContext } from 'modules/users-chats/hooks';
import { useSaveChat } from 'hooks';
import { useAuthContext } from 'modules/auth/context';
import { queryClient } from 'utils/queryClient';

import { QUERY_KEYS } from 'utils';

export default function SaveChatModal() {
	const { saveChatModal, toggleSaveChatModal, channelInfo } = useChatContext();
	const { userId } = useUserProfileContext();
	const { user } = useAuthContext();
	const saveChat = useSaveChat();
	const channelId = channelInfo?.ChannelData?.id || '';
	const channelMembers = channelInfo?.ChannelData?.members || [];

	let payload = {
		docUserInfo: {
			data: { ...user },
		},
		ref: {
			id: channelId,
			info: '',
			notes: '',
			owner_id: userId,
		},
	};
	const initialValues = useMemo(
		() => ({
			info: getInitialName('channel', channelMembers),
		}),
		[channelMembers],
	);

	function onSubmitHandler( form ) {
		return saveChat.mutate(
			{
				...payload, ref: {
					...payload.ref,
					info: form.info,
				},
			},
			{
				onSuccess: () => {
					toggleSaveChatModal();
					toast.success('Chat saved successfully');
					queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_BOOKMARKS] });
				},
			},
		);
	}

	return (
		<SaveElementModal
			open={saveChatModal}
			onClose={toggleSaveChatModal}
			title='Save Chat'
			formInitValues={initialValues}
			onFormSubmit={onSubmitHandler}
		/>
	);
}
