import { useAuthContext } from 'modules/auth/context';
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
const UserContext = createContext({});

const initUserContext = () => {
  const { strictPermissionCheck } = useAuthContext();

  const hasPermission = {
    blockFnF: strictPermissionCheck('FNF#WRITE#blockFnF'),
    unblockFnF: strictPermissionCheck('FNF#WRITE#unblockFnF'),
    blockInmate: strictPermissionCheck('INMATE#WRITE#blockInmate'),
    unblockInmate: strictPermissionCheck('INMATE#WRITE#unblockInmate'),
  };

  return {
    hasPermission,
  };
};

const UserProvider = ({ children, ...props }) => {
  const { selectedUser, selectUser, hasPermission, userInfo, loading } =
    initUserContext(props);

  const contextValue = useMemo(
    () => ({
      selectedUser,
      selectUser,
      hasPermission,
      userInfo,
      loading,
    }),
    [selectedUser, selectUser, hasPermission],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);

  return context;
};

export { UserProvider, useUserContext };
