import React, { useMemo } from 'react';
import styles from './CreateStaff.module.scss';

import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';

const SelectAllButton = ({ name, listName }) => {
  const { watch, setValue, trigger } = useFormContext();
  const authContext = useAuthContext();

  const list = authContext[listName];

  const value = watch(name);

  const allSelected = useMemo(
    () =>
      list.reduce((acc, curr) => {
        if (!value[curr.id]) return false;
        else return acc;
      }, true),
    [list, value]
  );

  const handleClick = () => {
    const newValue = list.reduce((acc, curr) => {
      acc[curr.id] = allSelected ? false : true;
      return acc;
    }, {});

    setValue(name, newValue);
    trigger();
  };

  return (
    <p className={styles.selectAll} onClick={handleClick}>
      {allSelected ? 'Deselect all' : 'Select all'}
    </p>
  );
};

export default SelectAllButton;
