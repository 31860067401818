import React from 'react';
import { useAdminSettingsContext } from '../context';
import { PermissionsList } from './PermissionsList';

const PermissionsTab = () => {
  const { roleSelected } = useAdminSettingsContext();

  return roleSelected ? <PermissionsList /> : null;
};


export default PermissionsTab;
