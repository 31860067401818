import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import NoCellIcon from '@mui/icons-material/NoCell';

import { Lock } from 'assets/icons';

export default function AvatarBadge( { showContent, avatarStatus, children } ) {
	return (
		<Badge
			overlap='circular'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			badgeContent={showContent ? <BadgedContent status={avatarStatus} /> : null}
			sx={{ zIndex: 70 }}
		>
			{children}
		</Badge>
	);
}

function BadgedContent( { status } ) {
	const { sxBox, sxLockIcon } = useDefaultContentStyles();
	return (
		<Box sx={sxBox}>
			{status === 'blocked' ? <Lock sx={sxLockIcon} /> : null}
		</Box>
	);
}

function useDefaultContentStyles() {
	return {
		sxBox: {
			width: 24,
			height: 24,
			bgcolor: 'customPalette.error',
			border: '2px solid white',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		sxLockIcon: {
			color: 'white',
			width: 16,
			height: 16,
		},
	};
}
