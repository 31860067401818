import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import BookmarkList from './BookmarkList';
import ErrorBoundary from 'shared/error-boundary';

const BookmarkDrawer = ( { open, onClose } ) => {
	return (
		<Drawer anchor={'right'} open={open} onClose={onClose} sx={sxDrawer}>
			<ErrorBoundary level='section' textVariant='small' imageSize='small'>
				<DrawerHeader>
					<Typography variant='bigBoldBlack' fontSize={24}>
						Saved
					</Typography>
					<IconButton onClick={onClose}>
						<ChevronRightIcon />
					</IconButton>
				</DrawerHeader>
				<BookmarkList onClose={onClose} />
			</ErrorBoundary>
		</Drawer>
	);
};

export default BookmarkDrawer;

const DrawerHeader = styled('div')(( { theme } ) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0 20px',
	...theme.mixins.toolbar,
}));

const sxDrawer = () => {
	return {
		zIndex: 1000,
		'& .MuiDrawer-paper': {
			width: '400px',
		},
	};
};
