import styles from './FilesList.module.scss';
import format from 'date-fns/format';

const FileFooter = (document , showFacility) => {
    const { displayName, by, createdAt, facilityName } = document;

    return(
      <div className={styles.file__footer}>
        <span className={styles.file__name}>
          {displayName}
        </span>
        <span className={styles.file__date}>
          Uploader: {`${by.firstName} ${by.lastName}`}
        </span>
        <span className={styles.file__date}>
          Upload Date: {format(Date.parse(createdAt), 'MMM dd, yyyy')}
        </span>
        <span className={styles.file__date}>
         {showFacility && `Sent To: ${facilityName}`}
        </span>
      </div>
    );
};

  export default FileFooter;