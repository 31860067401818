import { Box } from '@mui/material';

import SentimentsCharts from 'modules/users-chats/user-details/profile-tab/profile-charts/SentimentsCharts';
import FlaggedCharts from 'modules/users-chats/user-details/profile-tab/profile-charts/FlaggedCharts';
import InvitationsCharts from 'modules/users-chats/user-details/profile-tab/profile-charts/InvitationsCharts';
import BlockUser from 'modules/users-chats/block-user';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import UnblockUser from 'modules/users-chats/unblock-user';

export default function FnfProfile() {
	const { sxBoxTop, sxBoxBottom, sxBoxWrapper } = useFnfProfileStyles();
	return (
		<Box sx={sxBoxWrapper}>
			<Box sx={sxBoxTop}>
				<SentimentsCharts />
			</Box>
			<Box sx={sxBoxBottom}>
				<FlaggedCharts />
				<InvitationsCharts />
			</Box>
		</Box>
	);
}

function useFnfProfileStyles() {
	return {
		sxBoxWrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		sxBoxTop: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			ml: 2,
		},
		sxBoxBottom: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
		},
	};
}
