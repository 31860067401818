import Message from './Message';

import { useChatContext } from 'modules/users-chats/context';
import { useQuery } from 'hooks';

const MessagesList = ({ messages, listRef }) => {
  const { userId } = useQuery();
  const { currentMember } = useChatContext();
  return (
    <>
      {messages.map((item, index) => {
        return (
          <div key={index}>
            <Message
              listRef={listRef}
              key={index}
              userId={userId || currentMember?.id}
              prevMessage={messages[index - 1]}
              nextMessage={messages[index + 1]}
              messages={messages}
              currentMessage={item}
              isMessageBlock={Boolean(item?.statusData?.reason)}
              {...item}
            />
          </div>
        );
      })}
    </>
  );
};

export default MessagesList;
