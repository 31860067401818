import styles from './UnblockUser.module.scss';

import Grid from '@mui/material/Grid';

import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';

import { MultilineInput } from 'shared/inputs';
import { useAuthContext } from 'modules/auth/context';

import { USER_CATEGORY } from 'modules/users-chats/constants';
import { QUERY_KEYS, toTitleCase } from 'utils';
import { queryClient } from 'utils/queryClient';
import { useUnblockUser } from 'modules/users-chats/hooks';
import { toast } from 'react-toastify';

const INITIAL_VALUES = {
	note: '',
};

const UnblockUser = ( { open, onClose, isFnf } ) => {
		const { user } = useAuthContext();
		const { userId, userProfileInfo, userProfileChats, toggleUnBlockUserModal } = useUserProfileContext();
		const unBlockUser = useUnblockUser(isFnf);
		const userName = `${userProfileInfo?.firstName} ${userProfileInfo?.lastName}`;
		const firstChatId = userProfileChats?.[0]?.channelId;

		function handleSubmit( form ) {
			unBlockUser.mutate(
				{
					id: userProfileInfo.id,
					updatedReason: form.note,
					updatedBy: {
						id: user.id,
						firstName: user.firstName,
						lastName: user.lastName,
					},
				},
				{
					onSuccess: () => {
						toggleUnBlockUserModal();
						toast.success('User unblocked successfully');
						queryClient.setQueryData(
							[QUERY_KEYS.USER_PROFILE_INFO, userId],
							( oldData ) => ({ ...oldData, isBlocked: false }));
						queryClient.setQueryData(
							[QUERY_KEYS.USER_CHATS, userId],
							( oldData ) => {
								const newData = oldData?.map(( chat ) => {
									return {
										...chat,
										isChannelBlock: false,
									};
								});
								return newData;
							});
						queryClient.setQueryData(
							[QUERY_KEYS.CHANNEL_INFO, firstChatId],
							( oldData ) => {
								return {
									...oldData,
									ChannelData: {
										...oldData.ChannelData,
										isChannelBlock: false,
									},
								};
							});
					},
				});
		};

		return (
			<Modal className={styles.modal} open={open} onClose={onClose}>
				<ModalTitle text={`Unblock ${toTitleCase(userName)}`} />
				<Form initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
					<MultilineInput
						className={styles.note}
						name='note'
						label='Internal notes'
						placeholder='Type here'
						helperText={`Optional (not visible ${
							userProfileInfo.__typename === USER_CATEGORY.INMATE ? 'to inmate' : 'for the user'
						})`}
						maxLength={500}
					/>
					<Grid
						direction='row-reverse'
						justifyContent='space-between'
						wrap='nowrap'
						container
					>
						<Grid justifyContent='flex-end' wrap='nowrap' container item>
							<Button
								className={styles.button}
								onClick={onClose}
								display='secondary'
								disabled={unBlockUser.isLoading}
							>
								Cancel
							</Button>
							<Button
								className={styles.button}
								type='submit'
								display='primary'
								disabled={unBlockUser.isLoading}
							>
								Unblock
							</Button>
						</Grid>
						<FormLoader loading={unBlockUser.isLoading} />
					</Grid>
				</Form>
			</Modal>
		);
	}
;

export default UnblockUser;
