import { Box } from '@mui/material';
import styles from './Layouts.module.scss';

export default function ListContainer( { children } ) {
	return (
		<Box
			sx={{
				display: 'flex',
				height: 'calc(100% - 100px)', // 70px is the height of the tabs container
				justifyContent: 'center',
				width: '100%',
			}}
		>
			<Box
				className={styles.listContainer}
				sx={{
					padding: 1,
					width: '90%',
					overflow: 'hidden auto',
				}}
			>

				{children}
			</Box>
		</Box>
	);
}