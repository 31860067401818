import SvgIcon from '@mui/material/SvgIcon';

const HooverBookmark = ( { color, ...props } ) => {
	return (
		<SvgIcon width={24} height={24} viewBox='6 3 12 20' fill='none' {...props}>
			<path
				d='M7 6C7 4.89543 7.89543 4 9 4H15C16.1046 4 17 4.89543 17 6V19L12 15L7 19V6Z'
				stroke={color || 'currentColor'}
				strokeWidth='2'
				fill='none'
			/>
		</SvgIcon>
	);
};

export default HooverBookmark;
