import React from 'react';
import styles from './CreateRole.module.scss';

import RolePermissionInput from '../role-input';

import { useAdminSettingsContext } from '../context';

const RolesInputList = () => {
	const { appPermissions } = useAdminSettingsContext();

	return (
		<div className={styles.form__permissions}>
			{appPermissions.map(( permission ) => {
				return (
					<RolePermissionInput
						key={permission.value}
						label={permission.label}
						name={permission.value}
						permissionTree={permission}
					/>
				);
			})}
		</div>
	);
};

export default RolesInputList;
