import React from 'react';
import styles from './PrintRequestsSelection.module.scss';

import Button from '@mui/material/Button';

import { Print } from 'assets/icons';
import { If } from 'shared/utilities';
import { usePrintRequestsSectionContext } from 'modules/print-request-section/hooks';

const PrintRequestsSelection = ( props ) => {
	const { selectedRowsCount } = props;
	const { togglePrintSingleRequestModal } = usePrintRequestsSectionContext();

	function printIconClickHandler( e ) {
		e.preventDefault();
		togglePrintSingleRequestModal();
	}

	return (
		<div className={styles.wrapper}>
			<p className={styles.text}>
				<If
					condition={selectedRowsCount === 0}
					otherwise={<span>{`${selectedRowsCount} Selected`}</span>}>
					<span>Selected: 0</span>
				</If>
			</p>
			<If condition={selectedRowsCount > 0}>
				<Button
					classes={{ root: styles.action }}
					sx={{ color: '#101010' }}
					onClick={printIconClickHandler}
				>
					<Print />
				</Button>
			</If>
		</div>
	);
};

export default PrintRequestsSelection;
