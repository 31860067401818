import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import FnfProfile from './fnf-profile/FnfProfile';
import InmateProfile from './inmate-profile/InmateProfile';
import ProfileChartFilterMenu from './profile-charts/ProfileChartFilterMenu';
import AnalyticsModal from 'shared/analytics-modal/AnalyticsModal';
import InvitationModal from 'shared/invitation-modal/InvitationModal';
import DataSource from '../../../../components/data-source-core/DataSource';

import { If } from 'shared/utilities';
import { getUserType, QUERY_KEYS } from 'utils';
import { useUserProfileContext } from 'modules/users-chats/hooks';
import { UserProfileContext } from 'modules/users-chats/user-details/context/UserProfileContext';

export default function ProfileTab() {
  const {
    userProfileInfo,
    openFlaggedMessagesModal,
    toggleFlaggedMessagesModal,
    selectedOption,
    dateRangeFilter,
    selectedAnalyticModalConfig,
    sentimentSelectedRangeFilter,
    setSentimentSelectedRangeFilter,
  } = useUserProfileContext();
  const isFnf = getUserType(userProfileInfo?.id) === 'FNF';
  const isInmate = getUserType(userProfileInfo?.id) === 'INMATE';

  const closeModalAndResetState = () => {
    setSentimentSelectedRangeFilter(null);
    toggleFlaggedMessagesModal();
  };

  return (
    <ProfileTabContainer>
      <ChartsDateFilters>
        <ProfileChartFilterMenu />
      </ChartsDateFilters>
      <UserProfileContainer>
        <If condition={isFnf}>
          <FnfProfile />
        </If>
        <If condition={isInmate}>
          <InmateProfile />
        </If>
      </UserProfileContainer>
      <AnalyticsModal
        openModal={openFlaggedMessagesModal}
        toggleModal={closeModalAndResetState}
        currentSelectedOption={selectedOption}
        sectionDateRangeFilter={dateRangeFilter}
        sentimentSelectedRangeFilter={sentimentSelectedRangeFilter}
        selectedAnalyticModalConfig={selectedAnalyticModalConfig}
      />
      <InvitationModal context={UserProfileContext} />
    </ProfileTabContainer>
  );
}

const ProfileTabContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  height: 'calc(100vh - 170px)',
});

const ChartsDateFilters = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  marginRight: 2,
});

const UserProfileContainer = styled(Box)({
  display: 'flex',
  lexDirection: 'column',
  width: '100%',
  height: '100%',
});

// TODO: add loading status for profile tab
