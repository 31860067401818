import styled from '@emotion/styled';

function PrintRequestSectionLayout( props ) {
	const { SearchComponent, QuickFiltersComponent, MainContentComponent } = props;

	return (
		<SectionMain>
			<SectionHeader>
				<SearchInputContainer>
					{SearchComponent}
				</SearchInputContainer>
				<QuickFiltersContainer>
					{QuickFiltersComponent}
				</QuickFiltersContainer>
			</SectionHeader>
			<SectionMainContent>
				{MainContentComponent}
			</SectionMainContent>
		</SectionMain>
	);
}

export default PrintRequestSectionLayout;

const SectionMain = styled.main`
  /* Box Model */
  display: flex;
  flex-direction: column;
  height: calc(100% - 110px);
  margin: auto;
  overflow: hidden;
  padding: 1rem;
  width: 90%;
`;

const SectionHeader = styled.header`
  /* Box model */
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 1.5rem 2rem 0rem 2rem;
  gap: 1rem;
`;

const QuickFiltersContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const SearchInputContainer = styled.div`
  /* Box model */
  display: flex;
  flex: 1;
`;

const SectionMainContent = styled.section`
  /* Box model */
  display: flex;
  padding: 0 1rem;
  margin-top: 1rem;
`;

