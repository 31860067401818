import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AvatarSkeleton from './AvatarSkeleton';

const InfoSkeleton = ( { amount = 3, size = 250 } ) => {
	let i = 0;
	const arr = [];

	while (i < amount) {
		arr.push(i);
		i++;
	}
	return (
		<Stack mt={3} spacing={6}>
			<AvatarSkeleton />
			{arr.map(( _, index ) => {
				return <SingleInfo key={index} size={size} />;
			})}
		</Stack>
	);
};

export default InfoSkeleton;

const SingleInfo = ( { size } ) => {
	return (
		<Box>
			<Skeleton variant='rounded' width={size} height={20} />
			<Skeleton
				variant='rounded'
				width={size}
				height={20}
				sx={{ marginTop: '10px' }}
			/>
			<Skeleton
				variant='rounded'
				width={size + 50}
				height={5}
				sx={{ marginTop: '10px' }}
			/>
		</Box>
	);
};
