import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Image = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3.75C4.30964 3.75 3.75 4.30964 3.75 5V19C3.75 19.5943 4.16479 20.0918 4.72067 20.2187L15.4697 9.46967C15.7626 9.17678 16.2374 9.17678 16.5303 9.46967L20.25 13.1893V5C20.25 4.30964 19.6904 3.75 19 3.75H5ZM21.75 5C21.75 3.48122 20.5188 2.25 19 2.25H5C3.48122 2.25 2.25 3.48122 2.25 5V19C2.25 20.5188 3.48122 21.75 5 21.75H19C20.5188 21.75 21.75 20.5188 21.75 19V5ZM20.25 15.3107L16 11.0607L6.81066 20.25H19C19.6904 20.25 20.25 19.6904 20.25 19V15.3107Z"
      fill="currentColor"
    />
    <path
      d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Image;
