import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';

export default function HighlightLink( { substring, string, sxString, sxHighlighter = DEFAULT_STYLES, onClick } ) {
	const useStyles = makeStyles(sxHighlighter);
	const classes = useStyles();

	const highlightSubstring = ( str ) => {
		if (substring && string) {
			const regex = new RegExp(substring, 'gi');
			return str.replace(regex, ( match ) => `<span class='${classes.highlighted}'>${match}</span>`);
		}
		return str;
	};

	const highlightedString = highlightSubstring(string);

	return (
		<Link
			component='p'
			underline='hover'
			dangerouslySetInnerHTML={{ __html: highlightedString }}
			onClick={onClick}
			sx={{ fontSize: '18px', color: 'customPalette.grey4', cursor: 'pointer', ...sxString }} />
	);
};

const DEFAULT_STYLES = {
	highlighted: {
		color: 'black',
		fontWeight: 'bold',
	},
};