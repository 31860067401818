import { useGraphql } from '../../../../context';
import { useMutation } from '@tanstack/react-query';

export default function useDeleteSurvey() {
  const api = useGraphql();

  function deleteSurveyMutation(payload) {
    return api.controller.doc.deleteSavedSurvey(payload);
  }

  return useMutation({ mutationFn: deleteSurveyMutation });
}
