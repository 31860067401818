import { useInfiniteQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useSearchTextMessages( term ) {
	const api = useGraphql();

	function searchTextMessages( { pageParam = null } ) {
		return term !== '' ? api.controller.search.text({
			term,
			limit: 40,
			sortBy:[{
				by: 'clientKey',
				order: 'desc',
			}],
			nextPage: pageParam,
		}) : null;
	}

	const searchTextMessagesQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_TEXT_MESSAGES, term],
		queryFn: searchTextMessages,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
	});

	return searchTextMessagesQuery;
}
