import { forwardRef } from 'react';

import { Button } from '@mui/material';

const FilterButton = forwardRef(({ onClick, checked, children }) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        minWidth: 100,
        height: 45,
        fontSize: '0.8rem',
        textTransform: 'none',
        color: checked ? 'customPalette.grey1' : 'customPalette.grey4',
        borderColor: checked ? 'customPalette.grey1' : 'customPalette.grey4',
        border: '1.5px solid',
        borderRadius: '10px',
        backgroundColor: checked && 'customPalette.grey7',
      }}
    >
      {children}
    </Button>
  );
});

export default FilterButton;
