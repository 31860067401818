import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import SentimentTrend from './sentiment-trend';
import ModerationActivity from './moderation-activity';
import CommunicationsMetrics from './communication-metrics';
import InvitationsChart from './invitations/InvitationsChart';

export default function ChatCharts() {
  return (
    <ChatChartContainer>
      <SentimentTrend />
      <BottomChartsContainer>
        <CommunicationsMetrics />
        <ModerationActivity />
        <InvitationsChart />
      </BottomChartsContainer>
    </ChatChartContainer>
  );
}

const ChatChartContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const BottomChartsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
});
