import React from 'react';
import styles from './PrintRequestList.module.scss';

import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import format from 'date-fns/format';

import PrintActionIcon from '../PrintActionIcon';
import { PasswordModal, PrintRequestModal } from '../print-requests-modal';

import { makeStyles } from '@material-ui/core/styles';
import { PrintRequestsCheckbox } from '../PrintRequestsCheckBox';
import { useToggler } from 'hooks';
import { toTitleCase } from 'utils';

const RequestItem = ( { id, type, createdAt, code, inmateEid, ...props } ) => {
	const [modal, toggleModal] = useToggler();
	const [passwordModal, togglepasswordModal] = useToggler();

	const abreviatedId = id.replace('PRINT^REQUEST^', 'PR-');
	const creationDate = format(new Date(createdAt), 'MM/dd/yyyy HH:mm a');
	const titleType = toTitleCase(type.replace('_', ' ')).replace(' ', '.');

	return (
		<Grid
			container
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			className={styles.request}
		>
			<Grid item xs={4}>
				<PrintRequestsCheckbox name={abreviatedId} />
				<span>{abreviatedId}</span>
			</Grid>

			<Grid item xs={3}>
				<span>{titleType}</span>
			</Grid>
			<Grid item xs={3}>
				<span>{creationDate}</span>
			</Grid>
			<Grid item xs={2} container justifyContent='flex-end'>
				<ShowTempPasswordIcon onClickHandler={togglepasswordModal} />
				<PrintActionIcon id={id} onClickHandler={toggleModal} />
			</Grid>
			{modal ? <PrintRequestModal
				open={modal}
				onClose={toggleModal}
				requestArray={{ [abreviatedId]: true }}
				createdAt={creationDate}
				inmateEid={inmateEid}
				{...props}
			/> : null}
			<PasswordModal
				open={passwordModal}
				onClose={togglepasswordModal}
				code={code}
			/>
		</Grid>
	);
};

export default RequestItem;

const ShowTempPasswordIcon = ( { onClickHandler } ) => {
	const classes = useStyles();

	return (
		<Tooltip title='Show Password' classes={{ tooltip: classes.tooltip }}>
			<IconButton
				style={{
					backgroundColor: 'transparent',
					borderRadius: '8px',
				}}
				onClick={onClickHandler}
			>
				<VisibilityIcon
					sx={{
						fill: '#9191A1',
					}}
				/>
			</IconButton>
		</Tooltip>
	);
};

const useStyles = makeStyles(() => ({
	tooltip: {
		backgroundColor: '#EBEBEF',
		color: '#101010',
		borderRadius: '8px',
		width: '103px',
		height: '48px',
		fontSize: '12px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
