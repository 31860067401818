import React from 'react';

export const Down = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.7071 8.70711L16.2929 12.2929C16.9229 12.9229 16.4767 14 15.5858 14L8.41421 14C7.52331 14 7.07714 12.9229 7.70711 12.2929L11.2929 8.70711C11.6834 8.31658 12.3166 8.31658 12.7071 8.70711Z"
      fill="#101010"
    />
  </svg>
);

export const TreeMiddle = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <rect width="40" height="40" fill="white" />
    <path
      d="M18 8L18 32"
      stroke="#EBEBEF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M18 20H29"
      stroke="#EBEBEF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const TreeEnd = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <rect width="40" height="40" fill="white" />
    <path
      d="M18 8.99977L18 12.9998C18 16.8658 21.134 19.9998 25 19.9998L29 19.9998"
      stroke="#EBEBEF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
