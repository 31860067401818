import ProtectedApp from './ProtectedApp';
import UnprotectedApp from './UnprotectedApp';

import Switch from './components/switch/Switch';
import ErrorBoundary from 'shared/error-boundary';

import { AppLoader } from './shared/loaders';
import { useAuthContext } from './modules/auth/context';

const App = () => {
  const {
    isAuth,
    facilities,
    facilitiesHousingUnitInfo,
    onGetCurrentUser,
    initialLoadQuery,
  } = useAuthContext();

  const enableRenderProtectedApp =
    facilities.length && facilitiesHousingUnitInfo.length;

  const enableRenderUnprotectedApp =
    !isAuth && !onGetCurrentUser.isLoading && !initialLoadQuery?.isLoading;

  return (
    <ErrorBoundary level="global">
      <Switch>
        <Switch.Case condition={isAuth && enableRenderProtectedApp}>
          <ProtectedApp />
        </Switch.Case>
        <Switch.Case condition={enableRenderUnprotectedApp}>
          <UnprotectedApp />
        </Switch.Case>
        <Switch.Default>
          <AppLoader />
        </Switch.Default>
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
