import React, { useMemo, useCallback } from 'react';
import styles from './CreateStaff.module.scss';

import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Stepper, { StepperContent } from 'shared/stepper';
import { StepperProvider } from 'context';
import InfoStep from './InfoStep';
import FaciltiesStep from './FacilitiesStep';
import RolesStep from './RolesStep';

import * as yup from 'yup';
import { from, switchMap } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { AddDOCUserCmd, DOCUserSecurityUpdateCmd } from 'kiwi-sdk';
import { useGraphql } from 'context';
import { useToggler } from 'hooks';
import { useRoleHandler } from '../hooks';
import { useAuthContext } from '../../auth/context';
import { useAdminSettingsContext } from '../context';

const STEPS = [
  {
    id: 'info',
    label: 'Personal info',
    validation: yup.object({
      firstName: yup.string().trim().max(100).required(),
      lastName: yup.string().trim().max(100).required(),
      email: yup.string().trim().email().max(100).required(),
    }),
    component: InfoStep,
  },
  {
    id: 'facilities',
    label: 'Facility access',

    validation: yup.object({
      facilities: yup
        .object({})
        .test('required', 'Facilities is mandatory', (val) =>
          Object.values(val).some((value) => value)
        ),
    }),
    component: FaciltiesStep,
  },
  {
    id: 'roles',
    label: 'Roles',
    validation: yup.object({
      roles: yup
        .object({})
        .test('required', 'Role is mandatory', (val) =>
          Object.values(val).some((value) => value)
        ),
    }),
    component: RolesStep,
  },
];

const CreateStaff = ({ open, onClose }) => {
  const [loading, toggleLoading] = useToggler();
  const { facilities, agency } = useAuthContext();
  const { rolesArray: roles, setRolesArray } = useAdminSettingsContext();
  const { roleUsersCountHandler } = useRoleHandler();
  const api = useGraphql();

  function createStaff({ form, agency }) {
    return from(
      api.send(
        AddDOCUserCmd({
          firstName: form.firstName,
          lastName: form.lastName,
          username: form.email,
          agencyId: agency.id,
        })
      )
    );
  }

  function assignFacilitiesRoles({ form, facilities, roles, user }) {
    const payload = {
      docUserInfo: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone || '',
      },
      newFacilities: [],
      newRoles: [],
    };

    Object.entries(form.facilities).forEach(([id, assign]) => {
      if (assign) {
        payload.newFacilities.push({
          id,
          info: facilities.find((item) => item.id === id)?.name,
        });
      }
    });

    Object.entries(form.roles).forEach(([id, assign]) => {
      if (assign) {
        payload.newRoles.push({
          id,
          info: roles.find((item) => item.id === id)?.name,
        });
      }
    });

    return from(api.send(DOCUserSecurityUpdateCmd(payload)));
  }

  const initialValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      facilities: facilities.reduce((acc, curr) => {
        acc[curr.id] = false;
        return acc;
      }, {}),
      roles: roles?.reduce((acc, curr) => {
        acc[curr.id] = false;
        return acc;
      }, {}),
    }),
    [facilities, roles]
  );

  const onSubmit = useCallback(
    (form) => {
      toggleLoading(true);
      createStaff({ form, agency })
        .pipe(
          tap((res) => {
            if (res.status === 'ERROR') {
              const errorMessage = res.errors[0].message;
              toast.error(`Error creating account: ${errorMessage}`);
              throw new Error('Failed to create account');
            }
          }),
          pluck('data'),
          switchMap((user) =>
            assignFacilitiesRoles({ form, user, facilities, roles })
          )
        )
        .subscribe({
          next: (res) => {
            if (res.status === 'ERROR') {
              return toast.error('Failed to create account');
            } else {
              toast.info('Account created!');
              setRolesArray(roleUsersCountHandler(res.data?.operations, roles));
              onClose();
            }
            toggleLoading(false);
          },
          error: (err) => {
            console.log(err);
            toggleLoading(false);
          },
        });
    },
    [facilities, roles, agency]
  );

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <ModalTitle text="New account" onClose={onClose} />
      <StepperProvider steps={STEPS}>
        <Stepper />
        <Form
          className={styles.form}
          initialValues={initialValues}
          onSubmit={onSubmit}
          type="stepper-form"
        >
          <StepperContent loading={loading} />
        </Form>
      </StepperProvider>
    </Modal>
  );
};

export default CreateStaff;
