import React, { useCallback } from 'react';
import styles from './MediaModal.module.scss';

import Grid from '@mui/material/Grid';

import Thumbnail from './Thumbnail';
import MessageInfo from './MessageInfo';
import BlockFormInputs from './BlockFormInputs';

import { ModalTitle } from 'shared/modal';

import { from } from 'rxjs';
import { toast } from 'react-toastify';
import { DOCBlockImgCmd, DOCUnBlockImgCmd } from 'kiwi-sdk';
import { useBlockImageMessage, useToggler } from 'hooks';
import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';
import { useChatContext } from 'modules/users-chats/context';
import { setMessageState, changeMsg } from './setMessageState';
import { formatPhoneNumber, toTitleCase } from '../../../utils';
import { generatePath, useHistory } from 'react-router';
import routes from '../../../routing/routes';
import query from 'query-string';
import { useUserProfileContext } from '../hooks';

const initialValues = {
	reason: '',
	note: '',
};

const BlockForm = ( props ) => {
	const {
		isBlocked,
		id,
		channel,
		thumbnailSrc,
		owner_info,
		owner_id,
		owner_eid,
		owner_type,
		createdAt,
		onClose,
		mediaType,
		setMediaItem,
		setAudioItem,
		clientKey,
	} = props;

	const mediaTypesElements = {
		voice: 'Audio',
		image: 'Photo',
		video: 'Video',
		text: 'Text',
	};

	const { user } = useAuthContext();
	const [loading, toggleLoading] = useToggler();
	const { userId } = useUserProfileContext();
	const api = useGraphql();
	const history = useHistory();
	const blockImageMessageMutation = useBlockImageMessage(isBlocked);

	const date = new Date(createdAt);

	function handleBlockSubmit( form ) {
		toggleLoading(true);
		blockImageMessageMutation.mutate(
			{
				channelId: channel,
				msgId: id,
				comment: form.note || 'No comment',
				reason: form.reason || '',
				by: {
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
				},
			},
			{
				onSuccess: ( res ) => {
					toast.success(`The ${mediaTypesElements[mediaType]} has been blocked successfully`);
					history.push(
						`${generatePath(routes.chat, {
							userId: userId,
							chatId: channel,
						})}?${query.stringify({
							messageId: clientKey,
						})}`,
					);
					onClose();
				},
				onError: ( err ) => {
					toast.error(`Failed to block ${mediaTypesElements[mediaType]}`);
					onClose();
				},
			});
	}

	return (
		<>
			<ModalTitle
				className={styles.modal__title}
				text={`${isBlocked ? 'Unblock' : 'Block'} ${
					mediaTypesElements[mediaType]
				}`}
			/>
			<Grid
				classes={{ root: styles.preview }}
				alignItems='center'
				wrap='nowrap'
				container
			>
				<Thumbnail type={mediaType} src={thumbnailSrc} />

				<MessageInfo
					owner_info={toTitleCase(owner_info)}
					owner_id={formatPhoneNumber(owner_eid)}
					date={date}
					type={mediaType}
				/>
			</Grid>
			<BlockFormInputs
				isBlocked={isBlocked}
				initialValues={initialValues}
				handleBlockSubmit={handleBlockSubmit}
				owner_type={owner_type}
				onClose={onClose}
				loading={loading}
			/>
		</>
	);
};

export default BlockForm;
