import AccountOption from './AccountOption';
import SupportOption from './SupportOption';
import LogoutOption from './LogoutOption';
import ShowBookmarksOption from './ShowBookmarksOption';

import routes from 'routing/routes';

import { generatePath } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';

export default function ProfileOptions() {
  const { user } = useAuthContext();

  return [
    {
      label: 'Account',
      Item: AccountOption,
      to: `${generatePath(routes.singleStaff, { staffId: user.id })}`,
    },
    {
      label: 'Bookmarks',
      Item: ShowBookmarksOption,
    },
    {
      label: 'Suport',
      Item: SupportOption,
      to: `${generatePath(routes.support)}`,
    },
    { label: 'Logout', Item: LogoutOption },
  ];
};

