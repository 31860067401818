export const Chat = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M2 18L2.05521 17.9447V9.98154C2.05521 8.93339 2.26149 7.89549 2.6621 6.92714C3.06275 5.95876 3.64999 5.07888 4.39029 4.33774C5.13059 3.59659 6.00946 3.00867 6.97672 2.60756C7.94397 2.20645 8.98067 2 10.0276 2C13.8033 2 16.966 4.66843 17.7906 8.20848C17.1981 8.07028 16.5905 8 15.9793 8C15.8691 8 15.7594 8.00226 15.6503 8.00672C14.8197 5.67579 12.6041 4 10.0276 4C9.24363 4 8.46726 4.15459 7.74284 4.455C7.0184 4.75542 6.36 5.19581 5.80531 5.75114C5.25063 6.30646 4.81049 6.96588 4.51018 7.69174C4.20985 8.41771 4.05521 9.1958 4.05521 9.98154V16L8.00005 16L8 16.0276C8 16.6943 8.08355 17.3569 8.24762 18H2Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9586 22H15.9793C15.1947 22.0018 14.4175 21.8486 13.6922 21.5493C12.9668 21.25 12.3075 20.8104 11.7521 20.2556C11.1967 19.7008 10.7561 19.0418 10.4554 18.3163C10.1548 17.5908 10 16.8131 10 16.0276C10 12.7216 12.677 10 15.9793 10C16.7645 10 17.542 10.1548 18.2675 10.4557C18.9929 10.7565 19.6521 11.1974 20.2073 11.7533C20.7625 12.3092 21.2029 12.9691 21.5034 13.6954C21.8039 14.4216 21.9586 15.2 21.9586 15.9862V22ZM15.9748 20L15.9793 20L19.9586 20V15.9862C19.9586 15.4625 19.8555 14.9438 19.6553 14.46C19.4552 13.9762 19.1619 13.5367 18.7923 13.1667C18.4226 12.7967 17.984 12.5033 17.5013 12.3031C17.0187 12.103 16.5015 12 15.9793 12C13.8017 12 12 13.806 12 16.0276C12 16.5504 12.103 17.0679 12.303 17.5506C12.5031 18.0334 12.7962 18.4717 13.1655 18.8405C13.5348 19.2094 13.9731 19.5016 14.4551 19.7005C14.9371 19.8994 15.4535 20.0012 15.9748 20Z"
      fill="#101010"
    />
  </svg>
);

export const Message = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.0311 20L12.0333 20C13.0785 20.0023 14.114 19.7983 15.0803 19.3996C16.0467 19.0008 16.925 18.4151 17.6651 17.6759C18.4052 16.9367 18.9924 16.0585 19.3931 15.0917C19.7937 14.1248 20 13.0883 20 12.0414C20 7.62454 16.4221 4 12.0311 4C10.9847 4 9.94861 4.20633 8.98187 4.60723L8.59881 3.6835L8.98187 4.60723C8.01511 5.00813 7.13662 5.59578 6.39659 6.33666C5.65656 7.07754 5.06947 7.95714 4.66891 8.92531C4.26836 9.89352 4.06212 10.9313 4.06212 11.9792V20L12.0311 20Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export const User = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="7" r="4" stroke="#262626" strokeWidth="2" />
    <path
      d="M17.665 21H6.33502C5.24269 21 4.51666 19.8698 4.97081 18.8764L6.13168 16.337C6.78253 14.9132 8.20411 14 9.76957 14H14.2304C15.7959 14 17.2175 14.9132 17.8683 16.337L19.0292 18.8764C19.4833 19.8698 18.7573 21 17.665 21Z"
      stroke="#262626"
      strokeWidth="2"
    />
  </svg>
);

export const Search = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9999 20.9999L16.6499 16.6499"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmptyPlaceholder = (props) => (
  <svg width="102" height="73" viewBox="0 0 102 73" fill="none" {...props}>
    <path
      d="M16.3335 13.9999C16.3335 11.4226 18.4228 9.33325 21.0002 9.33325H35.0002C37.5775 9.33325 39.6668 11.4226 39.6668 13.9999V44.3333L28.0002 34.9999L16.3335 44.3333V13.9999Z"
      stroke="#DADAE2"
      strokeWidth="4.66667"
    />
    <rect x="56" y="9" width="46" height="10" rx="5" fill="#DADAE1" />
    <rect x="56" y="36" width="46" height="10" rx="5" fill="#DADAE1" />
    <rect x="16" y="63" width="86" height="10" rx="5" fill="#DADAE1" />
  </svg>
);
