import React, { useCallback, useEffect, useState } from 'react';
import styles from './BulletinsSearch.module.scss';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';
import BackIcon from '@mui/icons-material/ArrowBack';

import { useBulletinsContext } from '../context';
import { useSearchParams } from 'hooks';

const BulletinsSearch = () => {
  const { searchInput, searchBulletins, loading } = useBulletinsContext();
  const { searchParams } = useSearchParams();

  const [input, setInput] = useState('');

  useEffect(() => {
    setInput(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (searchParams.search && searchParams.search !== searchInput) {
      searchBulletins(searchParams.search);
    }
  }, [searchParams.search]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (input.trim() && input.trim() !== searchInput) {
      searchBulletins(input);
    }
  };

  const handleChange = useCallback((evt) => {
    setInput(evt.target.value);
  }, []);

  const handleClearSearch = () => {
    setInput('');
    searchBulletins('');
  };

  const searchDone = !loading && searchInput;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <TextField
        classes={{ root: styles.input__wrapper }}
        value={input}
        onChange={handleChange}
        placeholder='Search for announcements'
        variant='outlined'
        InputProps={{
          classes: {
            root: styles.input,
            focused: styles['input--focused'],
            notchedOutline: styles.input__outline,
          },
          startAdornment: (
            <InputAdornment position='start'>
              {searchDone ? (
                <BackIcon
                  className={styles.clearIcon}
                  onClick={handleClearSearch}
                />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default BulletinsSearch;
