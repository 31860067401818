import React, { useCallback } from 'react';
import styles from './RolesList.module.scss';

import Grid from '@mui/material/Grid';

import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Button from 'shared/button';

import RolesInputList from '../create-role/RolesInputList';

import { from } from 'rxjs';
import { TextInput } from 'shared/inputs';

import {
  useCheckDuplicate,
  useInitialPermissionsValues,
  usePermissionsPayload,
} from '../hooks';

import { toast } from 'react-toastify';

import { useGraphql } from 'context';
import { useAdminSettingsContext } from '../context';
import { useFormContext } from 'react-hook-form';

const SubmitButton = ({ loading }) => {
  const { formState } = useFormContext();
  return (
    <Button
      type="submit"
      disabled={formState.errors.title || loading}
      display="primary"
    >
      Save
    </Button>
  );
};

const EditRole = ({ open, onClose }) => {
  const api = useGraphql();
  const { agency, roleSelected, rolesArray } = useAdminSettingsContext();
  const [initialPermissionValues] = useInitialPermissionsValues(
    roleSelected?.permissions
  );

  const roleSelectedName = roleSelected?.name;
  const roleSelectedId = roleSelected?.id;

  const editRoleCmd = (payload) => {
    return from(api.controller.agency.updateRolePermissions(payload));
  };

  const handleSubmit = useCallback(
    (form) => {
      const editRolePayload = {
        roleId: roleSelectedId,
        agencyId: agency.id,
        permissions: usePermissionsPayload(form),
      };
      const [permissionsAlreadyExist, roleNameWithPermissions] =
        useCheckDuplicate(
          rolesArray,
          editRolePayload.permissions,
          'permissions'
        );

      if (permissionsAlreadyExist) {
        toast.error(
          `These permissions already exist in the ${roleNameWithPermissions} Role`
        );
      }

      if (roleSelectedId === 'ROLE^MASTER') {
        toast.error("You can't edit this role");
      } else {
        editRoleCmd(editRolePayload).subscribe({
          next: () => {
            toast.info('The role has been successfully edited');
            onClose();
          },
          error: (err) => {
            console.error(err);
            toast.error('Something went wrong with editing a this role');
          },
        });
      }
    },
    [roleSelected]
  );

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <ModalTitle className={styles.title} text="Edit role" />
      <Form
        className={styles.form}
        onSubmit={handleSubmit}
        initialValues={initialPermissionValues}
      >
        <div className={styles.form__content}>
          <TextInput
            className={styles.form__input}
            name={'title'}
            label={roleSelectedName || 'Title Role'}
            helperText="Up to 100 char."
            fullWidth
            disabled
          />
          <p className={styles.form__subtitle}>Permissions</p>
          <div className={styles.form__permissions}>
            <RolesInputList />
          </div>
        </div>
        <Grid
          classes={{
            root: styles.form__actions,
          }}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          container
        >
          <div />
          <Grid item>
            <Button
              className={styles.cancelButton}
              onClick={onClose}
              display="secondary"
            >
              Cancel
            </Button>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};

export default EditRole;
