import React from 'react';
import styles from './Button.module.scss';
import cs from 'classnames';

import MaterialButton from '@mui/material/Button';

const Button = ({ className, display, children, ...props }) => (
  <MaterialButton
    classes={{
      root: cs(
        styles.button,
        display === 'primary' && styles['button--primary'],
        display === 'secondary' && styles['button--secondary'],
        className
      ),
    }}
    {...props}
  >
    {children}
  </MaterialButton>
);

export default Button;
