export function getInitialMediaTypeFilter( types, activeTypesState ) {
	const result = {};

	types.forEach(type => {
		const isActive = activeTypesState.includes(type);
		result[type] = isActive;
	});

	return result;
}
