import { useQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';

import { QUERY_KEYS } from 'utils';

export default function useChatMetricsInvitations(
  agencyId,
  facilityId,
  dateRangeFilter
) {
  const api = useGraphql();

  function getInvitationsAnalysis() {
    return api.controller.analytics.inviteAggregateV2({
      agencyId,
      facilityId,
      dateRange: dateRangeFilter,
    });
  }

  const invitationsAnalysisQuery = useQuery({
    queryKey: [
      QUERY_KEYS.CHAT_METRICS_INVITATIONS,
      agencyId,
      facilityId,
      dateRangeFilter,
    ],
    queryFn: getInvitationsAnalysis,
  });
  return invitationsAnalysisQuery;
}
