import { useQuery } from '@tanstack/react-query';
import { useGraphql } from '../context';
import { QUERY_KEYS } from '../utils';

export default function useUserProfileInfo( id = '' ) {
	const api = useGraphql();

	function getUserProfileInfo() {
		return id.indexOf('FNF^') === 0
			? api.controller.fnf.info({ id })
			: api.controller.inmate.info({ id });
	}

	const profileHistoryQuery = useQuery({
		queryKey: [QUERY_KEYS.USER_PROFILE_INFO, id],
		queryFn: getUserProfileInfo,
		enable: !!id,
	});

	return profileHistoryQuery;
}
