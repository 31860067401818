import { useRef } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import Suspense from 'shared/suspense-list';
import ListContainer from 'modules/global-search/layouts/ListContainer';
import VerticalScroller from 'shared/vertical-scroller';
import GridLayoutLoader from 'shared/loaders/search-results/GridLayoutLoader';
import VoiceMessageCard from 'modules/global-search/components/voice-card';
import ErrorBoundary from 'shared/error-boundary';

import { useParams } from 'react-router';
import { getFlattedMsgsList } from 'utils';
import useSearchVoiceMessages from '../hooks/useSearchVoiceMessages';

export default function VoiceMessagesTab() {
	const { searchTerm } = useParams();
	const { data, isFetching, fetchNextPage, hasNextPage } = useSearchVoiceMessages(searchTerm);
	const list = useRef(null);
	const classes = useStyles();
	const iterableList = getFlattedMsgsList(data);

	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<Suspense
				total={iterableList?.length || 0}
				loading={isFetching}
				fallback={<GridLayoutLoader />}
				textNoResults='No text messages found'>
				<ListContainer>
					<VerticalScroller
						loadNext={fetchNextPage}
						hasNext={hasNextPage}
						loadingNext={isFetching}>
						<Box ref={list} className={classes.gridContainer}>
							{iterableList.map(( item, index ) => (
								<Box key={index} className={classes.box}>
									<VoiceMessageCard
										id={item?.owner_id}
										clientKey={item?.clientKey}
										searchTerm={searchTerm}
										text={item?.text}
										date={item?.createdAt}
										channelId={item?.channel}
										ownerId={item?.owner_id}
										owner={item?.owner_info}
										recipient={item?.recipient_info}
										statusData={item?.statusData}
										medialLocation={item?.mediaLocation} />
								</Box>
							))}
						</Box>
					</VerticalScroller>
				</ListContainer>
			</Suspense>
		</ErrorBoundary>
	)
		;
}

const useStyles = makeStyles(( theme ) => ({
	gridContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, 320px [col-start])',
		gridColumnGap: theme.spacing(3),
		gridRowGap: theme.spacing(3),

	},
	box: {
		alignItems: 'flex-start',
		backgroundColor: 'customPalette.grey5',
		display: 'flex',
		justifyContent: 'center',
		height: 250,
		width: 320,
	},
}));
