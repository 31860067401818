import React, { ReactNode } from 'react';

import { twc } from 'react-twc';

import Form from 'shared/form';

/**
 * Renders a login component.
 *
 * @param {Object} LoginProps - The props object for the Login component.
 * @param {ReactNode} LoginProps.children - The children nodes to be rendered inside the Login component.
 *
 * @return {ReactNode} The rendered login component with the provided children nodes.
 */

export default function Login({ children }: LoginProps) {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {children}
    </div>
  );
}

Login.SubmitButton = twc.button`flex w-full h-10 md:h-12 px-5 py-2 md:py-3 justify-center rounded-lg bg-grey-1 text-white text-sm md:text-base font-medium leading-normal border border-grey-1 shadow-sm hover:bg-white hover:text-grey-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition duration-150 ease-in-out`;

Login.Title = twc.div`sm:mx-auto sm:w-full sm:max-w-sm text-center`;

Login.Form = twc(Form)`space-y-6`;

Login.FormContainer = twc.div`mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-[550px] bg-white px-6 py-6  shadow-lg ring-1 ring-grey-4 sm:rounded-lg md:px-12 md:py-12`;

Login.ForgotPasswordLink = ({ href, children }: ForgotPasswordLinkProps) => (
  <div className="mt-4 text-end">
    <a
      className="text-xs md:text-sm font-medium leading-6 text-grey-2 hover:text-grey-1"
      href={href}
    >
      {children}
    </a>
  </div>
);

Login.EmailInput = twc.div``;

Login.PasswordInput = twc.div``;

interface LoginProps {
  children: ReactNode;
}

interface ForgotPasswordLinkProps {
  href: string;
  children: ReactNode;
}
