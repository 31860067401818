import { Grid, Typography, IconButton, DialogTitle } from '@mui/material';

import { Close } from 'assets/icons';

export default function AvatarModalTitle(props) {
  const { text, onClose } = props;
  return (
    <DialogTitle>
      <Grid container justifyContent='space-between' mt={1.5}>
        <Typography variant='bigBoldBlack' fontSize={20} component='h4'>
          {text}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </Grid>
    </DialogTitle>
  );
}