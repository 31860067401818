import SvgIcon from '@mui/material/SvgIcon';
const Chats = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 6.5H5C4.72386 6.5 4.5 6.72386 4.5 7V17C4.5 17.2761 4.72386 17.5 5 17.5H8.39445C9.08544 17.5 9.76096 17.7045 10.3359 18.0878L11.7226 19.0123C11.8906 19.1243 12.1094 19.1243 12.2773 19.0123L13.6641 18.0878C14.239 17.7045 14.9146 17.5 15.6056 17.5H19C19.2761 17.5 19.5 17.2761 19.5 17V7C19.5 6.72386 19.2761 6.5 19 6.5ZM5 5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H8.39445C8.7893 19 9.17531 19.1169 9.50385 19.3359L10.8906 20.2604C11.5624 20.7083 12.4376 20.7083 13.1094 20.2604L14.4962 19.3359C14.8247 19.1169 15.2107 19 15.6056 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5Z"
      fill="#686873"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 12.75H16.5C16.9142 12.75 17.25 13.0858 17.25 13.5C17.25 13.9142 16.9142 14.25 16.5 14.25H7.5C7.08579 14.25 6.75 13.9142 6.75 13.5C6.75 13.0858 7.08579 12.75 7.5 12.75Z"
      fill="#686873"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 9.75H16.5C16.9142 9.75 17.25 10.0858 17.25 10.5C17.25 10.9142 16.9142 11.25 16.5 11.25H7.5C7.08579 11.25 6.75 10.9142 6.75 10.5C6.75 10.0858 7.08579 9.75 7.5 9.75Z"
      fill="#686873"
    />
  </SvgIcon>
);

export default Chats;
