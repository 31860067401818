import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import Button from 'shared/button';
import FormLoader from 'shared/form-loader';

import { useFormContext } from 'react-hook-form';

export default function BlockChatModalActions( props ) {
	const { onClick, mutation } = props;
	return (
		<ActionsContainer wrap='nowrap'>
			<ButtonsContainer>
				<Button
					onClick={onClick}
					display='secondary'>
					Cancel
				</Button>
				<SubmitButton loading={mutation.isLoading} />
			</ButtonsContainer>
			<FormLoader loading={mutation.isLoading} />
		</ActionsContainer>
	);
}

const SubmitButton = ( { loading = false } ) => {
	const { formState } = useFormContext();
	return (
		<Button
			type='submit'
			display='primary'
			disabled={!formState?.dirtyFields?.reason || loading}>
			Block
		</Button>
	);
};

const ActionsContainer = styled(Box)({
	marginTop: 32,
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ButtonsContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'flex-end',
});
