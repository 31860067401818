import React, { useEffect } from 'react';
import styled from '@mui/material/styles/styled';

import { useFormContext } from 'react-hook-form';

const SaveModalInput = ( { name, label, ...props } ) => {
	const { register, setFocus } = useFormContext();

	useEffect(() => {
		setFocus(name);
	}, []);

	return (
		<ModalInputContainer>
			<StyledLabel>{label}</StyledLabel>
			<StyledInput
				autoComplete='off'
				{...register(name)}
				{...props}
			/>
		</ModalInputContainer>
	);
};

export default SaveModalInput;

const ModalInputContainer = styled('div')({
	marginBottom: 24,
});

const StyledInput = styled('input')(( { theme } ) => ({
	outline: 'none',
	boxSizing: ' border-box',
	width: '100%',
	padding: '12px 16px',
	border: '1px solid',
	borderColor: theme.palette.customPalette.grey1,
	borderRadius: '8px',
	fontSize: 16,
	lineHeight: '24px',
	color: theme.palette.customPalette.grey3,
	'&:focus': {
		borderColor: theme.palette.customPalette.green1,
		color: theme.palette.customPalette.grey1,
	},
}));

const StyledLabel = styled('label')(( { theme } ) => ({
	fontSize: 16,
	lineHeight: '24px',
	color: theme.palette.customPalette.grey1,
	marginBottom: 8,
	display: 'block',
}));
