import React from 'react';
import styles from './MediaModal.module.scss';

import Button from 'shared/button';
const CancelButton = (props) => {
  return (
    <Button
      className={styles.form__cancelButton}
      onClick={props.onClose}
      display="secondary"
      disabled={props.loading}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
