import { Fragment } from 'react';
import styles from './PrintRequestsTable.module.scss';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import TableNavigation from './table-navigation';

import {
	HeaderCellContainer,
	StyledBodyTd,
	StyledHeaderTh,
	StyledTable,
	StyledTableBody,
	StyledTableHeader,
	StyledTableRow,
	TableContainer,
} from 'modules/print-request-section/styled-components';

import { If } from 'shared/utilities';
import { usePrintRequestsSectionContext } from 'modules/print-request-section/hooks';

function PrintRequestsTable( props ) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page = [],
		prepareRow,
		state,
		...paginationProps
	} = props;

	const { togglePrintSingleRequestModal } = usePrintRequestsSectionContext();

	function handleRowClick( row ) {
		row.toggleRowSelected();
		togglePrintSingleRequestModal();
	};

	return (
		<Fragment>
			<TableContainer className={styles.table_container}>
				<StyledTable {...getTableProps()}>
					<StyledTableHeader>
						{headerGroups.map(( headerGroup ) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(( column ) => (
									<StyledHeaderTh {...column.getHeaderProps(column.getSortByToggleProps)}>
										<HeaderCellContainer>
											{column.render('Header')}
											<SortIndicator isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
										</HeaderCellContainer>
									</StyledHeaderTh>
								))}
							</tr>
						))}
					</StyledTableHeader>
					<StyledTableBody  {...getTableBodyProps}>
						{page.map(( row ) => {
							prepareRow(row);
							return (
								<StyledTableRow {...row.getRowProps()}>
									{row.cells.map(( cell, index ) => {
										return (
											<StyledBodyTd
												{...cell.getCellProps()}
												onClick={( e ) => {
													e.stopPropagation();
													index > 0 && handleRowClick(row);
												}}
											>
												{cell.render('Cell')}
											</StyledBodyTd>);
									})}
								</StyledTableRow>
							);
						})}
					</StyledTableBody>
				</StyledTable>
				<TableNavigation pageIndex={state.pageIndex} page={page} {...paginationProps} />
			</TableContainer>
		</Fragment>
	);
};

export default PrintRequestsTable;

function SortIndicator( { isSorted, isSortedDesc } ) {
	return (
		<If condition={isSorted}>
			{isSortedDesc
				? <ArrowDownwardIcon sx={{ width: 20, opacity: 0.5 }} />
				: <ArrowUpwardIcon sx={{ width: 20, opacity: 0.5 }} />}
		</If>
	);

}
