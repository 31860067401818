import { Fragment } from 'react';

import styled from '@mui/material/styles/styled';
import add from 'date-fns/add';

import Form from 'shared/form';
import Modal, { ModalTitle } from 'shared/modal';
import BlockChatModalActions from 'shared/block-chat-modal/BlockChatModalActions';

import { toast } from 'react-toastify';

import { DaysInput, MultilineInput, SelectInput, SwitchInput } from 'shared/inputs';
import { useAuthContext } from 'modules/auth/context';
import { If } from 'shared/utilities';
import { useBlockChat, useToggler } from 'hooks';

import { queryClient } from 'utils/queryClient';
import { BLOCKING_REASONS } from 'modules/users-chats/constants';
import { QUERY_KEYS } from 'utils';

export default function BlockChatModal( props ) {
	const { channelInfoData, ElementModalControl } = props;

	const { user } = useAuthContext();
	const [openBlockChatModal, toggleOpenBlockChatModal] = useToggler(false);
	const blockChat = useBlockChat();
	const { id, initiator } = channelInfoData || {};

	return (
		<Fragment>
			<ElementModalControl onClick={toggleOpenBlockChatModal} />
			<If condition={openBlockChatModal}>
				<BlockChatModalContainer open={openBlockChatModal}>
					<ModalTitle text={'Block chat'} />
					<StyledForm
						initialValues={INITIAL_FORM_VALUES}
						onSubmit={onSubmitHandler(blockChat, { id, user, ownerId: initiator }, toggleOpenBlockChatModal)}>
						<DaysInput
							name='days'
							postLabel='days'
							indefinitelyName='indefinitely'
							label='Block chat for'
							min={0}
							max={999} />
						<StyledSwitchInput
							name='indefinitely'
							label='Block indefinitely'
							color='primary' />
						<SelectInput
							name='reason'
							label='Blocking reason'
							placeholder='Choose a reason'
							options={BLOCKING_REASONS}
							variant='outlined'
							outlined
							required />
						<StyledMultilineInput
							name='note'
							label='Internal notes'
							placeholder='Type here'
							helperText='Optional (not visible for the user)'
							maxLength={500}
						/>
						<BlockChatModalActions onClick={toggleOpenBlockChatModal} mutation={blockChat} />
					</StyledForm>
				</BlockChatModalContainer>
			</If>
		</Fragment>
	);
}

const onSubmitHandler = ( mutation, { id, user, ownerId }, closeModal ) => ( form ) => {
	console.log('form', form);
	const begins = new Date().toISOString();
	const ends = add(
		new Date(),
		form.indefinitely ? { years: 10 } : { days: form.days },
	).toISOString();

	mutation.mutate(
		{
			id,
			reason: form.reason,
			comment: form.note,
			begins,
			ends,
			blockedBy: {
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
			},
			indefinite: form.indefinitely,
		},
		{
			onSuccess: () => {
				closeModal();
				toast.success('Chat blocked successfully');
				queryClient.setQueryData(
					[QUERY_KEYS.USER_CHATS, ownerId],
					( oldData ) => {
						if (!oldData) return;
						const newData = oldData.map(( chat ) => {
							if (chat.channelId === id) {
								const newChatData = {
									...chat,
									isChannelBlock: true,
									channelBlockReason: form.reason,
									blockedBy: `${user.id};${user.firstName};${user.lastName}`,
									begins: begins,
									blockedUntil: ends,
								};
								return newChatData;
							}
							return chat;
						});
						return newData;
					});
				queryClient.setQueryData(
					[QUERY_KEYS.CHANNEL_INFO, id],
					( oldData ) => {
						const newData = {
							...oldData,
							ChannelData: {
								...oldData.ChannelData,
								isChannelBlock: true,
							},
						};
						return newData;
					});
			},
		},
	);
};

const INITIAL_FORM_VALUES = {
	days: 1,
	indefinitely: false,
	note: '',
	reason: 'Other',
};

const BlockChatModalContainer = styled(Modal)({
	width: 584,
});

const StyledForm = styled(Form)(( { theme } ) => ({
	padding: '16px 0 0 0',
}));

const StyledSwitchInput = styled(SwitchInput)({
	marginTop: 16,
});

const StyledMultilineInput = styled(MultilineInput)({
	marginTop: 40,
});
