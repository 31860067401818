import React from 'react';
import styles from '../SurveyResults.module.scss';

import SurveySummary from './SurveySummary';
import SearchResponse from './SearchResponse';
const SearchAndSumary = () => {
  return (
    <div>
      <SurveySummary />
      <div className={styles.sidebar__divider} />
      {/*<SearchResponse />*/}
    </div>
  );
};

export default SearchAndSumary;
