import useControlActions from 'modules/users-chats/hooks/useControlActions';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import BroadcastMsgModal from './BroadcastMsgModal';

import { useAuthContext } from 'modules/auth/context';

const getControlActions = () => {
	const { user } = useAuthContext();
	const { userProfileInfo } = useUserProfileContext();
	const { setLostMsgMutation, setFoundMutation, lockScreenMutation, broadcastMsgMutation } = useControlActions();
	const hasDevice = userProfileInfo?.emmId !== null;

	const docName = `${user.firstName} ${user.lastName}`;

	return [
		{
			label: 'Lost Mode',
			value: 'lostMode',
			checkState: false,
			inputModal: true,
			modal: {
				ModalComponent: BroadcastMsgModal,
				modalTitle: 'Lost Device Message',
				handleSubmit: ( form ) =>
					setLostMsgMutation({
						message: form.message,
						devices: [userProfileInfo?.emmId],
					}),
			},
			disableAction: !hasDevice,
		},
		{
			label: 'Found Mode',
			value: 'foundMode',
			checkState: false,
			inputModal: false,
			sdkMutation: () => setFoundMutation({ devices: [userProfileInfo?.emmId] }),
			disableAction: !hasDevice,
		},
		{
			label: 'Lock Screen',
			value: 'lockScreen',
			checkState: false,
			inputModal: false,
			sdkMutation: () => lockScreenMutation({ devices: [userProfileInfo?.emmId] }),
			disableAction: !hasDevice,
		},
		{
			label: 'Broadcast Messages',
			value: 'broadcastMessages',
			checkState: false,
			inputModal: true,
			modal: {
				ModalComponent: BroadcastMsgModal,
				modalTitle: 'Broadcast Message',
				handleSubmit: ( form ) =>
					broadcastMsgMutation({
						message: form.message,
						docName: docName,
						devices: [userProfileInfo?.emmId],
					}),
			},
			disableAction: !hasDevice,
		},
	];
};

export default getControlActions;


