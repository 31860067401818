import { Box, Typography } from '@mui/material';
import HighlightText from 'shared/text-highlighter';
import { formatPhoneNumber, toTitleCase } from 'utils';
import HighlightLink from 'shared/text-highlighter/HighlightLink';

export default function CardInfo( { firstName = '', lastName = '', eid = '', searchTerm = '', userType = '', onClick } ) {
	const { sxInfoTypeBox } = useCardInfoStyles();
	return (
		<Box sx={sxInfoTypeBox}>
			<HighlightLink
				substring={searchTerm}
				string={toTitleCase(`${firstName} ${lastName}`)}
				sxHighlighter={HIGHLIGHTED_STYLE}
				onClick={onClick}
				sxString={{ fontWeight: 'regular', fontSize: 18, lineHeight: '22px' }}
			/>
			<Typography variant='regularGrey3' component='h4' fontSize={14}>
				{userType === 'FNF' ? formatPhoneNumber(eid) : `ID: ${eid}`}
			</Typography>
		</Box>
	);
}

function useCardInfoStyles() {
	return {
		sxInfoTypeBox: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginTop: 5,
			position: 'absolute',
			width: '225px',
		},
	};
};

const HIGHLIGHTED_STYLE = {
	highlighted: {
		backgroundColor: '#F8A80C',
		color: '#101010',
	},
};