import React, { useEffect, useMemo, useState } from 'react';
import styles from './BulletinsFilter.module.scss';

import MuiButton from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterAlt';

import Modal, { ModalTitle } from 'shared/modal';
import Button from 'shared/button';
import Form from 'shared/form';
import { DateRangePicker } from 'shared/inputs/date-picker';

import { useAuthContext } from 'modules/auth/context';
import { useToggler } from 'hooks';
import BlockLabel from 'modules/forms/forms-filters/BlockLabel';
import { CheckboxListInput } from 'shared/inputs';
import { useFormContext } from 'react-hook-form';
import { Skeleton } from 'shared/skeleton';
import { useBulletinsContext } from '../context';
import formatFilterData from 'utils/formatFilterData';

const ResetButton = ({ initialValues }) => {
  const { reset } = useFormContext();

  return (
    <Button
      display="secondary"
      onClick={() => {
        reset(initialValues);
      }}
    >
      Reset
    </Button>
  );
};

const SubmitButton = () => {
  return (
    <Button type="submit" display="primary">
      Apply Filters
    </Button>
  );
};

const convertToIsoDates = (fromDate, toDate) => {
  const convertedDates = {
    from: '',
    to: '',
  };
  if (fromDate) {
    convertedDates.from = new Date(fromDate).toISOString();
  }
  if (toDate) {
    convertedDates.to = new Date(toDate).toISOString();
  }
  return convertedDates;
};

const formatDateRangeFilter = (fromDate, toDate, filterData) => {
  const convertedDates = convertToIsoDates(fromDate, toDate);
  const dateRangeObject = {
    from: convertedDates.from,
    to: convertedDates.to,
  };

  filterData['dateRange'] = dateRangeObject;
  return filterData;
};

const handleFormCheckboxSubmitBuilder =
  (setFilterState, filterBulletins, toggleOpen) => (filterData) => {
    setFilterState(filterData);
    const filteredBulletins = formatFilterData(filterData);
    filterBulletins(
      formatDateRangeFilter(filterData.from, filterData.to, filteredBulletins)
    );
    toggleOpen(false);
  };

const BulletinsFilter = () => {
  const { facilities, facilitiesHousingUnitInfo } = useAuthContext();
  const [recipientOptions, setRecipientOptions] = useState({});
  const [loadingHousingUnits, setLoadingHousingUnits] = useState([]);
  const [windowOpen, toggleOpen] = useToggler();

  const {
    checkedFilterState,
    setCheckedFilterState,
    filterState,
    setFilterState,
    filterBulletins,
  } = useBulletinsContext();

  // add filter function too context menu and import here

  const { initialValues } = useMemo(() => {
    const initialValues = {
      facility: {},
      from: '',
      to: '',
    };

    return { initialValues };
  }, []);

  useEffect(() => {
    setRecipientOptions(() => {
      const recipients = facilities.reduce((facilityAcc, currFacility) => {
        initialValues.facility[`${currFacility.id}`] = false;
        facilityAcc.push({
          name: currFacility.name,
          id: currFacility.id,
        });
        return facilityAcc;
      }, []);
      return recipients;
    });
  }, [facilitiesHousingUnitInfo]);

  useEffect(() => {
    if (facilitiesHousingUnitInfo?.length === facilities.length) {
      if (Object.keys(filterState).length === 0) setFilterState(initialValues);
      if (Object.keys(checkedFilterState).length === 0)
        setCheckedFilterState(initialValues);
      setLoadingHousingUnits(false);
    }
  }, [recipientOptions]);

  useEffect(() => {
    setCheckedFilterState(filterState);
  }, [open, filterState]);

  const handleFormCheckboxSubmit = handleFormCheckboxSubmitBuilder(
    setFilterState,
    filterBulletins,
    toggleOpen
  );

  return (
    <>
      <MuiButton
        className={styles.button}
        onClick={toggleOpen}
        startIcon={<FilterIcon />}
      >
        Filters
      </MuiButton>
      <Modal className={styles.modal} onClose={toggleOpen} open={windowOpen}>
        {loadingHousingUnits ? (
          <Skeleton amount={3} />
        ) : (
          <>
            <ModalTitle text="Filters" onClose={toggleOpen} />
            <Form
              initialValues={checkedFilterState}
              onSubmit={handleFormCheckboxSubmit}
            >
              <div className={styles.blocksWrapper}>
                <div className={styles.block}>
                  <BlockLabel name="facility" label="Facility / housing unit" />
                  <div className={styles.block__content}>
                    <CheckboxListInput
                      name="facility"
                      options={recipientOptions}
                      allLabel="All facilities"
                      search
                    />
                  </div>
                </div>
                <div className={styles.block_}>
                  <span className={styles.block__label}>Upload window</span>
                  <DateRangePicker
                    startLabel="Start Date"
                    startName="from"
                    endLabel="End Date"
                    endName="to"
                  />
                </div>
              </div>
              <div className={styles.submit}>
                <ResetButton initialValues={initialValues} />
                <SubmitButton />
              </div>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default BulletinsFilter;
