import React, { useState } from 'react';
import styles from './UserGuide.module.scss';

import { Chat, Dash, AdminSettings } from './sections';
import Button from '@mui/material/Button';
// import { toTitleCase } from '../../../utils/toTitleCase';

const UserGuide = () => {
  const [errorState, setErrorState] = useState(false);

  // if (errorState) {
  //   toTitleCase({});
  // }

  return (
    <div className={styles.userGuide}>
      <h2 className={styles.userGuide__title}>FAQs and User Guide</h2>
      <div className={styles.userGuide__content}>
        <Chat />
        <Dash />
        <AdminSettings />
      </div>
      <Button
        className={styles.userGuide__button}
        variant="contained"
        onClick={() => setErrorState(true)}
      >
        Download User Guide
      </Button>
    </div>
  );
};

export default UserGuide;
