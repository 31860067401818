import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import format from 'date-fns/format';

import { If } from 'shared/utilities';

export default function MessageNotification( props ) {
	const { date = 0, messageReadStatus = 'read' } = props;
	const Sxs = getSxs(messageReadStatus);
	const formatDate = format(new Date(date), 'h:mm a');

	return (
		<Box sx={Sxs.BoxWrapper}>
			<Typography variant='messageDate' component='span'>
				{formatDate}
			</Typography>
			<If
				condition={messageReadStatus === 'sent'}
				otherwise={
					<DoneAllIcon sx={Sxs.ReadMarkIcon} />
				}>
				<DoneIcon sx={Sxs.ReadMarkIcon} />
			</If>
		</Box>
	);
}

function getSxs( status ) {
	return {
		BoxWrapper: {
			display: 'flex',
			marginLeft: 'auto',
			justifyContent: 'flex-end',
			alignItems: 'center',
			gap: 0.5,
		},
		ReadMarkIcon: {
			color: status === 'read' ? 'primary.main' : 'grey.main', fontSize: '16px',
		},
	};
};
