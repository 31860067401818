import React, { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export default function VerticalScroller({ children, ...props }) {
  const {
    reverse,
    hasNext = false,
    hasPrev = false,
    loadingPrev,
    loadingNext,
    loadPrev,
    loadNext,
    Fallback = DefaultLoader,
  } = props;
  const [topRef, topInView] = useInView({ threshold: 0 });
  const [bottomRef, bottomInView] = useInView({ threshold: 0 });

  function LoadNextPage() {
    if (hasNext) return loadNext();
  }

  function LoadPrevPage() {
    if (hasPrev) return loadPrev();
  }

  useEffect(() => {
    if (topInView) {
      reverse ? LoadNextPage() : LoadPrevPage();
    }
    if (bottomInView) {
      reverse ? LoadPrevPage() : LoadNextPage();
    }
  }, [topInView, bottomInView]);

  return (
    <Fragment>
      <div ref={topRef} />
      <div>
        {reverse ? loadingNext && <Fallback /> : loadingPrev && <Fallback />}
      </div>
      {children}
      <div>
        {reverse ? loadingPrev && <Fallback /> : loadingNext && <Fallback />}
      </div>
      <div ref={bottomRef} />
    </Fragment>
  );
}

function DefaultLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginBottom: 1,
        marginTop: 1.5,
      }}
    >
      <CircularProgress size={32} />
    </Box>
  );
}
