export const STANDARD_DATES = [
	{
		label: '24 hours',
		name: 'day',
	},
	{
		label: '7 days',
		name: 'week',
	},
	{
		label: 'Month',
		name: 'month',
	},
	{
		label: '3 Months',
		name: 'months',
	},
];
