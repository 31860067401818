import React from 'react';
import styles from './PrintRequestModal.module.scss';
import Button from 'shared/button';

import Modal, { ModalTitle } from 'shared/modal';
import ContentPreview from './ContentPreview';
import PrintTemplate from '../print-template';
import FormLoader from 'shared/form-loader';

import { from } from 'rxjs';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import { useGraphql } from 'context';
import { useSelectedRequests } from 'modules/users-chats/hooks';
import { useToggler, usePrint } from 'hooks';

const PrintRequestModal = (
	{
		requestArray,
		createdAt,
		open,
		onClose,
		allRequests,
	},
) => {
	const [loading, toggleLoading] = useToggler();
	const { reset } = useFormContext();
	const api = useGraphql();
	const [filteredRequests] = useSelectedRequests(requestArray, allRequests);
	const modalTitle = filteredRequests.length > 1 ? 'Print Batch' : filteredRequests[0]?.id.replace('PRINT^REQUEST^', 'PR-');

	const markRequestsAsPrinted = ( payload ) => {
		return from(api.controller.doc.printRequestsAction(payload));
	};

	const handleRequestAction = ( action ) => {
		toggleLoading(true);
		markRequestsAsPrinted({
			action: action,
			items: filteredRequests,
		}).subscribe({
			next: () => {
				toggleLoading(false);
				onClose();
				toast.success('Operation completed successfully');
				reset({});
			},
			error: ( err ) => {
				console.error(err);
				toggleLoading(false);
				onClose();
				toast.error('Operation failed');
				reset({});
			},
		});
	};

	return (
		<>
			<Modal className={styles.modal} onClose={onClose} open={open}>
				{createdAt ? (
					<span className={styles.info}>{`Print Request at ${createdAt}`}</span>
				) : null}
				<ModalTitle text={modalTitle} onClose={onClose} />
				<p>
					<strong>Details</strong>
				</p>
				<div className={styles.content}>
					{filteredRequests.map(( request ) => {
						return filteredRequests.length > 1 ||
						request.type !== 'TEMP_PASSWORD' ? (
							<ContentPreview key={request?.id} request={request} />
						) : (
							<div
								key={request?.id}
								className={styles.content__single_password}>
								Temporary password print request
							</div>
						);
					})}
				</div>
				<div className={styles.submit}>
					{loading ? <FormLoader /> : null}
					<Button display='secondary' onClick={onClose}>
						Cancel
					</Button>
					<Button
						type='submit'
						display='primary'
						disabled={false}
						onClick={() => {
							usePrint(
								'print',
								handleRequestAction('MARK_AS_PRINTED'),
							);
						}}>
						Print
					</Button>
				</div>
				<div id='print' className={styles.print}>
					<PrintTemplate requests={filteredRequests} />
				</div>
			</Modal>
		</>
	);
};

export default PrintRequestModal;
