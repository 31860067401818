import { useState, Fragment, useMemo } from 'react';

import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import Form from 'shared/form';
import FilterTitle from './FilterTitle';
import FilterForm from './FilterForm';

import { Filter } from 'assets/icons';

import { useToggler } from 'hooks';
import { useChatContext } from 'modules/users-chats/context';

import { MSG_TYPES_INITIAL_VALUES } from 'modules/users-chats/context/FiltersInitialValues';
import { getInitialMediaTypeFilter } from 'utils/getInitialMediaTypeFilter';

export default function TransitionsPopper() {
	const [anchorEl, setAnchorEl] = useState(null);

	const [open, toggleOpen] = useToggler(false);

	const { setFilters, filters } = useChatContext();

	const canBeOpen = open && Boolean(anchorEl);
	const id = canBeOpen ? 'transition-popper' : undefined;

	function handleClick( event ) {
		setAnchorEl(event.currentTarget);
		toggleOpen();
	};

	function handleFormSubmit( form ) {
		const mediaTypeActiveFilters = Object.keys(form).filter(( key ) => form[key]);
		setFilters({ filtersAreActive: true, mediaTypes: mediaTypeActiveFilters });
		toggleOpen();
	}

	const formMediaTypesInitialValues = useMemo(() => getInitialMediaTypeFilter(MEDIA_TYPES, filters?.mediaTypes), [filters?.mediaTypes]);

	return (
		<Fragment>
			<Filter aria-describedby={id} onClick={handleClick} sx={{ fontSize: 18, color: 'customPalette.grey1' }} />
			<Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
				<Form
					initialValues={formMediaTypesInitialValues}
					onSubmit={handleFormSubmit}>
					<FilterContainer elevation={1}>
						<FilterTitle />
						<FilterForm toggleOpen={toggleOpen} />
					</FilterContainer>
				</Form>
			</Popper>
		</Fragment>
	);
}

const FilterContainer = styled(Paper)({
	position: 'absolute',
	width: 295,
	top: 13,
	right: -5,

	zIndex: 2,
	borderRadius: 8,
});

const MEDIA_TYPES = [
	'image',
	'voice',
	'video',
	'text',
];
