import React, { useState, useMemo } from 'react';
import styles from './SurveysList.module.scss';

import Grid from '@mui/material/Grid';
import Form from 'shared/form';
import { Skeleton } from 'shared/skeleton';
import NoResult from 'shared/no-result';
import VirtualScroller from 'shared/virtual-scroller';
import SurveysSearch from './../surveys-search';
import SurveysSelection from './../surveys-selection';
import CreateSurvey from './../create-survey';
import SurveyItem from './SurveyItem';
import SurveyFilters from '../SurveyFilters/Index';
import { AllSurveyCheckbox } from './SurveyCheckbox';

import { useSurveysContext } from 'modules/surveys/context';
import { sortReducer, useToogleSort } from 'hooks/useSort';
import { TableLoader } from 'shared/loaders';
import { Box } from '@mui/material';

const SurveysList = () => {
	const { loading, surveys, searchInput, hasPermission } = useSurveysContext();

	const initialPayload = {
		type: 'date',
		descendent: false,
		key: 'createdAt',
	};
	const [payload, setPayload] = useState(initialPayload);
	const [order, toggleOrder] = useToogleSort(false);

	const sortedSurveys = useMemo(() => {
		toggleOrder();
		return sortReducer(surveys, payload);
	}, [payload, surveys]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<SurveysSearch />
				<SurveyFilters />
			</div>
			<Form className={styles.form} initialValues={{}} shouldUnregister={false}>
				<div className={styles.subheader}>
					<SurveysSelection />
					{hasPermission.createSurvey && <CreateSurvey />}
				</div>
				{loading && !surveys.length ? (
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
						<TableLoader />
					</Box>
				) : !surveys.length ? (
					<NoResult>
						{!searchInput ? (
							<p>There are no surveys.</p>
						) : (
							<p>
								There are no surveys with{' '}
								<span> &quot;{searchInput}&quot; </span>
								in the result.
							</p>
						)}
					</NoResult>
				) : (
					<>
						<Grid classes={{ root: styles.listHeader }} container>
							<Grid
								xs={4}
								alignItems='center'
								container
								item
								onClick={() => {
									setPayload({
										type: 'string',
										descendent: order,
										key: 'name',
									});
								}}
								sx={{ cursor: 'pointer' }}
							>
								<AllSurveyCheckbox />
								Survey name
							</Grid>
							<Grid xs={3} item>
								Recipient
							</Grid>
							<Grid
								xs={2}
								item
								onClick={() => {
									setPayload({
										type: 'number',
										descendent: order,
										key: 'sent',
									});
									toggleOrder();
								}}
								sx={{ cursor: 'pointer' }}
							>
								Completed/ Sent
							</Grid>
							<Grid
								xs={2}
								item
								onClick={() => {
									setPayload({
										type: 'date',
										descendent: order,
										key: 'createdAt',
									});
									toggleOrder();
								}}
								sx={{ cursor: 'pointer' }}
							>
								Date
							</Grid>
						</Grid>

						<VirtualScroller
							loading={loading}
							onLoadNext={() => {
							}}
							total={20}
							items={sortedSurveys}
							renderItem={( item ) => <SurveyItem {...item} />}
						/>
					</>
				)}
			</Form>
		</div>
	);
};

export default SurveysList;
