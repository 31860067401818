import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

export default function HighlightText( { substring = '', string, sxString, sxHighlighter = DEFAULT_STYLES } ) {
	const useStyles = makeStyles(sxHighlighter);
	const classes = useStyles();

	const highlightSubstring = ( str ) => {
		if (substring && string) {
			const regex = new RegExp(substring, 'gi');
			return str.replace(regex, ( match ) => `<span class='${classes.highlighted}'>${match}</span>`);
		}
		return str;
	};

	const highlightedString = highlightSubstring(string);

	return (
		<Typography
			component='p'
			dangerouslySetInnerHTML={{ __html: highlightedString }}
			sx={{ fontSize: '18px', color: 'customPalette.grey4', ...sxString }}
		/>
	);
};

const DEFAULT_STYLES = {
	highlighted: {
		color: 'black',
		fontWeight: 'bold',
	},
};