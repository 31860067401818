import React from 'react';
import styles from './MediaModal.module.scss';

import Grid from '@mui/material/Grid';

import { BlockInfo, UnBlockInfo, FlaggedInfo } from './BlockInfo';

const ModalInfo = ({ statusData, flagData }) => {
  return (
    <div className={styles.info}>
      <Grid container>
        {statusData?.status === 'blocked' ? (
          <BlockInfo {...statusData} />
        ) : null}
        {statusData?.status !== 'blocked' && statusData?.updatedByMetadata ? (
          <UnBlockInfo {...statusData} />
        ) : null}
        {flagData?.status === 'flagged' ? <FlaggedInfo {...flagData} /> : null}
      </Grid>
    </div>
  );
};

export default ModalInfo;
