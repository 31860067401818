import { Box, Typography } from '@mui/material';

export default function EmptyChart() {
	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography component='h3'>
				No data available to display
			</Typography>
		</Box>
	);
}