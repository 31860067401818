import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { Subject, catchError, of, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { useGraphql } from 'context';
import { useSearchParams, useToggler } from 'hooks';
import { useAuthContext } from 'modules/auth/context';

const SurveysContext = createContext({});

const initSurveysContext = () => {
	const api = useGraphql();
	const { paramsController } = useSearchParams();
	const { strictPermissionCheck } = useAuthContext();
	const hasPermission = {
		createSurvey: strictPermissionCheck('SURVEY#WRITE#sendSurvey'),
	};

	const [loadSurveys$] = useState(new Subject());

	const nextPage = useRef(undefined);
	const term = useRef('');
	const filteredSurveys = useRef([]);
	const [isDateRangeValid, setIsDateRangeValid] = useState(true);
	const [loading, toggleLoading] = useToggler(false);
	const [surveys, setSurveys] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [filterState, setFilterState] = useState({});
	const [checkedFilterState, setCheckedFilterState] = useState({});

	// eslint-disable-next-line no-unused-vars
	function loadSurveys( term, filters ) {
		const dateRange = filters?.dateRange;
		const observer = from(
			api.controller.search.survey({
				term,
				filters,
				dateRange,
				limit: 40,
				nextPage: nextPage.current,
			}),
		).pipe(
			catchError(( err ) => {
				console.error(err);
				return of([]);
			}),
		);

		nextPage.current = false;
		return observer;
	}

	useEffect(() => {
		const loadSub = loadSurveys$
		.pipe(
			tap(() => toggleLoading(true)),
			switchMap(( { firstPage, input, filters } ) => {
				return loadSurveys(input, filters).pipe(
					tap(( res ) => {
						nextPage.current = res?.data?.nextPage;
					}),
					map(( result ) => ({ result, payload: { firstPage, input } })),
				);
			}),
		)
		.subscribe(( { result, payload } ) => {
			if (payload.firstPage && result) {
				setSurveys(result.items.survey || []);
			} else if (!result) {
				setSurveys([]);
			} else {
				setSurveys(( state ) => [...state, ...result]);
			}
			toggleLoading(false);
		});

		loadSurveys$.next({ firstPage: true });

		return () => {
			loadSub.unsubscribe();
		};
	}, []);

	const loadNextPage = useCallback(() => {
		if (nextPage.current) {
			loadSurveys$.next({ firstPage: false, input: term.current });
		}
	}, [loadSurveys$]);

	const filterSurveys = useCallback(
		( filterResults ) => {
			filteredSurveys.current = filterResults;
			nextPage.current = undefined;
			setSurveys([]);
			loadSurveys$.next({
				firstPage: true,
				filters: filteredSurveys.current,
				input: term.current,
			});
		},
		[loadSurveys$],
	);

	const searchSurveys = useCallback(
		( input ) => {
			nextPage.current = undefined;
			term.current = input;
			setSurveys([]);
			setSearchInput(input);
			paramsController.set({ search: input });
			loadSurveys$.next({ firstPage: true, input });
		},
		[loadSurveys$],
	);

	return {
		loading,
		surveys,
		setSurveys,
		searchInput,
		loadNextPage,
		searchSurveys,
		filterSurveys,
		filterState,
		setFilterState,
		checkedFilterState,
		setCheckedFilterState,
		hasPermission,
		isDateRangeValid,
		setIsDateRangeValid,
	};
};

const SurveysProvider = ( { children, ...props } ) => {
	const {
		loading,
		surveys,
		setSurveys,
		searchInput,
		loadNextPage,
		searchSurveys,
		filterSurveys,
		filterState,
		setFilterState,
		checkedFilterState,
		setCheckedFilterState,
		hasPermission,
		isDateRangeValid,
		setIsDateRangeValid,
	} = initSurveysContext(props);

	const contextValue = useMemo(
		() => ({
			loading,
			surveys,
			setSurveys,
			searchInput,
			loadNextPage,
			searchSurveys,
			filterSurveys,
			filterState,
			setFilterState,
			checkedFilterState,
			setCheckedFilterState,
			hasPermission,
			isDateRangeValid,
			setIsDateRangeValid,
		}),
		[
			loading,
			surveys,
			setSurveys,
			searchInput,
			loadNextPage,
			searchSurveys,
			filterSurveys,
			filterState,
			setFilterState,
			checkedFilterState,
			setCheckedFilterState,
			hasPermission,
			isDateRangeValid,
			setIsDateRangeValid,
		],
	);

	return (
		<SurveysContext.Provider value={contextValue}>
			{children}
		</SurveysContext.Provider>
	);
};

/**
 * @typedef {Object} SurveyContextType
 * @property {boolean} loading
 * @property {array} surveys
 * @property {string} searchInput
 * @property {function} loadNextPage
 * @property {function} searchSurveys
 *
 */

/**
 * @returns {SurveyContextType}
 */
const useSurveysContext = () => {
	const context = useContext(SurveysContext);

	return context;
};

export { useSurveysContext, SurveysProvider };
