import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FlagIcon from '@mui/icons-material/Flag';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#EBEBEF',
    color: '#101010',
    borderRadius: '8px',
    width: '73px',
    height: '48px',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FlagActionIcon = ({ toggleFlagModal, flagData }) => {
  const classes = useStyles();

  return (
    <>
      <Tooltip title="Flag" classes={{ tooltip: classes.tooltip }}>
        <IconButton sx={sxIconBtn} onClick={toggleFlagModal}>
          {flagData?.status === 'flagged' ? (
            <FlagIcon sx={sxIcon} />
          ) : (
            <FlagOutlinedIcon sx={sxIcon} />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default FlagActionIcon;

const sxIconBtn = () => {
  return {
    bgcolor: 'customPalette.grey6',
    marginRight: 1,
  };
};

const sxIcon = () => {
  return {
    height: 16,
    width: 16,
    color: 'customPalette.grey1',
  };
};
