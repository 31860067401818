import { useEffect, useState } from 'react';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import format from 'date-fns/format';

import { FlagModal } from 'modules/users-chats/flag-modal';
import AudioPlayer from './AudioPlayer';
import AudioTranscription from './AudioTranscription';

import { useMediaSrc, useToggler } from 'hooks';
import { useGraphql } from 'context';
import { BlockAlert } from '../image-message/BlockAlert';

const AudioMessage = (props) => {
  const {
    openModal = false,
    isMessageBlock,
    text,
    createdAt,
    borderRadius,
    mediaLocation,
    statusData,
    isMessageMine,
  } = props;

  const isBlocked = isMessageBlock;

  const isSeen = true;

  const { getImages } = useGraphql();

  const [mediaSrc] = useMediaSrc(getImages)({ itemKey: mediaLocation });

  const [flagModal, toggleFlagModal] = useToggler();
  const [showHoover, setShowHoover] = useState(false);

  useEffect(() => {
    if (openModal) {
      toggleModal(true);
    }
  }, [openModal]);

  return (
    <>
      <BlockAlert
        isMessageBlock={isMessageBlock}
        reason={statusData.reason}
        isMine={isMessageMine}
      />
      <Card
        variant="outlined"
        sx={sxCard({ borderRadius: borderRadius, isBlocked: isBlocked })}
      >
        <CardContent sx={sxCardContent}>
          <AudioPlayer src={mediaSrc} />
          {text ? <AudioTranscription transcription={text} /> : null}
        </CardContent>
        <CardActions sx={sxCardAction(showHoover)} disableSpacing>
          <Box sx={sxNotification}>
            <Typography variant="messageDate" component="span">
              {format(new Date(createdAt), 'h:mm a')}
            </Typography>
            <DoneAllIcon sx={sxReadMark(isSeen)} />
          </Box>
        </CardActions>
        <FlagModal open={flagModal} toggleOpen={toggleFlagModal} {...props} />
      </Card>
    </>
  );
};

export default AudioMessage;

const sxCard = ({ borderRadius, isBlocked }) => {
  return {
    borderRadius: borderRadius || 16,
    maxWidth: 470,
    borderColor: isBlocked ? 'customPalette.error' : 'customPalette.grey5',
    '&:hover': {
      borderColor: isBlocked ? 'customPalette.error' : 'customPalette.grey1',
    },
  };
};

const sxCardContent = () => {
  return {
    '&.MuiCardContent-root': {
      padding: '12px 16px 0px 16px ',
      borderColor: 'customPalette.grey3',
    },
  };
};

const sxCardAction = () => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '4px 16px 8px 16px',
  };
};

const sxReadMark = (isSeen = true) => {
  return { color: isSeen ? 'primary.main' : 'grey.main', fontSize: '16px' };
};

const sxNotification = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
  };
};
