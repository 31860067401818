import { useEffect, useState } from 'react';
import { Box, Stack, Popover, Checkbox, FormGroup, TextField, FormControlLabel, FormControl, Button } from '@mui/material';

import { dateRangeBuilder, getDateRange } from 'utils';
import { useUserProfileContext } from '../../../hooks';
import DateRangePicker from 'shared/date-range-picker';

export default function ProfileChartFilterMenu() {
	const { setDateRangeFilter, checkboxSelected, setCheckboxSelected } = useUserProfileContext();

	function resetDateRange() {
		setCheckboxSelected('');
		setDateRangeFilter({ from: null, to: null });
	}

	return (
		<Stack direction='row' mr={3} mt={2} spacing={2} sx={{ display: 'flex', width: 1000, alignItems: 'center', justifyContent: 'flex-end' }}>
			{STANDARD_DATES.map(( { label, name, value } ) => {
				return (
					<FilterButton
						key={name}
						checked={checkboxSelected === name}
						onClick={
							( e ) => {
								e.preventDefault();
								resetDateRange();
								setCheckboxSelected(name);
								setDateRangeFilter(dateRangeBuilder({ interval: name }));
							}
						}>
						{label}
					</FilterButton>);
			})}
			<Box width={110}>
				<DateRangePicker
					setFilter={setDateRangeFilter}
					dateBuilder={dateRangeBuilder}
					resetFilters={true}
					resetFiltersAction={resetDateRange}
					onChangeActions={() => setCheckboxSelected('custom')}
					CustomInputComponent={
						<FilterButton width={300} checked={checkboxSelected === 'custom'}>
							Date Range
						</FilterButton>
					} />
			</Box>
		</Stack>
	)
		;
}

function FilterButton( { onClick, checked, children } ) {
	return (
		<Button
			variant='outlined'
			onClick={onClick}
			sx={{
				minWidth: 100,
				height: 45,
				textTransform: 'none',
				color: checked ? 'customPalette.grey1' : 'customPalette.grey4',
				borderColor: checked ? 'customPalette.grey1' : 'customPalette.grey4',
				border: '1.5px solid',
				borderRadius: '10px',
				backgroundColor: checked && 'customPalette.grey7',
			}}>
			{children}
		</Button>
	);
}

const STANDARD_DATES = [
	{
		label: '24 hours',
		name: 'day',
	},
	{
		label: '7 days',
		name: 'week',
	},
	{
		label: 'Month',
		name: 'month',
	},
	{
		label: '3 Months',
		name: 'months',
	},
];
