import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphql } from '../context';

import { flattenPages, QUERY_KEYS } from '../utils';

export default function useChannelMessages({
  messageKey,
  channelId,
  limit = 20,
}) {
  const api = useGraphql();
  const shouldExecuteQuery = !!channelId;

  function getChannelBackwardMessages({ pageParam = null }) {
    return api.controller.channel.messageBackwardContext({
      channelId: channelId,
      msgId: `${parseInt(messageKey) + 1}`,
      messageOnly: false,
      nextPage: pageParam,
      limit: limit,
    });
  }

  function getChannelForwardMessages({ pageParam = null }) {
    return api.controller.channel.messageForwardContext({
      channelId: channelId,
      msgId: `${parseInt(messageKey)}`,
      messageOnly: false,
      nextPage: pageParam,
      limit: limit,
    });
  }

  const backwardMessagesQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.CHANNEL_MESSAGES, channelId, messageKey, 'backward'],
    queryFn: getChannelBackwardMessages,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    enabled: shouldExecuteQuery,
  });

  const forwardMessagesQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.CHANNEL_MESSAGES, channelId, messageKey, 'forward'],
    queryFn: getChannelForwardMessages,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    enabled: shouldExecuteQuery,
  });

  const flattedForwardMessages = forwardMessagesQuery?.data
    ? flattenPages(forwardMessagesQuery.data)
    : [];
  const flattedBackwardMessages = backwardMessagesQuery?.data
    ? flattenPages(backwardMessagesQuery.data)
    : [];

  return {
    data: [...flattedForwardMessages, ...flattedBackwardMessages].sort(
      function (a, b) {
        return parseInt(b.clientKey) - parseInt(a.clientKey);
      }
    ),

    isInitialLoading:
      backwardMessagesQuery.isInitialLoading ||
      forwardMessagesQuery.isInitialLoading,
    loadingBackward: backwardMessagesQuery.isFetching,
    loadingForward: forwardMessagesQuery.isFetching,
    backwardErrorMessage: forwardMessagesQuery.error,
    forwardErrorMessage: forwardMessagesQuery.error,
    isBackwardError: backwardMessagesQuery.isError,
    isForwardError: forwardMessagesQuery.isError,
    hasBackwardMore: backwardMessagesQuery.hasNextPage,
    hasForwardMore: forwardMessagesQuery.hasNextPage,
    loadNextBackwardPage: backwardMessagesQuery.fetchNextPage,
    loadNextForwardPage: forwardMessagesQuery.fetchNextPage,
  };
}
