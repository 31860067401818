import React from 'react';
import styles from './ResultPageElements.module.scss';

import NoResult from 'shared/no-result';
import Suspense from 'shared/suspense-list';
import ResponseItem from './ResponseItem';
import Skeleton from 'shared/skeleton/Skeleton';
import ErrorBoundary from 'shared/error-boundary';
import VerticalScroller from 'shared/vertical-scroller';

import { flattenPages } from 'utils';

function ResponsesList(props) {
  const { responsesQuery } = props;
  const { data, isFetching, fetchNextPage, hasNextPage } = responsesQuery;

  const responses = flattenPages(data) || [];

  return (
    <ErrorBoundary level="section" textVariant="samll" imageSize="small">
      <Suspense
        total={responses?.length || 0}
        loading={isFetching}
        fallback={<Skeleton amount={2} />}
        noResultComponent={<NoResponsePlaceholder />}
      >
        <VerticalScroller
          loadNext={fetchNextPage}
          hasNext={hasNextPage}
          loadingNext={isFetching}
        >
          <div className={styles.map_list}>
            {responses.map((response, index) => (
              <ResponseItem
                key={response?.owner?.id || index}
                owner={response?.owner || 'Anonymous'}
                responseAnswers={response.questions}
                {...response}
              />
            ))}
          </div>
        </VerticalScroller>
      </Suspense>
    </ErrorBoundary>
  );
}

export default ResponsesList;

function NoResponsePlaceholder() {
  return (
    <div className={styles.map_list}>
      <NoResult
        text="No responses found"
        textVariant="small"
        imageSize="small"
        typographyProps={{ sx: { color: 'customPalette.grey4' } }}
        className="my-auto"
      />
    </div>
  );
}
