import { useMutation } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { UnblockChannelCmd } from 'kiwi-sdk';

export default function useUnBlockChat() {
	const api = useGraphql();

	return useMutation({
		mutationFn: ( payload ) => api.send(UnblockChannelCmd(payload)),
	});
}