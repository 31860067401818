import { useMemo } from 'react';

import SaveElementModal from 'shared/save-element-modal';

import { toast } from 'react-toastify';
import { useChatContext } from 'modules/users-chats/context';
import { useAuthContext } from 'modules/auth/context';
import { useSaveMessage } from 'modules/users-chats/hooks';
import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';
import { useChatsTabContext } from '../user-details/chats-tab/ChatsTabContext';

export default function SaveMessageModal() {
	const { data, saveMessageModal, toggleSaveMessageModal, focusMessage } = useChatContext();
	const { chatSelected = '' } = useChatsTabContext();
	const saveMessage = useSaveMessage();
	const { user } = useAuthContext();

	const [currentMessageInfo] = useMemo(() => {
		return data.filter(message => message.id === focusMessage);
	}, [focusMessage]);

	let payload = {
			docUserInfo: {
				data: { ...user },
			},
			ref: {
				ownerId: currentMessageInfo?.owner_id,
				messageId: currentMessageInfo?.clientKey,
				channelId: chatSelected,
				info: '',
				notes: '',
			},
		}
	;

	function onSubmitHandler( form ) {
		return saveMessage.mutate(
			{
				...payload,
				ref: {
					...payload.ref,
					info: form.info,
				},
			},
			{
				onSuccess: () => {
					toggleSaveMessageModal();
					toast.success('The message has been saved successfully');
					queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOC_BOOKMARKS] });
				},
			},
		)
			;
	}

	const initialValues = useMemo(
		() => ({
			info: `${currentMessageInfo?.mediaType} message - ${currentMessageInfo.owner_info}`,
		}),
		[],
	);

	return (
		<SaveElementModal
			open={saveMessageModal}
			onClose={toggleSaveMessageModal}
			title='Save Message'
			formInitValues={initialValues}
			onFormSubmit={onSubmitHandler}
		/>
	);
}
