import { useAdminSettingsContext } from '../context';

function useAppPermisions() {
  const { appPermissions } = useAdminSettingsContext();

  const formInitialPermissions = makeFormInitialPermissions(appPermissions);

  return { formInitialPermissions };
}

export default useAppPermisions;

const makeFormInitialPermissions = (appPermissions, res = {}, father = '') => {
  appPermissions.forEach((permission) => {
    const state = false;
    const currentPath = father
      ? `${father}#${permission.value}`
      : permission.value;
    Object.assign(res, { [currentPath]: state });
    if (permission.children) {
      makeFormInitialPermissions(permission.children, res, currentPath);
    }
  });
  return res;
};
