import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Button } from '@mui/material';

import TipModal from './TipModal';
import routes from 'routing/routes';

import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';

const CustomInput = ( { InputProps, input = '', open, onChange, toggleShowOptions, resetSearchTerm, inputRef, ...props } ) => {
	const history = useHistory();

	const handleKeyPress = ( event ) => {
		if (event.key === 'Enter' && input) {
			event.preventDefault();
			history.push(
				`${generatePath(routes.search, { searchTerm: input })}`,
			);
			toggleShowOptions();
		}
	};
	return (
		<TextField
			variant='outlined'
			placeholder='Search Kiwichat'
			value={input}
			onChange={onChange}
			onKeyDown={handleKeyPress}
			InputProps={{
				...InputProps,
				startAdornment: (<>
					{!input ?
						(<SearchIcon sx={{ color: 'customPalette.grey3' }} />)
						: null}
				</>),
				endAdornment: (
					<>
						{input.length < 2
							? <TipModal />
							: <CloseIcon onClick={resetSearchTerm} sx={{ color: 'customPalette.grey3', cursor: 'pointer' }} />}
					</>
				),
			}}
			margin='none'
			sx={{ bgcolor: 'white', borderRadius: open ? ' 16px 16px 0 0' : '16px' }}
			inputRef={inputRef}
			{...props}
		/>
	);
};

export default CustomInput;
