import { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

function useMessageScroll({ id, messageRef, clientKey }) {
  const location = useLocation();
  const currentMessageId = clientKey || id;
  const { messageId } = queryString.parse(location.search);

  useEffect(() => {
    if (messageRef.current && currentMessageId === parseInt(messageId)) {
      messageRef.current.scrollIntoView({ block: 'nearest' });

      messageRef.current.style.border = '3px solid #ebebef';
      messageRef.current.style.borderRadius = '16px';

      setTimeout(() => {
        messageRef.current.style.border = 'initial';
        messageRef.current.style.borderRadius = 'initial';
      }, 3000);
    }
  }, [messageId, currentMessageId]);
}

export default useMessageScroll;
