import { useGraphql } from 'context';
import { useMutation } from '@tanstack/react-query';

export default function useDeleteBookmark( type ) {
	const sdkMutation = getSdkMutation();
	const deleteBookmarkMutation = ( payload ) => sdkMutation[type](payload);

	return useMutation({ mutationFn: deleteBookmarkMutation });

}

function getSdkMutation() {
	const api = useGraphql();
	return {
		channel: api.controller.doc.removeChannel,
		message: api.controller.doc.removeMessage,
		inmate: api.controller.doc.removeInmate,
		fnf: api.controller.doc.removeFnF,
		search: api.controller.doc.removeSearch,

	};
}
