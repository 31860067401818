import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Trash = (props) => (
  <SvgIcon width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1953 4.06334C8.32033 3.93547 8.4899 3.86364 8.66671 3.86364H11.3334C11.5102 3.86364 11.6798 3.93547 11.8048 4.06334C11.9298 4.1912 12 4.36463 12 4.54545V5.22727H8.00004V4.54545C8.00004 4.36463 8.07028 4.1912 8.1953 4.06334ZM6.66671 5.22727V4.54545C6.66671 4.00297 6.87742 3.4827 7.25249 3.0991C7.62757 2.7155 8.13627 2.5 8.66671 2.5H11.3334C11.8638 2.5 12.3725 2.7155 12.7476 3.0991C13.1227 3.4827 13.3334 4.00297 13.3334 4.54545V5.22727H14.6667H16C16.3682 5.22727 16.6667 5.53253 16.6667 5.90909C16.6667 6.28565 16.3682 6.59091 16 6.59091H15.3334V15.4545C15.3334 15.997 15.1227 16.5173 14.7476 16.9009C14.3725 17.2845 13.8638 17.5 13.3334 17.5H6.66671C6.13627 17.5 5.62757 17.2845 5.25249 16.9009C4.87742 16.5173 4.66671 15.997 4.66671 15.4545V6.59091H4.00004C3.63185 6.59091 3.33337 6.28565 3.33337 5.90909C3.33337 5.53253 3.63185 5.22727 4.00004 5.22727H5.33337H6.66671ZM6.00004 6.59091V15.4545C6.00004 15.6354 6.07028 15.8088 6.1953 15.9367C6.32033 16.0645 6.4899 16.1364 6.66671 16.1364H13.3334C13.5102 16.1364 13.6798 16.0645 13.8048 15.9367C13.9298 15.8088 14 15.6354 14 15.4545V6.59091H12.6667H7.33337H6.00004Z"
      fill="#101010"
    />
  </SvgIcon>
);

export default Trash;
