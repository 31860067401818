import styles from './styles.module.scss';

import dayjs from 'dayjs';

import ErrorBoundary from 'shared/error-boundary';
import Suspense from 'shared/suspense-list';
import VerticalScroller from 'shared/vertical-scroller';
import NoResult from 'shared/no-result';
import SingleMessageLoader from 'shared/loaders/messages-loader/AnalyticsMessageLoader';
import UserLink from 'shared/user-link';

import { cn } from 'utils/functions';
import { formatPhoneNumber, toTitleCase } from 'utils';
import { If } from 'shared/utilities';

function InvitationsList(props) {
  const { invitationsQuery, filteredData, showSender } = props;
  const { isFetching, fetchNextPage, hasNextPage } = invitationsQuery;

  return (
    <ErrorBoundary level="section" textVariant="small" imageSize="samll">
      <Suspense
        total={filteredData?.length || 0}
        loading={isFetching}
        fallback={<ListLoadingComponent />}
        noResultComponent={<NoResultComponent />}
      >
        <div className="flex space-x-12 text-sm font-medium px-4">
          <If condition={showSender}>
            <div className="w-3/12">Sender</div>
          </If>
          <div className="grow pl-2">Invited Contact</div>
          <div className="w-2/12">Date</div>
          <div className="w-2/12 ">Status</div>
        </div>
        <div
          className={cn(
            'mt-4 h-[400px] overflow-y-auto p-1 ',
            styles.scroller_container
          )}
        >
          <VerticalScroller
            loadNext={fetchNextPage}
            hasNext={hasNextPage}
            loadingNext={isFetching}
          >
            <ul className=" divide-y divide-gray-200">
              {filteredData.map((item, index) => (
                <div
                  key={index}
                  className=" flex space-x-12  text-grey-2 text-sm  leading-normal overflow-hidden bg-white px-4 py-5 shadow sm:rounded-md my-2"
                >
                  <If condition={showSender}>
                    <div className="w-3/12 font-medium">
                      <UserLink
                        id={item?.sender.id}
                        name={toTitleCase(
                          `${item?.sender?.firstName} ${item?.sender?.lastName}`
                        )}
                        sxLink={{
                          fontSize: '0.875rem',
                          color: 'customPalette.grey3',
                        }}
                      />
                    </div>
                  </If>
                  <div className="grow font-medium">
                    {item?.recipient?.phone !== 'NA'
                      ? formatPhoneNumber(item?.recipient?.phone)
                      : item?.recipient?.email}
                  </div>
                  <div className="w-2/12 pl-1">
                    {dayjs(item?.createdAt).format('MM/DD/YYYY')}
                  </div>
                  <div className="w-2/12 pl-2">
                    {getFormatedStatus(toTitleCase(item?.status))}
                  </div>
                </div>
              ))}
            </ul>
          </VerticalScroller>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}

export default InvitationsList;

function ListLoadingComponent() {
  return (
    <div className="mt-2 h-[420px] p-1 flex flex-col justify-center">
      <SingleMessageLoader />
    </div>
  );
}

function NoResultComponent() {
  return (
    <div className="mt-2 h-[420px] p-1 flex flex-col justify-center ">
      <NoResult
        text="No invitations found"
        textVariant="medium"
        imageSize="medium"
        typographyProps={{ sx: { bottom: -5, color: 'customPalette.grey4' } }}
      />
    </div>
  );
}

function getFormatedStatus(status) {
  if (status === 'Sent') {
    return 'Pending';
  }
  if (status === 'Denied') {
    return 'Rejected';
  }

  return status;
}
