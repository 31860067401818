import { Grid, Typography } from '@mui/material';

import { Support } from '../../Icons';

export default function SupportOption() {
  return (
    <Grid container direction='row' gap={2}>
      <Support />
      <Typography component='span'>Support</Typography>
    </Grid>
  );
}