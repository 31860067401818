import { useRef } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import Suspense from 'shared/suspense-list';
import ListContainer from 'modules/global-search/layouts/ListContainer';
import VerticalScroller from 'shared/vertical-scroller';
import GridLayoutLoader from 'shared/loaders/search-results/GridLayoutLoader';
import useSearchImageMessages from '../hooks/useSearchImageMessages';
import ImageCard from 'modules/global-search/components/image-card';
import ErrorBoundary from 'shared/error-boundary';

import { useParams } from 'react-router';

export default function TextMessagesTab() {
	const { searchTerm } = useParams();
	const { data, isFetching, fetchNextPage, hasNextPage } = useSearchImageMessages(searchTerm);
	const list = useRef(null);
	const classes = useStyles();
	const iterableList = getFlattedList(data);

	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<Suspense
				total={iterableList?.length || 0}
				loading={isFetching}
				fallback={<GridLayoutLoader />}
				textNoResults='No text messages found'
			>
				<ListContainer>
					<VerticalScroller
						loadNext={fetchNextPage}
						hasNext={hasNextPage}
						loadingNext={isFetching}
					>
						<Box ref={list} className={classes.gridContainer}>
							{iterableList.map(( item, index ) => (
								<Box key={index} className={classes.box}>
									<ImageCard searchTerm={searchTerm}{...item} />
								</Box>
							))}
						</Box>
					</VerticalScroller>
				</ListContainer>
			</Suspense>
		</ErrorBoundary>
	)
		;
}

const useStyles = makeStyles(( theme ) => ({
	gridContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, 250px [col-start])',
		gridColumnGap: theme.spacing(3),
		gridRowGap: theme.spacing(2),

	},
	box: {
		alignItems: 'flex-start',
		backgroundColor: 'customPalette.grey5',
		display: 'flex',
		justifyContent: 'center',
		width: 250,
	},
}));

function getFlattedList( data ) {
	if (data?.pages) {
		const list = data.pages.flatMap(( page ) => page?.items.message) || [];
		return list.some(( item ) => item !== undefined) ? list : [];
	}
	if (data?.items) {
		const list = data.items.message || [];
		return list.some(( item ) => item !== undefined) ? list : [];
	}
	return [];
}
