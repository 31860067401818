import React from 'react';
import styles from './PrintTemplate.module.scss';

import format from 'date-fns/format';

import { useMediaSrc } from 'hooks';
import { formatPhoneNumber, toTitleCase } from 'utils';

const PrintTemplate = ( { requests = [] } ) => {
	return requests.map(( request, index ) => {
		return request.type === 'TEMP_PASSWORD' ? (
			<TempPasswordTemplate key={request.id} request={request} index={index} />
		) : (
			<MediaTemplate key={request.id} request={request} index={index} />
		);
	});
};

export default PrintTemplate;

const TempPasswordTemplate = ( { request, index } ) => {
	const { id, code, inmateEid, owner_info, validUntil, createdAt } = request;

	const validUntileDate = format(new Date(validUntil), 'MMM d, yyyy, h:mm a');
	const createdAtDate = format(new Date(createdAt), 'MMM d, yyyy, h:mm a');

	return (
		<div id={`section${index}`} className='page'>
			<p>Print Request {id.replace('PRINT^REQUEST^', '')}</p>
			<p>Owner: {toTitleCase(owner_info)}</p>
			<p>Owner Id: {formatPhoneNumber(inmateEid)}</p>
			<p>Date requested: {createdAtDate}</p>
			<p>Valid until: {validUntileDate}</p>
			<p>Printed at {format(new Date(), 'MMM d, yyyy, h:mm a')}</p>
			<p>Your KiwiChat temporary password is: {code}</p>
		</div>
	);
};

const MediaTemplate = ( { request, index } ) => {
	const { id, inmateEid, owner_info, mediaLocation } = request;
	const { mediaSrc } = useMediaSrc(mediaLocation);
	return (
		<div id={`section${index}`} className='page'>
			<p>Print Request {id.replace('PRINT^REQUEST^', '')}</p>
			<p>Owner: {toTitleCase(owner_info)}</p>
			<p>Owner Id: {formatPhoneNumber(inmateEid)}</p>
			<p>Printed at {format(new Date(), 'MMM d, yyyy, h:mm a')}</p>
			<img src={mediaSrc} />
		</div>
	);
};
