import { VariantProps, cva } from 'class-variance-authority';

import { If } from 'shared/utilities';
import { cn, extractInitials, getAvatarSrc } from 'utils/functions';

function Avatar({ mediaLocation, className, owner, variant }: AvatarProps) {
  const src = getAvatarSrc(mediaLocation);

  return (
    <If
      condition={Boolean(src)}
      otherwise={
        <span
          className={cn(
            defaultOwnerClass,
            avatarVariants({ variant, className })
          )}
        >
          <span className="text-xs font-medium leading-none text-white">
            {extractInitials(owner)}
          </span>
        </span>
      }
    >
      <img
        src={src}
        alt={`${owner} avatar`}
        className={cn(defaultImgClass, avatarVariants({ variant, className }))}
      />
    </If>
  );
}

export default Avatar;

const avatarVariants = cva('rounded-full ring-offset-2 ring-2', {
  variants: {
    variant: {
      inmate: 'bg-primary-purple ring-secondary-info',
      fnf: 'bg-primary-green-1 ring-primary-green-1',
      doc: 'bg-grey-1 ring-grey-1',
      blocked: 'bg-secondary-error ring-secondary-error',
      inactive: 'bg-grey-3 ring-grey-3',
    },
  },
  defaultVariants: {
    variant: 'inmate',
  },
});

interface AvatarProps {
  mediaLocation?: string | null | undefined;
  className?: string;
  variant?: 'inmate' | 'fnf' | 'doc' | 'blocked' | 'inactive';
  owner: string;
}

const defaultOwnerClass =
  ' w-6 h-6 rounded-full, inline-flex items-center justify-center ';

const defaultImgClass = 'w-6 h-6 rounded-full';
