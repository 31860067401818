import React, { useEffect } from 'react';
import styles from './InmateAutocomplete.module.scss';
import Avatar from 'shared/avatar';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import removeIdPrefix from 'utils/removeIdPrefix';
// eslint-disable-next-line no-unused-vars

const renderOption = ( { className, ...props }, option, { inputValue } ) => {
	const { firstName, lastName, id, eid } = option;
	const fullName = `${firstName} ${lastName}`;
	const matches = {
		fullName: match(fullName, inputValue),
		id: match(id, inputValue),
	};

	const parts = {
		fullName: parse(fullName, matches.fullName),
		id: parse(eid, matches.id),
	};

	return (
		<li className={styles.inmate} {...props}>
			<div>
				<Avatar
					className={styles.inmate__avatar}
					id={id}
					lastName={lastName}
					firstName={firstName}
				/>
				<p className={styles.inmate__name}>
					{parts.fullName.map(( part, index ) => (
						<span
							key={index}
							style={{ fontWeight: part.highlight ? 700 : 400 }}
						>
              {part.text}
            </span>
					))}
				</p>
			</div>
			<p className={styles.inmate__id}>
				{parts.id.map(( part, index ) => (
					<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {removeIdPrefix(part.text)}
          </span>
				))}
			</p>
		</li>
	);
};

export default renderOption;
