import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import styles from './QuestionsBody.module.scss';

import Button from 'shared/button';
import { SelectInput } from 'shared/inputs';
import { ChoiceInput, FakeAnswerInput, MultipleChoiceInput } from './Inputs';

import { Plus } from 'assets/icons';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { SurveyQuestionType } from 'kiwi-sdk';
import { initQuestionValue } from 'modules/surveys/constant';

const MAX_OPTIONS = 10;

const RATING_OPTIONS = Array(MAX_OPTIONS)
  .fill()
  .map((_, i) => i + 1);

const AddChoice = ({ className, ...props }) => {
  return (
    <Button
      className={cs(styles.addChoice, className)}
      startIcon={<Plus />}
      {...props}
    >
      Add choice
    </Button>
  );
};
const RatingQuestion = ({ fields, questionIndex }) => {
  const { setValue } = useFormContext();

  const [length, setLength] = useState(10);
  const handleChange = ({ target }) => {
    setLength(target.value);
    setValue(
      `questions.${questionIndex}.options`,
      Array(target.value)
        .fill()
        .map((_, i) => ({ value: i + 1 }))
    );
  };

  return (
    <div className={styles.ratingQuestion}>
      <div className={styles.ratingQuestion__options}>
        {fields.map((item) => (
          <div key={item.id} className={styles.ratingQuestion__option}>
            {item.value}
          </div>
        ))}
      </div>
      <SelectInput
        className={styles.ratingQuestion__select}
        value={length}
        onChange={handleChange}
        options={RATING_OPTIONS}
        formControl={false}
        variant="outlined"
        outlined
      />
    </div>
  );
};

const QuestionType = ({ type, baseName, index }) => {
  const { watch, setValue } = useFormContext();
  const questionType = watch(`questions.${index}.type`);
  const { fields, append, remove } = useFieldArray({
    name: `${baseName}.options`,
  });

  useEffect(() => {
    const { options } = initQuestionValue[questionType];
    setValue(`questions.${index}.options`, options, { shouldValidate: true });
  }, [questionType]);

  switch (type) {
    case SurveyQuestionType.RADIO: {
      return (
        <div className={styles.radio}>
          {fields.map((item, i) => (
            <ChoiceInput
              key={item.id}
              className={styles.radio__input}
              placeholder={`Choice ${i + 1}`}
              name={`${baseName}.options.${i}.value`}
              onRemove={() => remove(i)}
            />
          ))}
          <AddChoice
            disabled={fields.length === MAX_OPTIONS}
            onClick={() => fields.length < MAX_OPTIONS && append({ value: '' })}
          />
        </div>
      );
    }

    case SurveyQuestionType.MULTIPLE_SELECT: {
      return (
        <div className={styles.multiple}>
          {fields.map((item, i) => (
            <MultipleChoiceInput
              key={item.id}
              placeholder={`Choice ${i + 1}`}
              name={`${baseName}.options.${i}.value`}
              onRemove={() => remove(i)}
            />
          ))}

          <AddChoice
            disabled={fields.length === MAX_OPTIONS}
            onClick={() => fields.length < MAX_OPTIONS && append({ value: '' })}
          />
        </div>
      );
    }

    case SurveyQuestionType.TEXT: {
      return <FakeAnswerInput />;
    }

    case SurveyQuestionType.YES_NO: {
      return (
        <div className={styles.yesNo}>
          <div className={styles.yesNo__option}>Yes</div>
          <div className={styles.yesNo__option}>No</div>
        </div>
      );
    }

    case SurveyQuestionType.RATING: {
      return <RatingQuestion fields={fields} questionIndex={index} />;
    }

    default:
      return null;
  }
};

export default QuestionType;
