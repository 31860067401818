import Box from '@mui/material/Box';

import { generatePath, useHistory } from 'react-router-dom';

const NavigationItem = ({ children, to }) => {
  const onClickHandler = () => {
    return to ? history.push(to) : undefined;
  };

  const history = useHistory();
  return (
    <Box sx={sxGridNavigation} onClick={onClickHandler}>
      {children}
    </Box>
  );
};

export default NavigationItem;

const sxGridNavigation = () => {
  return {
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    margin: '0 2px',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: 'customPalette.grey5',
    },
  };
};
