import { Grid, Box } from '@mui/material';
import CustomTabs from 'shared/custom-tab';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import ProfileAvatar from './ProfileAvatar';
import ProfileActions from './ProfileActions';
import Skeleton from '@mui/material/Skeleton';
import { AvatarSkeleton } from 'shared/skeleton';
import BlockUser from '../../block-user';
import UnblockUser from '../../unblock-user';

const TabPanels = ( { setTab, tab } ) => {
	const { userId, loadingUserProfileInfo, blockUserModal, toggleBlockUserModal, unBlockUserModal, toggleUnBlockUserModal } = useUserProfileContext();
	const isFnF = userId.includes('FNF^');
	const TAB_LIST = getTabsList(isFnF);
	if (loadingUserProfileInfo) return <TabPanelLoader />;
	return (
		<Grid
			container
			direction='row'
			alignItems='center'
			justifyContent='flex-start'
			sx={{ height: '100%' }}
		>
			< Grid item xs={3}>
				<ProfileAvatar />
			</Grid>
			<Grid item xs={6}>
				<Box sx={{
					marginTop: '-25px',
					marginLeft: '-90px',
					position: 'absolute',
				}}>
					<CustomTabs
						value={tab}
						onChange={( evt, tab ) => setTab(tab)}
						tabsList={TAB_LIST}
						userId={userId}
					/>
				</Box>
			</Grid>
			<Grid
				item
				xs={3}
				sx={{
					display: 'flex',
					height: 48,
				}}
			>
				<ProfileActions />
			</Grid>
			{blockUserModal
				? <BlockUser open={blockUserModal} onClose={toggleBlockUserModal} isFnf={isFnF} />
				: null}
			{unBlockUserModal
				? <UnblockUser open={unBlockUserModal} onClose={toggleUnBlockUserModal} isFnf={isFnF} />
				: null}
		</Grid>
	);
};

export default TabPanels;

function TabPanelLoader() {
	return (
		<Grid container sx={{ height: '100%' }}>
			<Grid item xs={3} px={2.2} container alignItems='center' justifyContent='center'>
				<AvatarSkeleton />
			</Grid>
			<Grid item xs={8} gap={1} px={2.2} container alignItems='center' justifyContent='flex-start'>
				<Skeleton variant='text' animation='wave' width={100} height={15} />
				<Skeleton variant='text' animation='wave' width={100} height={15} />
				<Skeleton variant='text' animation='wave' width={100} height={15} />
			</Grid>
			<Grid item xs={1} px={2.2} container alignItems='center' justifyContent='flex-end'>
				<Skeleton variant='text' animation='wave' width={120} height={40} />
			</Grid>
		</Grid>
	);
}

const getTabsList = ( isFnF ) => {
	if (isFnF) {
		return [
			{ index: 0, label: 'Profile' },
			{ index: 1, label: 'Chats' },
			{ index: 2, label: 'Account History' },
		];
	}

	return [
		{ index: 0, label: 'Profile' },
		{ index: 1, label: 'Chats' },
		{ index: 2, label: 'Account History' },
		{ index: 3, label: 'Print Requests' },
	];
};

