import { useEffect, useState } from 'react';

import { useGraphql } from 'context';

export default function useAvatarPhoto( mediaKey = '' ) {
	const { getPhotos } = useGraphql();
	const [thumbnailSrc, setThumbnailSrc] = useState('');

	useEffect(() => {
		if (mediaKey) {
			const sub = getPhotos(mediaKey).subscribe({
				next: ( res ) => {
					setThumbnailSrc(res[0]);
				},
				error: ( err ) => {
					console.error(err);
				},
			});
			return () => sub.unsubscribe();
		}
	}, [mediaKey]);

	return { thumbnailSrc };
}

