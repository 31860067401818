import { useRef, useMemo } from 'react';
import styles from './ChatDetails.module.scss';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useHistory, useParams } from 'react-router';

import ChatControls from './chat-controls/ChatControls';
import MessagesList from './print-messages/messages-list';
import FloatDate from './FloatDate';
import Suspense from 'shared/suspense-list';
import BlockChatModal from 'modules/users-chats/block-chat/BlockChatModal';
import SaveChatModal from 'modules/users-chats/save-chat';
import SaveMessageModal from 'modules/users-chats/save-message';
import FlagModal from 'modules/users-chats/flag-modal/FlagModal';
import UnblockChat from 'modules/users-chats/unblock-chat';
import VerticalScroller from 'shared/vertical-scroller';
import BlockMessageModal from '../block-message-modal/BlockMessageModal';
import ErrorBoundary from 'shared/error-boundary';

import { If } from 'shared/utilities';
import { ChatLoader } from 'shared/loaders';
import { ChatProvider, useChatContext } from 'modules/users-chats/context';
import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';

const ChatDetails = () => {
  const {
    floatDate,
    blockChatModal,
    saveChatModal,
    saveMessageModal,
    flagMessageModal,
    toggleFlagMessageModal,
    unBlockChatModal,
    data,
    messageKey,
    filteredMessagesData,
    loadingFilteredMessages,
    hasMoreFilteredMessages,
    fetchMoreFilteredMessages,
    loadingBackward,
    loadingForward,
    hasBackwardMore,
    hasForwardMore,
    loadNextBackwardPage,
    loadNextForwardPage,
    filters,
    blockMessageModal,
    toggleBlockMessageModal,
    focusMessage,
  } = useChatContext();
  const list = useRef();

  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const scrollToBottom = () => {
    const container = list.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
    queryParams.set('messageId', `${new Date().getTime()}`);
    const newRoute = `${location.pathname}?${queryParams.toString()}`;
    history.push(newRoute);
  };

  const scrollToTop = () => {
    const container = list.current;
    if (container) {
      queryParams.set('messageId', '0');
      const newRoute = `${location.pathname}?${queryParams.toString()}`;
      history.push(newRoute);
      container.scrollTop = 0;
    }
  };

  const currentChannelMessageData = useMemo(
    () =>
      getCurrentChannelMessageData(
        data,
        filteredMessagesData,
        messageKey,
        filters?.filtersAreActive
      ),
    [filters?.filtersAreActive, messageKey, data, filteredMessagesData]
  );

  return (
    <ErrorBoundary level="section" textVariant="medium" imageSize="medium">
      <Paper classes={{ root: styles.content }}>
        <Box className={styles.chat}>
          <ChatControls />
          <IconButton
            onClick={scrollToTop}
            sx={{
              position: 'absolute',
              top: 80,
              right: 10,
              backgroundColor: 'customPalette.grey5',
              opacity: 0.6,
              zIndex: 10,
            }}
            disabled={filters?.filtersAreActive}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <Box
            ref={list}
            className={styles.messages}
            sx={{
              flexDirection: messageKey === '0' ? 'column' : 'column-reverse',
            }}
          >
            <FloatDate date={floatDate} listRef={list} />
            <Suspense
              total={
                currentChannelMessageData ? currentChannelMessageData.length : 0
              }
              loading={loadingBackward || loadingFilteredMessages}
              fallback={<ChatLoader />}
              items="messages"
            >
              <VerticalScroller
                loadNext={!filters?.filtersAreActive && loadNextForwardPage}
                loadPrev={
                  filters?.filtersAreActive
                    ? fetchMoreFilteredMessages
                    : loadNextBackwardPage
                }
                hasNext={!filters?.filtersAreActive && hasForwardMore}
                hasPrev={
                  filters?.filtersAreActive
                    ? hasMoreFilteredMessages
                    : hasBackwardMore
                }
                loadingNext={!filters?.filtersAreActive && loadingForward}
                loadingPrev={
                  filters?.filtersAreActive
                    ? loadingFilteredMessages
                    : loadingBackward
                }
                reverse={messageKey !== '0'}
              >
                <MessagesList
                  listRef={list}
                  messages={currentChannelMessageData}
                />
              </VerticalScroller>
            </Suspense>
          </Box>
          <If condition={blockChatModal}>
            <BlockChatModal />
          </If>
          <If condition={unBlockChatModal}>
            <UnblockChat />
          </If>
          <If condition={saveChatModal}>
            <SaveChatModal />
          </If>
          <If condition={saveMessageModal}>
            <SaveMessageModal />
          </If>
          <If condition={flagMessageModal}>
            <FlagModal
              open={flagMessageModal}
              toggleOpen={toggleFlagMessageModal}
            />
          </If>
          <If condition={blockMessageModal}>
            <BlockMessageModal
              openModal={blockMessageModal}
              toggleOpenModal={toggleBlockMessageModal}
              message={focusMessage}
              data={data}
            />
          </If>
        </Box>
        <IconButton
          onClick={scrollToBottom}
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 10,
            backgroundColor: 'customPalette.grey5',
            opacity: 0.6,
            zIndex: 10,
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Paper>
    </ErrorBoundary>
  );
};
export default () => {
  const { chatId } = useParams();
  const { chatSelected } = useChatsTabContext();

  return (
    <ChatProvider key={chatId || chatSelected}>
      <div className={styles.wrapper}>
        <ChatDetails chatId={chatId || chatSelected} />
      </div>
    </ChatProvider>
  );
};

function getCurrentChannelMessageData(
  messagesContextData,
  filteredMessagesData,
  currentMessageKey,
  filtersAreActive
) {
  if (filtersAreActive) {
    return filteredMessagesData || [];
  }
  return currentMessageKey === '0'
    ? messagesContextData.slice().reverse()
    : messagesContextData;
}
