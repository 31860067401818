import { useCallback, useEffect, useState } from 'react';

import { map, mergeMap, switchMap, tap, catchError } from 'rxjs/operators';
import { from, of, Subject } from 'rxjs';
import fileDownload from 'js-file-download';
import { useGraphql } from 'context';
import { useToggler } from 'hooks';

const useDownloadFormFiles = () => {
  const api = useGraphql();
  const [loading, toggleLoading] = useToggler();
  const [downloadFiles$] = useState(new Subject());

  function getFilesLinks(fileNames) {
    return api.getGrievanceOrFormFiles(fileNames).pipe(
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  function downloadFile(url, fileName) {
    return from(fetch(url)).pipe(
      map((response) => response.blob()),
      tap((blob) => fileDownload(blob, fileName)),
      catchError((error) => console.log(error))
    );
  }

  useEffect(() => {
    const sub = downloadFiles$
      .pipe(
        tap(() => toggleLoading(true)),
        switchMap((fileNames) =>
          getFilesLinks(fileNames).pipe(
            mergeMap((urls) => urls),
            switchMap((url, i) => downloadFile(url, fileNames[i]))
          )
        ),
        tap(() => toggleLoading(false))
      )
      .subscribe({ error: (err) => console.error(err) });

    return () => sub.unsubscribe();
  }, []);

  const downloadFiles = useCallback((files = []) => {
    downloadFiles$.next(files);
  }, []);

  return { loading, downloadFiles };
};

export default useDownloadFormFiles;
