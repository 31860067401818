import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import { If } from 'shared/utilities';

export default function ChannelItemLayout( props ) {
	const {
		avatarComponent,
		liveStatusComponent,
		moderationStatusComponent,
		lastMessageComponent,
		initiatorNameComponent,
		lastMessageDateComponent,
		isSelected,
	} = props;
	return (
		<ChannelItemWrapper selected={isSelected}>
			<If condition={!!avatarComponent}>
				<LeftContainer>
					{avatarComponent}
				</LeftContainer>
			</If>
			<If condition={!!lastMessageComponent || !!initiatorNameComponent}>
				<CenterContainer>
					{initiatorNameComponent}
					{lastMessageComponent}
				</CenterContainer>
			</If>
			<If condition={!!lastMessageDateComponent || !!moderationStatusComponent || !!liveStatusComponent}>
				<RightContainer>
					{liveStatusComponent}
					{moderationStatusComponent}
					{lastMessageDateComponent}
				</RightContainer>
			</If>
		</ChannelItemWrapper>
	);
}

const ChannelItemWrapper = styled(Box)(( { theme, selected } ) => ({
	borderBottom: '1px solid',
	borderColor: theme.palette.customPalette.grey6,
	display: 'flex',
	padding: theme.spacing(1),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	backgroundColor: selected && theme.palette.customPalette.grey6,
	'&:hover': {
		backgroundColor: theme.palette.customPalette.grey6,
	},
}));

const LeftContainer = styled(Box)(( { theme } ) => ({}));

const CenterContainer = styled(Box)(( { theme } ) => ({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
	marginLeft: theme.spacing(1),
	justifyContent: 'center',
	gap: theme.spacing(0.2),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

const RightContainer = styled(Box)(( { theme } ) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	// width: 75,
}));
