import { useRef } from 'react';

import styles from './CardVoice.module.scss';

import Box from '@mui/material/Box';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

import HighlightText from 'shared/text-highlighter';

import { If } from 'shared/utilities';

import { useGraphql } from 'context';
import { useMediaSrc, useToggler, useWavesurfer } from 'hooks';

export default function CardVoice(props) {
  const { mediaLocation, text, searchTerm } = props;

  const [showTranscript, setShowTranscript] = useToggler(false);
  const playerRef = useRef(null);

  const { getImages } = useGraphql();
  const [mediaSrc] = useMediaSrc(getImages)({ itemKey: mediaLocation });
  const { handlePlayPause, formatTime, playing, duration, currentTime } =
    useWavesurfer(mediaSrc, playerRef);

  return (
    <VoiceCardContainer>
      <AudioPlayerContainer
        sx={{
          visibility: showTranscript ? 'hidden' : 'auto',
          height: showTranscript ? '0px' : '200px',
        }}
      >
        <IconButton onClick={handlePlayPause}>
          <If
            condition={playing}
            otherwise={<PlayCircleOutlinedIcon sx={Sxs.PlayPauseIcon} />}
          >
            <PauseCircleOutlineOutlinedIcon sx={Sxs.PlayPauseIcon} />
          </If>
        </IconButton>
        <Box ref={playerRef} sx={{ flex: 1, marginX: 0.5 }} />
        <Box sx={{ marginX: 1, width: 40, fontSize: 14, textAlign: 'right' }}>
          {currentTime ? formatTime(currentTime) : formatTime(duration)}
        </Box>
      </AudioPlayerContainer>
      <If condition={showTranscript}>
        <TranscriptionContainer className={styles.transcriptionContainer}>
          <HighlightText
            substring={searchTerm}
            string={!!text ? text : 'No transcription available'}
            sxHighlighter={{ backgroundColor: '#F8A80C', color: '#101010' }}
            sxString={{ fontSize: '14px', color: 'customPalette.grey1' }}
          />
        </TranscriptionContainer>
      </If>
      <Typography
        sx={Sxs.ShowTranscriptButton}
        onClick={() => setShowTranscript()}
        component="span"
      >
        {showTranscript ? 'Hide transcription' : 'Show transcription'}
      </Typography>
    </VoiceCardContainer>
  );
}

const AudioPlayerContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const TranscriptionContainer = styled(Box)({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'flex-start',
  height: 100,
  overflow: 'hidden auto',
});

const VoiceCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.customPalette.grey6,
  margin: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  height: 200,
  gap: theme.spacing(0.5),
  overflow: 'hidden',
}));

const Sxs = {
  PlayPauseIcon: {
    fontSize: '50px',
    color: 'customPalette.green1',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  ShowTranscriptButton: {
    fontSize: '14px',
    color: 'customPalette.grey4',
    cursor: 'pointer',
    textAlign: 'right',
    '&:hover': {
      color: 'customPalette.grey3',
    },
  },
};
