import SvgIcon from '@mui/material/SvgIcon';

const User = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3993 14.5392H9.60062C8.58492 14.5392 7.66694 15.1445 7.26683 16.0781L6.48864 17.8938C6.34502 18.2289 6.59082 18.6017 6.95539 18.6017H17.0446C17.4091 18.6017 17.6549 18.2289 17.5113 17.8938L16.7331 16.0781C16.333 15.1445 15.415 14.5392 14.3993 14.5392ZM9.60062 13.0157H14.3993C16.0245 13.0157 17.4932 13.9842 18.1334 15.4779L18.9116 17.2937C19.486 18.6341 18.5028 20.1252 17.0446 20.1252H6.95539C5.49711 20.1252 4.51392 18.6341 5.08836 17.2937L5.86656 15.4779C6.50673 13.9842 7.97549 13.0157 9.60062 13.0157Z"
        fill="#101010"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.4766C13.4023 10.4766 14.5391 9.33984 14.5391 7.93754C14.5391 6.53524 13.4023 5.39845 12 5.39845C10.5977 5.39845 9.46089 6.53524 9.46089 7.93754C9.46089 9.33984 10.5977 10.4766 12 10.4766ZM12 12.0001C14.2437 12.0001 16.0625 10.1812 16.0625 7.93754C16.0625 5.69386 14.2437 3.875 12 3.875C9.75629 3.875 7.93743 5.69386 7.93743 7.93754C7.93743 10.1812 9.75629 12.0001 12 12.0001Z"
        fill="#101010"
      />
    </SvgIcon>
  );
};

export default User;
