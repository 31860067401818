import Grid from '@mui/material/Grid';
import MessageInfo from './MessageInfo';
import ImageThumbnail from './ImageThumbnail';
import query from 'query-string';
import { useQuery } from 'hooks';
import { useHistory, useLocation } from 'react-router';

export default function RepliedMessage( { parentMessage } ) {
	const { text, mediaType, blockReason, blockedBy, owner_info, id, clientKey } = parentMessage;
	const queryParams = useQuery();
	const history = useHistory();
	const location = useLocation();
	const hasBeenBlocked = blockReason && blockedBy;
	const textToRender = mediaType === 'image' ? 'Photo' : text;
	const repliedMsgStyle = sxRepliedMessage();
	const onClickHandler = () => {
		const newParams = {
			...queryParams,
			messageId: clientKey,
		};
		history.replace(`${location.pathname}?${query.stringify(newParams)}`);
	};

	return (
		<Grid container sx={repliedMsgStyle.gridWrapper} onClick={onClickHandler}>
			{mediaType === 'image'
				? <ImageThumbnail isBlocked={hasBeenBlocked} />
				: null}
			<MessageInfo messageText={textToRender} ownerName={owner_info} />
		</Grid>
	);
}

const sxRepliedMessage = () => ({
	gridWrapper: {
		alignItems: 'flex-start',
		borderColor: 'customPalette.grey1',
		borderLeft: '2px solid',
		borderRadius: '0 8px 8px 0',
		maxWidth: 470,
		marginBottom: '11px',
		'&:hover': {
			backgroundColor: 'customPalette.grey6',
			cursor: 'pointer',
		},
	},
});

//TODO: parent message doesent have client key
