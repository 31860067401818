import { useQuery } from '@tanstack/react-query';
import { DOCUserFavesQuery } from 'kiwi-sdk';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useBookmarks( docId ) {
	const api = useGraphql();

	 function getBookmarks() {
		return api.provider.send(DOCUserFavesQuery({ id: docId }));
	}

	const useBookmarksQuery = useQuery({
		queryKey: [QUERY_KEYS.DOC_BOOKMARKS],
		queryFn: getBookmarks,
	});

	return useBookmarksQuery;

}