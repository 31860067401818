import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import format from 'date-fns/format';

import { Lock } from 'assets/icons';
import { differenceInDays, formatDistanceToNow } from 'date-fns';

export default function ChatStatus( { info, isChannelBlock } ) {
	const infoArray = useBlockStatusInfo({ info }).filter(( { value } ) => value);
	const { sxIcon } = useChatStatusStyles();

	return <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
		{
			isChannelBlock ? (
				<InfoTooltip placement='top' title={<TooltipTitle arr={infoArray} />}>
					<TooltipGrid container alignItems='center'>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
						>
							<Typography variant='regularRed' component='span' mr={0.5}>
								Chat blocked
							</Typography>
							<Lock sx={sxIcon} />
						</Box>
					</TooltipGrid>
				</InfoTooltip>
			) : null
		}
	</Box>;
}

const TooltipTitle = ( { arr } ) => {
	return <>
		{arr.map(( { label, value } ) => (
			<Box key={label}>
				<Typography variant='mediumGrey4' component='span' fontSize={12}>
					{label}:{' '}
				</Typography>
				<Typography variant='regularBlack' component='span' fontSize={12}>
					{value}
				</Typography>
			</Box>
		))}
	</>;
};
const useBlockStatusInfo = ( { info } ) => {
	const isBlockedIndefinitely = differenceInDays(new Date(info.blockedUntil), new Date()) > 3000;
	const blockDaysRemaining = formatDistanceToNow(new Date(info.blockedUntil));

	const remainingUnit = blockDaysRemaining === 1 ? 'day' : 'days';
	return [
		{
			label: 'Blocked by ',
			value: info.blockedBy,
		},
		{
			label: 'Date ',
			value: info.begins ? format(new Date(info.begins), 'dd/MM/yy') : null,
		},
		{
			label: 'Block Duration ',
			value: isBlockedIndefinitely ? 'Indefinitely' : `${blockDaysRemaining}`,
		},
		{
			label: 'Block Reason',
			value: info.reason,
		},
	];
};

const InfoTooltip = styled(( { className, ...props } ) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#ffffff',
		minWidth: 150,
		// height: 70,
		border: '1px solid #ebebef',
	},
}));

const TooltipGrid = React.forwardRef(( props, ref ) => {
	return (
		<Grid
			ref={ref}
			container
			alignItems='center'
			justifyContent='center'
			sx={{ cursor: 'pointer' }}
			{...props}
		/>
	);
});
const useChatStatusStyles = () => ({
	sxIcon: {
		color: 'customPalette.liveCallRed',
		width: 14,
		height: 14,
	},
});
