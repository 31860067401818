import { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAlt';

import MessagesFilters from 'shared/analytics-modal/messages-filter';

import { If } from 'shared/utilities/index';
import { useToggler } from 'hooks';
import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';

export default function SearchInput() {
  const { searchTerm, setSearchTerm, selectedAnalyticModalConfig } =
    useAnalyticsModalContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const [open, toggleOpen] = useToggler(false);

  function onInputChangeHandler(event) {
    setSearchTerm(event.target.value);
  }

  function handleFilterClick(event) {
    setAnchorEl(event.currentTarget);
    toggleOpen();
  }

  return (
    <div className="w-full mx-3">
      <label htmlFor="searchTerm" className="sr-only">
        Search for items
      </label>
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            id="searchTerm"
            name="searchTerm"
            value={searchTerm}
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 focus:outline-none ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            placeholder="Search for ..."
            onChange={onInputChangeHandler}
          />
        </div>
        <If condition={selectedAnalyticModalConfig?.filters}>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-grey-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleFilterClick}
          >
            <FilterIcon
              className="-ml-0.5 h-5 w-5 text-gray-4"
              aria-hidden="true"
            />
            Filter
          </button>
          <MessagesFilters
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            open={open}
            toggleOpen={toggleOpen}
          />
        </If>
      </div>
    </div>
  );
}
