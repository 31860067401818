import React, { useEffect, useMemo } from 'react';
import styles from './PrintRequestsTab.module.scss';

import Checkbox from '@mui/material/Checkbox';

import { useFormContext } from 'react-hook-form';

export const PrintRequestsCheckbox = ({ name }) => {
  const { register, watch, setValue } = useFormContext();
  const value = watch(name);

  register(name);

  useEffect(() => {
    setValue(name, false);
  }, []);

  const onCheck = (evt) => {
    setValue(name, evt.target.checked);
  };

  return (
    <Checkbox
      classes={{ root: styles.checkbox }}
      onChange={onCheck}
      checked={!!value}
    />
  );
};

export const AllPrintRequestsCheckbox = () => {
  const { watch, reset } = useFormContext();
  const value = watch();

  const { allChecked, oneChecked } = useMemo(() => {
    let allChecked = true;
    let oneChecked = false;

    Object.values(value).forEach((item) => {
      if (!item) {
        allChecked = false;
      }
      if (item) {
        oneChecked = true;
      }
    });

    if (Object.keys(value).length === 0) {
      allChecked = false;
    }

    return { allChecked, oneChecked };
  }, [value]);

  const onCheck = (evt) => {
    const newValue = {};

    Object.keys(value).forEach((key) => {
      newValue[key] = evt.target.checked;
    });

    reset(newValue);
  };

  return (
    <Checkbox
      classes={{ root: styles.checkbox }}
      onChange={onCheck}
      checked={allChecked}
      indeterminate={oneChecked && !allChecked}
    />
  );
};
