import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';

function FiltersMenu(props) {
  const { children } = props;
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
          <span className="sr-only">Open options</span>
          {children}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <h3 className="text-sm font-semibold leading-6 text-gray-900 px-4 pt-4">
            Invitation Status
          </h3>
          <div className="py-1 flex flex-col">
            {statusList.map((status) => (
              <Menu.Item key={status.value}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm  text-left ml-6 flex-grow border-l border-gray-200 hover:border-gray-900'
                    )}
                    onClick={() => props.setStatus(status.value)}
                  >
                    {status.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default FiltersMenu;

const statusList = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 'Pending',
    value: '1',
  },
  {
    label: 'Withdrawn',
    value: '2',
  },
  {
    label: 'Rejected',
    value: '3',
  },
  {
    label: 'Accepted',
    value: '4',
  },
  {
    label: 'Expired',
    value: '6',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
