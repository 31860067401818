import { BOOKMARK_TYPE } from './BookmarkTypes';

import { toTitleCase } from 'utils';

export default function getInitialName( type, item ) {
	switch (type) {
		case BOOKMARK_TYPE.CHAT: {
			return `${getFullName(item[0])} - ${getFullName(item[1])} - Chat`;
		}
		case BOOKMARK_TYPE.INMATE:
		case BOOKMARK_TYPE.FNF: {
			return getFullName(item);
		}
		case BOOKMARK_TYPE.MESSAGE: {
			return `${item.owner_info} ${item.text}`;
		}
		case BOOKMARK_TYPE.PHOTO: {
			return `${item.owner_info} photo`;
		}
		case BOOKMARK_TYPE.AUDIO: {
			return `${item.owner_info} audio`;
		}
		case BOOKMARK_TYPE.VIDEO: {
			return `${item.owner_info} video`;
		}
		case BOOKMARK_TYPE.SEARCH: {
			return item.input;
		}
		default: {
			return item.id;
		}
	}
};

function getFullName( user ) {
	return toTitleCase(`${user?.firstName} ${user?.lastName}`);
};
