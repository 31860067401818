import styles from './UnblockChat.module.scss';

import Grid from '@mui/material/Grid';

import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';

import { toast } from 'react-toastify';
import { MultilineInput } from 'shared/inputs';
import { useAuthContext } from 'modules/auth/context';
import { useChatContext } from 'modules/users-chats/context';
import { useUnBlockChat } from 'modules/users-chats/hooks';
import { useUserProfileContext } from 'modules/users-chats/hooks';
import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';

const INITIAL_VALUES = {
	note: '',
};

const UnblockChat = () => {
	const { user } = useAuthContext();
	const { userId } = useUserProfileContext();
	const { channelInfo, selectChat, unBlockChatModal, toggleUnBlockChatModal } = useChatContext();
	const selectedChat = channelInfo?.ChannelData || {};
	const unBlockChat = useUnBlockChat();

	function handleUnblockSubmit( form ) {
		return unBlockChat.mutate(
			{
				id: selectedChat?.id,
				updatedReason: form.note,
				updatedBy: {
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
				},
			},
			{
				onSuccess: () => {
					toggleUnBlockChatModal();
					toast.success('Chat unblocked successfully');
					queryClient.setQueryData(
						[QUERY_KEYS.USER_CHATS, userId],
						( oldData ) => {
							const newData = oldData.map(( chat ) => {
								if (chat.channelId === selectedChat.id) {
									return {
										...chat,
										isChannelBlock: false,
									};
								}
								return chat;
							});
							return newData;
						});
					queryClient.setQueryData(
						[QUERY_KEYS.CHANNEL_INFO, selectedChat.id],
						( oldData ) => {
							return {
								...oldData,
								ChannelData: {
									...oldData.ChannelData,
									isChannelBlock: false,
								},
							};
						});
				},
			},
		);
	}

	return (
		<Modal className={styles.modal} open={unBlockChatModal} onClose={toggleUnBlockChatModal}>
			<ModalTitle text={'Unblock chat'} />
			<Form initialValues={INITIAL_VALUES} onSubmit={handleUnblockSubmit}>
				<MultilineInput
					className={styles.note}
					name='note'
					label='Internal notes'
					placeholder='Type here'
					helperText={'Optional (not visible for the user )'}
					maxLength={500} />
				<Grid
					direction='row-reverse'
					justifyContent='space-between'
					wrap='nowrap'
					container>
					<Grid justifyContent='flex-end' wrap='nowrap' container item>
						<Button
							className={styles.button}
							onClick={toggleUnBlockChatModal}
							display='secondary'>
							Cancel
						</Button>
						<Button className={styles.button} type='submit' display='primary'>
							Unblock
						</Button>
					</Grid>
					<FormLoader loading={unBlockChat.isLoading} />
				</Grid>
			</Form>
		</Modal>
	);
};

export default UnblockChat;