import format from 'date-fns/format';

export default function useGetParsedSentimentData(data = []) {
  const result = data.reduce(function (acc, obj) {
    const date = obj.messageDate;
    const sentiment = obj.sentiment;
    const count = obj.count;

    if (!acc[date]) {
      acc[date] = {
        date: format(new Date(date.replace(/-/g, '/')), 'dd MMM yyyy'),
      };
    }
    if (sentiment !== null) {
      acc[date][getSentiment(sentiment)] = count;
    }

    return acc;
  }, {});

  const parsedResult = Object.values(result).map(function (obj) {
    obj.negative = obj.negative ?? 0;
    obj.positive = obj.positive ?? 0;
    obj.neutral = obj.neutral ?? 0;
    return obj;
  });

  return parsedResult;
}

function getSentiment(number) {
  if (number === 1) return 'positive';
  if (number === 0) return 'neutral';
  return 'negative';
}
