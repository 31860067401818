import { useFormContext } from 'react-hook-form';
import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';

function FilterCheckboxItem( props ) {
	const { label, value } = props;
	const { register, watch } = useFormContext();

	return (
		<ListItem
			key={label}
			disablePadding
		>
			<ListItemIcon sx={{ display: 'flex', justifyContent: 'center', minWidth: 0 }}>
				<Checkbox
					edge='start'
					disableRipple
					inputProps={{ 'aria-labelledby': value }}
					checked={watch(value)}
					{...register(value)}
				/>
			</ListItemIcon>
			<ListItemText id={value} primary={label} />
		</ListItem>
	);
}

export default FilterCheckboxItem;
