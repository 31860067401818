import SvgIcon from '@mui/material/SvgIcon';

const Print = (props) => (
  <SvgIcon width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M1 9C1 7.34315 2.34315 6 4 6H20C21.6569 6 23 7.34315 23 9V15C23 16.6569 21.6569 18 20 18H17V16H20C20.5523 16 21 15.5523 21 15V9C21 8.44772 20.5523 8 20 8H4C3.44772 8 3 8.44772 3 9V15C3 15.5523 3.44772 16 4 16H7V18H4C2.34315 18 1 16.6569 1 15V9Z'
          fill='#101010' />
    <path fillRule='evenodd' clipRule='evenodd'
          d='M16 15H8L8 19H16V15ZM8 13C6.89543 13 6 13.8954 6 15V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V15C18 13.8954 17.1046 13 16 13H8Z'
          fill='#101010' />
    <path fillRule='evenodd' clipRule='evenodd'
          d='M16 4H8V6H16V4ZM8 2C6.89543 2 6 2.89543 6 4V8H18V4C18 2.89543 17.1046 2 16 2H8Z' fill='#101010' />
  </SvgIcon>
);

export default Print;
