import React from 'react';

const EmptyFile = (props) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1386 5.13766C11.428 3.84835 13.1766 3.12402 15 3.12402H32.5C32.9973 3.12402 33.4742 3.32157 33.8258 3.6732L51.3258 21.1732C51.6775 21.5248 51.875 22.0017 51.875 22.499V49.999C51.875 51.8224 51.1507 53.5711 49.8614 54.8604C48.572 56.1497 46.8234 56.874 45 56.874H15C13.1766 56.874 11.428 56.1497 10.1386 54.8604C8.84933 53.5711 8.125 51.8224 8.125 49.999V9.99902C8.125 8.17566 8.84933 6.42698 10.1386 5.13766ZM15 6.87402C14.1712 6.87402 13.3763 7.20326 12.7903 7.78931C12.2042 8.37537 11.875 9.17022 11.875 9.99902V49.999C11.875 50.8278 12.2042 51.6227 12.7903 52.2087C13.3763 52.7948 14.1712 53.124 15 53.124H45C45.8288 53.124 46.6237 52.7948 47.2097 52.2087C47.7958 51.6227 48.125 50.8278 48.125 49.999V23.2757L31.7234 6.87402H15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5 3.12402C33.5355 3.12402 34.375 3.96349 34.375 4.99902V20.624H50C51.0355 20.624 51.875 21.4635 51.875 22.499C51.875 23.5346 51.0355 24.374 50 24.374H32.5C31.4645 24.374 30.625 23.5346 30.625 22.499V4.99902C30.625 3.96349 31.4645 3.12402 32.5 3.12402Z"
      fill="currentColor"
    />
  </svg>
);

export default EmptyFile;
