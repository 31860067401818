import { Grid, Stack, Typography } from '@mui/material';

import format from 'date-fns/format';

import { useAvatarModalContext } from 'hooks';

export default function AvatarModalInfo({ currentModerationStatus }) {
  if (!currentModerationStatus) {
    return null;
  }

  const moderationInfo = getModerationInfo(currentModerationStatus);
  const isBlocked = currentModerationStatus.status === 'blocked';
  return (
    <Grid container justifyContent={isBlocked ? 'space-between' : 'center'}>
      {currentModerationStatus.status === 'active' ? (
        <Typography variant="mediumBlack" fontSize={16} component="span">
          No violation
        </Typography>
      ) : (
        moderationInfo.map((item, index) => <InfoItem key={index} {...item} />)
      )}
    </Grid>
  );
}

function InfoItem(props) {
  const { label, value } = props;
  return (
    <Stack>
      <Typography variant="mediumBlack" component="span">
        {label}
      </Typography>
      <Typography
        variant="regularGrey4"
        color={label === 'Status' && 'customPalette.error'}
      >
        {value}
      </Typography>
    </Stack>
  );
}

function getModerationInfo({ begins, blockedByMetadata, reason, status }) {
  const blockedBy = blockedByMetadata
    ? `${blockedByMetadata.split(';')[1]} ${blockedByMetadata.split(';')[2]}`
    : 'kiwicop';
  const blockStatus = status === 'blocked' && `Blocked/ ${reason}`;
  const blockedAt = format(new Date(begins), 'MMM dd, yyyy hh:mm a');
  return [
    { label: 'Status', value: blockStatus },
    { label: 'Blocked by', value: blockedBy },
    { label: 'Date of block:', value: blockedAt },
  ];
}
