import React from 'react';

const Show = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_385_8892)">
      <path
        d="M12 8.72745C10.1946 8.72745 8.7273 10.1947 8.7273 12.0002C8.7273 13.8057 10.1946 15.2729 12 15.2729C13.8055 15.2729 15.2728 13.8057 15.2728 12.0002C15.2728 10.1947 13.8054 8.72745 12 8.72745Z"
        fill="currentColor"
      />
      <path
        d="M12 3.81836C6.54546 3.81836 1.88729 7.21106 0 12.0002C1.88729 16.7893 6.54546 20.182 12 20.182C17.46 20.182 22.1128 16.7893 24.0001 12.0002C22.1128 7.21106 17.46 3.81836 12 3.81836ZM12 17.4547C8.98911 17.4547 6.54546 15.011 6.54546 12.0001C6.54546 8.98922 8.98911 6.54562 12 6.54562C15.0109 6.54562 17.4546 8.98927 17.4546 12.0002C17.4546 15.0111 15.0109 17.4547 12 17.4547Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_385_8892">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Show;
