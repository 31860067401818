import { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import ChannelItemLayout from './ChannelItemLayout';
import Avatar from 'shared/avatar/Avatar';
import UserLink from 'shared/user-link';
import ChatStatus from 'modules/users-chats/user-details/chats-tab/channel-list-section/chat-item/ChatStatus';

import { If } from 'shared/utilities';
import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';
import {
  useAvatarPhoto,
  useChannelLastMessage,
  useUserProfileInfo,
} from 'hooks';
import { chatFormatDate } from 'utils';

export default function ChannelItem(props) {
  const {
    id,
    initiator,
    members,
    blockedBy,
    blockedUntil,
    isChannelBlocked,
    channelBlockReason,
    isSelected,
  } = props;
  const { modalConfig } = useAnalyticsModalContext();

  const { data: lastChatMessage = {} } = useChannelLastMessage(id);
  const { data: ownerData } = useUserProfileInfo(initiator);
  const currentPhoto = ownerData?.photo
    ? ownerData?.photo
    : ownerData?.avatarModerationStatus?.mediaLocation;
  const { thumbnailSrc: avatarSrc } = useAvatarPhoto(currentPhoto || '');

  const photoIsBlocked = ownerData?.photoModerationStatus === 'HAS_VIOLATION';

  const initiatorInfo =
    members?.memberTwo?.id === initiator
      ? members?.memberTwo
      : members?.memberOne;

  const blockStatusInfo = {
    blockedBy,
    blockedUntil,
    reason: channelBlockReason,
  };

  return (
    <ChannelItemLayout
      avatarComponent={
        <Avatar
          id={initiatorInfo?.id}
          firstName={initiatorInfo?.firstName}
          lastName={initiatorInfo?.lastName}
          src={avatarSrc}
          isBlur={photoIsBlocked}
          isBlocked={ownerData?.isBlocked}
          isActive={ownerData?.active}
          sxAvatar={Sxs.Avatar}
        />
      }
      lastMessageComponent={
        <Fragment>
          <If condition={lastChatMessage.mediaType === 'text'}>
            <Typography
              variant="regularGrey3"
              width={modalConfig?.showChatPanel ? 240 : '100%'}
              noWrap
            >
              {lastChatMessage?.text}
            </Typography>
          </If>
          <If condition={lastChatMessage.mediaType === 'image'}>
            <Typography variant="regularGrey3" sx={Sxs.ImageMessage}>
              <PhotoCameraIcon />
              Photo
            </Typography>
          </If>
        </Fragment>
      }
      initiatorNameComponent={
        <UserLink
          id={initiator}
          name={`${initiatorInfo?.firstName} ${initiatorInfo?.lastName}`}
          sxLink={Sxs.UserLink}
        />
      }
      lastMessageDateComponent={
        <Typography variant="regularGrey3" sx={Sxs.Date}>
          {lastChatMessage?.clientKey
            ? chatFormatDate(lastChatMessage?.clientKey)
            : null}
        </Typography>
      }
      moderationStatusComponent={
        <If condition={isChannelBlocked}>
          <ChatStatus
            isChannelBlock={isChannelBlocked}
            info={blockStatusInfo}
          />
        </If>
      }
      isSelected={isSelected}
    />
  );
}

const Sxs = {
  Avatar: {
    width: 40,
    height: 40,
    fontSize: 14,
  },
  UserLink: {
    color: 'customPalette.grey1',
    fontSize: 14,
    fontWeight: 'bold',
  },
  Date: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 12,
  },
  ImageMessage: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      width: 16,
      height: 16,
      marginRight: 0.5,
    },
  },
};
