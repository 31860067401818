import React from 'react';
import styles from './PrintRequestModal.module.scss';

import Modal, { ModalTitle } from 'shared/modal';

const PasswordModal = ({ open, onClose, code = 'Password not available' }) => {
  return (
    <Modal className={styles.modal} onClose={onClose} open={open}>
      <ModalTitle text="Temporary Password" onClose={onClose} />
      <div className={styles.temporary_password}>
        <span>{code}</span>
      </div>
    </Modal>
  );
};

export default PasswordModal;
