import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import { If } from 'shared/utilities';

export default function KiwiDivider( props ) {
	const { label, chipVariant, chipSxProps, dividerSxProps, shouldRender = true, orientation } = props;
	return (
		<If condition={shouldRender}>
			<Root>
				<Divider sx={dividerSxProps} orientation={orientation}>
					<Chip variant={chipVariant} label={label} sx={chipSxProps} />
				</Divider>
			</Root>
		</If>
	);
}

const Root = styled('div')(( { theme } ) => ({
	width: '100%',
	'& > :not(style) ~ :not(style)': {
		marginTop: theme.spacing(2),
	},
}));
