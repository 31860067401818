/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import styles from './CreateRole.module.scss';

import Grid from '@mui/material/Grid';
import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import Button from 'shared/button';
import { TextInput } from 'shared/inputs';
import FormLoader from 'shared/form-loader';

import { from } from 'rxjs';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useAdminSettingsContext } from '../context';
import { useGraphql } from 'context';
import { useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';
import { sortReducer } from 'hooks/useSort';
import { useAppPermisions } from '../hooks';
import { useCheckDuplicate, usePermissionsPayload } from '../hooks';

import RolesInputList from './RolesInputList';

const CreateRole = ({ open, onClose }) => {
  const api = useGraphql();
  const { agency, rolesArray, setRolesArray } = useAdminSettingsContext();
  const { formInitialPermissions } = useAppPermisions();
  const [loading, toggleLoading] = useToggler();

  const createRoleCmd = (payload) => {
    return from(api.controller.agency.createRole(payload));
  };

  const handleSubmit = useCallback(({ title, ...form }) => {
    const createRolePayload = {
      agencyId: agency.id,
      name: title,
      displayName: title,
      description: title,
      permissions: usePermissionsPayload(form),
    };

    const [permissionsAlreadyExist, roleNameWithPermissions] =
      useCheckDuplicate(
        rolesArray,
        createRolePayload.permissions,
        'permissions'
      );
    const [nameAlreadyExist, roleNameWithName] = useCheckDuplicate(
      rolesArray,
      createRolePayload.name,
      'name'
    );

    if (permissionsAlreadyExist) {
      toast.error(
        `These permissions already exist in the ${roleNameWithPermissions} Role`
      );
    }
    if (nameAlreadyExist) {
      toast.error('This name already exist');
    } else {
      createRoleCmd(createRolePayload).subscribe({
        next: (res) => {
          setRolesArray((prevRolesArray) => [res, ...prevRolesArray]);

          toast.info('The  new role has been successfully created');
          onClose();
        },
        error: (err) => {
          console.error(err);
          toast.error('Something went wrong with creating a new role');
        },
      });
    }
  }, []);

  return (
    <>
      <Modal className={styles.modal} open={open} onClose={onClose}>
        <ModalTitle className={styles.title} text="New role" />
        <Form
          className={styles.form}
          onSubmit={(form) => {
            handleSubmit(form);
          }}
          validation={validation}
          initialValues={formInitialPermissions}
        >
          <div className={styles.form__content}>
            <TextInput
              className={styles.form__input}
              name="title"
              label="Role title"
              helperText="Up to 100 char."
              fullWidth
            />
            <p className={styles.form__subtitle}>Permissions</p>
            <div className={styles.form__permissions}>
              <RolesInputList />
            </div>
          </div>

          <Grid
            classes={{ root: styles.form__actions }}
            direction="row-reverse"
            justifyContent="space-between"
            wrap="nowrap"
            container
          >
            <Grid justifyContent="flex-end" wrap="nowrap" container item>
              <Button
                className={styles.cancelButton}
                onClick={onClose}
                display="secondary"
              >
                Cancel
              </Button>
              <SubmitButton />
            </Grid>
            <FormLoader loading={loading} />
          </Grid>
        </Form>
      </Modal>
    </>
  );
};

export default CreateRole;

const validation = yup.object({
  title: yup.string().max(100).required(),
});

const SubmitButton = ({ loading }) => {
  const { formState } = useFormContext();

  return (
    <Button
      type="submit"
      disabled={
        !formState.dirtyFields.title || formState.errors.title || loading
      }
      display="primary"
    >
      Create
    </Button>
  );
};
