import React, { useRef, useState } from 'react';
import './ModalAudioPlayer.scss';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Grid from '@mui/material/Grid';

const ModalAudioPlayer = (props) => {
  const { src, text, owner_info, recipient_info, mediaType } = props;
  const [seekValue, setSeekValue] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isplaying, setIsplaying] = useState(true);
  const audioPlayer = useRef();

  const formatTimes = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  };

  const play = () => {
    audioPlayer.current.play();
    setIsplaying(!isplaying);
  };

  const pause = () => {
    audioPlayer.current.pause();
    setIsplaying(!isplaying);
  };

  const handleTimeDuration = () => {
    setDuration(audioPlayer.current.duration);
  };

  const onPlaying = () => {
    setCurrentTime(audioPlayer.current.currentTime);
    const progressPosition =
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100;
    if (progressPosition === 0) {
      setSeekValue(0);
      setIsplaying(true);
    }
    if (progressPosition === 100) {
      setSeekValue(0);
      setIsplaying(false);
    } else setSeekValue(progressPosition);
  };

  return (
    <Grid
      container
      className="audio_player__wrapper"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={1}>
        {isplaying ? (
          <PauseIcon onClick={pause} sx={{ m: -0.8, fontSize: 44 }} />
        ) : (
          <PlayArrowIcon onClick={play} sx={{ m: -0.8, fontSize: 44 }} />
        )}
        <audio
          src={src.src || src}
          ref={audioPlayer}
          title={text || `${mediaType} from ${owner_info} to ${recipient_info}`}
          alt={text || `${mediaType} from ${owner_info} to ${recipient_info}`}
          preload="auto"
          onTimeUpdate={onPlaying}
          onLoadedMetadata={handleTimeDuration}
          autoPlay={true}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </Grid>
      <Grid item xs={3}>
        {`Voice note (${
          currentTime
            ? formatTimes(Math.floor(currentTime))
            : formatTimes(Math.floor(duration))
        })`}
      </Grid>
      <Grid item xs={8}>
        <input
          id="audio-player-progress-bar"
          style={{ backgroundSize: `${Math.floor(seekValue) + 1}%` }}
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={seekValue ? seekValue : 0}
          onChange={(e) => {
            const seekto =
              audioPlayer.current.duration * (+e.target.value / 100);
            audioPlayer.current.currentTime = seekto;
            setSeekValue(e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ModalAudioPlayer;
