import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './VirtualScroller.module.scss';

import CircularProgress from '@mui/material/CircularProgress';
import {
	AutoSizer,
	List,
	CellMeasurer,
	CellMeasurerCache,
	InfiniteLoader,
} from 'react-virtualized';

const VirtualScroller = ( {
														loading = false,
														onLoadNext,
														total = 20,
														items,
														renderItem,
														threshold,
													} ) => {
	const listRef = useRef();
	const [cache, setCache] = useState();

	useEffect(() => {
		setCache(
			new CellMeasurerCache({
				defaultHeight: 100,
				fixedWidth: true,
			}),
		);
	}, [items]);

	const setListRowRef = ( registerChild ) => ( list ) => {
		listRef.current = list;
		registerChild(list);
	};

	const rowRenderer = ( {
													key, // Unique key within array of rows
													index, // Index of row within collection
													parent,
													style = { overflow: 'auto' },
												} ) => {
		const item = items[index];
		const showLoader = loading && index === items.length - 1;

		return (
			<CellMeasurer
				key={key}
				cache={cache}
				parent={parent}
				rowIndex={index}
				columnIndex={0}
			>
				<div style={style}>
					{renderItem(item)}
					{showLoader && (
						<div className={styles.loader}>
							<CircularProgress />
						</div>
					)}
					<div />
				</div>
			</CellMeasurer>
		);
	};

	return (
		<InfiniteLoader
			isRowLoaded={( { index } ) => !!items[index]}
			loadMoreRows={onLoadNext}
			rowCount={total}
			threshold={threshold}
		>
			{( { onRowsRendered, registerChild } ) => (
				<AutoSizer>
					{( { height, width } ) => (
						<>
							<List
								style={{ overflowY: 'scroll', overflowX: 'scroll' }}
								ref={setListRowRef(registerChild)}
								className={styles.list}
								onRowsRendered={onRowsRendered}
								rowRenderer={rowRenderer}
								rowCount={items.length}
								deferredMeasurementCache={cache}
								rowHeight={cache?.rowHeight || 120}
								height={height}
								width={width}
							/>
						</>
					)}
				</AutoSizer>
			)}
		</InfiniteLoader>
	);
};

VirtualScroller.propTypes = {
	loading: PropTypes.bool.isRequired,
	onLoadNext: PropTypes.func.isRequired,
	total: PropTypes.number.isRequired,
	items: PropTypes.array.isRequired,
	renderItem: PropTypes.func.isRequired,
	threshold: PropTypes.number,
};

export default VirtualScroller;

// import React, { useState, useEffect, useCallback } from 'react';
// import { FixedSizeList } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';

// const InfiniteList = ( props ) => {
// 	const { threshold } = props;
//
// 	const [thresholdTop, thresholdBottom] = threshold;
//
// 	const itemHeight = 30; // Altura de cada elemento
// 	const itemCountPerPage = 20; // Número de elementos por página
// 	const thresholdTop = 200; // Distancia desde el top para cargar más elementos
// 	const thresholdBottom = 200; // Distancia desde el bottom para cargar más elementos

//
// useEffect(() => {
// 	// 	// Cargar elementos iniciales cuando el componente se monta
// 	// 	loadMoreItemsTop();
// 	// }, [loadMoreItemsTop]);
//
// 	const handleScroll = ( { scrollOffset, scrollHeight, clientHeight } ) => {
// 		if (scrollOffset < thresholdTop) {
// 			loadMoreItemsTop();
// 		} else if (scrollHeight - (scrollOffset + clientHeight) < thresholdBottom) {
// 			loadMoreItemsBottom();
// 		}
// 	};
//
// 	const rowRenderer = ( { index, style } ) => (
// 		<div style={style}>{items[index]}</div>
// 	);
//
// 	return (
// 		<div>
// 			<AutoSizer>
// 				{( { height, width } ) => (
// 					<FixedSizeList
// 						height={height}
// 						width={width}
// 						itemCount={items.length}
// 						itemSize={itemHeight}
// 						onScroll={( { scrollOffset, scrollHeight, clientHeight } ) =>
// 							handleScroll({ scrollOffset, scrollHeight, clientHeight })
// 						}
// 					>
// 						{rowRenderer}
// 					</FixedSizeList>
// 				)}
// 			</AutoSizer>
//
// 			{(isLoadingTop || isLoadingBottom) && <div>Cargando...</div>}
// 		</div>
// 	);
// };
//
// export default InfiniteList;
