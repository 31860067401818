import { useEffect, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';

import { toast } from 'react-toastify';

import Modal from 'shared/modal';
import Button from 'shared/button';
import PrintTemplate from 'modules/print-request-section/print-template';
import queryKeys from 'utils/queryKeys';

import { ModalTitle, ModalActions } from 'shared/modal';

import { If } from 'shared/utilities';
import { useMarkAsPrinted } from 'hooks';
import { usePrint, usePrintRequestsSectionContext } from 'modules/print-request-section/hooks';
import { queryClient } from 'utils/queryClient';

function PrintSingleRequestModal( props ) {
	const { selectedFlatRows, toggleAllSelectedRows } = props;

	const [index, setIndex] = useState(INITIAL_INDEX);

	const { openPrintSingleRequestModal, togglePrintSingleRequestModal } = usePrintRequestsSectionContext();
	const markAsPrinted = useMarkAsPrinted();

	const requestsArray = selectedFlatRows.map(( row ) => row.original);

	const titleText = requestsArray[index]?.id.replace('PRINT^REQUEST^', 'PR-');
	const isPassword = requestsArray[index]?.type === 'TEMP_PASSWORD';

	function handleNext() {
		setIndex(index + 1);
	}

	function handlePrevious() {
		setIndex(index - 1);
	}

	function toggleRowsAndModal() {
		togglePrintSingleRequestModal();
		toggleAllSelectedRows(false);
	}

	function postPrintedAction() {
		markAsPrinted.mutate({
				action: 'MARK_AS_PRINTED',
				items: requestsArray,
			},
			{
				onSuccess: () => {
					toast.success('Operation completed successfully');
					queryClient.invalidateQueries(( queryKey ) => {
						return queryKey.includes(`${queryKeys.FACILITIES_PRINT_REQUESTS}`);
					});
				},
				onError: () => {
					toast.error('Operation failed');
				},
			},
		);
		toggleRowsAndModal();
	}

	function onClickPrintBtn() {
		usePrint('print',
			postPrintedAction,
		);
	}

	useEffect(() => {
		setIndex(INITIAL_INDEX);
	}, [openPrintSingleRequestModal]);

	return (
		<StyledModal open={openPrintSingleRequestModal}>
			<ModalTitle text={titleText} onClose={toggleRowsAndModal} />
			<ModalContentContainer>
				<IconButton onClick={handlePrevious} disabled={index <= 0}>
					<KeyboardArrowLeftIcon />
				</IconButton>
				<PasswordContainer>
					<If condition={isPassword}>
						<Typography variant='h6'>
							New Temporary Password:
						</Typography>
						<Typography fontSize={20} variant='boldGrey3' component='span'>
							{requestsArray[index]?.code}
						</Typography>
					</If>
				</PasswordContainer>
				<IconButton onClick={handleNext} disabled={index >= requestsArray.length - 1}>
					<KeyboardArrowRightIcon />
				</IconButton>
			</ModalContentContainer>
			<ModalActions>
				<Button display='primary' onClick={toggleRowsAndModal}>
					Cancel
				</Button>
				<Button display='primary' onClick={onClickPrintBtn}>
					{selectedFlatRows.length > 1 ? 'Print All' : 'Print'}
				</Button>
				<HiddenContainer id='print'>
					<PrintTemplate requests={requestsArray} />
				</HiddenContainer>
			</ModalActions>
		</StyledModal>
	);
};

export default PrintSingleRequestModal;

const ModalContentContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const StyledModal = styled(Modal)({
	maxWidth: 600,
});

const PasswordContainer = styled('div')(( { theme } ) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	height: 300,
	justifyContent: 'center',
	alignItems: 'center',
	gap: 8,

	backgroundColor: theme.palette.customPalette.grey6,
	borderRadius: 8,
}));

const HiddenContainer = styled('div')({
	display: 'none',
});

const INITIAL_INDEX = 0;
