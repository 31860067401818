import Typography from '@mui/material/Typography';
import NavigationItem from 'shared/navigation-item';
import { Chats } from 'assets/icons';
import { routes } from 'routing';

export default function ChatsComponent() {
	return (
		<NavigationItem to={routes.chatsMetrics}>
			<Chats />
			<Typography variant='regularGrey3' component='span' ml={1}>
				Chat
			</Typography>
		</NavigationItem>
	);
}