import React from 'react';
import styles from './AppLoader.module.scss';

import CircularProgress from '@mui/material/CircularProgress';

const AppLoader = () => (
  <div className={styles.wrapper}>
    <CircularProgress />
    <p className={styles.text}>Please wait a second, page is loading</p>
  </div>
);

export default AppLoader;
