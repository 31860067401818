import React from 'react';
import styles from './RolesSection.module.scss';

import RolesSectionHeader from './RolesSectionHeader';
import RolesSectionContent from './RolesSectionContent';

const RolesSection = () => {
  return (
    <section className={styles.roles_section}>
      <RolesSectionHeader />
      <RolesSectionContent />
    </section>
  );
};

export default RolesSection;
