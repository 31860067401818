import React from 'react';
import StaffList from '../staff-list';

const UsersTab = () => {
  return (
    <>
      <StaffList />
    </>
  );
};

export default UsersTab;
