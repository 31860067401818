import { Grid, Typography } from '@mui/material';

import CollapsibleList from 'shared/collapsible-list';
import NavigationItem from 'shared/navigation-item';
import { Dash } from 'assets/icons';
import { routes } from 'routing';

export default function() {
	return <CollapsibleList component={<SettingsComponent />} list={<SettingsOptionList />} />;
}

function SettingsComponent() {
	return (
		<NavigationItem>
			<Dash />
			<Typography variant='regularGrey3' component='span' ml={1}>
				Hub
			</Typography>
		</NavigationItem>
	);
}

const settingsOptions = [
	{ label: 'announcements', title: 'Announcements', to: routes.bulletins },
	{ label: 'surveys', title: 'Surveys', to: routes.surveys },
	{ label: 'print-requests', title: 'Print Requests', to: routes.printRequests },
	{ label: 'documents', title: 'Documents', to: routes.documents },
];

function SettingsOptionList() {
	return (
		<Grid container gap={1.5} sx={sxGridList}>
			{settingsOptions.map(( { label, title, to } ) => {
				return (
					<NavigationItem key={label} to={to}>
						<Typography component='span'>{title}</Typography>
					</NavigationItem>
				);
			})}
		</Grid>
	);
};

const sxGridList = () => {
	return {
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		position: 'absolute',
		width: 150,
		height: 190,
		bgcolor: 'customPalette.grey6',
		padding: 1,
		borderRadius: '0 0 8px 8px',
		zIndex: 1000,
	};
};
