import React from 'react';
import PropTypes from 'prop-types';
import styles from './DocumentsNavigation.module.scss';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

import { generatePath } from 'react-router-dom';
import { routes } from 'routing';

const DocumentsNavigation = ({ folderPath = [] }) => {
  const navigation = [
    {
      id: '1',
      name: 'Documents',
    },
    ...folderPath,
  ];

  return (
    <div className={styles.navigation}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            className={styles.navigation__icon}
            fontSize="small"
          />
        }
      >
        {navigation.map(({ id, name }) => (
          <Link
            key={id}
            className={styles.navigation__item}
            to={generatePath(routes.documents, id && { folderId: id })}
          >
            {name}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

DocumentsNavigation.propTypes = {
  folderPath: PropTypes.array,
};

export default DocumentsNavigation;
