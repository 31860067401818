import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Forward = (props) => (
  <SvgIcon width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9107 2.74408C12.2361 2.41864 12.7638 2.41864 13.0892 2.74408L17.2559 6.91074C17.5813 7.23618 17.5813 7.76382 17.2559 8.08926L13.0892 12.2559C12.7638 12.5814 12.2361 12.5814 11.9107 12.2559C11.5853 11.9305 11.5853 11.4028 11.9107 11.0774L15.4881 7.5L11.9107 3.92259C11.5853 3.59715 11.5853 3.06951 11.9107 2.74408Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 8.33329C6.00363 8.33329 5.36774 8.59668 4.8989 9.06553C4.43006 9.53437 4.16667 10.1703 4.16667 10.8333V16.6666C4.16667 17.1269 3.79357 17.5 3.33333 17.5C2.8731 17.5 2.5 17.1269 2.5 16.6666V10.8333C2.5 9.72822 2.93899 8.66842 3.72039 7.88701C4.50179 7.10561 5.5616 6.66663 6.66667 6.66663H16.6667C17.1269 6.66663 17.5 7.03972 17.5 7.49996C17.5 7.9602 17.1269 8.33329 16.6667 8.33329H6.66667Z"
      fill="#101010"
    />
  </SvgIcon>
);

export default Forward;
