import React, { useRef } from 'react';
import styles from './BulletinDetails.module.scss';
import Grid from '@mui/material/Grid';
import Modal from 'shared/modal';
import {
  BulletinFiles,
  BulletinFooter,
  BulletinHeader,
  BulletinMessage,
  BulletinRecipients,
  BulletinSender,
} from './BulletinDetailsComponents';
import { generatePath, useHistory } from 'react-router';
import { routes } from 'routing';

const BulletinDetails = ({
  open,
  onClose,
  subject,
  sentBy,
  createdAt,
  sentTo,
  recipients,
  message,
  files,
  read,
  sent,
  //validUntil
}) => {
  const history = useHistory();
  const printableRef = useRef(null);

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <div ref={printableRef}>
        <BulletinHeader subject={subject} printableRef={printableRef} />
        <div className={styles.body}>
          <div className={styles.body__info}>
            <BulletinSender sentBy={sentBy} createdAt={createdAt} />
            <BulletinRecipients
              sentTo={sentTo}
              recipients={recipients}
              routes={routes}
              history={history}
              generatePath={generatePath}
            />
          </div>
          <BulletinMessage message={message} />
          <BulletinFiles files={files} />
        </div>
        <Grid justifyContent="space-between" alignItems="center" container>
          <BulletinFooter sent={sent} read={read} onClose={onClose} />
        </Grid>
      </div>
    </Modal>
  );
};

export default BulletinDetails;
