import { useMemo } from 'react';
import styles from './styles.module.scss';

import dayjs from 'dayjs';
import Skeleton from '@mui/material/Skeleton';

import { DialogContent } from '@mui/material';

import VerticalScroller from 'shared/vertical-scroller';
import AvatarModalActions from 'shared/avatar/avatar-modal/AvatarModalActions';
import AvatarModalInfo from 'shared/avatar/avatar-modal/AvatarModalInfo';

import { If } from 'shared/utilities';
import { Lock } from 'assets/icons';

import flattenPages from 'utils/flattenPages';
import useImageValidator from 'hooks/useImageValidator';

import { cn } from 'utils/functions';
import { useAvatarModalContext, useAvatarPhoto } from 'hooks';

export default function AvatarModalContent({
  toggleModal,
  blockAvatarPhoto,
  currentMediaLocation,
}) {
  const { photoHistoryQuery, selectPhoto, currentIndex } =
    useAvatarModalContext();
  const {
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetching,
    isInitialLoading,
  } = photoHistoryQuery;

  const flattenedData =
    useMemo(
      () =>
        data && updateHistoryArray(flattenPages(data), currentMediaLocation),
      [data]
    ) || [];

  return (
    <DialogContent sx={Sxs.DialogContent}>
      <div className="flex flex-1 px-5 pt-11">
        <If
          condition={!isInitialLoading}
          otherwise={
            <div className="flex flex-col w-full  justify-between">
              <Skeleton variant="rounded" width="100%" height={450} />
              <div className="flex space-x-3">
                <Skeleton variant="rounded" height={50} className="flex-1" />
                <Skeleton variant="rounded" height={50} className="flex-1" />
                <Skeleton variant="rounded" height={50} className="flex-1" />
              </div>
            </div>
          }
        >
          <ImagePreview
            isProfileCurrentPhoto={currentIndex === 0}
            blockMutation={blockAvatarPhoto}
            toggleModal={toggleModal}
            mediaLocation={
              flattenedData.length > 0 ? flattenedData[currentIndex].photo : ''
            }
            moderationStatus={
              flattenedData[currentIndex]?.avatarModerationStatus
            }
          />
        </If>
      </div>
      <aside className={cn(styles.scroller_container, 'flex-1')}>
        <div className="text-end px-5 font-black text-md text-grey-1">
          Image History
        </div>
        <If
          condition={!isInitialLoading}
          otherwise={
            <div className="py-5 pr-5 pl-4 grid grid-cols-2 gap-x-5 gap-y-5">
              <Skeleton variant="rounded" height={150} />
              <Skeleton variant="rounded" height={150} />
              <Skeleton variant="rounded" height={150} />
              <Skeleton variant="rounded" height={150} />
              <Skeleton variant="rounded" height={150} />
              <Skeleton variant="rounded" height={150} />
            </div>
          }
        >
          <VerticalScroller
            loadNext={fetchNextPage}
            hasNext={hasNextPage}
            loadingNext={isFetching}
          >
            <ul
              role="list"
              className=" py-5 pr-5 pl-4 grid grid-cols-2 gap-x-5 gap-y-5 overflow-y-auto"
            >
              {flattenedData.map(
                ({ photo, createdAt, avatarModerationStatus }, index) => (
                  <li key={index} className="relative">
                    <div
                      className="aspect-h-7 aspect-w-10 flex align-middle justify-center aspect-w-10 block h-full w-full overflow-hidden rounded-lg hover:filter shadow ring-1 ring-black ring-opacity-5 group-hover:animate-ping transform scale-100 hover:scale-[1.1] transition-transform duration-2000 ease-in-out focus-within:ring-2 focus-within:ring-offset-4 focus-within:ring-offset-primary-green-1"
                      onClick={() => selectPhoto(index)}
                    >
                      <HistoryImageComponent
                        mediaLocation={photo}
                        moderationStatus={avatarModerationStatus}
                        selectPhoto={selectPhoto}
                        index={index}
                        createdAt={createdAt}
                      />
                      <button
                        type="button"
                        className="absolute inset-0 focus:outline-none"
                      >
                        <span className="sr-only">View details</span>
                      </button>
                    </div>
                  </li>
                )
              )}
            </ul>
          </VerticalScroller>
        </If>
      </aside>
    </DialogContent>
  );
}

function ImagePreview({
  mediaLocation,
  isProfileCurrentPhoto,
  toggleModal,
  blockMutation,
  moderationStatus,
}) {
  const { thumbnailSrc } = useAvatarPhoto(mediaLocation);

  const photoIsBlocked = moderationStatus?.status === 'blocked';

  return (
    <div className="relative flex flex-col justify-between flex-1 pl-3">
      <If condition={isProfileCurrentPhoto}>
        <div className="absolute inset-0 flex items-start justify-end">
          <span className="text-white bg-grey-1 opacity-30 text-sm font-semibold p-1 rounded-lg m-2">
            Current Profile Photo
          </span>
        </div>
      </If>
      <img
        src={thumbnailSrc}
        alt=""
        className="w-full h-4/5 object-cover mx-auto rounded-lg shadow ring-1 ring-black ring-opacity-5 mb-5"
      />
      <If condition={isProfileCurrentPhoto && !photoIsBlocked}>
        <div className="text-end">
          <AvatarModalActions
            onClose={toggleModal}
            blockMutation={blockMutation}
          />
        </div>
      </If>
      <If condition={photoIsBlocked}>
        <AvatarModalInfo currentModerationStatus={moderationStatus} />
      </If>
    </div>
  );
}

function HistoryImageComponent({ mediaLocation, moderationStatus, createdAt }) {
  const { thumbnailSrc } = useAvatarPhoto(mediaLocation);
  const [isValidSrc] = useImageValidator(thumbnailSrc);

  const isBlocked = moderationStatus?.status === 'blocked';

  return (
    <If
      condition={isValidSrc}
      otherwise={
        <div className="flex items-center justify-center w-full h-full bg-gradient-to-br from-[#44444a] via-[#344551] to-[#374419]">
          <span className="flex items-center justify-center text-white text-md font-medium h-[200px] rounded">
            Image not Available
          </span>
        </div>
      }
    >
      <div className="relative w-full h-full">
        <If condition={createdAt}>
          <div className="absolute inset-0 flex ml-0.5 items-start justify-start">
            <span className="text-white bg-grey-1 opacity-30 text-[12px] font-semibold p-1 rounded-lg m-2">
              {dayjs(createdAt).format('MMMM DD, YYYY')}
            </span>
          </div>
        </If>
        <img src={thumbnailSrc} alt="" className="w-full h-full object-cover" />
        <If condition={isBlocked}>
          <div className="absolute inset-0 flex items-center justify-center">
            <Lock color="white" width="35px" height="35px" className="z-40" />
            <div className="absolute inset-0 bg-black opacity-20" />
          </div>
        </If>
      </div>
    </If>
  );
}

const Sxs = {
  DialogContent: {
    width: '900px',
    maxHeight: '600px',
    padding: 0,
    display: 'flex',
    overflow: 'hidden',
  },
};

function updateHistoryArray(arr = [], location = '') {
  const extractedObjByKey = arr.filter((obj) => obj['photo'] === location);

  if (extractedObjByKey[0]) {
    const filteredArrayByObject = arr.filter(
      (obj) => obj['photo'] !== location
    );
    const index = arr.findIndex((obj) => obj['photo'] === location);

    return index === 0 ? arr : [extractedObjByKey[0], ...filteredArrayByObject];
  }

  return [{ photo: location }, ...arr];
}
