import React from 'react';
import OptionResults from '../../survey-result/results-page-elements/responses-result-items/OptionResults';
import RatingResults from '../../survey-result/results-page-elements/responses-result-items/RatingResults';
import { useSurveyResultsContext } from '../../context';

const getResponseComponent = (question) => {
  const { type, options, answerAggregate, answer } = question;
  const { isSurveySummary } = useSurveyResultsContext();
  const answerArray = answer?.split(',') ?? [];
  switch (true) {
    case type === 'YES_NO' || type === 'RADIO':
      return (
        <OptionResults
          options={options}
          answers={isSurveySummary ? answerAggregate : answer}
          isSummary={isSurveySummary}
          type={type}
        />
      );

    case type === 'MULTIPLE_SELECT':
      return (
        <OptionResults
          options={options}
          answers={isSurveySummary ? answerAggregate : answerArray}
          isSummary={isSurveySummary}
          type={type}
        />
      );

    case type === 'RATING':
      return (
        <RatingResults
          options={options}
          answers={isSurveySummary ? answerAggregate : answer}
          isSummary={isSurveySummary}
        />
      );

    case type == 'TEXT':
      return null;
  }
};

export default getResponseComponent;
