import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Plus = (props) => (
  <SvgIcon width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.47434 2.66699H8.52697C8.62053 2.66699 8.66732 2.7065 8.66732 2.78551V13.2151C8.66732 13.2942 8.62053 13.3337 8.52697 13.3337H7.47434C7.38077 13.3337 7.33398 13.2942 7.33398 13.2151V2.78551C7.33398 2.7065 7.38077 2.66699 7.47434 2.66699Z"
      fill="#101010"
    />
    <path
      d="M2.79005 7.33301H13.2087C13.2913 7.33301 13.3327 7.37979 13.3327 7.47336V8.52599C13.3327 8.61956 13.2913 8.66634 13.2087 8.66634H2.79005C2.70736 8.66634 2.66602 8.61956 2.66602 8.52599V7.47336C2.66602 7.37979 2.70736 7.33301 2.79005 7.33301Z"
      fill="#101010"
    />
  </SvgIcon>
);

export default Plus;
