import React, { Component } from 'react';
import DevBoundary from 'shared/error-boundary/global-boundaries/DevBoundary';
import SectionBoundary from 'shared/error-boundary/section-boundary/SectionBoundary';

export class ErrorBoundary extends Component {
	constructor( props ) {
		super(props);

		this.state = {
			hasError: false,
			errorType: '',
			errorMsg: '',
			errorStack: '',
		};
	}

	static getDerivedStateFromError( error ) {
		console.log(error.stack.toString());
		return {
			hasError: true,
			errorType: error.name,
			errorMsg: error.message,
			errorStack: error.stack.toString(),
		};
	}

	componentDidCatch( error, info ) {
		console.log(error);
		console.log(info);
		this.setState({
			hasError: true,
			errorType: error.name,
			errorMsg: error.message,
			errorStack: error.stack.toString(),
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					{this.props.level === 'global'
						? <DevBoundary
							message={this.state.errorMsg}
							stack={this.state.errorStack}
							resetErrorState={() => this.setState({ ...this.state, hasError: false })} />
						: <SectionBoundary imageSize={this.props.imageSize} textVariant={this.props.textVariant} />}
				</>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
