function IconButton(props) {
  const { children, ...restProps } = props;
  return (
    <button type="button" {...restProps}>
      {props.children}
    </button>
  );
}

export default IconButton;
