import React, { memo } from 'react';
import styles from './SwitchInput.module.scss';
import cs from 'classnames';

import Switch from '@mui/material/Switch';

import { useFormContext } from 'react-hook-form';

const SwitchInput = ({
  className,
  label,
  color = 'secondary',
  name,
  ...props
}) => {
  const { register, setValue, watch } = useFormContext();

  const { ref, onChange, ...inputProps } = register(name, {
    onChange: (evt) => setValue(name, evt.target.checked),
  });
  const value = watch(name);

  return (
    <div className={cs(styles.switch__wrapper, className)}>
      <Switch
        classes={{
          root: styles.switch,
        }}
        inputRef={ref}
        inputProps={inputProps}
        checked={value}
        onChange={onChange}
        color={color}
        disableRipple
        {...props}
      />
      <label className={styles.switch__label}>{label}</label>
    </div>
  );
};

export default memo(SwitchInput);
