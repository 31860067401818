import React, { useMemo, memo } from 'react';

import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';

const SelectAllButton = () => {
  const { watch, setValue } = useFormContext();
  const { facilities } = useAuthContext();
  const values = watch();

  const allSelected = useMemo(
    () =>
      facilities.reduce((acc, curr) => {
        if (!values[curr.id]) return false;
        else return acc;
      }, true),
    [facilities, values]
  );

  const handleClick = () => {
    const newValue = allSelected ? false : true;
    facilities.forEach(({ id }) => setValue(id, newValue));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      marginLeft="4px"
    >
      <Checkbox onChange={handleClick} />
      <span>{allSelected ? 'Deselect all' : 'Select all'}</span>
    </Grid>
  );
};

export default memo(SelectAllButton);
