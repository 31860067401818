export default function getFlattedList( data ) {
	if (data?.pages) {
		const list = data.pages.flatMap(( page ) => page?.items.message) || [];
		return list.some(( item ) => item !== undefined) ? list : [];
	}
	if (data?.items) {
		const list = data.items.message || [];
		return list.some(( item ) => item !== undefined) ? list : [];
	}
	return [];
}
