import { memo } from 'react';

import ChartCard from '../chart-card/ChartCard';
import Suspense from 'shared/suspense-list';
import useDashboardModeration from 'modules/chats-metrics/hooks/useDashboardModeration';
import EmptyChart from 'modules/chats-metrics/charts/EmptyChart';
import useChatMetricsContext from 'modules/chats-metrics/hooks/useChatMetricsContext';
import KiwiPieChart from 'shared/pie-chart';
import CardContentLayout from '../chart-card/CardContentLayout';
import ErrorBoundary from 'shared/error-boundary';

import { PieChartLegend } from 'shared/chart-legend';
import { ChartLoader } from 'shared/loaders';

import { FilterTypes } from 'kiwi-sdk/dist/types/analytics/message';
import { ViolationType } from 'kiwi-sdk/dist/types/analytics/messageFilters';

import { useAuthContext } from 'modules/auth/context';

import { MODERATION_TOTAL_COLORS } from 'modules/chats-metrics/constants';

function ModerationActivity() {
  const {
    dateRangeFilter,
    setSelectedOption,
    toggleFlaggedMessagesModal,
    setSelectedAnalyticModalConfig,
  } = useChatMetricsContext();
  const { agency, facilities } = useAuthContext();
  const { data = [], isLoading } = useDashboardModeration(
    dateRangeFilter,
    agency,
    facilities
  );

  const onlyViolationsData = data.filter(
    ({ violationType }) => violationType.toLowerCase() !== 'no violation'
  );

  const total = onlyViolationsData.reduce(
    (accumulator, currentObject) => accumulator + currentObject.count,
    0
  );

  const optionsFormatData = ({ violationType, count }) => ({
    option: violationType,
    count: count,
  });

  const selectViolationFromLegend = (violationType) => {
    setSelectedOption([
      {
        term: FilterTypes.VIOLATION,
        value: ViolationType[violationType.toUpperCase()],
      },
    ]);
    setSelectedAnalyticModalConfig({
      modalName: 'Moderation Activity',
      showDateFilters: false,
      showChatPanel: true,
      listRenderItem: 'message',
      initialOptionsData: onlyViolationsData.map(optionsFormatData),
      typesData: ViolationType,
      search: true,
      filters: true,
      // filterMessages: true,
    });
    toggleFlaggedMessagesModal();
  };

  return (
    <ChartCard cardTitle="Moderation Activity">
      <ErrorBoundary level="section" textVariant="medium" imageSize="medium">
        <Suspense
          loading={isLoading}
          total={total}
          fallback={<ChartLoader />}
          noResultComponent={<EmptyChart />}
        >
          <CardContentLayout
            chartType="pie"
            LegendComponent={() => (
              <PieChartLegend
                legendData={onlyViolationsData}
                nameKey="violationType"
                valueKey="count"
                colorPalette={MODERATION_TOTAL_COLORS}
                clickable={true}
                onClickItem={selectViolationFromLegend}
              />
            )}
            ChartComponent={() => (
              <KiwiPieChart
                chartData={onlyViolationsData}
                legendData={onlyViolationsData}
                chartDataKey="count"
                chartNameKey="violationType"
                innerText="Total"
                innerValue={total}
                colorPalette={MODERATION_TOTAL_COLORS}
              />
            )}
          />
        </Suspense>
      </ErrorBoundary>
    </ChartCard>
  );
}

export default memo(ModerationActivity);
