export default function CheckmarkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3536 4.35363C14.5488 4.54889 14.5488 4.86547 14.3536 5.06074L6.00001 13.4143L1.64646 9.06074C1.4512 8.86547 1.4512 8.54889 1.64646 8.35363L2.35357 7.64652C2.54883 7.45126 2.86541 7.45126 3.06067 7.64652L6.00001 10.5859L12.9394 3.64652C13.1346 3.45126 13.4512 3.45126 13.6465 3.64652L14.3536 4.35363Z"
        fill="#038768"
      />
    </svg>
  );
}
