import { useGraphql } from 'context';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'utils';

function useFacilitiesPrintRequests(payload) {
  const { facilityId, agencyId } = payload;
  const api = useGraphql();
  const shouldExecuteQuery = !!facilityId && !!agencyId;

  function getFacilitiesPrintRequests() {
    return api.controller.doc.getFacilityPrintRequests({
      facilityId,
      agencyId,
      limit: 1000,
    });
  }

  const searchTextMessagesQuery = useQuery({
    queryKey: [QUERY_KEYS.FACILITIES_PRINT_REQUESTS, payload],
    queryFn: getFacilitiesPrintRequests,
    enabled: shouldExecuteQuery,
  });

  return searchTextMessagesQuery;
}

export default useFacilitiesPrintRequests;
