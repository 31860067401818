import { createContext } from 'react';

import styled from '@mui/material/styles/styled';
import Dialog from '@mui/material/Dialog';

import ChatControls from 'shared/analytics-modal/chat-controls-component/ChatControls';
import AnalyticsModalChat from 'shared/analytics-modal/chat-component';
import AnalyticsMessageList from 'shared/analytics-modal/items-list';
import AnalyticsModalLayout from 'shared/analytics-modal/AnalyticsModalLayout';
import AnalyticsModalTitle from 'shared/analytics-modal/AnalyticsModalTitle';
import ScrollableOptionsFilter from 'shared/analytics-modal/types-scrollaber-filter/ScrollableOptionsFilter';
import ChatOwnersInfo from 'shared/analytics-modal/chat-owners-info';
import MessagesFilter from 'shared/analytics-modal/messages-filter';

import { If } from 'shared/utilities';
import { useInitialModalContextValue } from 'shared/analytics-modal/hooks';
import SearchInput from 'shared/analytics-modal/search-input';

export const AnalyticsModalContext = createContext();

export default function AnalyticsModal(props) {
  const {
    openModal,
    toggleModal,
    currentSelectedOption,
    sectionDateRangeFilter,
    selectedAnalyticModalConfig,
    sentimentSelectedRangeFilter,
  } = props;
  const initialContextValue = useInitialModalContextValue({
    sectionDateRangeFilter,
    currentSelectedOption,
    selectedAnalyticModalConfig,
    sentimentSelectedRangeFilter,
  });

  const listVariantSize =
    selectedAnalyticModalConfig?.listRenderItem === 'invitation'
      ? 'medium'
      : 'large';

  return (
    <If condition={openModal}>
      <AnalyticsModalContext.Provider value={initialContextValue}>
        <CustomModal
          open={openModal}
          onClose={toggleModal}
          size={listVariantSize}
        >
          <AnalyticsModalTitle
            closeModal={toggleModal}
            sectionRangeFilter={sectionDateRangeFilter}
            sentimentRangeFilter={sentimentSelectedRangeFilter}
          />
          <AnalyticsModalLayout
            ItemsListComponent={AnalyticsMessageList}
            ChatControlsComponent={ChatControls}
            ChatComponent={AnalyticsModalChat}
            OptionsFilterComponent={ScrollableOptionsFilter}
            ChatOwnersInfo={ChatOwnersInfo}
            FilterComponent={MessagesFilter}
            SearchComponent={SearchInput}
          />
        </CustomModal>
      </AnalyticsModalContext.Provider>
    </If>
  );
}

const CustomModal = styled(Dialog)(({ theme, size }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    minWidth: size === 'medium' ? 800 : 1000,
    minHeight: 800,
  },
}));
