import { useState } from 'react';

import Box from '@mui/material/Box';

import PageContainer from 'shared/page-container';
import TabPanels from './tab-panels';
import ChatsTab from './chats-tab/ChatsTab';
import AccountInfoTab from './account-info-tab/AccountInfoTab';
import PrintRequestsTab from './print-requests-tab';
import ProfileTab from './profile-tab/ProfileTab';
import ErrorBoundary from 'shared/error-boundary/ErrorBoundary';

import { useParams } from 'react-router';
import { ChatsTabProvider } from './chats-tab/ChatsTabContext';

const UserDetails = ( ) => {
	const { chatId } = useParams();
	const initialTabIndex = chatId ? 1 : 0;
	const [tab, setTab] = useState(initialTabIndex);
	const Component = PromptComponents[tab];

	return (
		<>
			<Box sx={sxNavigationBox}>
				<TabPanels tab={tab} setTab={setTab} />
			</Box>
			<Box>{Component}</Box>
		</>
	);
};

export default () => {
	const { userId } = useParams();

	return (
		<PageContainer>
			<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
				<UserDetails key={userId} id={userId} />
			</ErrorBoundary>
		</PageContainer>
	);
};

const PromptComponents = [
	<ProfileTab />,
	<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
		<ChatsTabProvider>
			<ChatsTab />
		</ChatsTabProvider>,
	</ErrorBoundary>,
	<AccountInfoTab />,
	<PrintRequestsTab />,
];

const sxNavigationBox = () => {
	return {
		height: 90,
		borderBottom: '1px solid rgba(235, 235, 239, 1) ',
	};
};
