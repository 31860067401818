import React, { useEffect, useMemo, Fragment } from 'react';

import If from 'components/if/If';
import DotIcon from './DotIcon';
import CheckmarkIcon from './CheckmarkIcon';
import AuthInput from 'pages/auth-pages/components/auth-input';

import { useFormContext } from 'react-hook-form';

import { PASSWORD_VALIDATION } from './form-validation';

export default function NewPasswordInput() {
  const { watch, getValues, trigger } = useFormContext();

  const value = watch('newPassword');

  const valid = useMemo(() => {
    const valid = {
      LENGTH: false,
      LOWER_CASE: false,
      UPPER_CASE: false,
      NUMBER: false,
      SPECIAL_SYMBOL: false,
    };

    valid.LENGTH = value.length > 7;
    valid.LOWER_CASE = /[a-z]+/.test(value);
    valid.UPPER_CASE = /[A-Z]+/.test(value);
    valid.NUMBER = /[0-9]+/.test(value);
    valid.SPECIAL_SYMBOL = /[~!@#$%^*\-_=+[{}\]/;:,.?]+/.test(value);

    return valid;
  }, [value]);

  useEffect(() => {
    if (getValues().confirmPassword.length) {
      trigger('confirmPassword');
    }
  }, [value]);

  return (
    <Fragment>
      <AuthInput
        label="New password"
        name="newPassword"
        placeholder=""
        autoComplete="new-password"
        type="password"
      />
      <div className="mt-[26px] flex-col justify-start items-start gap-2 inline-fle">
        {Object.entries(valid).map(([key, value]) => (
          <div
            key={key}
            className={`justify-start items-center gap-2 flex ${
              value ? 'text-primary-green-1' : 'text-grey-3'
            } text-sm font-normal leading-snug ml-2 mb-2`}
          >
            <div className="w-4 h-4 relative">
              <If condition={value} otherwise={<DotIcon />}>
                <CheckmarkIcon />
              </If>
            </div>
            <p>{PASSWORD_VALIDATION[key]}</p>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
