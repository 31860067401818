import { chatFormatDate, toTitleCase } from 'utils';
import UserLink from 'shared/user-link';

export const PRINT_REQUESTS_TABLE_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) =>
      value === 'TEMP_PASSWORD' ? 'Temporary Password' : value,
  },
  {
    Header: 'Inmate Name',
    accessor: 'owner_info',
    Cell: ({ row }) => {
      return (
        <UserLink
          name={toTitleCase(row.original?.owner_info)}
          id={row.original?.inmateId}
          sxLink={{
            fontSize: '14px',
            color: 'customPalette.grey3',
            cursor: 'pointer',
          }}
        />
      );
    },
  },
  {
    Header: 'Inmate ID',
    accessor: 'inmateEid',
  },
  {
    Header: 'Housing Unit',
    accessor: 'location.huName',
  },
  {
    Header: 'Request Date',
    accessor: 'createdAt',
    Cell: ({ value }) => chatFormatDate(value, 'MM/DD/YYYY  h:mm A'),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => toTitleCase(value),
    width: 50,
  },
];
