import React, { useRef } from 'react';
import styles from './SurveyResults.module.scss';

import ResultPageHeader from './results-page-elements/ResultPageHeader';
import ResultsList from './results-page-elements/ResultsList';
import ResultPageSidebar from './results-page-elements/ResultPageSidebar';

import { useLocation } from 'react-router-dom';

import format from 'date-fns/format';
import { SurveyResultsProvider, useSurveyResultsContext } from '../context/';
import Skeleton from '@mui/material/Skeleton';

const SurveyResults = () => {
  const { currentSurveyResponse, surveyData } = useSurveyResultsContext();
  const { name, sent, completed, createdAt, recipients } = surveyData;

  const printableRef = useRef(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ResultPageHeader name={name} printableRef={printableRef} />
        <div className={styles.content__results} id="printable">
          {currentSurveyResponse ? (
            <ResultsList printableRef={printableRef} />
          ) : (
            <Skeleton amount={3} />
          )}
        </div>
      </div>
      <ResultPageSidebar
        id={surveyData?.id}
        sent={sent}
        completed={completed}
        format={format}
        Date={Date}
        createdAt={createdAt}
        recipients={recipients}
      />
    </div>
  );
};

export default () => {
  const location = useLocation();
  const surveysData = location.state.surveysData;

  return (
    <SurveyResultsProvider key={surveysData.id}>
      <SurveyResults id={surveysData.id} surveyData={surveysData} />
    </SurveyResultsProvider>
  );
};
