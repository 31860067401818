import { useState, useEffect, useCallback } from 'react';

import useDocInfo from './useDocInfo';

import { Subject, from, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { DOCUserRolesUpdateCmd } from 'kiwi-sdk';
import { useGraphql } from 'context';
import { useRoleHandler } from '../hooks';
import { useAdminSettingsContext } from '../context';

const useRemoveRoles = (id) => {
  const {
    roleSelected,
    docListByRole,
    setDocListByRole,
    rolesArray,
    setRolesArray,
  } = useAdminSettingsContext();

  const [edit$] = useState(new Subject());
  const api = useGraphql();

  const { selectedStaffInfo } = useDocInfo(id);
  const { roleUsersCountHandler } = useRoleHandler();
  const { security = {} } = selectedStaffInfo;
  const { role: roles = [] } = security;

  function editStaffRoles({ staff, newRoles }) {
    return from(
      api.send(
        DOCUserRolesUpdateCmd({
          docUserInfo: { data: { ...staff } },
          newRoles,
        })
      )
    );
  }

  useEffect(() => {
    const sub = edit$
      .pipe(
        switchMap((payload) => {
          return editStaffRoles(payload).pipe(map((res) => [res, payload]));
        }),
        map(([res]) => {
          if (res.status === 'ERROR') {
            toast.error(res.error);
          } else {
            setDocListByRole(
              docListByRole.filter((doc) => doc.id !== selectedStaffInfo.id)
            );
            setRolesArray(
              roleUsersCountHandler(res.data?.operations, rolesArray)
            );
          }
        })
      )
      .subscribe();

    return () => sub.unsubscribe();
  }, [selectedStaffInfo]);

  const handleRemoveClick = useCallback(() => {
    if (roles.length > 1) {
      const newRoles = roles.filter((role) => role.id !== roleSelected.id);
      return edit$.next({ staff: selectedStaffInfo, newRoles });
    } else {
      toast.error('The user cannot have zero roles assigned');
    }
  }, [selectedStaffInfo]);

  return { handleRemoveClick };
};

export default useRemoveRoles;
