import React, { useEffect, useRef } from 'react';
import styles from './CheckboxListInput.module.scss';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Search from '@mui/icons-material/Search';

import { fromEvent } from 'rxjs';
import { debounceTime, pluck } from 'rxjs/operators';

const CheckboxesSearch = ({ onSearch }) => {
  const input = useRef(null);

  useEffect(() => {
    const sub = fromEvent(input.current, 'input')
      .pipe(debounceTime(500), pluck('target', 'value'))
      .subscribe(onSearch);

    return () => sub.unsubscribe();
  }, []);

  return (
    <TextField
      variant="standard"
      classes={{ root: styles.search__wrapper }}
      InputProps={{
        ref: input,
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      placeholder="Type to search"
    />
  );
};

export default CheckboxesSearch;
