import Box from '@mui/material/Box';

import HighlightText from 'shared/text-highlighter';

export default function TextMessageContent( { text, searchTerm } ) {

	return (
		<Box sx={Sxs.BoxWrapper}>
			<HighlightText
				string={text}
				substring={searchTerm}
				sxHighlighter={Sxs.HighlightedText}
				sxString={Sxs.FontStyle}
			/>
		</Box>
	);
}

const Sxs = {
	BoxWrapper: {
		marginRight: 1.5,
		maxWidth: 435,
		wordWrap: 'break-word',
	},
	HighlightedText: {
		backgroundColor: '#F8A80C',
		color: '#101010',
	},
	FontStyle: {
		fontSize: 14,
		color: 'customPalette.grey1',
	},
};
