import React from 'react';
import styles from './CheckboxListInput.module.scss';

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useFormContext } from 'react-hook-form';

const ItemCheckbox = ( { name, itemId, label } ) => {
	const { watch, trigger, setValue } = useFormContext();
	const value = watch(name);
	const onCheck = ( evt ) => {
		setValue(
			name,
			{ ...value, [itemId]: evt.target.checked },
			{ shouldDirty: true, shouldValidate: true },
		);
		trigger(name);
	};


	return (
		<FormControlLabel
			classes={{
				root: styles.checkbox__wrapper,
				label: styles.checkbox__label,
			}}
			control={
				<MaterialCheckbox
					classes={{ root: styles.checkbox }}
					onChange={onCheck}
					checked={value[itemId]}
				/>
			}
			label={label}
		/>
	);
};

export default ItemCheckbox;
