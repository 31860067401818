import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@mui/material/styles/styled';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import SaveElementModalContainer from './SaveElementModalContainer';
import SaveModalInput from 'shared/save-element-modal/SaveModalInput';
import SaveElementModalActions from './SaveElementModalActions';

import { If } from 'shared/utilities';

export default function SaveElementModal( props ) {
	const {
		open,
		onClose,
		title = 'Save Element',
		formInitValues = { name: 'test' },
		onFormSubmit = ( form ) => console.log('form', form),
	} = props;
	return (
		<SaveElementModalContainer
			openDialog={open}
			setOpenDialog={onClose}
			initialValues={formInitValues}
			handleSubmit={onFormSubmit}>
			<ModalTitleContainer>
				<Typography variant='bigBoldBlack' fontSize={20}>
					{title}
				</Typography>
				<If condition={onClose}>
					<IconButton onClick={onClose} fontSize={24}>
						<CloseIcon sx={{ color: 'black' }} />
					</IconButton>
				</If>
			</ModalTitleContainer>
			<ModalInputContainer>
				<SaveModalInput name='info' label='Name' />
			</ModalInputContainer>
			<SaveElementModalActions />
		</SaveElementModalContainer>
	);
}

const ModalTitleContainer = styled(DialogTitle)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const ModalInputContainer = styled(Box)(( { theme } ) => ({
	margin: theme.spacing(0, 3),
}));
