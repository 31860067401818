import { useMemo } from 'react';

import createBookmarksArray from 'utils/createBookmarksArray';

import { useAuthContext } from 'modules/auth/context';
import { BOOKMARK_TYPE } from 'modules/bookmarks/constants';
import { creatBookArr } from 'utils/createBookmarksArray';

const useIsItemSaved = ({ id, type }) => {
  const { bookmarks } = useAuthContext();
  const isItemSaved = useMemo(() => {
    let compareFunction = (item) => {
      return item.id === id;
    };

    switch (type) {
      case BOOKMARK_TYPE.MESSAGE: {
        compareFunction = (item) => {
          return item.id === id;
        };
        break;
      }
      case BOOKMARK_TYPE.PHOTO: {
        compareFunction = (item) => item.id === id;
        break;
      }
      case BOOKMARK_TYPE.VIDEO: {
        compareFunction = (item) => item.id === id;
        break;
      }
      case BOOKMARK_TYPE.AUDIO: {
        compareFunction = (item) => item.id === id;
        break;
      }
      case BOOKMARK_TYPE.SEARCH: {
        compareFunction = (item) => item.info === id;
        break;
      }
      default: {
        compareFunction = (item) => item.id === id;
        break;
      }
    }

    return createBookmarksArray(bookmarks).find(compareFunction);
  }, [id, type, bookmarks]);

  return isItemSaved;
};

export default useIsItemSaved;
