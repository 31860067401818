export default function getUserType(id = '') {
  switch (true) {
    case id.includes('O^'):
      return 'DOC';
    case id.includes('I^'):
      return 'INMATE';
    case id.includes('FNF^'):
      return 'FNF';
    default:
      return 'DEFAULT';
  }
}