import Link from '@mui/material/Link';

import { routes } from 'routing';
import { generatePath, useHistory } from 'react-router';
import { toTitleCase } from 'utils';

export default function UserLink( { name, id, component = 'p', sxLink } ) {
	const history = useHistory();

	function onClickLinkHandler( evt ) {
		evt.stopPropagation();
		history.push(
			`${generatePath(routes.user, {
				userId: id,
			})}`,
		);
	}

	return (
		<Link
			component={component}
			underline='hover'
			onClick={onClickLinkHandler}
			sx={{ fontSize: '18px', color: 'customPalette.grey4', cursor: 'pointer', ...sxLink }}>
			{toTitleCase(name)}
		</Link>
	);
}
