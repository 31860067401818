import AvatarBlockForm from './AvatarBlockForm';
import ActionButton from 'shared/action-button';

import { If } from 'shared/utilities';
import { Lock } from 'assets/icons';

import { useToggler } from 'hooks';

export default function AvatarModalActions(props) {
  const { onClose, blockMutation } = props;
  const [openBlockForm, toggleOpenBlockForm] = useToggler();

  return (
    <If
      condition={openBlockForm}
      otherwise={
        <ActionButton
          onClick={toggleOpenBlockForm}
          startIcon={<Lock />}
          text="Block"
          className="w-1/4"
        />
      }
    >
      <AvatarBlockForm
        toggleForm={toggleOpenBlockForm}
        variant="ligth"
        onCloseAvatarModal={onClose}
        blockMutation={blockMutation}
      />
    </If>
  );
}
