import { Box } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';

export default function AvatarContent( props ) {
	const { src, firstName = '', lastName = '', sxAvatar, user, toggleModal, isBlur, sxBoxWrapper, inChatStyle, enableModal, userStatus } = props;
	const bgcolor = getBgColor(user, userStatus);
	const blurredScale = isBlur ? '5px' : userStatus === 'inactive' ? '1px' : '0px';
	return (
		<Box
			sx={{
				border: 'solid',
				borderWidth: inChatStyle ? 1 : 2,
				borderColor: bgcolor,
				borderRadius: '50%',
				padding: 0.2,
				...sxBoxWrapper,
			}}
		>
			<MuiAvatar
				src={src}
				alt='User avatar'
				onClick={( evt ) => {
					evt.stopPropagation();
					toggleModal();
				}}
				sx={{
					...sxAvatar,
					backgroundColor: bgcolor,
					cursor: enableModal && 'pointer',
					'.MuiAvatar-img': {
						filter: `blur(${blurredScale}) grayscale( ${userStatus === 'inactive' ? 1 : 0})`,
					},
				}}
			>
				{firstName.charAt(0)}
				{lastName.charAt(0)}
			</MuiAvatar>
		</Box>
	);
}

function getBgColor( user, status ) {
	if (status === 'blocked') return 'customPalette.error';
	if (status === 'inactive') return 'customPalette.grey4';
	if (user === 'DOC') return 'customPalette.grey1';
	if (user === 'INMATE') return 'customPalette.info';
	if (user === 'FNF') return 'customPalette.green1';
}
