import React from 'react';
import { Box } from '@mui/material';

export const Switch = ( props ) => {
	const { children, ...childProps } = props;
	let matchChild = null;
	let defaultCase = null;

	React.Children.forEach(children, ( child ) => {
		if (!matchChild && child.type === Case) {
			const { condition } = child.props;
			const conditionResult = Boolean(condition);

			if (conditionResult) {
				matchChild = child;
			}
		} else if (!defaultCase && child.type === Default) {
			defaultCase = child;
		}
	});

	if (matchChild) {
		return React.cloneElement(matchChild, childProps);
	}
	if (defaultCase) {
		return React.cloneElement(defaultCase, childProps);
	}
	return null;
};

export const If = ( { condition, children, otherwise } ) => {
	return condition ? children : otherwise ?? null;
};

export const Case = ( { children, ...props } ) => React.cloneElement(children, props);

export const Default = ( { children, ...props } ) => React.cloneElement(children, props);

export const CustomMap = ( { list, keyExtractor, renderItem, containerSx, renderItemProps, listAsRenderItemProp = false } ) => {
	return (
		<Box sx={{ ...containerSx }}>
			{list.map(( item, index ) => (
				<div key={keyExtractor(item, index)}>
					{listAsRenderItemProp
						? renderItem({ item, index, list, ...renderItemProps })
						: renderItem({ item, index, ...renderItemProps })
					}
				</div>
			))}
		</Box>
	);
};


