import styles from './MessagesList.module.scss';

import Box from '@mui/material/Box';

import Avatar from 'shared/avatar';

import useUserProfileInfo from '../../../../../hooks/useUserProfileInfo';

import { useAvatarPhoto } from 'hooks';

export default function OwnerInfo( { owner_id, owner_info: fullName, isMessageMine } ) {
	const [firstName, lastName] = fullName.split(' ');
	const { sxOwnerBox, sxOwnerAvatar } = useOwnerInfoStyles(isMessageMine);
	const { data } = useUserProfileInfo(owner_id);
	const avatarMediaLocation = data?.photo;
	const { thumbnailSrc: avatarSrc } = useAvatarPhoto(avatarMediaLocation);

	return (
		<Box sx={sxOwnerBox}>
			<Avatar
				id={owner_id}
				lastName={lastName}
				firstName={firstName}
				position='chat'
				sxAvatar={sxOwnerAvatar}
				inChatStyle={true}
				src={avatarSrc}
			/>
			<p className={styles.message__owner_info__name}>
				{fullName}
			</p>
		</Box>
	);
};

function useOwnerInfoStyles( isMessageMine ) {
	return {
		sxOwnerBox: {
			display: 'flex',
			flexDirection: isMessageMine ? 'row' : 'row-reverse',
			alignItems: 'center',
			marginTop: '8px',
		},
		sxOwnerAvatar: {
			width: 24,
			height: 24,
			fontSize: 12,
		},
	};
};
