import React, { useState, useRef, useMemo, useEffect } from 'react';
import cs from 'classnames';
import styles from './InmateAutocomplete.module.scss';

import Autocomplete from '@mui/material/Autocomplete';
import Input from './Input';
import Result from './Result';
import renderOption from './renderOption';

import { useFormContext } from 'react-hook-form';
import { from, fromEvent } from 'rxjs';
import { tap, filter, debounceTime, map, switchMap } from 'rxjs/operators';
import { useGraphql } from 'context';
import removeIdPrefix from 'utils/removeIdPrefix';

const InmateAutocomplete = ({ name }) => {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const input = useRef(null);
  const { watch, setValue, trigger } = useFormContext();
  const api = useGraphql();

  const value = watch(name);

  const options = useMemo(() => {
    return suggestions.filter(
      (option) => !value.find((item) => item.id === option.id)
    );
  }, [suggestions, value]);

  function searchInmates(search) {
    return from(api.controller.search.inmates({ term: search }));
  }

  useEffect(() => {
    const sub = fromEvent(input.current, 'input')
      .pipe(
        map((evt) => evt.target.value),
        tap(setSearchInput),
        debounceTime(500),
        filter((value) => value.length > 2),
        switchMap((value) => searchInmates(value)),
        map((data) => data.items.inmate || [])
      )
      .subscribe(setSuggestions);

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    trigger(name);
  }, [value]);

  function handleChange(_, value, reason) {
    if (reason === 'selectOption' && value.length < 11) {
      setValue(name, value);
      setSearchInput('');
      setSuggestions([]);
    }
  }

  function handleRemove(id) {
    setValue(
      name,
      value.filter((inmate) => inmate.id !== id)
    );
  }

  return (
    <div className={styles.wrapper}>
      <Autocomplete
        classes={{
          root: styles.autocomplete,
          focused: styles['autocomplete--focused'],
          inputRoot: styles.autocomplete__input,
        }}
        options={options}
        inputValue={searchInput}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          option?.firstName
            ? `${option?.firstName} ${option?.lastName} ${removeIdPrefix(option?.id)}`
            : 'No matching found'
        }
        onChange={handleChange}
        renderInput={(props) => <Input inputRef={input} {...props} />}
        // eslint-disable-next-line no-unused-vars
        // PopperComponent={({ anchorEl, disablePortal, ...props }) => (
        //   <div
        //     {...props}
        //     className={cs(
        //       styles.popper,
        //       options.length === 0  && styles['popper--hidden'],
        //     )}
        //   />
        // )}
        // PaperComponent={(props) => <div {...props} />}
        renderOption={renderOption}
        multiple
        freeSolo
        autoComplete
        handleHomeEndKeys
        filterSelectedOptions
      />
      <Result value={value} onRemove={handleRemove} />
    </div>
  );
};

export default InmateAutocomplete;
