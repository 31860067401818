import { useGraphql } from 'context';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'utils';

export default function useInmatePrintRequests( userId ) {
	const api = useGraphql();

	function getInmatePrintRequests( { pageParam = null } ) {
		return api.controller.doc.getInmatePrintRequests({
			id: userId,
			limit: 10,
			nextPage: pageParam,
		});
	}

	const printRequestsQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.INMATE_PRINT_REQUESTS, userId],
		queryFn: getInmatePrintRequests,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
	});

	return printRequestsQuery;

}