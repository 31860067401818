import React, { useState, useRef } from 'react';
import cs from 'classnames';
import styles from './EditStaffFacilities.module.scss';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';

const FilterFacilities = ({ onChange }) => {
  const [search, setSearch] = useState('');
  const input = useRef(null);
  const iconTouched = useRef(false);

  return (
    <TextField
      placeholder="Type to search"
      variant="standard"
      value={search}
      inputRef={input}
      onChange={(evt) => {
        setSearch(evt.target.value);
        onChange(evt.target.value);
      }}
      onMouseDown={(evt) => evt.stopPropagation()}
      sx={{ width: '100%' }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: styles.filter,
          input: styles.filter__input,
          notchedOutline: styles.filter__outline,
          focused: styles['filter--focused'],
        },
        startAdornment: (
          <InputAdornment
            position="start"
            onClick={() => {
              input.current.focus();
              iconTouched.current = false;
            }}
            onMouseDown={() => (iconTouched.current = true)}
          >
            <Search className={styles.filter__icon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="start"
            onClick={() => {
              input.current.focus();
              iconTouched.current = false;
            }}
            onMouseDown={() => (iconTouched.current = true)}
          >
            <Close
              className={cs(
                styles.filter__icon,
                styles['filter__icon--close'],
                styles['filter__icon--action'],
                !search.length && styles['filter__icon--hidden']
              )}
              onClick={() => {
                setSearch('');
                onChange('');
              }}
            />
          </InputAdornment>
        ),
      }}
      autoFocus
    />
  );
};

export default FilterFacilities;
