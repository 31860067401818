import React from 'react';
import { Box, Typography } from '@mui/material';

import BoundaryError from 'assets/icons/BoundaryError';

export default function SectionBoundary( { imageSize = 'medium', textVariant = 'medium' } ) {
	const { sxBoxWrapper } = useSectionBoundaryStyles();
	return (
		<Box sx={sxBoxWrapper}>
			<BoundaryError sx={{ fontSize: IMAGE_SIZE['medium'] }} color='#E06469' />
			<Typography variant={TEXT_VARIANT[textVariant]} sx={{ fontWeight: 'normal' }}>
				{'Oops! Something went wrong. Please try again later.'}
			</Typography>
		</Box>
	);
}

const IMAGE_SIZE = {
	small: 200,
	medium: 350,
	large: 500,
};

const TEXT_VARIANT = {
	small: 'p',
	medium: 'h6',
	large: 'h5',
};

function useSectionBoundaryStyles() {
	return {
		sxBoxWrapper: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: 'calc(100vh - 190px)',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};
}