import { Box, Typography } from '@mui/material';

import { KiwiModeration } from 'assets/icons';
import { toTitleCase } from 'utils';

export function BlockAlert( { isMessageBlock = false, reason = '', isMine } ) {
	const { sxBoxWrapper, sxIcon } = useBlockAlertStyles(isMine);
	if (!isMessageBlock) return null;
	return <Box sx={sxBoxWrapper}>
		<Typography variant='regularRed' m={1} sx={{ fontWeight: 'bold' }}>
			{toTitleCase(reason)}
		</Typography>
		<KiwiModeration sx={sxIcon} />
	</Box>;
}

function useBlockAlertStyles( isMine ) {
	return {
		sxBoxWrapper:
			{
				height: 'calc(100% - 15px)',
				display: 'flex',
				flexDirection: isMine ? 'row-reverse' : 'row',
				alignItems: 'center',
				marginX: 1,
			},
		sxIcon: {
			width: 20,
			height: 18,
		},
	};
}