import React, { useState, createContext, useContext, useMemo } from 'react';

import { from } from 'rxjs';
import config from '../sdk-config.js';
import { KiwiProvider, KiwiSvc } from 'kiwi-sdk';

const GraphQLContext = createContext({});

const GraphQLProvider = ( { children } ) => {
	const [provider] = useState(KiwiProvider({ config }));
	const contextValue = useMemo(
		() => ({
			env: config.enviroment?.env,
			send: ( cmd ) => from(provider.send(cmd)),
			provider: provider,
			next: () => from(provider.next()),
			prev: () => provider.prev(),
			auth: provider.auth,
			getImages: ( key ) => from(provider.getImages([key])),
			getPhotos: ( key ) => from(provider.getPhotos([key])),
			getVideoAppRecord: ( key ) => from(provider.getVideoAppVideos([key])),
			controller: KiwiSvc(provider),
			getGrievanceOrFormFiles: ( keys ) =>
				from(provider.getGrievanceOrFormFiles(keys)),
			getBulletinFiles: ( files ) => from(provider.getBulletinFiles(files)),
			getDocuments: ( key ) => from(provider.getDocuments(key)),
		}),
		[],
	);

	return (
		<GraphQLContext.Provider value={contextValue}>
			{children}
		</GraphQLContext.Provider>
	);
};

/**
 * @typedef {Object} GraphqlContextType
 * @property {function} send
 * @property {function} next
 * @property {function} prev
 * @property {object} auth
 * @property {function} getImage
 * @property {function} getVideoAppRecord

 * @property {import('kiwi-sdk').IKiwiSvc} controller
 * @property {function} getGrievanceOrFormFiles
 */

/**
 * @returns {GraphqlContextType}
 */
const useGraphql = () => {
	const context = useContext(GraphQLContext);

	return context;
};

export { GraphQLProvider, useGraphql };
