import { Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

export default function ChartCard( props ) {
	const {
		cardTitle = '',
		sxCard = {},
		sxCardHeader = {},
		cardProps = {},
		headerProps = {},
		children,
		hasFilter = false,
		hasInfo = false,
		FilterComponent,
		InfoComponent,
	} = props;
	const { defaultSxCard, defaultSxCardHeader, sxDefaultCardContent } = useChatCardStyles();
	return (
		<Box sx={{ ...defaultSxCard, ...sxCard }} {...cardProps}>
			<Box sx={{ ...defaultSxCardHeader, ...sxCardHeader }} {...headerProps}>
				<Typography
					component='h3'
					variant='bigBoldBlack'
					sx={{
						color: 'customPalette.grey3',
					}}
				>
					{cardTitle}
				</Typography>
				{hasFilter ? FilterComponent : null}
				{hasInfo && !hasFilter ? <InfoComponent /> : null}
			</Box>
			<Box sx={sxDefaultCardContent}>
				{children}
			</Box>
		</Box>
	)
		;
}

function useChatCardStyles() {
	return {
		defaultSxCardHeader: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			height: 20,
		},
		defaultSxCard: {
			flexDirection: 'column',
			bgcolor: 'customPalette.grey7',
			border: '1px solid',
			borderColor: 'customPalette.grey5',
			borderRadius: 6,
			display: 'flex',
			flex: 1,
			padding: 3,
			margin: 1,
		},
		sxDefaultCardContent: {
			flex: 1,
			display: 'flex',
		},
	};
}
