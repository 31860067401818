import Grid from '@mui/material/Grid';

import ImageThumbnail from 'modules/users-chats/chat-details/print-messages/replied-message/ImageThumbnail';
import MessageInfo from 'modules/users-chats/chat-details/print-messages/replied-message/MessageInfo';

import { If } from 'shared/utilities';

export default function RepliedMessage( props ) {
	const { text, mediaType, blockReason, blockedBy, owner_info } = props;
	const hasBeenBlocked = blockReason && blockedBy;
	const textToRender = mediaType === 'image' ? 'Photo' : text;

	return (
		<Grid container sx={Sxs.GridWrapper}>
			<If condition={mediaType === 'image'}>
				<ImageThumbnail isBlocked={hasBeenBlocked} />
			</If>
			<MessageInfo
				messageText={textToRender}
				ownerName={owner_info} />
		</Grid>
	);
}

const Sxs = {
	GridWrapper: {
		alignItems: 'flex-start',
		borderColor: 'customPalette.grey1',
		borderLeft: '2px solid',
		borderRadius: '0 8px 8px 0',
		maxWidth: 470,
		marginBottom: '11px',
		'&:hover': {
			backgroundColor: 'customPalette.grey6',
			cursor: 'pointer',
		},
	},
};
