import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

/**
 * @typedef {object} useSearchParamsType
 * @property {ParsedQuery<string>} searchParams
 * @property {object} paramsController
 * @property {function} paramsController.add
 * @property {function} paramsController.set
 * @property {function} paramsController.clear
 * @property {function} paramsController.remove
 */

/**
 * @param {object} initParams
 * @returns {useSearchParamsType}
 */
const useSearchParams = (initParams = {}) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const paramsController = {
    add: (obj) => {
      const params = new URLSearchParams(location.search);

      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value) {
          params.set(key, `${value}`);
        } else {
          params.delete(key);
        }
      });
      history.push({ search: params.toString() });
    },
    set: (obj) => {
      const params = new URLSearchParams();
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value) {
          params.append(key, `${value}`);
        } else {
          params.delete(key);
        }
      });
      history.push({ search: params.toString() });
    },
    clear: () => {
      history.push({ search: '' });
    },
  };

  useEffect(() => {
    if (Object.keys(initParams).length) paramsController.add(initParams);
  }, []);

  return { searchParams, paramsController };
};

export default useSearchParams;
