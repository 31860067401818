import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import FilterButton from './FilterButton';
import DateRangePicker from 'shared/date-range-picker';

import useChatMetricsContext from 'modules/chats-metrics/hooks/useChatMetricsContext';

import { dateRangeBuilder } from 'utils';
import { STANDARD_DATES } from './STANDARD_DATES';

export default function ChatChartFilterMenu() {
  const {
    dateRangeFilter,
    setDateRangeFilter,
    checkboxSelected,
    setCheckboxSelected,
  } = useChatMetricsContext();

  function resetDateRange() {
    setCheckboxSelected('');
    setDateRangeFilter({ from: null, to: null });
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        display: 'flex',
        width: 1000,
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {STANDARD_DATES.map(({ label, name }) => {
        return (
          <FilterButton
            key={name}
            checked={checkboxSelected === name}
            onClick={(e) => {
              e.preventDefault();
              resetDateRange();
              setCheckboxSelected(name);
              setDateRangeFilter(dateRangeBuilder({ interval: name }));
            }}
          >
            {label}
          </FilterButton>
        );
      })}
      <Box width={110}>
        <DateRangePicker
          setFilter={setDateRangeFilter}
          dateBuilder={dateRangeBuilder}
          resetFilters={true}
          resetFiltersAction={resetDateRange}
          onChangeActions={() => setCheckboxSelected('custom')}
          CustomInputComponent={
            <FilterButton width={300} checked={checkboxSelected === 'custom'}>
              Date Range
            </FilterButton>
          }
        />
      </Box>
    </Stack>
  );
}
