import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';
import { InvitationFilterTypes } from 'kiwi-sdk/dist/types/analytics/invite';

import { QUERY_KEYS } from 'utils';

function useInvitationsInfoBatch(status, dateRange) {
  const api = useGraphql();
  const { agency, facilities } = useAuthContext();

  const enabledQuery = Boolean(agency && facilities[0]);

  console.log('useInvitationsInfoBatch');

  const currentStatusFilter =
    status !== 0
      ? [
          {
            term: InvitationFilterTypes.STATUS,
            value: status,
          },
        ]
      : [
          {
            term: InvitationFilterTypes.STATUS,
            value: 1,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 2,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 3,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 4,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 6,
          },
        ];

  function getUserInvitations({ pageParam = null }) {
    return api.controller.analytics.invitationInfoBatch({
      agencyId: agency?.id,
      facilityId: facilities[0]?.id,
      limit: 100,
      page: pageParam,
      dateRange: dateRange,
      filters: currentStatusFilter,
      order: 'DESC',
    });
  }

  return useInfiniteQuery({
    queryKey: [
      QUERY_KEYS.CHAT_METRICS_INVITATIONS,
      agency?.id,
      facilities[0]?.id,
      status,
      dateRange,
    ],
    queryFn: getUserInvitations,
    enabled: enabledQuery,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });
}

export default useInvitationsInfoBatch;
