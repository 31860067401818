import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import Button from 'shared/button';

import { useFormContext } from 'react-hook-form';
import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';

export function FilterTitle() {
	const { getValues, setValue } = useFormContext();

	const { setMediaTypeActiveFilters } = useAnalyticsModalContext();

	const handleClearAll = () => {
		const allValues = getValues();
		for (const key in allValues) {
			setValue(key, false);
		}
		setMediaTypeActiveFilters([]);
	};

	return (
		<FilterTitleContainer>
			<Typography variant='bigBoldBlack' fontSize={16}>
				Filters
			</Typography>
			<StyledButton disableRipple onClick={handleClearAll}>
				Clear All
			</StyledButton>
		</FilterTitleContainer>
	);
}

const StyledButton = styled(Button)({
	textTransform: 'none',
});
const FilterTitleContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	margin: '1rem',
});
