import React, { useEffect } from 'react';
import styles from './BookmarkModal.module.scss';

import { useFormContext } from 'react-hook-form';

const Input = ({ name, label, ...props }) => {
  const { register, setFocus } = useFormContext();

  useEffect(() => {
    setFocus(name);
  }, []);

  return (
    <div className={styles.input__wrapper}>
      <label className={styles.input__label}>{label}</label>
      <input
        className={styles.input}
        autoComplete="off"
        {...register(name)}
        {...props}
      />
    </div>
  );
};

export default Input;
