import React from 'react';

import Grid from '@mui/material/Grid';

import format from 'date-fns/format';
import { toTitleCase } from 'utils';

export const BlockInfo = (
	{
		status,
		blockedByMetadata,
		reason,
		begins,
		comment,
		blockedByKiwiCop,
	} ) => {
	const date = format(new Date(begins), 'MMM d, y, h:mma');
	const blockedByName = blockedByKiwiCop
		? 'KiwiCop'
		: toTitleCase(
			`${blockedByMetadata?.firstName} ${blockedByMetadata?.lastName}`,
		);
	return (
		<>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Status</div>
				<span style={{ color: '#DF093C' }}>{`${toTitleCase(
					status,
				)} / ${toTitleCase(reason)}`}</span>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Blocked by</div>
				<span>{blockedByName}</span>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Date of block</div>
				<span>{date}</span>
			</Grid>
			<Grid>
				<div>Comment:</div>
				<span>{comment || 'No comment'}</span>
			</Grid>
		</>
	);
};

export const UnBlockInfo = ( { updatedByMetadata, begins, comment } ) => {
	const date = format(new Date(begins), 'MMM d, y, h:mma');
	const unblockedByName = toTitleCase(
		`${updatedByMetadata?.firstName} ${updatedByMetadata?.lastName}`,
	);

	return (
		<>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Status</div>
				<span>Unblocked / No Violation</span>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Unblocked by</div>
				<span>{unblockedByName || 'DOC'}</span>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Date of block</div>
				<span>{date}</span>
			</Grid>
			<Grid>
				<div>Comment:</div>
				<span>{comment || 'No comment'}</span>
			</Grid>
		</>
	);
};

export const FlaggedInfo = ( {
															 createdAt,
															 flaggedByMetadata,
															 comment,
															 reason,
														 } ) => {
	const date = format(new Date(createdAt), 'MMM d, y, h:mma');
	const [, docFirstName, docLastName] = flaggedByMetadata.split(';');

	return (
		<>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Flagged</div>
				<span style={{ color: '#DF093C' }}>{reason}</span>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Flagged by</div>
				<span>{`${docFirstName} ${docLastName}` || 'DOC'}</span>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					pb: 2,
				}}
			>
				<div>Timestamp:</div>
				<span>{date}</span>
			</Grid>
			<Grid>
				<div>Comment:</div>
				<span>{comment || 'No comment'}</span>
			</Grid>
		</>
	);
};
