import { useState } from 'react';

import { ListItem, ListItemButton, Typography, ListItemIcon, ListItemText } from '@mui/material';

import query from 'query-string';

import { toast } from 'react-toastify';
import { useHistory, generatePath } from 'react-router';
import { Chat, Message, User } from './BookmarkIcons';
import { useAuthContext } from 'modules/auth/context';
import { useDeleteBookmark } from 'modules/users-chats/hooks';

import { QUERY_KEYS } from 'utils';
import { queryClient } from 'utils/queryClient';

import routes from 'routing/routes';
import BOOKMARK_TYPE from 'shared/sidebar/drawer/BookmarkTypes';

const BookmarkItem = ( { item, onClose } ) => {
	const { info, type, id, channelId, messageId, ownerId } = item;
	console.log('item', item);
	const { user } = useAuthContext();
	const history = useHistory();
	const [showRemove, setShowRemove] = useState();
	const IconComponent = getIconComponent(item.type);
	const removeItemMutation = useDeleteBookmark(type);

	const onRemoveHandler = ( e ) => {
		e.stopPropagation();
		const payload = {
			docUserInfo: user,
			ref: { id, notes: '', info: '' },
		};
		return removeItemMutation.mutate({ ...payload }, {
				onSuccess: () => {
					toast.success('The bookmark has been successfully removed');
					queryClient.invalidateQueries(QUERY_KEYS.DOC_BOOKMARKS);
				},
			},
		);
	};

	const onBtnClickHandler = ( e ) => {
		if (type === BOOKMARK_TYPE.INMATE || type === BOOKMARK_TYPE.FNF) {
			history.push(generatePath(routes.user, { userId: id }));
			onClose(e);
		}
		if (type === BOOKMARK_TYPE.CHAT) {
			history.push(generatePath(routes.chat, { userId: ownerId, chatId: id }));
			onClose(e);
		}
		if (type === BOOKMARK_TYPE.MESSAGE) {
			e.stopPropagation();
			history.push(
				`${generatePath(routes.chat, {
					userId: ownerId,
					chatId: channelId,
				})}?${query.stringify({
					messageId: messageId,
				})}`,
			);
			onClose(e);
		}
		if (type === BOOKMARK_TYPE.SEARCH) {
			handlers.search(info);
			setInput(info);
			onClose(e);
		}
	};

	return (
		<ListItem
			disablePadding
			onMouseEnter={() => setShowRemove(true)}
			onMouseLeave={() => setShowRemove(false)}
		>
			<ListItemButton onClick={onBtnClickHandler}>
				<ListItemIcon>
					<IconComponent />
				</ListItemIcon>
				<ListItemText sx={sxItemText}>
					{info}
				</ListItemText>

				{showRemove ? (
					<Typography
						variant='regularRed'
						sx={sxRemove}
						onClick={onRemoveHandler}
					>
						Remove
					</Typography>
				) : null}
			</ListItemButton>
		</ListItem>
	);
};

export default BookmarkItem;

const getIconComponent = ( type ) => {
	switch (type) {
		case BOOKMARK_TYPE.CHAT:
			return Chat;
		case BOOKMARK_TYPE.MESSAGE:
			return Message;
		case BOOKMARK_TYPE.INMATE:
		case BOOKMARK_TYPE.FNF:
			return User;
		case BOOKMARK_TYPE.SEARCH:
			return SearchIcon;
		default:
			throw new Error('Invalid saved item type');
	}
};

const sxItemText = () => {
	return {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};
};
const sxRemove = () => {
	return {
		marginLeft: '10px',
		display: 'flex',
		justifyContent: 'flex-end',
	};
};
