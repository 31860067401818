export default function useMessageStyle(props) {
  const { mine, nextDay, prevDay, nextMine, prevMine, noNextMessage } = props;
  const messageStyle = {};
  let borderRadius = mine ? '16px 16px 16px 4px' : '16px 16px 4px 16px';

  if ((mine && !prevMine) || (!mine && prevMine) || prevDay) {
    messageStyle.marginBottom = '16px';
  } else {
    messageStyle.marginBottom = '4px';
  }

  if (!nextMine && !mine && !nextDay && !noNextMessage) {
    borderRadius = '16px 4px 4px 16px';
  }

  if (nextMine && mine && !nextDay) {
    borderRadius = '4px 16px 16px 4px';
  }

  messageStyle.borderRadius = borderRadius;

  return messageStyle;
}
