import { useQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useDashboardModeration( rangeDateFilter, agency, facilities ) {
	const api = useGraphql();
	const shouldExecuteQuery = Object.entries(rangeDateFilter).every(( [_, value] ) => value !== null) && rangeDateFilter.from < rangeDateFilter.to;

	function getFlaggedMessagesAnalytics() {
		return api.controller.analytics.messageFlaggedAnalysis({
			facilityId: facilities?.[0]?.id,
			agencyId: agency?.id,
			dateRange: rangeDateFilter,
		});
	}

	const flaggedMsgsAnalyticsQuery = useQuery({
		queryKey: [QUERY_KEYS.FLAGGED_MESSAGES_ANALYSIS, agency?.id, rangeDateFilter, facilities?.[0]?.id],
		queryFn: getFlaggedMessagesAnalytics,
		enabled: shouldExecuteQuery,
		keepPreviousData: !shouldExecuteQuery,
	});

	return flaggedMsgsAnalyticsQuery;
}
