import { Card, CardContent, CardMedia, Grid } from '@mui/material';

import ImageNotification from './ImageNotification';
import MediaModal from 'modules/users-chats/media-modal';

import { BlockAlert } from './BlockAlert';
import { useMediaSrc, useToggler, useImageValidator } from 'hooks';
import { useGraphql } from 'context';
import FallbackThumbnail from './FallbackThumbnail';
import { toTitleCase } from 'utils';

export default function ImageMessage({ openModal, ...props }) {
  const {
    statusData,
    isMessageBlock,
    isMessageMine,
    mediaLocation,
    owner_info,
    recipient_info,
    mediaType,
    createdAt,
  } = props;
  const { getImages } = useGraphql();
  const isSeen = true;
  const thumbnailAlt = `Image from ${toTitleCase(owner_info)} to ${toTitleCase(
    recipient_info
  )}`;
  const [modal, toggleModal] = useToggler();
  const { sxCardContent, sxCard, sxGridContainer, sxCarMedia, sxGridMedia } =
    useImageMessageStyles({ isMessageBlock, isMessageMine });
  const [thumbnailSrc] = useMediaSrc(getImages)({
    itemKey: mediaLocation,
    parseScheme: (key) => key.replace('.jpeg', '_t.jpeg'),
  });
  const [imageSrc] = useMediaSrc(getImages)({ itemKey: mediaLocation });

  const [isValidSrc] = useImageValidator(thumbnailSrc);

  return (
    <Grid container direction="row" height={'100%'} sx={sxGridContainer}>
      <Card elevation={1} sx={sxCard} onClick={() => toggleModal(true)}>
        <Grid item sx={sxGridMedia}>
          {isValidSrc ? (
            <CardMedia
              sx={sxCarMedia}
              image={thumbnailSrc}
              title={thumbnailAlt}
              component="img"
            />
          ) : (
            <FallbackThumbnail text={thumbnailAlt} />
          )}
        </Grid>
        <CardContent sx={sxCardContent}>
          <ImageNotification isSeen={isSeen} date={createdAt} />
        </CardContent>
      </Card>
      <Grid item>
        <BlockAlert
          isMessageBlock={isMessageBlock}
          reason={statusData?.reason}
        />
      </Grid>
      <MediaModal
        open={modal}
        toggleOpen={toggleModal}
        src={imageSrc}
        thumbnailSrc={thumbnailSrc}
        {...props}
      />
    </Grid>
  );
}

function useImageMessageStyles({ isMessageBlock, isMessageMine }) {
  return {
    sxCard: {
      borderRadius: '8px',
    },
    sxGridContainer: {
      flexDirection: isMessageMine ? 'row' : 'row-reverse',
      justifyContent: !isMessageMine ? 'flex-start' : 'flex-end',
    },
    sxGridMedia: {
      backgroundImage:
        'linear-gradient(to right top, #44444a, #344551,#1b484c,#1d4836,#374419)',
    },
    sxCardContent: {
      backgroundColor: isMessageBlock ? 'customPalette.error' : undefined,
      color: isMessageBlock ? 'white' : undefined,
    },
    sxCarMedia: { height: 280, width: 280, cursor: 'pointer' },
  };
}
