import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import styled from '@mui/material/styles/styled';
import Skeleton from '@mui/material/Skeleton';

import Avatar from 'shared/avatar';
import UserLink from 'shared/user-link';
import { If } from 'shared/utilities';

import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';
import { useAvatarPhoto, useChannelInfo, useUserProfileInfo } from 'hooks';
import { formatPhoneNumber, toTitleCase } from 'utils';

export default function ChatOwnersInfo({ children }) {
  const { messageAndChannelSelected } = useAnalyticsModalContext();
  const { data, isLoading } = useChannelInfo(
    messageAndChannelSelected?.channelId
  );
  const members = data ? data?.ChannelData?.members : [{}, {}];

  const { data: memberOneData } = useUserProfileInfo(members[0]?.id);
  const { data: memberTwoData } = useUserProfileInfo(members[1]?.id);
  const currentMemberOnePhoto = memberOneData?.photo
    ? memberOneData?.photo
    : memberOneData?.avatarModerationStatus?.mediaLocation;
  const currentMemberTwoPhoto = memberTwoData?.photo
    ? memberTwoData?.photo
    : memberTwoData?.avatarModerationStatus?.mediaLocation;
  const { thumbnailSrc: avatarOneSrc } = useAvatarPhoto(
    currentMemberOnePhoto || ''
  );
  const { thumbnailSrc: avatarTwoSrc } = useAvatarPhoto(
    currentMemberTwoPhoto || ''
  );

  const photoOneIsBlocked =
    memberOneData?.photoModerationStatus === 'HAS_VIOLATION';
  const photoTwoIsBlocked =
    memberTwoData?.photoModerationStatus === 'HAS_VIOLATION';

  return (
    <OwnersInfoContainer>
      <If
        condition={!isLoading}
        otherwise={
          <Skeleton
            variant="circular"
            animation="wave"
            width={37}
            height={37}
          />
        }
      >
        <Avatar
          id={members[0]?.id}
          firstName={members[0]?.firstName}
          lastName={members[0]?.lastName}
          src={avatarOneSrc}
          isBlur={photoOneIsBlocked}
          isBlocked={memberOneData?.isBlocked}
          isActive={memberOneData?.active}
          sxAvatar={Sxs.Avatar}
        />
      </If>
      <Info>
        <If
          condition={!isLoading}
          otherwise={
            <Skeleton variant="text" animation="wave" width={100} heigth={12} />
          }
        >
          <UserLink
            name={toTitleCase(
              `${members[0]?.firstName} ${members[0]?.lastName}`
            )}
            id={members[0]?.id}
            sxLink={Sxs.UserLink}
          />
        </If>
        <If
          condition={!isLoading}
          otherwise={
            <Skeleton variant="text" animation="wave" width={60} heigth={12} />
          }
        >
          <UserId fontSize={14}>{formatPhoneNumber(members[0]?.eid)}</UserId>
        </If>
      </Info>
      <StyledDividers orientation="vertical" />
      {children}
      <StyledDividers orientation="vertical" />
      <Info>
        <If
          condition={!isLoading}
          otherwise={
            <Skeleton variant="text" animation="wave" width={100} heigth={12} />
          }
        >
          <UserLink
            name={toTitleCase(
              `${members[1]?.firstName} ${members[1]?.lastName}`
            )}
            id={members[1]?.id}
            sxLink={Sxs.UserLink}
          />
        </If>
        <If
          condition={!isLoading}
          otherwise={
            <Skeleton variant="text" animation="wave" width={60} heigth={12} />
          }
        >
          <UserId>{formatPhoneNumber(members[1]?.eid)}</UserId>
        </If>
      </Info>
      <If
        condition={!isLoading}
        otherwise={
          <Skeleton
            variant="circular"
            animation="wave"
            width={37}
            height={37}
          />
        }
      >
        <Avatar
          id={members[1]?.id}
          firstName={members[1]?.firstName}
          lastName={members[1]?.lastName}
          src={avatarTwoSrc}
          isBlur={photoTwoIsBlocked}
          isBlocked={memberTwoData?.isBlocked}
          isActive={memberTwoData?.active}
          sxAvatar={Sxs.Avatar}
        />
      </If>
    </OwnersInfoContainer>
  );
}

const UserId = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.customPalette.grey3,
}));

const OwnersInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

const Info = styled(Box)(({ theme, reverse }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: reverse ? 'flex-end' : 'flex-start',
}));

const StyledDividers = styled(Divider)(({ theme }) => ({
  height: 40,
  backgroundColor: theme.palette.customPalette.grey6,
}));

const Sxs = {
  UserLink: {
    fontSize: 12,
    color: 'customPalette.grey3',
    fontWeight: 700,
    lineHeight: '22px',
  },
  Avatar: {
    width: 30,
    height: 30,
    fontSize: 12,
  },
};
