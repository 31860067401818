import { useState } from 'react';
import { useToggle } from 'react-use';

import { Box, Typography } from '@mui/material';

import Suspense from 'shared/suspense-list';
import CardContentLayout from 'modules/chats-metrics/charts/chart-card/CardContentLayout';
import KiwiPieChart from 'shared/pie-chart';
import InvitationModal from './ChatMetricsInvitationsModal';
import ChartCard from 'modules/chats-metrics/charts/chart-card/ChartCard';

import { If } from 'shared/utilities';
import { PieChartLegend } from 'shared/chart-legend';
import { ChartLoader } from 'shared/loaders';

import useChatMetricsInvitations from 'modules/chats-metrics/hooks/useChatMetricsInvitations';

import { useAuthContext } from 'modules/auth/context';
import useChatMetricsContext from '../../hooks/useChatMetricsContext';

export default function InvitationsChart() {
  const [openInvitationModal, toggleOpenInvitationModal] = useToggle(false);
  const [selectedInvitationStatus, setSelectedInvitationStatus] = useState(1);
  const { agency, facilities } = useAuthContext();
  const { dateRangeFilter } = useChatMetricsContext();
  const invitationsQuery = useChatMetricsInvitations(
    agency?.id,
    facilities?.[0]?.id,
    dateRangeFilter
  );
  const { data = {}, isLoading } = invitationsQuery;

  const dataIsNotEmpty = Object.keys(data)
    .map((key) => data[key])
    .some((value) => value > 0);

  const dataArray = Object.entries(data);

  const total = dataArray.reduce((accumulator, [key, value]) => {
    return key !== 'total' ? accumulator + value : accumulator;
  }, 0);

  const formatedDataToDisplay = dataArray
    .filter(([key]) => key !== 'total')
    .map(([key, value], index) => ({
      name: getProperStatus(key),
      value,
      color: COLORS[index],
    }));

  function getProperStatus(status) {
    if (status === 'sent') return 'pending';
    if (status === 'denied') return 'rejected';
    return status;
  }

  const selectInvitationFromLegend = (invitationType) => {
    toggleOpenInvitationModal();
    setSelectedInvitationStatus(invitationEnum[invitationType.toUpperCase()]);
  };

  const onClickInnerText = () => {
    toggleOpenInvitationModal();
    setSelectedInvitationStatus(0);
  };

  return (
    <ChartCard cardTitle="Invitations">
      <Suspense
        loading={isLoading}
        total={dataIsNotEmpty ? 1 : 0}
        fallback={<ChartLoader />}
        noResultComponent={<EmptyResult />}
      >
        <CardContentLayout
          chartType="pie"
          LegendComponent={() => (
            <PieChartLegend
              legendData={formatedDataToDisplay}
              nameKey="name"
              valueKey="value"
              colorPalette={COLORS}
              clickable={true}
              onClickItem={selectInvitationFromLegend}
            />
          )}
          ChartComponent={() => (
            <KiwiPieChart
              chartData={formatedDataToDisplay}
              legendData={formatedDataToDisplay}
              chartDataKey="value"
              chartNameKey="name"
              innerText="Total"
              innerValue={total}
              colorPalette={COLORS}
              clickableInner={true}
              onClickInner={onClickInnerText}
            />
          )}
        />
      </Suspense>
      <If condition={openInvitationModal}>
        <InvitationModal
          openInvitationModal={openInvitationModal}
          toggleOpenInvitationModal={toggleOpenInvitationModal}
          selectedInvitationStatus={selectedInvitationStatus}
          setSelectedInvitationStatus={setSelectedInvitationStatus}
          dateRange={dateRangeFilter}
        />
      </If>
    </ChartCard>
  );
}

const invitationEnum = {
  SENT: null,
  PENDING: '1',
  WITHDRAWN: '2',
  REJECTED: '3',
  ACCEPTED: '4',
  DUPLICATED: '5',
  EXPIRED: '6',
};

function EmptyResult() {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography component="h3">No data available to display</Typography>
    </Box>
  );
}

const COLORS = [
  '#468B97',
  '#EF6262',
  '#F3AA60',
  '#00C1D4',
  '#C490E4',
  '#75C2F6',
  '#BA704F',
];
