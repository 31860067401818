function SvgIcon(props) {
  const { children, size, color, ...rest } = props;
  const styles = {
    width: size || '24px',
    height: 'auto',
    fill: color || 'currentColor',
  };
  const enabledStylesProps = size || color ? styles : {};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={enabledStylesProps}
      {...rest}
    >
      {props.children}
    </svg>
  );
}

export default SvgIcon;
