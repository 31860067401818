import { Box, Grid } from '@mui/material';

import CustomTabs from 'shared/custom-tab';

import { PromptTabLabels } from 'modules/global-search/results-tab/PromptTabs';

export default function SearchTabs( { tab, setTab } ) {
	const { sxCustomTabsContainer } = useSearchTabsStyles();
	return (
		<TabsContainerGrid>
			<Box sx={sxCustomTabsContainer}>
				<CustomTabs
					value={tab}
					onChange={( evt, tab ) => setTab(tab)}
					tabsList={PromptTabLabels}
				/>
			</Box>
		</TabsContainerGrid>
	);
}

function TabsContainerGrid( { children } ) {
	return (
		<Grid
			container
			direction='row'
			sx={{ width: '100%', justifyContent: 'center' }}
		>
			{children}
		</Grid>
	);
}

function useSearchTabsStyles() {
	return {
		sxCustomTabsContainer: {
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'flex-start',
			width: '90%',
			height: '70px',
			borderBottom: '1px solid',
			borderColor: 'customPalette.grey5',
		},
	};
}