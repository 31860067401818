import Avatar from 'shared/avatar';
import { useAvatarPhoto } from 'hooks';

export default function ChatAvatar( props ) {
	const { id, firstName, lastName, isFnf, isBlocked, mediaLocation, isBlur, isActive } = props;
	const { sxAvatar } = useChatAvatarStyle(isFnf);
	const { thumbnailSrc: avatarSrc } = useAvatarPhoto(mediaLocation);

	return <Avatar
		id={id}
		firstName={firstName}
		lastName={lastName}
		sxAvatar={sxAvatar}
		isBlocked={isBlocked}
		enableModal={false}
		src={avatarSrc}
		isBlur={isBlur}
		isActive={isActive}
	/>;
};

const useChatAvatarStyle = ( isFnf ) => ({
	sxAvatar: {
		width: '50px',
		height: '50px',
		borderColor: !isFnf ? 'customPalette.lightPurple' : 'primary.main',
	},
});
