import { useState } from 'react';
import DatePicker from 'react-datepicker';
import './date-filter.css';

function DateRangePicker(props) {
  const {
    dateBuilder,
    setFilter,
    CustomInputComponent,
    resetFilters,
    resetFiltersAction,
    onChangeActions,
  } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (resetFilters) {
      resetFiltersAction();
    }

    setFilter({
      ...dateBuilder({
        interval: 'custom',
        from: start ? start.setHours(0, 0, 0, 0) : null,
        to: end ? end.setHours(0, 0, 0, 0) : start.setHours(0, 0, 0, 0),
      }),
    });

    onChangeActions && onChangeActions();
  };
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      maxDate={new Date()}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      showDisabledMonthNavigation
      customInput={CustomInputComponent}
    />
  );
}

export default DateRangePicker;
