import React from 'react';

const Play = (props) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
    <path
      d="M47.5152 23.307L18.1752 8.47228C17.0511 7.83503 15.7805 7.5 14.4878 7.5C13.195 7.5 11.9244 7.83503 10.8003 8.47228C9.70144 9.21995 8.8197 10.2438 8.24419 11.4405C7.66867 12.6372 7.41978 13.9642 7.52264 15.2876V44.7753C7.42444 46.0981 7.67541 47.4235 8.25052 48.6194C8.82564 49.8152 9.7048 50.8397 10.8003 51.5906C11.8162 52.2016 12.9832 52.5163 14.1691 52.4993C15.5661 52.4755 16.9381 52.1254 18.1752 51.477L47.5152 36.6878C51.8627 34.416 52.5 31.599 52.5 30.0542C52.5 28.5094 51.8627 25.5106 47.5152 23.307ZM45.4438 32.5304L16.1266 47.3651C15.6942 47.6508 15.1978 47.8252 14.6814 47.8728C14.1651 47.9203 13.645 47.8396 13.1676 47.6377C12.7637 47.2871 12.4526 46.8424 12.262 46.3431C12.0715 45.8438 12.0072 45.3053 12.075 44.7753V15.1059C12.0072 14.5759 12.0715 14.0374 12.262 13.5381C12.4526 13.0388 12.7637 12.5941 13.1676 12.2434C13.4464 12.0881 13.7588 12.0023 14.078 11.9935C14.7641 12.0209 15.4344 12.2076 16.0356 12.5388L45.4438 27.3735C46.9916 28.1459 47.9476 29.1455 47.9476 29.9406C47.9476 30.7357 46.9916 31.7353 45.4438 32.5304Z"
      fill="currentColor"
    />
  </svg>
);

export default Play;
