import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { of, Subject, from } from 'rxjs';
import { tap, switchMap, catchError, map } from 'rxjs/operators';

import { useSearchParams, useToggler } from 'hooks';
import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';

const BulletinsContext = createContext({});

const initBulletinsContext = () => {
  const api = useGraphql();
  const { paramsController } = useSearchParams();

  const [loadBulletins$] = useState(new Subject());

  const nextPage = useRef(undefined);
  const term = useRef('');
  const filteredBulletins = useRef([]);

  const [loading, toggleLoading] = useToggler(true);
  const [bulletins, setBulletins] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filterState, setFilterState] = useState({});
  const [checkedFilterState, setCheckedFilterState] = useState({});
  const { strictPermissionCheck } = useAuthContext();

  const hasPermission = {
    createBulletin: strictPermissionCheck('BULLETIN#WRITE#sendBulletin'),
  };

  function fetchBulletins(term, filters) {
    const dateRange = filters?.dateRange;
    const observer = from(
      api.controller.search.bulletins({
        term,
        filters,
        dateRange,
        sortBy: [{ by: 'createdAt', order: 'desc' }],
        limit: 40,
        nextPage: nextPage.current,
      })
    ).pipe(
      catchError((err) => {
        console.error(err);
        return of([]);
      })
    );

    nextPage.current = false;
    return observer;
  }

  useEffect(() => {
    const loadSub = loadBulletins$
      .pipe(
        tap(() => toggleLoading(true)),
        switchMap(({ firstPage, input, filters }) => {
          return fetchBulletins(input, filters).pipe(
            tap((res) => {
              nextPage.current = res?.data?.nextPage;
            }),
            map((result) => ({ result, payload: { firstPage, input } }))
          );
        })
      )
      .subscribe(({ result, payload }) => {
        if (payload.firstPage && result) {
          setBulletins(result.items.bulletin || []);
        } else if (!result) {
          setBulletins([]);
        } else {
          setBulletins((state) => [...state, ...result]);
        }
        toggleLoading(false);
      });

    loadBulletins$.next({ firstPage: true });

    return () => {
      loadSub.unsubscribe();
    };
  }, []);

  const loadBulletins = useCallback(() => {
    nextPage.current = undefined;
    loadBulletins$.next({ firstPage: true, input: '' });
  }, [loadBulletins$]);

  const loadNextPage = useCallback(() => {
    if (nextPage.current) {
      loadBulletins$.next({
        firstPage: false,
        input: term.current,
        filters: filteredBulletins.current,
      });
    }
  }, [loadBulletins$]);

  const searchBulletins = useCallback(
    (input = '') => {
      nextPage.current = undefined;
      term.current = input;
      setBulletins([]);
      setSearchInput(input);
      paramsController.set({ search: input });
      loadBulletins$.next({ firstPage: true, input });
    },
    [loadBulletins$]
  );

  const filterBulletins = useCallback(
    (filterResults) => {
      filteredBulletins.current = filterResults;
      nextPage.current = undefined;
      term.current = '';
      setBulletins([]);
      loadBulletins$.next({
        firstPage: true,
        filters: filteredBulletins.current,
        input: term.current,
      });
    },
    [loadBulletins$]
  );

  return {
    bulletins,
    setBulletins,
    loading,
    loadBulletins,
    loadNextPage,
    searchInput,
    setSearchInput,
    searchBulletins,
    filterBulletins,
    filterState,
    setFilterState,
    checkedFilterState,
    setCheckedFilterState,
    hasPermission,
  };
};

const BulletinsProvider = ({ children, ...props }) => {
  const {
    nextPage,
    bulletins,
    setBulletins,
    loading,
    loadBulletins,
    loadNextPage,
    searchInput,
    setSearchInput,
    searchBulletins,
    filterBulletins,
    filterState,
    setFilterState,
    checkedFilterState,
    setCheckedFilterState,
    hasPermission,
  } = initBulletinsContext(props);

  const contextValue = useMemo(
    () => ({
      bulletins,
      setBulletins,
      nextPage,
      loading,
      loadBulletins,
      loadNextPage,
      searchInput,
      setSearchInput,
      searchBulletins,
      filterBulletins,
      filterState,
      setFilterState,
      checkedFilterState,
      setCheckedFilterState,
      hasPermission,
    }),
    [
      bulletins,
      nextPage,
      setBulletins,
      loading,
      loadBulletins,
      loadNextPage,
      searchInput,
      setSearchInput,
      searchBulletins,
      filterBulletins,
      filterState,
      setFilterState,
      checkedFilterState,
      setCheckedFilterState,
      hasPermission,
    ]
  );

  return (
    <BulletinsContext.Provider value={contextValue}>
      {children}
    </BulletinsContext.Provider>
  );
};

/**
 * @typedef {Object} BulletinsContext
 * @property {array} bulletins,
 * @property {boolean} loading,
 * @property {function} loadBulletins,
 * @property {function} loadNextPage,
 * @property {string} searchInput,
 * @property {function} setSearchInput,
 * @property {function} searchBulletins,
 */

/**
 * @returns {BulletinsContext}
 */
const useBulletinsContext = () => {
  const context = useContext(BulletinsContext);

  return context;
};

export { BulletinsProvider, useBulletinsContext };
