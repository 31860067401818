import { Grid, Box, Typography } from '@mui/material';
import millisecondsToSeconds from 'date-fns/millisecondsToSeconds';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';

export default function MessageInfo( { ownerName, messageText, location, msDuration, transcriptionText } ) {
	return <Grid item pl={1} sx={{ width: '99%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
		<ParentOwnerName name={ownerName} />
		{location ?
			<ParentAudioMsgInfo duration={msDuration} text={transcriptionText} />
			: <ParentTextMessage text={messageText} />}
	</Grid>;
};

const ParentTextMessage = ( { text } ) => {
	return <>
		<Typography noWrap variant='messageText' fontSize={12} >
			{text}
		</Typography>
	</>;
};

const ParentOwnerName = ( { name } ) => {
	return <Typography variant='repliedMsgName' component='p'>
		{name}
	</Typography>;
};

const ParentAudioMsgInfo = ( { duration, text } ) => {
	const durationInSeconds = millisecondsToSeconds(duration);
	const formatTimes = ( s ) => {
		return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
	};

	return <Box sx={audioInfoStyle.boxWrapper}>
		<MicNoneOutlinedIcon color='primary' sx={audioInfoStyle.icon} />
		<Typography variant='messageText' fontSize={12} component='span' ml={1}>
			{formatTimes(durationInSeconds)}
		</Typography>
		{text ? <Typography variant='messageText' color='customPalette.grey3' fontSize={12} component='span' ml={1} noWrap>
			{text}
		</Typography> : null}
	</Box>;
};

const audioInfoStyle = {
	boxWrapper: { display: 'flex', alignItems: 'center' },
	icon: {
		'&.MuiSvgIcon-root': {
			width: 16,
			height: 16,
		},
	},
};