import { memo } from 'react';

import Box from '@mui/material/Box';

import TextMessageContainer from './TextMessageContainer';
import RepliedMessage from 'shared/replied-message';
import MessageNotification from 'shared/message-notification/MessageNotification';
import TextMessageContent from 'shared/chat-message/text-message/TextMessageContent';

import { If } from 'shared/utilities';

function TextMessage( props ) {
	const { parentMessageMetadata, isMessageBlock, text, clientKey, mine } = props;
	const Sxs = getSxs();
	return (
		<Box sx={Sxs.BoxWrapper}>
			<TextMessageContainer
				size='medium'
				isBlock={isMessageBlock}
				isMine={mine}
			>
				<If condition={parentMessageMetadata}>
					<RepliedMessage parentInfo={parentMessageMetadata} />
				</If>
				<TextMessageContent text={text} isMine={mine} />
				<MessageNotification date={clientKey} />
			</TextMessageContainer>
		</Box>
	);
}

export default memo(TextMessage);

function getSxs() {
	return {
		BoxWrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginY: 0.5,
		},
	};
};
