import styles from './MediaModal.module.scss';

import Grid from '@mui/material/Grid';

import { SubmitBlockButton } from './SubmitBlockButton';
import { SubmitUnBlockButton } from './SubmitBlockButton';

import CancelButton from './CancelButton';
import Form from 'shared/form';
import FormLoader from 'shared/form-loader';

import { SelectInput, MultilineInput } from 'shared/inputs';

import { BLOCKING_REASONS } from '../constants';

const BlockFormInputs = ({
  isBlocked,
  initialValues,
  handleBlockSubmit,
  owner_type,
  onClose,
  loading,
}) => {
  return (
    <Form
      className={styles.form}
      initialValues={initialValues}
      onSubmit={handleBlockSubmit}
    >
      {!isBlocked ? (
        <SelectInput
          name="reason"
          label="Blocking reason"
          placeholder="Choose a reason"
          options={BLOCKING_REASONS}
          variant="outlined"
          outlined
          required
        />
      ) : null}

      <MultilineInput
        className={styles.form__note}
        name="note"
        label="Internal notes"
        placeholder="Type here"
        helperText={`Optional (not visible ${
          owner_type === 'Inmate' ? 'to inmate' : 'for the user'
        })`}
        maxLength={500}
      />

      <Grid
        direction="row-reverse"
        justifyContent="space-between"
        wrap="nowrap"
        container
      >
        <Grid justifyContent="flex-end" wrap="nowrap" container item>
          <CancelButton onClose={onClose} loading={loading} />
          {isBlocked ? (
            <SubmitUnBlockButton />
          ) : (
            <SubmitBlockButton loading={loading} isBlocked={isBlocked} />
          )}
        </Grid>
        <FormLoader loading={loading} />
      </Grid>
    </Form>
  );
};

export default BlockFormInputs;
