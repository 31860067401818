import useAppPermisions from './useAppPermisions';
const useInitialPermissionsValues = (currentPermissions = []) => {
  const { formInitialPermissions } = useAppPermisions();

  const initialPermissionsValues = JSON.parse(
    JSON.stringify(formInitialPermissions)
  );
  const currentArrPermissionLevels = Object.fromEntries(
    currentPermissions.map((item) => {
      const initValue = item.includes('#') ? true : 'FULL_ACCESS';
      return [item, initValue];
    })
  );

  const initialPermissionsLevel = Object.assign(
    initialPermissionsValues,
    currentArrPermissionLevels
  );

  return [initialPermissionsLevel];
};

export default useInitialPermissionsValues;
