import React from 'react';
import styles from './CreateStaff.module.scss';

import { useFormContext } from 'react-hook-form';

const NameEmail = () => {
  const { watch } = useFormContext();

  const [firstName, lastName, email] = watch([
    'firstName',
    'lastName',
    'email',
  ]);

  return (
    <div className={styles.nameEmail}>
      <p>{`${firstName} ${lastName}`}</p>
      <p>{email}</p>
    </div>
  );
};

export default NameEmail;
