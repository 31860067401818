import { useQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useDashboardSentiments( rangeDateFilter, agency, facilities ) {
	const api = useGraphql();
	const shouldExecuteQuery = Object.entries(rangeDateFilter).every(( [_, value] ) => value !== null);

	function getSentimentsAnalysis() {
		return api.controller.analytics.messageSentimentAnalysis({
			agencyId: agency?.id,
			facilityId: facilities[0]?.id,
			dateRange: rangeDateFilter,
		});
	}

	const sentimentsAnalysisQuery = useQuery({
		queryKey: [QUERY_KEYS.SENTIMENT_ANALYSIS, agency?.id, rangeDateFilter, facilities[0]?.id],
		queryFn: getSentimentsAnalysis,
		enabled: shouldExecuteQuery,
		keepPreviousData: !shouldExecuteQuery,
	});

	return sentimentsAnalysisQuery;

}
