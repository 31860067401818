import format from 'date-fns/format';

export default function useMessageInfo( props ) {
	const { messageData, nextMessageData, prevMessageData, currentKeyEqualZero = false } = props;
	const { owner_id, clientKey, owner_type, recipient_id, userId } = messageData;

	const alternativeUserId = owner_type.toLowerCase() === 'inmate' ? owner_id : recipient_id;
	const currentUserId = userId || alternativeUserId;
	const isMessageMine = owner_id === currentUserId;

	const clientKeyDate = clientKey ? format(new Date(clientKey), 'dd.MM.yyyy') : null;
	const nextMsgDate = nextMessageData?.clientKey ? format(new Date(nextMessageData?.clientKey), 'dd.MM.yyyy') : null;
	const prevMsgDate = prevMessageData?.clientKey ? format(new Date(prevMessageData?.clientKey), 'dd.MM.yyyy') : null;

	const nextDay = nextMsgDate && nextMsgDate !== clientKeyDate;
	const prevDay = prevMsgDate && prevMsgDate !== clientKeyDate;

	if (currentKeyEqualZero) {
		const showDate = prevDay || !prevMessageData;
		const showOwnerInfo = nextMessageData?.owner_id !== messageData?.owner_id || nextDay;

		return { isMessageMine, showOwnerInfo, showDate };
	}

	const showDate = nextDay || !nextMessageData;
	const showOwnerInfo = prevMessageData?.owner_id !== messageData?.owner_id || prevDay;

	return { isMessageMine, showOwnerInfo, showDate };
}
