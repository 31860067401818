import SvgIcon from '@mui/material/SvgIcon';

const BoundaryError = ( props ) => (
	<SvgIcon width='893' height='734' viewBox='0 0 893 734' preserveAspectRatio='xMidYMid meet' {...props}>
		<g transform='translate(0.000000,734.000000) scale(0.100000,-0.100000)'
			 fill={props.color || '#22A699'} stroke='none'>
			<path d='M2940 5833 c0 -1288 -18 -1143 146 -1143 l104 0 0 -221 c0 -133 4
 -228 10 -240 11 -19 3 -19 -413 -19 l-425 0 15 68 c17 78 19 186 4 223 -5 15
 -22 42 -38 60 -16 19 -41 50 -56 70 -92 126 -423 128 -602 4 -54 -38 -90 -83
 -106 -136 -12 -40 -15 -186 -5 -246 l8 -43 -652 0 c-669 0 -677 0 -707 -39
 -10 -12 -12 -292 -13 -1233 l0 -1217 24 -28 24 -28 1586 1 c872 0 1586 -2
1586 -5 0 -3 -16 -17 -36 -30 -20 -14 -60 -52 -90 -85 l-54 -59 -97 41 c-53
23 -100 39 -104 34 -13 -13 -100 -225 -96 -235 1 -5 43 -26 92 -47 l90 -38 1
 -129 1 -129 -99 -39 c-54 -21 -98 -40 -98 -41 0 -5 92 -238 96 -243 2 -2 49
13 105 34 l102 39 31 -40 c37 -46 59 -69 105 -107 38 -31 38 -28 -12 -144 -27
 -61 -33 -84 -24 -91 10 -8 223 -102 232 -102 1 0 22 44 44 98 l42 97 127 -2
127 -3 39 -95 c21 -52 43 -95 50 -95 14 0 218 80 230 91 5 4 -8 50 -29 102
l-37 95 90 89 91 90 91 -39 c50 -22 96 -38 102 -36 14 5 109 231 101 239 -5 4
 -48 25 -97 46 l-89 38 1 124 c1 69 5 127 9 131 4 4 47 23 95 41 49 19 88 40
88 46 0 7 -21 64 -46 127 l-46 115 -51 -20 c-29 -12 -74 -29 -102 -40 l-50
 -19 -30 37 c-36 46 -81 92 -117 120 l-28 21 40 91 c22 51 40 97 40 104 0 11
 -212 107 -235 107 -5 0 -28 -44 -51 -98 l-41 -97 -39 5 c-21 3 -78 4 -126 3
l-86 -1 -40 96 c-24 62 -44 97 -53 96 -8 -1 -38 -11 -66 -23 l-53 -21 1 203 0
202 52 44 c29 24 119 105 201 180 l150 136 130 30 c107 25 145 29 206 24 130
 -9 185 27 128 84 l-24 24 34 39 c85 99 113 143 110 171 -5 41 -54 44 -105 6
l-37 -28 22 36 c33 54 29 83 -15 114 -48 32 -60 32 -112 -7 -36 -26 -42 -28
 -32 -11 27 51 3 101 -42 89 -13 -3 -59 -42 -101 -86 -69 -72 -82 -82 -127 -90
 -106 -20 -230 -82 -369 -183 l-65 -48 -3 422 c-1 232 -1 423 0 423 2 1 517 0
1146 -2 l1142 -2 0 -94 c0 -419 226 -868 579 -1149 l76 -60 3 29 c38 379 102
1057 100 1063 -2 5 -42 -7 -89 -25 -128 -49 -245 -70 -312 -55 -111 24 -170
96 -171 206 -1 33 3 70 7 83 l8 22 -1229 0 c-1178 0 -1230 1 -1245 18 -16 17
 -17 108 -17 1100 0 715 -3 1082 -10 1082 -7 0 -10 -288 -10 -845 l0 -845 -103
0 c-67 0 -107 4 -115 12 -9 9 -12 267 -12 1100 0 960 -2 1088 -15 1088 -13 0
 -15 -101 -15 -850 l0 -850 -93 0 c-149 0 -132 -146 -135 1111 -1 696 -6 1087
 -12 1093 -7 7 -10 -363 -10 -1081z m-745 -1160 c22 -12 48 -30 58 -41 l19 -20
 -64 20 c-52 16 -82 19 -168 15 -108 -5 -152 -17 -203 -57 -55 -44 -97 -160
 -112 -313 -6 -64 -8 -68 -28 -63 -12 3 -28 6 -36 6 -11 0 -12 16 -7 88 24 306
112 396 381 389 98 -3 127 -7 160 -24z m82 -229 c31 -16 65 -40 75 -54 18 -25
18 -29 -2 -131 -16 -83 -20 -138 -18 -255 3 -165 -1 -182 -64 -245 -95 -95
 -264 -95 -385 1 -28 22 -43 25 -43 10 0 -18 104 -78 164 -96 41 -11 76 -14
113 -10 68 8 68 -2 -1 -79 l-53 -59 -157 89 -156 90 0 77 c0 62 3 79 17 87 13
7 25 2 50 -20 48 -41 102 -67 161 -75 30 -4 58 -14 68 -25 37 -41 134 -14 134
38 0 14 11 38 25 55 14 16 25 33 25 37 0 5 -37 13 -82 19 -46 7 -93 14 -105
18 -19 5 -23 2 -23 -15 0 -12 5 -31 10 -41 7 -13 6 -27 -2 -45 -10 -22 -15
 -25 -47 -19 -56 10 -113 38 -149 73 -31 31 -31 33 -17 62 27 51 30 111 7 162
l-21 47 60 59 c64 64 102 134 119 218 13 62 16 63 147 59 79 -3 101 -8 150
 -32z m-579 -259 c73 -31 112 -86 112 -159 0 -53 -14 -93 -43 -124 -77 -83
 -212 -70 -277 25 -19 28 -25 49 -25 93 0 72 34 128 97 159 51 25 85 26 136 6z
m-200 -22 c-62 -67 -76 -154 -35 -233 41 -82 147 -129 226 -102 l31 11 0 -68
0 -68 -66 -82 c-37 -44 -72 -81 -79 -81 -20 0 -213 -98 -275 -140 -170 -115
 -245 -257 -334 -630 -43 -178 -71 -340 -96 -545 -11 -94 1 -193 30 -241 39
 -62 180 -115 349 -130 80 -8 78 -3 65 -111 l-7 -53 -518 0 c-506 0 -520 1
 -539 20 -20 20 -20 33 -20 1225 l0 1206 25 24 24 25 622 0 623 0 -26 -27z
m1929 4 c17 -16 18 -64 21 -681 l2 -664 -92 -65 -93 -65 -25 27 c-14 15 -70
81 -125 147 -154 183 -335 360 -445 433 -95 63 -246 143 -330 175 -40 15 -52
27 -98 100 -28 46 -52 88 -52 94 0 7 13 18 29 26 47 25 91 66 111 106 11 21
35 48 54 59 40 25 86 109 86 156 0 47 -18 95 -53 139 l-30 38 512 -4 c471 -3
512 -5 528 -21z m-1000 -71 c32 -62 15 -153 -40 -204 l-24 -23 -7 141 c-4 77
 -4 145 0 151 8 13 50 -25 71 -65z m-287 -222 c58 -6 60 -7 44 -25 -12 -14 -20
 -16 -30 -8 -7 5 -32 11 -56 12 -33 2 -44 6 -46 21 -3 14 0 17 11 13 9 -3 43
 -9 77 -13z m2026 -658 c8 -21 -18 -74 -79 -158 -30 -42 -52 -79 -49 -82 3 -4
54 41 112 99 59 58 112 105 119 105 18 0 24 -26 12 -49 -6 -11 -45 -63 -87
 -116 -41 -53 -73 -102 -71 -108 4 -14 35 19 123 136 40 53 77 98 81 100 35 11
 -28 -119 -109 -224 -15 -20 -28 -42 -28 -48 0 -22 18 -10 46 31 34 49 158 158
180 158 27 0 27 -30 -2 -68 -16 -20 -64 -74 -108 -120 -89 -92 -98 -97 -321
 -147 -112 -25 -121 -29 -171 -73 -432 -388 -486 -433 -559 -468 -116 -55 -238
 -20 -366 107 -38 37 -69 72 -69 77 0 10 419 298 430 295 5 -1 102 63 217 144
223 156 333 216 441 238 65 14 69 16 147 100 80 84 101 98 111 71z m159 -422
c27 -28 1 -44 -69 -44 l-61 1 37 19 c20 11 40 24 43 30 9 15 31 12 50 -6z
m-1540 -425 c77 -93 167 -174 232 -205 47 -24 71 -29 128 -29 80 1 132 23 226
96 30 23 60 46 67 52 9 7 12 -31 12 -185 l0 -195 -50 -21 -49 -21 29 -72 c17
 -39 30 -78 30 -85 0 -12 -60 -14 -340 -14 l-340 0 0 303 c0 166 -3 332 -7 370
 -3 37 -2 67 3 67 5 0 32 -28 59 -61z m-1605 -85 c107 -9 197 -20 200 -24 3 -4
124 -23 270 -42 244 -31 270 -33 325 -21 81 18 382 28 418 14 56 -22 21 -58
 -85 -85 -29 -7 -53 -17 -52 -22 0 -5 45 -31 100 -59 l99 -50 115 0 c118 0 140
 -5 140 -31 0 -21 -57 -42 -144 -54 -71 -10 -166 0 -230 26 -13 5 -17 3 -14 -6
6 -19 72 -40 143 -47 54 -5 112 2 200 22 27 7 29 6 15 -8 -39 -41 -237 -61
 -318 -33 -47 17 -70 2 -25 -15 69 -26 127 -31 210 -17 95 16 100 8 11 -17
 -114 -32 -228 -20 -422 47 l-89 30 -211 -11 c-634 -33 -890 10 -934 157 -13
42 -16 173 -6 235 7 38 7 38 48 32 22 -2 129 -12 236 -21z m2446 -449 l39 -96
142 -2 143 -2 36 85 c20 47 40 89 44 94 10 10 190 -63 196 -79 3 -6 -13 -51
 -34 -100 l-39 -89 82 -81 c45 -44 88 -90 96 -103 l14 -23 99 41 c55 22 100 39
102 38 1 -2 20 -49 41 -105 l40 -101 -96 -38 -96 -37 0 -144 0 -145 90 -38
c50 -21 90 -42 90 -47 0 -19 -79 -193 -88 -193 -5 0 -50 18 -100 40 l-91 41
 -25 -33 c-33 -43 -86 -96 -135 -134 l-39 -31 37 -94 c20 -52 36 -97 36 -101 0
 -7 -178 -78 -195 -78 -5 0 -26 42 -46 92 l-36 92 -129 1 c-71 1 -135 3 -142 6
 -8 2 -27 -29 -54 -91 -22 -52 -41 -96 -43 -98 -2 -3 -150 59 -184 77 -19 10
 -18 14 21 105 l41 95 -80 78 c-44 43 -87 89 -95 103 l-15 25 -97 -39 c-53 -21
 -100 -35 -104 -31 -14 14 -80 190 -74 197 4 3 48 23 97 42 l90 36 0 142 -1
142 -89 39 c-50 21 -92 40 -94 43 -4 4 78 204 84 204 1 0 44 -18 94 -41 51
 -22 96 -39 100 -37 5 2 27 25 51 52 23 27 66 69 96 94 l53 44 -20 51 c-60 150
 -59 148 -41 155 9 3 53 20 97 38 44 18 83 33 86 33 4 1 24 -42 45 -94z' />
			<path d='M2003 4290 c-13 -5 -23 -15 -23 -21 0 -7 8 -9 23 -3 12 4 41 7 65 7
27 0 42 4 39 11 -5 14 -75 18 -104 6z' />
			<path d='M2228 4284 c-12 -12 2 -24 28 -24 14 0 34 -5 45 -10 15 -8 19 -7 19
4 0 8 -3 17 -8 20 -15 9 -78 17 -84 10z' />
			<path d='M2050 4175 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
 -5 0 -10 -16 -10 -35z' />
			<path d='M2260 4159 c0 -24 4 -38 10 -34 6 3 10 22 10 41 0 19 -4 34 -10 34
 -5 0 -10 -18 -10 -41z' />
			<path d='M2175 4180 c-7 -11 8 -101 24 -141 11 -31 -1 -49 -36 -49 -13 0 -23
 -4 -23 -10 0 -16 57 -12 74 6 20 19 20 30 1 84 -8 23 -15 57 -15 76 0 33 -14
52 -25 34z' />
			<path d='M3709 1365 c-59 -19 -128 -80 -159 -141 -33 -65 -34 -167 -4 -235 44
 -96 126 -150 237 -157 118 -7 215 49 263 153 28 60 32 165 9 221 -55 132 -209
203 -346 159z m196 -34 c91 -40 152 -147 142 -251 -16 -175 -196 -280 -352
 -206 -135 64 -186 208 -121 340 61 124 202 173 331 117z' />
			<path d='M5740 6805 c0 -63 4 -105 10 -105 6 0 10 42 10 105 0 63 -4 105 -10
105 -6 0 -10 -42 -10 -105z' />
			<path d='M5990 6300 c0 -67 4 -110 10 -110 6 0 10 43 10 110 0 67 -4 110 -10
110 -6 0 -10 -43 -10 -110z' />
			<path d='M2210 6209 c0 -6 107 -63 238 -126 l237 -116 -214 -104 c-117 -56
 -215 -103 -217 -103 -2 0 19 46 47 101 34 68 47 105 40 112 -6 6 -30 -31 -70
 -112 -34 -66 -61 -127 -61 -136 0 -16 26 -5 334 145 154 75 186 94 175 104
 -14 12 -492 246 -503 246 -3 0 -6 -5 -6 -11z' />
			<path d='M1855 5936 c-345 -97 -627 -332 -793 -662 -107 -213 -182 -545 -182
 -805 0 -112 17 -79 24 49 19 312 83 565 198 782 162 305 454 536 779 619 39
10 70 22 70 27 1 15 -13 13 -96 -10z' />
			<path d='M6240 5050 c0 -751 2 -851 15 -856 10 -4 24 5 38 22 12 16 56 68 99
117 l76 88 -54 92 c-30 50 -54 93 -54 96 1 22 87 81 118 81 15 0 18 6 14 29
 -9 62 39 93 103 67 54 -23 46 -46 -15 -46 -27 0 -50 -4 -50 -10 0 -5 8 -10 18
 -11 9 0 25 -2 36 -4 27 -5 66 12 66 30 0 9 9 15 25 15 14 0 30 9 37 20 7 11
21 20 30 20 10 0 23 9 30 20 7 11 18 20 25 20 10 0 12 -36 10 -152 -2 -170 3
 -189 70 -259 l34 -36 -38 -26 c-21 -14 -55 -33 -75 -42 -36 -15 -51 -36 -73
 -105 l-7 -23 33 20 c51 31 218 95 229 88 6 -4 10 6 10 22 l0 29 69 -4 c47 -3
85 1 119 12 65 22 142 71 142 92 0 15 -2 15 -22 0 -139 -99 -263 -111 -372
 -37 -87 60 -113 139 -96 290 10 85 9 108 -9 201 -28 143 -28 153 14 195 50 49
68 46 154 -27 65 -57 111 -56 111 0 l0 26 33 -31 c52 -50 73 -57 96 -34 20 20
20 19 42 -47 53 -161 89 -197 152 -150 43 34 72 41 99 27 33 -17 38 -28 38
 -80 0 -65 -32 -110 -93 -129 l-47 -15 0 -157 c0 -153 1 -158 21 -158 49 0 288
 -113 418 -197 162 -105 258 -220 335 -400 75 -176 152 -467 127 -482 -11 -7
 -11 -32 -1 -137 22 -244 -13 -395 -108 -455 -24 -15 -68 -33 -97 -40 -75 -17
 -247 -7 -310 18 -27 11 -135 72 -239 137 -189 117 -190 117 -256 119 -55 2
 -75 7 -115 32 -26 17 -53 39 -59 50 -32 59 -60 95 -69 86 -6 -6 0 -21 14 -41
20 -26 21 -32 9 -40 -25 -16 -50 -11 -75 14 -53 53 -40 128 23 134 20 2 32 9
32 18 0 17 -18 18 -56 4 -33 -13 -38 -9 -29 28 6 30 38 52 75 52 11 0 20 5 20
11 0 8 -15 10 -45 7 -51 -6 -54 0 -23 42 15 21 30 30 49 30 15 0 31 5 35 11 5
9 -8 10 -47 6 -50 -5 -179 22 -256 53 -28 12 -30 46 -4 55 27 8 218 -22 317
 -51 105 -30 143 -49 262 -130 68 -46 138 -81 255 -128 89 -36 169 -67 177 -68
19 -4 19 -1 -36 247 -24 110 -44 201 -44 202 0 2 -4 3 -10 3 -16 0 -13 -22 31
 -223 23 -103 39 -190 37 -193 -3 -2 -74 25 -159 60 -110 46 -186 86 -268 140
 -128 85 -201 113 -383 146 -154 28 -193 27 -214 -5 -15 -23 -15 -27 -1 -49 16
 -24 37 -32 173 -71 l62 -18 -14 -33 c-8 -18 -14 -43 -14 -56 -1 -13 -2 -54 -4
 -93 -3 -82 10 -123 50 -154 23 -18 38 -22 71 -18 35 4 46 0 64 -19 37 -39 100
 -64 170 -67 63 -3 68 -5 254 -122 104 -65 215 -127 247 -138 l58 -20 2 -178 3
 -178 89 44 c150 76 253 151 387 285 220 220 345 443 415 745 26 111 28 136 28
325 0 176 -3 220 -22 310 -45 205 -116 374 -229 543 -71 108 -219 268 -321
349 -152 121 -374 231 -564 279 -85 21 -88 23 -99 58 -28 89 -87 136 -200 161
 -81 18 -348 9 -413 -15 -111 -39 -163 -96 -173 -189 l-6 -53 -66 -27 c-103
 -42 -240 -122 -340 -196 l-91 -69 0 560 c0 490 -2 559 -15 559 -13 0 -15 -101
 -15 -850z m544 -136 c12 -5 25 -54 14 -54 -13 0 -95 49 -91 54 8 8 57 8 77 0z
m-46 -50 c12 -8 22 -20 22 -25 0 -20 -30 -20 -56 0 -15 12 -35 21 -45 21 -11
0 -19 5 -19 10 0 16 73 12 98 -6z m-57 -34 c29 -16 24 -40 -9 -40 -33 0 -106
33 -96 43 11 11 83 8 105 -3z' />
			<path d='M3875 5708 c-3 -7 -4 -425 -3 -928 l3 -915 983 -3 982 -2 -2 927 -3
928 -978 3 c-777 2 -979 0 -982 -10z m1943 -920 l-3 -903 -960 0 -960 0 -3
903 -2 902 965 0 965 0 -2 -902z' />
			<path d='M4780 4940 l0 -230 75 0 75 0 0 230 0 230 -75 0 -75 0 0 -230z' />
			<path d='M4780 4560 l0 -80 75 0 75 0 0 80 0 80 -75 0 -75 0 0 -80z' />
			<path d='M4460 4135 l0 -95 50 0 c28 0 50 5 50 10 0 6 -13 10 -30 10 -28 0
 -30 3 -30 35 0 28 4 35 20 35 11 0 20 5 20 10 0 6 -9 10 -20 10 -16 0 -20 7
 -20 30 0 27 3 30 30 30 17 0 30 5 30 10 0 6 -22 10 -50 10 l-50 0 0 -95z' />
			<path d='M4610 4135 c0 -88 1 -95 20 -95 17 0 20 7 20 45 0 30 4 45 13 45 22
0 37 -23 37 -57 0 -26 4 -33 20 -33 17 0 20 7 20 44 0 25 -5 48 -10 51 -7 4
 -6 13 1 27 8 15 8 27 0 44 -9 21 -17 24 -66 24 l-55 0 0 -95z m85 65 c11 -18
 -6 -50 -26 -50 -14 0 -19 7 -19 30 0 23 5 30 19 30 11 0 23 -5 26 -10z' />
			<path d='M4780 4135 c0 -88 1 -95 20 -95 17 0 20 7 20 39 0 22 5 43 10 46 19
12 40 -16 40 -51 0 -27 4 -34 20 -34 17 0 20 7 20 43 0 24 -5 48 -11 54 -8 8
 -8 13 0 17 15 9 14 49 -1 64 -7 7 -36 12 -65 12 l-53 0 0 -95z m85 45 c0 -18
 -6 -26 -22 -28 -20 -3 -23 1 -23 28 0 27 3 31 23 28 16 -2 22 -10 22 -28z' />
			<path d='M4960 4211 c-16 -30 -12 -123 6 -149 17 -24 61 -30 95 -12 16 9 19
22 19 83 0 44 -5 78 -12 85 -7 7 -31 12 -55 12 -32 0 -45 -5 -53 -19z m78 -70
c3 -69 -7 -92 -33 -71 -10 8 -15 29 -15 60 0 60 8 82 29 78 12 -3 17 -18 19
 -67z' />
			<path d='M5130 4135 c0 -88 1 -95 20 -95 17 0 20 7 20 46 0 38 3 45 18 42 12
 -2 18 -15 20 -46 3 -34 7 -42 23 -42 16 0 19 7 19 43 0 24 -5 48 -11 54 -8 8
 -8 13 0 17 15 9 14 49 -1 64 -7 7 -34 12 -60 12 l-48 0 0 -95z m78 48 c-2 -17
 -10 -29 -20 -31 -14 -3 -18 3 -18 27 0 25 4 31 21 31 16 0 20 -5 17 -27z' />
			<path d='M6886 4953 c-31 -10 -45 -18 -38 -25 11 -11 99 12 102 27 2 15 -10
14 -64 -2z' />
			<path d='M7050 4956 c0 -11 9 -16 28 -16 15 0 44 -3 65 -6 30 -5 37 -4 37 10
0 12 -10 16 -42 16 -24 0 -53 3 -65 6 -17 5 -23 2 -23 -10z' />
			<path d='M7092 4859 c2 -19 8 -33 13 -31 15 3 12 55 -3 60 -10 3 -13 -5 -10
 -29z' />
			<path d='M6882 4849 c2 -18 9 -34 16 -37 10 -3 13 5 10 29 -2 18 -9 34 -16 37
 -10 3 -13 -5 -10 -29z' />
			<path d='M6965 4824 c-3 -25 -13 -64 -21 -86 -9 -27 -11 -46 -4 -58 10 -19 55
 -35 71 -25 14 9 1 25 -22 25 -29 0 -32 21 -15 81 18 58 21 109 7 109 -5 0 -12
 -21 -16 -46z' />
			<path d='M7042 4589 c-67 -14 -95 -72 -50 -103 14 -10 44 -16 76 -16 43 0 58
5 77 25 14 13 25 30 25 38 0 41 -65 70 -128 56z m88 -34 c32 -38 -23 -74 -95
 -61 -45 9 -56 33 -26 58 28 22 103 25 121 3z' />
			<path d='M5228 2624 l-3 -126 -71 -22 c-39 -13 -97 -37 -130 -55 l-58 -31 -86
85 c-47 47 -90 85 -95 85 -6 0 -57 -47 -115 -105 l-105 -105 89 -89 89 -89
 -41 -84 c-23 -46 -47 -107 -53 -135 l-12 -53 -129 0 -128 0 0 -150 0 -149 127
 -3 126 -3 22 -70 c12 -38 36 -98 55 -133 l33 -63 -89 -90 -89 -89 105 -105
c58 -58 109 -105 115 -105 6 0 48 38 94 84 l84 84 64 -34 c35 -18 96 -43 134
 -55 l69 -21 0 -124 0 -124 155 0 155 0 0 124 0 124 69 21 c38 12 99 37 134 55
l64 34 84 -84 c46 -46 88 -84 94 -84 6 0 57 47 114 104 l105 105 -89 90 -88
91 28 52 c16 29 41 89 56 133 l27 80 126 3 126 3 0 149 0 150 -130 0 c-109 0
 -130 2 -130 15 0 27 -42 139 -74 197 l-31 58 90 90 90 90 -105 105 c-58 58
 -109 105 -115 105 -5 0 -48 -38 -95 -85 l-86 -85 -58 31 c-33 18 -91 42 -130
55 l-71 22 -3 126 -3 126 -154 0 -154 0 -3 -126z m292 -23 l5 -123 75 -22 c41
 -12 106 -39 144 -60 l68 -38 87 86 86 86 90 -90 90 -90 -83 -83 c-45 -45 -82
 -86 -82 -91 0 -4 20 -48 44 -96 24 -49 50 -114 57 -144 l12 -56 124 0 123 0 0
 -130 0 -129 -122 -3 -121 -3 -22 -71 c-12 -38 -39 -105 -60 -147 l-39 -78 87
 -87 87 -87 -90 -90 -90 -89 -89 87 -89 88 -64 -35 c-70 -38 -122 -60 -185 -76
l-43 -11 0 -124 0 -125 -135 0 -135 0 0 125 0 124 -42 11 c-64 16 -116 38
 -186 76 l-64 35 -89 -88 -89 -87 -90 89 -90 90 87 88 88 88 -34 62 c-19 34
 -47 100 -61 147 l-27 85 -127 3 -127 3 3 127 3 127 124 5 124 5 22 70 c23 75
72 182 86 191 22 13 3 44 -73 121 l-83 83 90 90 90 90 86 -86 87 -86 68 38
c38 21 103 48 144 60 l75 22 3 113 c1 63 4 120 7 127 3 9 36 12 132 10 l128
 -3 5 -124z' />
			<path d='M5322 2060 c-106 -22 -203 -108 -237 -211 -23 -69 -17 -178 14 -237
33 -65 87 -118 149 -149 77 -38 192 -39 267 -3 239 116 248 451 15 567 -71 35
 -142 46 -208 33z m170 -40 c62 -23 124 -82 159 -150 32 -64 33 -177 1 -241
 -133 -270 -528 -202 -559 96 -13 128 81 267 207 306 48 14 138 9 192 -11z' />
		</g>
	</SvgIcon>
);

export default BoundaryError;