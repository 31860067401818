import React from 'react';
import styles from './FileInput.module.scss';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';

import { PdfIcon } from 'assets/icons';

import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useToggler } from 'hooks';

const FileInput = ({ name, acceptedFiles, openDropZone }) => {
  const [showDropzone, toggleDropzone] = useToggler(openDropZone);
  const { register, setValue, watch } = useFormContext();
  register(name);
  const value = watch(name);

  function truncateFileName(fullFileName) {
    if (fullFileName) {
      return fullFileName.length > 12
        ? `${fullFileName.substr(0, 11)}...`
        : fullFileName;
    }
    return '';
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles,
    // validator: (file) => {
    //   if (file.size / 1024 / 1024 > 10) {
    //     return true;
    //   }
    //   return false;
    // },
    onDrop: (files) => {
      setValue(name, files[0]);
    },
  });

  return (
    <div className={styles.wrapper}>
      {!showDropzone && (
        <p className={styles.placeholder} onClick={toggleDropzone}>
          Attach PDF file
        </p>
      )}
      {showDropzone && !value && (
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          <p className={styles.dropzone__label}>
            Drag & drop files here, or
            <span className={styles.dropzone__label__highlight}>
              {' browse'}
            </span>
            .
          </p>
          <p
            className={styles.dropzone__cancel}
            onClick={(evt) => {
              toggleDropzone(false);
              evt.stopPropagation();
            }}
          >
            Cancel
          </p>
        </div>
      )}

      {value && (
        <div className={styles.file__layout}>
          <div className={styles.file}>
            <PdfIcon />

            <p className={styles.file__info}>
              <span>{truncateFileName(value.name.split('.')[0])}</span>
              <span className={styles.file__info__ext}>
                {`.${value.name.split('.')[1]}`}
              </span>
            </p>

            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => setValue(name, null)}
            >
              <DeleteOutlineOutlinedIcon
                className={styles.file__remove}
                fontSize="medium"
              />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileInput;
