import React from 'react';
import styles from './FormsFilters.module.scss';

import { useFormContext } from 'react-hook-form';

const BlockLabel = ({ name, label }) => {
  const { watch } = useFormContext();
  const value = watch(name);

  const amount = Object.values(value).filter(Boolean).length;

  return (
    <p className={styles.block__label}>
      {label}

      {amount !== 0 && (
        <span>
          <span>{amount}</span>
        </span>
      )}
    </p>
  );
};

export default BlockLabel;
