import React, { useState, memo, useEffect } from 'react';
import styles from './SettingsAutocomplete.module.scss';

import { InputAdornment, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import BackIcon from '@mui/icons-material/ArrowBack';

import { useAdminSettingsContext } from '../context';
import { useHistory, generatePath } from 'react-router';
import routes from 'routing/routes';

import {} from '../settings-content';
import { Box } from '@mui/system';
import Avatar from 'shared/avatar';

function removeExcessWhiteSpace(string) {
  return string.replace(/\s+/g, ' ').trim();
}

function handleGetInputLabel(option) {
  if (option?.firstName && option?.lastName)
    return `${option.firstName} ${option.lastName}`;
  return '';
}

function checkIfNeedToRunSearch(
  input,
  updateAutocompleteSearchList,
  searchDOCStaff,
  isSuggestionsOpen
) {
  if (input.length === 3 && !isSuggestionsOpen) {
    updateAutocompleteSearchList.current = true;
    searchDOCStaff(removeExcessWhiteSpace(input));
  }
}

function handleIsSuggestionOpen(newInput, setIsSuggestionsOpen) {
  const shouldOpenSuggestions = newInput.length > 2;
  return setIsSuggestionsOpen(shouldOpenSuggestions);
}

function handleRenderOption(params, option) {
  const { firstName, lastName, id } = option;
  return (
    <Box key={id} {...params}>
      <div>
        <Avatar
          className={styles.option__avatar}
          id={id}
          lastName={lastName}
          firstName={firstName}
        />
      </div>
      <div className={styles.renderOption}>
        {firstName} {lastName}
      </div>
    </Box>
  );
}

function handleRenderInput(params, displayRoles, handleBackButton) {
  return (
    <TextField
      {...params}
      classes={{ root: styles.autocomplete }}
      placeholder="Search for Staff by Name"
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        classes: {
          notchedOutline: styles.autocomplete__input__outline,
        },
        startAdornment: (
          <InputAdornment position="start" sx={{ paddingLeft: '10px' }}>
            {displayRoles ? (
              <SearchIcon />
            ) : (
              <BackIcon
                className={styles.clearIcon}
                onClick={() => handleBackButton()}
              />
            )}
          </InputAdornment>
        ),
      }}
      sx={{
        ['& fieldset']: {
          borderRadius: '30px',
        },
      }}
    />
  );
}

const SettingsAutocomplete = () => {
  const {
    searchDOCStaff,
    swapSettingsDisplayToRoles,
    swapSettingsDisplayToStaff,
    displayRoles,
    triggerLoading,
    autocompleteSearchList,
    loading,
    setSearchInput,
    searchInput,
    setDisplayedDOCList,
    updateDisplayedDOCList,
    updateAutocompleteSearchList,
    setNoResultsFromSearch,
    setNoResultSearchInput,
  } = useAdminSettingsContext();

  const history = useHistory();
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [autoCompleteInput, setAutoCompleteInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (searchInput && autoCompleteInput.length == 0) {
      setAutoCompleteInput(searchInput);
    }
  }, []);

  useEffect(() => {
    setSuggestions(Object.values(autocompleteSearchList));
  }, [autocompleteSearchList]);

  const handleAutocompleteValueChange = (event, newValue) => {
    if (!event || !newValue) {
      return;
    }
    if (typeof newValue === 'object') {
      history.push(
        `${generatePath(routes.singleStaff, { staffId: newValue.id })}`
      );
      return;
    }
    setAutoCompleteValue(newValue);
    setAutoCompleteInput(newValue);
    updateDisplayedDOCList.current = true;
    setNoResultSearchInput('');
    searchDOCStaff(removeExcessWhiteSpace(newValue));
    setIsSuggestionsOpen(false);
    setNoResultsFromSearch(false);
    triggerLoading();
    swapSettingsDisplayToStaff();
  };

  const handleAutocompleteInputChange = (event, newInput) => {
    if (!event) {
      return;
    }
    setSearchInput(newInput);
    setAutoCompleteInput(newInput);
    handleIsSuggestionOpen(newInput, setIsSuggestionsOpen);
    checkIfNeedToRunSearch(
      newInput,
      updateAutocompleteSearchList,
      searchDOCStaff,
      isSuggestionsOpen
    );
  };

  function handleBackButton() {
    cleanSearchState();
  }

  const cleanSearchState = () => {
    setAutoCompleteInput('');
    setAutoCompleteValue('');
    setSearchInput('');
    setDisplayedDOCList([]);
    setNoResultSearchInput('');
    swapSettingsDisplayToRoles();
  };

  return (
    <>
      <Autocomplete
        open={isSuggestionsOpen}
        disablePortal
        options={suggestions}
        id="admin-settings-autocomplete"
        fullWidth
        value={autoCompleteValue}
        onChange={(event, newValue) =>
          handleAutocompleteValueChange(event, newValue)
        }
        inputValue={autoCompleteInput}
        onInputChange={(event, newInput) =>
          handleAutocompleteInputChange(event, newInput)
        }
        getOptionLabel={(option) => handleGetInputLabel(option)}
        renderOption={(params, option) => handleRenderOption(params, option)}
        renderInput={(params) =>
          handleRenderInput(params, displayRoles, handleBackButton)
        }
        freeSolo
        blurOnSelect={true}
        loading={loading}
      />
    </>
  );
};

export default memo(SettingsAutocomplete);
