import { Box, Button, Typography } from '@mui/material';
import { generatePath, useHistory } from 'react-router';
import { routes } from 'routing';
import query from 'query-string';
import { toTitleCase } from '../../../../utils';
import { Fragment } from 'react';

export default function CardActions({
  ownerId,
  channelId,
  id,
  clientKey,
  isBlocked,
  reason,
}) {
  const { sxBoxWrapper } = useCardButtonStyles();
  const history = useHistory();
  const currentMessageId = clientKey || id;

  function onHandlerClick(e) {
    e.stopPropagation();
    history.push(
      `${generatePath(routes.chat, {
        userId: ownerId,
        chatId: channelId,
      })}?${query.stringify({
        messageId: currentMessageId,
      })}`
    );
  }

  return (
    <Box sx={sxBoxWrapper}>
      {isBlocked ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {reason ? (
            <Fragment>
              <Typography
                variant="regularRed"
                m={1}
                sx={{ fontWeight: 'bold' }}
              >
                Blocked by {toTitleCase(reason)}
              </Typography>
            </Fragment>
          ) : null}
        </Box>
      ) : null}
      <CardCustomButton label="View in Chat" onClick={onHandlerClick} />
    </Box>
  );
}

function CardCustomButton({
  label = 'Action',
  onClick = () => console.log('clicked'),
}) {
  const { sxButton } = useCardButtonStyles();

  return (
    <Button disableRipple variant="text" onClick={onClick} sx={sxButton}>
      {label}
    </Button>
  );
}

function useCardButtonStyles() {
  return {
    sxBoxWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 20px',
    },
    sxButton: {
      paddingX: 1,
      textTransform: 'none',
      color: 'customPalette.grey3',
      fontSize: 14,
      fontWeight: 500,
      borderRadius: 4,
    },
  };
}
