import { Box, Typography } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

export default function FallbackThumbnail( { text, sx } ) {
	const { sxBox, sxIcon } = useFallbackThumbnailStyles();
	return (
		<Box sx={{ ...sxBox, ...sx }}>
			<ImageOutlinedIcon sx={sxIcon} />
			<Typography variant='altThumbnailText' component='p' p={1} align='center' fontSize={13}>
				{text}
			</Typography>
		</Box>
	);
}

function useFallbackThumbnailStyles() {
	return {
		sxBox: {
			alignItems: 'center',
			cursor: 'pointer',
			display: 'flex',
			height: 150,
			flexDirection: 'column',
			justifyContent: 'center',
			padding: 1,
		},
		sxIcon: {
			color: 'customPalette.white',
			fontSize: 40,
		},
	};
}
