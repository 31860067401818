import React from 'react';

import If from 'components/if/If';
import RolesList from '../roles-list';

import { Skeleton } from 'shared/skeleton';
import { useAdminSettingsContext } from '../context';

const RolesSectionContent = () => {
  const { rolesArray, loading } = useAdminSettingsContext();
  return (
    <If condition={!loading} otherwise={<Skeleton amount={3} />}>
      <RolesList rolesArray={rolesArray} loading={loading} />
    </If>
  );
};

export default RolesSectionContent;
