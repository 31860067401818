import { Fragment } from 'react';

import Skeleton from '@mui/material/Skeleton';

export default function SingleMessageLoader() {
	return (
		<Fragment>
			<CustomSkeleton />
			<CustomSkeleton />
			<CustomSkeleton />
		</Fragment>
	);
}

function CustomSkeleton( props ) {
	return <Skeleton
		variant='text'
		animation='wave'
		width={80}
		height={24}
	/>;
}
