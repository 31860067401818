import { useQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';

import { QUERY_KEYS } from 'utils';

export default function useChannelLastMessage( channelId ) {

	const api = useGraphql();

	function getChannelLastMessage() {
		return api.controller.channel.lastChatMessage(channelId);
	}

	const channelInfoQuery = useQuery({
		queryKey: [QUERY_KEYS.CHANNEL_LAST_MESSAGE, channelId],
		queryFn: getChannelLastMessage,
		enabled: !!channelId,
	});

	return channelInfoQuery;

}
