import { useEffect, useState } from 'react';

import { useToggler } from 'hooks';

export default function getInitialModalContextValue({
  sentimentSelectedRangeFilter,
  sectionDateRangeFilter,
  currentSelectedOption,
  selectedAnalyticModalConfig,
}) {
  const initialQueryPayload = {
    dateRange: sentimentSelectedRangeFilter || sectionDateRangeFilter,
    filters: [currentSelectedOption || [{ term: '', value: [''] }]],
  };
  const [listQueryPayload, setListQueryPayload] = useState(initialQueryPayload);
  const [messageAndChannelSelected, setMessageAndChannelSelected] = useState({
    messageKey: 0,
    channelId: '',
  });
  const [modalConfig, setModalConfig] = useState();
  const [mediaTypeActiveFilters, setMediaTypeActiveFilters] = useState([]);
  const [currentInvitationStatus, setCurrentInvitationStatus] = useState([]);
  const [openSaveChatModal, toggleOpenSaveChatModal] = useToggler(false);
  const [searchTerm, setSearchTerm] = useState('');

  // See Comment #1
  useEffect(() => {
    setListQueryPayload((payload) => ({
      ...payload,
      dateRange: sentimentSelectedRangeFilter || sectionDateRangeFilter,
      filters: [...currentSelectedOption],
      term: searchTerm,
    }));
    setModalConfig(selectedAnalyticModalConfig);
  }, [currentSelectedOption, sectionDateRangeFilter]);

  return {
    searchTerm,
    setSearchTerm,
    initialQueryPayload,
    sectionDateRangeFilter,
    messageAndChannelSelected,
    listQueryPayload,
    modalConfig,
    openSaveChatModal,
    setMessageAndChannelSelected,
    setListQueryPayload,
    setModalConfig,
    toggleOpenSaveChatModal,
    mediaTypeActiveFilters,
    setMediaTypeActiveFilters,
    currentInvitationStatus,
    setCurrentInvitationStatus,
    selectedAnalyticModalConfig,
  };
}

/* Comments
 * 1. This is a hacky solution to the problem of the modal context doesn't initialize with the correct values provided by the parent component. In the future try to remove the useEffect and find a better solution.
 */
