import { Card, CardContent } from '@mui/material';
import { useHover } from 'hooks';

export function TextMessageContainer( { border, isMine, isBlock, children } ) {
	const { sxCard, sxCardContent, sxCardAction } = useTextMessageStyles({ border, isBlock, isMine });
	const [hoverRef, onHover] = useHover();
	return (
		<Card sx={sxCard} ref={hoverRef} elevation={1}>
			<CardContent sx={sxCardContent}>
				{children}
			</CardContent>
		</Card>
	);
}

function useTextMessageStyles( { border = 2, isBlock = false, isMine = false } ) {
	const unblockedBorderColor = isMine ? 'customPalette.grey5' : 'customPalette.grey';
	return {
		sxCard: {
			borderRadius: border,
			maxWidth: 470,
			bgcolor: isMine ? '#f2f2f2' : '#fdfdfd',
			borderColor: isBlock ? 'customPalette.error' : unblockedBorderColor,
			'&:hover': {
				borderColor: isBlock ? 'customPalette.error' : 'customPalette.grey4',
			},
			boxShadow: isBlock && '0 1px 3px #df093c',
		},
		sxCardContent: {
			display: 'flex',
			alignItems: 'flex-end',
			flexWrap: 'wrap',
			maxWidth: 460,
			'&.MuiCardContent-root': {
				padding: '12px 16px 12px 16px ',
			},
		},
	};
}