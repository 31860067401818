import ProfileCard from '../ProfileCard';
import DeviceControls from 'modules/users-chats/user-details/device-controls';

export default function DeviceActions() {
	const { sxCard } = useDeviceActionsStyles();
	return (
		<ProfileCard
			cardTitle='Devices controls'
			sxCard={sxCard}>
			<DeviceControls />
		</ProfileCard>);
}

function useDeviceActionsStyles() {
	return {
		sxCard: {
			flex: 1.8,
			margin: '24px 10px 10px 20px',
		},
	};
}
