import { useState } from 'react';

import PageContainer from 'shared/page-container';
import SearchTabs from './tab-panel/SearchTabs';
import ErrorBoundary from 'shared/error-boundary';

import { useParams } from 'react-router';
import { PromptTabs } from './results-tab/PromptTabs';

const SearchResults = () => {
	const { searchTerm } = useParams();
	const [tabIndex, setTabIndex] = useState(0);
	const CurrentTab = PromptTabs[tabIndex];

	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<PageContainer>
				<SearchTabs tab={tabIndex} setTab={setTabIndex} />
				<CurrentTab searchTerm={searchTerm} />
			</PageContainer>
		</ErrorBoundary>
	);
};

export default SearchResults;
