import { useState, useEffect, useRef } from 'react';

export default function useIdle( timeToSignOut = 90000, onSignOut ) {
	const [idleTime, setIdleTime] = useState(0);
	const [remainingTime, setRemainingTime] = useState(timeToSignOut);
	const timerRef = useRef(null);

	const handleResetTimer = () => {
		setIdleTime(0);
		setRemainingTime(timeToSignOut);
	};

	useEffect(() => {

		const handleSignOut = () => {
			if (typeof onSignOut === 'function') {
				onSignOut();
			}
		};

		const handleResetIdle = () => {
				setIdleTime(0);
				setRemainingTime(timeToSignOut);
		};

		const handleStartTimer = () => {
			timerRef.current = setInterval(() => {
				const newRemainingTime = remainingTime - 1000;
				setRemainingTime(newRemainingTime);
				if (newRemainingTime <= 0) {
					handleSignOut();
				}
			}, 1000);
		};

		const handleStopTimer = () => {
			clearInterval(timerRef.current);
			timerRef.current = null;
		};

		handleStartTimer();

		if (remainingTime - 1000 > 60000) {
			window.addEventListener('mousemove', handleResetIdle);
			window.addEventListener('keypress', handleResetIdle);
			window.addEventListener('mousedown', handleResetIdle);
			window.addEventListener('scroll', handleResetIdle);
			window.addEventListener('wheel', handleResetIdle);
		}
		return () => {
			handleStopTimer();
			window.removeEventListener('mousemove', handleResetIdle);
			window.removeEventListener('keypress', handleResetIdle);
			window.removeEventListener('mousedown', handleResetIdle);
			window.removeEventListener('scroll', handleResetIdle);
			window.removeEventListener('wheel', handleResetIdle);
		};
	}, [remainingTime]);

	return { idleTime, remainingTime, handleResetTimer };
}