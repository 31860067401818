import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import ChatMessageOwnerInfo from 'shared/message-owner-info';
import KiwiDivider from 'shared/kiwi-divider';

import useMessageInfo from 'hooks/useMessageInfo';

import { BlockAlert } from 'shared/message-block-alert';
import { getFormattedDate } from 'utils';

export default function ChatMessageContainer(props) {
  const {
    children,
    messageData,
    nextMessageData,
    prevMessageData,
    messageAndChannelSelected,
    temporaryAnimation = false,
  } = props;
  const messageContentRef = React.useRef(null);

  const { isMessageMine, showOwnerInfo, showDate } = useMessageInfo({
    messageData,
    nextMessageData,
    prevMessageData,
  });

  const formatMessageDate = getFormattedDate(messageData?.clientKey, 'd MMM');
  const currentMessageKey = messageData?.clientKey;
  const selectedMessageKey = messageAndChannelSelected?.messageKey;

  // Comment #1
  useEffect(() => {
    if (messageContentRef.current && currentMessageKey == selectedMessageKey) {
      messageContentRef.current.scrollIntoView({ block: 'nearest' });
      messageContentRef.current.style.border = '3px solid #03a882';
      messageContentRef.current.style.borderRadius = '16px';

      if (temporaryAnimation) {
        setTimeout(() => {
          messageContentRef.current.style.border = 'initial';
          messageContentRef.current.style.borderRadius = 'initial';
        }, 3000);
      }
    }
  }, [messageContentRef.current, currentMessageKey, selectedMessageKey]);

  return (
    <MessageWrapper>
      <KiwiDivider label={formatMessageDate} shouldRender={showDate} />
      <MessageContainer isMine={isMessageMine}>
        <div ref={messageContentRef}>
          {React.cloneElement(children, {
            ...messageData,
            mine: isMessageMine,
            isMessageBlock: Boolean(messageData?.statusData?.reason),
          })}
        </div>
        <BlockAlert
          isBlock={Boolean(messageData?.statusData?.reason)}
          isMine={isMessageMine}
          reason={messageData?.statusData?.reason}
        />
      </MessageContainer>
      <ChatMessageOwnerInfo
        owner_id={messageData?.owner_id}
        owner_info={messageData?.owner_info}
        isMessageMine={isMessageMine}
        showOwnerInfo={showOwnerInfo}
      />
    </MessageWrapper>
  );
}

const MessageWrapper = styled(Box)({
  margin: '0 8px',
});

const MessageContainer = styled(Box)(({ isMine }) => ({
  display: 'flex',
  flexDirection: !isMine ? 'row-reverse' : 'row',
  alignItems: 'center',
}));

/* Comments
 * #1: This useEffect is used to autoscroll to the selected message in the flagged messages modal.
 */
