import { useGraphql } from 'context';

export default function fetchSurveyResponses(payload: fetchSurveysResProps) {
  const api = useGraphql();
  return ({ pageParam = undefined }) =>
    api.controller.survey.getSurveyResponses({
      id: payload.surveyId,
      limit: 30,
      nextPage: pageParam,
    });
}

interface fetchSurveysResProps {
  surveyId: string;
}
