import React from 'react';
import styles from './EditStaffRoles.module.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useFormContext } from 'react-hook-form';

const RoleCheckbox = ({ name, label, description = '' }) => {
  const { register, watch, setValue } = useFormContext();
  register(name);
  const value = watch(name);

  const onCheck = (evt) => {
    setValue(name, evt.target.checked);
  };

  return (
    <div className={styles.checkbox__wrapper}>
      <FormControlLabel
        classes={{
          root: styles.checkbox__header,
        }}
        control={
          <Checkbox
            classes={{ root: styles.checkbox }}
            onChange={onCheck}
            checked={!!value}
          />
        }
        label={label}
      />
      <p className={styles.checkbox__description}>{description}</p>
    </div>
  );
};

export default RoleCheckbox;
