import React, { useState } from 'react';
import cs from 'classnames';
import styles from './Sections.module.scss';

import IconButton from '@mui/material/IconButton';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SectionItem = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.sectionItem}>
      <div
        className={cs(
          styles.sectionItem__header,
          open && styles['sectionItem__header--open']
        )}
        onClick={() => setOpen((state) => !state)}
      >
        <p className={styles.sectionItem__title}>{title}</p>
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <div
        className={cs(
          styles.sectionItem__content,
          open && styles['sectionItem__content--open']
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionItem;
