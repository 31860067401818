import React from 'react';
import styles from './DaysInput.module.scss';
import cs from 'classnames';

import { useFormContext } from 'react-hook-form';

const UNIT_SINGLE = {
  days: 'day',
};

const DaysInput = ({
  className,
  name,
  indefinitelyName,
  label,
  postLabel,
  ...props
}) => {
  const { register, watch, setValue, setError } = useFormContext();

  const disabled = indefinitelyName ? watch(indefinitelyName) : false;
  // eslint-disable-next-line no-unused-vars
  const { onChange, ...inputProps } = register(name);
  const value = watch(name);

  return (
    <>
      {label && <label className={styles.input__label}>{label}</label>}
      <div className={cs(styles.input__wrapper, className)}>
        <input
          className={styles.input}
          type={disabled ? 'text' : 'number'}
          value={disabled ? '--' : value}
          disabled={disabled}
          onChange={(evt) => {
            const value = parseInt(evt.target.value);
            setValue(name, value, { shouldValidate: true });
            isNaN(value) &&
              setError(name, {
                types: {
                  required: 'This is required',
                },
              });
          }}
          //steps={1}
          {...inputProps}
          {...props}
        />
        {postLabel && (
          <label className={styles.input__postLabel}>
            {value === 1 ? UNIT_SINGLE[postLabel] : postLabel}
          </label>
        )}
      </div>
    </>
  );
};

export default DaysInput;

// import React, { useEffect } from 'react';
// import styles from './DaysInput.module.scss';
// import cs from 'classnames';
//
// import { useFormContext } from 'react-hook-form';
//
// const UNIT_SINGLE = {
//   days: 'day',
// };
//
// const DaysInput = ({
//   className,
//   name,
//   indefinitelyName,
//   label,
//   postLabel,
//   ...props
// }) => {
//   const { register, watch, setValue, setError } = useFormContext();
//
//   const disabled = indefinitelyName ? watch(indefinitelyName) : false;
//   // eslint-disable-next-line no-unused-vars
//   const { onChange, ...inputProps } = register(name);
//   const value = watch(name);
//
//   return (
//     <>
//       {label && <label className={styles.input__label}>{label}</label>}
//       <div className={cs(styles.input__wrapper, className)}>
//         <input
//           className={styles.input}
//           type={disabled ? 'text' : 'number'}
//           value={disabled ? '--' : value}
//           placeholder="0"
//           // disabled={disabled}
//           onChange={(evt) => {
//             const value = parseInt(evt.target.value);
//             setValue(name, value, { shouldValidate: true });
//             isNaN(value) &&
//               setError(name, {
//                 types: {
//                   required: 'This is required',
//                 },
//               });
//           }}
//           //steps={1}
//           {...inputProps}
//           {...props}
//         />
//         {postLabel && (
//           <label className={styles.input__postLabel}>
//             {value === 1 ? UNIT_SINGLE[postLabel] : postLabel}
//           </label>
//         )}
//       </div>
//     </>
//   );
// };
//
// export default DaysInput;
