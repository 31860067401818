/* eslint-disable css-modules/no-undef-class */
import React, { useMemo } from 'react';
import cs from 'classnames';
import styles from './CheckboxListInput.module.scss';

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Down, TreeMiddle, TreeEnd } from './Icons';

import { useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';

const GroupCheckbox = ({ name, label, items, displayItems }) => {
  const { watch, setValue, trigger } = useFormContext();
  const [expanded, toggleExpanded] = useToggler(false);
  const value = watch(name);

  const onGroupCheck = (evt) => {
    const payload = items.reduce((acc, curr) => {
      acc[curr.id] = evt.target.checked;
      return acc;
    }, {});

    setValue(name, { ...value, ...payload }, { shouldDirty: true });
    trigger(name);
  };

  const onItemCheck = (payload) => {
    setValue(name, { ...value, ...payload }, { shouldDirty: true });
    trigger(name);
  };

  const { allChecked, oneChecked } = useMemo(() => {
    let allChecked = true;
    let oneChecked = false;

    items.forEach((item) => {
      if (!value[item.id]) {
        allChecked = false;
      }
      if (value[item.id]) {
        oneChecked = true;
      }
    });

    return { allChecked, oneChecked };
  }, [value]);

  const itemsArr = displayItems || items;

  return (
    <>
      <div className={styles.group}>
        <FormControlLabel
          classes={{
            root: cs(
              styles.checkbox__wrapper,
              styles['checkbox__wrapper--group']
            ),
            label: styles.checkbox__label,
          }}
          control={
            <MaterialCheckbox
              classes={{ root: styles.checkbox }}
              onChange={onGroupCheck}
              checked={allChecked}
              indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
              indeterminate={oneChecked && !allChecked}
            />
          }
          label={label}
        />
        <Down
          className={cs(
            styles.group__icon,
            !expanded && styles['group__icon--up']
          )}
          onClick={toggleExpanded}
        />
      </div>
      {expanded &&
        itemsArr.map((item, i) => (
          <div key={item.id} className={styles.nestedItem}>
            {i === itemsArr.length - 1 ? <TreeEnd /> : <TreeMiddle />}
            <FormControlLabel
              classes={{
                root: cs(
                  styles.checkbox__wrapper,
                  styles['checkbox__wrapper--item']
                ),
                label: styles.checkbox__label,
              }}
              control={
                <MaterialCheckbox
                  classes={{ root: styles.checkbox }}
                  onChange={(evt) =>
                    onItemCheck({ [item.id]: evt.target.checked })
                  }
                  checked={value[item.id]}
                />
              }
              label={item.name}
            />
          </div>
        ))}
    </>
  );
};

export default GroupCheckbox;
