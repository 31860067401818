import { useGraphql } from 'context';
import { useMutation } from 'hooks';

export default function useControlActions() {
	const api = useGraphql();
	const [, lockScreenMutation] = useMutation({
		sdkMutationCmd: api.controller.doc.device.lockScreen,
		onSuccessText: 'The screen has been locked successfully',
		onErrorText: 'The screen could not be locked',
	});

	const [, setFoundMutation] = useMutation({
		sdkMutationCmd: api.controller.doc.device.setFoundMode,
		onSuccessText: 'Found Mode has been set successfully',
		onErrorText: 'Found Mode could not be set',
	});

	const [, broadcastMsgMutation] = useMutation({
		sdkMutationCmd: api.controller.doc.device.broadcastMessage,
		onSuccessText: 'The message has been sent successfully',
		onErrorText: 'The message could not be sent',
	});

	const [, setLostMsgMutation] = useMutation({
		sdkMutationCmd: api.controller.doc.device.setLostMode,
		onSuccessText: 'Lost mode has been set successfully',
		onErrorText: 'Found Mode could not be set',
	});
	const [loadingResetPasswordMutation, resetPasswordMutation] = useMutation({
		sdkMutationCmd: api.controller.doc.resetInmatePassword,
		onSuccessText: 'The new password has been successfully requested',
		onErrorText: 'Something went wrong while requesting the new password',
	});

	return { setLostMsgMutation, broadcastMsgMutation, setFoundMutation, lockScreenMutation,loadingResetPasswordMutation ,  resetPasswordMutation };
}