import ReactDOM from 'react-dom';
import './index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { GraphQLProvider } from 'context';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'utils/queryClient';
import { AuthProvider } from 'modules/auth/context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import KiwiTheme from './KiwiTheme';
import NotificationContainer from 'shared/notification-container';

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={KiwiTheme}>
      <BrowserRouter>
        <GraphQLProvider>
          <AuthProvider>
            <App />
            <NotificationContainer />
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </GraphQLProvider>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
