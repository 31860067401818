import { Box } from '@mui/material';

import ProfileCard from 'modules/users-chats/user-details/profile-tab/ProfileCard';
import Suspense from 'shared/suspense-list';
import EmptyResult from './EmptyResult';
import KiwiAreaChart from 'shared/trend-chart';

import {
  useSentimentsAnalysis,
  useUserProfileContext,
} from 'modules/users-chats/hooks';
import { ChartLoader } from 'shared/loaders';
import { useGetParsedSentimentData } from 'hooks';
import { dateRangeBuilder } from 'utils';
import { FilterTypes } from 'kiwi-sdk/dist/types/analytics/message';

export default function SentimentsCharts() {
  const {
    userId,
    dateRangeFilter,
    setSelectedOption,
    setSentimentSelectedRangeFilter,
    setSelectedAnalyticModalConfig,
    toggleFlaggedMessagesModal,
  } = useUserProfileContext();
  const { data = [], isLoading } = useSentimentsAnalysis(
    userId,
    dateRangeFilter
  );
  const parsedSentimentData = useGetParsedSentimentData(data);

  function onHandleAreaClick(data) {
    const currentDate = data.activeLabel;
    setSelectedOption([
      { term: FilterTypes.SENTIMENT, value: SENTIMENT_ENUM.NEGATIVE },
      { term: FilterTypes.USER_ID, value: userId },
    ]);
    setSentimentSelectedRangeFilter(
      dateRangeBuilder({ interval: 'customDay', day: `${currentDate} 2023` })
    );
    setSelectedAnalyticModalConfig({
      modalName: 'Sentiment Trend',
      showDateFilters: false,
      showChatPanel: false,
      listRenderItem: 'message',
      initialOptionsData: formatTrendData(parsedSentimentData, currentDate),
      search: true,
      filters: true,
      typesData: SENTIMENT_ENUM,
    });
    toggleFlaggedMessagesModal();
  }

  return (
    <ProfileCard
      cardTitle="Sentiment"
      sxCard={{ flex: 9, margin: '18px 24px 10px 10px' }}
    >
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Suspense
          loading={isLoading}
          total={data.length}
          fallback={<ChartLoader />}
          noResultComponent={<EmptyResult />}
        >
          <KiwiAreaChart
            chartData={parsedSentimentData}
            dataKey="date"
            scale="sqrt"
            clickable={true}
            onHandleAreaClick={onHandleAreaClick}
          />
        </Suspense>
      </Box>
    </ProfileCard>
  );
}

function formatTrendData(array, currentDate) {
  const dateSelectedInfo = array.find((item) => item.date === currentDate);
  return Object.entries(dateSelectedInfo)
    .filter((item) => item[0] !== 'date')
    .map((index) => ({ option: index[0], count: index[1] }));
}

const SENTIMENT_ENUM = {
  NEGATIVE: '-1',
  POSITIVE: '1',
  NEUTRAL: '0',
};
