import React from 'react';
import styles from './UploadFile.module.scss';

import { UploadFileIcon } from 'assets/icons';
import { StepperProvider, useGraphql } from 'context';
import { useToggler } from 'hooks';
import Modal, { ModalTitle } from 'shared/modal';
import Stepper, { StepperContent } from 'shared/stepper';
import Form from 'shared/form';
import TitleAndFileStep from './TitleAndFileStep';
import FileRecipientsStep from './FileRecipientsStep';
import FileReviewStep from './FileReviewStep';
import { AddDocumentCmd } from 'kiwi-sdk';
import { from } from 'rxjs';
import { toast } from 'react-toastify';
import { useDocumentsContext } from '../context';

const STEPS = [
	{
		id: 'titleAndFile',
		label: 'Upload File',
		component: TitleAndFileStep,
	},
	{
		id: 'recipients',
		label: 'Recipients',
		component: FileRecipientsStep,
	},
	{
		id: 'review',
		label: 'Review',
		component: FileReviewStep,
	},
];

const formInitialValues = {
	recipientsType: 'FACILITIES',
	recipients: [],
	title: '',
	file: null,
};

const UploadFile = () => {
	const [showModal, toggleModal] = useToggler();
	const [loading, toggleLodaing] = useToggler();
	const { refreshDocuments } = useDocumentsContext();
	const api = useGraphql();

	const handleOnSubmit = ( form ) => {
		toggleLodaing(true);
		const { title, file, recipients } = form;
		const recipientArray = recipients.map(( facility ) => (
			{
				facilityId: facility.id,
				facilityName: facility.name,
				agencyId: facility.agencyId,
				agencyName: facility.agencyName,
			}
		));

		const payload = {
			displayName: title,
			file: file,
			recipients: recipientArray,
		};

		const sendPayload = from(api.send(AddDocumentCmd(payload)));
		sendPayload.subscribe({
			next: () => {
				toggleLodaing(false);
				toast.success('File was uploaded!');
				toggleModal(false);
				refreshDocuments();
			},
			error: ( err ) => {
				console.error(err);
				toast.error('An Issue occured uploading your file.');
				toggleLodaing(false);
			},
		});
	};

	const handleModalClose = () => {
		if (!loading) {
			toggleModal(false);
		}
	};

	return (
		<>
			<button
				className={styles.uploadButton}
				onClick={toggleModal}
			>
				<UploadFileIcon />
				Upload File
			</button>

			<Modal className={styles.modal} open={showModal} onClose={handleModalClose}>
				<ModalTitle className={styles.modal__header} text='Upload File' onClose={handleModalClose} />
				<div className={styles.uploadModalDivider}></div>
				<StepperProvider steps={STEPS}>
					<Stepper />
					<Form
						onSubmit={handleOnSubmit}
						initialValues={formInitialValues}
						className={styles.modal__form}
					>
						<StepperContent loading={loading} />
					</Form>
				</StepperProvider>
			</Modal>
		</>
	);
};

export default UploadFile;