import React from 'react';
import styles from './PrintTemplate.module.scss';

import format from 'date-fns/format';

import { useUserContext } from 'modules/users-chats/context';
import { useMediaSrc } from 'hooks';
import { formatPhoneNumber, toTitleCase } from '../../../../../utils';

const PrintTemplate = ( { requests } ) => {
	console.log('PrintTemplate requests', requests);
	return requests.map(( request ) => {
		return request.type === 'TEMP_PASSWORD' ? (
			<TempPasswordTemplate key={request.id} request={request} />
		) : (
			<MediaTemplate key={request.id} request={request} />
		);
	});
};

export default PrintTemplate;

const TempPasswordTemplate = ( { request } ) => {
	const { id, code, location, inmateEid, owner_info } = request;
	const { selectedUser } = useUserContext();
	const housingUnit = location ? location : selectedUser.location;
	return (
		<>
			<div className={styles.page}>
				<p>Print Request: {id.replace('PRINT^REQUEST^', '')}</p>
				<p>Owner: {toTitleCase(owner_info)}</p>
				<p>Owner Id: {formatPhoneNumber(inmateEid)}</p>
				<p>Printed at: {format(new Date(), 'MMM d, yyyy, h:mm a')}</p>
				<p>Housing unit: {housingUnit.huName}</p>
				<p>New Temporary Password: {code}</p>
			</div>
		</>
	);
};

const MediaTemplate = ( { request } ) => {
	const { id, location, inmateEid, owner_info, mediaLocation } = request;
	const { selectedUser } = useUserContext();
	const { mediaSrc } = useMediaSrc(mediaLocation);
	const housingUnit = location ? location : selectedUser.location;

	return (
		<div className={styles.page}>
			<p>Print Request: {id.replace('PRINT^REQUEST^', '')}</p>
			<p>Owner: {toTitleCase(owner_info)}</p>
			<p>Owner Id: {formatPhoneNumber(inmateEid)}</p>
			<p>Printed at: {format(new Date(), 'MMM d, yyyy, h:mm a')}</p>
			<p>Housing unit: {housingUnit.huName}</p>
			<img src={mediaSrc} />
		</div>
	);
};
