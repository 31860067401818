import React from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import { Print } from 'assets/icons';

const PrintActionIcon = ({ onClickHandler }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Print" classes={{ tooltip: classes.tooltip }}>
      <IconButton
        style={{
          backgroundColor: 'transparent',
          borderRadius: '8px',
        }}
        onClick={onClickHandler}
      >
        <Print
          color={'#9191a1'}
          sx={{
            '&:hover': {
              color: '#101010',
            },
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default PrintActionIcon;

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#EBEBEF',
    color: '#101010',
    padding: 0,
    borderRadius: '8px',
    width: '73px',
    height: '48px',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
