import React from 'react';
import styled from '@mui/material/styles/styled';
import Checkbox from '@mui/material/Checkbox';

function IndeterminateCheckbox( { indeterminate, className = '', onChange, ...rest } ) {
	const ref = React.useRef(null);

	React.useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate, rest.checked]);

	return (
		<StyledCheckbox
			ref={ref}
			onChange={onChange}
			className={className + ' cursor-pointer'}
			{...rest}
		/>
	);
}

export default IndeterminateCheckbox;

const StyledCheckbox = styled(Checkbox)(( { theme } ) => ({
	'& .MuiSvgIcon-root': {
		fontSize: 20,
	},
	'&.MuiCheckbox-root': {
		color: theme.palette.customPalette.grey4,
		borderColor: theme.palette.customPalette.grey4,
	},
	'&.Mui-checked': {
		color: theme.palette.customPalette.green1,
		borderColor: theme.palette.customPalette.green1,
	},
}));
