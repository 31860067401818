import React from 'react';
import styles from '../SurveyResults.module.scss';

import IconButton from '@mui/material/IconButton';

import BackIcon from '@mui/icons-material/ArrowBack';

import formatName from 'utils/formatInmateName';
import ReactToPrint from 'react-to-print';

import { Download, Print } from 'assets/icons';
import { useHistory } from 'react-router-dom';
import { useSurveyResultsContext } from 'modules/surveys/context';

const ResultPageHeader = ({ name, printableRef }) => {
  const { isSurveySummary, currentSurveyResponseOwner, isSurveyAnonymous } =
    useSurveyResultsContext();
  const { firstName, lastName } = currentSurveyResponseOwner ?? {
    firstName: '',
    lastName: '',
  };
  const history = useHistory();

	return (
		<div className={styles.content__header}>
			<div className={styles.content__header__left}>
				<IconButton
					classes={{
						root: styles.content__header__backButton,
					}}
					onClick={history.goBack}
				>
					<BackIcon />
				</IconButton>
				<div className={styles.content__header__surveyTitle}>
          <span className={styles.content__header__surveyTitle__surveyName}>
            {name}
          </span>
					<span className={styles.content__hexader__surveyTitle__resultsOwner}>
            {isSurveySummary
              ? 'Survey Results'
              : isSurveyAnonymous ? 'Anonymous Response'
              : `${formatName(firstName, lastName)}'s responses`}
          </span>
				</div>
			</div>
			<div className={styles.content__header__actions}>
				<ReactToPrint
					trigger={() => (
						<IconButton
							classes={{
								root: styles.content__header__actions__item,
							}}
							style={{
								backgroundColor: 'transparent',
								borderRadius: '8px',
							}}
						>
							<Print />
						</IconButton>
					)}
					content={() => printableRef.current}
				/>
			</div>
		</div>
	);
};

export default ResultPageHeader;
