import List from '@mui/material/List';

import PopperListItem from './PopperListItem';

import { Bookmark, OpenLock, Lock } from 'assets/icons';
import { useChatContext } from 'modules/users-chats/context';

export default function PopperList( { closePopover } ) {
	const { channelInfo, toggleBlockChatModal, toggleSaveChatModal, toggleUnBlockChatModal } = useChatContext();
	const selectedChat = channelInfo?.ChannelData || {};
	return (
		<List sx={{ padding: 1 }}>
			<PopperListItem
				label={selectedChat?.isChannelBlock ? 'Unblock Chat' : 'Block Chat'}
				Icon={selectedChat?.isChannelBlock ? OpenLock : Lock}
				onClick={e => {
					e.preventDefault();
					closePopover();
					selectedChat?.isChannelBlock ? toggleUnBlockChatModal() : toggleBlockChatModal();
				}}
			/>
			<PopperListItem
				label={'Save Chat'}
				Icon={Bookmark}
				onClick={e => {
					e.preventDefault();
					closePopover();
					toggleSaveChatModal();
				}} />
		</List>
	);
}