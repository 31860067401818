import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import styles from './MediaModal.module.scss';

import Grid from '@mui/material/Grid';

import CustomButton from 'shared/button';
import Modal from 'shared/modal';
import BlockForm from './BlockForm';
import ModalInfo from './ModalInfo';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalCaption from './ModalCaption';

import { OpenLock } from './Icons';
import { Lock } from 'assets/icons';

import { generatePath, useHistory } from 'react-router';

import routes from 'routing/routes';

const MediaModal = ( props ) => {
	const [mode, setMode] = useState('view');
	const {
		isOpenModal,
		open,
		toggleOpen,
		src,
		thumbnailSrc,
		isMessageMine,
		owner_info,
		owner_id,
		recipient_info,
		createdAt,
		text,
		mediaType,
		statusData,
		isVideoCall,
		flagData,
	} = props;

	const isBlocked = statusData?.status === 'blocked';

	const [firstName, lastName] = owner_info.split(' ');

	const history = useHistory();

	const hasCaption = !!text;

	useEffect(() => {
		if (!open && mode === 'block') {
			setMode('view');
		}
	}, [open]);

	function openUserPage() {
		history.push(generatePath(routes.user, { userId: owner_id }));
	}

	return (
		<Modal
			className={cs(styles.modal, mode === 'block' && styles['modal--block'])}
			open={open}
			onClose={toggleOpen}
		>
			{mode === 'view' ? (
				<>
					<ModalHeader
						lastName={lastName}
						firstName={firstName}
						openUserPage={openUserPage}
						createdAt={createdAt}
						toggleOpen={toggleOpen}
						isVideoCall={isVideoCall}
						{...props}
					/>
					<div className={styles.content}>
						<div className={styles.content__wrapper}>
							<ModalContent
								src={src}
								isOpenModal={isOpenModal}
								recipient_info={recipient_info}
								hasCaption={hasCaption}
								{...props}
							/>
							{(hasCaption || isBlocked) && (
								<ModalCaption
									isMessageMine={isMessageMine}
									isBlocked={isBlocked}
									hasCaption={hasCaption}
									text={text}
									{...props}
								/>
							)}
						</div>
					</div>
					{statusData || flagData ? (
						<Grid>
							<ModalInfo statusData={statusData} flagData={flagData} />
						</Grid>
					) : null}
					<Grid justifyContent='flex-end' container mt={2}>
						{!isVideoCall && (
							<CustomButton
								className={styles.modal__blockButton}
								startIcon={isBlocked ? <OpenLock /> : <Lock />}
								display='primary'
								onClick={() => setMode('block')}
							>
								{isBlocked ? 'Unblock' : 'Block'}
							</CustomButton>
						)}
						<CustomButton
							className={styles.modal__closeButton}
							onClick={toggleOpen}
							display='secondary'
						>
							Close
						</CustomButton>
					</Grid>
				</>
			) : (
				<BlockForm
					thumbnailSrc={thumbnailSrc}
					mediaType={mediaType}
					onClose={toggleOpen}
					isBlocked={isBlocked}
					{...props}
				/>
			)}
		</Modal>
	);
};

export default MediaModal;
