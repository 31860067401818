import { Box } from '@mui/material';
import DeviceActions from './DeviceActions';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import BlockUser from 'modules/users-chats/block-user';
import InvitationsCharts from '../profile-charts/InvitationsCharts';
import FlaggedCharts from '../profile-charts/FlaggedCharts';
import SentimentsCharts from '../profile-charts/SentimentsCharts';
import UnblockUser from 'modules/users-chats/unblock-user';

export default function InmateProfile() {
	const { sxBoxTop, sxBoxBottom, sxBoxWrapper } = useInmateProfileStyles();
	const { loadingUserProfileInfo } = useUserProfileContext();

	if (loadingUserProfileInfo) {
		return null;
	}
	;

	return (
		<Box sx={sxBoxWrapper}>
			<Box sx={sxBoxTop}>
				<DeviceActions />
				<SentimentsCharts />
			</Box>
			<Box sx={sxBoxBottom}>
				<FlaggedCharts />
				<InvitationsCharts />
			</Box>
		</Box>
	);
}

function useInmateProfileStyles() {
	return {
		sxBoxWrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		sxBoxTop: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
		},
		sxBoxBottom: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
		},
	};
}