import { Box } from '@mui/material';

export default function CardContentLayout( props ) {
	const { chartType = 'pie' } = props;
	const PromptLayout = LAYOUT_TYPES[chartType];
	return <PromptLayout {...props} />;
}

function PieTypeLayout( props ) {
	const { LegendComponent, ChartComponent } = props;
	return (
		<Box sx={CARD_LAYOUT_STYLES.sxBoxWrapper}>
			<Box sx={CARD_LAYOUT_STYLES.sxBoxLegend}>
				<LegendComponent />
			</Box>
			<Box sx={CARD_LAYOUT_STYLES.sxBoxChart}>
				<ChartComponent />
			</Box>
		</Box>
	);
}

function TrendTypeLayout( props ) {
	const { ChartComponent } = props;
	return (
		<Box sx={CARD_LAYOUT_STYLES.sxBoxWrapper}>
			<Box sx={{ ...CARD_LAYOUT_STYLES.sxBoxChart, width: '100%' }}>
				<ChartComponent />
			</Box>
		</Box>
	);
}

const LAYOUT_TYPES = {
	pie: PieTypeLayout,
	trend: TrendTypeLayout,
};

const CARD_LAYOUT_STYLES = {
	sxBoxWrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	sxBoxLegend: {
		width: '50%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	sxBoxChart: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		width: '50%',
		height: '100%',
	},
};
