import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const Print = ( props ) => (
	<SvgIcon width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
		<path
			d='M17 17H20C21.1046 17 22 16.1046 22 15V9C22 7.89543 21.1046 7 20 7H4C2.89543 7 2 7.89543 2 9V15C2 16.1046 2.89543 17 4 17H7'
			stroke='#101010'
			strokeWidth='2'
			fill='none'
		/>
		<rect
			x='7'
			y='14'
			width='10'
			height='6'
			rx='1'
			stroke='#101010'
			strokeWidth='2'
			fill='none'
		/>
		<path
			d='M7 4C7 3.44772 7.44772 3 8 3H16C16.5523 3 17 3.44772 17 4V7H7V4Z'
			stroke='#101010'
			strokeWidth='2'
			fill='none'
		/>
	</SvgIcon>
);

export const Filter = ( props ) => (
	<SvgIcon width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
		<path
			d='M0.8085 1.7413C2.36265 3.72797 4.66038 6.66797 4.66038 6.66797V10.0013C4.66038 10.7346 5.26328 11.3346 6.00016 11.3346C6.73704 11.3346 7.33994 10.7346 7.33994 10.0013V6.66797C7.33994 6.66797 9.63767 3.72797 11.1918 1.7413C11.5335 1.3013 11.2186 0.667969 10.6559 0.667969H1.33771C0.781704 0.667969 0.466855 1.3013 0.8085 1.7413Z'
			fill='currentColor'
		/>
	</SvgIcon>
);

export const OpenLock = ( props ) => (
	<SvgIcon width='14' height='19' viewBox='0 0 14 20' fill='none' {...props}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7 2C5.34315 2 4 3.34315 4 5V8H11C12.6569 8 14 9.34315 14 11V16C14 17.6569 12.6569 19 11 19H3C1.34315 19 0 17.6569 0 16V11C0 9.6935 0.83498 8.5826 2 8.17078V5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5C12 5.55228 11.5523 6 11 6C10.4477 6 10 5.55228 10 5C10 3.34315 8.65685 2 7 2ZM3 10C2.44772 10 2 10.4477 2 11V16C2 16.5523 2.44772 17 3 17H11C11.5523 17 12 16.5523 12 16V11C12 10.4477 11.5523 10 11 10H3Z'
			fill='currentColor'
		/>
	</SvgIcon>
);

export const Camera = () => (
	<SvgIcon
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14 6H5C4.44772 6 4 6.44772 4 7V17C4 17.5523 4.44772 18 5 18H14C14.5523 18 15 17.5523 15 17V7C15 6.44772 14.5523 6 14 6ZM5 4C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H14C15.6569 20 17 18.6569 17 17V7C17 5.34315 15.6569 4 14 4H5Z'
			fill='currentColor'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M20 8.44827L17.5039 9.87463C17.1923 10.0527 17 10.384 17 10.7429V13.2609C17 13.6198 17.1923 13.9511 17.5039 14.1292L20 15.5555L20 8.44827ZM16.5116 8.13815C15.5769 8.67227 15 9.66631 15 10.7429V13.2609C15 14.3375 15.5769 15.3315 16.5116 15.8657L19.0077 17.292C20.341 18.0539 22 17.0912 22 15.5555V8.44827C22 6.91262 20.341 5.94989 19.0077 6.71178L16.5116 8.13815Z'
			fill='currentColor'
		/>
	</SvgIcon>
);

export const History = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4C9.536 4 7.332 5.114 5.865 6.865L8 9H2V3L4.447 5.446C6.28 3.336 8.984 2 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z'
			fill='currentColor'
		/>
	</svg>
);

export const Alert = ( props ) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
		<circle cx='12' cy='12' r='9.25' stroke='#DF093C' strokeWidth='1.5' />
		<path
			d='M12.7314 14.0674H11.3027L11.1455 7.04688H12.8955L12.7314 14.0674ZM11.0977 16.2139C11.0977 15.9541 11.182 15.7399 11.3506 15.5713C11.5192 15.3981 11.7493 15.3115 12.041 15.3115C12.3327 15.3115 12.5628 15.3981 12.7314 15.5713C12.9001 15.7399 12.9844 15.9541 12.9844 16.2139C12.9844 16.4645 12.9023 16.6742 12.7383 16.8428C12.5742 17.0114 12.3418 17.0957 12.041 17.0957C11.7402 17.0957 11.5078 17.0114 11.3438 16.8428C11.1797 16.6742 11.0977 16.4645 11.0977 16.2139Z'
			fill='#DF093C'
		/>
	</svg>
);
