const useHighlightPart = ({ parts, searchTerm }) => (part, index) => {
  const prevPart = parts[index - 1];
  const nextPart = parts[index + 1];

  if (searchTerm.indexOf(`${prevPart?.text}${part?.text}`) !== -1) {
    return true;
  }

  return searchTerm.indexOf(`${part?.text}${nextPart?.text}`) !== -1;
};

export default useHighlightPart;