export default function createBookmarksArray( bookmarks = {} ) {
	let itemArr = [];
	const objEntries = Object.entries(bookmarks);
	objEntries.map(( item ) => {
		const children = item[1].map(( child ) => {
			const newChild = { ...child, type: item[0] };
			return newChild;
		});
		itemArr = [...itemArr, ...children];
	});

	return itemArr;
}
