import React from 'react';
import styles from './BulletinsList.module.scss';

import Grid from '@mui/material/Grid';
import BulletinDetails from '../bulletin-details';

import { Facilities, HousingUnit, Inmates, All } from './Icons';

import format from 'date-fns/format';
import { useHistory, generatePath } from 'react-router-dom';
import { useToggler } from 'hooks';

import routes from 'routing/routes';

const getRecipientsIcon = (type) => {
  switch (type) {
    case 'All':
      return All;
    case 'Inmates':
      return Inmates;
    case 'Facilities':
      return Facilities;
    case 'HU':
      return HousingUnit;
    default:
      return () => null;
  }
};

const BulletinsItem = (props) => {
  const Icon = getRecipientsIcon(props.sentTo);
  const [modal, toggleModal] = useToggler();
  const history = useHistory();
  return (
    <>
      <Grid
        key={props.id}
        classes={{ root: styles.bulletin }}
        onClick={toggleModal}
        container
      >
        <Grid xs={3} item>
          <p
            className={styles.bulletin__sender}
            onClick={(evt) => {
              evt.stopPropagation();
              history.push(
                generatePath(routes.singleStaff, { staffId: props.sentBy.id })
              );
            }}
          >{`${props.sentBy.firstName} ${props.sentBy.lastName}`}</p>
        </Grid>
        <Grid classes={{ root: styles.bulletin__subject }} xs={3} item>
          {props.subject}
        </Grid>
        <Grid xs={2} item>
          <div className={styles.bulletin__recipients}>
            <Icon />
            <p>{props.sentTo}</p>
          </div>
        </Grid>
        <Grid xs={2} item>
          <div className={styles.bulletin__statistic}>
            <p>{props.sent}</p>
            <div />
            <p>{props.read}</p>
          </div>
        </Grid>
        <Grid classes={{ root: styles.bulletin__date }} xs={2} item>
          {format(new Date(props.createdAt), 'MMM d, yy')}
        </Grid>
      </Grid>
      <BulletinDetails open={modal} onClose={toggleModal} {...props} />
    </>
  );
};

export default BulletinsItem;
