import React, { memo } from 'react';
import styles from './CreateBulletin.module.scss';

import Grid from '@mui/material/Grid';
import { TextInput, MultilineInput } from 'shared/inputs';
import Button from 'shared/button';
import FileInput from '../file-input';

import { useStepperContext } from 'context';
import { useFormContext } from 'react-hook-form';

const NextButton = memo(() => {
  const { formState } = useFormContext();
  const { onNextStep } = useStepperContext();

  return (
    <Button
      onClick={onNextStep}
      disabled={
        !formState.dirtyFields.title ||
        !formState.dirtyFields.text ||
        formState.errors.title ||
        formState.errors.text
      }
      display="primary"
    >
      Next
    </Button>
  );
});

const TitleStep = () => (
  <div className={styles.formStep}>
    <TextInput
      className={styles.input}
      name="title"
      label="Title"
      helperText="Up to 100 char."
      fullWidth
    />
    <MultilineInput
      name="text"
      label="Announcement text"
      placeholder="Type here"
    />
    <FileInput name="file" acceptedFiles={['application/pdf']} />
    <Grid
      classes={{ root: styles.actions }}
      justifyContent="flex-end"
      wrap="nowrap"
      container
    >
      <NextButton />
    </Grid>
  </div>
);

export default TitleStep;
