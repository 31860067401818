import { Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SearchInput from './SearchInput';

import { useSearchMessage } from 'modules/users-chats/hooks';
import { useChatContext } from 'modules/users-chats/context';

const ChatSearch = () => {

	const { searchResultsTotal, setSearchTerm, setSearchResults, setSearchResultsTotal } = useChatContext();
	const { searchValue, setSearchValue, handleSearch } = useSearchMessage(setSearchResults, setSearchResultsTotal);
	const onClickHandler = () => {
		setSearchResults([]);
		setSearchResultsTotal(0);
		setSearchTerm('');
	};

	return (
		<Grid container direction='row' alignItems='center' justifyContent='flex-start'>
			<Grid item>
				{searchResultsTotal ? <IconButton onClick={onClickHandler}>
					<ArrowBackIcon sx={{ color: 'customPalette.grey1' }} />
				</IconButton> : null}
			</Grid>
			<Grid item width={searchResultsTotal ? 'calc(100% - 40px)' : '100%'}>
				<SearchInput
					value={searchValue}
					setValue={setSearchValue}
					onSearch={handleSearch}
				/>
			</Grid>

		</Grid>
	);
};

export default ChatSearch;

//TODO: add memoization to search results and search input