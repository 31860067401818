import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default function dateRangeBuilder( { from, to, interval = 'month', day } ) {
	dayjs.extend(utc);

	function utcFormat( date ) {
		return date.utc().format();
	}

	switch (interval) {
		case 'day':
			const toDayjs = dayjs();
			const fromDayjs = dayjs().subtract(1, 'day');
			return { from: utcFormat(fromDayjs), to: utcFormat(toDayjs) };

		case 'week':
			const toWeekDayjs = dayjs();
			const fromWeekDayjs = dayjs().subtract(1, 'week');
			return { from: utcFormat(fromWeekDayjs), to: utcFormat(toWeekDayjs) };

		case 'month':
			const toMonthDayjs = dayjs();
			const fromMonthDayjs = dayjs().subtract(1, 'month');
			return { from: utcFormat(fromMonthDayjs), to: utcFormat(toMonthDayjs) };

		case 'months':
			const toMonthsDayjs = dayjs();
			const fromMonthsDayjs = dayjs().subtract(3, 'month');
			return { from: utcFormat(fromMonthsDayjs), to: utcFormat(toMonthsDayjs) };

		case 'customDay':
			return { from: utcFormat(dayjs(day)), to: utcFormat(dayjs(day).add(23, 'hour').add(59, 'minute').add(59, 'second')) };

		default:
			return { from: utcFormat(dayjs(from)), to: utcFormat(dayjs(to).add(23, 'hour').add(59, 'minute').add(59, 'second')) };

	}

}
