import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';

const usePrint = ({
  contentRef,
  onErrorMessage = 'something went wrong loading your document',
}) => {
  const handlePrint = () => (
    <ReactToPrint
      content={() => contentRef.current}
      onPrintError={() => toast.error(onErrorMessage)}
    />
  );

  return handlePrint();
};

export default usePrint;
