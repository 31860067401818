import { useCallback, useEffect } from 'react';

import { Grid, Box, Typography } from '@mui/material';

import query from 'query-string';

import UpDownMessageControl from './UpDownMessageControl';
import SearchResultInfo from './SearchResultInfo';

import { useChatContext } from '../../context';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from 'hooks';

function SearchResultsControls() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQuery();
  const {
    currentFoundMessageIndex,
    setCurrentFoundMessageIndex,
    searchResultsTotal,
    searchResults,
  } = useChatContext();
  const isLastMessage = currentFoundMessageIndex + 1 === searchResultsTotal;
  const isFirstMessage = !currentFoundMessageIndex;

  useEffect(() => {
    if (searchResultsTotal > 0) {
      const newParams = {
        ...queryParams,
        messageId: searchResults[currentFoundMessageIndex]?.clientKey,
      };
      history.replace(`${location.pathname}?${query.stringify(newParams)}`);
    }
  }, [currentFoundMessageIndex, searchResults]);

  const arrowUpActionHandler = useCallback(() => {
    if (!isLastMessage) {
      setCurrentFoundMessageIndex(currentFoundMessageIndex + 1);
    }
  }, [currentFoundMessageIndex]);

  const arrowDownActionHandler = useCallback(() => {
    if (!isFirstMessage) {
      setCurrentFoundMessageIndex(currentFoundMessageIndex - 1);
    }
  }, [currentFoundMessageIndex]);
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <UpDownMessageControl
        upActionHandler={arrowUpActionHandler}
        firstMessage={isFirstMessage}
        downActionHandler={arrowDownActionHandler}
        lastMessage={isLastMessage}
      />
      <SearchResultInfo
        index={currentFoundMessageIndex}
        total={searchResultsTotal}
      />
    </Grid>
  );
}

export default SearchResultsControls;
