import React from 'react';
import styles from './MediaModal.module.scss';
import Button from 'shared/button';
import { useFormContext } from 'react-hook-form';

export const SubmitBlockButton = ({ loading }) => {
  const { formState } = useFormContext();

  return (
    <Button
      className={styles.form__submitButton}
      type="submit"
      display="primary"
      disabled={!formState?.dirtyFields?.reason || loading}
    >
      Block
    </Button>
  );
};
export const SubmitUnBlockButton = () => {
  return (
    <Button
      className={styles.form__submitButton}
      type="submit"
      display="primary"
    >
      Unblock
    </Button>
  );
};
