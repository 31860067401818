import { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoginPage from './pages/auth-pages/login/LoginPage';
import ForgotPasswordPage from './pages/auth-pages/password-forgot/ForgotPasswordPage';

import routes from './routing/routes';
import ResetPasswordPage from './pages/auth-pages/password-reset';

export default function UnprotectedApp() {
  return (
    <Fragment>
      <Switch>
        <Route path={routes.login} component={LoginPage} />
        <Route path={routes.passwordReset} component={ResetPasswordPage} />
        <Route path={routes.passwordForgot} component={ForgotPasswordPage} />
        <Redirect to={routes.login} />
      </Switch>
    </Fragment>
  );
}
