import React, { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Typography } from '@mui/material';

const AudioPlayer = ({ src }) => {
  const [playing, setPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioPlayer = useRef(null);
  const wavesurfer = useRef(null);

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: audioPlayer.current,
      waveColor: '#9191A1',
      progressColor: '#03A882',
      height: 28,
      barMinHeight: 2,
      barWidth: 2,
      barGap: 5,
      barRadius: 2,
      cursorWidth: 2,
      cursorColor: '#9191A1',
      hideScrollbar: true,
      normalize: true,
      responsive: true,
      backend: 'MediaElement',
    });

    src && wavesurfer.current.load(src);

    wavesurfer.current.on('ready', () => {
      setDuration(Math.floor(wavesurfer.current.getDuration()));
    });

    wavesurfer.current.on('finish', () => {
      setPlaying(false);
    });

    wavesurfer.current.on('audioprocess', () => {
      setCurrentTime(Math.floor(wavesurfer.current.getCurrentTime()));
    });

    return () => {
      wavesurfer.current.destroy();
    };
  }, [src]);

  const formatTimes = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  };

  const handlePlayPause = () => {
    if (playing) {
      wavesurfer.current.pause();
      setPlaying(false);
    } else {
      wavesurfer.current.play();
      setPlaying(true);
    }
  };

  return (
    <Box width={304} sx={sxBoxWrapper}>
      <IconButton onClick={handlePlayPause} sx={sxIcon}>
        {playing ? (
          <PauseIcon color="primary" />
        ) : (
          <PlayArrowIcon color="primary" />
        )}
      </IconButton>
      <Typography component="span" mx={1.5} sx={sxTyp}>
        {currentTime ? formatTimes(currentTime) : formatTimes(duration)}
      </Typography>
      <Box
        width={'100%'}
        sx={{
          '& wave': {
            zIndex: 0,
          },
        }}
      >
        <div ref={audioPlayer} />
      </Box>
    </Box>
  );
};

export default AudioPlayer;

const sxBoxWrapper = () => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
  };
};

const sxIcon = () => {
  return {
    height: 24,
    width: 24,
  };
};

const sxTyp = () => {
  return { overflow: 'hidden', width: '50px' };
};
