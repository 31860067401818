import RepliedMessage from '../replied-message';
import TextMessageNotification from './TextMessageNotification';
import TextMessageContent from './TextMessageContent';
import { TextMessageContainer } from './TextMessageContainer';
import { BlockAlert } from '../image-message/BlockAlert';
import { Box } from '@mui/material';

export default function TextMessage(props) {
  const {
    text,
    borderRadius,
    clientKey,
    parentMessageMetadata,
    isMessageMine,
    isMessageBlock,
    statusData,
    messageReadStatus,
  } = props;
  const isSeen = true;
  const { sxBoxWrapper } = useTextMessageStyles(isMessageMine);

  return (
    <Box sx={sxBoxWrapper}>
      <BlockAlert
        isMessageBlock={Boolean(statusData.reason)}
        reason={statusData.reason}
        isMine={isMessageMine}
      />
      <TextMessageContainer
        border={borderRadius}
        isBlock={isMessageBlock}
        isMine={isMessageMine}
      >
        {parentMessageMetadata ? (
          <RepliedMessage parentMessage={parentMessageMetadata} />
        ) : null}
        <TextMessageContent text={text} />
        <TextMessageNotification
          date={clientKey}
          isSeen={isSeen}
          messageReadStatus={messageReadStatus}
        />
      </TextMessageContainer>
    </Box>
  );
}

function useTextMessageStyles(isMine) {
  return {
    sxBoxWrapper: {
      display: 'flex',
      flexDirection: isMine ? 'row-reverse' : 'row',
      alignItems: 'center',
    },
  };
}
