import React, { useState } from 'react';

import AllCheckbox from './AllCheckbox';
import ItemCheckbox from './ItemCheckbox';
import GroupCheckbox from './GroupCheckbox';
import CheckboxesSearch from './CheckboxesSearch';

import { useFormContext } from 'react-hook-form';

const CheckboxListInput = ({
  name,
  options,
  allLabel = 'All',
  grouped,
  search,
}) => {
  const { register } = useFormContext();
  const [displayOptions, setDisplayOptions] = useState(options);
  register(name);

  function handleSearch(input) {
    if (!input.length) {
      return setDisplayOptions(options);
    }

    if (grouped) {
      const result = Object.values(options).reduce((acc, curr) => {
        const itemsFound = curr.items.filter(
          (item) => item.name.search(input) !== -1
        );
        const nameMatch = curr.name.search(input) !== -1;
        if (nameMatch || itemsFound.length) {
          acc[curr.id] = {
            id: curr.id,
            name: curr.name,
            items: curr.items,
            displayItems: nameMatch ? undefined : itemsFound,
          };
        }

        return acc;
      }, {});
      setDisplayOptions(result);
    } else {
      const result = options.filter((item) => item.search(input) !== -1);
      setDisplayOptions(result);
    }
  }

  return (
    <>
      {search && <CheckboxesSearch onSearch={handleSearch} />}
      <AllCheckbox name={name} label={allLabel} />
      {grouped
        ? Object.values(displayOptions).map((group) => (
            <GroupCheckbox
              key={group.id}
              name={name}
              label={group.name}
              items={group.items}
              displayItems={group.displayItems}
            />
          ))
        : options.map((item) => (
            <ItemCheckbox
              key={item.id}
              name={name}
              itemId={item.id}
              label={item.name}
            />
          ))}
    </>
  );
};

export default CheckboxListInput;
