import { useMutation } from '@tanstack/react-query';
import { useGraphql } from 'context';

export default function useBlockMessage() {
  const api = useGraphql();

  function blockImageMutation(payload) {
    return api.controller.channel.blockMsg(payload);
  }

  return useMutation({ mutationFn: blockImageMutation });
}
