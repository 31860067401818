import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const House = (props) => (
  <SvgIcon width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M5 14.9997L20 3.33301L35 14.9997V33.333C35 34.2171 34.6488 35.0649 34.0237 35.69C33.3986 36.3152 32.5507 36.6663 31.6667 36.6663H8.33333C7.44928 36.6663 6.60143 36.3152 5.97631 35.69C5.35119 35.0649 5 34.2171 5 33.333V14.9997Z"
      stroke="#59585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15 36.6667V20H25V36.6667"
      stroke="#59585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default House;
