import { useQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useMessagesTypesAnalysis( rangeDateFilter, agency,facilities ) {
	const api = useGraphql();
	const shouldExecuteQuery = Object.entries(rangeDateFilter).every(( [_, value] ) => value !== null) && rangeDateFilter.from < rangeDateFilter.to;

	function getSentimentsAnalysis() {
		return api.controller.analytics.messagesTypesTotalsAnalysisQuery({
			agencyId: agency?.id,
			facilityId: facilities?.[0]?.id,
			dateRange: rangeDateFilter,
		});
	}

	const sentimentsAnalysisQuery = useQuery({
		queryKey: [QUERY_KEYS.MESSAGES_TYPES_ANALYSIS, agency?.id, rangeDateFilter],
		queryFn: getSentimentsAnalysis,
		enabled: shouldExecuteQuery,
		keepPreviousData: !shouldExecuteQuery,
	});

	return sentimentsAnalysisQuery;

}
