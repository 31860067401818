import { useContext } from 'react';
import { PrintRequestsContext } from 'modules/print-request-section/context';

const usePrintRequestsSectionContext = () => {
	const context = useContext(PrintRequestsContext);
	if (!context) {
		throw new Error('usePrintRequestsSectionContext must be used within a PrintRequestsContext');
	}
	return context;
};

export default usePrintRequestsSectionContext;
