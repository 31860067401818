import React, { createContext, useContext, useMemo, useState } from 'react';

import queryString from 'query-string';

import { useChannelLastMessage, useToggler } from 'hooks';
import { useAuthContext } from 'modules/auth/context';
import {
  useChannelInfo,
  useChannelMessages,
  useFilteredChannelMessages,
} from '../hooks';
import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';

const ChatContext = createContext({});

const initChatContext = () => {
  const { strictPermissionCheck } = useAuthContext();
  const { messageId: messageKey } = queryString.parse(location.search);
  const { chatSelected } = useChatsTabContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [focusMessage, setFocusMessage] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsTotal, setSearchResultsTotal] = useState(0);
  const [floatDate, setFloatDate] = useState('');
  const [filters, setFilters] = useState({
    filtersAreActive: false,
    mediaTypes: [],
  });

  const [currentFoundMessageIndex, setCurrentFoundMessageIndex] = useState(0);
  const [saveChatModal, toggleSaveChatModal] = useToggler(false);
  const [blockChatModal, toggleBlockChatModal] = useToggler(false);
  const [unBlockChatModal, toggleUnBlockChatModal] = useToggler(false);
  const [saveMessageModal, toggleSaveMessageModal] = useToggler(false);
  const [flagMessageModal, toggleFlagMessageModal] = useToggler(false);
  const [loadingSearch, toggleLoadingSearch] = useToggler();
  const [blockMessageModal, toggleBlockMessageModal] = useToggler(false);

  const {
    data,
    loadingBackward,
    loadingForward,
    hasBackwardMore,
    hasForwardMore,
    loadNextBackwardPage,
    loadNextForwardPage,
  } = useChannelMessages(messageKey);
  const { data: channelInfo } = useChannelInfo(chatSelected);
  const { data: channelLastMessageData } = useChannelLastMessage(
    channelInfo?.ChannelData?.id
  );
  const {
    data: filteredMessagesData,
    isFetching: loadingFilteredMessages,
    hasNextPage: hasMoreFilteredMessages,
    fetchNextPage: fetchMoreFilteredMessages,
  } = useFilteredChannelMessages({
    messageKey,
    channelId: chatSelected,
    ...filters,
  });
  const hasPermission = {
    blockChat: strictPermissionCheck('MESSAGE#WRITE#doc_block_msg'),
    UnblockChat: strictPermissionCheck('MESSAGE#WRITE#doc_block_msg'),
  };

  return {
    data,
    filteredMessagesData,
    channelLastMessageData,
    channelInfo,
    messageKey,
    loadingBackward,
    loadingForward,
    loadingFilteredMessages,
    hasMoreFilteredMessages,
    fetchMoreFilteredMessages,
    hasBackwardMore,
    hasForwardMore,
    loadNextBackwardPage,
    loadNextForwardPage,
    unBlockChatModal,
    toggleUnBlockChatModal,
    focusMessage,
    setFocusMessage,
    loadingSearch,
    toggleLoadingSearch,
    currentFoundMessageIndex,
    setCurrentFoundMessageIndex,
    searchResultsTotal,
    setSearchResultsTotal,
    searchResults,
    setSearchResults,
    searchTerm,
    setSearchTerm,
    hasPermission,
    floatDate,
    setFloatDate,
    saveChatModal,
    toggleSaveChatModal,
    blockChatModal,
    toggleBlockChatModal,
    saveMessageModal,
    toggleSaveMessageModal,
    flagMessageModal,
    toggleFlagMessageModal,
    filters,
    setFilters,
    blockMessageModal,
    toggleBlockMessageModal,
  };
};

const ChatProvider = ({ children, ...props }) => {
  const contextValue = initChatContext();
  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};
const useChatContext = () => {
  const context = useContext(ChatContext);

  return context;
};

export { ChatProvider, useChatContext };
