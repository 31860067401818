import format from 'date-fns/format';
import { useUserProfileContext } from './index';

export default function useMessagePosition( props ) {
	const { userId } = useUserProfileContext();
	const { owner_id, nextMessage, prevMessage, clientKey } = props;

	const isMessageMine = owner_id === userId;
	const nextMine = nextMessage?.owner_id === userId;
	const prevMine = prevMessage?.owner_id === userId;
	const prevMsgDate = prevMessage?.clientKey ? format(new Date(prevMessage?.clientKey), 'dd.MM.yyyy') : null;
	const nextMsgDate = nextMessage?.clientKey ? format(new Date(nextMessage?.clientKey), 'dd.MM.yyyy') : null;
	const clientKeyDate = clientKey ? format(new Date(clientKey), 'dd.MM.yyyy') : null;

	const prevDay = prevMsgDate !== clientKeyDate;
	const nextDay = nextMsgDate !== clientKeyDate;

	return { isMessageMine, nextDay, prevDay, nextMine, prevMine };
}
