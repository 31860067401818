import { Grid, Typography, Box } from '@mui/material';

export default function ChatLive({ inLiveChat }) {
  const { sxLiveCallGrid, sxLiveCallIcon } = useChatLiveStatus();
  return <>
    {
      inLiveChat ?
        <Grid sx={sxLiveCallGrid}>
          <Typography variant='regularBlack' component='span'>
            Live call
          </Typography>
          <Box component='span' sx={sxLiveCallIcon} />
        </Grid> : null
    }
  </>;
}

const useChatLiveStatus = () => ({
  sxLiveCallGrid: {
    display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
  },
  sxLiveCallIcon: {
    borderRadius: '50%', bgcolor: 'customPalette.liveCallRed', width: 8, height: 8,
  },
});
