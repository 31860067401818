import React, { memo } from 'react';
import cs from 'classnames';
import styles from './SelectInput.module.scss';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CheckIcon from '@mui/icons-material/Check';

import { useFormContext } from 'react-hook-form';

const SelectInput = ({
                       className,
                       label,
                       renderValue,
                       name,
                       options,
                       formControl = true,
                       value: propValue,
                       ...props
                     }) => {
  const { register, watch } = useFormContext();
  const { onChange } = register(name);

  const handleChange = formControl ? register(name).onChange : onChange;
  const value = formControl ? watch(name) : propValue;

  return (
    <Grid
      classes={{ root: cs(styles.select, className) }}
      alignItems='center'
      container
    >
      {label && <label className={styles.select__label}>{label}</label>}
      <Select
        classes={{
          root: styles.select__input,
          icon: styles.select__icon,
        }}
        renderValue={renderValue}
        name={name || ''}
        onChange={handleChange}
        value={value || ''}
        variant='standard'
        {...props}
      >
        {Object.keys(options).map((optionValue) => (
          <MenuItem
            classes={{ selected: styles.select__selectedItem }}
            key={optionValue}
            value={optionValue}
          >
            {renderValue(optionValue)}
            {value === optionValue && <CheckIcon />}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};


export default memo(SelectInput);
