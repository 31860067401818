import React from 'react';
import styles from './BoundaryType.module.scss';

import ErrorDefaultImg from '../../../assets/ErrorDefaultImg.png';

import { useHistory } from 'react-router-dom';

const BoundaryType = ( {
												 title = 'Something went wrong',
												 message = '',
												 stack = '',
												 image = '',
												 actions = [],
											 } ) => {
	return (
		<>
			<div className={styles.info_wrapper}>
				<h1>{title}</h1>
				{message ? <h3>{message}</h3> : null}
				<StackList stack={stack} />
				<ActionButtons actions={actions} />
			</div>
			<div className={styles.image_wrapper}>
				<img src={ErrorDefaultImg} alt='error gif' width='800' />
			</div>
		</>
	);
};

export default BoundaryType;

const ActionButtons = ( { actions } ) => {
	if (actions) {
		return <div className={styles.action_wrapper}>
			{actions.map(( action ) => {
				return <button className={styles.button_action} onClick={action.BtnAction}><span>{action.BtnText}</span>
				</button>;
			})
			}</div>;
	}
};

const StackList = ( { stack } ) => {
	const stackToArray = stack.replace('/n', '').split('(');

	if (stack) {
		return <div className={styles.stack_info}>
			{stackToArray.map(( line ) => <span>{line}</span>)}
		</div>;
	}
};

const DEFAULT_ACTIONS_BUTTONS = [{
	BtnText: 'Go Home', BtnAction: () => {
		goHome();
	},
}, {
	BtnText: 'Try Again', BtnAction: () => {
		alert('Trying Again');
	},
}];