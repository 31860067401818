import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStepperContext } from 'context';

const Form = ({
  initialValues,
  onSubmit,
  onError,
  validation,
  mode = 'onChange',
  reValidateMode = 'onChange',
  shouldUnregister,
  className,
  children,
  type = 'form',
  ...props
}) => {
  const { currentStep } = useStepperContext();

  const validationSchema =
    type === 'stepper-form' && currentStep.validation
      ? yupResolver(currentStep.validation)
      : validation
      ? yupResolver(validation)
      : undefined;

  const methods = useForm({
    mode,
    reValidateMode,
    resolver: validationSchema,
    shouldUnregister,
    defaultValues: initialValues,
  });

  const handleSubmit = useCallback(methods.handleSubmit(onSubmit, onError), [
    onSubmit,
    onError,
  ]);

  // Prevent reset input on create survey page
  // useEffect(() => {
  //   methods.reset(initialValues);
  // }, [initialValues]);

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={handleSubmit} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  validation: PropTypes.object,
  mode: PropTypes.oneOf(['onChange', 'onSubmit', 'onBlur']),
  reValidateMode: PropTypes.oneOf(['onChange', 'onBlur', 'onSubmit']),
  shouldUnregister: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  type: PropTypes.oneOf(['stepper-form', 'form']),
};

export default Form;
