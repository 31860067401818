import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import ShowMore from './more-popper/ShowMore';
import TooltipElement from 'shared/tooltip-element';

import { useChatContext } from 'modules/users-chats/context';
import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';
import ChannelFilter from './channel-filter';

const ControlButtons = () => {
	const Actions = ActionButtons();
	const { loadingSearch } = useChatContext();
	const justifyAs = loadingSearch ? 'space-between' : 'flex-end';

	return (
		<Grid container justifyContent={justifyAs} alignItems='center'>
			{loadingSearch ? <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.6, marginLeft: 2 }}>
				<CircularProgress size={20} />
				<Typography variant='regularGrey4' component='span'>
					Loading
				</Typography>
			</Box> : null}
			<Box>
				{Actions.map(( { label, title, icon, action, iconRotation } ) => {
					return (
						<ActionButton
							key={label}
							label={label}
							title={title}
							Icon={icon}
							onClickHandler={action}
							transformIcon={label === 'sorter' ? iconRotation : null}
						/>
					);
				})}
			</Box>
		</Grid>
	);
};

export default ControlButtons;

const ActionButton = ( { Icon, transformIcon, onClickHandler, title } ) => {
	return (
		<TooltipElement title={title} placement='top'>
			<IconButton onClick={onClickHandler} sx={sxIconButton}>
				<Icon sx={{ ...sxIcon, transform: transformIcon }} />
			</IconButton>
		</TooltipElement>
	);
};
const ActionButtons = () => {
	const { showContactDetails, setShowContactDetails } = useChatsTabContext();
	// const { currentMessageKey, setCurrentMessageKey } = useChatContext();

	return [
		{
			title: 'Filter',
			label: 'filter',
			icon: ChannelFilter,
		},
		{
			title: showContactDetails ? 'Hidde contact details' : 'Show contact details',
			label: 'info',
			icon: InfoIcon,
			action: () => setShowContactDetails(!showContactDetails),
		},
		{
			title: 'Show more options',
			label: 'showmore',
			icon: ShowMore,
		},
	];
};

const sxIconButton = {
	borderRadius: '8px',
	border: '1px solid #EBEBEF',
	width: '34px',
	height: '34px',
	marginLeft: '8px',
};

const sxIcon = {
	width: '20px',
	height: '20px',
	color: 'customPalette.grey1',
};
