import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'utils';
import { useGraphql } from 'context';

export default function useSearchImageMessages( term ) {
	const api = useGraphql();

	function searchImageMessages( { pageParam = null } ) {
		return term !== '' ? api.controller.search.photos({
			term,
			sortBy:[{
				by: 'clientKey',
				order: 'desc',
			}],
			limit: 30,
			nextPage: pageParam,
		}) : null;
	}

	const searchImageMessagesQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_IMAGE_MESSAGES, term],
		queryFn: searchImageMessages,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
	});

	return searchImageMessagesQuery;
}
