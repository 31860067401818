import React from 'react';
import styles from './AccountInfoTab.module.scss';
import cs from 'classnames';

import Grid from '@mui/material/Grid';
import BlockInfoModal from './block-info-modal';
import Avatar from 'shared/avatar';

import { KiwiCop } from 'shared/sidebar/Icons';

import { format } from 'date-fns';
import { generatePath } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useToggler } from 'hooks';

import { routes } from 'routing';
import { BLOCKED_ENTITY } from 'modules/users-chats/constants';

const getBlockStatus = ( blockedEntity, isBlock ) => {
	const statuses = {
		[BLOCKED_ENTITY.CHAT]: isBlock ? 'Chat blocked' : 'Chat unblocked',
		[BLOCKED_ENTITY.USER]: isBlock ? 'Account blocked' : 'Account unblocked',
	};

	return statuses[blockedEntity];
};

const getProfilePath = ( id ) => {
	if (!id) return null;

	const path = id.includes('O^')
		? generatePath(routes.singleStaff, {
			staffId: id,
		})
		: id.includes('I^') || id.includes('FNF^')
			? generatePath(routes.user, {
				userId: id,
			})
			: null;

	return path;
};

const BlockedHistoryItem = ( props ) => {
	const {
		reason,
		blockedEntity,
		blockedByMetadata,
		blockedByDOC,
		blockedByKiwiCop,
		isBlock,
		unblockedByTTL,
		updatedByMetadata,
		with: chatWith,
		createdAt,
	} = props;

	const history = useHistory();
	const [modal, toggleModal] = useToggler();

	const displayDate = format(Date.parse(createdAt), 'MMM d, yyyy');

	const profilePath = getProfilePath(blockedByMetadata?.id);
	const blockStatus = getBlockStatus(blockedEntity, isBlock);
	const timeExpired = unblockedByTTL;
	const isChat = blockedEntity === BLOCKED_ENTITY.CHAT;
	const isKiwiCop = blockedByKiwiCop || unblockedByTTL;
	const moderator = blockedByMetadata || updatedByMetadata;
	const displayChatWith =
		isChat && `${chatWith?.firstName?.[0]}. ${chatWith?.lastName}`;

	return (
		<>
			<Grid
				onClick={() => toggleModal(true)}
				classes={{ root: styles.statusItem }}
				container
				item
			>
				<Grid xs={3} item>
					<div
						className={cs(
							styles.statusItem__status,
							isBlock && styles['statusItem__status--blocked'],
						)}
					>
						{blockStatus}
						{isChat && <span>with {displayChatWith}</span>}
					</div>
				</Grid>
				<Grid xs={4} item>
					{!timeExpired && (
						<div className={styles.completedBy}>
							{isKiwiCop ? (
								<div
									className={cs(
										styles.completedBy__user,
										styles['completedBy__user--kiwicop'],
									)}
								>
									<KiwiCop />
								</div>
							) : (
								<Avatar className={styles.completedBy__user} {...moderator} />
							)}
							<div className={styles.completedBy__info}>
								{isKiwiCop ? (
									<span
										className={cs(
											styles.completedBy__name,
											isKiwiCop && styles['completedBy__name--kiwicop'],
										)}
									>
                    KiwiCop
                  </span>
								) : (
									<>
                    <span
											onClick={() => profilePath && history.push(profilePath)}
											className={styles.completedBy__name}
										>
                      {moderator.firstName} {moderator.lastName}
                    </span>
										{/* TODO: need to be phone number */}
										<span className={styles.completedBy__phone}>
                      {moderator.id}
                    </span>
									</>
								)}
							</div>
						</div>
					)}
				</Grid>
				<Grid xs={3} item>
					{isBlock && (isKiwiCop || blockedByDOC) && (
						<div className={styles.statusItem__reason}>{reason}</div>
					)}
				</Grid>
				<Grid xs={2} item>
					<div className={styles.statusItem__date}>
						<span>{displayDate}</span>
					</div>
				</Grid>
			</Grid>
			<BlockInfoModal
				open={modal}
				toggleOpen={toggleModal}
				blockStatus={blockStatus}
				isChat={isChat}
				profilePath={profilePath}
				displayChatWith={displayChatWith}
				isKiwiCop={isKiwiCop}
				moderator={moderator}
				{...props}
			/>
		</>
	);
};

export default BlockedHistoryItem;
