const useSelectedRequests = ( obj = {}, requests = [] ) => {
	const formSelectedRequests = Object.keys(obj).filter(( item ) => {
		return obj[item];
	});
	const filteredRequests = requests.filter(( request ) =>
		formSelectedRequests.includes(request.id.replace('PRINT^REQUEST^', 'PR-')),
	);

	return [filteredRequests];
};

export default useSelectedRequests;
