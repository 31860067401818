import React from 'react';
import ContentLoader from 'react-content-loader';

const GridLayoutLoader = props => (
	<ContentLoader viewBox='0 0 900 607' height={907} width={900} backgroundColor='#ebebef' foregroundColor='#A2CDB0' {...props}>
		<rect x='30' y='20' rx='0' ry='0' width='130' height='23' />
		<rect x='30' y='60' rx='0' ry='0' width='200' height='120' />
		<rect x='30' y='189' rx='0' ry='0' width='200' height='15' />
		<rect x='30' y='211' rx='0' ry='0' width='140' height='15' />
		<rect x='243' y='60' rx='0' ry='0' width='200' height='120' />
		<rect x='243' y='189' rx='0' ry='0' width='200' height='15' />
		<rect x='243' y='211' rx='0' ry='0' width='140' height='15' />
		<rect x='455' y='60' rx='0' ry='0' width='200' height='120' />
		<rect x='455' y='189' rx='0' ry='0' width='200' height='15' />
		<rect x='455' y='211' rx='0' ry='0' width='140' height='15' />
		<rect x='667' y='60' rx='0' ry='0' width='200' height='120' />
		<rect x='667' y='188' rx='0' ry='0' width='200' height='15' />
		<rect x='667' y='209' rx='0' ry='0' width='140' height='15' />
		<rect x='30' y='280' rx='0' ry='0' width='130' height='23' />
		<rect x='30' y='320' rx='0' ry='0' width='200' height='120' />
		<rect x='30' y='450' rx='0' ry='0' width='200' height='15' />
		<rect x='30' y='474' rx='0' ry='0' width='140' height='15' />
		<rect x='243' y='320' rx='0' ry='0' width='200' height='120' />
		<rect x='455' y='320' rx='0' ry='0' width='200' height='120' />
		<rect x='667' y='320' rx='0' ry='0' width='200' height='120' />
		<rect x='243' y='450' rx='0' ry='0' width='200' height='15' />
		<rect x='455' y='450' rx='0' ry='0' width='200' height='15' />
		<rect x='667' y='450' rx='0' ry='0' width='200' height='15' />
		<rect x='243' y='474' rx='0' ry='0' width='140' height='15' />
		<rect x='455' y='474' rx='0' ry='0' width='140' height='15' />
		<rect x='667' y='474' rx='0' ry='0' width='140' height='15' />
	</ContentLoader>
);

export default GridLayoutLoader;