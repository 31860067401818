import { useEffect } from 'react';

import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import useAnalyticsModalContext from 'shared/analytics-modal/hooks/useAnalyticsModalContext';

import { If } from 'shared/utilities';

export default function AnalyticsModalLayout(props) {
  const {
    ItemsListComponent,
    ChatControlsComponent,
    ChatComponent,
    OptionsFilterComponent,
    ChatOwnersInfo,
    FilterComponent,
    additionalProps,
    SearchComponent,
  } = props;
  const { setListQueryPayload, modalConfig } = useAnalyticsModalContext();
  const violation = additionalProps?.violation;

  useEffect(() => {
    setListQueryPayload((payload) => {
      const newPayload = { ...payload, violation };
      return newPayload;
    });
  }, [additionalProps?.violation]);

  return (
    <ModalContentContainer>
      <LeftPanelContainer
        size={modalConfig?.listRenderItem === 'invitation' ? 'medium' : 'large'}
      >
        <If condition={OptionsFilterComponent}>
          <ScrollableContainer>
            <OptionsFilterComponent />
          </ScrollableContainer>
        </If>
        <If condition={modalConfig?.filterMessages}>
          <FilterComponentContainer>
            <FilterComponent />
          </FilterComponentContainer>
        </If>
        <If condition={modalConfig?.search}>
          <SearchComponentContainer>
            <SearchComponent />
          </SearchComponentContainer>
        </If>
        <MessagesListContainer>
          <If condition={!!ItemsListComponent}>
            <ItemsListComponent />
          </If>
        </MessagesListContainer>
      </LeftPanelContainer>
      <If condition={modalConfig?.showChatPanel}>
        <RightPanelContainer>
          <ChatControlContainer>
            <ChatOwnersInfo>
              <If condition={!!ChatControlsComponent}>
                <ChatControlsComponent />
              </If>
            </ChatOwnersInfo>
          </ChatControlContainer>
          <ChatContainer>
            <If condition={!!ChatComponent}>
              <ChatComponent />
            </If>
          </ChatContainer>
        </RightPanelContainer>
      </If>
    </ModalContentContainer>
  );
}

const ModalContentContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '100%',
  padding: theme.spacing(1),
  display: 'flex',
}));

const SearchComponentContainer = styled(Box)({
  display: 'flex',
  height: '50px',
});

const LeftPanelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1.5,
  flexDirection: 'column',
  margin: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.customPalette.grey5,
  borderRadius: theme.spacing(2),
}));

const RightPanelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 2,
  flexDirection: 'column',
  margin: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.customPalette.grey5,
  borderRadius: theme.spacing(2),
}));

const ScrollableContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 1),
  height: 30,
}));

const MessagesListContainer = styled(Box)({
  display: 'flex',
  flex: 1,
});

const ChatControlContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  display: 'flex',
  // height: 30,
  justifyContent: 'space-between',
  margin: theme.spacing(),
  border: '2px solid',
  borderColor: theme.palette.customPalette.grey5,
  padding: theme.spacing(1),
}));

const ChatContainer = styled(Box)({
  display: 'flex',
  flex: 2,
});

const FilterComponentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}));

//TODO: Try to remove the useEffect using proper logic
