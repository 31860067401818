import React, { memo } from 'react';
import styles from './Skeleton.module.scss';
import Grid from '@mui/material/Grid';

const DocumentSkeleton = ({ amount = 1 }) => {
  let i = 0;
  const arr = [];

  while (i < amount) {
    arr.push(i);
    i++;
  }

  return arr.map((_, index) => (
    <div key={index} className={styles.documentSkeleton}>

      <div className='header'>
          <div className={styles.documentSkeleton__circle}></div>
      </div>

      <div className={styles.documentSkeleton__divider}></div>

      <div className={styles.documentSkeleton__footer}>
          <div className={styles.documentSkeleton__lines__item}></div>
          <div className={styles.documentSkeleton__lines__item__short}></div>
      </div>
        
    </div>
    
  ));
};

export default memo(DocumentSkeleton);
