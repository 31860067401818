import { useQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';
import { QUERY_KEYS } from 'utils';

export default function useFlaggedMessagesAnalytics( userId, rangeDateFilter ) {
	const api = useGraphql();
	const { agency, facilities } = useAuthContext();
	const shouldExecuteQuery = Object.entries(rangeDateFilter).every(( [_, value] ) => value !== null) && rangeDateFilter.from < rangeDateFilter.to;

	function getFlaggedMessagesAnalytics() {
		return api.controller.analytics.messageFlaggedAnalysis({
			userId,
			agencyId: agency?.id,
			facilityId: facilities?.[0]?.id,
			dateRange: rangeDateFilter,
		});
	}

	const flaggedMsgsAnalyticsQuery = useQuery({
		queryKey: [QUERY_KEYS.FLAGGED_MESSAGES_ANALYSIS, userId, rangeDateFilter],
		queryFn: getFlaggedMessagesAnalytics,
		enabled: shouldExecuteQuery,
		keepPreviousData: !shouldExecuteQuery,
	});

	return flaggedMsgsAnalyticsQuery;
}
