import React from 'react';
import styles from './PrintRequestModal.module.scss';

import { useMediaSrc } from 'hooks';
import { toTitleCase } from 'utils';

const ContentPreview = ({ request }) => {
  return request.type === 'TEMP_PASSWORD' ? (
    <TempPasswordPreview key={request.id} owner={request.owner_info} />
  ) : (
    <ImagePreview key={request.id} mediaLocation={request.mediaLocation} />
  );
};

export default ContentPreview;

const ImagePreview = ({ mediaLocation }) => {
  const { thumbnailSrc } = useMediaSrc(mediaLocation);
  return (
    <div className={styles.printable_image}>
      <img src={thumbnailSrc} />
    </div>
  );
};
const TempPasswordPreview = ({ owner }) => {
  return (
    <div className={styles.printable_password}>
      <p>{toTitleCase(owner)}</p>
      <p>New Temporary Password</p>
    </div>
  );
};
