export default function formatPhoneNumber( number = '' ) {
	const digits = number ? number.replace(/\D/g, ''): '';
	const validFormat = /^1?(\d{3})(\d{3})(\d{4})$/;

	if (validFormat.test(digits)) {
		const format = digits.replace(validFormat, '($1) $2-$3');
		return format;
	}

	return number;
}