import React, { useMemo, useState, useEffect } from 'react';
import cs from 'classnames';
import styles from './DocumentsFilter.module.scss';

import MuiButton from '@mui/material/Button';
import { CheckboxListInput, SwitchInput } from 'shared/inputs';
import Form from 'shared/form';
import Modal, { ModalActions, ModalTitle } from 'shared/modal';
import Button from 'shared/button';

import FilterIcon from '@mui/icons-material/FilterAlt';

import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';
import { useToggler } from 'hooks';
import { Skeleton } from 'shared/skeleton';
import { useDocumentsContext } from '../context';
import formatFilterData from 'utils/formatFilterData';

const FILE_OPTIONS = [
  {
    id: 'File',
    name: 'PDF',
  },
  {
    id: 'Video',
    name: 'Video',
  },
  {
    id: 'Image',
    name: 'Photo',
  },
];

const ResetButton = ({ initialValues }) => {
  const { reset } = useFormContext();

  return (
    <Button
      display="secondary"
      onClick={() => {
        reset(initialValues);
      }}
    >
      Reset
    </Button>
  );
};

const SubmitButton = () => {
  // const {
  //   formState: { isValid, isDirty },
  // } = useFormContext();

  return (
    <Button type="submit" display="primary">
      Apply Filters
    </Button>
  );
};

const BlockLabel = ({ name, label }) => {
  const { watch } = useFormContext();
  const value = watch(name);

  const amount = Object.values(value).filter(Boolean).length;

  return (
    <p className={styles.block__label}>
      {label}

      {amount !== 0 && (
        <span>
          <span>{amount}</span>
        </span>
      )}
    </p>
  );
};


const handleFormCheckboxSubmitBuilder = (setFilterState, toggleOpen, filterFiles) => (filterData) => {
  setFilterState(filterData);
  filterFiles(filterData);
  toggleOpen(false);
};

const DocumentsFilter = () => {
  const { facilities, facilitiesHousingUnitInfo } = useAuthContext();
  const [housingUnitOptions, setHousingUnitOptions] = useState({});
  const [loadingHousingUnits, setLoadingHousingUnits] = useState([]);
  const [open, toggleOpen] = useToggler();
  const { checkedFilterState, setCheckedFilterState, filterState, setFilterState, filterFiles } = useDocumentsContext();

  const { initialValues } = useMemo(() => {
    const initialValues = {
      type: {
        File: false,
        Video: false,
        Image: false,
      },
      recipient: {},
      onlyInmate: false,
    };

    return { initialValues };
  }, []);

  useEffect(() => {
    setHousingUnitOptions(() => {
      const housingUnitOptionDetails = [];
      facilitiesHousingUnitInfo.forEach((facility) => {
        housingUnitOptionDetails.push({
          name: facility.facilityName,
          id: facility.facilityId,
        });
        initialValues.recipient[`${facility.facilityId}`] = false;
      });
      
      return housingUnitOptionDetails;
    });
  }, [facilitiesHousingUnitInfo]);

  useEffect(() => {
    if (facilitiesHousingUnitInfo?.length === facilities.length) {
      if (Object.keys(filterState).length === 0) setFilterState(initialValues);
      if (Object.keys(checkedFilterState).length === 0)
        setCheckedFilterState(initialValues);
      setLoadingHousingUnits(false);
    }
  }, [housingUnitOptions]);

  useEffect(() => {
    setCheckedFilterState(filterState);
  },[open, filterState]);

  const handleFormCheckboxSubmit = handleFormCheckboxSubmitBuilder(setFilterState, toggleOpen, filterFiles);

  return (
    <>
      <MuiButton
        className={styles.button}
        startIcon={<FilterIcon />}
        onClick={toggleOpen}
      >
        Filters
      </MuiButton>
      <Modal className={styles.modal} open={open} onClose={toggleOpen}>
        {loadingHousingUnits ? (
          <Skeleton amount={3} />
        ) : (
          <>
            <ModalTitle className={styles.modal__documentFilterHeader} text="Filters" onClose={toggleOpen} />
            <Form
              initialValues={checkedFilterState}
              onSubmit={handleFormCheckboxSubmit}
            >
              <div className={styles.modal__container}>
                <div className={cs(styles.block, styles.modal__column)}>
                  <BlockLabel name="recipient" label="Recipient" />
                  <div className={styles.block__content}>
                    <CheckboxListInput
                      name="recipient"
                      options={housingUnitOptions}
                      allLabel="All facilities"
                      search
                    />
                  </div>
                </div>
                <div className={styles.modal__column}>
                  <div className={styles.block}>
                    <BlockLabel name="type" label="Type" />
                    <div className={styles.block__content}>
                      <CheckboxListInput
                        name="type"
                        options={FILE_OPTIONS}
                        allLabel="All Types"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ModalActions>
                <ResetButton initialValues={initialValues} />
                <SubmitButton />
              </ModalActions>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default DocumentsFilter;
