import { LogoFoil } from 'assets/icons';

import IconButton from '@mui/material/IconButton';

import { routes } from 'routing';
import { useHistory } from 'react-router';
import { useSearchParams } from '../../hooks';

const Logo = () => {
	const history = useHistory();
	const onClickHandler = () => {
		history.push(routes.chatsMetrics);
	};
	return (
		<IconButton disableRipple onClick={onClickHandler}>
			<LogoFoil sx={sxLogo} />
		</IconButton>
	);
};

export default Logo;

const sxLogo = {
	fontSize: '33px',
};
