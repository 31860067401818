import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';

const StepperContext = createContext({});

const initStepContext = (steps) => {
  const STEPS_MAP = useMemo(() => {
    return steps.reduce((acc, step, index) => {
      acc[step.id] = index;
      acc[index] = step.id;
      return acc;
    }, {});
  }, [steps]);

  const [currentStep, _goToStep] = useState(steps[0]);

  const goToStep = useCallback(
    (step) => {
      if (typeof step === 'number') {
        _goToStep(steps[step]);
      } else {
        _goToStep(steps[STEPS_MAP[step]]);
      }
    },
    [STEPS_MAP, steps, _goToStep]
  );

  const onNextStep = useCallback(() => {
    const nextStep = steps[STEPS_MAP[currentStep.id] + 1];
    if (!nextStep) throw new Error('Steps out of range');
    _goToStep(nextStep);
  }, [STEPS_MAP, currentStep, goToStep, steps]);

  const onPrevStep = useCallback(() => {
    const prevStep = steps[STEPS_MAP[currentStep.id] - 1];
    if (!prevStep) throw new Error('Steps out of range');
    _goToStep(prevStep);
  }, [STEPS_MAP, currentStep, goToStep, steps]);

  const getStepState = useCallback(
    (id) => {
      if (id === currentStep.id) {
        return STEP_STATE.ACTIVE;
      }

      const currentIndex = STEPS_MAP[currentStep.id];
      const stepIndex = STEPS_MAP[id];
      if (stepIndex < currentIndex) {
        return STEP_STATE.COMPLETED;
      }
      if (stepIndex > currentIndex) {
        return STEP_STATE.TO_COMPLETE;
      }
    },
    [STEPS_MAP, currentStep.id]
  );

  return {
    currentStep,
    onNextStep,
    onPrevStep,
    getStepState,
  };
};

const StepperProvider = ({ children, steps }) => {
  const { currentStep, onNextStep, onPrevStep, getStepState } =
    initStepContext(steps);

  const contextValue = useMemo(
    () => ({ currentStep, onNextStep, onPrevStep, getStepState, steps }),
    [currentStep, onNextStep, onPrevStep, getStepState, steps]
  );

  return (
    <StepperContext.Provider value={contextValue}>
      {children}
    </StepperContext.Provider>
  );
};

const useStepperContext = () => {
  const context = useContext(StepperContext);

  return context;
};

const STEP_STATE = {
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
  TO_COMPLETE: 'TO_COMPLETE',
};

export { StepperProvider, useStepperContext, STEP_STATE };
