import React from 'react';

const Calendar = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00001 4.16536C3.6193 4.16536 2.50001 5.28465 2.50001 6.66536V14.9987C2.50001 16.3794 3.6193 17.4987 5.00001 17.4987H15C16.3807 17.4987 17.5 16.3794 17.5 14.9987V6.66536C17.5 5.28465 16.3807 4.16536 15 4.16536H5.00001ZM0.833344 6.66536C0.833344 4.36418 2.69882 2.4987 5.00001 2.4987H15C17.3012 2.4987 19.1667 4.36418 19.1667 6.66536V14.9987C19.1667 17.2999 17.3012 19.1654 15 19.1654H5.00001C2.69882 19.1654 0.833344 17.2999 0.833344 14.9987V6.66536Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66668 0.832031C7.12691 0.832031 7.50001 1.20513 7.50001 1.66536V4.9987C7.50001 5.45894 7.12691 5.83203 6.66668 5.83203C6.20644 5.83203 5.83334 5.45894 5.83334 4.9987V1.66536C5.83334 1.20513 6.20644 0.832031 6.66668 0.832031Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 0.832031C13.7936 0.832031 14.1667 1.20513 14.1667 1.66536V4.9987C14.1667 5.45894 13.7936 5.83203 13.3333 5.83203C12.8731 5.83203 12.5 5.45894 12.5 4.9987V1.66536C12.5 1.20513 12.8731 0.832031 13.3333 0.832031Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833344 8.33203C0.833344 7.87179 1.20644 7.4987 1.66668 7.4987H18.3333C18.7936 7.4987 19.1667 7.87179 19.1667 8.33203C19.1667 8.79227 18.7936 9.16537 18.3333 9.16537H1.66668C1.20644 9.16537 0.833344 8.79227 0.833344 8.33203Z"
      fill="currentColor"
    />
  </svg>
);

export default Calendar;
