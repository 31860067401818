import If from 'components/if/If';
import Logo from 'assets/Logo';
import Login from 'pages/auth-pages/components/login';
import AuthInput from 'pages/auth-pages/components/auth-input';
import AuthLoader from 'pages/auth-pages/components/auth-loader';
import SquareBackground from 'pages/auth-pages/components/square-background';
import BackgroundSmudge from 'pages/auth-pages/components/background-smudge';

import { useAuthContext } from 'modules/auth/context';

import LOGIN_FORM_VALIDATION from './login-form-validation';
import {
  INITIAL_LOGIN_FORM_VALUES,
  LoginFormType,
} from './initial-login-form-values';

import { routes } from 'routing';

export default function LoginPage() {
  // @ts-ignore
  const { onLogin, error, setUserLoginCredentials } = useAuthContext();

  const showLoader = onLogin.isLoading;

  function onHandleFormSubmit(form: LoginFormType) {
    setUserLoginCredentials(form);
    onLogin.mutate({ form });
  }

  return (
    <Login>
      <SquareBackground />
      <BackgroundSmudge />
      <If condition={!showLoader} otherwise={<AuthLoader />}>
        <Login.Title>
          <h2 className="text-lg md:text-2xl text-grey-2 mb-4">Welcome to</h2>
          <Logo className="mx-auto h-[30px] w-auto md:h-[40px] md:w-[272px]" />
        </Login.Title>

        <Login.FormContainer>
          <Login.Form
            onSubmit={onHandleFormSubmit}
            initialValues={INITIAL_LOGIN_FORM_VALUES}
            validation={LOGIN_FORM_VALIDATION}
          >
            <Login.EmailInput>
              <AuthInput
                autoComplete="email"
                label="Email address"
                name="email"
                placeholder="you@example.com"
                type="text"
                errorMsg={error}
                invalid={Boolean(error)}
              />
            </Login.EmailInput>
            <Login.PasswordInput>
              <AuthInput
                autoComplete="current-password"
                label="Password"
                name="password"
                placeholder="Your password"
                type="password"
                invalid={Boolean(error)}
              />
            </Login.PasswordInput>

            <Login.ForgotPasswordLink href={routes.passwordForgot}>
              Forgot password?
            </Login.ForgotPasswordLink>
            <Login.SubmitButton type="submit">Log in</Login.SubmitButton>
          </Login.Form>
        </Login.FormContainer>
      </If>
    </Login>
  );
}
