import format from 'date-fns/format';

import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import AudioPlayer from 'modules/users-chats/chat-details/print-messages/audio-message/AudioPlayer';
import AudioTranscription from 'modules/users-chats/chat-details/print-messages/audio-message/AudioTranscription';

import { useGraphql } from 'context';
import { useMediaSrc } from 'hooks';

function VoiceMessage(props) {
  const { isBlock, text, clientKey, mediaLocation } = props;

  const isSeen = true;

  const { getImages } = useGraphql();

  const [mediaSrc] = useMediaSrc(getImages)({ itemKey: mediaLocation });

  return (
    <Card sx={sxCard({ isBlocked: isBlock })}>
      <CardContent sx={sxCardContent}>
        <AudioPlayer src={mediaSrc} />
        {text ? <AudioTranscription transcription={text} /> : null}
      </CardContent>
      <Box sx={sxNotification}>
        <Typography variant="messageDate" component="span">
          {format(new Date(clientKey), 'h:mm a')}
        </Typography>
        <DoneAllIcon sx={sxReadMark(isSeen)} />
      </Box>
    </Card>
  );
}

export default VoiceMessage;

const sxCard = ({ isBlocked }) => {
  return {
    borderRadius: 4,
    maxWidth: 470,
    borderColor: isBlocked ? 'customPalette.error' : 'customPalette.grey5',
    '&:hover': {
      borderColor: isBlocked ? 'customPalette.error' : 'customPalette.grey1',
    },
    marginTop: 1,
  };
};

const sxCardContent = () => {
  return {
    '&.MuiCardContent-root': {
      padding: '12px 16px 0px 16px ',
      borderColor: 'customPalette.grey3',
    },
  };
};

const sxCardAction = () => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '4px 16px 8px 16px',
  };
};

const sxReadMark = (isSeen = true) => {
  return { color: isSeen ? 'primary.main' : 'grey.main', fontSize: '16px' };
};

const sxNotification = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 2,
    gap: 0.5,
  };
};
