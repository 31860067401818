import { useInfiniteQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';

import { QUERY_KEYS } from 'utils';
import { useAuthContext } from 'modules/auth/context';

export default function useAnalyticsModalList(payload) {
  const { agency, facilities } = useAuthContext();

  const { filters, dateRange, mediaTypesFilter, term = '' } = payload;

  const api = useGraphql();
  const shouldExecuteChannelQuery =
    !!agency && !!facilities && dateRange && filters?.[0]?.term === 'CHANNEL';
  const shouldExecuteInvitationQuery =
    !!filters?.length && !!dateRange && filters?.[0]?.term === 'statusId';
  const shouldExecuteMessageQuery =
    !!filters?.length &&
    !!dateRange &&
    !shouldExecuteInvitationQuery &&
    !shouldExecuteChannelQuery;

  function getAnalyticsModalInvitationList({ pageParam = null }) {
    return api.controller.analytics.invitationInfoBatch({
      agencyId: agency?.id,
      limit: 100,
      senderId: filters?.[1]?.value,
      page: pageParam,
      order: 'DESC',
      filters: filters?.[0]?.value ? [filters?.[0]] : [],
    });
  }

  function getAnalyticsModalMessageList({ pageParam = null }) {
    const activeMediaTypesFiltersArray = mediaTypesFilter.map((filter) => ({
      term: 'mediaTypeId',
      value: msgTypes[filter],
    }));
    return api.controller.analytics.messageInfoBatch({
      limit: 20,
      filters: [filters?.[0], ...activeMediaTypesFiltersArray],
      ownerId: filters?.[1]?.value,
      agencyId: agency?.id,
      facilityId: !filters?.[1]?.value ? facilities?.[0]?.id : null,
      dateRange: dateRange,
      order: 'DESC',
      page: pageParam,
      term: term,
    });
  }

  function getAnalyticsModalChannelList({ pageParam = null }) {
    return api.controller.analytics.channelInfoBatch({
      agencyId: agency?.id,
      facilityId: facilities?.[0]?.id,
      queryType: filters?.[0]?.value,
      limit: 20,
      page: pageParam,
    });
  }

  const chatsFlaggedMessagesQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.ANALYTICS_LIST_INFO, payload],
    queryFn: shouldExecuteMessageQuery
      ? getAnalyticsModalMessageList
      : shouldExecuteChannelQuery
        ? getAnalyticsModalChannelList
        : getAnalyticsModalInvitationList,
    enabled:
      shouldExecuteChannelQuery ||
      shouldExecuteMessageQuery ||
      shouldExecuteInvitationQuery,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  return chatsFlaggedMessagesQuery;
}

const msgTypes = {
  text: '1',
  image: '2',
  voice: '3',
  video: '4',
};
