import { Grid, Typography } from '@mui/material';

import Avatar from 'shared/avatar';

import { useAuthContext } from 'modules/auth/context';

export default function ProfileAvatar() {
	const { user } = useAuthContext();
	const { sxProfileAvatar, sxAvatarBoxWrapper } = useProfileAvatarStyles();

	return (
		<Grid container direction='row' gap={1} alignItems='center' sx={{ height: '100%' }}>
			<Avatar
				id={user.id}
				firstName={user.firstName}
				lastName={user.lastName}
				sxAvatar={sxProfileAvatar}
				sxBoxWrapper={sxAvatarBoxWrapper}
			/>
			<Typography variant='regularBlack'>
				{user.firstName} {user.lastName}
			</Typography>
		</Grid>
	);
}

function useProfileAvatarStyles() {
	return {
		sxProfileAvatar: {
			width: 30,
			height: 30,
			fontSize: 12,
		},
		sxAvatarBoxWrapper: {
			border: 'none',
		},
	};
}