import { useState, useCallback } from 'react';

const useToggler = (initialValue) => {
  const [toggleValue, setToggleValue] = useState(!!initialValue);

  const onToggle = useCallback(
    (b) =>
      setToggleValue((collapsed) =>
        typeof value === 'boolean' ? value : !collapsed
      ),
    []
  );

  return [toggleValue, onToggle];
};

export default useToggler;
