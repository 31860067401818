import React from 'react';

import Permission from './Permission';

import { useAdminSettingsContext } from '../context';

export const PermissionsList = () => {
  const { appPermissions } = useAdminSettingsContext();

  return (
    <>
      {appPermissions.map((permission) => {
        return <Permission key={permission.value} permission={permission} />;
      })}
    </>
  );
};

export default PermissionsList;
