import { Box } from '@mui/material';

export function FallbackContainer({ children, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function EmptyResultContainer({ children, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
