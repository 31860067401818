import React from 'react';
import styles from './RolesList.module.scss';

import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';

import { TwoUsers } from 'assets/icons';
import { useAdminSettingsContext } from '../context';
import useRoleSelected from '../hooks/useRoleSelected';

const RoleItem = ({ role }) => {
  const { setRoleSelected } = useAdminSettingsContext();
  const [isSelected] = useRoleSelected(role.id);

  const handleClick = () => {
    setRoleSelected(role);
  };

  const roleBackground = isSelected ? '#ebebef' : '#ffffff';

  return (
    <>
      <Grid
        key={role.id}
        classes={{ root: styles.role }}
        direction="column"
        gap={1}
        justifyContent="flex-start"
        container
        onClick={handleClick}
        sx={{ background: roleBackground }}
      >
        <Grid container direction="row" gap={1}>
          <TwoUsers />
          <h4 className={styles.role__title}>{role.name}</h4>
        </Grid>
        <span>{`${role.assignedUsersCount || 0} users`}</span>
      </Grid>
      <Divider variant="middle" />
    </>
  );
};

export default RoleItem;
