import { useInfiniteQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useSearchVoiceMessages( term ) {
	const api = useGraphql();
	const shouldExecuteQuery = !!term;

	function searchVoiceMessages( { pageParam = null } ) {
		return term !== '' ? api.controller.search.voice({
			term,
			limit: 30,
			nextPage: pageParam,
		}) : null;
	}

	const searchVoiceMessagesQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_VOICE_MESSAGES, term],
		queryFn: searchVoiceMessages,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
	});

	return searchVoiceMessagesQuery;
}
