import { List, Box } from '@mui/material';

import Suspense from 'shared/suspense-list';
import useBookmarks from 'modules/auth/hooks/useBookmarks';
import BookmarkItem from './BookmarkItem';

import { useAuthContext } from 'modules/auth/context';
import { EmptyPlaceholder } from './BookmarkIcons';

const BookmarkList = ( { onClose } ) => {
	const { user } = useAuthContext();
	const bookmarksQuery = useBookmarks(user.id);
	const bookmarkArr = creatBookArr(bookmarksQuery?.data?.data?.faves || bookmarksQuery?.data?.data);

	return (
		<Suspense
			total={bookmarkArr.length}
			noResultComponent={<BookmarkNoResult />}>
			<List sx={sxList}>
				{bookmarkArr.map(( bookmark ) => (
					<BookmarkItem
						key={bookmark.id}
						item={bookmark}
						onClose={onClose}
					/>
				))}
			</List>
		</Suspense>
	);
};

export default BookmarkList;

const BookmarkNoResult = () => {
	return (
		<Box sx={sxNoResultBox}>
			<EmptyPlaceholder />
			<p>You didn&apos;t save anything yet</p>
		</Box>
	);
};

function creatBookArr( bookmarks = {} ) {
	console.log(
		'bookmarks',
		bookmarks,
	);
	let itemArr = [];
	const objEntries = Object.entries(bookmarks);
	objEntries.map(( item ) => {
		const children = item[1].map(( child ) => {
			const newChild = { ...child, type: item[0] };
			return newChild;
		});
		itemArr = [...itemArr, ...children];
	});

	return itemArr;
}

const sxNoResultBox = () => {
	return {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	};
};

const sxList = () => {
	return { height: 'calc(100% - 90px)', overflow: 'hidden auto' };
};
