import React from 'react';
import styles from './CreateBulletin.module.scss';

import Grid from '@mui/material/Grid';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';

import { useFormContext } from 'react-hook-form';
import { useStepperContext } from 'context';

const PrevButton = ({ loading }) => {
  const { onPrevStep } = useStepperContext();

  return (
    <Button
      className={styles.backButton}
      onClick={onPrevStep}
      display="secondary"
      disabled={loading}
    >
      Back
    </Button>
  );
};

const Chip = ({ label }) => (
  <Grid item>
    <div className={styles.review__chip}>
      <p className={styles.review__chip__text}>{label}</p>
    </div>
  </Grid>
);

const ReviewStep = ({ loading }) => {
  const { getValues } = useFormContext();
  const values = getValues();

  const { recipientsType, recipients } = values;

  return (
    <div className={styles.review}>
      <h3 className={styles.review__title}>{values.title}</h3>
      <h4 className={styles.review__label}>Recipients:</h4>
      <Grid
        classes={{ root: styles.review__recipients }}
        spacing="8px"
        container
      >
        {recipientsType === 'ALL' ? (
          <Chip label="All" />
        ) : (
          recipients.map((item) => (
            <Chip
              key={item.id}
              label={
                recipientsType === 'INMATES'
                  ? `${item.firstName} ${item.lastName}`
                  : item.name
              }
            />
          ))
        )}
      </Grid>
      <h4 className={styles.review__label}>Message</h4>
      <p className={styles.review__text}>{values.text}</p>
      <Grid
        classes={{ root: styles.actions }}
        direction="row-reverse"
        justifyContent="space-between"
        wrap="nowrap"
        container
      >
        <Grid
          justifyContent="flex-end"
          wrap="nowrap"
          columnSpacing="16px"
          container
        >
          <PrevButton loading={loading} />
          <Button display="primary" type="submit" disabled={loading}>
            Send
          </Button>
        </Grid>
        <FormLoader loading={loading} />
      </Grid>
    </div>
  );
};

export default ReviewStep;
