import { useQueries } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useAutocompleteSearch( searchTerm ) {
	const api = useGraphql();

	function getInmatesSuggestions() {
		return api.controller.search.inmates({
			term: searchTerm,
			limit: 100,
		});
	}

	function getFnfsSuggestions() {
		return api.controller.search.fnfs({
			term: searchTerm,
			limit: 100,
		});
	}

	const queries = useQueries({
		queries: [
			{
				queryKey: [QUERY_KEYS.SEARCH_INMATE, searchTerm, 'autocomplete'],
				queryFn: getInmatesSuggestions,
			},
			{
				queryKey: [QUERY_KEYS.SEARCH_FNF, searchTerm, 'autocomplete'],
				queryFn: getFnfsSuggestions,
			},
		],
	});

	const isLoading = queries.some(( query ) => query.isLoading);
	const isError = queries.some(( query ) => query.isError);
	const data = [...queries[0].data?.items?.inmate || [], ...queries[1].data?.items?.fnf || []];

	return { data, isLoading, isError };

}
