import React, { useRef, useState } from 'react';
import './VideoPlayer.scss';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const useVideoPlayer = (video) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [seekValue, setSeekValue] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volumenLevel, setVolumenLevel] = useState(100);

  const formatTimes = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  };

  const togglePlay = () => {
    !isPlaying ? video.current.play() : video.current.pause();
    setIsPlaying(!isPlaying);
  };

  const toggleFullscreen = () => {
    if (video.current) {
      video.current.requestFullscreen();
    }
  };

  const toggleMute = () => {
    !isMuted ? (video.current.muted = true) : (video.current.muted = false);
    setIsMuted(!isMuted);
  };

  const handleTimeDuration = () => {
    setDuration(video.current.duration);
  };

  const handleOnTimeUpdate = () => {
    setCurrentTime(video.current.currentTime);

    const progressPosition =
      (video.current.currentTime / video.current.duration) * 100;
    if (progressPosition === 0) {
      setSeekValue(0);
      setIsPlaying(true);
    }
    if (progressPosition === 100) {
      setSeekValue(0);
      setIsPlaying(false);
    } else setSeekValue(progressPosition);
  };

  const handleVideoProgress = (e) => {
    const seekto = video.current.duration * (+e.target.value / 100);
    video.current.currentTime = seekto;
    setSeekValue(e.target.value);
  };

  const handleVolumeLevel = (e) => {
    const volumen = +e.target.value / 100;
    video.current.volume = volumen;
    setVolumenLevel(e.target.value);
    video.current.volume ? setIsMuted(false) : setIsMuted(true);
  };

  return {
    isPlaying,
    isMuted,
    seekValue,
    togglePlay,
    toggleMute,
    toggleFullscreen,
    handleOnTimeUpdate,
    handleTimeDuration,
    handleVideoProgress,
    currentTime,
    duration,
    volumenLevel,
    formatTimes,
    handleVolumeLevel,
  };
};

function VideoPlayer({
  src,
  text,
  owner_info,
  mediaType,
  recipient_info,
  hasCaption = false,
  ...props
}) {
  const video = useRef(null);

  const {
    isPlaying,
    isMuted,
    seekValue,
    togglePlay,
    handleOnTimeUpdate,
    handleTimeDuration,
    handleVideoProgress,
    toggleMute,
    toggleFullscreen,
    formatTimes,
    currentTime,
    duration,
    volumenLevel,
    handleVolumeLevel,
  } = useVideoPlayer(video);

  return (
    <div
      className="videoplayer"
      style={{ borderRadius: hasCaption ? '8px 8px 0 0' : '8px' }}
    >
      <video
        className="videoplayer__video_element"
        src={src}
        ref={video}
        title={text || `${mediaType} from ${owner_info} to ${recipient_info}`}
        alt={text || `${mediaType} from ${owner_info} to ${recipient_info}`}
        onLoadedMetadata={handleTimeDuration}
        onTimeUpdate={handleOnTimeUpdate}
        autoPlay={true}
      />
      <div className="videoplayer__controls">
        <div className="videoplayer__controls__play_btn">
          {
            <button id="play-pause-button" onClick={togglePlay}>
              {!isPlaying ? (
                <PlayArrowIcon
                  sx={{
                    color: '#ffff',
                    background: 'transparent',
                    width: '100%',
                    fontSize: '1.5rem',
                  }}
                />
              ) : (
                <PauseIcon
                  sx={{
                    color: '#ffff',
                    background: 'transparent',
                    width: '100%',
                    fontSize: '1.5rem',
                  }}
                />
              )}
            </button>
          }
        </div>
        <div className="videoplayer__controls__progress">
          <div className="videoplayer__controls__progress__bar">
            <input
              id="video-progress-bar"
              type="range"
              min="0"
              max="100%"
              step="1"
              value={seekValue}
              onChange={handleVideoProgress}
              style={{ backgroundSize: `${Math.floor(seekValue)}%` }}
            />
          </div>
        </div>
        <div className="videoplayer__controls__time">
          <span className="videoplayer__controls__time__current">
            {formatTimes(Math.floor(currentTime))}
          </span>
          <span className="videoplayer__controls__time__duration">
            {`/${formatTimes(Math.floor(duration))}`}
          </span>
        </div>
        <div className="videoplayer__controls__volume_btn">
          <button
            id="volume-button"
            className="mute-control-icon"
            onClick={toggleMute}
          >
            {!isMuted ? (
              <VolumeUpIcon onClick={toggleMute} />
            ) : (
              <VolumeOffIcon onClick={toggleMute} />
            )}
          </button>
        </div>
        <div className="videoplayer__controls__volume_control">
          <div className="videoplayer__controls__volume_control__bar">
            <input
              id="video-volume-bar"
              type="range"
              min="0"
              max="100"
              step="0.01"
              value={volumenLevel}
              onChange={handleVolumeLevel}
              style={{ backgroundSize: `${Math.floor(volumenLevel)}%` }}
            />
          </div>
        </div>
        <div className="videoplayer__controls__fullscreen">
          <button id="fullscreen-button">
            <FullscreenIcon onClick={toggleFullscreen} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
