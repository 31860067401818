import { useMutation } from '@tanstack/react-query';
import { useGraphql } from 'context';

export default function useBlockImageMessage( isBlocked ) {
	const api = useGraphql();

	function blockImageMutation( payload ) {
		return api.controller.channel.blockImg(payload);
	}

	function unBlockImageMutation( payload ) {
		return api.controller.channel.unblockImg(payload);
	}

	return useMutation({ mutationFn: isBlocked ? unBlockImageMutation : blockImageMutation });

}