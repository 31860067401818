import React, { useEffect, useState } from 'react';
import styles from './StaffDetails.module.scss';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Section from './Section';
import EditStaffProfile from '../edit-staff-profile';
import EditStaffRoles from '../edit-staff-roles';
import EditStaffFacilities from '../edit-staff-facilities';

import BackIcon from '@mui/icons-material/ArrowBack';
import { Bookmark } from 'assets/icons';

import { useHistory, useParams } from 'react-router';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DOCUserInfoQuery } from 'kiwi-sdk';
import { useToggler } from 'hooks';
import { useGraphql } from 'context';
import { useAdminSettingsContext } from '../context';
import { useAuthContext } from 'modules/auth/context';
import { Skeleton } from 'shared/skeleton';
import SettingsAutocomplete from '../settings-autocomplete';

const EditStaffRolesButton = ({ showEdit, toggleEdit, id }) => {
  return (
    <>
      <MuiButton
        classes={{ root: styles.button }}
        onClick={toggleEdit}
        style={{ fontSize: '14px' }}
      >
        Change Role
      </MuiButton>
      <EditStaffRoles id={id} showEdit={showEdit} toggleEdit={toggleEdit} />
    </>
  );
};

const EditStaffFacilitiesButton = ({ facility, staffInfo, user, id }) => {
  return (
    <EditStaffFacilities
      facilities={facility}
      selectedStaff={staffInfo}
      disableEdit={user.id == id}
    />
  );
};

const StaffDetails = ({ id }) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const { setSelectedStaff, selectedStaff } = useAdminSettingsContext();
  const [isLoading, setIsloading] = useState(true);
  const [staffInfo, setStaffInfo] = useState({});
  const [showEdit, toggleEdit] = useToggler();
  const { hasPermission } = useAdminSettingsContext();

  const {
    firstName,
    lastName,
    email,
    createdAt,
    security = {},
  } = staffInfo || {};
  const { role = [], facility = [] } = security;
  const api = useGraphql();

  function loadStaff(id) {
    return api.send(DOCUserInfoQuery({ id }));
  }

  function formatDate(date) {
    if (date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    }
    return '';
  }

  useEffect(() => {
    setStaffInfo(selectedStaff);
  }, [selectedStaff]);

  useEffect(() => {
    const sub = from(loadStaff(id))
      .pipe(pluck('data'))
      .subscribe((data) => {
        setSelectedStaff(data);
        setStaffInfo(data);
        setIsloading(false);
      });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item p={2} mt={3} width={'95%'}>
        <SettingsAutocomplete />
      </Grid>
      <Grid item p={2} width={'95%'}>
        <div className={styles.info_wrapper}>
          {isLoading ? (
            <Skeleton amount={3} />
          ) : (
            <>
              <Grid
                classes={{ root: styles.actions }}
                justifyContent="space-between"
                alignItems="center"
                container
              >
                <Button
                  classes={{
                    root: styles.actions__item,
                  }}
                  onClick={history.goBack}
                  startIcon={<BackIcon />}
                >
                  Back
                </Button>
                <Grid item justifyContent="space-between" alignItems="center">
                  <EditStaffProfile
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                  />
                </Grid>
              </Grid>
              <h6 className={styles.name}>{`${firstName} ${lastName}`}</h6>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <div className={styles.info}>
                  <p className={styles.info__item}>{email}</p>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ ml: '10px', mr: '10px' }}
                  />

                  <p className={styles.info__item}>
                    {`User since ${formatDate(createdAt)}`}
                  </p>
                </div>
                <span className={styles.info__doc}>Doc Officer</span>
              </Grid>
              {
                <Section
                  title="User roles"
                  action={
                    hasPermission.editStaffRoles ? (
                      <EditStaffRolesButton
                        toggleEdit={toggleEdit}
                        showEdit={showEdit}
                        id={id}
                      />
                    ) : (
                      () => {}
                    )
                  }
                  info={!role.length ? 'No assigned roles' : role}
                />
              }
              <Section
                title="Facilities access"
                action={
                  hasPermission.editStaffFacilities ? (
                    <EditStaffFacilitiesButton
                      facility={facility}
                      staffInfo={staffInfo}
                      user={user}
                      id={id}
                    />
                  ) : (
                    () => {}
                  )
                }
                info={facility}
              />
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default () => {
  const { staffId } = useParams();
  const id = decodeURIComponent(staffId);
  return (
    <>
      <StaffDetails id={id} key={id} />
    </>
  );
};
