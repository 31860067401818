import Box from '@mui/material/Box';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { useChatContext } from 'modules/users-chats/context';
import { useHighlightPart } from 'modules/users-chats/hooks';

export default function TextMessageContent( { text } ) {
	const { searchTerm } = useChatContext();

	const matched = match(text, searchTerm, { insideWords: true });
	const parts = parse(text, matched);
	const highlightPart = useHighlightPart({ parts, searchTerm });

	return (
		<Box mr={1.5} sx={{ maxWidth: 435, wordWrap: 'break-word' }}>
			{parts.map(( part, index ) => (
				<span
					key={index}
					style={
						part.highlight || highlightPart(part, index)
							? {
								backgroundColor: '#f1d28d',
								color: '#101010',
							}
							: undefined
					}
				>
          {part.text}
        </span>))}
		</Box>
	);
}
