import { Box } from '@mui/material';
import Avatar from 'shared/avatar';

export default function CardAvatar( { id, firstName, lastName, src = '', isBlocked, isActive } ) {
	const { sxAvatarContainer, sxBackgroundBox } = useAvatarStyles();
	return (
		<Box sx={sxBackgroundBox}>
			<Box sx={sxAvatarContainer}>
				<Avatar
					id={id}
					firstName={firstName}
					lastName={lastName}
					src={src}
					sxAvatar={{ width: 70, height: 70 }}
					isBlocked={isBlocked}
					isActive={isActive}
				/>
			</Box>
		</Box>
	);
}

function useAvatarStyles() {
	return {
		sxBackgroundBox: {
			backgroundColor: 'customPalette.grey5',
			borderRadius: '16px 16px 0 0',
			height: 70,
		},
		sxAvatarContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'customPalette.white',
			borderRadius: '50%',
			height: 90,
			position: 'absolute',
			marginTop: 2,
			marginLeft: 8.5,
			width: 90,
		},
	};
};
