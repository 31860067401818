import React from 'react';
import cs from 'classnames';
import styles from './TextInput.module.scss';

import TextField from '@mui/material/TextField';

import { useFormContext } from 'react-hook-form';

const TextInput = ({
  className,
  name,
  InputProps = {},
  inputProps = {},
  nativeOutline,
  ...props
}) => {
  const { register } = useFormContext();

  const { onChange, ref, ...input } = register(name);

  return (
    <TextField
      classes={{
        root: cs(
          styles.field,
          !nativeOutline && styles['field--disableOutline'],
          className
        ),
      }}
      name={name}
      inputRef={ref}
      onChange={onChange}
      inputProps={{
        ...inputProps,
        ...input,
      }}
      InputProps={{
        classes: {
          root: styles.field__input__wrapper,
          focused: styles['field--focused'],
          notchedOutline: styles.field__outline,
          input: styles.field__input,
        },
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default TextInput;
