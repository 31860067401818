import React from 'react';
import styles from './ResultPageElements.module.scss';
import cs from 'classnames';

import format from 'date-fns/format';

import getResponseComponent from '../../utils/functions/getResponseComponent';
import { useSurveyResultsContext } from 'modules/surveys/context';

const ResultsList = ({ printableRef }) => {
  const { surveyData, currentSurveyResponse } = useSurveyResultsContext();
  const { isSurveySummary, currentSurveyResponseOwner, isSurveyAnonymous } =
    useSurveyResultsContext();
  const responseOwnerName =
    currentSurveyResponseOwner?.firstName +
    ' ' +
    currentSurveyResponseOwner?.lastName;
  const { completed, sent, questions, id, name, createdAt } = surveyData;
  const surveyCreationData = format(new Date(createdAt), 'LLL d, y, h:mm a');

  const QuestionInfo = ({ questionTitle, questionNumber }) => {
    return (
      <div className={cs(styles.question__item)}>
        <div className={styles.question__item__number}>
          <p>{questionNumber}</p>
        </div>

        <div className={styles.question__item__title}>
          <p>{questionTitle}</p>
        </div>
        <div className={styles.question__item__stats}>
          <span>{`${completed} out of ${sent} people answered this question`}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={cs(styles.map_list)} ref={printableRef}>
      <div className={styles.survey_title}>
        <h4>Survey Name: {name}</h4>
        {isSurveySummary ? (
          <>
            <h4>Survey Summary</h4>
            <h4>Created at: {surveyCreationData}</h4>
          </>
        ) : isSurveyAnonymous ? (
          <>
            <h4>Anonymous Response</h4>
          </>
        ) : (
          <>
            <h4>Inmate Name: {responseOwnerName} </h4>
            <h4>
              Inmate Id: {currentSurveyResponseOwner.id.replace('I^', '')}
            </h4>
          </>
        )}
      </div>
      {questions.map((question, index) => (
        <div key={id + index}>
          <div className={styles.list_item}>
            <QuestionInfo
              questionTitle={question.question}
              questionNumber={index + 1}
            />
            <div className={styles.responses_item}>
              {getResponseComponent(currentSurveyResponse[index])}
            </div>
          </div>
          <div className={styles['page-break']} />
        </div>
      ))}
    </div>
  );
};

export default ResultsList;
