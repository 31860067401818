import { Box, Typography } from '@mui/material';

import EmptyImage from './EmptyImage';

const NoResult = ( props ) => {
	const { text, children, imageSize = 'medium', textVariant = 'medium', sxImage, typographyProps } = props;
	const { sxBoxWrapper } = useNoResultStyles();
	return (
		<Box sx={sxBoxWrapper}>
			<EmptyImage sx={{ fontSize: IMAGE_SIZE[imageSize], ...sxImage }} />
			{children ||
				<Typography variant={TEXT_VARIANT[textVariant]}{...typographyProps}>
					{text}
				</Typography>}
		</Box>
	);
};

export default NoResult;

function useNoResultStyles() {
	return {
		sxBoxWrapper: {
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	};
}

const IMAGE_SIZE = {
	small: 200,
	medium: 300,
	large: 400,
};

const TEXT_VARIANT = {
	small: 'p',
	medium: 'h6',
	large: 'h5',
};


