import React from 'react';
import styles from './FilesList.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Modal, { ModalTitle } from 'shared/modal';
import { useDocumentsContext } from '../context';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';
import { useToggler } from 'hooks';
import { toast } from 'react-toastify';

function truncateFileName( fullFileName, maxFileNameLength ) {
	if (fullFileName) {
		return fullFileName.length > maxFileNameLength
			? `${fullFileName.substr(0, maxFileNameLength - 1)}...`
			: fullFileName;
	}
	return '';
}

const formLoaderStyle = {
	display: 'flex',
	justifyContent: 'center',
};

const DeleteFileModal = ( {
														open,
														handleClose,
														documentId,
														documentDisplayName,
														facilityId,
													} ) => {
	const { deleteDocument, refreshDocuments } = useDocumentsContext();
	const [loading, toggleLoading] = useToggler(false);

	const handleDeleteDocument = () => {
		toggleLoading(true);
		deleteDocument(documentId, facilityId).subscribe({
			next: () => {
				toast.success(`'${documentDisplayName}' was deleted`);
				toggleLoading(false);
				handleClose(false);
				refreshDocuments();
			},
			error: ( err ) => {
				toast.error('And error occured deleting your file');
				toggleLoading(false);
				handleClose(false);
				console.error(err);
			},
		});
	};

	const handleModalClose = () => {
		if (!loading) {
			handleClose();
		}
	};

	return (
		<Modal className={styles.deleteModal} open={open} onClose={handleModalClose}>
			<div className={styles.deleteModal__modalHeader}>
				<ReportProblemIcon
					fontSize='large'
					color='warning'
				/>
				<ModalTitle
					text={`Delete "${truncateFileName(documentDisplayName, 24)}"?`}
					className={styles.deleteModal__modalTitle}
				/>
			</div>

			<div>
				<p>Once deleted the file cannot be restored, Are you sure you wish to procced?</p>
			</div>
			<div className={styles.deleteModal__modalButtons}>

				{loading ? <FormLoader loading={loading} text='Deleting file...' /> :
					<>
						<Button
							onClick={handleModalClose}
							display='secondary'
							disabled={loading}
						>
							<ClearIcon />
							Cancel
						</Button>

						<Button
							onClick={handleDeleteDocument}
							disabled={loading}
							className={styles.deleteModal__deleteButton}
							display='primary'
						>
							<DeleteIcon />
							Delete
						</Button>
					</>
				}

			</div>

		</Modal>
	);
};

export default DeleteFileModal;