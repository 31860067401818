import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Lock = ({ color, width, height, ...props }) => {
  return (
    <SvgIcon
      width={width || 14}
      height={height || 19}
      viewBox="0 0 14 19"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5V8.17078C0.83498 8.5826 0 9.6935 0 11V16C0 17.6569 1.34315 19 3 19H11C12.6569 19 14 17.6569 14 16V11C14 9.6935 13.165 8.5826 12 8.17078V5C12 2.23858 9.76142 0 7 0C4.23858 0 2 2.23858 2 5ZM4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5V8H4V5ZM3 10C2.44772 10 2 10.4477 2 11V16C2 16.5523 2.44772 17 3 17H11C11.5523 17 12 16.5523 12 16V11C12 10.4477 11.5523 10 11 10H3Z"
        fill={color || 'currentColor'}
      />
    </SvgIcon>
  );
};

export default Lock;
