import { Card, CardMedia, Box } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { useMediaSrc } from 'hooks';

export default function ImageThumbnail({ location = '', isBlocked = false }) {
  const { mediaSrc } = useMediaSrc(location);

  return (
    <Box sx={boxStyle}>
      {isBlocked ? <WarningRoundedIcon sx={warningIconStyle} /> : null}
      <Card sx={cardStyle} elevation={0}>
        <CardMedia
          component="img"
          image={mediaSrc}
          alt="Image message replied"
        />
      </Card>
    </Box>
  );
}

const cardStyle = {
  width: 48,
  height: 48,
};

const boxStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 1,
};

const warningIconStyle = {
  color: 'customPalette.error',
  marginRight: 1,
};
