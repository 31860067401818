import React, { useRef } from 'react';
import styles from './StaffList.module.scss';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreIcon from '@mui/icons-material/MoreVert';
import EditStaffRoles from '../edit-staff-roles';

import { useHistory, generatePath } from 'react-router';

import { useToggler } from 'hooks';
import { useRemoveRoles } from '../hooks';

import routes from 'routing/routes';
import { useAdminSettingsContext } from '../context';

const StaffItem = ( staffInfo ) => {
	const { info, id = '' } = staffInfo;

	const [name, email] = info.split(',');
	const history = useHistory();
	const button = useRef(null);

	const [menu, toggleMenu] = useToggler();
	const [showEdit, toggleEdit] = useToggler(false);
	const { handleRemoveClick } = useRemoveRoles(id);
	const { hasPermission } = useAdminSettingsContext();

	return (
		<>
			<Grid
				classes={{ root: styles.staff }}
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				container
				sx={{ borderRadius: '16px' }}
				onClick={hasPermission.editStaffRoles ? toggleEdit : () => {
				}}
			>
				<Grid item xs={6}>
					<span className={styles.staff__name}>{name}</span>
				</Grid>
				<Grid item xs={4}>
					<p className={styles.staff__email}>{email}</p>
				</Grid>
				<Grid
					item
					xs={2}
					container
					direction='row'
					justifyContent='flex-end'
					alignItems='center'
				>
					<IconButton
						ref={button}
						style={{
							backgroundColor: 'transparent',
						}}
						sx={{
							ml: '100px',
							color: ' #101010',
						}}
						onClick={( event ) => {
							event.stopPropagation();
							toggleMenu();
						}}
					>
						<MoreIcon
							sx={{
								'&:hover': {
									border: '1px solid #101010',
									borderRadius: '4px',
								},
							}}
						/>
					</IconButton>
					<Menu
						classes={{
							paper: styles.form__menu__wrapper,
							list: styles.form__menu,
						}}
						anchorEl={button.current}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={menu}
						onClose={( event ) => {
							event.stopPropagation();
							toggleMenu();
						}}
					>
						<MenuItem
							classes={{ root: styles.form__menu__item }}
							onClick={( event ) => {
								event.stopPropagation();
								history.push(
									`${generatePath(routes.singleStaff, { staffId: id })}`,
								);
							}}
						>
							View Profile
						</MenuItem>
						<MenuItem
							classes={{ root: styles.form__menu__item }}
							onClick={( event ) => {
								event.stopPropagation();
								toggleMenu();
								toggleEdit();
							}}
						>
							<span>Change Role</span>
						</MenuItem>
						<MenuItem
							classes={{ root: styles.form__menu__item }}
							onClick={( event ) => {
								event.stopPropagation();
								handleRemoveClick();
								toggleMenu();
							}}
						>
							Remove
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
			{showEdit ? <EditStaffRoles id={id} showEdit={showEdit} toggleEdit={toggleEdit} /> : null}
		</>
	);
};

export default StaffItem;
