import { Box, Grid } from '@mui/material';

import ActionButton from 'shared/action-button';
import FormLoader from 'shared/form-loader';

import { If } from 'shared/utilities';
import { SelectInput } from 'shared/inputs';

export default function AvatarBlockForm(props) {
  const { onCloseAvatarModal, blockMutation } = props;

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item width={180}>
        <SelectInput
          name="reason"
          placeholder="Choose violation"
          options={BLOCKING_REASONS}
          variant="outlined"
          outlined
          fullWidth
          disabled={blockMutation.isLoading}
        />
      </Grid>
      <Grid>
        <If
          condition={blockMutation.isLoading}
          otherwise={
            <>
              <ActionButton
                text="Close"
                variant="ligth"
                onClick={() => {
                  onCloseAvatarModal(false);
                }}
              />
              <ActionButton
                text="Confirm"
                sx={{ marginLeft: 1.2 }}
                type="submit"
              />
            </>
          }
        >
          <BlockLoading loading={blockMutation.isLoading} />
        </If>
      </Grid>
    </Grid>
  );
}

function BlockLoading({ loading }) {
  return (
    <Box
      sx={{
        minWidth: 100,
        height: 44,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FormLoader loading={loading} />
    </Box>
  );
}

const BLOCKING_REASONS = {
  DRUGS: 'Drugs',
  GANGS: 'Gangs',
  THREATS: 'Threats',
  OTHER: 'Other',
};
