import { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

export default function useWavesurfer(src, playerRef) {
  const [playing, setPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const wavesurferRef = useRef(null);

  useEffect(() => {
    wavesurferRef.current = WaveSurfer.create({
      container: playerRef.current,
      waveColor: '#9191A1',
      progressColor: '#03A882',
      height: 28,
      barMinHeight: 2,
      barWidth: 2,
      barGap: 5,
      barRadius: 2,
      cursorWidth: 1,
      cursorColor: '#ddd5e9',
      hideScrollbar: true,
      normalize: true,
      responsive: true,
      backend: 'MediaElement',
    });

    src && wavesurferRef.current.load(src);

    wavesurferRef.current.on('ready', () => {
      setDuration(Math.floor(wavesurferRef.current.getDuration()));
    });

    wavesurferRef.current.on('finish', () => {
      setPlaying(false);
    });

    wavesurferRef.current.on('audioprocess', () => {
      setCurrentTime(Math.floor(wavesurferRef.current.getCurrentTime()));
    });

    return () => {
      wavesurferRef.current.destroy();
    };
  }, [src]);

  const handlePlayPause = () => {
    if (playing) {
      wavesurferRef.current.pause();
      setPlaying(false);
    } else {
      wavesurferRef.current.play();
      setPlaying(true);
    }
  };

  function formatTime(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  }

  return {
    handlePlayPause,
    formatTime,
    playing,
    position,
    duration,
    currentTime,
  };
}
