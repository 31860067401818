import styles from './styles.module.scss';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Suspense from 'shared/suspense-list';
import VerticalScroller from 'shared/vertical-scroller';
import useChannelMessages from '../../../hooks/useChannelMessages';
import ChatMessage from 'shared/chat-message';
import ErrorBoundary from 'shared/error-boundary';

import { useIsFetching } from '@tanstack/react-query';
import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';
import { ChatLoader } from 'shared/loaders';
import { CustomMap, If } from 'shared/utilities';
import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';
import { useChannelLastMessage } from 'hooks';

export default function AnalyticsModalChat() {
	const { messageAndChannelSelected, listQueryPayload } = useAnalyticsModalContext();
	const {
		data = [],
		isInitialLoading,
		hasForwardMore,
		hasBackwardMore,
		loadNextForwardPage,
		loadNextBackwardPage,
		loadingForward,
		loadingBackward,
	} = useChannelMessages(messageAndChannelSelected);

	// See Comment 1
	const isFetchingElementList = useIsFetching({ queryKey: [QUERY_KEYS.ANALYTICS_LIST_INFO, listQueryPayload] });
	const modalElementListData = queryClient.getQueryData([QUERY_KEYS.ANALYTICS_LIST_INFO, listQueryPayload]);
	const { data: lastChannelMessageData } = useChannelLastMessage(messageAndChannelSelected?.channelId);

	return (
		<ErrorBoundary level='section' textVariant='small' imageSize='small'>
			<Suspense
				total={data?.length && !!modalElementListData}
				loading={isFetchingElementList || isInitialLoading}
				fallback={<ChatLoader />}
				textNoResults='No messages found'>
				<ScrollerContainer
					className={styles.scroller_container}
					sx={{
						flexDirection: messageAndChannelSelected?.messageKey === 0
							? 'column-reverse'
							: 'column',
					}}>
					<VerticalScroller
						loadNext={loadNextForwardPage}
						loadPrev={loadNextBackwardPage}
						hasNext={hasForwardMore}
						hasPrev={hasBackwardMore}
						loadingNext={loadingForward}
						loadingPrev={loadingBackward}
						reverse={true}>
						<CustomMap
							list={data}
							keyExtractor={( item ) => item?.clientKey}
							containerSx={Sxs.CustomMapListContainer}
							renderItem={ChatMessage}
							listAsRenderItemProp={true}
							renderItemProps={{ ...messageAndChannelSelected }}
						/>
					</VerticalScroller>
				</ScrollerContainer>
			</Suspense>
		</ErrorBoundary>
	);
}

const ScrollerContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	height: 615,
});

const Sxs = {
	CustomMapListContainer: {
		display: 'flex',
		flex: 1,
		padding: 1,
		flexDirection: 'column-reverse',
		overflow: 'hidden auto',
	},
};

/* Comments
 	* #1: The next line is to get the status of the query that gets the flagged messages. In this way, the <EmptyResults/> component will not be shown as the initial screen in the chat component. The chat loader will be displayed instead.
*/

