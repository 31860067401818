import AvatarBadge from './AvatarBadge';
import AvatarContent from './AvatarContent';
import AvatarModal from './avatar-modal';

import { useToggler } from 'hooks';
import { getUserType } from 'utils';
import { AvatarModalProvider } from 'shared/avatar/avatar-context/AvatarContext';
import { If } from 'shared/utilities';

export default function Avatar(props) {
  const {
    id = '',
    inChatStyle = false,
    enableModal = false,
    isBlocked = false,
    isActive = true,
  } = props;
  const [openAvatarModal, toggleOpenAvatarModal] = useToggler();
  const userType = getUserType(id);
  const userStatus = getStatus(isBlocked, isActive);

  const enabledAvatarModal = Boolean(
    userType === 'FNF' && enableModal && openAvatarModal
  );

  return (
    <AvatarBadge
      showContent={isBlocked && !inChatStyle}
      avatarStatus={userStatus}
    >
      <AvatarContent
        inChatStyle={inChatStyle}
        user={userType}
        toggleModal={toggleOpenAvatarModal}
        userStatus={userStatus}
        {...props}
      />
      <If condition={enabledAvatarModal}>
        <AvatarModalProvider userId={id}>
          <AvatarModal
            open={openAvatarModal}
            toggleModal={toggleOpenAvatarModal}
            {...props}
          />
        </AvatarModalProvider>
      </If>
    </AvatarBadge>
  );
}

function getStatus(isBlocked, isActive) {
  if (isBlocked) {
    return 'blocked';
  }
  if (!isActive) {
    return 'inactive';
  }
  return 'active';
}
