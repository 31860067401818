import { useState } from 'react';

import { Paper, Popover } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import PopperList from './PopperList';

export default function ShowMore( { sx } ) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = ( event ) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<MoreHorizIcon onClick={handleClick} sx={sx} />
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<PopperList closePopover={handleClose} />
			</Popover>
		</>
	);
};

const sxPaper = () => {
	return {
		position: 'absolute',
		width: 106,
		py: 0.5,
		px: 0.5,
		height: 110,
		top: 10,
		right: 0,
		left: -100,
		zIndex: 1,
	};
};




