import React from 'react';
import styles from '../ResultPageElements.module.scss';

import HorizontalBarResult from './results-display-type/HorizontalBarResult';
import OptionAnswerType from './answer-display-type/OptionAnswerType';
import getPercentOfTotalResponses from '../../../utils/functions/getPercentOfTotalResponses';

function OptionResults({ options, answers, isSummary, type }) {
  const answerInfo = {
    count: 0,
    percentOfTotal: 0,
  };

  return (
    <>
      {options.map((option) => {
        answerInfo.percentOfTotal = 0;
        switch (true) {
          case isSummary:
            answerInfo.count =
              answers?.find((answer) => answer.choice === option)?.count ?? 0;
            answerInfo.percentOfTotal = getPercentOfTotalResponses(
              answerInfo.count
            );
            break;

          case !isSummary && type === 'MULTIPLE_SELECT':
            if (answers.some((answer) => answer === option)) {
              answerInfo.percentOfTotal = 100;
            }
            break;

          case !isSummary && answers === option:
            answerInfo.percentOfTotal = 100;
            break;
        }
        return (
          <div className={styles.responses_item__option} key={option}>
            <OptionAnswerType
              option={option}
              numberOfResponses={answerInfo.count}
              percentOfTotal={answerInfo.percentOfTotal}
              displayAggregate={isSummary}
            />
            <HorizontalBarResult value={answerInfo.percentOfTotal} />
          </div>
        );
      })}
    </>
  );
}

export default OptionResults;
