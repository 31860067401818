import { Typography, ListItemButton } from '@mui/material';

export default function PopperListItem({ Icon, label, ...props }) {
  const { sxListItemButton, sxIcon } = usePopperListItemStyles();
  return (
    <ListItemButton key={label} sx={sxListItemButton} {...props}>
      <Icon sx={sxIcon} />
      <Typography variant='messageDate' component='span'>
        {label}
      </Typography>
    </ListItemButton>
  );
};

function usePopperListItemStyles() {
  return {
    sxListItemButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingY: 1,
      paddingX: 0.5,
      borderRadius: 2,
      '&:hover': {
        bgcolor: 'customPalette.grey6',
      },
    },
    sxIcon: {
      width: 16,
      height: 16,
      pr: 1,
    },
  };
}