import { useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';
import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';

import { QUERY_KEYS } from 'utils';
import { getFlattedList } from '../utils/getFlattedList';

export default function useChannelMessages(messageKey) {
  const api = useGraphql();
  const { chatSelected = '' } = useChatsTabContext();
  const currentKey = useMemo(
    () => messageKey ?? new Date().getTime(),
    [messageKey]
  );

  const backwardMessagesQuery = useInfiniteQuery({
    queryKey: [
      QUERY_KEYS.CHANNEL_MESSAGES,
      chatSelected,
      currentKey,
      'backward',
    ],
    queryFn: getChannelBackwardMessages,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });
  const forwardMessagesQuery = useInfiniteQuery({
    queryKey: [
      QUERY_KEYS.CHANNEL_MESSAGES,
      chatSelected,
      currentKey,
      'forward',
    ],
    queryFn: getChannelForwardMessages,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  const backwardMessagesItems = getFlattedList(backwardMessagesQuery.data);
  const forwardMessagesItems = getFlattedList(forwardMessagesQuery.data);

  function getChannelBackwardMessages({ pageParam = null }) {
    return api.controller.channel.messageBackwardContext({
      channelId: chatSelected,
      msgId: `${parseInt(currentKey) + 1}`,
      messageOnly: false,
      nextPage: pageParam,
      limit: 40,
    });
  }

  function getChannelForwardMessages({ pageParam = null }) {
    return api.controller.channel.messageForwardContext({
      channelId: chatSelected,
      msgId: `${parseInt(currentKey)}`,
      messageOnly: false,
      nextPage: pageParam,
      limit: 40,
    });
  }

  return {
    data: [...forwardMessagesItems, ...backwardMessagesItems].sort(
      function (a, b) {
        return parseInt(b.clientKey) - parseInt(a.clientKey);
      }
    ),
    loadingBackward: backwardMessagesQuery.isFetching,
    loadingForward: forwardMessagesQuery.isFetching,
    backwardErrorMessage: backwardMessagesItems.error,
    forwardErrorMessage: forwardMessagesQuery.error,
    isBackwardError: backwardMessagesQuery.isError,
    isForwardError: forwardMessagesQuery.isError,
    hasBackwardMore: backwardMessagesQuery.hasNextPage,
    hasForwardMore: forwardMessagesQuery.hasNextPage,
    loadNextBackwardPage: backwardMessagesQuery.fetchNextPage,
    loadNextForwardPage: forwardMessagesQuery.fetchNextPage,
  };
}
