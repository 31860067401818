import React from 'react';
import styles from './Modal.module.scss';
import cs from 'classnames';

import MuiModal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import { Close } from 'assets/icons';

import { makeStyles } from '@material-ui/core/styles';

const Modal = ( { className, children, sxModal, ...props } ) => {
	const classes = useStyles();
	return (
		<MuiModal
			onClick={( evt ) => {
				evt.stopPropagation();
			}}
			sx={{ ...sxModal }}
			className={classes.customDialog}
			{...props}
		>
			<div className={cs(styles.modal, classes.customDialog, className)}>{children}</div>
		</MuiModal>
	);
};

export const ModalTitle = ( { className, text, onClose } ) => (
	<div className={cs(styles.modal__header, className)}>
		<h4 className={styles.modal__title}>{text}</h4>
		{onClose && (
			<IconButton onClick={onClose}>
				<Close />
			</IconButton>
		)}
	</div>
);

export const ModalDescription = ( { className, text } ) => (
	<p className={cs(styles.modal__description, className)}>{text}</p>
);

export const ModalActions = ( { className, children } ) => (
	<div className={cs(styles.modal__actions, className)}>{children}</div>
);
export default Modal;

const useStyles = makeStyles(( theme ) => ({
	customDialog: {
		zIndex: 1300,
	},
}));

//TODO: Refactor this because use a lot of css
