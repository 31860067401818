import { useSurveyResultsContext } from '../../context';

export default function getPercentOfTotalResponses(optionCount) {
  const { surveyData } = useSurveyResultsContext();
  const percentOfTotal = Math.trunc((optionCount / surveyData.completed) * 100);
  if (percentOfTotal) {
    return percentOfTotal;
  }
  return 0;
}
