import React, { useMemo } from 'react';
import styles from './SurveysSelection.module.scss';

import Button from '@mui/material/Button';

import { Forward, Print, Download, Trash } from 'assets/icons';
import { useFormContext } from 'react-hook-form';

const SurveysSelection = () => {
  const { watch } = useFormContext();

  const value = watch();

  const selected = useMemo(() => {
    return Object.values(value).filter(Boolean).length;
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        {selected === 0 ? (
          <>
            <span>Selected: </span>0
          </>
        ) : (
          `${selected} Selected`
        )}
      </p>
      {selected > 0 && (
        <>
          <Button classes={{ root: styles.action }}>
            <Forward />
          </Button>
          <Button classes={{ root: styles.action }}>
            <Print />
          </Button>
          <Button classes={{ root: styles.action }}>
            <Download />
          </Button>
          <Button classes={{ root: styles.action }}>
            <Trash />
          </Button>
        </>
      )}
    </div>
  );
};

export default SurveysSelection;
