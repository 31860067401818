import { createContext, useState } from 'react';

import useUserProfileInfo from 'hooks/useUserProfileInfo';
import dateRangeBuilder from 'utils/dateRangeBuilder';

import { useParams } from 'react-router';

import { useProfileChats } from 'modules/users-chats/hooks';
import { useToggle } from 'react-use';
import useUserInvitations from 'shared/invitation-modal/useUserInvitations';

export const UserProfileContext = createContext();

function initialContextValue() {
  const params = useParams();
  const { userId } = params;
  const { data: userProfileInfo, isLoading: loadingUserProfileInfo } =
    useUserProfileInfo(userId);
  const {
    data: userProfileChats,
    isLoading: loadingUserProfileChats,
    setData: setUserProfileChats,
  } = useProfileChats();

  const [blockUserModal, toggleBlockUserModal] = useToggle(false);
  const [unBlockUserModal, toggleUnBlockUserModal] = useToggle(false);
  const [checkboxSelected, setCheckboxSelected] = useState('month');
  const [dateRangeFilter, setDateRangeFilter] = useState(
    dateRangeBuilder({ interval: checkboxSelected })
  );
  const [sentimentSelectedRangeFilter, setSentimentSelectedRangeFilter] =
    useState();
  const [openFlaggedMessagesModal, toggleFlaggedMessagesModal] =
    useToggle(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedAnalyticModalConfig, setSelectedAnalyticModalConfig] =
    useState({});
  const [openInvitationModal, toggleOpenInvitationModal] = useToggle(false);
  const [selectedInvitationStatus, setSelectedInvitationStatus] = useState(1);
  const userInvitationsQuery = useUserInvitations({
    userId,
    status: selectedInvitationStatus,
  });

  return {
    userId,
    invitationsQuery: userInvitationsQuery,
    selectedInvitationStatus,
    setSelectedInvitationStatus,
    checkboxSelected,
    openInvitationModal,
    toggleOpenInvitationModal,
    dateRangeFilter,
    userProfileInfo,
    blockUserModal,
    unBlockUserModal,
    loadingUserProfileInfo,
    loadingUserProfileChats,
    userProfileChats,
    sentimentSelectedRangeFilter,
    openFlaggedMessagesModal,
    setCheckboxSelected,
    setUserProfileChats,
    setDateRangeFilter,
    toggleBlockUserModal,
    toggleUnBlockUserModal,
    setSentimentSelectedRangeFilter,
    toggleFlaggedMessagesModal,
    selectedOption,
    setSelectedOption,
    selectedAnalyticModalConfig,
    setSelectedAnalyticModalConfig,
  };
}

export function UserProfileProvider({ children }) {
  const value = initialContextValue();
  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
}
