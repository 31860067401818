import { Box, Grid } from '@mui/material';

import Avatar from 'shared/avatar';
import HighlightText from 'shared/text-highlighter';
import routes from 'routing/routes';

import { toTitleCase } from 'utils';
import { generatePath, useHistory } from 'react-router';
import { formatPhoneNumber } from 'utils/';
import { useAvatarPhoto } from 'hooks';

export default function Option({ info }) {
  const {
    id,
    eid,
    lastName,
    firstName,
    photo,
    avatarModerationStatus,
    status,
    active,
  } = info;
  const history = useHistory();
  const { sxAvatarBox } = useOptionStyles();
  const { thumbnailSrc: avatarSrc } = useAvatarPhoto(photo);

  function onClickHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    history.push(generatePath(routes.user, { userId: id }));
  }

  return (
    <Grid
      component="li"
      alignItems="center"
      justifyContent="space-between"
      container
      onClick={onClickHandler}
    >
      <Box sx={sxAvatarBox}>
        <Avatar
          id={id}
          lastName={lastName}
          firstName={firstName}
          src={avatarSrc}
          moderationStatus={avatarModerationStatus}
          isBlocked={status === 'blocked'}
          isActive={active}
        />
        <HighlightText
          string={toTitleCase(`${firstName} ${lastName}`)}
          sxHighlighter={{
            highlighted: { color: 'black', fontWeight: 'bold' },
          }}
          sxString={{ color: 'customPalette.grey3' }}
        />
      </Box>
      <HighlightText
        string={formatPhoneNumber(eid)}
        sxHighlighter={{ highlighted: { color: 'black', fontWeight: 'bold' } }}
        sxString={{ color: 'customPalette.grey3' }}
      />
    </Grid>
  );
}

function useOptionStyles() {
  return {
    sxAvatarBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 1,
    },
  };
}

//TODO: Add mediaLocation and moderationStatus to Avatar when info is available
