import React, { useRef } from 'react';
import styles from './QuestionsList.module.scss';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddQuestion from '../AddQuestion';
import DeleteWrapper from '../DeleteWrapper';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Plus } from 'assets/icons';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';

const QuestionItem = ({ index, question }) => {
  const button = useRef();

  const { insert } = useFieldArray({ name: 'questions' });
  const [menu, toggleMenu] = useToggler();

  const handleDuplicate = () => {
    insert(index, question);
    toggleMenu();
  };

  return (
    <div className={styles.question}>
      <div className={styles.question__left}>
        <div className={styles.question__number}>{index + 1}</div>
        <span className={styles.question__name}>
          {question?.question || 'You question here.'}
        </span>
      </div>
      <IconButton ref={button} onClick={toggleMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={button.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={menu}
        onClose={toggleMenu}
      >
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        <DeleteWrapper index={index} Action={<MenuItem>Delete</MenuItem>} />
      </Menu>
    </div>
  );
};

const QuestionsList = () => {
  const [open, toggleModal] = useToggler();
  const { watch } = useFormContext();

  const questions = watch('questions');
  return (
    <div>
      <div className={styles.header}>
        <span>Content</span>
        <IconButton className={styles.header__btn} onClick={toggleModal}>
          <Plus />
        </IconButton>
        <AddQuestion open={open} onClose={toggleModal} />
      </div>
      <div className={styles.content}>
        {questions.map((item, i) => (
          <QuestionItem key={i} index={i} question={item} />
        ))}
      </div>
    </div>
  );
};

export default QuestionsList;
