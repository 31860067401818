import { BOOKMARK_TYPE } from '../constants';

export default function getInitialName(type, item) {

  switch (type) {
    case BOOKMARK_TYPE.CHAT: {
      const { members } = item;
      return `${getFullName(members[0])} - ${getFullName(members[1])}`;
    }
    case BOOKMARK_TYPE.INMATE:
    case BOOKMARK_TYPE.FNF: {
      return getFullName(item);
    }
    case BOOKMARK_TYPE.MESSAGE: {
      return `${item.owner_info} ${item.text}`;
    }
    case BOOKMARK_TYPE.PHOTO: {
      return `${item.owner_info} photo`;
    }
    case BOOKMARK_TYPE.AUDIO: {
      return `${item.owner_info} audio`;
    }
    case BOOKMARK_TYPE.VIDEO: {
      return `${item.owner_info} video`;
    }
    case BOOKMARK_TYPE.SEARCH: {
      return item.input;
    }
    default: {
      return item.id;
    }
  }
};

function getFullName(user) {
  return `${user?.firstName} ${user?.lastName}`;
};