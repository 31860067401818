import styled from '@mui/material/styles/styled';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import TooltipElement from 'shared/tooltip-element';
import PaginationButton from './PaginationButton';
import PrintRequestSelection from 'modules/print-request-section/print-requests-table/requests-selection/PrintRequestsSelection';

import { pagesArrayGenerator } from 'modules/print-request-section/utils';
import { If } from 'shared/utilities';

function TableNavigation( props ) {
	const {
		pageIndex,
		pageCount,
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		selectedFlatRows,
	} = props;

	const navigationPages = pagesArrayGenerator(pageIndex, pageCount);

	return (
		<TableNavigationContainer>
			<div>
				<PrintRequestSelection selectedRowsCount={selectedFlatRows.length} />
			</div>
			<div>
				<PagesContainer>
					<If condition={pageCount > 1}>
						<TooltipElement title='Go to previous page'>
					<span>
					<PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
						<NavigateBeforeIcon />
					</PaginationButton>
					</span>
						</TooltipElement>
					</If>
					<PaginationButton isSelected={pageIndex === 0} onClick={() => gotoPage(0)}>
						1
					</PaginationButton>
					<If condition={pageCount > 1}>
						{navigationPages.map(( page, index ) => {
							return (
								<PaginationButton
									key={index}
									isSelected={pageIndex === page - 1}
									onClick={() => gotoPage(page - 1)}
									disabled={page === '...'}
									value={page}
								>
									{page}
								</PaginationButton>
							);
						})}
					</If>
					<If condition={pageCount > 1}>
						<PaginationButton isSelected={pageIndex + 1 === pageCount} onClick={() => gotoPage(pageCount - 1)}>
							{pageCount}
						</PaginationButton>
					</If>
					<If condition={pageCount > 1}>
						<TooltipElement title='Go to next page'>
					<span>
					<PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
					<NavigateNextIcon />
					</PaginationButton>
					</span>
						</TooltipElement>
					</If>
				</PagesContainer>
			</div>
		</TableNavigationContainer>
	)
		;

};

export default TableNavigation;

const TableNavigationContainer = styled('nav')(( { theme } ) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginTop: '1rem',

	color: theme.palette.customPalette.grey3,
	fontWeight: 500,
	fontSize: '14px',
}));

const PagesContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	gap: '0.1rem',
});
