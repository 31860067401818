import SvgIcon from '@mui/material/SvgIcon';

const LogoFoil = ({ color, ...props }) => {
  return (
    <SvgIcon width={32} height={33} viewBox="0 0 32 33" fill="none" {...props}>
      <path
        d="M27.4462 4.76161C24.5095 1.70532 20.5514 0 16.423 0H0.845703V16.2116C0.845703 20.5081 2.50558 24.6275 5.42099 27.6616C8.33641 30.7179 12.2946 32.4232 16.423 32.4454H32.0002V16.2116C32.0002 11.9151 30.3617 7.79575 27.4462 4.76161ZM13.4012 23.8081C12.4435 23.3873 11.571 22.7893 10.8475 22.0363C10.124 21.2833 9.52812 20.3753 9.14507 19.3786C8.74074 18.382 8.54922 17.319 8.54922 16.2338C8.52794 11.6936 12.0605 7.95078 16.423 7.95078C17.4657 7.95078 18.4872 8.17225 19.4448 8.5709C20.4024 8.99169 21.2749 9.58966 21.9984 10.3427C22.722 11.0957 23.3178 12.0037 23.7009 13.0003C24.1052 13.9969 24.2967 15.06 24.2967 16.1452V24.3174V24.3617V24.4282H16.423C15.3802 24.4282 14.3588 24.2067 13.4012 23.8081Z"
        fill="#03A882"
      />
    </SvgIcon>
  );
};

export default LogoFoil;
