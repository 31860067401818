import { Grid, Typography } from '@mui/material';
import { User } from 'assets/icons';

export default function AccountOption() {
  return (
    <Grid container direction='row' gap={2}>
      <User />
      <Typography component='span'>Account</Typography>
    </Grid>
  );
}