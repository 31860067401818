import React from 'react';
import styles from './BlockInfoModal.module.scss';
import cs from 'classnames';

import Modal from 'shared/modal';
import { IconButton } from '@mui/material';

import { Close } from 'assets/icons';
import { KiwiCop } from 'shared/sidebar/Icons';

import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { isDateIndefinitely } from 'utils/date';

const getUserTypeById = (id) => {
  if (!id) return null;
  return id.includes('FNF^')
    ? 'F&F'
    : id.includes('I^')
    ? 'Inmate'
    : id.includes('O^')
    ? 'DOC officer'
    : 'Unknown';
};

const BlockInfoModal = ({
  open,
  toggleOpen,
  blockStatus,
  isChat,
  isBlock,
  profilePath,
  blockedByMetadata,
  blockedByDOC,
  reason,
  begins,
  ends,
  comment,
  displayChatWith,
  updatedReason,
  isKiwiCop,
  moderator,
}) => {
  const history = useHistory();
  const startDate = format(Date.parse(begins), 'MMM d, yyyy');
  const endDate = format(Date.parse(ends), 'MMM d, yyyy');
  const displayComment = isBlock ? comment : updatedReason;
  const userRole = getUserTypeById(blockedByMetadata?.id);
  const isIndefinitely = isDateIndefinitely(begins, ends);

  return (
    <Modal open={open} onClose={toggleOpen}>
      <div className={styles.header}>
        <div className={styles.header__left}>
          <p className={styles.header__status}>{blockStatus}</p>
          {isChat && (
            <span className={styles.header__chat}>with {displayChatWith}</span>
          )}
        </div>
        <IconButton onClick={toggleOpen}>
          <Close />
        </IconButton>
      </div>
      <div className={styles.content}>
        <div className={styles.block}>
          <p className={styles.block__title}>Completed on </p>
          <p className={styles.block__text}>
            {format(new Date(begins), 'MMM d, yyyy, h:mm a')}
          </p>
        </div>

        <div className={styles.block}>
          <p className={styles.block__title}>Completed by</p>
          <div className={styles.completedBy}>
            {isKiwiCop ? (
              <>
                <div className={styles.completedBy__avatar}>
                  <KiwiCop />
                </div>

                <span
                  className={cs(
                    styles.completedBy__name,
                    isKiwiCop && styles['completedBy__name--kiwicop']
                  )}
                >
                  KiwiCop
                </span>
              </>
            ) : (
              <div className={styles.completedBy__nameWrapper}>
                <p
                  className={styles.completedBy__name}
                  onClick={() => profilePath && history.push(profilePath)}
                >
                  {moderator.firstName} {moderator.lastName}
                </p>
                <span className={styles.completedBy__role}>{userRole}</span>
              </div>
            )}
          </div>
        </div>

        {isBlock && (isKiwiCop || blockedByDOC) && (
          <div className={styles.block}>
            <p className={styles.block__title}>Block reason</p>
            <p className={styles.block__reason}>{reason}</p>
          </div>
        )}
        <div className={styles.block}>
          <p className={styles.block__title}>Start / End date</p>
          <p className={styles.block__text}>
            {startDate} - {isIndefinitely ? 'Indefinitely' : endDate}
          </p>
        </div>

        {(blockedByDOC || !isChat) && (
          <div className={styles.block}>
            <p className={styles.block__title}>Comment</p>
            <p className={styles.block__text}>
              {displayComment ? displayComment : 'No comment'}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BlockInfoModal;
