import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useFormContext } from 'react-hook-form';
import { useChatContext } from 'modules/users-chats/context';

function FilterTitle() {
	const { getValues, setValue } = useFormContext();
	const { setFilters } = useChatContext();

	const handleClearAll = () => {
		const allValues = getValues();
		for (const key in allValues) {
			setValue(key, false);
		}
		setFilters({ filtersAreActive: false, mediaTypes: [] });
	};

	return (
		<FilterTitleContainer>
			<Typography variant='bigBoldBlack'>
				Filters
			</Typography>
			<StyledButton disableRipple onClick={handleClearAll}>
				Clear All
			</StyledButton>
		</FilterTitleContainer>
	);
}

export default FilterTitle;

const StyledButton = styled(Button)({
	textTransform: 'none',
});

const FilterTitleContainer = styled('div')(( { theme } ) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	margin: '1rem',
}));
