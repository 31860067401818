import React, { useEffect, useState, useCallback } from 'react';
import styles from './CreateStaff.module.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from 'shared/button';
import SelectAllButton from './SelectAllButton';
import FacilitiesFilter from './FacilitiesFilter';
import NameEmail from './NameEmail';

import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';
import { useStepperContext } from 'context';

const FacilityCheckbox = ({ id, label }) => {
  const { watch, setValue, trigger } = useFormContext();
  const value = watch('facilities');

  const onCheck = (evt) => {
    setValue('facilities', { ...value, [id]: evt.target.checked });
    trigger();
  };

  return (
    <FormControlLabel
      classes={{
        root: styles.checkbox__wrapper,
        label: styles.checkbox__label,
      }}
      control={
        <Checkbox
          classes={{ root: styles.checkbox }}
          onChange={onCheck}
          checked={!!value[id]}
        />
      }
      label={label}
    />
  );
};

const FacilitiesStep = () => {
  const [filter$] = useState(new Subject());
  const { facilities } = useAuthContext();
  const { register, trigger, formState } = useFormContext();
  const { onNextStep, onPrevStep } = useStepperContext();
  const [displayFacilities, setDisplayFacilities] = useState(facilities);

  register('facilities');

  useEffect(() => {
    const filterSub = filter$
      .pipe(
        debounceTime(500),
        map(({ value, facilities }) => {
          return facilities.filter(({ name }) =>
            name.toLowerCase().includes(value.toLowerCase())
          );
        })
      )
      .subscribe(setDisplayFacilities);

    trigger();

    return () => {
      filterSub.unsubscribe();
    };
  }, []);

  const handleFilter = useCallback(
    (value) => filter$.next({ value, facilities }),
    [facilities]
  );

  return (
    <div className={styles.facilitiesStep}>
      <NameEmail />
      <div className={styles.facilitiesStep__header}>
        <SelectAllButton name="facilities" listName="facilities" />
        <FacilitiesFilter onChange={handleFilter} />
      </div>
      <div className={styles.checkboxList}>
        {displayFacilities.map(({ id, name }) => (
          <FacilityCheckbox key={id} id={id} label={name} />
        ))}
      </div>
      <div className={styles.button__wrapper}>
        <Button
          className={styles.button}
          onClick={onPrevStep}
          display="secondary"
        >
          Back
        </Button>
        <Button
          className={styles.button}
          onClick={onNextStep}
          disabled={!formState.isValid}
          display="primary"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default FacilitiesStep;
