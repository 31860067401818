import React, { useEffect, useState } from 'react';

import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';

import { useFormContext } from 'react-hook-form';

import { Minus, Check } from 'assets/icons';

const FoilsInput = ({
                      foil,
                      selectValue,
                      rootPermission,
                      formStateObject,
                      rootPermissionIsChecked,
                    }) => {
  const [isChecked, setIsChecked] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const { setValue = '' } = useFormContext();

  const completeFoilValue = `${rootPermission}#${foil.parentValue}#${foil.value}`;
  const completeParentValue = `${rootPermission}#${foil.parentValue}`;

  const requiredPermissions = foil.requiredPermissions;

  const description = foil.description.replace('Bulletins', 'Announcements').replace('bulletins', 'announcements');

  const handleFoilPayload = {
    value: selectValue,
    foil: foil,
    rootPermission: rootPermission,
    disableStateModifier: setIsDisabled,
    checkStateModifier: setIsChecked,
    formStateObject: formStateObject,
    formStateValueModifier: setValue,
    completeParentValue: completeParentValue,
    rootPermissionIsChecked: rootPermissionIsChecked,
  };

  const foilColor = () => {
    if (!isChecked) {
      return '#686873';
    }
    return '';
  };

  const onChange = () => {
    if (
      isChecked &&
      formStateObject[completeParentValue] &&
      requiredPermissions.length > 0
    ) {
      requiredPermissions.forEach((permission) => {
        const root = permission.split('#')[0];
        setValue(permission, false);
        rootPermission !== root &&
        dependenciesModifier(root, formStateObject, setValue);
      });
      setIsChecked(!isChecked);
      setValue(completeFoilValue, !isChecked);
    }
    if (isChecked && !formStateObject[completeParentValue]) {
      setIsChecked(!isChecked);
      setValue(completeFoilValue, !isChecked);
    }

    if (!isChecked && !formStateObject[completeParentValue]) {
      setIsChecked(!isChecked);
      setValue(completeFoilValue, !isChecked);
      requiredPermissions.forEach((permission) => {
        const root = permission.split('#')[0];
        setValue(permission, true);
        rootPermission !== root &&
        dependenciesModifier(root, formStateObject, setValue);
      });
    }
  };

  useEffect(() => {
    setIsChecked(false);
    setIsDisabled(true);
    handleFoilBehavior(handleFoilPayload);
  }, [selectValue, formStateObject[completeParentValue]]);

  return (
    <FormGroup>
      <FormControlLabel
        sx={{ color: foilColor() }}
        control={
          <Checkbox
            icon={<Minus />}
            checkedIcon={<Check />}
            onChange={() => {
              selectValue === 'CUSTOM_ACCESS' && onChange();
            }}
            checked={isChecked}
            disabled={isDisabled}
            name={foil.value}
          />
        }
        label={description}
      />
    </FormGroup>
  );
};

export default FoilsInput;

const handleFoilBehavior = ({
                              value,
                              foil,
                              rootPermission,
                              disableStateModifier,
                              checkStateModifier,
                              formStateObject,
                              formStateValueModifier,
                              completeParentValue,
                              rootPermissionIsChecked,
                            }) => {
  const requiredPermissions = foil.requiredPermissions;

  if (!rootPermissionIsChecked) {
    requiredPermissions.forEach((permission) => {
      const root = permission.split('#')[0];
      formStateValueModifier(`${root}#hasActiveDependency`, false);
    });
  }

  if (value === 'VIEW_ONLY' && foil.parentValue === 'READ') {
    disableStateModifier(false);
    checkStateModifier(true);
    formStateValueModifier(`${rootPermission}#WRITE`, false);
  }

  if (formStateObject[completeParentValue] === true) {
    disableStateModifier(false);
    checkStateModifier(true);
  }

  if (value === 'FULL_ACCESS') {
    disableStateModifier(false);
    checkStateModifier(true);
    requiredPermissions.forEach((permission) => {
      const root = permission.split('#')[0];
      if (rootPermission !== root) {
        formStateValueModifier(permission, true);
        dependenciesModifier(root, formStateObject, formStateValueModifier);
      }
    });
  }
  if (value === 'CUSTOM_ACCESS') {
    disableStateModifier(false);
  }
};

const dependenciesModifier = (rootPermission, formState, formModifier) => {
  formModifier(
    `${rootPermission}#hasActiveDependency`,
    !formState[`${rootPermission}#hasActiveDependency`],
  );
};
