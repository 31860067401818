import { Fragment, memo } from 'react';
import styles from './ChannelList.module.scss';

import { Box, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';

import ChatItem from '../chat-item/ChatItem';
import Suspense from 'shared/suspense-list';
import ContactSearch from 'modules/users-chats/contact-search';

import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';

export default function ChannelList() {
  return (
    <Fragment>
      <ListHeader>
        <Typography variant="sectionHeader">Chats</Typography>
      </ListHeader>
      <ListSearch>
        <ContactSearch />
      </ListSearch>
      <ListContainer className={styles.chatList}>
        <MemoizedListContent />
      </ListContainer>
    </Fragment>
  );
}

const MemoizedListContent = memo(ListContent);

function ListContent() {
  const { filteredContacts = [] } = useChatsTabContext();
  const total = filteredContacts.length;

  return (
    <ListContentContainer>
      <Suspense loading={false} total={total} items="chats">
        <Fragment>
          {filteredContacts.map((chat) => {
            return <ChatItem key={chat.channelId} chatInfo={chat} />;
          })}
        </Fragment>
      </Suspense>
    </ListContentContainer>
  );
}

const ListContentContainer = styled(Box)({
  width: '100%',
});

const ListHeader = styled(Box)({
  margin: '20px 16px',
});

const ListSearch = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const ListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
