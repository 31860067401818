import React from 'react';
import cs from 'classnames';
import styles from './AnswerType.module.scss';

import Grid from '@mui/material/Grid';

function OptionAnswerType({ percentOfTotal, numberOfResponses, displayAggregate, option }) {
  return (
    <Grid className={cs(styles.answer__type_option)} container>
      <Grid
        item
        xs={7}
        display="flex"
        justifyContent="flex-start"
        className={styles.answer__type_option__response}
      >
        <span>{option}</span>
      </Grid>
      <Grid
        item
        xs={3}
        className={styles.answer__type_option__responses_number}
      >
        {displayAggregate && <span>{`${percentOfTotal} %`}</span>}
      </Grid>
      <Grid
        item
        xs={2}
        display="flex"
        justifyContent="flex-end"
        className={styles.answer__type_option__response_percent}
      >
        {displayAggregate && <span>{`${numberOfResponses} resp`}</span>}
      </Grid>
    </Grid>
  );
}

export default OptionAnswerType;
