import React, { useEffect, useRef, useState } from 'react';
import styles from './QuestionsBody.module.scss';
import cs from 'classnames';

import IconButton from '@mui/material/IconButton';

import { Close } from 'assets/icons';

import { useFormContext } from 'react-hook-form';

export const TextInput = ({ className, placeholder, name }) => {
  const { register } = useFormContext();
  return (
    <div className={cs(styles.input, className)}>
      <input placeholder={placeholder} {...register(name)} />
    </div>
  );
};

export const ChoiceInput = ({ onRemove, ...props }) => {
  return (
    <div className={styles.choiceInput}>
      <TextInput {...props} />
      <IconButton className={styles.choiceInput__btn} onClick={onRemove}>
        <Close />
      </IconButton>
    </div>
  );
};

const ResizeInputWrapper = ({ name, children }) => {
  const MAX_WIDTH = 813;
  const OFFSET = 35;
  const { watch } = useFormContext();
  const value = watch(name);

  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    const { offsetWidth = 0 } = span.current;
    if (MAX_WIDTH > offsetWidth && offsetWidth !== width) {
      setWidth(offsetWidth + OFFSET);
    }
  }, [value]);

  return (
    <div style={{ width }} className={styles.multipleChoiceInput}>
      <span ref={span}>{value}</span>
      {children}
    </div>
  );
};

export const MultipleChoiceInput = ({ onRemove, name, placeholder }) => {
  const { register } = useFormContext();

  return (
    <ResizeInputWrapper name={name}>
      <input placeholder={placeholder} {...register(name)} />
      <IconButton
        className={styles.multipleChoiceInput__btn}
        onClick={onRemove}
      >
        <Close />
      </IconButton>
    </ResizeInputWrapper>
  );
};

export const FakeAnswerInput = () => {
  const MAX_LENGTH = 150;
  const [answer, setAnswer] = useState('');

  const handleChange = ({ target }) => {
    if (target.value.length <= MAX_LENGTH) {
      setAnswer(target.value);
    }
  };

  return (
    <div className={styles.fakeAnswerInput}>
      <label>Answer</label>
      <textarea
        placeholder="Type your answer here..."
        value={answer}
        onChange={handleChange}
      />
    </div>
  );
};
