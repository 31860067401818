import { QUERY_KEYS } from 'utils';
import { useGraphql } from 'context';
import { useInfiniteQuery } from '@tanstack/react-query';

export default function useSearchFnfs( term ) {
	const api = useGraphql();

	function searchFnfs( { pageParam = null } ) {
		return api.controller.search.fnfs({
			term,
			limit: 40,
			nextPage: pageParam,
		});
	}

	const searchFnfsQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_FNF, term , 'search'],
		queryFn: searchFnfs,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
	});

	return searchFnfsQuery;
}
