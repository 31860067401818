import { useGraphql } from '../../../../context';
import { useMutation } from '@tanstack/react-query';

export default function useSendSurvey() {
  const api = useGraphql();

  function sendSurveyMutation(payload) {
    return api.controller.survey.send(payload);
  }

  return useMutation({ mutationFn: sendSurveyMutation });
}
