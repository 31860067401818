import { Fragment } from 'react';

import styled from '@mui/styles/styled';
import Button from '@mui/material/Button';

import DateRangePicker from 'shared/date-range-picker';

import { CustomMap } from 'shared/utilities';
import { dateRangeBuilder } from 'utils';
import { usePrintRequestsSectionContext } from 'modules/print-request-section/hooks';

function QuickFilters() {
	const { dateRangeFilter, setDateRangeFilter } = usePrintRequestsSectionContext();

	function resetFilter() {
		setDateRangeFilter({
			name: 'all',
			from: '',
			to: '',
		});
	}

	const onClickHandler = ( name ) => {
		if (dateRangeFilter?.name === name) {
			resetFilter();
		} else {
			const dateRange = dateRangeBuilder({ interval: name });
			setDateRangeFilter({
				name: name,
				from: dateRange.from,
				to: dateRange.to,
			});
		}
	};

	return (
		<Fragment>
			<CustomMap
				list={FILTERS_OPTIONS_DATES}
				keyExtractor={( { name } ) => name}
				renderItem={FilterButton}
				renderItemProps={{ onClick: onClickHandler, currentFilterSelected: dateRangeFilter?.name }}
				containerSx={{ display: 'flex', gap: '0.5rem' }}
			/>
			<DateRangeContainer>
				<DateRangePicker
					setFilter={setDateRangeFilter}
					dateBuilder={dateRangeBuilder}
					CustomInputComponent={
						<StyledButton isChecked={dateRangeFilter?.name === 'custom'}>
							Date Range
						</StyledButton>
					} />
			</DateRangeContainer>
		</Fragment>
	);
};

function FilterButton( props ) {
	const { item, onClick, currentFilterSelected } = props;

	return (
		<StyledButton onClick={() => onClick(item?.name)} isChecked={currentFilterSelected === item?.name}>
			{item?.label}
		</StyledButton>
	);
};

export default QuickFilters;

const StyledButton = styled(Button)(( { theme, isChecked } ) => ({
	height: 45,
	width: 100,

	border: '1px solid',
	borderColor: !isChecked && theme.palette.customPalette.grey4,
	borderRadius: '0.5rem',
	cursor: 'pointer',
	background: isChecked ? theme.palette.customPalette.grey3 : theme.palette.customPalette.white,

	fontWeight: 'bold',
	fontSize: '0.8rem',
	color: isChecked ? theme.palette.customPalette.white : theme.palette.customPalette.grey3,
	textTransform: 'none',

	'&:hover': {
		background: isChecked ? theme.palette.customPalette.grey3 : theme.palette.customPalette.grey6,
		color: isChecked ? theme.palette.customPalette.white : theme.palette.customPalette.grey3,
	},
}));

const DateRangeContainer = styled('div')({
	width: 100,
});

const FILTERS_OPTIONS_DATES = [
	{
		label: '24 hours',
		name: 'day',
	},
	{
		label: '7 days',
		name: 'week',
	},
	{
		label: 'Month',
		name: 'month',
	},
];
