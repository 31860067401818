import { useEffect } from 'react';
import styles from './styles.module.scss';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

import Suspense from 'shared/suspense-list';
import useAnalyticsModalList from 'shared/analytics-modal/hooks/useAnalyticsModalList';
import VerticalScroller from 'shared/vertical-scroller';
import TypeItem from 'shared/analytics-modal/items-list/TypeItem';
import useAnalyticsModalContext from 'shared/analytics-modal/hooks/useAnalyticsModalContext';
import SingleMessageLoader from 'shared/loaders/messages-loader/AnalyticsMessageLoader';
import ErrorBoundary from 'shared/error-boundary';

import { CustomMap, If } from 'shared/utilities';
import { flattenPages } from 'utils';

export default function AnalyticsMessageList() {
  const {
    setMessageAndChannelSelected,
    listQueryPayload,
    messageAndChannelSelected,
    modalConfig,
    mediaTypeActiveFilters,
    searchTerm,
  } = useAnalyticsModalContext();

  const { data, isInitialLoading, isFetching, fetchNextPage, hasNextPage } =
    useAnalyticsModalList({
      ...listQueryPayload,
      mediaTypesFilter: mediaTypeActiveFilters,
      term: searchTerm,
    });

  const iterableList = data && flattenPages(data);

  // Comment #1
  useEffect(() => {
    if (iterableList?.length) {
      if (modalConfig?.listRenderItem === 'channel') {
        const firstChannel = iterableList?.[0];
        setMessageAndChannelSelected({
          messageKey: 0,
          channelId: firstChannel.id,
        });
      }

      if (modalConfig?.listRenderItem === 'message') {
        const firstMessage = iterableList?.[0];
        setMessageAndChannelSelected({
          messageKey: firstMessage?.clientKey,
          channelId: firstMessage?.channel,
        });
      }
    }
  }, [iterableList?.[0]]);

  return (
    <ErrorBoundary level="section" textVariant="small" imageSize="small">
      <Suspense
        total={iterableList?.length || 0}
        loading={isInitialLoading}
        fallback={<SingleMessageLoader />}
        textNoResults="No messages found"
      >
        <ScrollerContainer
          className={styles.scroller_container}
          sx={{ maxHeight: modalConfig?.filterMessage ? 560 : 600 }}
        >
          <If condition={modalConfig?.listRenderItem === 'invitation'}>
            <InvitationHeader>
              <Typography
                sx={{
                  width: 170,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'customPalette.grey3',
                }}
              >
                Sender
              </Typography>
              <Typography
                sx={{
                  width: 170,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'customPalette.grey3',
                }}
              >
                Recipient
              </Typography>
            </InvitationHeader>
          </If>
          <VerticalScroller
            loadNext={fetchNextPage}
            hasNext={hasNextPage}
            loadingNext={isFetching}
          >
            <CustomMap
              list={iterableList}
              keyExtractor={(item) => item?.id}
              containerSx={Sxs.CustomMapListContainer}
              renderItem={TypeItem}
              renderItemProps={{
                handleSetMessageAndChannelSelected:
                  setMessageAndChannelSelected,
                currentItemSelected: messageAndChannelSelected,
                hasChatPanel: modalConfig?.showChatPanel,
              }}
            />
          </VerticalScroller>
        </ScrollerContainer>
      </Suspense>
    </ErrorBoundary>
  );
}

const ScrollerContainer = styled('ul')({
  width: '100%',
  padding: 1,
});

const InvitationHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  marginTop: 8,
}));

const Sxs = {
  CustomMapListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
};

/* Comments
 * 1. This useEffect is used to set the first message as selected by default.
 */
