import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const OpenLock = ({ color, ...props }) => (
  <SvgIcon width="14" height="19" viewBox="0 0 14 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2C5.34315 2 4 3.34315 4 5V8H11C12.6569 8 14 9.34315 14 11V16C14 17.6569 12.6569 19 11 19H3C1.34315 19 0 17.6569 0 16V11C0 9.6935 0.83498 8.5826 2 8.17078V5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5C12 5.55228 11.5523 6 11 6C10.4477 6 10 5.55228 10 5C10 3.34315 8.65685 2 7 2ZM3 10C2.44772 10 2 10.4477 2 11V16C2 16.5523 2.44772 17 3 17H11C11.5523 17 12 16.5523 12 16V11C12 10.4477 11.5523 10 11 10H3Z"
      fill={color || 'currentColor'}
    />
  </SvgIcon>
);

export default OpenLock;
