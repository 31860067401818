import React, { useEffect, useCallback, useRef, useState } from 'react';
import styles from './FacilitiesInput.module.scss';

import TextField from '@mui/material/TextField';
import FacilityCheckbox from './FacilityCheckbox';
import InputAdornment from '@mui/material/InputAdornment';
import Result from './Result';

import SearchIcon from '@mui/icons-material/Search';

import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';
import { fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const createValues = (facilities, oldValues) => {
  const allSelected = oldValues[0]?.id === 'all';

  const newValues = facilities.reduce(
    (acc, { id }) => {
      const oldValue = !!oldValues.find((item) => item.id === id);

      acc[id] = allSelected ? true : oldValue;
      return acc;
    },
    { all: allSelected }
  );

  return newValues;
};

const FacilitiesInput = ({ name }) => {
  const input = useRef(null);
  const [filter, setFilter] = useState('');
  const { watch, setValue, trigger } = useFormContext();
  const { facilities } = useAuthContext();
  const [displayFacilities, setDisplayFacilities] = useState(facilities);
  const value = watch(name);
  const [checkboxes, setCheckboxes] = useState(createValues(facilities, value));

  useEffect(() => {
    const filter = fromEvent(input.current, 'input')
      .pipe(
        map((evt) => evt.target.value),
        tap(setFilter),
        map((value) => value.toLowerCase()),
        map((search) =>
          facilities.filter(({ name }) => name.toLowerCase().includes(search))
        )
      )
      .subscribe(setDisplayFacilities);

    return () => {
      filter.unsubscribe();
    };
  }, []);

  useEffect(() => {
    trigger(name);
  }, [value]);

  const handleAllCheck = useCallback(({ checked }) => {
    const setAllCheckboxes = (checked) => (oldValues) => {
      return Object.keys(oldValues).reduce(
        (acc, curr) => {
          acc[curr] = checked;
          return acc;
        },
        { all: checked }
      );
    };

    setValue(name, checked ? facilities : []);
    setCheckboxes(setAllCheckboxes(checked));
  }, []);

  const handleCheck = useCallback(
    ({ id, checked, facility }) => {
      setCheckboxes((oldState) => ({
        ...oldState,
        [id]: checked,
      }));

      if (checked) {
        value.push(facility);
        setValue(name, [...value]);
      } else {
        setValue(
          name,
          value.filter((item) => item.id !== id)
        );
      }
    },
    [value]
  );

  return (
    <div>
      {/* <Result
        value={value}
        onRemove={(id) => {
          const checked = false;
          if (id === 'all') handleAllCheck({ checked });
          else handleCheck({ id, checked });
        }}
      /> */}
      <div className={styles.wrapper}>
        <TextField
          inputRef={input}
          placeholder="Type to search"
          variant="standard"
          disabled={checkboxes.all}
          fullWidth
          InputProps={{
            classes: {
              root: styles.input,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />

        {!filter.length && (
          <FacilityCheckbox
            name="all"
            label="Select all"
            value={checkboxes.all}
            onCheck={handleAllCheck}
          />
        )}

        {displayFacilities.map((item) => (
          <FacilityCheckbox
            key={item.id}
            name={item.id}
            label={item.name}
            value={checkboxes[item.id]}
            onCheck={handleCheck}
            facility={item}
            disabled={checkboxes.all}
          />
        ))}
      </div>
    </div>
  );
};

export default FacilitiesInput;
