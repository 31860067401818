import { Fragment, useContext, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import IconButton from 'shared/kiwi-components/icon-button';
import InvitationsList from 'shared/invitation-modal/InvitationsList';
import FiltersMenu from 'shared/invitation-modal/FiltersMenu';

import { Close } from 'assets/icons';
import { flattenPages, toTitleCase } from 'utils';
import { FilterSettingsIcon } from 'assets/icons';
import SearchIcon from '@mui/icons-material/Search';
import { If } from 'shared/utilities';
import FilterIcon from '@mui/icons-material/FilterAlt';
import MessagesFilters from 'shared/analytics-modal/messages-filter';
import { useToggler } from '../../hooks';

function InvitationModal({ context }) {
  const {
    openInvitationModal,
    toggleOpenInvitationModal,
    userProfileInfo,
    invitationsQuery,
    selectedInvitationStatus,
    setSelectedInvitationStatus,
  } = useContext(context);

  const { data } = invitationsQuery;

  const flattedList = useMemo(
    () =>
      data
        ? flattenPages(data).sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        : [],
    [data]
  );

  const [searchTerm, setSearchTerm] = useState('');

  const filteredList = useMemo(() => {
    if (!searchTerm) return flattedList;
    return flattedList.filter((item) => {
      const { email, phone } = item.recipient;
      const { firstName, lastName, eid } = item.sender;
      const searchValue = searchTerm.toLowerCase();
      return (
        email.toLowerCase().includes(searchValue) ||
        phone.includes(searchValue) ||
        firstName.toLowerCase().includes(searchValue) ||
        lastName.toLowerCase().includes(searchValue) ||
        eid.includes(searchValue)
      );
    });
  }, [searchTerm, flattedList]);

  function onSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <Transition.Root show={openInvitationModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={toggleOpenInvitationModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-4">
                <div className="overflow-hidden rounded-lg bg-white">
                  <div className="flex justify-between p-4">
                    <Dialog.Title
                      as="h3"
                      className="text-stone-950 text-xl font-bold leading-7"
                    >
                      {`${
                        toTitleCase(
                          `${userProfileInfo?.firstName} ${userProfileInfo?.lastName}'s`
                        ) ?? ''
                      } Invitations`}
                    </Dialog.Title>
                    <IconButton onClick={toggleOpenInvitationModal}>
                      <Close />
                    </IconButton>
                  </div>
                  <div className=" w-full px-3">
                    <label htmlFor="searchTerm" className="sr-only">
                      Search for items
                    </label>
                    <div className="mt-2 flex rounded-md shadow-sm">
                      <div className="relative flex flex-grow items-stretch focus-within:z-10">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="text"
                          id="searchTerm"
                          name="searchTerm"
                          value={searchTerm}
                          className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 focus:outline-none ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          placeholder="Search for ..."
                          onChange={onSearchChange}
                        />
                      </div>
                      <FiltersMenu
                        status={selectedInvitationStatus}
                        setStatus={setSelectedInvitationStatus}
                      >
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-grey-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          <FilterIcon
                            className="-ml-0.5 h-5 w-5 text-gray-4"
                            aria-hidden="true"
                          />
                          Filter
                        </button>
                      </FiltersMenu>
                    </div>
                  </div>
                  <div className="p-4">
                    <InvitationsList
                      filteredData={filteredList}
                      invitationsQuery={invitationsQuery}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default InvitationModal;
