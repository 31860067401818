import { SvgIcon } from '@mui/material';
import React from 'react';

export const Alert = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="9.25" stroke="#DF093C" strokeWidth="1.5" />
    <path
      d="M12.7314 14.0674H11.3027L11.1455 7.04688H12.8955L12.7314 14.0674ZM11.0977 16.2139C11.0977 15.9541 11.182 15.7399 11.3506 15.5713C11.5192 15.3981 11.7493 15.3115 12.041 15.3115C12.3327 15.3115 12.5628 15.3981 12.7314 15.5713C12.9001 15.7399 12.9844 15.9541 12.9844 16.2139C12.9844 16.4645 12.9023 16.6742 12.7383 16.8428C12.5742 17.0114 12.3418 17.0957 12.041 17.0957C11.7402 17.0957 11.5078 17.0114 11.3438 16.8428C11.1797 16.6742 11.0977 16.4645 11.0977 16.2139Z"
      fill="#DF093C"
    />
  </svg>
);

export const ReadMark = ({ className }) => (
  <SvgIcon className={className} viewBox="0 0 16 10" fill="none">
    <path
      d="M7.73475 6.17171L8.67608 7.11304L14.3201 1.46904L15.2627 2.41171L8.67608 8.99837L4.43341 4.75571L5.37608 3.81304L6.79275 5.22971L7.73475 6.17104V6.17171ZM7.73608 4.28638L11.0374 0.984375L11.9774 1.92437L8.67608 5.22638L7.73608 4.28638ZM5.85141 8.05638L4.90941 8.99837L0.666748 4.75571L1.60941 3.81304L2.55141 4.75504L2.55075 4.75571L5.85141 8.05638Z"
      fill="currentColor"
    />
  </SvgIcon>
);
