import React, { createContext, useState } from 'react';

import useFacilitiesPrintRequests from 'modules/print-request-section/queries/useFacilitiesPrintRequests';

import { useToggler } from 'hooks';
import { useAuthContext } from 'modules/auth/context';

export const PrintRequestsContext = createContext({});

const initPrintRequestsContext = () => {
	const [selectedRequest, setSelectedRequest] = useState();
	const [dateRangeFilter, setDateRangeFilter] = useState(INITIAL_DATE_RANGE_FILTER);
	const [openPrintSingleRequestModal, togglePrintSingleRequestModal] = useToggler(false);
	const [openPrintMultipleRequestModal, togglePrintMultipleRequestModal] = useToggler();

	const { agency, facilities } = useAuthContext();
	const { data: printRequests, isLoading } = useFacilitiesPrintRequests({ agencyId: agency?.id, facilityId: facilities?.[0]?.id });

	return {
		selectedRequest,
		setSelectedRequest,
		dateRangeFilter,
		setDateRangeFilter,
		openPrintSingleRequestModal,
		togglePrintSingleRequestModal,
		openPrintMultipleRequestModal,
		togglePrintMultipleRequestModal,
		printRequests,
		isLoading,
	};
};

export const PrintRequestsProvider = ( { children, ...props } ) => {
	const contextValue = initPrintRequestsContext();

	return (
		<PrintRequestsContext.Provider value={contextValue}>
			{children}
		</PrintRequestsContext.Provider>
	);
};

const INITIAL_DATE_RANGE_FILTER = {
	name: 'all',
	from: '',
	to: '',
};

