import { BOOKMARK_TYPE } from '../constants';

export default function renderTitle(type) {
  switch (type) {
    case BOOKMARK_TYPE.CHAT: {
      return 'Save chat';
    }
    case BOOKMARK_TYPE.INMATE: {
      return 'Save inmate';
    }
    case BOOKMARK_TYPE.FNF: {
      return 'Save F&F';
    }
    case BOOKMARK_TYPE.MESSAGE: {
      return 'Save message';
    }
    case BOOKMARK_TYPE.PHOTO: {
      return 'Save photo';
    }
    case BOOKMARK_TYPE.VIDEO: {
      return 'Save video';
    }
    case BOOKMARK_TYPE.SEARCH: {
      return 'Save search';
    }
    default: {
      return '';
    }
  }
};
