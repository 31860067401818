import React, { useCallback, useEffect, useState } from 'react';
import styles from './SurveysSearch.module.scss';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';
import BackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { useSearchParams } from 'hooks';
import { useSurveysContext } from 'modules/surveys/context';

const SurveysSearch = () => {
  const { searchInput, searchSurveys, loading } = useSurveysContext();
  const { searchParams } = useSearchParams();

  const [input, setInput] = useState('');

  useEffect(() => {
    setInput(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (searchParams.search && searchParams.search !== searchInput) {
      searchSurveys(searchParams.search);
    }
  }, [searchParams.search]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (input.trim() && input.trim() !== searchInput) {
      searchSurveys(input);
    }
  };

  const handleChange = useCallback((evt) => {
    setInput(evt.target.value);
  }, []);

  const handleClearSearch = () => {
    setInput('');
    searchSurveys('');
  };

  const searchDone = !loading && searchInput;

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <TextField
        classes={{ root: styles.input__wrapper }}
        placeholder="Search for survey"
        variant="outlined"
        value={input}
        onChange={handleChange}
        InputProps={{
          classes: {
            root: styles.input,
            notchedOutline: styles.input__outline,
          },
          startAdornment: (
            <InputAdornment position="start">
              {searchDone ? (
                <BackIcon
                  className={styles.clearIcon}
                  onClick={handleClearSearch}
                />
              ) : (
                <SearchIcon className={styles.input__icon} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SurveysSearch;
