import React from 'react';
import styles from './FormLoader.module.scss';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const FormLoader = ({ loading = true, text = 'Applying changes' }) =>
  loading ? (
    <Grid
      classes={{ root: styles.loader }}
      alignItems="center"
      wrap="nowrap"
      container
    >
      <CircularProgress classes={{ root: styles.loader__spinner }} />
      <p className={styles.loader__text}>{text}</p>
    </Grid>
  ) : null;

export default FormLoader;
