import * as yup from 'yup';

export const RESET_PASSWORD_FORM_VALIDATION = yup.object({
  email: yup.string().email().lowercase().trim(),
  newPassword: yup
    .string()
    .test(
      'newPassword',
      'Password must be at least 8 characters, include UPPER/lowercase and numbers',
      (value = '') => {
        if (value.length < 8) return false;
        if (!/[~!@#$%^*\-_=+[{}\]/;:,.?]+/.test(value)) return false;
        if (!/[A-Z]+/.test(value)) return false;
        if (!/[a-z]+/.test(value)) return false;
        if (!/[0-9]+/.test(value)) return false;
        return true;
      }
    )
    .required(),
  confirmPassword: yup
    .string()
    .test('confirmPassword', "Password doesn't match", function (value) {
      return value === this.parent.newPassword;
    }),
});

export const PASSWORD_VALIDATION: PASSWORD_VALIDATION_OBJECT = {
  LENGTH: 'At least 8 characters',
  LOWER_CASE: 'At least 1 lower case letter',
  UPPER_CASE: 'At least 1 upper case letter',
  NUMBER: 'At least 1 number',
  SPECIAL_SYMBOL:
    'At least 1 special character ' +
    '(~ ! @ # $ % ^ * - _ = + [ { ] } / ; :,. ?)',
};

interface PASSWORD_VALIDATION_OBJECT {
  LENGTH: string;
  LOWER_CASE: string;
  UPPER_CASE: string;
  NUMBER: string;
  SPECIAL_SYMBOL: string;
  [key: string]: string; // Index signature for string keys with string values
}
