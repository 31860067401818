import { createContext, useState } from 'react';
import dateRangeBuilder from 'utils/dateRangeBuilder';
import { useToggler } from 'hooks';

export const ChatMetricsContext = createContext();

function initialContextValue() {
	const [location, setLocation] = useState('all');
	const [checkboxSelected, setCheckboxSelected] = useState('month');
	const [dateRangeFilter, setDateRangeFilter] = useState(dateRangeBuilder({ interval: checkboxSelected }));
	const [sentimentSelectedRangeFilter, setSentimentSelectedRangeFilter] = useState();
	const [openFlaggedMessagesModal, toggleFlaggedMessagesModal] = useToggler(false);
	const [selectedOption, setSelectedOption] = useState([]);
	const [selectedAnalyticModalConfig, setSelectedAnalyticModalConfig] = useState({});

	return {
		location,
		dateRangeFilter,
		checkboxSelected,
		openFlaggedMessagesModal,
		selectedAnalyticModalConfig,
		sentimentSelectedRangeFilter,
		selectedOption,
		setLocation,
		setDateRangeFilter,
		setCheckboxSelected,
		setSelectedOption,
		toggleFlaggedMessagesModal,
		setSelectedAnalyticModalConfig,
		setSentimentSelectedRangeFilter,
	};

}

export function ChatMetricsProvider( { children } ) {

	const value = initialContextValue();
	return (
		<ChatMetricsContext.Provider value={value}>
			{children}
		</ChatMetricsContext.Provider>
	);
}
