import { Fragment } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import format from 'date-fns/format';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import { useToggler } from 'hooks';
import { useUserProfileContext } from 'modules/users-chats/hooks';
import { formatPhoneNumber, getUserType, toTitleCase } from 'utils';
import { If } from 'shared/utilities';

export default function ProfileInfoModal() {
  const [openInfoModal, setToggleInfoModal] = useToggler();
  const { sxDialog } = useProfileInfoModalStyles();
  const { userProfileInfo } = useUserProfileContext();
  const titleName = toTitleCase(
    `${userProfileInfo?.firstName} ${userProfileInfo?.lastName}`
  );
  const userType = getUserType(userProfileInfo?.id);
  return (
    <Fragment>
      <IconButton onClick={setToggleInfoModal} disableRipple>
        <InfoIcon sx={{ color: 'black' }} />
      </IconButton>
      <Dialog open={openInfoModal} onClose={setToggleInfoModal} sx={sxDialog}>
        <DialogTitle id="user-info-modal">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="bigBoldBlack" fontSize={20}>
                {titleName}
              </Typography>
              <Typography
                mt={1}
                variant="regularGrey4"
                fontSize={16}
                component="span"
              >
                {userType === 'FNF' && userProfileInfo.createdAt
                  ? format(new Date(userProfileInfo?.createdAt), 'MMM dd, yyyy')
                  : null}
                {(userType === 'INMATE' && userProfileInfo?.eid) || null}
              </Typography>
            </Box>
            <IconButton onClick={setToggleInfoModal} disableRipple>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AccordionInfo />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

function AccordionInfo() {
  const INFO_ELEMENTS = getAccordionInfoElements();
  return (
    <Fragment>
      {INFO_ELEMENTS.map(
        ({ label, value, values, InfoDetails, isAccordion }) => (
          <InfoSummary
            key={label}
            label={label}
            value={value}
            isAccordion={isAccordion}
            values={values}
          />
        )
      )}
    </Fragment>
  );
}

function InfoSummary({ label, value, values = [], isAccordion = false }) {
  const singleInfoStyles = !isAccordion
    ? {
        height: 56,
        borderBottom: '1px solid',
        borderColor: 'customPalette.grey5',
      }
    : {};

  console.log('value', value);
  return (
    <If
      condition={!isAccordion && typeof value === 'string'}
      otherwise={
        <Accordion
          elevation={0}
          sx={{ '& .MuiAccordionSummary-root': { padding: 0 } }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="regularGrey4" fontSize={16}>
              {label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              '&.MuiAccordionDetails-root': {
                padding: 0,
              },

              maxHeight: 200,
              overflow: 'hidden auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                marginBottom: 1,
              }}
            >
              {values.map((ip) => (
                <Typography
                  sx={{
                    padding: '4px 12px',
                    backgroundColor: 'customPalette.grey5',
                    borderRadius: 4,
                  }}
                >
                  {ip.split(';')[0]}
                </Typography>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', ...singleInfoStyles }}>
        <Typography
          mr={1}
          variant="regularGrey4"
          fontSize={16}
          component="span"
        >
          {label}
        </Typography>
        <Typography component="span">{value}</Typography>
      </Box>
    </If>
  );
}

function getAccordionInfoElements() {
  const { userProfileInfo } = useUserProfileContext();

  if (getUserType(userProfileInfo.id) === 'FNF')
    return [
      {
        label: 'Phone: ',
        value: userProfileInfo?.phone
          ? formatPhoneNumber(userProfileInfo.phone)
          : '',
      },
      { label: 'Email: ', value: userProfileInfo?.email || '' },
      {
        label: 'Date of Birth: ',
        value: userProfileInfo?.dob
          ? format(new Date(userProfileInfo?.dob), 'MMM dd, yyyy')
          : '',
      },
      {
        label: 'IP Addresses',
        values: userProfileInfo?.ipAddresses || [],
        isAccordion: true,
      },
    ];

  return [
    {
      label: 'Facility: ',
      value: userProfileInfo?.location?.facilityName || '',
    },
    { label: 'Housing unit: ', value: userProfileInfo?.location?.huName || '' },
    {
      label: 'Date of Birth: ',
      value: userProfileInfo?.dob
        ? format(new Date(userProfileInfo?.dob), 'MMM dd, yyyy')
        : '',
    },
    {
      label: 'Date registered: ',
      value: userProfileInfo?.createdAt
        ? format(new Date(userProfileInfo?.createdAt), 'MMM dd, yyyy')
        : '',
    },
    { label: 'Number of contacts: ', value: '' },
  ];
}

function useProfileInfoModalStyles() {
  return {
    sxDialog: {
      '& .MuiDialog-paper': {
        borderRadius: 4,
        minWidth: 550,
      },
    },
  };
}
