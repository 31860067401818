import React from 'react';
import styles from './MediaModal.module.scss';
import query from 'query-string';

import MaterialButton from '@mui/material/Button';
import routes from 'routing/routes';

import { useHistory, generatePath } from 'react-router';

const ShowInChatButton = ( { channel, ownerId, messageClientKey } ) => {
	const history = useHistory();

	function handleShowInChat( e ) {
		e.stopPropagation();
		history.push(
			`${generatePath(routes.chat, {
				userId: ownerId,
				chatId: channel,
			})}?${query.stringify({
				messageId: messageClientKey,
			})}`,
		);
	};

	return (
		<MaterialButton
			classes={{
				root: styles.header__showButton,
			}}
			onClick={handleShowInChat}>
			Show in chat
		</MaterialButton>
	);
};

export default ShowInChatButton;
