import { useEffect } from 'react';

import format from 'date-fns/format';

import { fromEvent } from 'rxjs';
import { useChatContext } from 'modules/users-chats/context';

const useChangeFloatDate = ( { listRef, messageRef, createdAt } ) => {
	const { setFloatDate, floatDate } = useChatContext();
	const handleSetFloatDate = ( date ) => {
		const formatDate = date && format(new Date(date), 'd MMM');
		setFloatDate(state =>
			formatDate && formatDate !== state ? formatDate : state,
		);
	};

	useEffect(() => {
		const sub = fromEvent(listRef.current, 'scroll').subscribe(() => {
			if (!messageRef.current) return;
			if (!listRef.current) return;
			const messageRect = messageRef.current.getBoundingClientRect();
			const listRect = listRef.current.getBoundingClientRect();
			const offSet = listRect.top;
			if (messageRect.top - offSet <= 0 && messageRect.bottom - offSet >= 0) {
				handleSetFloatDate(createdAt);
			}
		});

		return () => sub.unsubscribe();
	}, [listRef]);
};

export default useChangeFloatDate;

// TODO: why have a console.log() here make the componente work?.log() here make the componente work?
