import styled from '@emotion/styled';

function SearchRequest( props ) {
	const { filter, setFilter } = props;
	return (
		<SearchContainer>
			<SearchInput
				type='search'
				placeholder='Search Requests' value={filter || ''}
				onChange={( e ) => setFilter(e.target.value)} />
		</SearchContainer>
	);
};

export default SearchRequest;

const SearchContainer = styled.div`
  /* Box model */
  flex: 1;
  display: flex;
`;

const SearchInput = styled.input`
  /* Box model */
  height: 48px;
  flex: 1;

  /* Typography */
  font-size: 1rem;

  /* Visual */
  border-radius: 16px;
  border: 1px solid #9191a1;
  padding: 0 1rem;

  &:focus {
    border-width: 1px;
    outline: #101010;
    border-color: #101010;
  }
`;
