import React from 'react';
import styles from './EditStaffFacilities.module.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useFormContext } from 'react-hook-form';

const FacilityCheckbox = ({ name, label }) => {
  const { register, watch, setValue } = useFormContext();
  register(name);
  const value = watch(name);

  const onCheck = (evt) => {
    setValue(name, evt.target.checked);
  };

  return (
    <FormControlLabel
      classes={{
        root: styles.checkbox__wrapper,
        label: styles.checkbox__label,
      }}
      control={
        <Checkbox
          classes={{ root: styles.checkbox }}
          onChange={onCheck}
          checked={!!value}
        />
      }
      label={label}
    />
  );
};

export default FacilityCheckbox;
