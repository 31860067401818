import ProfileCard from 'modules/users-chats/user-details/profile-tab/ProfileCard';
import EmptyResult from './EmptyResult';
import Suspense from 'shared/suspense-list';
import CardContentLayout from 'modules/chats-metrics/charts/chart-card/CardContentLayout';
import KiwiPieChart from 'shared/pie-chart';

import { PieChartLegend } from 'shared/chart-legend';
import {
  useInvitationsAnalysis,
  useUserProfileContext,
} from 'modules/users-chats/hooks';
import { ChartLoader } from 'shared/loaders';
import { COLORS } from './constants';

export default function InvitationsCharts() {
  const {
    userId,
    toggleOpenInvitationModal,
    setSelectedInvitationStatus,
    dateRangeFilter,
  } = useUserProfileContext();
  const { data = {}, isLoading } = useInvitationsAnalysis(
    userId,
    dateRangeFilter
  );

  const dataIsNotEmpty = Object.keys(data)
    .map((key) => data[key])
    .some((value) => value > 0);

  const dataArray = Object.entries(data);

  const total = dataArray.reduce((accumulator, [key, value]) => {
    return key !== 'total' ? accumulator + value : accumulator;
  }, 0);

  const formatedDataToDisplay = dataArray
    .filter(([key]) => key !== 'total')
    .map(([key, value], index) => ({
      name: getProperStatus(key),
      value,
      color: COLORS[index],
    }));

  function getProperStatus(status) {
    if (status === 'sent') return 'pending';
    if (status === 'denied') return 'rejected';
    return status;
  }

  const selectInvitationFromLegend = (invitationType) => {
    toggleOpenInvitationModal();
    setSelectedInvitationStatus(invitationEnum[invitationType.toUpperCase()]);
  };

  const onClickInnerText = () => {
    toggleOpenInvitationModal();
    setSelectedInvitationStatus(0);
  };

  return (
    <ProfileCard
      cardTitle="Invitations"
      sxCard={{ flex: 1, margin: '10px 24px 20px 10px' }}
    >
      <Suspense
        loading={isLoading}
        total={dataIsNotEmpty ? 1 : 0}
        fallback={<ChartLoader />}
        noResultComponent={<EmptyResult />}
      >
        <CardContentLayout
          chartType="pie"
          LegendComponent={() => (
            <PieChartLegend
              legendData={formatedDataToDisplay}
              nameKey="name"
              valueKey="value"
              colorPalette={COLORS}
              clickable={true}
              onClickItem={selectInvitationFromLegend}
            />
          )}
          ChartComponent={() => (
            <KiwiPieChart
              chartData={formatedDataToDisplay}
              legendData={formatedDataToDisplay}
              chartDataKey="value"
              chartNameKey="name"
              innerText="Total"
              innerValue={total}
              colorPalette={COLORS}
              clickableInner={true}
              onClickInner={onClickInnerText}
            />
          )}
        />
      </Suspense>
    </ProfileCard>
  );
}

const invitationEnum = {
  SENT: null,
  PENDING: '1',
  WITHDRAWN: '2',
  REJECTED: '3',
  ACCEPTED: '4',
  DUPLICATED: '5',
  EXPIRED: '6',
};
