import React from 'react';
import cs from 'classnames';
import styles from './TypeResult.module.scss';

function VerticalBarResult({ value }) {
  const valueInPercent = `${value}%`;
  return (
    <div className={cs(styles.input__type__vertical)}>
      <p
        className={styles.input__type__vertical__background}
        style={{ height: valueInPercent }}
      />
    </div>
  );
}

export default VerticalBarResult;
