import { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

import ChatAvatar from './ChatAvatar';
import ChatInfo from './ChatInfo';
import ChatDate from './ChatDate';
import ChatStatus from './ChatStatus';
import ChatLive from './ChatLive';

import { useParams } from 'react-router';
import { generatePath, useHistory } from 'react-router-dom';

import { useChannelLastMessage, useUserProfileInfo } from 'hooks';
import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';

import { routes } from 'routing';
import { QUERY_KEYS } from 'utils';
import { queryClient } from 'utils/queryClient';

const ChatItem = (props) => {
  const {
    id,
    firstName,
    lastName,
    channelId,
    isPersonBlock,
    isChannelBlock,
    inLiveChat,
    blockedBy,
    begins,
    blockedUntil,
    photo,
    avatarModerationStatus,
  } = props.chatInfo;
  const { chatSelected } = useChatsTabContext();
  const history = useHistory();
  const { userId } = useParams();
  const currentUserId = decodeURIComponent(userId);
  const chatRef = useRef();
  const { data = {} } = useChannelLastMessage(channelId);
  const { data: userInfo } = useUserProfileInfo(id);

  const avatarMediaLocation = photo
    ? photo
    : avatarModerationStatus?.mediaLocation || '';
  const isSelected = channelId === chatSelected;
  const isFnf = id.includes('FNF^');
  const blockStatusInfo = { blockedBy, begins, blockedUntil };

  const onHandleClick = () => {
    console.log('hello click');
    history.push(
      `${generatePath(routes.chat, {
        userId: currentUserId,
        chatId: channelId,
      })}`
    );
  };

  useEffect(() => {
    if (channelId === chatSelected) {
      chatRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [chatRef.current, chatSelected, id]);

  useEffect(() => {
    if (data) {
      queryClient.setQueriesData([QUERY_KEYS.USER_CHATS, userId], (oldData) => {
        const newData = oldData?.map((chat) => {
          if (chat.channelId === channelId) {
            return {
              ...chat,
              lastMsgClientKey: data?.clientKey,
            };
          }
          return chat;
        });
        return newData;
      });
    }
  }, [data]);

  return (
    <Paper elevation={isSelected ? 4 : 0} sx={Sxs.Paper} ref={chatRef}>
      <ChatItemContainer selected={isSelected} onClick={onHandleClick}>
        <ChatAvatarContainer>
          <ChatAvatar
            id={id}
            firstName={firstName}
            lastName={lastName}
            isFnf={isFnf}
            isBlocked={isPersonBlock}
            mediaLocation={avatarMediaLocation}
            isBlur={!photo && avatarModerationStatus}
            isActive={userInfo?.active}
          />
        </ChatAvatarContainer>
        <ChatInfoContainer>
          <ChatInfo
            id={id}
            firstName={firstName}
            lastName={lastName}
            text={data?.text}
            mediaType={data?.mediaType}
            shouldRender={data?.clientKey}
          />
        </ChatInfoContainer>
        <ChatStatusContainer>
          <ChatLive />
          <ChatStatus isChannelBlock={isChannelBlock} info={blockStatusInfo} />
          <ChatDate date={data?.clientKey} inLiveChat={inLiveChat} />
        </ChatStatusContainer>
      </ChatItemContainer>
    </Paper>
  );
};

export default ChatItem;

const ChatItemContainer = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  height: '72px',
  flexDirection: 'row',
  overflow: 'hidden',
  borderRadius: '16px',
  alignItems: 'center',
  border: '1px solid #E0E0E0',
  borderColor: theme.palette.customPalette.grey5,
  bgcolor: selected
    ? theme.palette.customPalette.grey6
    : theme.palette.customPalette.white,
  '&:hover': {
    bgcolor: theme.palette.customPalette.grey6,
  },
}));

const ChatStatusContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 160,
  height: 44,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  margin: theme.spacing(1),
}));

const ChatInfoContainer = styled(Box)({
  display: 'flex',
  height: 44,
  alignItems: 'center',
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ChatAvatarContainer = styled(Box)(({ theme }) => ({
  width: '55px',
  margin: theme.spacing(1),
  cursor: 'auto',
}));

const Sxs = {
  Paper: {
    borderRadius: '16px',
    margin: 1,
    cursor: 'pointer',
  },
};
