import React, { useMemo } from 'react';
import styles from './PrintRequestSelection.module.scss';

import Button from '@mui/material/Button';

import { PrintRequestModal } from '../print-requests-modal';

import { Print } from 'assets/icons';
import { useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';

const PrintRequestSelection = ({ allRequests }) => {
  const { watch } = useFormContext();
  const [modal, toggleModal] = useToggler();

  const value = watch();

  const selected = useMemo(() => {
    return Object.values(value).filter(Boolean).length;
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        {selected === 0 ? (
          <>
            <span>Selected: </span>0
          </>
        ) : (
          `${selected} Selected`
        )}
      </p>
      {selected > 0 && (
        <>
          <Button
            classes={{ root: styles.action }}
            onClick={toggleModal}
            sx={{ color: '#101010' }}
          >
            <Print />
          </Button>
        </>
      )}
      <PrintRequestModal
        open={modal}
        onClose={toggleModal}
        requestArray={watch()}
        allRequests={allRequests}
      />
    </div>
  );
};

export default PrintRequestSelection;
