import { Fragment, useMemo, useState } from 'react';

import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';
import MuiButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Form from 'shared/form';

import { useToggler } from 'hooks';
import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';
import { getInitialMediaTypeFilter } from 'utils/getInitialMediaTypeFilter';

import { MEDIA_TYPES } from 'shared/analytics-modal/messages-filter/MEDIA_TYPES';
import { FilterTitle } from 'shared/analytics-modal/messages-filter/FilterTitle';
import { FilterForm } from 'shared/analytics-modal/messages-filter/FilterForm';

export default function MessagesFilters(props) {
  const { anchorEl, open, toggleOpen } = props;
  const { setMediaTypeActiveFilters, mediaTypeActiveFilters } =
    useAnalyticsModalContext();

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'mesesage-filters-popper' : undefined;

  function handleFormSubmit(form) {
    const mediaTypeActiveFilters = Object.keys(form).filter((key) => form[key]);
    setMediaTypeActiveFilters(mediaTypeActiveFilters);
    toggleOpen();
  }

  const formMediaTypesInitialValues = useMemo(
    () => getInitialMediaTypeFilter(MEDIA_TYPES, mediaTypeActiveFilters),
    [mediaTypeActiveFilters]
  );

  return (
    <Fragment>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{ zIndex: 1301 }}
      >
        <Form
          initialValues={formMediaTypesInitialValues}
          onSubmit={handleFormSubmit}
        >
          <FilterContainer elevation={1}>
            <FilterTitle />
            <FilterForm toggleOpen={toggleOpen} />
          </FilterContainer>
        </Form>
      </Popper>
    </Fragment>
  );
}

const FilterContainer = styled(Paper)({
  position: 'absolute',
  width: 295,
  top: 0,
  right: 0,

  zIndex: 100,
  borderRadius: 8,
});
