import React, { ReactNode } from 'react';

import { twc } from 'react-twc';

import Form from 'shared/form';

import { cn } from 'utils/functions';
import { useFormContext } from 'react-hook-form';

/**
 * Renders a password forgot component.
 *
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The content to be rendered within the component.
 *
 * @return {ReactNode} - The rendered component.
 */
export default function PasswordForgot({ children }: ForgotPasswordProps) {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {children}
    </div>
  );
}

PasswordForgot.Title = twc.div`sm:mx-auto sm:w-full sm:max-w-sm text-center`;

PasswordForgot.SubmitButton = (props: SubmitButtonProps) => {
  const { formState } = useFormContext();

  const isDisable = !formState.isValid;

  return (
    <button
      className={cn(
        'flex w-full h-10 md:h-12 px-5 py-2 md:py-3 justify-center rounded-lg bg-grey-1 text-white text-sm md:text-base font-medium leading-normal border border-grey-1 shadow-sm hover:bg-white hover:text-grey-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition duration-150 ease-in-out',
        {
          'bg-grey-3 border border-grey-4 hover:bg-grey-3 hover:text-white':
            isDisable,
        }
      )}
      type="submit"
      disabled={isDisable}
    >
      {props?.children}
    </button>
  );
};

PasswordForgot.FormContainer = twc.div`mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-[550px] bg-white px-6 py-6  shadow-lg ring-1 ring-grey-4 sm:rounded-lg md:px-12 md:py-12`;

PasswordForgot.Form = twc(Form)`space-y-6`;

PasswordForgot.EmailInput = twc.div``;

PasswordForgot.GoLoginLink = ({ href, children }: GoLoginLinkProps) => (
  <div className="text-sm mt-4 text-end">
    <a className="text-sm font-medium leading-6 text-grey-2" href={href}>
      {children}
    </a>
  </div>
);

interface ForgotPasswordProps {
  children: ReactNode;
}

interface SubmitButtonProps {
  children: ReactNode;
}

interface GoLoginLinkProps {
  href: string;
  children: ReactNode;
}
