export const MESSAGE_TYPES_FILTERS = [
	{
		label: 'Photos',
		value: 'image',

	},
	{
		label: 'Voice notes',
		value: 'voice',
	},
	{
		label: 'Video messages',
		value: 'video',
	},
	{
		label: 'Text messages',
		value: 'text',
	},
];
