import React from 'react';

export const HousingUnit = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M5.75741 8.77273V14.8333C5.75741 15.2936 6.13051 15.6667 6.59075 15.6667H13.4089C13.8692 15.6667 14.2423 15.2936 14.2423 14.8333V8.77273M4.1665 9.83333L9.99984 4L15.8332 9.83333"
      stroke="#9191A1"
      strokeWidth="1.66667"
      strokeLinecap="round"
    />
    <rect x="8.3335" y="9.83301" width="3.33333" height="2.5" fill="#9191A1" />
  </svg>
);

export const Facilities = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M9.16669 5.83333H7.50002V7.5H9.16669V5.83333Z" fill="#9191A1" />
    <path d="M12.5 5.83333H10.8334V7.5H12.5V5.83333Z" fill="#9191A1" />
    <path d="M12.5 9.1925H10.8334V10.8333H12.5V9.1925Z" fill="#9191A1" />
    <path d="M12.5 12.5H10.8334V14.1667H12.5V12.5Z" fill="#9191A1" />
    <path
      d="M9.16669 9.16667H7.50002V10.8333H9.16669V9.16667Z"
      fill="#9191A1"
    />
    <path d="M7.50002 12.5H9.16669V14.1667H7.50002V12.5Z" fill="#9191A1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16669 4.16667C4.16669 3.24619 4.91288 2.5 5.83335 2.5H14.1667C15.0872 2.5 15.8334 3.24619 15.8334 4.16667V15.8333C15.8334 16.7538 15.0872 17.5 14.1667 17.5H5.83335C4.91288 17.5 4.16669 16.7538 4.16669 15.8333V4.16667ZM5.83335 4.16667H14.1667V15.8333H5.83335L5.83335 4.16667Z"
      fill="#9191A1"
    />
  </svg>
);

export const All = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M15.8331 1.66699H7.49978C6.58062 1.66699 5.83312 2.41449 5.83312 3.33366V7.98866L1.91062 11.9112C1.79411 12.0277 1.71477 12.1762 1.68263 12.3378C1.65049 12.4994 1.66699 12.667 1.73005 12.8192C1.79311 12.9715 1.89989 13.1016 2.0369 13.1932C2.17391 13.2847 2.33499 13.3336 2.49978 13.3337V17.5003C2.49978 17.7213 2.58758 17.9333 2.74386 18.0896C2.90014 18.2459 3.1121 18.3337 3.33312 18.3337H16.6664C16.8875 18.3337 17.0994 18.2459 17.2557 18.0896C17.412 17.9333 17.4998 17.7213 17.4998 17.5003V3.33366C17.4998 2.41449 16.7523 1.66699 15.8331 1.66699ZM9.16645 16.667H4.16645V12.012L6.66645 9.51199L9.16645 12.012V16.667ZM15.8331 16.667H10.8331V13.3337C10.9981 13.334 11.1594 13.2854 11.2967 13.1939C11.434 13.1024 11.541 12.9721 11.6041 12.8197C11.6672 12.6673 11.6836 12.4996 11.6513 12.3378C11.6189 12.176 11.5392 12.0275 11.4223 11.9112L7.49978 7.98866V3.33366H15.8331V16.667Z"
      fill="#9191A1"
    />
    <path
      d="M9.16671 5H10.8334V6.66667H9.16671V5ZM12.5 5H14.1667V6.66667H12.5V5ZM12.5 8.35917H14.1667V10H12.5V8.35917ZM12.5 11.6667H14.1667V13.3333H12.5V11.6667ZM5.83337 12.5H7.50004V14.1667H5.83337V12.5Z"
      fill="#9191A1"
    />
  </svg>
);

export const Inmates = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.15139 13.4397C6.46839 12.7001 7.19572 12.2205 8.00047 12.2205H11.9994C12.8041 12.2205 13.5315 12.7001 13.8485 13.4397L14.497 14.9529C14.5872 15.1634 14.4328 15.3976 14.2037 15.3976H5.79611C5.56708 15.3976 5.41267 15.1634 5.50289 14.9529L6.15139 13.4397ZM11.9994 10.7426H8.00047C6.60452 10.7426 5.34289 11.5745 4.793 12.8576L4.14451 14.3707C3.63634 15.5564 4.50609 16.8755 5.79611 16.8755H14.2037C15.4938 16.8755 16.3635 15.5564 15.8553 14.3707L15.2068 12.8576C14.657 11.5745 13.3953 10.7426 11.9994 10.7426ZM12.0117 6.61494C12.0117 7.726 11.111 8.62668 9.99993 8.62668C8.88887 8.62668 7.98819 7.726 7.98819 6.61494C7.98819 5.50389 8.88887 4.6032 9.99993 4.6032C11.111 4.6032 12.0117 5.50389 12.0117 6.61494ZM9.99993 10.1046C11.9272 10.1046 13.4895 8.5422 13.4895 6.61494C13.4895 4.68768 11.9272 3.12533 9.99993 3.12533C8.07266 3.12533 6.51031 4.68768 6.51031 6.61494C6.51031 8.5422 8.07266 10.1046 9.99993 10.1046Z"
      fill="#9191A1"
      stroke="#9191A1"
      strokeWidth="0.208333"
    />
  </svg>
);
