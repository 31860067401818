import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EmptyImage = (props) => (
  <SvgIcon width="219" height="138" viewBox="0 0 219 138" fill="none" {...props}>
    <g clipPath="url(#clip0_6060_49796)">
      <path
        opacity="0.2"
        d="M144 47.8926V30.5232C144 23.5766 149.631 17.9453 156.578 17.9453H187.723"
        stroke="black"
        strokeWidth="0.598945"
      />
      <path
        opacity="0.2"
        d="M65.2734 105.576L65.2734 122.946C65.2734 129.892 59.6422 135.523 52.6956 135.523L21.5505 135.523"
        stroke="black"
        strokeWidth="0.598945"
      />
      <path
        d="M36.9998 92.6217V13.1345H136.066C165.828 13.1345 189.958 37.2603 189.958 67.0257V137.895H82.2685C57.2656 137.89 36.9998 117.625 36.9998 92.6217Z"
        fill="#E1E3E3"
      />
      <path
        d="M159.035 122.607C159.06 121.917 159.082 121.184 159.234 120.51C159.37 119.891 159.51 119.273 159.637 118.654C159.857 117.565 160.128 116.527 160.696 115.565C161.272 114.595 161.98 113.62 163.005 113.087C163.797 112.671 164.857 112.608 165.649 113.061C166.395 113.493 166.789 114.315 167.272 114.989C167.899 115.858 169.043 116.489 170.034 115.798C170.763 115.29 171.144 114.366 171.589 113.629C171.92 113.078 172.174 112.451 172.411 111.858C172.67 111.218 172.954 110.519 173.047 109.832C173.094 109.477 173.127 109.121 173.149 108.765C172.221 108.104 171.089 107.71 169.861 107.71H161.721C158.586 107.71 156.048 110.252 156.048 113.383V120.251C156.048 122.442 157.294 124.344 159.115 125.289C159.026 124.399 158.997 123.501 159.035 122.607Z"
        fill="#C3CACE"
      />
      <path
        d="M173.501 109.027C173.48 109.447 173.438 109.871 173.336 110.269C172.997 111.608 172.433 112.925 171.739 114.116C171.306 114.858 170.904 115.709 170.15 116.184C169.285 116.73 168.239 116.476 167.51 115.832C166.798 115.201 166.476 114.252 165.769 113.616C165.069 112.989 163.955 113.002 163.159 113.43C162.112 113.989 161.409 115.04 160.837 116.048C160.282 117.031 160.095 118.196 159.875 119.29C159.76 119.862 159.587 120.429 159.519 121.006C159.438 121.65 159.392 122.298 159.375 122.942C159.354 123.785 159.392 124.628 159.485 125.467C160.171 125.764 160.93 125.929 161.726 125.929H163.684C163.731 125.128 163.828 124.332 164.065 123.556C164.383 122.51 164.883 121.535 165.942 121.09C166.862 120.705 167.828 121.048 168.396 121.844C168.679 122.247 168.862 122.709 169.09 123.141C169.374 123.671 169.781 124.09 170.188 124.527C170.569 124.933 170.963 125.332 171.357 125.721C173.768 125.069 175.543 122.87 175.543 120.251V113.383C175.543 111.633 174.747 110.07 173.501 109.027Z"
        fill="#C3CACE"
      />
      <path
        d="M169.784 124.721C169.424 124.331 169.056 123.95 168.793 123.484C168.577 123.103 168.412 122.696 168.191 122.319C167.958 121.921 167.67 121.637 167.246 121.446C166.801 121.251 166.297 121.361 165.886 121.59C164.907 122.132 164.539 123.26 164.314 124.293C164.196 124.836 164.136 125.382 164.107 125.933H169.869C170.212 125.933 170.547 125.899 170.873 125.84C170.505 125.471 170.136 125.103 169.784 124.721Z"
        fill="#C3CACE"
      />
      <path
        d="M176.038 76.8092C177.203 76.8092 178.148 75.8645 178.148 74.6992C178.148 73.5338 177.203 72.5891 176.038 72.5891C174.872 72.5891 173.928 73.5338 173.928 74.6992C173.928 75.8645 174.872 76.8092 176.038 76.8092Z"
        fill="white"
      />
      <path
        d="M167.581 76.8092C168.747 76.8092 169.691 75.8645 169.691 74.6992C169.691 73.5338 168.747 72.5891 167.581 72.5891C166.416 72.5891 165.471 73.5338 165.471 74.6992C165.471 75.8645 166.416 76.8092 167.581 76.8092Z"
        fill="white"
      />
      <path
        d="M159.123 76.8092C160.289 76.8092 161.234 75.8645 161.234 74.6992C161.234 73.5338 160.289 72.5891 159.123 72.5891C157.958 72.5891 157.013 73.5338 157.013 74.6992C157.013 75.8645 157.958 76.8092 159.123 76.8092Z"
        fill="white"
      />
      <path
        d="M150.672 76.8092C151.837 76.8092 152.782 75.8645 152.782 74.6992C152.782 73.5338 151.837 72.5891 150.672 72.5891C149.506 72.5891 148.562 73.5338 148.562 74.6992C148.562 75.8645 149.506 76.8092 150.672 76.8092Z"
        fill="white"
      />
      <path
        d="M142.214 76.8092C143.379 76.8092 144.324 75.8645 144.324 74.6992C144.324 73.5338 143.379 72.5891 142.214 72.5891C141.048 72.5891 140.104 73.5338 140.104 74.6992C140.104 75.8645 141.048 76.8092 142.214 76.8092Z"
        fill="white"
      />
      <path
        d="M176.038 68.3561C177.203 68.3561 178.148 67.4114 178.148 66.246C178.148 65.0807 177.203 64.136 176.038 64.136C174.872 64.136 173.928 65.0807 173.928 66.246C173.928 67.4114 174.872 68.3561 176.038 68.3561Z"
        fill="white"
      />
      <path
        d="M167.581 68.3561C168.747 68.3561 169.691 67.4114 169.691 66.246C169.691 65.0807 168.747 64.136 167.581 64.136C166.416 64.136 165.471 65.0807 165.471 66.246C165.471 67.4114 166.416 68.3561 167.581 68.3561Z"
        fill="white"
      />
      <path
        d="M159.123 68.3561C160.289 68.3561 161.234 67.4114 161.234 66.246C161.234 65.0807 160.289 64.136 159.123 64.136C157.958 64.136 157.013 65.0807 157.013 66.246C157.013 67.4114 157.958 68.3561 159.123 68.3561Z"
        fill="white"
      />
      <path
        d="M150.672 68.3561C151.837 68.3561 152.782 67.4114 152.782 66.246C152.782 65.0807 151.837 64.136 150.672 64.136C149.506 64.136 148.562 65.0807 148.562 66.246C148.562 67.4114 149.506 68.3561 150.672 68.3561Z"
        fill="white"
      />
      <path
        d="M142.214 68.3561C143.379 68.3561 144.324 67.4114 144.324 66.246C144.324 65.0807 143.379 64.136 142.214 64.136C141.048 64.136 140.104 65.0807 140.104 66.246C140.104 67.4114 141.048 68.3561 142.214 68.3561Z"
        fill="white"
      />
      <path
        d="M176.038 59.8991C177.203 59.8991 178.148 58.9544 178.148 57.789C178.148 56.6237 177.203 55.679 176.038 55.679C174.872 55.679 173.928 56.6237 173.928 57.789C173.928 58.9544 174.872 59.8991 176.038 59.8991Z"
        fill="white"
      />
      <path
        d="M167.581 59.8991C168.747 59.8991 169.691 58.9544 169.691 57.789C169.691 56.6237 168.747 55.679 167.581 55.679C166.416 55.679 165.471 56.6237 165.471 57.789C165.471 58.9544 166.416 59.8991 167.581 59.8991Z"
        fill="white"
      />
      <path
        d="M159.123 59.8991C160.289 59.8991 161.234 58.9544 161.234 57.789C161.234 56.6237 160.289 55.679 159.123 55.679C157.958 55.679 157.013 56.6237 157.013 57.789C157.013 58.9544 157.958 59.8991 159.123 59.8991Z"
        fill="white"
      />
      <path
        d="M150.672 59.8991C151.837 59.8991 152.782 58.9544 152.782 57.789C152.782 56.6237 151.837 55.679 150.672 55.679C149.506 55.679 148.562 56.6237 148.562 57.789C148.562 58.9544 149.506 59.8991 150.672 59.8991Z"
        fill="white"
      />
      <path
        d="M142.214 59.8991C143.379 59.8991 144.324 58.9544 144.324 57.789C144.324 56.6237 143.379 55.679 142.214 55.679C141.048 55.679 140.104 56.6237 140.104 57.789C140.104 58.9544 141.048 59.8991 142.214 59.8991Z"
        fill="white"
      />
      <path
        d="M176.038 51.4418C177.203 51.4418 178.148 50.4971 178.148 49.3317C178.148 48.1664 177.203 47.2217 176.038 47.2217C174.872 47.2217 173.928 48.1664 173.928 49.3317C173.928 50.4971 174.872 51.4418 176.038 51.4418Z"
        fill="white"
      />
      <path
        d="M167.581 51.4418C168.747 51.4418 169.691 50.4971 169.691 49.3317C169.691 48.1664 168.747 47.2217 167.581 47.2217C166.416 47.2217 165.471 48.1664 165.471 49.3317C165.471 50.4971 166.416 51.4418 167.581 51.4418Z"
        fill="white"
      />
      <path
        d="M159.123 51.4418C160.289 51.4418 161.234 50.4971 161.234 49.3317C161.234 48.1664 160.289 47.2217 159.123 47.2217C157.958 47.2217 157.013 48.1664 157.013 49.3317C157.013 50.4971 157.958 51.4418 159.123 51.4418Z"
        fill="white"
      />
      <path
        d="M150.672 51.4418C151.837 51.4418 152.782 50.4971 152.782 49.3317C152.782 48.1664 151.837 47.2217 150.672 47.2217C149.506 47.2217 148.562 48.1664 148.562 49.3317C148.562 50.4971 149.506 51.4418 150.672 51.4418Z"
        fill="white"
      />
      <path
        d="M142.215 51.442C143.38 51.442 144.325 50.4973 144.325 49.332C144.325 48.1666 143.38 47.2219 142.215 47.2219C141.049 47.2219 140.104 48.1666 140.104 49.332C140.104 50.4973 141.049 51.442 142.215 51.442Z"
        fill="white"
      />
      <path
        d="M95.1014 115.79C93.4023 124.963 90.4668 137.924 90.4668 137.924H143.854L139.641 109.74L95.1014 115.79Z"
        fill="black"
      />
      <path
        d="M122.271 49.1411C125.712 49.4419 136.787 52.3104 138.372 54.7467C141.389 59.3821 144.647 76.8218 143.261 89.7406C143.261 89.7406 140.783 90.8846 137.435 90.3084C138.414 95.4733 141.787 113.714 141.787 113.714C141.787 113.714 134.186 119.349 116.699 120.277C104.967 120.9 91.5015 116.489 91.5015 116.489C91.5015 116.489 94.1497 88.0331 93.3489 84.9104C92.5481 81.7876 85.1544 76.9659 81.269 76.3007C84.324 72.7542 88.4254 59.5134 92.2642 56.7043C96.0988 53.8951 105.471 51.713 105.471 51.713L122.271 49.1411Z"
        fill="white"
      />
      <path
        d="M119.728 78.0758C118.876 79.0843 134.18 90.1684 137.468 90.4777C140.756 90.787 142.54 91.0412 143.023 89.7235C143.464 88.5159 139.739 84.6221 132.587 80.8342C132.583 80.8342 121.342 76.1565 119.728 78.0758Z"
        fill="black"
      />
      <path
        d="M88.9758 83.4697C84.3743 79.936 81.2516 77.2878 81.6669 76.1607C82.0821 75.0337 90.8909 74.0591 95.4119 78.5843L88.9758 83.4697Z"
        fill="#0F0F0F"
      />
      <path
        d="M119.826 78.076C121.516 74.949 124.211 70.5043 125.554 65.0767"
        stroke="#0F0F0F"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M55.6726 75.5125C54.0625 74.949 50.9652 72.8389 50.2661 70.3391C49.567 67.8392 46.9358 64.8394 48.8679 64.8394C50.8 64.8394 53.5286 66.9367 53.5286 66.9367L55.6726 75.5125Z"
        fill="#A86658"
      />
      <path
        d="M84.7548 77.0932C80.4457 83.0293 67.2472 99.9309 72.8868 103.528C78.5263 107.125 83.704 105.74 99.6735 87.8977C100.792 79.915 88.53 71.8943 84.7548 77.0932Z"
        fill="#A86658"
      />
      <path
        d="M42.8642 67.8099L37.8475 46.2348C37.4026 44.3112 38.8602 42.4766 40.8304 42.4766H69.1043C70.5152 42.4766 71.744 43.4384 72.0787 44.8112L77.3708 66.382C77.8453 68.3141 76.3836 70.1785 74.3964 70.1785H45.8513C44.4234 70.1785 43.1862 69.1955 42.8642 67.8099Z"
        fill="#F5F5F5"
      />
      <path
        d="M55.0623 75.7672C59.4518 83.9913 67.82 104.944 76.8153 104.944C85.8106 104.944 88.2342 93.7662 85.6242 90.2791C85.6242 90.2791 68.6886 77.2883 67.0404 74.6655C65.3922 72.0428 55.0623 75.7672 55.0623 75.7672Z"
        fill="#A86658"
      />
      <path
        d="M68.0063 42.4763C69.4173 42.4763 70.646 43.4381 70.9808 44.8109L76.2728 66.3818C76.7474 68.3139 75.2856 70.1782 73.2984 70.1782"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M67.5567 75.2797C65.095 70.8138 63.3875 62.4371 63.2222 60.3567C63.1078 58.9077 61.1291 57.7933 59.7097 58.95C58.964 57.9247 57.6547 58.234 56.9768 59.1577C55.8964 60.6364 55.5913 65.2548 55.6718 66.4708C54.1931 63.4583 54.2693 59.3144 53.0618 58.7848C51.4136 58.0603 49.8458 59.1577 50.778 63.1659C51.7102 67.1742 51.7779 72.5044 55.4388 76.1652C59.0996 79.826 67.5567 75.2797 67.5567 75.2797Z"
        fill="#A86658"
      />
      <path
        d="M59.7098 58.95C59.0488 62.3057 59.1081 67.0597 59.3539 67.9622"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M51.1809 65.3904C51.4817 66.7123 52.3037 70.0978 52.7317 70.9197"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M76.8958 83.042C78.5016 84.3131 83.12 89.0968 83.12 89.0968"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M84.137 77.4106C87.0775 78.0716 92.1069 80.3299 93.2975 82.1561C94.4881 83.9823 93.9754 77.1776 93.9754 77.1776C93.9754 77.1776 89.5985 74.8175 86.6156 75.4404C85.8403 75.6014 84.137 77.4106 84.137 77.4106Z"
        fill="#171717"
      />
      <path
        opacity="0.3412"
        d="M56.5499 57.7857C57.6581 57.3429 58.0672 55.7596 57.4637 54.2493C56.8602 52.7391 55.4725 51.8739 54.3643 52.3167C53.2561 52.7596 52.8469 54.3429 53.4505 55.8532C54.054 57.3634 55.4417 58.2286 56.5499 57.7857Z"
        fill="black"
      />
      <path
        d="M92.9758 74.928C92.9758 79.9828 92.4843 104.901 92.3614 107.634C92.2385 110.367 101.056 108.943 101.056 108.943L109.907 86.5502L92.9758 74.928Z"
        fill="white"
      />
      <path
        d="M122.992 77.792C121.132 81.0672 118.831 85.0543 117.733 88.3253C116.632 91.5921 110.2 108.608 115.632 110.972C123.424 114.366 135.466 97.8926 140.105 85.7958C138.944 84.2281 132.618 80.8893 132.618 80.8893C132.618 80.8893 127.593 78.5886 122.992 77.792Z"
        fill="#A86658"
      />
      <path
        d="M96.9004 76.1775C98.1927 80.9145 109.768 111.985 117.289 111.417C124.509 110.875 120.03 90.7233 120.03 90.7233L104.548 73.1353L96.9004 76.1775Z"
        fill="#A86658"
      />
      <path
        d="M105.192 73.9194C104.45 71.983 105.4 68.051 104.844 66.0173C104.018 62.9962 101.908 62.1912 101.747 60.6489C101.658 59.8184 102.048 57.8863 102.273 56.7254C102.476 55.6704 99.1711 53.9374 98.2982 56.1534C97.4254 58.3694 95.506 56.4584 95.506 56.4584C95.506 56.4584 94.2179 55.717 92.7985 57.2889C91.3791 58.8609 91.5274 60.1828 91.8367 61.0429C91.1503 61.6743 89.9512 63.547 90.5148 65.1021C90.1165 67.0214 89.286 67.9239 91.3113 70.3857C93.3409 72.8474 96.4594 75.2117 97.4678 77.3683C98.472 79.5292 105.192 73.9194 105.192 73.9194Z"
        fill="#A86658"
      />
      <path
        d="M90.6465 65.1104C91.3244 66.1611 92.3837 67.5043 92.8879 67.8941"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M100.06 62.5343C99.6366 61.975 99.3994 60.9708 98.5181 60.3564"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
      />
      <path
        d="M91.8232 60.9282C92.463 61.9833 93.4291 63.2289 93.9714 63.7755"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M117.852 88.1726C119.394 90.0666 122.453 94.435 122.453 94.435"
        stroke="#0A0A0A"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.302"
        d="M122.449 78.8129C126.699 80.1391 136.762 86.821 138.105 90.3038C139.473 87.2277 139.948 86.5922 140.105 85.6897C133.906 80.6518 128.864 78.9951 122.996 77.7706C122.707 77.7113 122.449 78.8129 122.449 78.8129Z"
        fill="#0F0F0F"
      />
      <path
        d="M109.45 43.7137C107.247 43.5823 104.141 43.2857 103.209 41.6036C103.209 39.5317 101.933 29.3882 101.933 29.3882L98.8571 22.9309L97.0564 19.8548L98.7682 14.1432L105.314 11.0671L114.894 13.9229L119.903 23.5876L122.801 28.5069L122.186 30.6169C122.186 30.6169 121.123 33.1507 118.979 33.299C118.212 35.8116 109.45 43.7137 109.45 43.7137Z"
        fill="#A86658"
      />
      <path
        d="M105.666 42.5401L104.844 51.904C104.844 51.904 109.242 53.4421 114.674 52.7514C120.101 52.0608 123.504 49.5016 123.504 49.5016L119.699 32.2483L109.877 42.6121L105.666 42.5401Z"
        fill="#A86658"
      />
      <path
        d="M93.7472 18.3423C93.7472 20.5964 91.5482 26.6893 93.9548 34.0787C96.0141 40.3962 100.531 42.4384 102.209 42.5401C103.446 42.6164 102.509 29.1553 102.509 29.1553L97.3233 20.7701L93.7472 18.3423Z"
        fill="#A86658"
      />
      <path
        d="M107.779 23.0621C107.436 24.8077 108.703 24.1552 109.758 24.5578C110.813 24.9603 112.093 25.7102 112.482 24.9518C112.872 24.1934 111.58 23.2994 110.855 22.8884C110.131 22.4774 107.932 22.2782 107.779 23.0621Z"
        fill="#0F0E0E"
      />
      <path
        d="M105.44 26.1211C105.139 27.1549 104.648 28.8879 104.648 28.8879C104.648 28.8879 102.601 28.9091 102.512 30.4598C102.41 32.214 104.885 32.858 107.169 31.3369"
        stroke="#3D2B27"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M105.665 36.6204C105.665 36.6204 106.458 36.7433 108.165 35.9382"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M117.546 31.0867C117.838 29.6673 119.275 28.6038 119.991 28.5657"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M110.602 27.9768C111.011 27.9768 111.343 27.5974 111.343 27.1294C111.343 26.6614 111.011 26.282 110.602 26.282C110.192 26.282 109.86 26.6614 109.86 27.1294C109.86 27.5974 110.192 27.9768 110.602 27.9768Z"
        fill="black"
      />
      <path
        d="M100.543 23.4516C101.318 22.8203 102.462 22.4643 102.661 23.0491C102.86 23.6338 102.721 24.5447 101.399 24.9388C101.077 24.2608 100.543 23.4516 100.543 23.4516Z"
        fill="black"
      />
      <path
        d="M97.2638 28.8795C97.8207 28.8795 98.2722 28.299 98.2722 27.5829C98.2722 26.8669 97.8207 26.2864 97.2638 26.2864C96.7069 26.2864 96.2554 26.8669 96.2554 27.5829C96.2554 28.299 96.7069 28.8795 97.2638 28.8795Z"
        fill="black"
      />
      <path
        d="M92.3609 23.9007C92.5304 25.265 94.0897 23.7142 95.4286 22.9516C96.9454 22.083 97.6827 21.2567 97.6827 21.2567L96.2506 19.5789C96.2548 19.5789 92.1025 21.8203 92.3609 23.9007Z"
        fill="black"
      />
      <path
        d="M100.577 29.9258C100.254 30.7817 100.331 31.7943 100.708 32.2519"
        stroke="#3D2B27"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M118.122 27.1294C114.906 26.9006 113.355 21.6001 113.906 19.1722C111.279 19.0748 103.106 17.2147 101.903 14.0623C99.3942 15.4139 98.2078 17.113 97.4875 19.0451C96.9325 19.3459 94.9156 17.6723 94.2377 16.9944C92.5471 15.3038 93.153 10.071 98.2714 10.071C96.8096 6.01615 97.8816 3.7578 101.009 2.03755C104.263 0.241038 109.288 2.67311 109.678 5.74921C109.678 5.74921 111.788 0.98676 116.631 2.83835C120.414 4.28319 119.601 8.21942 119.27 9.17699C121.279 8.15163 122.931 10.2786 121.821 12.4226C126.575 12.2362 130.083 17.9477 124.728 21.8289C128.761 23.0195 129.049 25.5109 128.677 27.587C128.126 30.6928 123.245 32.5571 122.012 31.2436C123.223 27.2226 121.007 26.6083 119.991 26.6083C118.94 26.5998 118.533 27.1591 118.122 27.1294Z"
        fill="black"
      />
      <path
        d="M101.869 13.647C101.869 15.1723 101.319 16.6638 105.662 18.3713C108.945 19.6594 113.886 19.5111 115.47 18.7442"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
      />
      <path
        d="M118.694 9.63015C120.134 7.90566 123.388 8.75731 123.287 11.0665C123.185 13.3799 122.655 13.6469 122.015 14.4053C121.376 15.1637 124.405 21.8625 124.405 21.8625C124.405 21.8625 131.638 21.9007 129.286 29.1545"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M109.22 7.21949C110.614 3.49512 106.5 -2.25457 100.543 0.923226"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.3216"
        d="M105.601 43.192C107.288 43.4378 109.36 43.6369 111.682 42.4802C110.775 44.8233 106.398 47.069 105.271 47.2257L105.601 43.192Z"
        fill="black"
      />
      <path
        d="M93.5557 22.7145C91.8312 23.2992 87.8103 20.1256 90.3864 17.075C92.9625 14.0243 95.2548 17.9266 95.2548 17.9266L97.1488 20.1299L93.5557 22.7145Z"
        fill="black"
      />
      <path
        d="M100.297 37.2855C101.115 37.5143 102.039 37.0312 102.039 37.0312"
        stroke="black"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M103.476 29.248C101.484 18.8587 94.349 11.4905 87.5401 12.7955C80.7311 14.1005 76.8288 23.5831 78.8244 33.9766C80.8159 44.3658 87.9511 51.7341 94.76 50.4291C101.569 49.1241 105.471 39.6415 103.476 29.248ZM94.0016 48.336C88.4171 49.4079 82.4683 42.8617 80.7142 33.7181C78.96 24.5745 82.0658 16.2953 87.6502 15.2234C93.2347 14.1514 99.1835 20.6976 100.938 29.8412C102.692 38.9848 99.5818 47.264 94.0016 48.336Z"
        fill="black"
      />
      <path
        d="M93.353 49.7805C93.9462 52.2253 95.0945 58.5089 95.0945 58.5089C95.0945 58.5089 96.0436 59.386 96.9037 59.763C98.2256 60.3435 99.0773 61.246 99.0773 61.246L97.5096 48.8484L93.353 49.7805Z"
        fill="black"
      />
      <path
        d="M86.069 18.562C83.065 21.1593 81.1541 27.3412 82.4887 32.5613"
        stroke="white"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M100.513 38.5564C100.013 41.1791 98.9577 44.0307 97.4917 45.4077"
        stroke="white"
        strokeWidth="0.423706"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M187 30.7527C187 41.3379 195.581 49.9189 206.166 49.9189H218.744V36.7422C218.744 26.1569 210.163 17.5759 199.578 17.5759H187V30.7527Z"
        fill="#E1E3E3"
      />
      <path
        d="M31.7441 122.742C31.7441 112.157 23.1631 103.576 12.5779 103.576L7.82013e-05 103.576L7.93532e-05 116.753C8.02786e-05 127.338 8.58109 135.919 19.1663 135.919L31.7441 135.919L31.7441 122.742Z"
        fill="#E1E3E3"
      />
    </g>
    <defs>
      <clipPath id="clip0_6060_49796">
        <rect width="218.744" height="137.924" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default EmptyImage;
