export default function KiwiCustomPie (props) {
	const { cx, cy, outerRadius, innerRadius, startAngle, endAngle, fill } = props;

	const startAngleRad = Math.degToRad(startAngle);
	const endAngleRad = Math.degToRad(endAngle);

	const outerX1 = cx + outerRadius * Math.cos(startAngleRad);
	const outerY1 = cy + outerRadius * Math.sin(startAngleRad);
	const outerX2 = cx + outerRadius * Math.cos(endAngleRad);
	const outerY2 = cy + outerRadius * Math.sin(endAngleRad);

	const innerX1 = cx + innerRadius * Math.cos(startAngleRad);
	const innerY1 = cy + innerRadius * Math.sin(startAngleRad);
	const innerX2 = cx + innerRadius * Math.cos(endAngleRad);
	const innerY2 = cy + innerRadius * Math.sin(endAngleRad);

	const path = `M${outerX1},${outerY1} L${innerX1},${innerY1} A${innerRadius},${innerRadius} 0 0,1 ${innerX2},${innerY2} L${outerX2},${outerY2} A${outerRadius},${outerRadius} 0 0,0 ${outerX1},${outerY1}`;

	return <path d={path} fill={fill} />;
};
