export const BOOKMARK_TYPE = {
  CHAT: 'channel',
  MESSAGE: 'message',
  INMATE: 'inmate',
  FNF: 'fnf',
  SEARCH: 'search',
  PHOTO: 'photo',
  AUDIO: 'audio',
  VIDEO: 'video',
};
