import React from 'react';

import Modal, {
  ModalActions,
  ModalDescription,
  ModalTitle,
} from 'shared/modal';
import Button from 'shared/button';
import ExitModal from './ExitModal';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';

const DeleteWrapper = ({ Action, index }) => {
  const { watch } = useFormContext();
  const { remove } = useFieldArray({ name: 'questions' });
  const questions = watch('questions');

  const [deleteModal, toggleDeleteModal] = useToggler();
  const [closeModal, toggleCloseModal] = useToggler();

  const handleClickDelete = () => {
    if (questions.length === 1) {
      toggleCloseModal(true);
    } else {
      toggleDeleteModal(true);
    }
  };

  const handleClose = () => {
    toggleDeleteModal(false);
    toggleCloseModal(false);
  };

  return (
    <>
      {React.cloneElement(Action, { onClick: handleClickDelete })}
      <Modal open={deleteModal} onClose={toggleDeleteModal}>
        <ModalTitle text="Are you sure you want to delete?" />
        <ModalDescription text="Are you sure you want to delete this question?" />
        <ModalActions>
          <Button display="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            display="primary"
            onClick={() => {
              remove(index);
              handleClose();
            }}
          >
            Delete
          </Button>
        </ModalActions>
      </Modal>
      <ExitModal open={closeModal} onClose={handleClose} />
    </>
  );
};

export default DeleteWrapper;
