import React, { useEffect, useMemo, useState } from 'react';
import styles from './FilesList.module.scss';
import { Box } from '@mui/material';
import NoResult from 'shared/no-result';
import { useToggler } from 'hooks';
import { useDocumentsContext } from 'modules/documents/context';
import { useGraphql } from 'context';
import { from } from 'rxjs';
import DeleteFileModal from './DeleteModal';
import InmateViewedModal from './InmateViewedModal';
import FileFooter from './FileFooter';
import FileContent from './FileContent';
import { sortReducer } from 'hooks/useSort';

const FileItem = (document) => {
  const { file, displayName, id, facilityId, fileType } = document;
  const { hasPermission, showFacility } = useDocumentsContext();
  const [deleteModal, toggleDeleteModal] = useToggler();
  const [viewsModal, toggleViewsModal] = useToggler();
  const [fileLink, setFileLink] = useState();
  const api = useGraphql();

  useEffect(() => {
    const fetchFileLink = from(api.getDocuments(file));
    fetchFileLink.subscribe((data) => setFileLink(data));
  }, []);
  useEffect(() => {
    const fetchFileLink = from(api.getDocuments(file));
    fetchFileLink.subscribe((data) => setFileLink(data));
  }, []);

  return (
    <div className={styles.file}>
      <DeleteFileModal
        open={deleteModal}
        handleClose={toggleDeleteModal}
        documentId={id}
        documentDisplayName={displayName}
        facilityId={facilityId}
      />
      <InmateViewedModal
        open={viewsModal}
        handleClose={toggleViewsModal}
        api={api}
        documentId={id}
      />
      <FileContent
        toggleViewsModal={toggleViewsModal}
        toggleDeleteModal={toggleDeleteModal}
        hasPermission={hasPermission}
        fileType={fileType}
        fileLink={fileLink}
      />
      <FileFooter showFacility={showFacility} {...document} />
    </div>
  );
};

const FilesList = () => {
  const { shownFiles } = useDocumentsContext();

  const sortedFiles = useMemo(() => {
    return sortReducer(shownFiles, {
      type: 'date',
      descendent: false,
      key: 'createdAt',
    });
  }, [shownFiles]);

  return (
    <div className={styles.filesList}>
      <div className={styles.filesList__list}>
        {shownFiles?.length ? (
          sortedFiles.map((item) => <FileItem key={item.id} {...item} />)
        ) : (
          <Box sx={{ height: '90%', width: '100%' }}>
            <NoResult>
              <p>There are no files.</p>
            </NoResult>
          </Box>
        )}
      </div>
    </div>
  );
};

export default FilesList;
