import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

export function getCacheProps(params) {
  const { client, sourceKey, sourceName } = params;

  const queryData = client.getQueryData(sourceKey);

  const updateQueryData = (newData) => {
    client.setQueryData(sourceKey, newData);
  };
  const resourceProps = {
    [`${sourceName}Data`]: queryData,
    [`set${toCapital(sourceName)}Data`]: updateQueryData,
  };

  return { ...resourceProps };
}

export function getServerProps(params) {
  const {
    fetchFn,
    sourceKey,
    enableFetch = true,
    client,
    sourceName,
    pagination = false,
  } = params;

  let queryInfo = null;

  if (pagination) {
    queryInfo = useInfiniteQuery({
      enable: enableFetch,
      queryKey: sourceKey,
      queryFn: fetchFn,
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    });
  } else {
    queryInfo = useQuery({
      enable: enableFetch,
      queryKey: sourceKey,
      queryFn: fetchFn,
    });
  }

  const updateQueryData = (newData) => {
    client.setQueryData(key, newData);
  };

  const resourceProps = {
    [`${sourceName}Query`]: queryInfo,
    [`set${toCapital(sourceName)}Data`]: updateQueryData,
  };

  return { ...resourceProps };
}

export const childProps = {
  server: getServerProps,
  cache: getCacheProps,
};

const toCapital = (str) => str[0].toUpperCase() + str.slice(1);
