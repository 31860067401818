const usePermissionsPayload = (obj) => {
  const entries = Object.entries(obj);
  const permissionsLevel = removeDuplicates(entries)
    .filter(([key, value]) => {
      return value !== 'CUSTOM_ACCESS' && value && !hasActiveDependencies(key);
    })
    .map(([key, value]) => {
      return parsePermission(key, value);
    });

  const permissionPayload = removeChildren(permissionsLevel);
  return permissionPayload;
};

export default usePermissionsPayload;

const parsePermission = (key, value) => {
  const uppercaseValue = value === 'VIEW_ONLY' ? '#READ' : '';
  return `${key}${uppercaseValue}`.trim();
};

const removeDuplicates = (arr) => {
  return arr.filter((item, index) => {
    return arr.indexOf(item) === index;
  });
};

const removeChildren = (arr) => {
  let permissions = [];
  arr.map((item) => {
    const splited = item.split('#');
    const root = splited[0];
    const child = splited[1] ? `#${splited[1]}` : '';

    if (!item.includes('#')) {
      permissions = [...permissions, item];
    }
    if (
      !permissions.includes(root) &&
      !permissions.includes(`${root}${child}`)
    ) {
      permissions = [...permissions, item];
    }
  });
  return permissions;
};

const hasActiveDependencies = (permission) => {
  return permission.includes('#hasActiveDependency');
};
