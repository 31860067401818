import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';

import { QUERY_KEYS } from 'utils';

export default function useFilteredChannelMessages( payload ) {
	const { filtersAreActive, channelId, mediaTypes } = payload;
	const api = useGraphql();

	function getFilteredChannelMessages( { pageParam = undefined } ) {
		return api.controller.search.channelMessages({
			channelId,
			limit: 20,
			filters: [{ term: 'mediaType', value: mediaTypes }],
			sortBy: [{ by: 'clientKey', order: 'desc' }],
			nextPage: pageParam,
			rearrangeResult: false,
		});
	}

	const filteredChannelMessagesQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.CHANNEL_FILTERED_MESSAGES, payload],
		queryFn: getFilteredChannelMessages,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
		enabled: filtersAreActive,
	});

	const hasMessages = filteredChannelMessagesQuery?.data ? !!filteredChannelMessagesQuery?.data?.pages[0]?.total : false;

	return {
		data: hasMessages ? filteredChannelMessagesQuery?.data?.pages?.flatMap(( page ) => page?.items?.message).sort(function( a, b ) {
			return parseInt(b.clientKey) - parseInt(a.clientKey);
		}) : [],
		isFetching: filteredChannelMessagesQuery.isFetching,
		hasNextPage: filteredChannelMessagesQuery.hasNextPage,
		fetchNextPage: filteredChannelMessagesQuery.fetchNextPage,
	};
}


