import React from 'react';

import { SvgIcon } from '@mui/material';

const PdfIcon = () => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75893 3.42519C7.61847 2.56565 8.78426 2.08276 9.99984 2.08276H21.6665C21.998 2.08276 22.316 2.21446 22.5504 2.44888L34.2171 14.1155C34.4515 14.35 34.5832 14.6679 34.5832 14.9994V33.3328C34.5832 34.5483 34.1003 35.7141 33.2407 36.5737C32.3812 37.4332 31.2154 37.9161 29.9998 37.9161H9.99984C8.78426 37.9161 7.61847 37.4332 6.75893 36.5737C5.89939 35.7141 5.4165 34.5483 5.4165 33.3328V6.6661C5.4165 5.45052 5.89939 4.28473 6.75893 3.42519ZM9.99984 4.58276C9.4473 4.58276 8.9174 4.80226 8.5267 5.19296C8.136 5.58366 7.9165 6.11356 7.9165 6.6661V33.3328C7.9165 33.8853 8.136 34.4152 8.5267 34.8059C8.9174 35.1966 9.4473 35.4161 9.99984 35.4161H29.9998C30.5524 35.4161 31.0823 35.1966 31.473 34.8059C31.8637 34.4152 32.0832 33.8853 32.0832 33.3328V15.5172L21.1487 4.58276H9.99984Z"
      fill="currentColor"
    />
    <path
      d="M11.6665 29.4442V22.4442H14.2565C14.8165 22.4442 15.2798 22.5375 15.6465 22.7242C16.0132 22.9109 16.2865 23.1675 16.4665 23.4942C16.6465 23.8209 16.7365 24.1875 16.7365 24.5942C16.7365 24.9809 16.6498 25.3375 16.4765 25.6642C16.3032 25.9842 16.0332 26.2442 15.6665 26.4442C15.2998 26.6375 14.8298 26.7342 14.2565 26.7342H12.9465V29.4442H11.6665ZM12.9465 25.6942H14.1765C14.6232 25.6942 14.9432 25.5975 15.1365 25.4042C15.3365 25.2042 15.4365 24.9342 15.4365 24.5942C15.4365 24.2475 15.3365 23.9775 15.1365 23.7842C14.9432 23.5842 14.6232 23.4842 14.1765 23.4842H12.9465V25.6942Z"
      fill="currentColor"
    />
    <path
      d="M17.7407 29.4442V22.4442H20.1307C20.9507 22.4442 21.6241 22.5909 22.1507 22.8842C22.6841 23.1709 23.0774 23.5775 23.3307 24.1042C23.5907 24.6242 23.7207 25.2375 23.7207 25.9442C23.7207 26.6509 23.5907 27.2675 23.3307 27.7942C23.0774 28.3142 22.6841 28.7209 22.1507 29.0142C21.6241 29.3009 20.9507 29.4442 20.1307 29.4442H17.7407ZM19.0207 28.3442H20.0707C20.6574 28.3442 21.1207 28.2509 21.4607 28.0642C21.8007 27.8709 22.0441 27.5975 22.1907 27.2442C22.3374 26.8842 22.4107 26.4509 22.4107 25.9442C22.4107 25.4442 22.3374 25.0142 22.1907 24.6542C22.0441 24.2942 21.8007 24.0175 21.4607 23.8242C21.1207 23.6309 20.6574 23.5342 20.0707 23.5342H19.0207V28.3442Z"
      fill="currentColor"
    />
    <path
      d="M24.8501 29.4442V22.4442H29.3701V23.4742H26.1301V25.4342H28.7501V26.4442H26.1301V29.4442H24.8501Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6665 2.08276C22.3569 2.08276 22.9165 2.64241 22.9165 3.33276V13.7494H33.3332C34.0235 13.7494 34.5832 14.3091 34.5832 14.9994C34.5832 15.6898 34.0235 16.2494 33.3332 16.2494H21.6665C20.9761 16.2494 20.4165 15.6898 20.4165 14.9994V3.33276C20.4165 2.64241 20.9761 2.08276 21.6665 2.08276Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PdfIcon;
