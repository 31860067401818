import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

const INDEFINITELY_DAYS_BLOCK = 999;

/**
 * @param {string} timeRemaining ISO date string
 * @returns {string}
 */
export const getTimeRemaining = (timeRemaining) => {
  const date = Date.parse(timeRemaining);

  const diffInHours = differenceInHours(date, Date.now());
  if (diffInHours <= 24 && diffInHours >= 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
  } else if (diffInHours < 0) {
    return '0 hours';
  }

  const diffInDays = differenceInDays(date, Date.now());

  return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
};

/**
 * @param {string} startBlock ISO date
 * @param {string} endBlock ISO date
 * @returns {boolean}
 */
export const isDateIndefinitely = (startBlock, endBlock) => {
  const blockLength = differenceInCalendarDays(
    Date.parse(endBlock),
    Date.parse(startBlock)
  );

  return blockLength > INDEFINITELY_DAYS_BLOCK;
};
