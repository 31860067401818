import * as React from 'react';
import { useMemo, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Typography from '@mui/material/Typography';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

import { Lock } from 'assets/icons';
import { If } from 'shared/utilities';
import { useChatContext } from 'modules/users-chats/context';

export default function MessageActions({
  messageId,
  messages = [],
  messageType,
  isMessageBlock,
}) {
  const {
    toggleSaveMessageModal,
    toggleFlagMessageModal,
    setFocusMessage,
    blockMessageModal,
    toggleBlockMessageModal,
  } = useChatContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isAlreadyFlagged = useMemo(
    () => messages.find((message) => message.id === messageId)?.flagData,
    [messageId]
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFocusMessage(messageId);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setFocusMessage(null);
  };

  function handleSaveItem() {
    toggleSaveMessageModal();
    setAnchorEl(null);
  }

  function handleFlagItem() {
    toggleFlagMessageModal();
    setAnchorEl(null);
  }

  function handleBlockItem() {
    toggleBlockMessageModal();
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleSaveItem} sx={{ paddingY: 1.3, paddingX: 3 }}>
          <BookmarkBorderOutlinedIcon sx={{ marginRight: 1 }} />
          <Typography variant="mediumBlack">Save</Typography>
        </MenuItem>
        <If condition={!isAlreadyFlagged}>
          <MenuItem
            onClick={handleFlagItem}
            sx={{ paddingY: 1.3, paddingX: 3 }}
          >
            <FlagOutlinedIcon sx={{ marginRight: 1 }} />
            <Typography variant="mediumBlack">Flag</Typography>
          </MenuItem>
        </If>
        <If condition={messageType === 'voice' && !isMessageBlock}>
          <MenuItem
            onClick={handleBlockItem}
            sx={{ paddingY: 1.3, paddingX: 3 }}
          >
            <Lock sx={{ marginRight: 1.5, fontSize: '20px' }} />
            <Typography variant="mediumBlack">Block</Typography>
          </MenuItem>
        </If>
      </Menu>
    </div>
  );
}
