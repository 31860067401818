const getFormatFilterValues = (filterValues) => {
  return Object.keys(filterValues).filter((key) => filterValues[key]);
};

const formatFilterData = (filterData) => {
  const filterDataElements = Object.entries(filterData);
  const formatedFilterData = filterDataElements.reduce(
    (acum, [filterKey, filterValue]) => {
      const filterValues = getFormatFilterValues(filterValue);
      if (filterValues.length) {
        acum.push({
          term: filterKey,
          value: filterValues,
        });
      }
      return acum;
    },
    []
  );
  return formatedFilterData;
};

export default formatFilterData;
