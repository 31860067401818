import React, { memo } from 'react';
import styles from './UploadFile.module.scss';
import Grid from '@mui/material/Grid';
import { TextInput } from 'shared/inputs';
import Button from 'shared/button';

import { useStepperContext } from 'context';
import { useFormContext } from 'react-hook-form';
import FileInput from 'modules/bulletins/file-input';

const acceptedFileTypes = [
  'application/pdf',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/bmp',
  'video/mp4',
  'audio/mp3 ',
];

const NextButton = memo(() => {
  const { formState, watch } = useFormContext();
  const { onNextStep } = useStepperContext();
  const value = watch('file');

  return (
    <Button
      onClick={onNextStep}
      disabled={
        !value || !formState.dirtyFields.title || formState.errors.title
      }
      display="primary"
    >
      Next
    </Button>
  );
});

const TitleAndFileStep = () => (
  <div className={styles.formStep}>
    <div className={styles.uploadModalDivider__uploadStep}></div>
    <TextInput
      className={styles.input}
      name="title"
      label="File Display Name"
      helperText="Up to 100 char."
      fullWidth
    />
    <FileInput
      name="file"
      acceptedFiles={acceptedFileTypes}
      openDropZone={true}
    />
    <Grid
      classes={{ root: styles.actions }}
      justifyContent="flex-end"
      wrap="nowrap"
      container
    >
      <NextButton />
    </Grid>
  </div>
);

export default TitleAndFileStep;
