import React from 'react';

import { toast } from 'react-toastify';

import AuthInput from 'pages/auth-pages/components/auth-input';
import If from 'components/if/If';
import Logo from 'assets/Logo';
import PasswordForgot from 'pages/auth-pages/components/password-forgot';
import AuthLoader from 'pages/auth-pages/components/auth-loader';
import SquareBackground from 'pages/auth-pages/components/square-background';
import BackgroundSmudge from 'pages/auth-pages/components/background-smudge';

import { useAuthContext } from 'modules/auth/context';

import {
  INITIAL_FORM_VALUES,
  ForgotPasswordFormType,
} from './initial-pswd-forgot-form-values';
import RESET_EMAIL_VALIDATION from './form-validation';

import { routes } from 'routing';

export default function ForgotPasswordPage() {
  // @ts-ignore
  const { onRequestPasswordReset } = useAuthContext();

  function onHandleFormSubmit(form: ForgotPasswordFormType) {
    onRequestPasswordReset.mutate(
      { form },
      {
        onSuccess: () => {
          toast.success('Your request has been processed successfully.');
        },
      },
      {
        onError: (error: any) => {
          console.log('Error from onRequestPasswordReset', error.message);
          toast.error('Something when wrong with your request');
        },
      }
    );
  }

  return (
    <PasswordForgot>
      <SquareBackground />
      <BackgroundSmudge />
      <If
        condition={!onRequestPasswordReset.isLoading}
        otherwise={<AuthLoader />}
      >
        <PasswordForgot.Title>
          <h2 className="text-[24px] text-grey-2 mb-4">Forgot Password</h2>
          <Logo className="mx-auto h-[40px] w-[272px]" />
        </PasswordForgot.Title>
        <PasswordForgot.FormContainer>
          <PasswordForgot.Form
            initialValues={INITIAL_FORM_VALUES}
            validation={RESET_EMAIL_VALIDATION}
            onSubmit={onHandleFormSubmit}
          >
            <PasswordForgot.EmailInput>
              <AuthInput
                autoComplete="email"
                label="Email address"
                name="email"
                placeholder="you@example.com"
                type="text"
              />
            </PasswordForgot.EmailInput>
            <PasswordForgot.GoLoginLink href={routes.login}>
              Go back to Login
            </PasswordForgot.GoLoginLink>
            <PasswordForgot.SubmitButton>
              Reset Password
            </PasswordForgot.SubmitButton>
          </PasswordForgot.Form>
        </PasswordForgot.FormContainer>
      </If>
    </PasswordForgot>
  );
}
