import React, { useRef, useEffect } from 'react';
import styles from './InfiniteScroller.module.scss';

import CircularProgress from '@mui/material/CircularProgress';

import { fromEvent } from 'rxjs';

const InfiniteScroller = ({ containerRef, loading, onLoadNext }) => {
  const scroller = useRef(null);

  function isEnd() {
    if (!scroller.current) return true;
    const rect = scroller.current.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      Math.floor(rect.bottom) <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      Math.floor(rect.right) <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  useEffect(() => {
    const onScroll = () => {
      if (isEnd()) {
        Boolean(onLoadNext) && onLoadNext();
      }
    };

    const sub = fromEvent(containerRef.current, 'scroll').subscribe(onScroll);

    onScroll();

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.wrapper} ref={scroller}>
      {loading && Boolean(onLoadNext) && (
        <CircularProgress classes={{ root: styles.loader }} />
      )}
    </div>
  );
};

export default InfiniteScroller;
