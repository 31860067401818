import Grid from '@mui/material/Grid';

import ChatSearch from '../chat-search/ChatSearch';
import ControlButtons from './ControlButtons';
import SearchResultsControls from 'modules/users-chats/chat-details/search-result-controls';

import { If } from 'shared/utilities';
import { useChatContext } from 'modules/users-chats/context';

const ChatControls = () => {
	const { searchResultsTotal } = useChatContext();
	return (
		<Grid
			container
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			sx={sxControlsBox}
		>
			<Grid item xs={7}>
				<ChatSearch />
			</Grid>
			<Grid item xs={5} sx={sxSearchGrid}>
				<If condition={searchResultsTotal} otherwise={<ControlButtons />}>
					<SearchResultsControls />
				</If>
			</Grid>
		</Grid>
	);
};

export default ChatControls;

const sxControlsBox = {
	height: '72px',
	borderColor: 'primary',
	borderBottom: '1px solid #EBEBEF',
	padding: '0 24px',
};

const sxSearchGrid = {
	display: 'flex',
	justifyContent: 'flex-end',
};
