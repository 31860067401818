import { Fragment } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import format from 'date-fns/format';
import Avatar from 'shared/avatar';
import InfoSkeleton from 'shared/skeleton/InfoSkeleton';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import useUserProfileInfo from '../../../../../hooks/useUserProfileInfo';

import { useChatsTabContext } from '../ChatsTabContext';
import { useAvatarPhoto, useDisplayId } from 'hooks';
import { generatePath, useHistory } from 'react-router';
import { toTitleCase } from 'utils';
import { routes } from 'routing';

const ContactDetails = (props) => {
  const { contactSelected, userProfileInfoData } = props;

  const {
    id = '',
    begins,
    firstName = '',
    lastName = '',
    location,
    phone = '',
    email = '',
    isPersonBlock,
    eid,
    iWasLastSeen,
    avatarModerationStatus,
  } = contactSelected;

  const history = useHistory();
  const avatarMediaLocation =
    userProfileInfoData?.data?.photo || avatarModerationStatus?.mediaLocation;
  const { thumbnailSrc: avatarSrc } = useAvatarPhoto(avatarMediaLocation);
  const displayId = eid || useDisplayId(id);
  const isFnf = id.includes('FNF^');
  const joinedDate = begins
    ? format(new Date(begins), 'MM/dd/yyyy')
    : 'Information not available';

  const displayInfo = (id) => {
    if (id.indexOf('I^') === 0) {
      return [
        {
          label: 'Most recently active',
          value: iWasLastSeen
            ? format(new Date(iWasLastSeen), 'MMM dd, yyyy hh:mm a')
            : 'No recent activity',
        },
        {
          label: 'Facility',
          value: location?.facilityName,
        },
        {
          label: 'Housing Unit',
          value: `Housing ${location?.huName}`,
        },
      ];
    }
    return [
      {
        label: 'Joined',
        value: joinedDate,
      },
      {
        label: 'Phone Number',
        value: formatPhoneNumber(phone),
      },
      {
        label: 'Email',
        value: email,
      },
    ];
  };

  const onHandleLinkClick = (e) => {
    e.stopPropagation();
    history.push(
      `${generatePath(routes.user, {
        userId: id,
      })}`
    );
  };
  return (
    <Box sx={{ mx: 3, my: 2.5 }}>
      {userProfileInfoData?.isLoading && contactSelected ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InfoSkeleton amount={3} />
        </Box>
      ) : (
        <Fragment>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            mb={3}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
              }}
            >
              <Avatar
                id={id}
                firstName={firstName}
                lastName={lastName}
                isBlocked={isPersonBlock}
                sxAvatar={sxAvatar(isFnf)}
                isBlur={
                  userProfileInfoData?.data?.photoModerationStatus ===
                  'HAS_VIOLATION'
                }
                src={avatarMediaLocation ? avatarSrc : ''}
                isActive={userProfileInfoData?.data?.active}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 1.5,
                flexDirection: 'column',
              }}
            >
              <Typography variant="regularGrey3" component="p">
                {isFnf ? formatPhoneNumber(phone) : displayId}
              </Typography>
              <Link
                component="p"
                underline="hover"
                onClick={onHandleLinkClick}
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: 14,
                  lineHeight: '22px',
                  color: 'customPalette.grey1',
                }}
              >
                {toTitleCase(`${firstName} ${lastName}`)}
              </Link>
            </Box>
          </Grid>
          <Box>
            <ContactInfo info={displayInfo(id)} />
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default ContactDetails;

const sxAvatar = () => {
  return {
    width: '65px',
    height: '65px',
  };
};

const ContactInfo = ({ info }) => {
  return (
    <Box>
      {info.map(({ label, value }) => {
        return <InfoItem key={label} label={label} value={value} />;
      })}
    </Box>
  );
};

const InfoItem = ({ label, value }) => {
  return (
    <Box>
      <Typography variant="boldBlack" component="p" mt={2} mb={1}>
        {label}
      </Typography>
      <Typography variant="regularGrey3" component="p" mb={2}>
        {value}
      </Typography>
      <Divider />
    </Box>
  );
};
