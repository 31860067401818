import React, { useEffect, useMemo } from 'react';
import styles from './SurveysList.module.scss';

import Checkbox from '@mui/material/Checkbox';
import { useForm } from 'react-hook-form';

export const SurveyCheckbox = ( { name } ) => {
	const { register, setValue, watch } = useForm();

	const value = watch(name);
	register(name);

	useEffect(() => {
		setValue(name, false);
	}, []);

	const onCheck = ( evt ) => {
		evt.preventDefault();
		setValue(name, evt.target.checked);
	};

	return (
		<Checkbox
			classes={{ root: styles.checkbox }}
			onClick={( evt ) => evt.stopPropagation()}
			onChange={onCheck}
			checked={!!value}
		/>
	);
};

export const AllSurveyCheckbox = () => {
	const { watch, reset } = useForm();
	const value = watch();

	const { allChecked, oneChecked } = useMemo(() => {
		let allChecked = true;
		let oneChecked = false;

		Object.values(value).forEach(( item ) => {
			if (!item) {
				allChecked = false;
			}
			if (item) {
				oneChecked = true;
			}
		});

		if (Object.keys(value).length === 0) {
			allChecked = false;
		}

		return { allChecked, oneChecked };
	}, [value]);

	const onCheck = ( evt ) => {
		const newValue = {};

		Object.keys(value).forEach(( key ) => {
			newValue[key] = evt.target.checked;
		});

		reset(newValue);
	};

	return (
		<Checkbox
			classes={{ root: styles.checkbox }}
			onChange={onCheck}
			checked={allChecked}
			indeterminate={oneChecked && !allChecked}
		/>
	);
};
