import React from 'react';
import styles from '../SurveyResults.module.scss';

import SearchAndSumary from './SearchAndSumary';
import SideBarInfo from './SideBarInfo';
import ResponsesList from './ResponsesList';
import fetchSurveyResponses from 'modules/surveys/utils/functions/fetchSurveyResponses';
import DataSource from 'components/data-source-core/DataSource';

import queryKeys from 'utils/queryKeys';

const ResultPageSidebar = ({
  id,
  sent,
  completed,
  format,
  Date,
  createdAt,
  recipients,
}) => {
  return (
    <div className={styles.sidebar}>
      <SideBarInfo
        sent={sent}
        completed={completed}
        format={format}
        Date={Date}
        createdAt={createdAt}
        recipients={recipients}
      />
      <div className={styles.sidebar__divider} />
      <SearchAndSumary />
      <DataSource.Server
        fetchFn={fetchSurveyResponses({ surveyId: id })}
        pagination={true}
        sourceName="responses"
        sourceKey={[queryKeys.SURVEY_RESPONSES, id]}
      >
        <ResponsesList />
      </DataSource.Server>
    </div>
  );
};

export default ResultPageSidebar;
