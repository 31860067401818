import React from 'react';

import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import CustomButton from 'shared/button';

import CreateRole from '../create-role';

import { useToggler } from 'hooks';
import { useAdminSettingsContext } from '../context';

const NewRoleButton = ({ handleClick }) => {
  return (
    <CustomButton
      display={'secondary'}
      startIcon={<AddIcon />}
      sx={{ marginRight: 1 }}
      onClick={handleClick}
    >
      New Role
    </CustomButton>
  );
};

const RolesSectionHeader = () => {
  const [open, toggleOpen] = useToggler();
  const { hasPermission } = useAdminSettingsContext();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 1, position: 'sticky' }}
    >
      <h3>Roles</h3>
      {hasPermission.createRole && <NewRoleButton handleClick={toggleOpen}/>}
      {hasPermission.createRole && <CreateRole open={open} onClose={toggleOpen} />}
    </Grid>
  );
};

export default RolesSectionHeader;
