import { useEffect } from 'react';
import styles from './ChatSearch.module.scss';

import { Search } from 'assets/icons';

import { useForm } from 'react-hook-form';
import { useChatContext } from '../../context';

const SearchInput = ( { onSearch, setValue } ) => {
	const { setCurrentFoundMessageIndex } = useChatContext();
	const { register, watch } = useForm();
	const text = watch('text');

	useEffect(() => {
		setValue(text);
	}, [text]);

	return (
		<>
			<form
				className={styles.input__wrapper}
				onSubmit={( evt ) => {
					evt.preventDefault();
					setCurrentFoundMessageIndex(0);
					onSearch(text);
				}}
			>
				<Search classes={{ root: styles.input__icon }} />
				<input
					className={styles.input}
					placeholder='Search In Chat'
					{...register('text', { required: true, maxLength: 1000 })}

				/>
			</form>
		</>
	);
};

export default SearchInput;
