const useRoleHandler = () => {
	return { roleUsersCountHandler };
};

export default useRoleHandler;

const roleUsersCountHandler = ( response, currentRolesArray ) => {
	const removeLexem = 'removeRoleFromDOC_input_ROLE_';
	const addLexem = 'addRoleToDOC_input_ROLE_';
	const newRolesArray = currentRolesArray.map(( role ) => {
		if (response.includes(`${removeLexem}${role?.name.toUpperCase()}`)) {
			return {
				...role,
				assignedUsersCount: Math.max(role?.assignedUsersCount - 1, 0),
			};
		}
		if (response.includes(`${addLexem}${role?.name.toUpperCase()}`)) {
			return { ...role, assignedUsersCount: role?.assignedUsersCount + 1 };
		}
		return role;
	});
	return newRolesArray;
};
