import React from 'react';
import styles from './Sections.module.scss';

import SectionItem from './SectionItem';

const Chat = () => {
  return (
    <div className={styles.section}>
      <h3 className={styles.section__title}>Chat</h3>
      <div className={styles.section__content}>
        <SectionItem title="How are the moderation categories determined when I search?">
          <p>
            The categories in the search dropdown are pre-populated based on the
            moderation technology of the KiwiChat system. KiwiChat is constantly
            updating the moderation tools to include new categories and flags to
            ensure the highest level of security in the chat application.
          </p>
        </SectionItem>
        <SectionItem title="How do I block someone that is using chat inappropriately?">
          <p>
            The portal allows you to block a specific chat or to block an inmate
            or F&F from all chats, barring their use of the KiwiChat system to
            communicate.
          </p>
          <ul>
            <li>
              To block a chat:
              <ul>
                <li>
                  Search for that specific user from the KiwiChat portal home
                  page
                </li>
                <li>
                  From the profile page of that user, open up the chat you would
                  like to block
                </li>
                <li>
                  Choose &apos;Block Chat&apos; and fill in the number of days
                  for the block, the reason, and add comments that will only be
                  visible to DOC staff
                </li>
                <li>
                  This action will be tracked on the Account History tab of the
                  user&rsquo;s profile page
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              To block from all chats:
              <ul>
                <li>
                  Search for that specific user from the KiwiChat portal home
                  page
                </li>
                <li>
                  On the profile page of that user, choose &apos;Block from
                  Chat&apos;
                </li>
                <li>
                  Fill in the number of days for the block, the reason, and add
                  comments that will only be visible to DOC staff
                </li>
                <li>
                  This action will be tracked on the Account History tab of the
                  user&apos;s profile page
                </li>
              </ul>
            </li>
          </ul>
        </SectionItem>
        <SectionItem title="What is Kiwicop?">
          <p>
            Kiwicop is Kiwichat&apos;s system moderation tool that runs in the
            background against all chat messages, photos, videos and audio
            notes. It will identify any content that breaks security protocol
            and, depending on the settings within your facility, will flag the
            content appropriately.
          </p>
        </SectionItem>
        <SectionItem title="What are moderation flags and warnings?">
          <ul>
            <li>
              Flags and warnings are configurable by agency through the Kiwicop
              moderating system, but are both based on AI thresholds associated
              to any message sent.
            </li>
            <li>
              Flags and warnings are configurable by agency through the Kiwicop
              moderating system, but are both based on AI thresholds associated
              to any message sent.
            </li>
            <li>
              A warning is any message that triggered the KiwiChat AI moderation
              system to the higher configured threshold.
            </li>
            <li>
              Example: Flag thresholds are set at 50% for Drugs and Warnings
              thresholds are set at 80% for Drug warnings. If a message is sent
              with 55% threat threshold, it will go through and be delivered to
              the F&F or inmate and be presented in the reports as a flagged
              message. If a message is sent and identified with 85% drugs, it
              will not be delivered and the F&F or inmate will receive a
              notification that the message was not approved. This will be noted
              in the DOC portal as a warning, which is received by both the F&F
              and the inmate when this type of message is sent.
            </li>
          </ul>
        </SectionItem>
      </div>
    </div>
  );
};

export default Chat;
