import Box from '@mui/material/Box';

import Logo from './Logo';
import AutocompleteSearch from '../../modules/global-search/autocomplete/AutompleteSearch';

export default function SearchAndLogo() {
	return (
		<Box sx={{ width: 520, height: 46, display: 'flex', gap: 2.5 }}>
			<Box ml={2.5}>
				<Logo />
			</Box>
			<AutocompleteSearch />
		</Box>
	);
}

