import React from 'react';
import styles from '../../ChatDetails.module.scss';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import { MODERATION_TYPES } from 'modules/users-chats/constants';
import { isDateIndefinitely } from 'utils/date';

export const ModerationFlag = React.forwardRef(
  ({ type, statusData, warnings }, ref) => {
    const {
      ends,
      begins,
      blockedBy,
      isChannelBlock,
      reason,
      blockedByMetadata,
      updatedByMetadata,
      updateBy,
    } = statusData;

    const entry = isChannelBlock ? 'Chat' : 'User';

    const getText = () => {
      switch (type) {
        case MODERATION_TYPES.BLOCK: {
          const startDate = Date.parse(begins);
          const endDate = Date.parse(ends);
          const isIndefinitely = isDateIndefinitely(begins, ends);
          const blockLength = differenceInCalendarDays(endDate, startDate);
          const moderator = getModerator(blockedBy, blockedByMetadata);
          const displayBlock = isIndefinitely
            ? 'indefinitely'
            : `${blockLength} ${blockLength === 1 ? 'day' : 'days'}`;

          return (
            <p>
              {entry} was{' '}
              <span className={styles.moderation__bolt}>blocked </span>
              on{' '}
              <span className={styles.moderation__name}>
                {`${format(new Date(startDate), 'MMM d, yyyy, h:mm a')} `}
              </span>
              by <span className={styles.moderation__name}>{moderator}</span>{' '}
              for: {displayBlock}
            </p>
          );
        }

        case MODERATION_TYPES.UNBLOCK: {
          const startDate = Date.parse(begins);
          const moderator = getModerator(
            updateBy || blockedBy,
            updatedByMetadata || blockedByMetadata,
          );

          return (
            <p>
              {entry} was{' '}
              <span className={styles.moderation__bolt}>unblocked </span>
              on{' '}
              <span className={styles.moderation__name}>
                {`${format(new Date(startDate), 'MMM d, yyyy, h:mm a')} `}
              </span>
              by <span className={styles.moderation__name}>{moderator}</span>
            </p>
          );
        }

        case MODERATION_TYPES.WARNING: {
          return (
            <p>
              <span className={styles.moderation__bolt}>
                Warning {`#${warnings.warningCount}`}
              </span>{' '}
              - {reason.toLowerCase()}
            </p>
          );
        }

        default:
          return null;
      }
    };

    return (
      <div ref={ref} className={styles.moderation}>
        {getText()}
      </div>
    );
  },
);

function getModerator (isKiwiCop, user) {
  return isKiwiCop ? 'Kiwicop' : `DOC ${user.firstName} ${user.lastName}`;
};
