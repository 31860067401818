const formatName = (firstName, lastName) => {
   const name = `${firstName} ${lastName}`;
    return name.split(' ').map(item => {
       const newName = item.toLowerCase().split('-');
       return newName.map(subItem => {
          return subItem.charAt(0).toUpperCase() + subItem.slice(1);
       }).join('-');
    }).join(' ');
 };
 
 export default formatName;