function getDataByDateRange( data = [], startDate, endDate ) {
	if (!startDate || !endDate) return data;

	const start = new Date(startDate).getTime();
	const end = new Date(endDate).getTime();

	return data.filter(( item ) => {
		const currentDate = new Date(item.createdAt).getTime();

		return currentDate >= start && currentDate <= end;
	});
}

export default getDataByDateRange;
