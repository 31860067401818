import Box from '@mui/material/Box';

import ChatsComponent from './ChatsComponent';
import ReportsComponent from './ReportsComponent';
import SettingsComponent from './SettingsComponent';
import DashComponent from './DashComponent';

export default function Navigation() {
	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				gap: 6,
			}}
		>
			{NavigationElements.map(( { label, Component } ) => {
				return (
					<Component key={label} />
				);
			})}
		</Box>
	);
};

const NavigationElements = [
	{ label: 'Chats', Component: ChatsComponent },
	{ label: 'Hub', Component: DashComponent },
	{ label: 'Reports', Component: ReportsComponent },
	{ label: 'Administrative', Component: SettingsComponent },
];