import React from 'react';
import styles from './SettingsPage.module.scss';

import Grid from '@mui/material/Grid';

import SettingsHeader from '../settings-header';
import { RolesSection } from '../settings-roles';
import { PermissionsSection } from '../settings-permissions';

const SettingsPage = () => {
  return (
    <Grid
      classes={{ root: styles.wrapper }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item p={2}>
        <SettingsHeader />
      </Grid>

      <Grid container height={'82vh'} overflow={'hidden'}>
        <Grid item xs={4} p={2}>
          <RolesSection />
        </Grid>
        <Grid item xs={8} p={2}>
          <PermissionsSection />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
