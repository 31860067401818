import { useContext } from 'react';
import { AnalyticsModalContext } from 'shared/analytics-modal/AnalyticsModal';

export default function useAnalyticsModalContext() {
	const context = useContext(AnalyticsModalContext);
	if (!context) {
		throw new Error('useAnalyticsModalContext must be used within a AnalyticsModalContext');
	}
	return context;
}
