import { memo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Option from './Option';
import CustomInput from './CustomInput';

import { useAutocompleteSearch, useToggler } from 'hooks';
import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';
import Paper from '@mui/material/Paper';

export default function AutocompleteSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, toggleShowOptions] = useToggler(false);
  const { data = [], isLoading } = useAutocompleteSearch(searchTerm.trim());
  const { sxAutocomplete } = useAutocompleteSearchStyles();
  const textFieldRef = useRef();

  function onInputChange(event) {
    event.preventDefault();
    queryClient.cancelQueries({
      queryKey: [QUERY_KEYS.SEARCH_FNF, searchTerm],
    });
    queryClient.cancelQueries({
      queryKey: [QUERY_KEYS.SEARCH_INMATE, searchTerm],
    });
    setSearchTerm(event.target.value);
  }

  function resetSearchTerm(e) {
    e.preventDefault();
    setSearchTerm('');
    textFieldRef.current.value = '';
    toggleShowOptions();
  }

  return (
    <>
      <Autocomplete
        id="global-search-bar"
        inputValue={searchTerm}
        options={data}
        loading={isLoading}
        loadingText="Searching..."
        disableClearable
        clearOnBlur={false}
        freeSolo={data.length === 0}
        getOptionLabel={(option) =>
          `${option.eid} ${option?.firstName} ${option?.lastName}`
        }
        isOptionEqualToValue={(option = '') => {
          return `${option.eid} ${option?.firstName} ${option?.lastName}`.includes(
            searchTerm.replace(/[()\-\s]/g, '')
          );
        }}
        noOptionsText="No match found"
        onOpen={toggleShowOptions}
        open={showOptions}
        onClose={toggleShowOptions}
        openOnFocus
        fullWidth
        renderInput={(params) => (
          <CustomInput
            inputRef={textFieldRef}
            InputProps={{ ...params.InputProps }}
            input={searchTerm}
            onChange={onInputChange}
            open={showOptions && data.length > 0}
            toggleShowOptions={toggleShowOptions}
            resetSearchTerm={resetSearchTerm}
            {...params}
          />
        )}
        renderOption={(props, option) => (
          <Box {...props}>
            <Option info={option} searchTerm={searchTerm} />
          </Box>
        )}
        sx={sxAutocomplete}
      />
    </>
  );
}

function useAutocompleteSearchStyles() {
  return {
    sxAutocomplete: {
      '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
        padding: '4px 15px',
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  };
}
