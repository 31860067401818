import React, { useMemo } from 'react';
import styles from './CheckboxListInput.module.scss';

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useFormContext } from 'react-hook-form';

const AllCheckbox = ({ name, label }) => {
  const { watch, setValue, trigger } = useFormContext();
  const value = watch(name);

  const allChecked = useMemo(() => {
    let allChecked = true;

    Object.values(value).forEach((item) => {
      if (!item) {
        allChecked = false;
      }
    });

    if (Object.keys(value).length === 0) {
      allChecked = false;
    }

    return allChecked;
  }, [value]);

  const onCheck = (evt) => {
    const newValue = {};

    Object.keys(value).forEach((key) => {
      newValue[key] = evt.target.checked;
    });

    setValue(name, newValue, { shouldDirty: true, shouldValidate: true });
    trigger(name);
  };

  return (
    <FormControlLabel
      classes={{
        root: styles.checkbox__wrapper,
        label: styles.checkbox__label,
      }}
      control={
        <MaterialCheckbox
          classes={{ root: styles.checkbox }}
          onChange={onCheck}
          checked={allChecked}
        />
      }
      label={label}
    />
  );
};

export default AllCheckbox;
