import dayjs from 'dayjs';

export default function chatFormatDate(date, alternativeFormat) {
  const dayJsDate = dayjs(date);
  const currentDate = dayjs();
  const datesDifference = currentDate.diff(dayJsDate, 'day');

  if (currentDate.isSame(dayJsDate, 'day')) {
    return dayJsDate.format('h:mm A');
  } else if (datesDifference < 7 && !currentDate.isSame(dayJsDate, 'day')) {
    return dayJsDate.format('MM/DD/YYYY h:mm A');
  } else {
    return alternativeFormat
      ? dayJsDate.format(alternativeFormat)
      : dayJsDate.format('MM/DD/YYYY');
  }
}
