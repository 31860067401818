import { SurveyQuestionType } from 'kiwi-sdk';

export const QUESTION_OPTIONS = {
  [SurveyQuestionType.RADIO]: 'Radio button',
  [SurveyQuestionType.MULTIPLE_SELECT]: 'Multiple select',
  [SurveyQuestionType.YES_NO]: 'Yes/No',
  [SurveyQuestionType.RATING]: 'Rating',
  // [SurveyQuestionType.TEXT]: 'Text entry',
};

export const initQuestionValue = {
  [SurveyQuestionType.RADIO]: {
    question: '',
    type: SurveyQuestionType.RADIO,
    options: [{ value: '' }, { value: '' }],
  },
  [SurveyQuestionType.MULTIPLE_SELECT]: {
    question: '',
    type: SurveyQuestionType.MULTIPLE_SELECT,
    options: [{ value: '' }, { value: '' }],
  },
  [SurveyQuestionType.YES_NO]: {
    question: '',
    type: SurveyQuestionType.YES_NO,
    options: [{ value: 'Yes' }, { value: 'No' }],
  },
  [SurveyQuestionType.RATING]: {
    question: '',
    type: SurveyQuestionType.RATING,
    options: Array(10)
      .fill()
      .map((_, i) => ({ value: i + 1 })),
  },
  [SurveyQuestionType.TEXT]: {
    question: '',
    type: SurveyQuestionType.TEXT,
    options: [],
  },
};
