/**
 * @param {object} params search params
 * @param {boolean} empty from empty SearchParams
 * @returns {string} string search params
 */
export const generateSearchParams = (params, empty) => {
  const searchParams = new URLSearchParams(
    empty ? '' : document.location.search
  );
  Object.keys(params).forEach((key) => {
    searchParams.set(key, `${params[key]}`);
  });
  return searchParams.toString();
};
