import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function TextMessageContainer( props ) {
	const { children, isBlock, isMine, size = 'medium' } = props;
	const Sxs = useTextMessageStyles({ isBlock, isMine, size });
	return (
		<Card sx={Sxs.CardWrapper} elevation={1}>
			<CardContent sx={Sxs.CardContent}>
				{children}
			</CardContent>
		</Card>
	);
}

function useTextMessageStyles( { border = 2, isBlock = false, isMine = false, size } ) {
	const unblockedBorderColor = isMine ? 'customPalette.grey5' : 'customPalette.grey';
	return {
		CardWrapper: {
			borderRadius: border,
			maxWidth: getMsgWidth(size),
			// bgcolor: isMine ? '#c8dcdd7d' : '#fdfdfd',
			bgcolor: isMine ? 'customPalette.grey5' : '#fdfdfd',
			borderColor: isBlock ? 'customPalette.error' : unblockedBorderColor,
			'&:hover': {
				borderColor: isBlock ? 'customPalette.error' : 'customPalette.grey4',
			},
			boxShadow: isBlock && '0 1px 3px #df093c',
		},
		CardContent: {
			display: 'flex',
			alignItems: 'flex-end',
			flexWrap: 'wrap',
			maxWidth: getMsgWidth(size) - 10,
			'&.MuiCardContent-root': {
				padding: '12px 16px 12px 16px ',
			},
		},
	};
}

function getMsgWidth( size ) {
	switch (size) {
		case 'small':
			return 200;
		case 'medium':
			return 470;
		default:
			return 470;
	}
}
