import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import { If } from 'shared/utilities';
import { toTitleCase } from 'utils';
import { KiwiModeration } from 'assets/icons';

export function BlockAlert( props ) {
	const { isBlock, reason = '', isMine } = props;
	const titleCaseReason = toTitleCase(reason);
	return (
		<If condition={isBlock}>
			<AlertContainer isMine={isMine}>
				<BlockReason variant='regularRed'>
					{titleCaseReason}
				</BlockReason>
				<ModerationIcon />
			</AlertContainer>
		</If>
	);
}

const AlertContainer = styled(Box)(( { theme, isMine } ) => ({
	height: 'calc(100% - 15px)',
	display: 'flex',
	flexDirection: isMine ? 'row' : 'row-reverse',
	alignItems: 'center',
	margin: theme.spacing(0, 1),
}));

const BlockReason = styled(Typography)(( { theme } ) => ({
	fontWeight: 'bold',
}));

const ModerationIcon = styled(KiwiModeration)({
	width: 20,
	height: 18,
});
