/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { useSearchParams, useToggler } from 'hooks';
import { useAuthContext } from 'modules/auth/context';
import { useGraphql } from 'context';
import { from } from 'rxjs';


const DocumentsContext = createContext({});

const initDocumentsContext = () => {
  const { paramsController } = useSearchParams();
  const { strictPermissionCheck, facilities } = useAuthContext();
  const hasPermission = {
    uploadDocument : strictPermissionCheck('DOCUMENT#WRITE#addDocument'),
    deleteDocument : strictPermissionCheck('DOCUMENT#WRITE#deleteDocument'),
    seeDocumentViews: strictPermissionCheck('DOCUMENT#READ#DOC_Get_Read_Document_Inmates')
  };
  const term = useRef('');
  const incomingFiles = useRef([]);
  const api = useGraphql();
  const [showFacility, setShowFacility] = useState(false);
  const [loading, toggleLoading] = useToggler();
  const [searchInput, setSearchInput] = useState('');
  const [files, setFiles] = useState();
  const [shownFiles, setShownFiles] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [checkedFilterState, setCheckedFilterState] = useState({});
  

  function fetchDocuments(){
    toggleLoading(true);
    return from(
      api.controller.document.getDocuments()
    );
  }

  function deleteDocument(fileId, facilityId){
    return from(
      api.controller.document.deleteDocument({
        entityId: facilityId,
        documentId: fileId
      })
    );
  }

  function filterByFacility(facilityFilters){
    incomingFiles.current = incomingFiles.current.reduce((fileAcc, fileCurr) =>{
      if(facilityFilters.some((facId) => facId === fileCurr.facilityId)){
        fileAcc.push(fileCurr);
      }
      return fileAcc;
    },[]);
    
  }
  
  function filterByFileType(fileTypeFilters){
    incomingFiles.current = incomingFiles.current.reduce((fileAcc, fileCurr) =>{
      if(fileTypeFilters.some((type) => type === fileCurr.fileType)){
        fileAcc.push(fileCurr);
      }
      return fileAcc;
    },[]);
    
  }

  function filterFiles(filters){
    incomingFiles.current = files;
    const facilityFilters = Object.keys(filters.recipient).filter((key) => filters.recipient[key]);
    const fileTypeFilters = Object.keys(filters.type).filter((key) => filters.type[key]);
    if(facilityFilters.length > 0){
      filterByFacility(facilityFilters);
    }
    if(fileTypeFilters.length > 0){
      filterByFileType(fileTypeFilters);
    }
    setShownFiles([...incomingFiles.current]);
  }
  
  
  function getFileType(fileName){
    const fileExtension = fileName.split('.').pop();
    const fileType = ['jpg', 'jpeg', 'png'].includes(fileExtension)
    ? 'Image'
    : fileExtension === 'mp4'
    ? 'Video'
    : 'File';
    return fileType;
  }

  function formatFiles(entity){
    const { documents, entityId, entityName } = entity; 
    documents.forEach((document) => {
      document['facilityName'] = entityName;
      document['facilityId'] = entityId;
      document['fileType'] = getFileType(document.file);
      incomingFiles.current.push(document);
    });
  }

  function refreshDocuments(){
    const fetchedDocumentsObservable = fetchDocuments();
    incomingFiles.current = [];
    fetchedDocumentsObservable.subscribe((data) => {
      data.items.forEach((entity) => {
        if(entity.documents.length > 0){
          formatFiles(entity);
        }
      });
      setFiles(incomingFiles.current);
      toggleLoading(false);
    });
  }

  useEffect(() => {
    const fetchedDocumentsObservable = fetchDocuments();
    fetchedDocumentsObservable.subscribe((data) => {
      data.items.forEach((entity) => {
        if(entity.documents.length > 0){
          formatFiles(entity);
        }
      });
      setFiles(incomingFiles.current);
      toggleLoading(false);
    });

    if(facilities.length > 1){
      setShowFacility(true);
    }
  },[]);

  useEffect(() => {
    if(filterState?.type){
      filterFiles(filterState);
    }
  },[files]);

  const searchDocuments = useCallback((input) => {
    term.current = input;
    setFiles([]);
    setSearchInput(input);
    paramsController.set({ search: input });
  }, []);


  return {
    files,
    shownFiles,
    loading,
    searchInput,
    setSearchInput,
    searchDocuments,
    filterState,
    setFilterState,
    checkedFilterState,
    setCheckedFilterState,
    hasPermission,
    showFacility,
    deleteDocument,
    refreshDocuments,
    filterFiles
  };
};

const DocumentsProvider = ({ children, ...props }) => {
  const {
    files,
    shownFiles,
    loading,
    searchInput,
    setSearchInput,
    searchDocuments,
    filterState,
    setFilterState,
    checkedFilterState,
    setCheckedFilterState,
    hasPermission,
    showFacility,
    deleteDocument,
    refreshDocuments,
    filterFiles
  } = initDocumentsContext(props);

  const contextValue = useMemo(
    () => ({
      files,
      shownFiles,
      loading,
      searchInput,
      setSearchInput,
      searchDocuments,
      filterState,
      setFilterState,
      checkedFilterState,
      setCheckedFilterState,
      hasPermission,
      showFacility,
      deleteDocument,
      refreshDocuments,
      filterFiles
    }),
    [
      files,
      shownFiles,
      loading,
      searchInput,
      setSearchInput,
      searchDocuments,
      filterState,
      setFilterState,
      checkedFilterState,
      setCheckedFilterState,
      hasPermission,
      showFacility,
      deleteDocument,
      refreshDocuments,
      filterFiles
    ]
  );

  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
    </DocumentsContext.Provider>
  );
};

/**
 * @typedef {Object} DocumentsContext
 * @property {array} files,
 * @property {array} folders,
 * @property {boolean} loading,
 * @property {string} searchInput,
 * @property {function} setSearchInput,
 * @property {function} searchDocuments,
 */

/**
 * @returns {DocumentsContext} returns DocumentsContext
 */
const useDocumentsContext = () => {
  const context = useContext(DocumentsContext);

  return context;
};

export { DocumentsProvider, useDocumentsContext };
