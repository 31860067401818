import React from 'react';
import styles from './DocumentsList.module.scss';

import { DocumentSkeleton } from 'shared/skeleton';
import DocumentsSearch from '../documents-search';
import DocumentsFilter from '../documents-filter';
import UploadFile from '../upload-file';
import DocumentsNavigation from '../documents-navigation';
import FilesList from '../files-list';

import { DocumentsProvider, useDocumentsContext } from '../context';

const DocumentsList = () => {
  const { loading, hasPermission } = useDocumentsContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <DocumentsSearch />
        <DocumentsFilter />
      </div>

      <div className={styles.subheader}>
        <DocumentsNavigation />
        {hasPermission.uploadDocument && <UploadFile />}
      </div>

      <div className={loading ? styles.loadingList : styles.list}>
        {loading ? <DocumentSkeleton amount={4} /> : <FilesList />}
      </div>
    </div>
  );
};

export default () => (
  <DocumentsProvider>
    <DocumentsList />
  </DocumentsProvider>
);
