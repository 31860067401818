import { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useParams } from 'react-router';

import ChannelList from './channel-list-section/chats-list/ChannelList';
import ContactDetails from './contact-details/ContactDetails';
import NoResult from 'shared/no-result';
import ChatDetails from 'modules/users-chats/chat-details';
import DataSource from 'components/data-source-core/DataSource';

import { If } from 'shared/utilities';

import { useChatsTabContext } from './ChatsTabContext';

const ChatsTab = () => {
  const { userId, chatId } = useParams();

  const {
    chatSelected,
    setChatSelected,
    contactSelected,
    showContactDetails,
    chats,
    loadingUserProfileChats,
  } = useChatsTabContext();

  useEffect(() => {
    if (chatId) {
      setChatSelected(chatId);
    }
  }, [chatId]);

  if (!loadingUserProfileChats && chats?.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 180px)',
        }}
      >
        <NoResult text={'There are no Chats'} />
      </Box>
    );
  }

  return (
    <Grid container sx={sxChatTabs.chatsTabContainer}>
      <Grid item xs={3} sx={sxChatTabs.chatsList}>
        <ChannelList />
      </Grid>
      <Grid item xs={showContactDetails && contactSelected ? 6 : 9}>
        <ChatDetails id={chatSelected} userId={userId} />
      </Grid>
      <If condition={showContactDetails && contactSelected}>
        <Grid item xs={3} sx={sxChatTabs.contactDetail}>
          <DataSource.Cache
            id={contactSelected?.id}
            sourceName="userProfileInfo"
          >
            <ContactDetails contactSelected={contactSelected} />
          </DataSource.Cache>
        </Grid>
      </If>
    </Grid>
  );
};

export default ChatsTab;

const sxChatTabs = {
  chatsTabContainer: {
    height: '100%',
  },
  contactDetail: {
    borderLeft: '1px solid #EBEBEF',
  },
  chatsList: {
    borderRight: '1px solid #EBEBEF',
  },
};
