import React, { useEffect } from 'react';
import styles from './CreateStaff.module.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';
import SelectAllButton from './SelectAllButton';
import NameEmail from './NameEmail';

import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';
import { useStepperContext } from 'context';

const RoleCheckbox = ({ id, label }) => {
  const { watch, trigger, setValue } = useFormContext();
  const value = watch('roles');

  const onCheck = (evt) => {
    setValue('roles', { ...value, [id]: evt.target.checked });
    trigger();
  };

  return (
    <FormControlLabel
      classes={{
        root: styles.checkbox__wrapper,
        label: styles.checkbox__label,
      }}
      control={
        <Checkbox
          classes={{ root: styles.checkbox }}
          onChange={onCheck}
          checked={!!value[id]}
        />
      }
      label={label}
    />
  );
};

const RolesStep = ({ loading }) => {
  const { roles } = useAuthContext();
  const { register, trigger, formState } = useFormContext();
  const { onPrevStep } = useStepperContext();

  register('roles');

  useEffect(() => {
    trigger();
  }, []);

  return (
    <div className={styles.rolesStep}>
      <NameEmail />
      <div className={styles.rolesStep__header}>
        <SelectAllButton name="roles" listName="roles" />
      </div>
      <div className={styles.checkboxList}>
        {roles.map(({ id, name }) => (
          <RoleCheckbox key={id} id={id} label={name} />
        ))}
      </div>
      <div className={styles.rolesStep__submit}>
        <div className={styles.rolesStep__btnWrapper}>
          <Button
            className={styles.button}
            onClick={onPrevStep}
            display="secondary"
            disabled={loading}
          >
            Back
          </Button>
          <Button
            className={styles.button}
            disabled={loading || !formState.isValid}
            display="primary"
            type="submit"
          >
            Create
          </Button>
        </div>
        <FormLoader loading={loading} />
      </div>
    </div>
  );
};

export default RolesStep;
