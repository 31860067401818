import { useReducer, useState } from 'react';

export function sortReducer(state, payload) {
  const getFullName = (person) => `${person.firstName} ${person.lastName}`;
  const priority = {
    OPEN: 5,
    PENDING: 4,
    APPEAL: 3,
    RESOLVE: 2,
    CLOSED: 1,
  };
  switch (true) {
    case payload.type === 'string': {
      return payload.descendent === true
        ? state.sort((a, b) => a[payload.key].localeCompare(b[payload.key]))
        : state.sort((a, b) => -a[payload.key].localeCompare(b[payload.key]));
    }
    case payload.type === 'name': {
      return payload.descendent === false
        ? state.sort((a, b) =>
          getFullName(a[payload.key]).localeCompare(
            getFullName(b[payload.key]),
          ),
        )
        : state.sort(
          (a, b) =>
            -getFullName(a[payload.key]).localeCompare(
              getFullName(b[payload.key]),
            ),
        );
    }

    case payload.type === 'date': {
      return payload.descendent === true
        ? state.sort(
          (a, b) => new Date(a[payload.key]) - new Date(b[payload.key]),
        )
        : state.sort(
          (a, b) => new Date(b[payload.key]) - new Date(a[payload.key]),
        );
    }

    case payload.type === 'number': {
      return payload.descendent === false
        ? state.sort((a, b) => a[payload.key] - b[payload.key])
        : state.sort((a, b) => b[payload.key] - a[payload.key]);
    }
    case payload.type === 'category': {
      return payload.descendent === true
        ? state.sort(
          (a, b) => priority[a[payload.key]] - priority[b[payload.key]],
        )
        : state.sort(
          (a, b) => priority[b[payload.key]] - priority[a[payload.key]],
        );
    }
    default:
      return state;
  }
}

export const useToogleSort = (initOrder) => {
  const [order, setOrder] = useState(!!initOrder);

  const toggleOrder = () => {
    return setOrder(!order);
  };

  return [order, toggleOrder];
};

const useSort = (obj) => {
  const initialState = { data: obj };
  const [state, dispatch] = useReducer(sortReducer, initialState);

  return [state, dispatch];
};

export default useSort;
