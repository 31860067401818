import { useMutation } from '@tanstack/react-query';
import { useGraphql } from 'context';

export default function useBlockUser( isFnf ) {
	const api = useGraphql();

	function blockUserMutation( payload ) {
		return isFnf ? api.controller.fnf.block(payload) : api.controller.inmate.block(payload);
	}

	return useMutation({ mutationFn: blockUserMutation });

}