import { Grid, Box, Typography } from '@mui/material';

import format from 'date-fns/format';

import Avatar from 'shared/avatar';
import HighlightLink from 'shared/text-highlighter/HighlightLink';

import { generatePath, useHistory } from 'react-router';
import { toTitleCase } from 'utils';
import { routes } from 'routing';

export default function CardInfo( { owner, date, searchTerm, id, ownerId, isMessageBlocked = false } ) {
	const { sxBoxWrapper } = useCardInfoStyles();
	const history = useHistory();
	const formatedDate = format(new Date(date), 'MMM  dd, yyyy hh:mm a');
	const [firstName, lastName] = owner.split(' ');

	function onClickLinkHandler( evt ) {
		evt.stopPropagation();
		history.push(
			`${generatePath(routes.user, {
				userId: ownerId,
			})}`,
		);
	}

	return (
		<Grid container mt={0.5} ml={2} flexDirection='row' alignItems='center' justifyContent='flex-start'>
			<Box sx={{ width: 35, height: 35, marginRight: 1 }}>
				<Avatar
					id={id}
					firstName={firstName}
					lastName={lastName}
					src={''}
					sxAvatar={{ width: 35, height: 35 }}
				/>
			</Box>
			<Box sx={sxBoxWrapper}>
				<Box>
					<HighlightLink
						substring={searchTerm}
						string={toTitleCase(owner)}
						sxHighlighter={HIGHLIGHTED_STYLE}
						onClick={onClickLinkHandler}
						sxString={{ fontWeight: 'regular', fontSize: 18, lineHeight: '22px', color: isMessageBlocked ? 'white' : '#101010' }}
					/>
					<Typography variant='regularGrey3' fontSize={13}>
						{`${formatedDate}`}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
}

function useCardInfoStyles() {
	return {
		sxBoxWrapper: {
			display: 'flex',
			flexDirection: 'column',
			height: 35,
			padding: 1,
		},
	};
}

const HIGHLIGHTED_STYLE = {
	highlighted: {
		backgroundColor: '#F8A80C',
		color: '#101010',
	},
};