import { createContext, useState } from 'react';
import { usePhotoHistory, useToggler } from 'hooks';

export const AvatarModalContext = createContext();

function initialContextValue(id) {
  const photoHistoryQuery = usePhotoHistory(id);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photoHasBeenBlocked, setPhotoHasBeenBlocked] = useToggler();

  function selectPhoto(index) {
    setCurrentIndex(index);
  }

  return {
    id,
    selectPhoto,
    photoHistoryQuery,
    currentIndex,
    photoHasBeenBlocked,
    setCurrentIndex,
    setPhotoHasBeenBlocked,
  };
}

export function AvatarModalProvider({ children, userId }) {
  const value = initialContextValue(userId);
  return (
    <AvatarModalContext.Provider value={value}>
      {children}
    </AvatarModalContext.Provider>
  );
}
