import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Download = (props) => (
  <SvgIcon width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16663 11.6666L9.16663 1.66663L10.8333 1.66663L10.8333 11.6666L9.16663 11.6666Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58329 15V15.8333C4.58329 16.2936 4.95639 16.6667 5.41663 16.6667H14.5833C15.0435 16.6667 15.4166 16.2936 15.4166 15.8333V15H17.0833V15.8333C17.0833 17.214 15.964 18.3333 14.5833 18.3333H5.41663C4.03591 18.3333 2.91663 17.214 2.91663 15.8333V15H4.58329Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41077 7.25596L5.58928 6.07745L10 10.4882L14.4108 6.07745L15.5893 7.25596L10 12.8452L4.41077 7.25596Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Download;
