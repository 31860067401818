import Stack from '@mui/material/Stack';

import ActionSwitch from './ActionSwitch';
import getControlActions from './getControlActions';

const DeviceControls = () => {
	const CONTROL_ACTIONS = getControlActions();
	const { sxStack } = userDeviceControlsStyles();
	return (
		<Stack sx={sxStack}>
			{CONTROL_ACTIONS.map(
				( action ) =>
					<ActionSwitch
						key={action.label} label={action.label}
						mutation={action?.sdkMutation}
						defaultCheckStatus={action.checkState}
						inputModal={action.inputModal}
						ModalComponent={action?.modal?.ModalComponent}
						modalTitle={action?.modal?.modalTitle}
						handleSubmit={action?.modal?.handleSubmit}
						IconButton={action.button}
						disableAction={action.disableAction}
					/>)}
		</Stack>
	);
};

export default DeviceControls;

function userDeviceControlsStyles() {
	return {
		sxStack: {
			marginTop: 2,
			width: '100%',
			justifyContent: 'space-around',
			alignItems: 'space-between',
		},
	};
}