import printJS from 'print-js';
import { toast } from 'react-toastify';

const usePrint = ( contentId, handleMarkRequest = () => {
} ) => {
	const printStyles = `
			#${contentId} {
				display: inherit !important;
				}
      .page {
        margin-bottom: 20px;
        padding: 10px;
        page-break-after: always; 
      }
    `;
	return printJS({
		onPrintDialogClose: handleMarkRequest,
		onError: () => toast.error('Something went wrong loading your document.'),
		printable: `${contentId}`,
		type: 'html',
		style: printStyles,
	});
};

export default usePrint;



