import { useCallback, useEffect, useRef, useState } from 'react';

import { from, Subject } from 'rxjs';
import { map, pluck, switchMap, tap } from 'rxjs/operators';

import { useGraphql } from 'context';
import { useChatContext } from 'modules/users-chats/context';
import { useChatsTabContext } from 'modules/users-chats/user-details/chats-tab/ChatsTabContext';
import { SearchChannelMessagesQuery } from 'kiwi-sdk';
import { sortReducer } from 'hooks/useSort';

function useSearchMessage() {
  const api = useGraphql();
  const nextPage = useRef(undefined);

  const [search$] = useState(new Subject());
  const [searchValue, setSearchValue] = useState('');

  const { chatSelected: chatId } = useChatsTabContext();
  const {
    searchTerm,
    setSearchTerm,
    setSearchResults,
    searchResults,
    toggleLoadingSearch,
    setSearchResultsTotal,
  } = useChatContext();

  function searchMessages(input) {
    const observer = from(
      api.send(
        SearchChannelMessagesQuery({
          term: input,
          channelId: chatId,
          limit: 40,
          nextPage: nextPage.current,
        })
      )
    );

    nextPage.current = false;

    return observer;
  }

  useEffect(() => {
    const sub = search$
      .pipe(
        tap(() => toggleLoadingSearch(true)),
        switchMap(({ firstPage, input }) =>
          searchMessages(input).pipe(
            tap((res) => {
              nextPage.current = res?.data?.nextPage;
              if (firstPage) {
                setSearchResultsTotal(res?.data?.total);
              }
            }),
            map((messages) => ({
              messages,
              payload: { firstPage, searchTerm: input },
            }))
          )
        )
      )
      .subscribe({
        next: ({ messages = [], payload }) => {
          const { searchTerm, firstPage } = payload;
          const {
            message = [],
            image = [],
            voice = [],
            video = [],
          } = messages?.data?.items;
          const unzippedMessages = messages?.data?.items
            ? [...message, ...image, ...voice, ...video]
            : [];
          if (firstPage) {
            setSearchResults(
              sortReducer(unzippedMessages, {
                type: 'date',
                descendent: false,
                key: 'createdAt',
              })
            );
            setSearchTerm(searchTerm);
          } else {
            setSearchResults((state) =>
              sortReducer([...state, ...unzippedMessages], {
                type: 'date',
                descendent: false,
                key: 'createdAt',
              })
            );
          }
          toggleLoadingSearch(false);
        },
        error: (err) => {
          console.error(err);
          toggleLoadingSearch(false);
        },
      });

    return () => {
      sub.unsubscribe();
      setSearchTerm('');
    };
  }, [setSearchTerm, chatId]);

  const loadNextPage = useCallback(() => {
    if (nextPage.current) {
      search$.next({ firstPage: false, input: searchTerm });
    }
  }, [search$]);

  const handleSearch = useCallback(
    (input) => {
      const inputValue = input.trim();

      if (!inputValue.length) return;

      setSearchTerm('');
      setSearchResults([]);
      nextPage.current = undefined;
      search$.next({ firstPage: true, input: inputValue });
    },
    [search$]
  );

  return { searchValue, setSearchValue, loadNextPage, handleSearch };
}

export default useSearchMessage;
