import { Grid, Box } from '@mui/material';
import CollapsibleList from 'shared/collapsible-list/CollapsibleList';

import NavigationItem from 'shared/navigation-item';
import ProfileOptions from './profile-options/ProfileOptions';

import ProfileAvatar from './ProfileAvatar';

export default function ProfileManagement() {
	return (
		<Box mr={3} sx={{ height: '100%' }}>
			<CollapsibleList component={<ProfileAvatar />} list={<OptionList />} />
		</Box>
	);
};

const OptionList = () => {
	const PROFILE_OPTIONS = ProfileOptions();
	return (
		<Grid container gap={1.5} sx={sxGridList} justifyContent='center'>
			{PROFILE_OPTIONS.map(( { label, Item, to } ) => {
				return (
					<NavigationItem key={label} to={to} collapse={false}>
						<Item />
					</NavigationItem>
				);
			})}
		</Grid>
	);
};

const sxGridList = () => {
	return {
		width: 170,
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		position: 'absolute',
		height: '200px',
		bgcolor: 'customPalette.grey6',
		padding: '8px 12px',
		borderRadius: '0 0 8px 8px',
		zIndex: 1000,
	};
};
