import { useMutation } from '@tanstack/react-query';
import { useGraphql } from 'context';

export default function useUnblockUser( isFnf ) {
	const api = useGraphql();
	function unblockUserMutation( payload ) {
		return isFnf ? api.controller.fnf.unblock(payload) : api.controller.inmate.unblock(payload);
	}

	return useMutation({ mutationFn: unblockUserMutation });
}