import React from 'react';
import styles from './RolesList.module.scss';

import RoleItem from './RoleItem';

const RolesList = ({ rolesArray }) => {
  return (
    <div className={styles.wrapper}>
      {rolesArray.map((role) => (
        <RoleItem key={role.id} role={role} />
      ))}
    </div>
  );
};

export default RolesList;
