import React, { memo } from 'react';
import styles from './ChatsTab.module.scss';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { CHAT_SORTING_TYPES } from '../../constants';

const renderValue = (value) => CHAT_SORTING_TYPES[value];

const SortingSelector = ({ chatSorting, onSort }) => (
  <Grid
    classes={{ root: styles.sorting }}
    justifyContent="flex-end"
    alignItems="center"
    container
    item
  >
    <label className={styles.sorting__label}>Sort by:</label>
    <Select
      classes={{ root: styles.sorting__input, icon: styles.sorting__icon }}
      value={chatSorting || ''}
      onChange={(evt) => onSort(evt.target.value)}
      renderValue={renderValue}
      variant="standard"
    >
      {Object.keys(CHAT_SORTING_TYPES).map((value) => (
        <MenuItem
          classes={{ selected: styles.sorting__selectedItem }}
          key={value}
          value={value}
        >
          {renderValue(value)}
        </MenuItem>
      ))}
    </Select>
  </Grid>
);

export default memo(SortingSelector);
