const QUERY_KEYS = {
  ANALYTICS_LIST_INFO: 'ANALYTICS_LIST_INFO',
  BATCH_PRINT_REQUESTS: 'BATCH_PRINT_REQUESTS',
  CHANNEL_MESSAGES: 'CHANNEL_MESSAGES',
  CHANNEL_INFO: 'CHANNEL_INFO',
  CHANNEL_LAST_MESSAGE: 'CHANNEL_LAST_MESSAGE',
  CHANNEL_FILTERED_MESSAGES: 'CHANNEL_FILTERED_MESSAGES',
  CHAT_METRICS_INVITATIONS: 'CHAT_METRICS_INVITATIONS',
  DOC_BOOKMARKS: 'DOC_BOOKMARKS',
  DOC_DOCUMENTS: 'DOC_DOCUMENTS',
  DOC_PRINT_REQUESTS: 'DOC_PRINT_REQUESTS',
  FACILITIES_PRINT_REQUESTS: 'FACILITIES_PRINT_REQUESTS',
  FLAGGED_MESSAGES_ANALYSIS: 'FLAGGED_MESSAGES_ANALYSIS',
  INMATE_PRINT_REQUESTS: 'INMATE_PRINT_REQUESTS',
  USER_INITIAL_LOAD_DATA: 'USER_INITIAL_LOAD_DATA',
  MESSAGES_TYPES_ANALYSIS: 'MESSAGES_TYPES_ANALYSIS',
  MULTICHANNEL_MESSAGES: 'MULTICHANNEL_MESSAGES',
  RESET_PASSWORD_BY_REQUEST_ID: 'RESET_PASSWORD_BY_REQUEST_ID',
  SEARCH_ALL: 'SEARCH_ALL',
  SEARCH_DOC_STAFF: 'SEARCH_DOC_STAFF',
  SEARCH_IMAGE_MESSAGES: 'SEARCH_IMAGE_MESSAGES',
  SEARCH_INMATE: 'SEARCH_INMATE',
  SEARCH_FNF: 'SEARCH_FNF',
  SEARCH_TEXT_MESSAGES: 'SEARCH_TEXT_MESSAGES',
  SEARCH_VIDEO_MESSAGES: 'SEARCH_VIDEO_MESSAGES',
  SEARCH_VOICE_MESSAGES: 'SEARCH_VOICE_MESSAGES',
  SURVEY_RESPONSES: 'SURVEY_RESPONSES',
  PRINT_REQUEST_BATCHES: 'PRINT_REQUEST_BATCHES',
  PROFILE_HISTORY: 'PROFILE_HISTORY',
  SENTIMENT_ANALYSIS: 'SENTIMENT_ANALYSIS',
  TOTAL_ACTIVITY_ANALYSIS: 'TOTAL_ACTIVITY_ANALYSIS',
  USER_CHATS: 'USER_CHATS',
  USER_FLAGGED_MESSAGES: 'USER_FLAGGED_MESSAGES',
  USER_INVITATIONS_ANALYSIS: 'USER_INVITATIONS_ANALYSIS',
  USER_PROFILE_INFO: 'USER_PROFILE_INFO',
};

export default QUERY_KEYS;
