import { useEffect, useState } from 'react';
import { useGraphql } from 'context';

import { Subject, switchMap } from 'rxjs';
import { DOCUserInfoQuery } from 'kiwi-sdk';

const useDocInfo = (id) => {
  const [selectedStaffInfo, setSelectedStaffInfo] = useState([]);

  const [loadDOCInfo$] = useState(new Subject());
  const api = useGraphql();

  function loadStaff(id) {
    return api.send(DOCUserInfoQuery({ id }));
  }

  useEffect(() => {
    const sub = loadDOCInfo$
      .pipe(
        switchMap((payload) => {
          return loadStaff(payload);
        })
      )
      .subscribe({
        next: (results) => {
          setSelectedStaffInfo(results.data);
        },
        error: (err) => {
          console.log(err);
        },
      });
    loadDOCInfo$.next(id);
    return () => sub.unsubscribe();
  }, []);

  return { selectedStaffInfo };
};

export default useDocInfo;
