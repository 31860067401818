import { Box } from '@mui/material';
import SearchAndLogo from 'shared/sidebar/SearchAndLogo';
import Navigation from 'shared/sidebar/navigation/Navigation';
import ProfileManagement from 'shared/sidebar/profile/ProfileManagement';

export default function Sidebar() {
	const { sxSidebarBox } = useSidebarStyles();
	return (
		<Box sx={sxSidebarBox}>
			<SearchAndLogo />
			<Navigation />
			<ProfileManagement />
		</Box>
	);
};

;

function useSidebarStyles() {
	return {
		sxSidebarBox: {
			alignItems: 'center',
			bgcolor: 'customPalette.grey6',
			display: 'flex',
			height: 74,
			justifyContent: 'space-around',
			p: 0,
			width: '100%',
		},
	};
}
