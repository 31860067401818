import { EmptyResultContainer, FallbackContainer } from 'shared/suspense-list/Containers';

import NoResult from 'shared/no-result';
import { Skeleton } from 'shared/skeleton';

function Suspense( props ) {

	const {
		total = 1,
		loading = false,
		noResultComponent,
		noResultText = 'No results found',
		fallback,
		children,
	} = props;

	if (loading && total === 0) {
		return (
			<FallbackContainer>
				{fallback || <Skeleton amount={4} />}
			</FallbackContainer>
		);
	}

	if (!loading && total === 0) {
		return  (
			<EmptyResultContainer>
			{noResultComponent ||
				<NoResult
					text={noResultText}
					textVariant='medium'
					imageSize='medium'
					typographyProps={{ sx: { bottom: -5, color: 'customPalette.grey4' } }}
				/>}
		</EmptyResultContainer>
		);
	}

	return children;

};

export default Suspense;
