import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './StaffList.module.scss';

import Grid from '@mui/material/Grid';
import NoResult from 'shared/no-result';
import CreateStaff from '../create-staff';
import StaffItem from './StaffItem';

import { Subject, from } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { useAdminSettingsContext } from '../context';
import { useGraphql } from 'context';
import { useToggler } from 'hooks';
import { sortReducer, useToogleSort } from 'hooks/useSort';
import { TableLoader } from 'shared/loaders';
import { Box } from '@mui/material';

const StaffList = () => {
	const api = useGraphql();

	const initialSortPayload = {
		type: 'string',
		descendent: true,
		key: 'info',
	};

	const { roleSelected, docListByRole, setDocListByRole } =
		useAdminSettingsContext();

	const nextPage = useRef(undefined);

	const [loadDocUsersByRole$] = useState(new Subject());

	const [createModal, toggleCreateModal] = useToggler();

	const [loading, toggleLoading] = useToggler();

	const [payload, setPayload] = useState(initialSortPayload);
	const [order, toggleOrder] = useToogleSort(false);

	const sortedStaffList = useMemo(() => {
		toggleOrder();
		return sortReducer(docListByRole, payload);
	}, [payload, docListByRole]);

	function loadDocUsersByRole( agencyId, id ) {
		const observer = from(
			api.controller.agency.docUsersByRole({
				agencyId: agencyId,
				roleId: id,
				limit: 40,
				nextPage: nextPage.current,
			}),
		);

		nextPage.current = false;
		return observer;
	}

	useEffect(() => {
		const sub = loadDocUsersByRole$
		.pipe(
			tap(() => toggleLoading(true)),
			switchMap(() =>
				loadDocUsersByRole(roleSelected.agencyId, roleSelected.id),
			),
			map(( data ) => data.items),
		)
		.subscribe({
			next: ( results = [] ) => {
				setDocListByRole(results);
				toggleLoading(false);
			},
			error: ( err ) => {
				console.log(err);
				toggleLoading(false);
			},
		});

		loadDocUsersByRole$.next();
		return () => sub.unsubscribe();
	}, [roleSelected]);

	return (
		<div className={styles.wrapper}>
			{loading && !docListByRole.length ? (
				<Box sx={{ display: 'flex', flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
					<TableLoader />
				</Box>
			) : !docListByRole.length ? (
				<Grid
					container
					direction='column'
					justifyContent='flex-end'
					alignItems='center'
					pt={'20%'}
				>
					<NoResult>
						<p>There are no users with this role.</p>
					</NoResult>
				</Grid>
			) : (
				<div className={styles.list}>
					<Grid
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						container
						sx={{ cursor: 'pointer', p: '10px 20px' }}
					>
						<Grid item xs={6}>
							<span className={styles.staff__label}>Name</span>
						</Grid>
						<Grid item xs={4}>
							<span className={styles.staff__label}>Email</span>
						</Grid>
						<Grid
							xs={2}
							item
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							onClick={() => {
								setPayload({
									type: 'string',
									descendent: order,
									key: 'info',
								});
							}}
							sx={{ cursor: 'pointer' }}
						>
							<span className={styles.staff__label}>Sort by: Name</span>
						</Grid>
					</Grid>
					{sortedStaffList.map(( item ) => (
						<StaffItem key={item.id} info={item.info} id={item.id} {...item} />
					))}
				</div>
			)}
			<CreateStaff open={createModal} onClose={toggleCreateModal} />
		</div>
	);
};

export default StaffList;
