const routes = {
  login: '/login',
  passwordForgot: '/password-forgot',
  passwordReset: '/reset-password',
  sigUp: '/sign-up',
  newPassword: '/new-password',
  home: '/home',
  search: '/search/:searchTerm',
  user: '/home/user/:userId',
  chat: '/home/user/:userId/chat/:chatId',
  bulletins: '/bulletins',
  surveys: '/surveys',
  surveyResults: '/surveys/:surveysId',
  surveysCreate: '/surveys/create',
  documents: '/documents',
  printRequests: '/print-requests',
  batchDetails: '/batch-details/:batchId',
  settings: '/settings',
  support: '/support',
  chatsMetrics: '/chats-metrics',
  singleStaff: '/settings/staff/:staffId',
};

export default routes;

/* routes disabled for now *
reports: '/reports',
kiwiCop: '/kiwiCop',
tablets: '/tablets',
kiosks: '/kiosks',
forms: '/forms',
singleForms: '/forms/:formId',
*/
