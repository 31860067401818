const useCheckDuplicate = (oldRoles, newRole, key) => {
  const comparator = (role) => {
    const newRoleToCompare =
      typeof newRole === 'string' ? newRole : newRole.sort().join();
    const oldRoleToCompare =
      typeof newRole === 'string' ? role[key] : role[key].sort().join();
    return oldRoleToCompare === newRoleToCompare;
  };
  const [duplicate] = oldRoles.filter(comparator);
  const hasDuplicate = duplicate ? true : false;
  const duplicateName = duplicate?.name;

  return [hasDuplicate, duplicateName];
};

export default useCheckDuplicate;
