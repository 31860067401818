import React, { useEffect, useState } from 'react';
import styles from './FilesList.module.scss';

import Modal, { ModalTitle } from 'shared/modal';
import { useToggler } from 'hooks';
import Avatar from 'shared/avatar';
import formatName from 'utils/formatInmateName';
import removeIdPrefix from 'utils/removeIdPrefix';
import { Skeleton } from 'shared/skeleton';
import { from } from 'rxjs';
import NoResult from 'shared/no-result';
import format from 'date-fns/format';

const InmateCard = ({ inmate }) => {
  const { inmateId, inmateEid, firstName, lastName, createdAt } = inmate;
  return (
    <div className={styles.inmateCards__item}>
      <Avatar id={inmateId} lastName={lastName} firstName={firstName} />
      <div className={styles.inmateCards__item__ownerName}>
        <span>{formatName(firstName, lastName)}</span>
        <span className={styles.inmateCards__item__ownerName__ownerId}>
          {removeIdPrefix(inmateEid)}
        </span>
      </div>
      <div className={styles.inmateCards__item__dateRead}>
        <span>{format(Date.parse(createdAt), 'MMM dd, yyyy')}</span>
        <span className={styles.inmateCards__item__ownerName__ownerId}>
          Date viewed
        </span>
      </div>
    </div>
  );
};

const InmateViewedModal = ({
  open,
  handleClose,
  api, //GraphQL
  documentId,
}) => {
  const [loading, toggleLoading] = useToggler();
  const [fileViews, setFileViews] = useState([]);

  useEffect(() => {
    if (open) {
      toggleLoading(true);
      const fetchFileViews = from(
        api.controller.document.docGetReadDocumentInmates({
          documentId: documentId,
        })
      );
      fetchFileViews.subscribe((data) => {
        setFileViews(data.items);
        toggleLoading(false);
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={styles.inmateViewedModal}
    >
      <ModalTitle
        text="Inmates who viewed this file"
        onClose={handleClose}
        onClick={() => toggleLoading()}
        className={styles.inmateViewedModal__header}
      />
      <div className={styles.inmateCards__divider}></div>
      <div className={styles.inmateCards} key={documentId}>
        {loading ? (
          <Skeleton amount={3} />
        ) : fileViews?.length ? (
          fileViews.map((inmate) => (
            <InmateCard key={inmate.inmateId} inmate={inmate} />
          ))
        ) : (
          <NoResult text="No inmates have viewed this file" />
        )}
      </div>
    </Modal>
  );
};

export default InmateViewedModal;
