import styles from './CardText.module.scss';
import Box from '@mui/material/Box';

import HighlightText from 'shared/text-highlighter';

export default function TextContent({ text, searchTerm }) {
  const { sxBoxWrapper } = useCardTextStyles();

  return (
    <Box className={styles.container} sx={sxBoxWrapper}>
      <HighlightText
        substring={searchTerm}
        string={text}
        sxHighlighter={HIGHLIGHTED_STYLE}
        sxString={{ fontSize: '13px', color: 'customPalette.grey1' }}
      />
    </Box>
  );
}

function useCardTextStyles() {
  return {
    sxBoxWrapper: {
      bgcolor: 'customPalette.grey6',
      marginX: 'auto',
      marginTop: 2.5,
      marginBottom: 1.5,
      padding: 1.5,
      width: 'calc(100% - 48px)',
      height: 80,
      overflow: 'hidden auto',
      borderRadius: 1,
    },
  };
}

const HIGHLIGHTED_STYLE = {
  highlighted: {
    backgroundColor: '#F8A80C',
    color: '#101010',
  },
};
