import { useQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useInvitationsAnalysis(userId) {
  const api = useGraphql();

  function getInvitationsAnalysis() {
    return api.controller.analytics.inviteAggregate({
      id: userId,
    });
  }

  const invitationsAnalysisQuery = useQuery({
    queryKey: [QUERY_KEYS.USER_INVITATIONS_ANALYSIS, userId],
    queryFn: getInvitationsAnalysis,
  });
  return invitationsAnalysisQuery;
}
