import { useGraphql } from 'context';
import { useMediaSrc } from 'hooks';

export default function getAvatarSrc(mediaLocation: string | null | undefined) {
  // @ts-ignore
  const { getPhotos } = useGraphql();
  // @ts-ignore
  const [imageSrc] = useMediaSrc(getPhotos)({ itemKey: mediaLocation });

  return imageSrc;
}
