import styled from '@mui/material/styles/styled';

function PaginationButton( props ) {
	const { children, isSelected, onClick, disabled } = props;

	return (
		<StyledPaginationButton isSelected={isSelected} onClick={onClick} disabled={disabled}>
			{children}
		</StyledPaginationButton>
	);
};

export default PaginationButton;

const StyledPaginationButton = styled('span')(( { theme, isSelected = false } ) => ({
	width: 35,
	height: 35,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	borderRadius: '50%',
	borderColor: theme.palette.customPalette.grey4,
	backgroundColor: isSelected ? theme.palette.customPalette.grey3 : 'initial',
	cursor: 'pointer',

	fontSize: 14,
	color: isSelected ? theme.palette.customPalette.white : theme.palette.customPalette.grey3,

	'&:hover': {
		backgroundColor: theme.palette.customPalette.grey6,
		borderColor: 'initial',
		color: theme.palette.customPalette.grey3,
	},
}));
