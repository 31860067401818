import { createTheme } from '@mui/material/styles';

const KiwiTheme = createTheme({
	palette: {
		primary: {
			main: '#03A882',
		},
		grey: {
			main: '#646668',
		},
		customPalette: {
			green1: '#03a882',
			green2: '#038768',
			green3: '#025d48',
			grey1: '#101010',
			grey3: '#686873',
			grey4: '#9191a1',
			grey5: '#ebebef',
			grey6: '#f2f4f4',
			grey7: '#ffffff',
			white: '#ffffff',
			purple: '#3f1892',
			lightPurple: '#7247EC',
			success: '#03a882',
			error: '#df093c',
			warning: '#ffc226',
			info: '#551cf6',
			liveCallRed: '#FF0000',
		},
	},
	typography: {
		fontFamily: [
			'DM Sans',
			'-apple-system',
			'Roboto',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		regularRed: {
			fontWeight: 'regular',
			fontSize: 14,
			lineHeight: '22px',
			color: '#df093c',
		},
		regularBlack: {
			fontWeight: 'regular',
			fontSize: 14,
			lineHeight: '22px',
			color: '#101010',
		},
		regularGrey3: {
			fontWeight: 'regular',
			fontSize: 14,
			lineHeight: '22px',
			color: '#686873',
		},
		boldGrey3: {
			fontWeight: 'bold',
			fontSize: 14,
			lineHeight: '22px',
			color: '#686873',
		},
		regularGrey4: {
			fontWeight: 'regular',
			fontSize: 14,
			lineHeight: '22px',
			color: '#9191a1',
		},
		boldGrey4: {
			fontWeight: 'bold',
			fontSize: 14,
			lineHeight: '22px',
			color: '#9191a1',
		},
		boldBlack: {
			fontWeight: 'bold',
			fontSize: 14,
			lineHeight: '22px',
			color: '#101010',
		},
		bigBoldBlack: {
			fontWeight: 'bold',
			fontSize: 18,
			lineHeight: '28px',
			color: '#101010',
		},
		mediumGrey3: {
			fontWeight: 'medium',
			fontSize: 14,
			lineHeight: '22px',
			color: '#686873',
		},
		mediumGrey4: {
			fontWeight: 'medium',
			fontSize: 14,
			lineHeight: '22px',
			color: '#9191a1',
		},
		mediumBlack: {
			fontWeight: 'medium',
			fontSize: 14,
			lineHeight: '22px',
			color: '#101010',
		},
		sectionHeader: {
			fontWeight: 'bold',
			fontSize: 24,
			lineHeight: '31px',
		},
		messageText: {
			fontWeight: 'regular',
			fontSize: 16,
			lineHeight: '24px',
		},
		repliedMsgName: {
			fontWeight: 500,
			fontSize: 14,
			lineHeight: '24px',
		},
		messageDate: {
			fontWeight: 'regular',
			fontSize: 12,
			lineHeight: '20px',
		},
		sectionsTitle: {
			fontWeight: 'bold',
			fontSize: 16,
			lineHeight: '28px',
		},
		altThumbnailText: {
			fontWeight: 'medium',
			fontSize: 16,
			lineHeight: '20px',
			color: '#ffffff',
		},
	},
});

export default KiwiTheme;
