import React from 'react';

import routes from 'routing/routes';
import BoundaryType from 'shared/error-boundary/global-boundaries/BoundaryType';

import { useHistory, generatePath } from 'react-router-dom';

const DevBoundary = ( {
												message = '',
												stack = '',
												resetErrorState,
											} ) => {
	const history = useHistory();

	const devActionButtons = [{
		BtnText: 'Go Home', BtnAction: () => {
			history.push(generatePath(routes.home));
			resetErrorState();
		},
	}, {
		BtnText: 'Try Again', BtnAction: () => {
			resetErrorState();
		},
	}];

	return <BoundaryType title='Something went wrong' message={message} stack={stack} actions={devActionButtons} />;
};

export default DevBoundary;

