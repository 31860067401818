import React from 'react';
import cs from 'classnames';
import styles from './OutlinedSelectInput.module.scss';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CheckIcon from '@mui/icons-material/Check';

import { useFormContext } from 'react-hook-form';

const OutlinedSelectInput = ({
  className,
  required,
  placeholder,
  label,
  renderValue = (value) => value,
  name,
  options,
  formControl = true,
  onChange,
  value: propValue,
  ...props
}) => {
  const { register, watch } = useFormContext();

  const handleChange = formControl ? register(name).onChange : onChange;
  const value = formControl ? watch(name) : propValue;

  return (
    <Grid
      classes={{ root: cs(styles.select, className) }}
      alignItems="center"
      container
    >
      {label && (
        <label className={styles.select__label}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}

      <Select
        classes={{
          root: cs(
            styles.select__input,
            !value && styles['select__input--placeholder']
          ),
          icon: styles.select__icon,
        }}
        name={name}
        onChange={handleChange}
        renderValue={!value && placeholder ? () => placeholder : renderValue}
        MenuProps={{
          classes: {
            paper: styles.select__options,
          },
        }}
        required={required}
        defaultValue=""
        variant="standard"
        displayEmpty
        value={value}
        fullWidth
        {...props}
      >
        {placeholder && (
          <MenuItem
            classes={{ selected: styles.select__selectedItem }}
            value=""
            disabled
          >
            {placeholder}
          </MenuItem>
        )}

        {Object.keys(options).map((key) => (
          <MenuItem
            classes={{ selected: styles.select__selectedItem }}
            key={key}
            value={options[key]}
          >
            {renderValue(options[key])}
            {options[key] === value && <CheckIcon />}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default OutlinedSelectInput;
