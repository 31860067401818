import { Box, Typography } from '@mui/material';
import format from 'date-fns/format';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export default function TextMessageNotification( { date, messageReadStatus } ) {
	const { sxBox, sxReadMark } = useTextNotificationStyles(messageReadStatus);

	return (
		<Box sx={sxBox}>
			<Typography variant='messageDate' component='span'>
				{format(new Date(date), 'h:mm a')}
			</Typography>
			{messageReadStatus === 'sent' ? <DoneIcon sx={sxReadMark} /> : <DoneAllIcon sx={sxReadMark} />}
		</Box>
	);
}

const useTextNotificationStyles = ( status ) => ({
	sxBox: { display: 'flex', marginLeft: 'auto', alignItems: 'center', gap: 0.5 },
	sxReadMark: { color: status === 'read' ? 'primary.main' : 'grey.main', fontSize: '16px' },
});
