import React from 'react';
import styles from '../SurveyResults.module.scss';
import { useSurveyResultsContext } from 'modules/surveys/context';
import removeIdPrefix from 'utils/removeIdPrefix';
import Avatar from 'shared/avatar';
import UserLink from 'shared/user-link';

const ResponseItem = ( props ) => {
	const { owner, responseAnswers } = props;
	const { isSurveyAnonymous, currentSurveyResponseOwner, showIndividualResponse } = useSurveyResultsContext();

	const highlightResponse = {
		borderRadius: 16,
		borderColor: '#03a882',
	};

	return (
		<div
			className={styles.sidebar__responses__item}
			onClick={() => showIndividualResponse(responseAnswers, owner)}
			style={currentSurveyResponseOwner?.id === owner?.id ? highlightResponse : {}}>
			{isSurveyAnonymous ? <span>Response</span> :
				<>
					<Avatar
						id={owner?.id || ''}
						lastName={owner.firstName || ''}
						firstName={owner.lastName || ''}
					/>
					<div className={styles.sidebar__responses__item__ownerName}>
						<UserLink
							id={owner?.id}
							name={`${owner.firstName} ${owner.lastName}`}
							sxLink={{ fontSize: '16px', color: 'customPalette.grey1', cursor: 'pointer' }}
						/>
						<span className={styles.sidebar__responses__item__ownerName__ownerId}>{removeIdPrefix(owner.id)}</span>
					</div>
				</>
			}
		</div>
	);
};

export default ResponseItem;
