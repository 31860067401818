import React, { useCallback, useMemo } from 'react';
import cs from 'classnames';
import styles from './FlagModal.module.scss';

import Grid from '@mui/material/Grid';
import Form from 'shared/form';
import Button from 'shared/button';

import * as yup from 'yup';
import Modal, { ModalTitle } from 'shared/modal';
import { SelectInput } from 'shared/inputs';
import { useFormContext } from 'react-hook-form';
import { useChatContext } from '../context';
import { useAuthContext } from 'modules/auth/context';
import { useFlagMutations } from '../hooks';
import { useChatsTabContext } from '../user-details/chats-tab/ChatsTabContext';

const FlagModal = ({ open, toggleOpen }) => {
  const isFlagged = false;
  const { user } = useAuthContext();
  const { data, focusMessage } = useChatContext();
  const { chatSelected: selectedChat } = useChatsTabContext();

  const [_, flagMutationHandler] = useFlagMutations(
    isFlagged ? 'UNFLAG_MESSAGE' : 'FLAG_MESSAGE',
    savePostMutation
  );
  const [currentMessageInfo] = useMemo(() => {
    return data.filter((message) => message.id === focusMessage);
  }, [focusMessage]);

  function savePostMutation() {
    toggleOpen();
  }

  const handleSubmit = (form) => {
    flagMutationHandler({
      channelId: selectedChat,
      msgId: currentMessageInfo?.id,
      comment: 'No comment',
      reason: form.reason,
      by: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    });
  };

  return (
    <Modal className={cs(styles.modal)} open={open} onClose={toggleOpen}>
      <ModalTitle
        className={styles.modal__title}
        text="Flag a message"
        onClose={() => {
          toggleOpen();
        }}
      />
      <Form
        className={styles.form}
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
      >
        <SelectInput
          className={styles.form__input}
          name="reason"
          label="Moderation Flag"
          placeholder="Choose a moderation flag"
          options={FLAG_OPTIONS}
          validation={validation}
          variant="outlined"
          outlined
        />

        <Grid
          classes={{ root: styles.form__actions }}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CancelButton
            onClose={() => {
              toggleOpen();
            }}
          />
          <ConfirmButton />
        </Grid>
      </Form>
    </Modal>
  );
};

export default FlagModal;

const FLAG_OPTIONS = {
  DRUGS: 'Drugs',
  GANGS: 'Gangs',
  THREATS: 'Threats',
  SUICIDE: 'Suicide',
  OTHER: 'Other',
};

const formInitialValues = {
  reason: '',
};

const validation = yup.object({
  reason: yup.string().required(),
});

const CancelButton = (props) => {
  return (
    <Button
      className={styles.form__cancelButton}
      onClick={props.onClose}
      display="secondary"
      disabled={props.loading}
    >
      Cancel
    </Button>
  );
};

const ConfirmButton = () => {
  const { formState } = useFormContext();
  return (
    <Button
      className={styles.form__submitButton}
      type="submit"
      display="primary"
      disabled={!formState?.dirtyFields?.reason}
    >
      Confirm
    </Button>
  );
};
