import { Box, Grid, Typography } from '@mui/material';
import { toTitleCase } from 'utils';

export default function LegendBall(props) {
  const { color = '', name = '', value } = props;
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '18px',
            height: '18px',
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <h6 className="text-sm font-medium flex-wrap">
          {toTitleCase(name.replace('_', ' '))}
        </h6>
      </Grid>
      <Grid item px={1}>
        <Typography
          variant="h6"
          fontSize={14}
          sx={{
            display: 'flex',
            width: 20,
            justifyContent: 'center',
            color: 'customPalette.grey2',
          }}
        >
          {value ?? 0}
        </Typography>
      </Grid>
    </Grid>
  );
}
