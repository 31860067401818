import { memo } from 'react';
import { Box } from '@mui/material';

import ChartCard from 'modules/chats-metrics/charts/chart-card/ChartCard';
import Suspense from 'shared/suspense-list';
import useChatMetricsContext from 'modules/chats-metrics/hooks/useChatMetricsContext';
import EmptyChart from 'modules/chats-metrics/charts/EmptyChart';
import useDashboardSentiments from 'modules/chats-metrics/hooks/useDashboardSentiment';
import KiwiAreaChart from 'shared/trend-chart';
import ErrorBoundary from 'shared/error-boundary';

import { ChartLoader } from 'shared/loaders';
import { useGetParsedSentimentData } from 'hooks';
import { useAuthContext } from 'modules/auth/context';
import { dateRangeBuilder } from 'utils';
import { FilterTypes } from 'kiwi-sdk/dist/types/analytics/message';

function SentimentTrend() {
  const {
    dateRangeFilter,
    setSelectedAnalyticModalConfig,
    setSelectedOption,
    toggleFlaggedMessagesModal,
    setSentimentSelectedRangeFilter,
  } = useChatMetricsContext();
  const { agency, facilities } = useAuthContext();
  const { data = [], isLoading } = useDashboardSentiments(
    dateRangeFilter,
    agency,
    facilities
  );
  const parsedSentimentData = useGetParsedSentimentData(data);

  function onHandleAreaClick(data) {
    const currentDate = data.activeLabel;
    setSelectedOption([
      { term: FilterTypes.SENTIMENT, value: SENTIMENT_ENUM.NEGATIVE },
    ]);
    setSentimentSelectedRangeFilter(
      dateRangeBuilder({ interval: 'customDay', day: currentDate })
    );
    setSelectedAnalyticModalConfig({
      modalName: 'Sentiment Trend',
      showDateFilters: false,
      showChatPanel: true,
      listRenderItem: 'message',
      initialOptionsData: formatTrendData(parsedSentimentData, currentDate),
      search: true,
      filters: true,
      typesData: SENTIMENT_ENUM,
    });
    toggleFlaggedMessagesModal();
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, minHeight: '35%' }}>
      <ChartCard cardTitle="Sentiment Trend">
        <ErrorBoundary level="section" textVariant="medium" imageSize="medium">
          <Suspense
            loading={isLoading}
            total={parsedSentimentData.length}
            fallback={<ChartLoader />}
            noResultComponent={<EmptyChart />}
          >
            <KiwiAreaChart
              chartData={parsedSentimentData}
              dataKey="date"
              scale="sqrt"
              clickable={true}
              onHandleAreaClick={onHandleAreaClick}
            />
          </Suspense>
        </ErrorBoundary>
      </ChartCard>
    </Box>
  );
}

export default memo(SentimentTrend);

function formatTrendData(array, currentDate) {
  const dateSelectedInfo = array.find((item) => item.date === currentDate);
  return Object.entries(dateSelectedInfo)
    .filter((item) => item[0] !== 'date')
    .map((index) => ({ option: index[0], count: index[1] }));
}

const SENTIMENT_ENUM = {
  NEGATIVE: '-1',
  POSITIVE: '1',
  NEUTRAL: '0',
};
