import React from 'react';
import styles from './CreateStaff.module.scss';

import { TextInput } from 'shared/inputs';
import Button from 'shared/button';

import { useFormContext } from 'react-hook-form';
import { useStepperContext } from 'context';

const NextButton = () => {
  const { formState } = useFormContext();
  const { onNextStep } = useStepperContext();

  return (
    <Button
      className={styles.button}
      onClick={onNextStep}
      disabled={!formState.isDirty || !formState.isValid}
      display="primary"
    >
      Next
    </Button>
  );
};

const InfoStep = () => {
  return (
    <div className={styles.infoStep}>
      <p className={styles.infoStep__heading}>Personal info</p>
      <TextInput
        className={styles.infoStep__input}
        name="firstName"
        label="First name"
        fullWidth
      />
      <TextInput
        className={styles.infoStep__input}
        name="lastName"
        label="Last name"
        fullWidth
      />
      <TextInput
        className={styles.infoStep__input}
        name="email"
        label="Email"
        helperText="Will be used as username"
        fullWidth
      />
      <div className={styles.button__wrapper}>
        <NextButton />
      </div>
    </div>
  );
};

export default InfoStep;
