import React from 'react';
import styles from './MediaModal.module.scss';
import { formatPhoneNumber, toTitleCase } from '../../utils';

const PrintTemplate = ( {
													templateRef,
													imageSrc,
													date,
													owner_eid,
													owner,
													recipient,
												} ) => {
	return (
		<div ref={templateRef}>
			<div>
				<p>
          <span className={styles.print_template__info_label}>
            Image Owner:{' '}
          </span>
					<span className={styles.print_template__info_value}>{toTitleCase(owner)}</span>
				</p>
				<p>
          <span className={styles.print_template__info_label}>
            Image Recipient:{' '}
          </span>
					<span className={styles.print_template__info_value}>{toTitleCase(recipient)}</span>
				</p>
				<p>
					<span className={styles.print_template__info_label}>Owner Id: </span>
					<span className={styles.print_template__info_value}>{formatPhoneNumber(owner_eid)}</span>
				</p>
				<p>
					<span className={styles.print_template__info_label}>Sended at: </span>
					<span className={styles.print_template__info_value}>{date}</span>
				</p>
			</div>
			<div className={styles.print_template__image}>
				<img src={imageSrc} alt='image to print' />
			</div>
		</div>
	);
};

export default PrintTemplate;
