import { useGraphql } from 'context';
import { useToggler } from 'hooks';
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { from } from 'rxjs';

const SurveyResultsContext = createContext({});

const SurveyResultsProvider = ({ children }) => {
  const { surveyData } = children.props;
  const [currentSurveyResponse, setCurrentSurveyResponse] = useState(
    surveyData.questions
  );
  const [currentSurveyResponseOwner, setCurrentSurveyResponseOwner] =
    useState();
  const [isSurveyAnonymous, setIsSurveyAnonymous] = useState();
  const [isSurveySummary, setIsSurveySummary] = useState(true);

  function showIndividualResponse(newResponseQuestions, owner) {
    setIsSurveySummary(false);
    setCurrentSurveyResponse(newResponseQuestions);
    setCurrentSurveyResponseOwner(owner);
  }

  function showSurveySummary() {
    setIsSurveySummary(true);
    setCurrentSurveyResponse(surveyData.questions);
    setCurrentSurveyResponseOwner(null);
  }

  useEffect(() => {
    setIsSurveyAnonymous(surveyData.anonymous);
  }, []);

  const contextValue = useMemo(
    () => ({
      isSurveyAnonymous,
      currentSurveyResponse,
      currentSurveyResponseOwner,
      showIndividualResponse,
      surveyData,
      isSurveySummary,
      showSurveySummary,
    }),
    [isSurveyAnonymous, currentSurveyResponse, surveyData, isSurveySummary]
  );

  return (
    <SurveyResultsContext.Provider value={contextValue}>
      {children}
    </SurveyResultsContext.Provider>
  );
};

/**
 * @typedef {Object} SurveyResultsContextType
 * @property {Object} SurveyResults
 * @property {Function} setSurveyResults
 * @property {Object} events
 * @property {Function} setEvents
 */

/**
 * @returns {SurveyResultsContextType}
 */
const useSurveyResultsContext = () => {
  const context = useContext(SurveyResultsContext);

  return context;
};

export { useSurveyResultsContext, SurveyResultsProvider };
