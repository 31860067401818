import React from 'react';
import styles from './SupportPage.module.scss';
import ZohoForm from './zoho-form';
import UserGuide from './user-guide';

const SupportPage = () => {
  return (
    <div className={styles.page}>
      <ZohoForm />
      <UserGuide />
    </div>
  );
};

SupportPage.propTypes = {};

export default SupportPage;
