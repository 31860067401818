export const USER_CATEGORY = {
  FF: 'FnFData',
  INMATE: 'InmateData',
  DOC: 'DOCUserData',
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};

export const CHAT_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};

export const CHAT_SORTING_TYPES = {
  Name_ASC: 'Name A-Z',
  Name_DESC: 'Name Z-A',
  Date_ASC: 'Date Ascending',
  Date_DESC: 'Date Descending',
};
export const DASH_SORTING_TYPES = {
  Name_ASC: 'Name A-Z',
  Name_DESC: 'Name Z-A',
  Date_ASC: 'Date Ascending',
  Date_DESC: 'Date Descending',
  NUMBER_ASC: 'Date Ascending',
  NUMBER_DESC: 'Date Descending',
};

//TODO: Make sure to change Gangs to Gang in other locations
export const BLOCKING_REASONS = {
  DRUGS: 'Drugs',
  GANGS: 'Gang',
  THREATS: 'Threats',
  OTHER: 'Other',
};

export const UNBLOCKING_REASONS = {
  NO_VIOLATION: 'No Violation',
  OTHER: 'Other',
};

export const EMPTY_TYPES = {
  MEDIA: 'media',
  VIDEO_CHATS: 'videoChats',
  SEARCH: 'search',
  PHOTOS: 'photos',
  VIDEOS: 'videos',
  VOICE_NOTES: 'voiceNotes',
};

export const BLOCKED_ENTITY = {
  CHAT: 'CHANNEL',
  USER: 'USER',
};

export const MODERATION_TYPES = {
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  WARNING: 'WARNING',
};

export const OWNER_TYPE = {
  BOTH: 'BOTH',
};

export const BOOKMARK_TYPE = {
  CHAT: 'channel',
  MESSAGE: 'message',
  INMATE: 'inmate',
  FNF: 'fnf',
  SEARCH: 'search',
  PHOTO: 'photo',
  AUDIO: 'audio',
  VIDEO: 'video',
};

