import Form from 'shared/form';
import Button from 'shared/button';
import Modal from 'shared/modal';

import { MultilineInput } from 'shared/inputs';
import { ModalActions, ModalTitle } from 'shared/modal';

export default function BroadcastMsgModal( { open, onClose, handleSubmit, title } ) {

	const initialValues = {
		message: '',
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalTitle text={title} onClose={onClose} />
			<Form
				initialValues={initialValues}
				onSubmit={( form ) => {
					handleSubmit(form);
					onClose();
				}}
			>
				<MultilineInput
					name='message'
					placeholder='Type here'
					maxLength={500}
				/>
				<ModalActions>
					<Button type='submit' display='primary'>
						Send
					</Button>
				</ModalActions>
			</Form>
		</Modal>
	);
};