import { Typography } from '@mui/material';

import NavigationItem from 'shared/navigation-item';
import { Administrative } from 'assets/icons';

import { routes } from 'routing';

export default function() {
	return (
		<NavigationItem to={routes.settings}>
			<Administrative />
			<Typography variant='regularGrey3' component='span' ml={1}>
				Settings
			</Typography>
		</NavigationItem>
	);
}
