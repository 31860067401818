import { Box, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import KiwiCustomPie from 'shared/pie-chart/KiwiCustomPie';

import { If } from 'shared/utilities';

export default function KiwiPieChart(props) {
  const {
    chartData,
    legendData,
    chartDataKey,
    chartNameKey,
    innerText,
    innerValue = 0,
    colorPalette,
    enableTooltip,
    clickableInner = false,
    onClickInner,
  } = props;
  const { sxBoxChart, sxBoxTotal, sxTypographyTotal } =
    useKiwiPieChartStyles(clickableInner);
  return (
    <Box sx={sxBoxChart}>
      <Box sx={sxBoxTotal} onClick={onClickInner}>
        <Typography variant="h5" sx={sxTypographyTotal}>
          {innerText}
        </Typography>
        <Typography variant="h5" sx={sxTypographyTotal}>
          {innerValue}
        </Typography>
      </Box>
      <ResponsiveContainer debounce={300}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey={chartDataKey}
            nameKey={chartNameKey}
            cx="50%"
            cy="50%"
            outerRadius={'90%'}
            innerRadius={'55%'}
            paddingAngle={0}
            cornerRadius={0}
            stroke="none"
            fill="#8884d8"
          >
            {legendData.map((item, index) => (
              <Cell
                key={`${chartNameKey}-${index}`}
                fill={colorPalette[index]}
                shape={<KiwiCustomPie />}
              />
            ))}
          </Pie>
          <If condition={enableTooltip}>
            <Tooltip />
          </If>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}

function useKiwiPieChartStyles(clickableInner) {
  return {
    sxCard: {
      flex: 1,
      margin: '10px 15px 20px 24px',
    },
    sxBoxWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    sxBoxLegend: {
      width: '50%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sxBoxChart: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    sxBoxTotal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      paddingY: 2.5,
      paddingX: 3,
      transform: 'translate(-50%, -50%)',
      cursor: clickableInner && 'pointer',
      borderRadius: '50%',
      zIndex: clickableInner && 12,
      '&:hover': {
        boxShadow:
          ' 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      },
    },
    sxTypographyTotal: {
      textAlign: 'center',
      color: 'customPalette.grey2',
    },
  };
}
