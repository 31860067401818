import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useHover } from '../../hooks';
import { Slide } from '@material-ui/core';

export default function CollapsibleList( { component, list } ) {
	const [hoverRef, isHovered] = useHover();

	return (
		<ClickAwayListener onClickAway={() => {
		}}>
			<Box sx={{ position: 'relative', height: '100%' }} ref={hoverRef}>
				<Box
					variant='text'
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						'&:hover': {
							cursor: 'pointer',
							bgcolor: 'customPalette.grey5',
							borderRadius: 1,
						},
					}}
					my={'18px'}

				>
					{component}
					<IconButton disableRipple>
					</IconButton>
				</Box>
				<Slide direction='down' in={isHovered} timeout={75} mountOnEnter unmountOnExit>
					<Box>{isHovered ? list : null}</Box>
				</Slide>
			</Box>
		</ClickAwayListener>
	)
		;
}

const sxArrow = () => {
	return {
		fontSize: 16,
	};
};
