// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const aws_mobile = {
  aws_appsync_graphqlEndpoint:
    'https://xzyq7khn4jdeblkvyrgvtdzcou.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // 'aws_appsync_authenticationType': 'API_KEY',
  //'aws_appsync_apiKey': 'da2-ogkwzchi7fbc7bzjqwnerdifyi',
  aws_user_files_s3_bucket:
    'kiwichatappstack-kiwichatmsgimgsaa0ddb06-iyqycegv3bl0',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_o6h99Jjdt',
  aws_user_pools_web_client_id: '6aq9ql62noe40p3sqo92kistk8',
  aws_cognito_identity_pool_id:
    'us-east-1:d48865fa-be25-449f-9ad0-714fcf530c21',
  API: {
    endpoints: [
      {
        name: 'accMgmtApiGateway',
        endpoint: 'https://dev-acc-mgmt-api.kiwichat.com',
      },
      {
        name: 'documentApiGateway',
        endpoint: 'https://dev-documents.kiwichat.com',
      },
    ],
  },
  Storage: {
    userPhotos: {
      bucket: 'kiwichatappstack-kiwichatuserphoto2406ec8a-eid4zrl9vruk',
      region: 'us-east-1',
    },
    messageImgs: {
      bucket: 'kiwichatappstack-kiwichatmsgimgsaa0ddb06-13l9tjtqowsin',
      region: 'us-east-1',
    },
    grievanceAndForm: {
      bucket: 'kiwichatappstack-kiwichatgrievanceandformfilesed8-1rbguxcnyhskk',
      region: 'us-east-1',
    },
    bulletins: {
      bucket: 'kiwichatappstack-kiwichatbulletinfilesfea9a418-14jqkmr1ykvti',
      region: 'us-east-1',
    },
    videoApp: {
      bucket: 'kiwichatappstack-kiwichatvideorecordings69c634a1-1oybysefsvm11',
      region: 'us-east-1',
    },
    documents: {
      bucket: 'kiwichatappstack-kiwichatdocumentsde3420b0-1wa5gw3s94tnu',
      region: 'us-east-1',
    },
  },
  logToSentry: true,
  environment: 'dev', // dev | test | uat | production
  Sentry: {
    api_base_url: 'https://o1416691.ingest.sentry.io',
    project_id: '6760568',
    sentry_key: 'd9d3e94f72504f04a49f7ae9353c67c2',
  },
  AppInfo: {
    platform: 'WEB',
    platformVersion: '0.0.0',
    version: '0.0.0',
  },
  kiwi: {
    logLevel: 'DEBUG',
  },
  enviroment: {
    env: 'DEV',
  },
};

export default aws_mobile;
