import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import styles from './UploadFile.module.scss';
import Button from 'shared/button';

import { useStepperContext } from 'context';
import { useFormContext } from 'react-hook-form';
import RecipientsInput from 'modules/bulletins/create-bulletin/RecipientsInput';


const PrevButton = memo(() => {
  const { onPrevStep } = useStepperContext();

  return (
    <Button
      onClick={onPrevStep}
      display="secondary"
    >
      Back
    </Button>
  );
});

const NextButton = memo(() => {
  const { onNextStep } = useStepperContext();
  const { formState, watch } = useFormContext();
  const value = watch('recipients');

  return (
    <Button
      onClick={onNextStep}
      display="primary"
      disabled={
        !value.length > 0 || 
        formState.errors.recipients
      }
    >
      Next
    </Button>
  );
});

const FileRecipientsStep = () => {

  return (
    <div className={styles.formStep} >
      <div className={styles.uploadModalDivider__recipientStep}></div>
      <RecipientsInput name="recipients" />
      <Grid
        className={styles.actions}
        justifyContent="space-between"
        wrap="nowrap"
        columnSpacing="16px"
        container
      >
        <PrevButton />
        <NextButton />
      </Grid>
    </div>
  );
};

export default FileRecipientsStep;
