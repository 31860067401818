import React from 'react';
import styles from './FacilitiesInput.module.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FacilityCheckbox = ({
  label,
  name,
  value,
  facility,
  onCheck,
  disabled,
}) => {
  return (
    <FormControlLabel
      classes={{ root: styles.checkbox__label }}
      control={
        <Checkbox
          classes={{ root: styles.checkbox }}
          onChange={(evt) =>
            onCheck({ id: name, checked: evt.target.checked, facility })
          }
          checked={!!value}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export default FacilityCheckbox;
