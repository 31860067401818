import React from 'react';
import cs from 'classnames';
import styles from './AnswerType.module.scss';

import Stack from '@mui/material/Stack';

function RatingAnswerType({ percentOfTotal, numberOfResponses, displayAggregate }) {
  
  const hideAggregateData = {
    opacity: 0
  };

  return (
    <Stack spacing={1} className={cs(styles.answer__type_rating)}>
      <Stack className={styles.answer__type_rating__response_percent}>
        <span style={displayAggregate ? {} : hideAggregateData}>
          {`${percentOfTotal} %`}
        </span>
      </Stack>
      <Stack className={styles.answer__type_rating__responses_number}>
        <span style={displayAggregate ? {} : hideAggregateData}>
          {`${numberOfResponses} resp`}
        </span>
      </Stack>
    </Stack>
  );
}

export default RatingAnswerType;
