import SvgIcon from '@mui/material/SvgIcon';

const Report = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5.5H6C5.72386 5.5 5.5 5.72386 5.5 6V18C5.5 18.2761 5.72386 18.5 6 18.5H9.26962C9.41462 19.0254 9.61535 19.5276 9.86505 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H16C17.1046 4 18 4.89543 18 6V9.15003C17.5153 9.05165 17.0137 9 16.5 9V6C16.5 5.72386 16.2761 5.5 16 5.5ZM14.766 9.20144C15.0489 9.09409 15.25 8.82053 15.25 8.5C15.25 8.08579 14.9142 7.75 14.5 7.75H7.5C7.08579 7.75 6.75 8.08579 6.75 8.5C6.75 8.91421 7.08579 9.25 7.5 9.25H14.5C14.5323 9.25 14.564 9.24796 14.5952 9.24401C14.6519 9.22917 14.7089 9.21498 14.766 9.20144ZM11.6844 10.75H7.5C7.08579 10.75 6.75 11.0858 6.75 11.5C6.75 11.9142 7.08579 12.25 7.5 12.25H10.3196C10.7052 11.6903 11.1648 11.1856 11.6844 10.75ZM9.5202 13.75H7.5C7.08579 13.75 6.75 14.0858 6.75 14.5C6.75 14.9142 7.08579 15.25 7.5 15.25H9.1037C9.19096 14.7299 9.33174 14.2279 9.5202 13.75ZM19.5303 15.5304L16 19.0607L13.4697 16.5304L14.5303 15.4697L16 16.9394L18.4697 14.4697L19.5303 15.5304ZM20.5 16.5C20.5 18.7091 18.7091 20.5 16.5 20.5C14.2909 20.5 12.5 18.7091 12.5 16.5C12.5 14.2909 14.2909 12.5 16.5 12.5C18.7091 12.5 20.5 14.2909 20.5 16.5ZM22 16.5C22 19.5376 19.5376 22 16.5 22C13.4624 22 11 19.5376 11 16.5C11 13.4624 13.4624 11 16.5 11C19.5376 11 22 13.4624 22 16.5Z"
        fill="#686873"
      />
    </SvgIcon>
  );
};

export default Report;
