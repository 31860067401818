import { Box, Typography } from '@mui/material';

import Avatar from 'shared/avatar';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import AvatarSkeleton from 'shared/skeleton/AvatarSkeleton';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import ProfileInfoModal from './ProfileInfoModal';

import { useAvatarPhoto, useDisplayId } from 'hooks';
import { toTitleCase } from 'utils';

export default function ProfileAvatar() {
  const { userProfileInfo, loadingUserProfileInfo, userId } =
    useUserProfileContext();
  const { sxAvatar, sxTypography } = useProfileAvatarStyles();
  const avatarMediaLocation =
    userProfileInfo?.photo || userProfileInfo?.previousAllowedPhoto;

  const { thumbnailSrc: avatarSrc } = useAvatarPhoto(avatarMediaLocation);
  const isFnF = userId.includes('FNF^');
  const displayId = userProfileInfo?.eid || useDisplayId(userId);
  const nameAsTitle = toTitleCase(
    `${userProfileInfo?.firstName} ${userProfileInfo?.lastName}`
  );

  if (loadingUserProfileInfo) {
    return (
      <Box ml={2.2}>
        <AvatarSkeleton />
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box m={2.2}>
        <Avatar
          id={userProfileInfo?.id || ''}
          firstName={userProfileInfo?.firstName || ''}
          lastName={userProfileInfo?.lastName || ''}
          sxAvatar={sxAvatar}
          isBlocked={userProfileInfo?.isBlocked || false}
          src={avatarMediaLocation ? avatarSrc : ''}
          currentMediaLocation={avatarMediaLocation}
          enableModal={
            !!userProfileInfo?.photo ||
            !!userProfileInfo?.previousAllowedPhoto ||
            !!userProfileInfo?.avatarModerationStatus
          }
          isActive={userProfileInfo?.active}
        />
      </Box>
      <Box component="span">
        <Typography component="p" sx={sxTypography}>
          {nameAsTitle}
        </Typography>
        <Typography variant="boldGrey4" component="p">
          {isFnF
            ? formatPhoneNumber(userProfileInfo?.phone)
            : `ID: ${displayId}`}
        </Typography>
      </Box>

      <Box
        ml={1.5}
        sx={{ height: 58, display: 'flex', alignItems: 'flex-start' }}
      >
        <ProfileInfoModal />
      </Box>
    </Box>
  );
}

function useProfileAvatarStyles(isFnf) {
  return {
    sxAvatar: {
      '&.MuiAvatar-root': {
        width: '50px',
        height: '50px',
      },
    },
    sxTypography: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '32px',
    },
  };
}
