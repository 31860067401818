import Box from '@mui/material/Box';
import ChatIcon from '@mui/icons-material/Chat';
import styled from '@mui/material/styles/styled';

import query from 'query-string';

import SaveChatModal from 'shared/save-chat-modal/SaveChatModal';
import BlockChatModal from 'shared/block-chat-modal';
import TooltipElement from 'shared/tooltip-element';

import { generatePath, useHistory } from 'react-router';

import { If } from 'shared/utilities';
import { HooverBookmark, Lock } from 'assets/icons';
import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';
import { useChannelInfo } from 'hooks';
import { routes } from 'routing';

export default function ChatControls() {
	const history = useHistory();
	const { messageAndChannelSelected } = useAnalyticsModalContext();
	const { data: channelInfo, isLoading } = useChannelInfo(messageAndChannelSelected?.channelId);

	function onClickGoToChatButton( evt ) {
		evt.stopPropagation();
		history.push(
			`${generatePath(routes.chat, {
				userId: channelInfo?.ChannelData?.initiator,
				chatId: messageAndChannelSelected?.channelId,
			})}?${query.stringify({
				messageId: messageAndChannelSelected?.messageKey,
			})}`,
		);
	};

	return (
		<If condition={!isLoading && !!channelInfo}>
			<ControlsContainer>
				<GoToChatButton onClick={onClickGoToChatButton} />
				<BlockChatModal
					ElementModalControl={BlockModalButton}
					channelInfoData={channelInfo?.ChannelData}
				/>
				<SaveChatModal
					ElementModalControl={SaveModalButton}
					channelInfoData={channelInfo?.ChannelData}
				/>
			</ControlsContainer>
		</If>
	);
}

function GoToChatButton( { onClick } ) {
	return (
		<TooltipElement title='Go to Chat'>
			<ChatControlButton onClick={onClick}>
				<ChatIcon />
			</ChatControlButton>
		</TooltipElement>
	);
}

function BlockModalButton( { onClick } ) {
	return (
		<TooltipElement title='Block Chat'>
			<ChatControlButton onClick={onClick}>
				<Lock />
			</ChatControlButton>
		</TooltipElement>
	);
}

function SaveModalButton( { onClick } ) {
	return (
		<TooltipElement title='Save Chat'>
			<ChatControlButton onClick={onClick}>
				<HooverBookmark />
			</ChatControlButton>
		</TooltipElement>
	);
}

const ControlsContainer = styled(Box)(( { theme } ) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-end',
	borderRadius: theme.spacing(1),
}));

const ChatControlButton = styled(Box)(( { theme } ) => ({
	width: 30,
	height: 30,
	margin: theme.spacing(0.5),
	backgroundColor: theme.palette.customPalette.grey6,
	borderRadius: 4,
	border: '1px solid',
	borderColor: theme.palette.customPalette.grey4,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	fontSize: 12,
	fontWeight: 'bold',
	color: theme.palette.customPalette.grey3,
	'svg': {
		color: theme.palette.customPalette.grey3,
		width: 18,
		height: 18,
	},
}));
