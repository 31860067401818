import React, { useCallback, useState } from 'react';
import cs from 'classnames';
import styles from './FlagModal.module.scss';

import Grid from '@mui/material/Grid';
import Form from 'shared/form';
import Button from 'shared/button';
import Avatar from 'shared/avatar';
// import AltThumbnail from '../chat-details/print-messages/AltThumbnail';

import format from 'date-fns/format';

import * as yup from 'yup';
import { toast } from 'react-toastify';
import Modal, { ModalTitle } from 'shared/modal';
import { from } from 'rxjs';
import { MultilineInput, SelectInput } from 'shared/inputs';
import { useFormContext } from 'react-hook-form';
import { useGraphql } from 'context';
import { useChatContext } from '../context';
import { useAuthContext } from 'modules/auth/context';
import { DOCFlagMsgCmd } from 'kiwi-sdk';
import { useCallDuration, useSetFlagStatus } from '../hooks';

const FLAG_OPTIONS = {
  DRUGS: 'Drugs',
  GANGS: 'Gangs',
  THREATS: 'Threats',
  SUICIDE: 'Suicide',
  OTHER: 'Other',
};

const formInitialValues = {
  reason: '',
};

const validation = yup.object({
  reason: yup.string().required(),
});

const VideoAppFlagModal = (props) => {
  const { modal, toggleModal, channel, id, endTime, startTime } = props;
  const api = useGraphql();

  const { user } = useAuthContext();
  const { messages, setMessages } = useChatContext();

  const flagMessageCmd = (payload) => {
    return from(api.send(DOCFlagMsgCmd(payload)));
  };

  const handleSubmit = useCallback(
    (form) => {
      flagMessageCmd({
        channelId: channel,
        msgId: id,
        comment: 'No comment',
        reason: form.reason,
        by: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      }).subscribe({
        next: (res) => {
          if (res.status === 'ERROR') {
            toast.error('Failed to flag this message');
          } else {
            setMessages(useSetFlagStatus(id, messages, form, user));
            toast.success('The message has been successfully flagged');
            toggleModal();
          }
        },
        error: (err) => {
          console.error(err);
          toast.error(err);
          toggleModal();
        },
      });
    },
    [id, channel, user]
  );

  return (
    <Modal className={cs(styles.modal)} open={modal} onClose={toggleModal}>
      <ModalTitle
        className={styles.modal__title}
        text="Flag a videochat"
        onClose={toggleModal}
      />
      <VideoAppDetails startTime={startTime} endTime={endTime} {...props} />
      <VideoAppFlagForm handleSubmit={handleSubmit} toggleModal={toggleModal} />
    </Modal>
  );
};

export default VideoAppFlagModal;

const CancelButton = (props) => {
  return (
    <Button
      className={styles.form__cancelButton}
      onClick={props.onClose}
      display="secondary"
      disabled={props.loading}
    >
      Cancel
    </Button>
  );
};

const ConfirmButton = () => {
  const { formState } = useFormContext();
  return (
    <Button
      className={styles.form__submitButton}
      type="submit"
      display="primary"
      disabled={!formState?.dirtyFields?.reason}
    >
      Confirm
    </Button>
  );
};

function VideoAppFlagForm({ handleSubmit, toggleModal }) {
  return (
    <Form
      className={styles.form}
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
    >
      <SelectInput
        className={styles.form__input}
        name="reason"
        label="Moderation Flag"
        placeholder="Choose a moderation flag"
        options={FLAG_OPTIONS}
        validation={validation}
        variant="outlined"
        outlined
        required
      />
      <MultilineInput
        name="note"
        label="Internal notes"
        placeholder="Type here"
        helperText={'Optional'}
        maxLength={500}
      />

      <Grid
        classes={{ root: styles.form__actions }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <CancelButton onClose={toggleModal} />
        <ConfirmButton />
      </Grid>
    </Form>
  );
}

const ParticipantCard = ({ userId = '', userName = '', userPhone = '' }) => {
  const [userFirstName = '', userLastName = ''] = userName.split(' ');
  return (
    <Grid
      className={styles.participant__card}
      alignItems="center"
      wrap="nowrap"
      container
    >
      <Avatar
        className={styles.participant__card__avatar}
        firstName={userFirstName}
        lastName={userLastName}
        id={userId}
      />
      <Grid
        className={styles.participant__card__info}
        container
        direction="column"
        justifyContent="space-around"
        alignItems="flex-start"
      >
        <span className={styles.participant__card__info__label}>
          {userName}
        </span>
        <span className={styles.participant__card__info__value}>
          {userPhone}
        </span>
      </Grid>
    </Grid>
  );
};

const VideoAppDetails = (props) => {
  const {
    startTime,
    endTime,
    owner_info,
    recipient_info,
    ownerDisplayId,
    recipientDisplayId,
    ownersThumbnailsSrc,
  } = props;

  return (
    <Grid
      className={styles.modal__details}
      container
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item className={styles.modal__details__info} container xs={6}>
        <VideoAppThumbnail
          ownersThumbnailsSrc={ownersThumbnailsSrc}
          startTime={startTime}
          endTime={endTime}
          ownerDisplay={ownerDisplayId}
          owner_info={owner_info}
          recipient_info={recipient_info}
          recipientDisplayId={recipientDisplayId}
        />
      </Grid>
      <div className={styles.divider} />
      <Grid
        className={styles.modal__details__info}
        item
        container
        xs={5}
        pl={2}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <span>Participants</span>
        <ParticipantCard
          userName={owner_info}
          userPhone={ownerDisplayId}
          userId={ownerDisplayId}
        />
        <ParticipantCard
          userName={recipient_info}
          userPhone={recipientDisplayId}
          userId={recipientDisplayId}
        />

        <Grid container direction="column" pt={0.7}>
          <span className={styles.participant__card__info__label}>
            Videochat finished at
          </span>
          <span className={styles.participant__card__info__value}>
            {format(new Date(endTime), 'MMM d, y, h:mma')}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};

const VideoAppThumbnail = ({
  ownersThumbnailsSrc,
  startTime,
  endTime,
  owner_info,
  ownerDisplayId,
  recipient_info,
  recipientDisplayId,
}) => {
  const [callDuration] = useCallDuration(startTime, endTime);
  const [inmateThumbnail, fnfThumbnail] = ownersThumbnailsSrc;
  const [inmateFailLoad, setInmateFailLoad] = useState(false);
  const [fnfFailLoad, setFnfFailLoad] = useState(false);

  return (
    <div className={styles.thumbnail}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          {!inmateFailLoad ? (
            <img
              className={styles.thumbnail__image_left}
              src={inmateThumbnail}
              onError={() => setInmateFailLoad(true)}
            />
          ) : (
            <AltThumbnail
              personInfo={owner_info}
              personId={ownerDisplayId}
              borderRadius={'8px 0px 0px 8px'}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {!fnfFailLoad ? (
            <img
              className={styles.thumbnail__image_right}
              src={fnfThumbnail}
              onError={() => setFnfFailLoad(true)}
            />
          ) : (
            <AltThumbnail
              personInfo={recipient_info}
              personId={recipientDisplayId}
              borderRadius={'0px 8px 8px 0px'}
            />
          )}
        </Grid>
      </Grid>
      <span className={styles.thumbnail__duration}>{callDuration}</span>
      <span className={styles.thumbnail__type}>Videochat</span>
    </div>
  );
};

const AltThumbnail = ({ personInfo, personId, borderRadius }) => {
  const [firstName, lastName] = personInfo.split(' ');

  return (
    <Grid
      className={styles.thumbnail__altThumbnail}
      container
      direction="column"
      alignItems="center"
      sx={{ borderRadius: borderRadius }}
    >
      <Grid item>
        <Avatar
          className={styles.thumbnail__avatar}
          id={personId}
          firstName={firstName}
          lastName={lastName}
        />
      </Grid>
      <Grid item>
        <span className={styles.thumbnail__info}>{personInfo}</span>
      </Grid>
    </Grid>
  );
};
