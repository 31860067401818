import React from 'react';
import cs from 'classnames';
import styles from './Stepper.module.scss';

import Grid from '@mui/material/Grid';

import { useStepperContext, STEP_STATE } from 'context';

const CompletedIcon = (props) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.38119 0.273438H8.28196C8.0429 0.273438 7.81556 0.388281 7.67493 0.585156L3.99054 5.69453L2.32179 3.37891C2.18116 3.18438 1.95616 3.06719 1.71475 3.06719H0.615532C0.463188 3.06719 0.374125 3.24062 0.463188 3.36484L3.38351 7.41484C3.4525 7.51113 3.54344 7.58959 3.64881 7.64372C3.75417 7.69784 3.87092 7.72608 3.98937 7.72608C4.10782 7.72608 4.22457 7.69784 4.32994 7.64372C4.4353 7.58959 4.52624 7.51113 4.59523 7.41484L9.53119 0.571094C9.62259 0.446875 9.53353 0.273438 9.38119 0.273438Z"
      fill="#97979B"
    />
  </svg>
);

const Step = ({ label, status, index, isLast }) => (
  <Grid
    justifyContent= 'flex-start'
    alignItems="center"
    wrap="nowrap"
    container
    item
  >
    <div
      className={cs(
        styles.step__icon,
        status === STEP_STATE.ACTIVE && styles['step__icon--active'],
        status === STEP_STATE.COMPLETED && styles['step__icon--completed']
      )}
    >
      {status === STEP_STATE.COMPLETED ? (
        <CompletedIcon />
      ) : (
        <p className={styles.step__icon__number}>{index + 1}</p>
      )}
    </div>
    <p
      className={cs(
        styles.step__title,
        status === STEP_STATE.ACTIVE && styles['step__title--active']
      )}
    >
      {label}
    </p>
  </Grid>
);

export const Stepper = () => {
  const { steps, getStepState } = useStepperContext();

  return (
    <Grid justifyContent="space-between" wrap="nowrap" container>
      {steps.map(({ id, label }, index) => (
        <Step
          key={id}
          label={label}
          status={getStepState(id)}
          index={index}
          isLast={steps.length - 1 === index}
        />
      ))}
    </Grid>
  );
};

export const StepperContent = (props) => {
  const {
    currentStep: { component: Component },
  } = useStepperContext();

  return <Component {...props} />;
};

export default Stepper;
