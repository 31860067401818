import styles from './FilesList.module.scss';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { EmptyFile, Image, Play, Show } from 'assets/icons';
import cs from 'classnames';

const FILE_ICON = {
  Image: Image,
  Video: Play,
  Audio: Play,
  File: EmptyFile,
};

const FileContent = ({
  toggleViewsModal,
  toggleDeleteModal,
  hasPermission,
  fileType,
  fileLink,
}) => {
  const FileIcon = FILE_ICON[fileType];
  return (
    <div className={styles.file__content}>
      <div className={styles.file__header}>
        {hasPermission.seeDocumentViews && (
          <Tooltip title="See inmates who viewed this file" placement="top">
            <IconButton onClick={toggleViewsModal}>
              <Show />
            </IconButton>
          </Tooltip>
        )}

        {hasPermission.deleteDocument && (
          <Tooltip title="Delete File" placement="top">
            <IconButton onClick={toggleDeleteModal}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={styles.filePreview}>
        <a href={fileLink} target="_blank" rel="noopener noreferrer">
          <FileIcon
            className={cs(styles.file__icon, styles[`file__icon--${fileType}`])}
          />
        </a>
      </div>
    </div>
  );
};

export default FileContent;
