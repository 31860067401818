import { useEffect } from 'react';
import styles from './ContactSearch.module.scss';

import { Search } from 'assets/icons';
import { useForm } from 'react-hook-form';
import { useChatsTabContext } from '../user-details/chats-tab/ChatsTabContext';
import { Box } from '@mui/material';

const ContactSearch = () => {
	const { setSearchTerm } = useChatsTabContext();
	const { register, watch } = useForm();
	const username = watch('username');

	useEffect(() => {
		setSearchTerm(username);
	}, [username]);

	return (
		<Box sx={{ width: '100%' }}>
			<form className={styles.input__wrapper} onSubmit={( event ) => event.preventDefault()}>
				<Search classes={{ root: styles.input__icon }} />
				<input
					className={styles.input}
					placeholder='Search Chat Contacts'
					{...register('username')}
				/>
			</form>
		</Box>
	);
};
export default ContactSearch;
