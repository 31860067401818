import Dialog from '@mui/material/Dialog';

import { toast } from 'react-toastify';

import Form from 'shared/form';

import { useAvatarModalContext } from 'hooks';
import { useAuthContext } from 'modules/auth/context';

import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';

export function AvatarModalContainer(props) {
  const { children, open, toggleOpen, blockPhotoMutation } = props;
  const { id } = useAvatarModalContext();
  const { user } = useAuthContext();
  const { sxAvatarModalDialog } = useAvatarModalStyles();
  const FORM_INITIAL_VALUES = { fnfId: id, reason: '' };

  const handleBlock = (form) => {
    blockPhotoMutation.mutate(
      { ...form },
      {
        onSuccess: () => {
          toggleOpen(false);
          toast.success("The user's photo has been successfully blocked");
          queryClient.setQueryData(
            [QUERY_KEYS.USER_PROFILE_INFO, id],
            (oldData) => {
              return {
                ...oldData,
                photoModerationStatus: 'HAS_VIOLATION',
                photo: '',
                avatarModerationStatus: {
                  status: 'blocked',
                  begins: new Date().toISOString(),
                  ends: null,
                  reason: form.reason,
                  mediaLocation: '',
                  blockedByMetadata: `${user.id};${user.firstName};${user.lastName}`,
                },
              };
            }
          );
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={toggleOpen}
      maxWidth="800px"
      sx={sxAvatarModalDialog}
    >
      <Form initialValues={FORM_INITIAL_VALUES} onSubmit={handleBlock}>
        {children}
      </Form>
    </Dialog>
  );
}

function useAvatarModalStyles() {
  return {
    sxAvatarModalDialog: {
      '& .MuiDialog-paper': {
        borderRadius: '16px',
        overflow: 'hidden',
      },
    },
  };
}
