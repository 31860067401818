import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import { useAnalyticsModalContext } from 'shared/analytics-modal/hooks';

export default function ScrollableOptionsFilter() {
  const { modalConfig, listQueryPayload, setListQueryPayload } =
    useAnalyticsModalContext();
  const { initialOptionsData = [] } = modalConfig;
  const initialState =
    Object.entries(modalConfig.typesData).find(
      ([_, value]) => value === listQueryPayload?.filters[0]?.value
    )?.[0] || 'SENT';
  const [value, setValue] = useState(initialState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setListQueryPayload((payload) => {
      const mainFilterType = payload.filters[0]?.term;
      const newFilters = payload?.filters.slice();
      newFilters[0] = {
        term: mainFilterType,
        value: modalConfig.typesData[newValue],
      };
      const newPayload = {
        ...payload,
        filters: newFilters,
      };
      return newPayload;
    });
  };

  return (
    <TabsContainer className="mx-auto max-w-[365px]">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        indicatorColor="none"
        heigth={20}
      >
        {initialOptionsData.map(({ option, count }, index) => {
          return (
            <TypeTab
              key={option}
              label={`${option} (${count})`}
              index={index}
              value={option.toUpperCase()}
              {...getTabProps(index)}
            />
          );
        })}
      </Tabs>
    </TabsContainer>
  );
}

const getTabProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TypeTab = styled(Tab)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.customPalette.grey6,
  textTransform: 'capitalize',
  minHeight: 15,
  margin: theme.spacing(0, 0.25),
  fontSize: 12,
}));
