import React from 'react';
import styles from './Sections.module.scss';

import SectionItem from './SectionItem';

import bulletinImage_1 from './images/bulletin_1.png';

const Dash = () => (
  <div className={styles.section}>
    <h3 className={styles.section__title}>Dash</h3>
    <div className={styles.section__content}>
      <SectionItem title="Bulletins">
        <section>
          <h3>How do I send a bulletin?</h3>
          <ul>
            <li>
              On the Bulletins home page, you will see a &apos;Create New&apos;
              button. After clicking on this, it will send you through the
              process of creating the subject and text of the bulletin, choosing
              the recipients, and adding attachments.
            </li>
            <img src={bulletinImage_1} />
            <li>
              The sent bulletin can be viewed from the Bulletins home page.
            </li>
          </ul>
          <h3>Who can I send bulletins to?</h3>
          <ul>
            <li>
              Depending on your access rights and your role within the system,
              bulletins can be sent to the following:
            </li>
            <ul>
              <li>One facility</li>
              <li>Multiple facilities</li>
              <li>An entire agency</li>
              <li>Individual inmates (up to 10 at a time)</li>
            </ul>
          </ul>
          <h3>When will the recipients see the bulletin that I sent?</h3>
          <ul>
            <li>
              The bulletin will be delivered immediately to the Dash application
              on the kiosk and tablet. The inmate will receive a notification
              that they have a new bulletin available as soon as it is
              delivered.
            </li>
          </ul>
        </section>
      </SectionItem>
      <SectionItem title="Grievances">
        <section>
          <h3>How does Grievances work and what does the inmate see?</h3>
          <ul>
            <li>
              Grievances will be updated according to each agency&apos;s
              Grievance procedure and policy. The Grievance forms will be
              available via the Dash application on the tablet and kiosk.
            </li>
          </ul>
        </section>
      </SectionItem>
      <SectionItem title="Forms">
        <section>
          <h3>How do I create a new form and make it accessible to inmates?</h3>
          <ul>
            <li>
              On the Forms home page, there is a button to create a new form.
              Once created, this form will be accessible through the Dash
              application on the tablet to all inmates within a facility.
            </li>
          </ul>
        </section>
      </SectionItem>
      <SectionItem title="Documents">
        <section>
          <h3>What kind of documents can I upload?</h3>
          <ul>
            <li>
              The Documents section currently supports the following file types:
              <ul>
                <li>.jpg, .jpeg, .png, .pdf and .mp4</li>
              </ul>
            </li>
          </ul>
        </section>
      </SectionItem>
    </div>
  </div>
);

export default Dash;
