import React from 'react';
import styles from './StaffDetails.module.scss';

import Grid from '@mui/material/Grid';

const Section = ({ title, action = null, info }) => {
  return (
    <>
      <Grid
        classes={{ root: styles.section }}
        justifyContent="space-between"
        alignItems="center"
        container
      >
        <p className={styles.section__title}>{title}</p>
        {action}
      </Grid>
      <div className={styles.section__content}>
        {typeof info !== 'string' ? (
          info.map((item) => {
            return <span key={item.info}>{item.info}</span>;
          })
        ) : (
          <span>{info}</span>
        )}
      </div>
    </>
  );
};

export default Section;
