import React, { useEffect, useState } from 'react';
import styles from './CreateSurveyPage.module.scss';

import IconButton from '@mui/material/IconButton';
import Button from 'shared/button';
import Form from 'shared/form';
import QuestionsBody from './questions-body';
import QuestionsList from './questions-list';
import ExitModal from './ExitModal';

import BackIcon from '@mui/icons-material/ArrowBack';
import { Bookmark } from 'assets/icons';

import { useGraphql } from 'context';
import { useQuery, useToggler } from 'hooks';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';

import add from 'date-fns/add';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { SurveyQuestionType } from 'kiwi-sdk';

import { routes } from 'routing';
import { initQuestionValue } from './../constant';
import SendSurveyModal from './SendSurveyModal';
import { useSurveysContext } from '../context';
import { useSendSurvey } from '../utils/hooks';

const optionsValidation = {
  [SurveyQuestionType.TEXT]: yup.array(),
  [SurveyQuestionType.RADIO]: yup
    .array()
    .of(
      yup.object({
        value: yup.string().required().max(1000),
      })
    )
    .min(2)
    .max(10),
  [SurveyQuestionType.MULTIPLE_SELECT]: yup
    .array()
    .of(
      yup.object({
        value: yup.string().required().max(1000),
      })
    )
    .min(2)
    .max(10),
  [SurveyQuestionType.YES_NO]: yup
    .array()
    .of(
      yup.object({
        value: yup.string().required(),
      })
    )
    .min(2)
    .max(2),
};

const questionValidation = yup.object({
  question: yup.string().required().max(1000),
  type: yup.string().required(),
  options: yup.array().when('type', (type) => optionsValidation[type]),
});

const validationSchema = yup.object({
  anonymous: yup.bool(),
  name: yup.string().required(),
  questions: yup.array().of(questionValidation).min(1),
});

const CreateSurveyPage = () => {
  const history = useHistory();
  const api = useGraphql();
  const { setSurveys } = useSurveysContext();
  const { anonymous, name, firstQuestion } = useQuery();
  const [exit, toggleExit] = useToggler(false);
  const [showModal, toggleModal] = useToggler();
  const [surveyFormData, setSurveyFormData] = useState({});
  const sendSurvey = useSendSurvey();

  useEffect(() => {
    if (!name || !firstQuestion) {
      history.replace(routes.surveys);
    }
  }, []);

  const sendHandleSubmit = (data) => {
    setSurveyFormData(data);
    toggleModal();
  };
  const onHandleModalSubmit = (form) => {
    const { questions: formQuestions, name, anonymous } = surveyFormData;

    const getRecipients = (recipients) => {
      return recipients.map((recipient) => {
        const {
          id: facilityId,
          agencyId,
          agencyName,
          name: facilityName,
          inmateCount,
          huId,
          huName,
        } = recipient;

        return {
          agencyId,
          facilityId,
          agencyName,
          facilityName,
          inmateCount,
          huId,
          huName,
        };
      });
    };

    const getStrValue = (arr) => {
      return arr.map((val, index) => (arr[index] = val.value));
    };

    const getQuestionArr = (questionArr) => {
      return questionArr.map((question) => {
        return { ...question, options: getStrValue(question.options) };
      });
    };

    const questions = getQuestionArr(formQuestions);

    const recipients = getRecipients(form.recipients);

    const validUntil = add(new Date(), { days: form.days }).toISOString();

    sendSurvey.mutate(
      {
        name,
        questions,
        anonymous,
        recipients,
        validUntil,
      },
      {
        onSuccess: (response) => {
          toast.success('Survey was sent');
          toggleModal();
          setSurveys((surveys) => [response, ...surveys]);
          history.replace(routes.surveys);
        },
        onError: () => {
          toast.error('Something went wrong');
        },
      }
    );
  };

  const SendBtn = () => {
    const {
      formState: { isValid },
    } = useFormContext();

    return (
      <>
        <Button
          className={styles.header__sentBtn}
          display="primary"
          disabled={!isValid}
          type="submit"
        >
          Send survey
        </Button>
      </>
    );
  };

  return (
    <>
      <Form
        initialValues={{
          anonymous,
          name,
          questions: [initQuestionValue[firstQuestion]],
        }}
        validation={validationSchema}
        onSubmit={sendHandleSubmit}
      >
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.header__left}>
                <IconButton
                  classes={{ root: styles.header__backBtn }}
                  onClick={toggleExit}
                >
                  <BackIcon />
                </IconButton>
                <ExitModal open={exit} onClose={toggleExit} />
                <span>{name}</span>
              </div>
              <div className={styles.header__right}>
                <IconButton className={styles.header__iconBtn}>
                  <Bookmark />
                </IconButton>
                <SendBtn />
              </div>
            </div>
            <div className={styles.content__body}>
              <QuestionsBody />
            </div>
          </div>
          <div className={styles.sidebar}>
            <QuestionsList />
          </div>
        </div>
        <SendSurveyModal
          showModal={showModal}
          toggleModal={toggleModal}
          surveyData={surveyFormData}
          onHandleModalSubmit={onHandleModalSubmit}
        />
      </Form>
    </>
  );
};

export default CreateSurveyPage;
