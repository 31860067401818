import React from 'react';

const Check = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1176 5.44863C12.3128 5.64389 12.3128 5.96047 12.1176 6.15574L6.66639 11.6069L3.88187 8.8224C3.68661 8.62714 3.68661 8.31056 3.88187 8.1153L4.11758 7.87959C4.31284 7.68433 4.62942 7.68433 4.82468 7.8796L6.66639 9.7213L11.1748 5.21293C11.37 5.01767 11.6866 5.01767 11.8819 5.21293L12.1176 5.44863Z"
      fill="#101010"
    />
  </svg>
);

export default Check;
