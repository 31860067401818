import React from 'react';
import styles from './InmateAutocomplete.module.scss';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';

const Input = (props) => {
  return (
    <TextField
      {...props}
      placeholder="Search for inmate"
      variant="outlined"
      InputProps={{
        ...props.InputProps,
        classes: {
          notchedOutline: styles.autocomplete__input__outline,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Input;
