import React, { memo } from 'react';
import cs from 'classnames';
import styles from './Skeleton.module.scss';

import Grid from '@mui/material/Grid';

const Skeleton = ({ amount = 1 }) => {
  let i = 0;
  const arr = [];

  while (i < amount) {
    arr.push(i);
    i++;
  }

  return arr.map((_, index) => (
    <Grid
      key={index}
      classes={{ root: styles.skeleton }}
      alignItems="center"
      wrap="nowrap"
      container
    >
      <div className={styles.skeleton__circle} />
      <div className={styles.skeleton__lines}>
        <div className={styles.skeleton__lines__item} />
        <div
          className={cs(
            styles.skeleton__lines__item,
            styles['skeleton__lines__item--short']
          )}
        />
      </div>
    </Grid>
  ));
};

export default memo(Skeleton);
