import React from 'react';
import styles from '../SurveyResults.module.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Facilities } from 'modules/surveys/survey-list/Icons';
import RecipientsDisplay from 'shared/recipient-accordion';

const SentTo = ({ sent }) => {
  return (
    <div className={styles.info__item}>
      <span className={styles.info__item__label}>Sent:</span>
      <span className={styles.info__item__value}>{sent}</span>
    </div>
  );
};

const Completed = ({ completed }) => {
  return (
    <div className={styles.info__item}>
      <span className={styles.info__item__label}>Completed:</span>
      <span className={styles.info__item__value}>{completed}</span>
    </div>
  );
};

const CreatedDate = ({ format, Date, createdAt }) => {
  return (
    <div className={styles.info__item}>
      <span className={styles.info__item__label}>Created:</span>
      <span className={styles.info__item__value}>
        {format(new Date(createdAt), 'LLL d, y, h:mm a')}
      </span>
    </div>
  );
};

const SideBarInfo = ({
  sent,
  completed,
  format,
  Date,
  createdAt,
  recipients,
}) => {
  return (
    <>
      <div className={styles.info}>
        <SentTo sent={sent} />
        <Completed completed={completed} />
        <CreatedDate format={format} Date={Date} createdAt={createdAt} />
        <span className={styles.info__item__label}>
          {recipients.length > 1 ? 'Recipients:' : 'Recipient:'}
        </span>
      </div>
      <div>
        <RecipientsDisplay recipients={recipients} />
      </div>
    </>
  );
};
export default SideBarInfo;
