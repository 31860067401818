import { Box, Stack } from '@mui/material';
import LegendBall from 'shared/chart-legend/LegendBall';

export default function PieChartLegend(props) {
  const {
    legendData,
    nameKey,
    valueKey,
    colorPalette,
    stackProps,
    onClickItem,
    clickable = false,
  } = props;
  return (
    <Stack
      justifyContent="center"
      direction="column"
      spacing={2}
      width={'100%'}
      {...stackProps}
    >
      {legendData.map((item, index) => (
        <Box
          key={index}
          onClick={() => clickable && onClickItem(item[nameKey])}
          sx={{ cursor: clickable && 'pointer' }}
        >
          <LegendBall
            key={item[nameKey]}
            name={item[nameKey]}
            color={colorPalette[index]}
            value={item[valueKey]}
          />
        </Box>
      ))}
    </Stack>
  );
}
