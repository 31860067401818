import { useEffect, useState } from 'react';

const useMediaSrc = (getCmd) => ({ itemKey = '', parseScheme }) => {
  const [src, setSrc] = useState('');
  const parsedKey = parseScheme ? parseScheme(itemKey) : itemKey;

  useEffect(() => {
    const sub = getCmd(parsedKey).subscribe({
      next: (res) => {
        setSrc(res[0]);
      },
      error: (err) => {
        console.error(err);
      },
    });
    return () => sub.unsubscribe();
  }, [itemKey]);

  return [src];
};

export default useMediaSrc;