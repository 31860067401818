import { useGraphql } from 'context';
import { useMutation } from 'hooks';

export default function useFlagMutations( action, postMutation ) {
	const api = useGraphql();
	switch (action) {
		case ACTION_TYPES.FLAG_MESSAGE:
			const [loadingFlagMessage, loadingFlagMessageMutation] = useMutation({
				sdkMutationCmd: api.controller.doc.flagMessage,
				onSuccesText: 'The message has been successfully flagged',
				onErrorText: 'Failed to flag this message',
				onClose: postMutation,
			});
			return [loadingFlagMessage, loadingFlagMessageMutation];

		case ACTION_TYPES.UNFLAG_MESSAGE:
			const [loadingUnFlagMessage, loadingUnFlagMessageMutation] = useMutation({
				sdkMutationCmd: api.controller.doc.unflagMessage,
				onSuccesText: 'The message has been successfully unflagged ',
				onErrorText: 'The message has not been unflagged',
				onClose: postMutation,
			});
			return [loadingUnFlagMessage, loadingUnFlagMessageMutation];
	}
}

const ACTION_TYPES = {
	FLAG_MESSAGE: 'FLAG_MESSAGE',
	UNFLAG_MESSAGE: 'UNFLAG_MESSAGE',
};