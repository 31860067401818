import styled from '@mui/styles/styled';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { formatPhoneNumber, toTitleCase } from 'utils';

export default function InvitationItem( props ) {
	const { sender, recipient, status } = props;

	return (
		<InvitationItemContainer>
			<MemberInfoContainer sx={{ marginRight: 2 }}>
				<MemberValueTypography sx={{ textAlign: 'center' }}>
					{toTitleCase(getMemberDataToDisplay(sender))}
				</MemberValueTypography>
			</MemberInfoContainer>
			<StatusDividerContainer>
				<Divider>
					<StyledChip label={toTitleCase(getProperStatus(status))} />
				</Divider>
			</StatusDividerContainer>
			<MemberInfoContainer sx={{ marginLeft: 2 }}>
				<MemberValueTypography sx={{ textAlign: 'center' }} noWrap>{getMemberDataToDisplay(recipient)}</MemberValueTypography>
			</MemberInfoContainer>
		</InvitationItemContainer>
	);
}

const InvitationItemContainer = styled('div')(( { theme } ) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		border: `1px solid ${theme.palette.customPalette.grey5}`,
		borderRadius: 16,
		height: 80,
		cursor: 'auto',
		margin: '4px 8px',
	}))
;

const MemberInfoContainer = styled(Box)({
	display: 'flex',
	width: 170,
	flexDirection: 'column',
	padding: 8,
	marginX: 12,
	gap: 8,
});

const StatusDividerContainer = styled('div')({
	flex: 2,
	marginX: 8,
});

const MemberLabelTypography = styled(Typography)(( { theme } ) => ({
	fontWeight: 'bold',
	color: theme.palette.customPalette.grey3,
}));

const MemberValueTypography = styled(Typography)(( { theme } ) => ({
	color: theme.palette.customPalette.grey4,
}));

const StyledChip = styled(Chip)(( { theme } ) => ({
	backgroundColor: 'white',
	borderColor: theme.palette.customPalette.grey5,
	fontWeight: 'bold',
	color: theme.palette.customPalette.grey4,
}));

function getMemberDataToDisplay( member ) {
	const nameIsAvailable = member?.firstName && member?.lastName;
	if (nameIsAvailable) {
		return `${member?.firstName} ${member?.lastName}`;
	}
	if (!nameIsAvailable && member?.email !== 'NA') {
		return member?.email;
	}
	if (!nameIsAvailable && member?.phone) {
		return formatPhoneNumber(member?.phone);
	}
	return 'Data not available';
}

function getProperStatus( option ) {
	const status = option.toLowerCase();
	if (status === 'total') return 'sent';
	if (status === 'denied') return 'rejected';
	return status;
}
