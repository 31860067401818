import { useMutation } from '@tanstack/react-query';
import { useGraphql } from '../context';
import { BlockChannelCmd } from 'kiwi-sdk';

export default function useBlockChat() {
	const api = useGraphql();

	return useMutation({
		mutationFn: ( payload ) => api.send(BlockChannelCmd(payload)),
	});
}
