import React from 'react';
import styles from './QuestionsBody.module.scss';

import IconButton from '@mui/material/IconButton';
import { SelectInput } from 'shared/inputs';
import Button from 'shared/button';
import AddQuestion from '../AddQuestion';
import DeleteWrapper from '../DeleteWrapper';
import { TextInput } from './Inputs';
import QuestionType from './QuestionType';

import { Plus, Trash } from 'assets/icons';

import { useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';

import { SurveyQuestionType } from 'kiwi-sdk';
import { QUESTION_OPTIONS } from '../../constant';

const Question = ({ type, index }) => {
  return (
    <div className={styles.question}>
      <div className={styles.question__header}>
        <div className={styles.question__headerLeft}>
          <div className={styles.question__number}>{index + 1}</div>
          <SelectInput
            className={styles.question__select}
            name={`questions.${index}.type`}
            renderValue={(value) => QUESTION_OPTIONS[value]}
            options={SurveyQuestionType}
            variant="outlined"
            outlined
          />
        </div>
        <DeleteWrapper
          index={index}
          Action={
            <IconButton className={styles.question__removeBtn}>
              <Trash />
            </IconButton>
          }
        />
      </div>
      <TextInput
        className={styles.question__name}
        name={`questions.${index}.question`}
        placeholder="Enter your question here"
      />
      <QuestionType type={type} baseName={`questions.${index}`} index={index} />
    </div>
  );
};

const QuestionsBody = () => {
  const [modal, toggleModal] = useToggler();
  const { watch } = useFormContext();

  const questions = watch('questions');

  return (
    <div className={styles.questions}>
      {questions.map((item, i) => (
        <Question key={i} index={i} {...item} />
      ))}
      <Button
        className={styles.questions__add}
        startIcon={<Plus />}
        onClick={toggleModal}
      >
        New question
      </Button>
      <AddQuestion open={modal} onClose={toggleModal} />
    </div>
  );
};

export default QuestionsBody;
