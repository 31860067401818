import React from 'react';

import Modal, {
  ModalActions,
  ModalDescription,
  ModalTitle,
} from 'shared/modal';
import Button from 'shared/button';

import { useHistory } from 'react-router';

import { routes } from 'routing';

const ExitModal = ({ open, onClose }) => {
  const history = useHistory();

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle text="Exit Survey Creation" />
      <ModalDescription text="If you exit before saving the survey, all progress will be lost. Are you sure you want to exit?" />
      <ModalActions>
        <Button display="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          display="primary"
          onClick={() => history.replace(routes.surveys)}
        >
          Yes
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ExitModal;
