import { Typography, Grid, Box } from '@mui/material';

import Avatar from 'shared/avatar';
import HighlightLink from 'shared/text-highlighter/HighlightLink';

import { toTitleCase } from 'utils';
import { generatePath, useHistory } from 'react-router';

import { routes } from 'routing';
import HighlightText from 'shared/text-highlighter';

export default function MemberInfo( props ) {
	const { label, memberInfo, memberId, memberEid, searchTerm, isMessageBlocked, reverse = false } = props;
	const history = useHistory();

	function onClickLinkHandler( evt ) {
		evt.stopPropagation();
		history.push(
			`${generatePath(routes.user, {
				userId: memberId,
			})}`,
		);
	}

	return (
		<Grid
			gap={1}
			container
			flexDirection={reverse ? 'row-reverse' : 'row'}
			alignItems='center'
			sx={{ padding: '8px 16px' }}
		>
			<Avatar
				id={memberId}
				firstName={memberInfo.split(' ')[0]}
				lastName={memberInfo.split(' ')[1]}
				sxAvatar={{ width: '25px', height: '25px', fontSize: '10px' }} />
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<Box sx={{ display: 'flex' }}>
					{label && <Typography variant='regularGrey4' sx={{ color: isMessageBlocked && '#F9F9FB', fontSize: '12px' }} mr={1}>
						{label}
					</Typography>}
					<HighlightLink
						substring={searchTerm}
						string={toTitleCase(memberInfo)}
						sxHighlighter={HIGHLIGHTED_STYLE}
						onClick={onClickLinkHandler}
						sxString={{ fontWeight: 'regular', fontSize: 13, lineHeight: '12px', color: isMessageBlocked ? 'white' : '#101010', display: 'flex', alignItems: 'center' }}
					/>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row' }}>
					<HighlightText
						substring={searchTerm}
						string={memberEid}
						sxHighlighter={HIGHLIGHTED_STYLE}
						sxString={{ fontWeight: 'regular', fontSize: 13, lineHeight: '22px', color: isMessageBlocked ? 'white' : 'customPalette.grey3' }}
					/>
				</Box>
			</Box>
		</Grid>
	);
}

const HIGHLIGHTED_STYLE = {
	highlighted: {
		backgroundColor: '#F8A80C',
		color: '#101010',
	},
};
