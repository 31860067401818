import { Button, Box } from '@mui/material';

export default function ActionButton( { text = '', sx = {}, variant = 'dark', type, ...props } ) {
	const isDark = variant === 'dark';
	const { sxDefaultStyles } = useDefaultStyles(isDark);
	return (
		<Button
			variant={isDark ? 'contained' : 'outlined'}
			disableRipple={true}
			type={type}
			sx={{ ...sxDefaultStyles, ...sx }}
			{...props}
		>
			{text}
		</Button>
	)
		;
}

function useDefaultStyles( isDark ) {
	return {
		sxDefaultStyles: {
			color: isDark ? 'customPalette.white' : 'customPalette.grey1',
			bgcolor: isDark ? 'customPalette.grey1' : 'customPalette.white',
			textTransform: 'capitalize',
			padding: '12px 20px 12px 20px',
			minWidth: 100,
			height: 44,
			fontWeight: 500,
			fontSize: 16,
			lineHeight: '24px',
			borderRadius: '8px',
			border: '1px solid',
			borderColor: 'customPalette.grey1',
			'&:hover': { bgcolor: isDark ? 'customPalette.grey1' : 'customPalette.white' },
			'& .MuiButton-startIcon': {
				svg: {
					width: 24,
					height: 24,
				},
			},
			'& .MuiButton-endIcon': {
				svg: {
					width: 24,
					height: 24,
				},
			},
		},
	};
};