import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const AvatarSkeleton = ({ avatarSize = 56 }) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Skeleton variant="circular" width={avatarSize} height={avatarSize} />
      <Box ml={1}>
        <Skeleton variant="text" animation="wave" width={150} height={22} />
        <Skeleton variant="text" animation="wave" width={150} height={22} />
      </Box>
    </Grid>
  );
};

export default AvatarSkeleton;
