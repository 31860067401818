import React, { createContext, useContext, useEffect, useState } from 'react';

import { useToggle } from 'react-use';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';
import { FacilityHUQuery } from 'kiwi-sdk';

import queryKeys from 'utils/queryKeys';
import { queryClient } from 'utils/queryClient';
import { routes } from 'routing';

const AuthContext = createContext({});

const initAuthContext = () => {
  const [error, setError] = useState();
  const [userId, setUserId] = useState('');
  const [isAuth, toggleIsAuth] = useToggle(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userLoginCredentials, setUserLoginCredentials] = useState({});
  const [openBookmarksDrawer, toggleBookmarksDrawer] = useToggle(false);
  const [userPermissions, setUserPermissions] = useState(new Set());
  const [facilities, setFacilities] = useState([]);
  const [facilitiesHousingUnitInfo, setFacilitiesHousingUnitInfo] = useState(
    []
  );

  const api = useGraphql();
  const { search = '', pathname } = useLocation();
  const history = useHistory();
  const requestId = search.split('requestId=')[1];

  const isAuthPage =
    pathname === routes.login ||
    pathname === routes.passwordForgot ||
    pathname === routes.passwordReset;

  const initialLoadQuery = useQuery({
    queryKey: [queryKeys.USER_INITIAL_LOAD_DATA, userId],
    queryFn: () => api.controller.doc.initialLoad(userId),
    enabled: Boolean(userId),
  });

  const onLogin = useMutation({
    mutationFn: ({ form }) => {
      return api.auth.signIn({
        username: form?.email,
        password: form?.password,
      });
    },
    onSuccess: (res) => {
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        history.push(routes.passwordReset);
      } else {
        console.log('onSuccess res', res);
        setCognitoUser(res);
        !requestId && setUserId(res?.id);
        !requestId && toggleIsAuth();
      }
    },
    onError: (error) => {
      console.log('onError error', error);
      setError(error.message);
    },
  });

  const onGetCurrentUser = useMutation({
    mutationFn: () => api.auth.getCurrentUser(),
    onSuccess: (res) => {
      setCognitoUser(res);
      setUserId(res?.id);
      toggleIsAuth();
    },
    onError: () => {
      toast.error('Something went wrong');
      history.push(routes.login);
    },
  });

  const resetPasswordByRequestIdQuery = useQuery({
    queryFn: () => api.controller.doc.resetPassword(requestId),
    queryKey: [queryKeys.RESET_PASSWORD_BY_REQUEST_ID, requestId],
    enabled: Boolean(requestId),
    onSuccess: (res) => {
      onLogin.mutate({
        form: { email: res?.username, password: res?.tempPassword },
      });
    },
  });

  const onRequestPasswordReset = useMutation({
    mutationFn: ({ form }) => {
      return api.controller.doc.requestPasswordReset(form);
    },
  });

  const onSetNewPassword = useMutation({
    mutationFn: ({ form }) => {
      api.auth.completeNewPassword({
        user: cognitoUser,
        password: form.newPassword,
      });
    },
  });

  const onLogOut = useMutation({
    mutationFn: () => api.auth.signOut(),
    onSuccess: () => {
      toggleIsAuth();
      cleanUserInfo();
      queryClient.clear();
    },
    onError: (err) => {
      console.log('Error at Log Out', err?.message);
    },
  });

  function cleanUserInfo() {
    setUserId('');
    setCognitoUser(null);
    setUserPermissions(new Set());
    setFacilities([]);
    setFacilitiesHousingUnitInfo([]);
  }

  const getHousingUnitInfo = getHousingUnitInfoBuilder(
    setFacilitiesHousingUnitInfo,
    loadHousingUnits
  );

  function loadHousingUnits(payload) {
    return api.send(FacilityHUQuery(payload));
  }

  function getUserPermissions(userRoles, agencyRoles) {
    const userPermissions = userRoles.reduce((permissionAcum, userRole) => {
      const rolePermissions = agencyRoles.find(
        (agencyRole) => agencyRole.id == userRole.id
      ).permissions;
      return [...permissionAcum, ...rolePermissions];
    }, []);
    return new Set(userPermissions);
  }

  function loosePermissionCheck(requiredPermission) {
    const permissions = Array.from(userPermissions);
    return permissions.some((permission) =>
      permission.includes(requiredPermission)
    );
  }

  function removeLastNestedPermission(permission) {
    const lastNestedIndex = permission.lastIndexOf('#');
    return permission.slice(0, lastNestedIndex);
  }

  function strictPermissionCheck(requiredPermission) {
    if (userPermissions.has(requiredPermission)) {
      return true;
    }
    if (requiredPermission.includes('#')) {
      return strictPermissionCheck(
        removeLastNestedPermission(requiredPermission)
      );
    }
    return false;
  }

  useEffect(() => {
    if (initialLoadQuery?.data) {
      setFacilities(
        handleSetFacilities(
          initialLoadQuery?.data?.docUser,
          initialLoadQuery?.data?.agency.facilities,
          getHousingUnitInfo,
          loadHousingUnits
        )
      );
      setUserPermissions(
        getUserPermissions(
          initialLoadQuery?.data?.docUser?.info?.security?.role,
          initialLoadQuery?.data?.agency?.roles
        )
      );
    }
  }, [initialLoadQuery?.data]);

  useEffect(() => {
    !isAuthPage && onGetCurrentUser.mutate();
  }, []);

  console.log('facilitiesHousingUnitInfo', facilitiesHousingUnitInfo);
  console.log('initialLoadQuery?.data', initialLoadQuery?.data);

  return {
    isAuth,
    error,
    cognitoUser,
    user: initialLoadQuery?.data?.docUser?.info,
    userLoginCredentials,
    bookmarks: initialLoadQuery?.data?.docUser?.faves,
    agency: initialLoadQuery?.data?.agency?.info,
    resetPasswordByRequestIdQuery,
    facilities,
    userPermissions,
    facilitiesHousingUnitInfo,
    roles: initialLoadQuery?.data?.agency?.roles,
    onLogin,
    setUserLoginCredentials,
    onSetNewPassword,
    onGetCurrentUser,
    onLogOut,
    cleanUserInfo,
    onRequestPasswordReset,
    openBookmarksDrawer,
    toggleBookmarksDrawer,
    loosePermissionCheck,
    strictPermissionCheck,
  };
};

const AuthProvider = ({ children, ...props }) => {
  const initContextValue = initAuthContext(props);

  return (
    <AuthContext.Provider value={initContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  return context;
};

export { AuthProvider, useAuthContext };

function handleSetFacilities(
  user,
  facilities,
  setFacilitiesHousingUnitInfo,
  loadHousingUnits
) {
  const userAgency = user.info?.security?.agency;
  const userFacilities = user.info?.security?.facility;
  const getHousingUnitInfo = getHousingUnitInfoBuilder(
    setFacilitiesHousingUnitInfo,
    loadHousingUnits
  );
  if (userAgency) {
    getHousingUnitInfo(facilities);
    return facilities;
  }
  if (userFacilities)
    return filterViewableFacilities(
      facilities,
      userFacilities,
      getHousingUnitInfo
    );
}

function filterViewableFacilities(
  agencyFacilities,
  userViewableFacilities,
  getHousingUnitInfo
) {
  if (!userViewableFacilities) return null;
  const filteredArray = agencyFacilities.filter((agencyFacility) =>
    userViewableFacilities.find(
      (userFacility) => userFacility.id == agencyFacility.id
    )
  );
  getHousingUnitInfo(filteredArray);
  return filteredArray;
}

const getHousingUnitInfoBuilder =
  (setFacilitiesHousingUnitInfo, loadHousingUnits) => (userFacilities) => {
    const housingUnitArray = [];
    userFacilities.forEach((facility) => {
      loadHousingUnits({ id: facility.id }).subscribe((housingUnitList) => {
        housingUnitArray.push({
          housingUnits: housingUnitList.data.items,
          facilityId: facility.id,
          facilityName: facility.name,
        });
        if (housingUnitArray.length === userFacilities.length) {
          setFacilitiesHousingUnitInfo(housingUnitArray);
        }
      });
    });
  };
