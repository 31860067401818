import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';

import { QUERY_KEYS } from 'utils';

export default function usePhotoHistory(id) {
  const api = useGraphql();

  function getProfileHistory({ pageParam = null }) {
    return api.controller.fnf.profileHistory({
      id,
      limit: 24,
      nextPage: pageParam,
    });
  }

  const profileHistoryQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.PROFILE_HISTORY, id],
    queryFn: getProfileHistory,
    enabled: Boolean(id),
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  return profileHistoryQuery;
}

//TODO: extend this hook to inmate case
