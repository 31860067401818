import React from 'react';

import Grid from '@mui/material/Grid';
import { Switch, Route } from 'react-router';
import SettingsAutocomplete from '../settings-autocomplete';
import StaffDetails from '../staff-details';
import { AdminSettingsProvider } from '../context';

import routes from 'routing/routes';

const SettingsHeader = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <AdminSettingsProvider>
        <SettingsAutocomplete />
        <Switch>
          <Route path={routes.singleStaff} component={StaffDetails} />
        </Switch>
      </AdminSettingsProvider>
    </Grid>
  );
};

export default SettingsHeader;
