import React, { useState } from 'react';
import styles from './PermissionsSection.module.scss';

import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import CustomButton from 'shared/button';

import PermissionsTab from './PermissionTab';
import UsersTab from './UsersTab';
import CreateStaff from '../create-staff';

import { useToggler } from 'hooks';
import EditRole from '../roles-list/EditRole';
import { useAdminSettingsContext } from '../context';

const getTabProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabContent = ({ children, value, index, ...props }) => (
  <div
    className={styles.tabs__content}
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...props}
  >
    {value === index && children}
  </div>
);

const CreateAccountButton = ({ handleOnClick }) => {
  return (
    <CustomButton
      display={'primary'}
      startIcon={<AddIcon />}
      onClick={handleOnClick}
      style={{ fontSize: '14px' }}
    >
      Create Account
    </CustomButton>
  );
};

const EditRoleButton = ({ handleOnClick, selectedRoleId }) => {
  return (
    <CustomButton
      display={'secondary'}
      sx={{ width: '150px' }}
      onClick={handleOnClick}
      style={{ fontSize: '14px' }}
      disabled={selectedRoleId === 'ROLE^MASTER'}
    >
      Edit Role
    </CustomButton>
  );
};

const PermissionsSection = () => {
  const [tab, setTab] = useState(0);
  const [editRoleModal, toggleEditRoleModal] = useToggler();
  const [createAccountModal, toggleCreateAccountModal] = useToggler();
  const { hasPermission, roleSelected } = useAdminSettingsContext();

  return (
    <section>
      <Paper elevation={0} square sx={{ backgroundColor: 'transparent' }}>
        <Grid
          container
          sx={{ borderBottom: '2px solid #EBEBEF' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Tabs
              classes={{ root: styles.tabs, indicator: styles.tabs__indicator }}
              value={tab}
              onChange={(_, tab) => setTab(tab)}
              textColor="primary"
              indicatorColor={'secondary'}
            >
              <Tab
                classes={{
                  root: styles.tabs__item,
                  selected: styles['tabs__item--active'],
                }}
                label="Permissions"
                sx={{ textTransform: 'none' }}
                {...getTabProps(0)}
              />
              <Tab
                classes={{
                  root: styles.tabs__item,
                  selected: styles['tabs__item--active'],
                }}
                label="Users"
                sx={{ textTransform: 'none' }}
                {...getTabProps(1)}
              />
            </Tabs>
          </Grid>
          <Grid item sx={{ mb: '2px' }}>
            {hasPermission.editRole && (
              <EditRoleButton
                handleOnClick={toggleEditRoleModal}
                selectedRoleId={roleSelected?.id}
              />
            )}
            {hasPermission.editRole && (
              <EditRole open={editRoleModal} onClose={toggleEditRoleModal} />
            )}

            {hasPermission.createAccount && (
              <CreateAccountButton handleOnClick={toggleCreateAccountModal} />
            )}
            {hasPermission.createAccount && (
              <CreateStaff
                open={createAccountModal}
                onClose={toggleCreateAccountModal}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <TabContent value={tab} index={0}>
        <PermissionsTab />
      </TabContent>
      <TabContent value={tab} index={1}>
        <UsersTab />
      </TabContent>
    </section>
  );
};

export default PermissionsSection;
