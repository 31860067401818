import { useMemo } from 'react';
import { toast } from 'react-toastify';

import Modal, { ModalTitle } from 'shared/modal';
import BlockFormInputs from 'shared/media-modal/BlockFormInputs';
import useBlockMessage from 'hooks/useBlockMessage';

import { useAuthContext } from 'modules/auth/context';
import { queryClient } from 'utils/queryClient';
import { QUERY_KEYS } from 'utils';

export default function BlockMessageModal(props) {
  const { openModal, toggleOpenModal, data, message } = props;
  const { user } = useAuthContext();
  const blockMessageMutation = useBlockMessage();

  const [currentMessageInfo] = data.filter((msg) => msg.id === message);

  function handleBlockSubmit(form) {
    blockMessageMutation.mutate(
      {
        channelId: currentMessageInfo?.channel,
        msgId: currentMessageInfo?.id,
        comment: form.note || 'No comment',
        reason: form.reason || '',
        by: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      },
      {
        onSuccess: () => {
          toast.success('The message has been blocked successfully');
          queryClient.invalidateQueries([
            QUERY_KEYS.CHANNEL_MESSAGES,
            currentMessageInfo?.channel,
          ]);
          toggleOpenModal();
        },
        onError: () => {
          toast.error('Failed to block the message');
          toggleOpenModal();
        },
      }
    );
  }

  return (
    <Modal open={openModal}>
      <ModalTitle text="Block Message" onClose={toggleOpenModal} />
      <BlockFormInputs
        isBlocked={false}
        initialValues={{ reason: '' }}
        handleBlockSubmit={handleBlockSubmit}
        owner_type={'user'}
        onClose={toggleOpenModal}
        loading={blockMessageMutation.isLoading}
      />
    </Modal>
  );
}
