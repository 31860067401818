import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default function TooltipElement( props ) {
	const { children } = props;
	const classes = useStyles();
	return (
		<Tooltip classes={{ tooltip: classes.tooltip }} {...props}>
			{children}
		</Tooltip>
	);
}

const useStyles = makeStyles(() => ({
	tooltip: {
		backgroundColor: '#f2f4f4',
		color: '#101010',
		borderRadius: '8px',
		fontSize: '12px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '16px',
	},
}));

