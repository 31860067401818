import React from 'react';

const Minus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.79102 7.33594H13.2096C13.2923 7.33594 13.3337 7.38272 13.3337 7.47629V8.52892C13.3337 8.62249 13.2923 8.66927 13.2096 8.66927H2.79102C2.70834 8.66927 2.66699 8.62249 2.66699 8.52892V7.47629C2.66699 7.38272 2.70834 7.33594 2.79102 7.33594Z"
      fill="#686873"
    />
  </svg>
);

export default Minus;
