import { Typography, Box } from '@mui/material';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';

// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';
// import { useHighlightPart } from 'modules/users-chats/hooks';
// import { useChatContext } from 'modules/users-chats/context';

const AudioTranscription = ({ transcription }) => {
  // const { searchTerm } = useChatContext();
  // const matched = match(transcription, searchTerm, { insideWords: true });
  // const parts = parse(transcription, matched);
  // const highlightPart = useHighlightPart({ parts, searchTerm });

  return (
    <Box sx={sxBoxWrapper}>
      <Box>
        <MicNoneOutlinedIcon color='primary' />
      </Box>
      {/*{parts.map((part, index) => (*/}
      {/*  <span*/}
      {/*    key={index}*/}
      {/*    style={*/}
      {/*      part.highlight || highlightPart(part, index)*/}
      {/*        ? {*/}
      {/*          backgroundColor: '#f1d28d',*/}
      {/*          color: '#101010',*/}
      {/*        }*/}
      {/*        : undefined*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {part.text.trim()}*/}
      {/*  </span>))}*/}
      <span>
        {transcription}
      </span>
    </Box>
  );
};

export default AudioTranscription;

const sxBoxWrapper = () => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 1,
  };
};
