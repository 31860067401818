import { DialogActions } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import Button from 'shared/button';

export default function SaveElementModalActions( { loading = false } ) {
	const { formState } = useFormContext();
	return (
		<DialogActions>
			<Button
				display='primary'
				type='submit'
				disabled={loading || !formState.isValid}>
				Save
			</Button>
		</DialogActions>
	);
}
