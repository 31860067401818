import React from 'react';
import styles from './RoleInput.module.scss';

import SelectInput from './SelectInput';
import FoilsInput from './FoilsInput';

import { Grid, FormControlLabel, Checkbox } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { usePermissionFoils } from '../hooks';

import { ROLE_LEVEL } from '../constants';

const RolePermissionInput = ( { name, label, permissionTree } ) => {
	const { register, watch, setValue } = useFormContext();
	const { onChange } = register(name);

	const formStateObject = watch();

	const childrenPermission = permissionTree.children;

	const [permissionCheckedFoils] = usePermissionFoils(childrenPermission, name);

	const value = watch(name);
	const hasActiveDependency = formStateObject[`${name}#hasActiveDependency`];

	const hasCheckedFoils = checkedFoils(formStateObject, name);

	const isChecked = handleCheck(value, hasActiveDependency, hasCheckedFoils);

	const onChangeHandler = ( evt ) => {
		if (!hasActiveDependency) {
			setValue(
				name,
				evt.target.checked ? ROLE_LEVEL.FULL_ACCESS : ROLE_LEVEL.NONE,
			);
			childrenPermission.forEach(( child ) =>
				setValue(`${name}#${child.value}`, false),
			);
		}
	};

	return (
		<Grid classes={{ root: styles.wrapper }} direction='column' container>
			<Grid
				justifyContent='space-between'
				alignItems='center'
				xs={12}
				container
				item
			>
				<Grid xs={6} item>
					<FormControlLabel
						classes={{ root: styles.label }}
						control={
							<Checkbox
								classes={{ root: styles.checkbox }}
								onChange={onChangeHandler}
								checked={isChecked}
							/>
						}
						label={label.replace('Bulletin', 'Announcement')}
					/>
				</Grid>
				<Grid xs={6} item>
					<SelectInput
						name={name}
						value={value}
						onChange={onChange}
						rootPermissionIsChecked={isChecked}
						formStateObject={formStateObject}
					/>
				</Grid>
			</Grid>
			<Grid pl={'35px'}>
				{permissionCheckedFoils.map(( foil ) => {
					return (
						<FoilsInput
							key={foil.value}
							foil={foil}
							selectValue={value}
							rootPermission={name}
							onChange={onChange}
							rootPermissionIsChecked={isChecked}
							formStateObject={formStateObject}
						/>
					);
				})}
			</Grid>
		</Grid>
	);
};

export default RolePermissionInput;

const handleCheck = ( value, hasActiveDependency, hasCheckedFoils ) => {
	return hasActiveDependency || hasCheckedFoils || !!value;
};

const checkedFoils = ( permissions, currentPermission ) => {
	return Object.entries(permissions).some(( [permission, value] ) => {
		return permission.includes(`${currentPermission}#`) && value;
	});
};

// const unCheckDependencies = (permissions, currentPermission) => {
//   return Object.entries(permissions).some(([permission, value]) => {
//     return permission.includes(`${currentPermission}#`) && value;
//   });
// };
