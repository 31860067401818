import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

import ActionButton from 'modules/users-chats/user-details/action-buttons';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';

import { toast } from 'react-toastify';
import { Lock, OpenLock } from 'assets/icons';
import { useResetPassword } from 'modules/users-chats/hooks';
import { getUserType, QUERY_KEYS } from 'utils';
import { queryClient } from 'utils/queryClient';

export default function ProfileActions() {
	const { userId, userProfileInfo, toggleBlockUserModal, toggleUnBlockUserModal } = useUserProfileContext();
	const { sxBoxWrapper } = useProfileActionsStyles();
	const resetPassword = useResetPassword();
	const isInmate = getUserType(userId) === 'INMATE';

	function handleResetPassword() {
		return resetPassword.mutate(
			{ inmateId: userId },
			{
				onSuccess: () => {
					toast.success('Password reset successfully');
					queryClient.invalidateQueries({
						queryKey: [QUERY_KEYS.INMATE_PRINT_REQUESTS, userId,
						],
					});
					queryClient.invalidateQueries(( queryKey ) => {
						return queryKey.includes(`${QUERY_KEYS.FACILITIES_PRINT_REQUESTS}`);
					});
				},
				onError: () => {
					toast.error('Password reset failed');
				},
			},
		);
	}

	return (
		<Box sx={sxBoxWrapper}>
			{isInmate
				? <ActionButton
					label='Reset Password'
					color='customPalette.error'
					RenderIcon={resetPassword.isLoading ? ResetProgress : AddIcon}
					disabled={resetPassword.isLoading}
					action={handleResetPassword} />
				: null}
			<ActionButton
				label={userProfileInfo?.isBlocked ? 'Unblock' : 'Block'}
				color='customPalette.error'
				RenderIcon={userProfileInfo?.isBlocked ? OpenLock : Lock}
				action={userProfileInfo?.isBlocked ? toggleUnBlockUserModal : toggleBlockUserModal} />
		</Box>
	);
}

function ResetProgress() {
	return <CircularProgress size={16} sx={{ color: 'customPalette.error' }} />;
}

function useProfileActionsStyles() {
	return {
		sxBoxWrapper: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingX: 5,
			width: '100%',
		},
	};
}
