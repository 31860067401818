import { memo, useCallback, useEffect, useMemo, useState } from 'react';

export default function useImageValidator(src) {
  const [valid, setValid] = useState();
  const img = new Image();

    img.onload = () => setValid(true);
    img.onerror = () => setValid(false);
    img.src = src;

  return [valid];
}
