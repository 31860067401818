import React from 'react';
import cs from 'classnames';
import styles from './TypeResult.module.scss';

function HorizontalBarResult({ value }) {
  const valueInPercent = `${value}%`;
  return (
    <div className={cs(styles.input__type__horizontal)}>
      <p
        className={styles.input__type__horizontal__background}
        style={{ width: valueInPercent }}
      />
    </div>
  );
}

export default HorizontalBarResult;
