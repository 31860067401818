import React from 'react';
import styles from './MultilineInput.module.scss';

import { useFormContext } from 'react-hook-form';

const MultilineInput = ({ className, label, helperText, name, ...props }) => {
  const { register } = useFormContext();

  return (
    <div className={className}>
      {label && (
        <label className={styles.input__label}>
          {label}
          {helperText && (
            <span className={styles.input__helperText}>{helperText}</span>
          )}
        </label>
      )}
      <textarea className={styles.input} {...register(name)} {...props} />
    </div>
  );
};

export default MultilineInput;
