import { Box, Link, Typography } from '@mui/material';

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import { If } from 'shared/utilities';
import { toTitleCase } from 'utils';

export default function ChatInfo({
  shouldRender,
  mediaType,
  text,
  firstName,
  lastName,
}) {
  const { sxLink, sxImageMessage } = useChatInfoStyles();

  return (
    <Box sx={{ width: '100%' }}>
      <Link component="p" underline="none" sx={sxLink}>
        {toTitleCase(`${firstName} ${lastName}`)}
      </Link>
      <If condition={shouldRender}>
        <If condition={mediaType === 'text'}>
          <Typography variant="regularGrey4" component="p" noWrap>
            {text}
          </Typography>
        </If>
        <If condition={mediaType === 'image'}>
          <Typography variant="regularGrey3" sx={sxImageMessage}>
            <PhotoCameraIcon />
            Photo
          </Typography>
        </If>
        <If condition={mediaType === 'voice'}>
          <Typography variant="regularGrey3" sx={sxImageMessage}>
            <GraphicEqIcon />
            Voice
          </Typography>
        </If>
      </If>
    </Box>
  );
}

const useChatInfoStyles = () => ({
  sxLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '22px',
    color: 'customPalette.grey1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sxImageMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    svg: {
      width: 16,
      height: 16,
      marginRight: 0.5,
    },
  },
});
