import { useInfiniteQuery } from '@tanstack/react-query';
import { useGraphql } from 'context';
import { QUERY_KEYS } from 'utils';

export default function useSearchInmates( term ) {
	const api = useGraphql();

	function searchInmates( { pageParam = null } ) {
		return api.controller.search.inmates({
			term,
			limit: 40,
			nextPage: pageParam,
		});
	}

	const searchInmatesQuery = useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_INMATE, term, 'search'],
		queryFn: searchInmates,
		getNextPageParam: ( lastPage ) => lastPage?.nextPage,
	});

	return searchInmatesQuery;
}
