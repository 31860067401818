/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import styles from './SurveysList.module.scss';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { SurveyCheckbox } from './SurveyCheckbox';

import { Facilities } from './Icons';

import format from 'date-fns/format';
import { useToggler } from 'hooks';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from 'routing';
import { useDeleteSurvey } from 'modules/surveys/utils/hooks';
import { useAuthContext } from '../../auth/context';
import { toast } from 'react-toastify';
import { useSurveysContext } from '../context';

const SurveyItem = (props) => {
  const { name, completed, sent, id, createdAt } = props;
  const { setSurveys } = useSurveysContext();
  const { user } = useAuthContext();
  const history = useHistory();
  const button = useRef();
  const [menu, toggleMenu] = useToggler(false);
  const deleteSurveyMutation = useDeleteSurvey();

  function onDeleteSurvey(event) {
    event.stopPropagation();
    event.preventDefault();
    deleteSurveyMutation.mutate(
      {
        docUserInfo: user,
        ref: { id, notes: '', info: '' },
      },
      {
        onSuccess: () => {
          toast.success('The survey has been successfully removed');
          setSurveys((prev) => prev.filter((survey) => survey.id !== id));
          toggleMenu();
        },
        onError: () => {
          toast.error('Something went wrong');
          toggleMenu();
        },
      }
    );
  }

  return (
    <Grid
      classes={{ root: styles.survey }}
      container
      onClick={(e) => {
        e.preventDefault();
        history.push(
          `${generatePath(routes.surveyResults, {
            surveysId: id,
          })}`,
          { surveysData: props }
        );
      }}
    >
      <Grid
        classes={{ root: styles.survey__name }}
        xs={4}
        alignItems="center"
        wrap="nowrap"
        container
        item
      >
        <SurveyCheckbox name={name} />
        <p>{name}</p>
      </Grid>

      <Grid classes={{ root: styles.survey__recipients }} xs={3} item>
        <Facilities />
        <p>Facilities</p>
      </Grid>

      <Grid classes={{ root: styles.survey__statistic }} xs={2} item>
        <p>{completed}</p>
        <div />
        <p>{sent}</p>
      </Grid>

      <Grid classes={{ root: styles.survey__date }} xs={2} item>
        <p>{format(new Date(createdAt), 'MMM d, yy')}</p>
        <span
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            toggleMenu();
          }}
        >
          <IconButton
            classes={{ root: styles.survey__menuButton }}
            ref={button}
          >
            <MoreIcon />
          </IconButton>
        </span>
        <Menu
          classes={{
            paper: styles.survey__menu__wrapper,
            list: styles.survey__menu,
          }}
          anchorEl={button.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={menu}
          onClose={toggleMenu}
        >
          <MenuItem
            classes={{ root: styles.survey__menu__item }}
            onClick={onDeleteSurvey}
          >
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default SurveyItem;
