import React from 'react';
import styles from './MediaModal.module.scss';

import VideoPlayer from 'shared/media-players/video-player/VideoPlayer';
import ModalAudioPlayer from 'shared/media-players/modal-audio-player/ModalAudioPlayer';

const ModalContent = ( props ) => {
	const {
		src,
		isOpenModal,
		recipient_info,
		text,
		mediaType,
		owner_info,
		hasCaption = false,
	} = props;
	const mediaAttr = text || `${mediaType} from ${owner_info} to ${recipient_info}`;

	return (
		<>
			{mediaType === 'image' ? (
				<img src={src} title={mediaAttr} alt={mediaAttr} style={{ borderRadius: hasCaption ? '8px 8px 0 0' : '8px', backgroundColor: 'black' }} />
			) : mediaType === 'video' ? (
				<VideoPlayer
					src={src}
					text={text}
					mediaType={mediaType}
					owner_info={owner_info}
					recipient_info={recipient_info}
					hasCaption={hasCaption}
					{...props}
				/>
			) : (
				<>
					<div className={styles.content__wrapper__audioplayer}>
						<ModalAudioPlayer
							borderRadius={props.borderRadius}
							isMessageMine={true}
							text={text}
							isOpenModal={isOpenModal}
							mediaAttr={mediaAttr}
							src={src}
							{...props}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default ModalContent;
