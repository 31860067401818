import React from 'react';
import styles from './BulletinDetails.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { generatePath } from 'react-router';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import formatInmateName from 'utils/formatInmateName';
import { Facilities, HousingUnit } from '../bulletins-list/Icons';

export const InmatesRecipient = ({ recipients, routes, history }) => {

    function handleInmateLinkOnClick(e, id){ 
      e.stopPropagation;
      history.push(generatePath(routes.user, { userId: id }));
    }

    function getFormattedName(firstName, lastName, currentIndex, arrayLength){
      const formattedName = formatInmateName(firstName, lastName);
      if(arrayLength > 1 && currentIndex !== arrayLength - 1){
        return `${formattedName},`;
      }
      return formattedName;
    }
  
    const InmateLink = ({ firstName, lastName, id, currentIndex, arrayLength }) => {
      return (
        <span
          onClick={(e) => handleInmateLinkOnClick(e, id)}
          className={styles.body__inmate}
        >
           {getFormattedName(firstName, lastName, currentIndex, arrayLength)}
        </span>
      );
    };
  
    return (
      recipients.map((inmate , index, array ) => (
        <InmateLink 
          key={inmate.inmateId} 
          firstName={inmate.firstName} 
          lastName={inmate.lastName} 
          id={inmate.inmateId} 
          currentIndex={index}
          arrayLength={array.length}
          />
      ))
    );
};

// export const FacilitiesRecipient = ({ recipients }) => {

//   const SingleFacility = () => {
//     return (
//         <p className={styles.singleRecipient}>{recipients[0].facilityName}</p> 
//     );
//   };


//   const MultipleFacilities = () => {
//     return (
//       <Accordion elevation={0} className={styles.accordion} >
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//       >
//           <Facilities/>
//           <span>{`${recipients.length} Facilities`}</span>
//         </AccordionSummary>
//         <AccordionDetails >
//           {recipients.map((facility) => (
//             <li key={facility.facilityId}>{facility.facilityName}</li>
//           ))}
//         </AccordionDetails>

//       </Accordion>
//     );
//   };

//   return (      
//     <>
//       {recipients.length > 1 ? <MultipleFacilities /> : <SingleFacility />}
//     </>
//   );
// };


export const HousingUnitRecipients = ({ recipients }) => {
  const SingleHousingUnit = () => {
    const facilityName = recipients[0].facilityName;
    const huName = recipients[0].huName;

    return (
      <p className={styles.singleRecipient}>{`${facilityName}-${huName}`}</p> 
    );
  };

  const MultipleHosingUnits = () => {
    return (
      <Accordion elevation={0} className={styles.accordion} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
      >
          <HousingUnit/>
          <span>{`${recipients.length} Housing Units`}</span>
        </AccordionSummary>
        <AccordionDetails >
          {recipients.map((hu) => (
            <li key={hu.huId}>{`${hu.facilityName}-${hu.huName}`}</li>
          ))}
        </AccordionDetails>

      </Accordion>
    );
  };

  return (
    <>
    {recipients.length > 1 ? <MultipleHosingUnits /> : <SingleHousingUnit />}
    </>
  );

};