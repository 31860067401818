import React from 'react';
import cs from 'classnames';
import style from './PermissionsSection.module.scss';

import { Grid } from '@mui/material';

import { Check, Minus } from 'assets/icons';

const PermissionFoilsList = ({ foils }) => {
  return (
    <ul className={style.singles_permissions}>
      {foils.map((foil, index) => {
        return <SingleFoil key={index} foil={foil} />;
      })}
    </ul>
  );
};

export default PermissionFoilsList;

const SingleFoil = ({ foil }) => {
  const hasPermission = foil.isValid;
  const statusIcon = hasPermission ? <Check /> : <Minus />;
  const description = foil.description.replace('bulletins', 'announcements').replace('Bulletins', 'Announcements');

  return (
    <li className={style.singles_permissions__foil}>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >
        <span className={style.singles_permissions__foil__icon}>
          {statusIcon}
        </span>
        <span
          className={cs(
            style.singles_permissions__foil,
            !hasPermission && style['singles_permissions__foil--noValid'],
          )}
        >
          {description}
        </span>
      </Grid>
    </li>
  );
};
