import React from 'react';
import styles from './MediaModal.module.scss';

import format from 'date-fns/format';

const mediaTypesElements = {
  voice: 'Audio',
  image: 'Photo',
  video: 'Video',
  text: 'Text',
};
const MessageInfo = ({ owner_info, owner_id, date, type }) => {
  return (
    <div>
      <p
        className={styles.preview__header}
      >{`${mediaTypesElements[type]} info`}</p>
      <div className={styles.preview__item}>
        <p>Sender:</p>
        <p>{owner_info}</p>
      </div>
      <div className={styles.preview__item}>
        <p>User id:</p>
        <p>{owner_id}</p>
      </div>
      <div className={styles.preview__item}>
        <p>Time sent:</p>
        <p>
          {format(date, 'MMM d, y ')}
          <span>{format(date, 'h:mmaaa')}</span>
        </p>
      </div>
    </div>
  );
};

export default MessageInfo;
