import { Grid, Typography } from '@mui/material';
import format from 'date-fns/format';

export default function ChatDate( { inLiveChat, date } ) {
	const lastMessageDate = date ? format(new Date(date), 'MMM d, yyyy hh:mm a') : '';
	const dateVariant = inLiveChat ? 'regularGrey3' : 'regularBlack';

	return (
		<Grid container justifyContent='flex-end'>
			<Typography mt={0.25} mr={0.5} variant={dateVariant} component='p'>
				{lastMessageDate}
			</Typography>
		</Grid>
	);
}

