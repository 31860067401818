import { memo } from 'react';

import CardContentLayout from 'modules/chats-metrics/charts/chart-card/CardContentLayout';
import ChartCard from 'modules/chats-metrics/charts/chart-card/ChartCard';
import EmptyChart from 'modules/chats-metrics/charts/EmptyChart';
import ErrorBoundary from 'shared/error-boundary';
import KiwiPieChart from 'shared/pie-chart';
import Suspense from 'shared/suspense-list';

import useChatMetricsContext from 'modules/chats-metrics/hooks/useChatMetricsContext';
import useMessagesTypesAnalysis from 'modules/chats-metrics/hooks/useMessagesTypesAnalysis';

import { ChartLoader } from 'shared/loaders';
import { PieChartLegend } from 'shared/chart-legend';

import { useAuthContext } from 'modules/auth/context';

import { FilterTypes } from 'kiwi-sdk/dist/types/analytics/message';

import { MEDIA_TYPES_COLORS } from 'modules/chats-metrics/constants';

function CommunicationsMetrics() {
  const {
    dateRangeFilter,
    setSelectedOption,
    toggleFlaggedMessagesModal,
    setSelectedAnalyticModalConfig,
  } = useChatMetricsContext();
  const { agency, facilities } = useAuthContext();
  const { data = [], isLoading } = useMessagesTypesAnalysis(
    dateRangeFilter,
    agency,
    facilities
  );

  const total = data.reduce(
    (accumulator, currentObject) => accumulator + currentObject.count,
    0
  );
  const optionsFormatData = ({ mediaType, count }) => ({
    option: mediaType,
    count: count,
  });

  function selectMediaTypeFromLegend(mediaType) {
    setSelectedOption([
      {
        term: FilterTypes.MEDIA_TYPE,
        value: MEDIA_TYPES_ENUM[mediaType.toUpperCase()],
      },
    ]);
    setSelectedAnalyticModalConfig({
      modalName: 'Total Activity',
      showDateFilters: false,
      showChatPanel: true,
      listRenderItem: 'message',
      initialOptionsData: data.map(optionsFormatData),
      search: true,
      filters: false,
      typesData: MEDIA_TYPES_ENUM,
    });
    toggleFlaggedMessagesModal();
  }

  return (
    <ChartCard cardTitle="Total Activity">
      <ErrorBoundary level="section" textVariant="medium" imageSize="medium">
        <Suspense
          loading={isLoading}
          total={data.length}
          fallback={<ChartLoader />}
          noResultComponent={<EmptyChart />}
        >
          <CardContentLayout
            chartType="pie"
            LegendComponent={() => (
              <PieChartLegend
                legendData={data}
                nameKey="mediaType"
                valueKey="count"
                colorPalette={MEDIA_TYPES_COLORS}
                clickable={true}
                onClickItem={selectMediaTypeFromLegend}
              />
            )}
            ChartComponent={() => (
              <KiwiPieChart
                chartData={data}
                legendData={data}
                chartDataKey="count"
                chartNameKey="mediaType"
                innerText="Total"
                innerValue={total}
                colorPalette={MEDIA_TYPES_COLORS}
              />
            )}
          />
        </Suspense>
      </ErrorBoundary>
    </ChartCard>
  );
}

export default memo(CommunicationsMetrics);

const MEDIA_TYPES_ENUM = {
  TEXT: 1,
  IMAGE: 2,
  VOICE: 3,
  VIDEO: 4,
};
