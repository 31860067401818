import Avatar from 'shared/kiwi-components/avatar';

import { chatFormatDate, getUserType } from 'utils';
import { Case, Default, If, Switch } from 'shared/utilities';
import { cn } from 'utils/functions';
import { useMediaSrc, useWavesurfer } from 'hooks';
import { useGraphql } from 'context';
import { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import UserLink from 'shared/user-link';

export default function TextMessageItem(props) {
  const {
    text,
    date,
    isSelected,
    messageIsImage,
    messageIsVoice,
    ownerName,
    clientKey,
    ownerId,
    mediaLocation,
    owner_id,
  } = props;

  const formattedDate = chatFormatDate(date);

  return (
    <>
      <li
        key={clientKey}
        className={cn(
          'flex gap-x-4 p-4 m-1 hover:bg-gray-50 shadow-md  border-[1px] rounded-lg hover:shadow-md',
          {
            'bg-gray-50 shadow-md': isSelected,
          }
        )}
      >
        <Avatar
          className="h-6 w-6 flex-none rounded-full"
          owner={ownerName}
          variant={getUserType(ownerId).toLowerCase()}
        />
        <div className="flex-auto">
          <div className="flex items-baseline justify-between gap-x-4">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              <UserLink name={ownerName} id={owner_id} sxLink={Sxs.UserLink} />
            </p>
            <p className="flex-none text-xs text-gray-600">
              <time dateTime={clientKey}>{formattedDate}</time>
            </p>
          </div>
          <Switch>
            <Case condition={messageIsImage}>
              <ImageMessageContent mediaLocation={mediaLocation} />
            </Case>
            <Case condition={messageIsVoice}>
              <VoiceMessageContent mediaLocation={mediaLocation} text={text} />
            </Case>
            <Default>
              <TextMessageContent text={text} />
            </Default>
          </Switch>
        </div>
      </li>
    </>
  );
}

function TextMessageContent({ text }) {
  return (
    <p className="max-w-[280px] mt-1 line-clamp-2 text-sm leading-6 text-gray-600  text-ellipsis break-words rounded-bl-xl rounded-tr-xl rounded-br-xl max-h-[70px] overflow-hidden">
      {text}
    </p>
  );
}

function ImageMessageContent({ mediaLocation }) {
  const { getImages } = useGraphql();
  const [thumbnailSrc] = useMediaSrc(getImages)({
    itemKey: mediaLocation,
    parseScheme: (key) => key.replace('.jpeg', '_t.jpeg'),
  });
  return (
    <img
      src={thumbnailSrc}
      alt="image"
      className="w-20 h-20 rounded-xl mt-1 object-cover shadow-md"
    />
  );
}

function VoiceMessageContent({ mediaLocation, text }) {
  const playerRef = useRef(null);

  const { getImages } = useGraphql();
  const [mediaSrc] = useMediaSrc(getImages)({ itemKey: mediaLocation });
  const { handlePlayPause, formatTime, playing, duration, currentTime } =
    useWavesurfer(mediaSrc, playerRef);

  return (
    <div className="mt-1 line-clamp-2 text-sm leading-6 text-gray-600 whitespace-pre-wrap break-words flex flex-col rounded-bl-xl rounded-tr-xl rounded-br-xl ">
      <div className="w-full flex items-center justify-between">
        <IconButton onClick={handlePlayPause}>
          <If
            condition={playing}
            otherwise={<PlayCircleOutlinedIcon sx={Sxs.PlayPauseIcon} />}
          >
            <PauseCircleOutlineOutlinedIcon sx={Sxs.PlayPauseIcon} />
          </If>
        </IconButton>
        <div ref={playerRef} className="flex-1 mx-1" />
        <div className="mx-2 w-[40px] text-sm text-right">
          {currentTime ? formatTime(currentTime) : formatTime(duration)}
        </div>
      </div>
      <p className="mt-1 line-clamp-2 text-sm leading-6 text-gray-600 whitespace-pre-wrap break-words flex rounded-xl ">
        {text || 'No transcription available'}
      </p>
    </div>
  );
}

const Sxs = {
  PlayPauseIcon: {
    fontSize: '30px',
    color: 'customPalette.grey4',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  UserLink: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',
    color: 'customPalette.grey1',
  },
};
