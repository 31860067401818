import DialogActions from '@mui/material/DialogActions';

import AvatarModalTitle from './AvatarModalTitle';
import AvatarModalContent from './AvatarModalContent';
import AvatarModalInfo from './AvatarModalInfo';

import { If } from 'shared/utilities';
import { AvatarModalContainer } from 'shared/avatar/avatar-modal/AvatarModalContainer';

import { useAvatarModalContext, useBlockAvatarPhoto } from 'hooks';

export default function AvatarModal(props) {
  const { open, toggleModal, currentMediaLocation } = props;
  const {
    id,
    isCurrentProfilePhoto = false,
    currentModerationStatus,
  } = useAvatarModalContext();
  const blockAvatarPhoto = useBlockAvatarPhoto();
  const showPhotoInfo =
    !isCurrentProfilePhoto ||
    `${currentModerationStatus?.status}` === 'blocked';

  return (
    <AvatarModalContainer
      open={open}
      toggleOpen={toggleModal}
      id={id}
      blockPhotoMutation={blockAvatarPhoto}
    >
      <AvatarModalTitle text="Profile Images" onClose={toggleModal} />
      <AvatarModalContent
        toggleModal={toggleModal}
        blockAvatarPhoto={blockAvatarPhoto}
        currentMediaLocation={currentMediaLocation}
      />
      <DialogActions sx={{ padding: 3 }}>
        <If condition={showPhotoInfo}>
          <AvatarModalInfo />
        </If>
      </DialogActions>
    </AvatarModalContainer>
  );
}
