import React from 'react';
import styles from './Sections.module.scss';

import SectionItem from './SectionItem';

import createAccount from './images/createAccount.png';

const AdminSettings = () => (
  <div className={styles.section}>
    <h3 className={styles.section__title}>Admin Settings</h3>
    <div className={styles.section__content}>
      <SectionItem title="How do I set up an account for a new user?">
        <ul>
          <li>
            Under the Admin Settings home page, there is a button to create
            &apos;Create Account&apos;
          </li>
          <img src={createAccount} />
          <li>
            From here, you will need to enter the first name, last name, and
            email address associated with this user. A facility (or multiple
            facilities) and the role(s) must also be chosen in order to create
            the account.
          </li>
          <li>
            Once the user account has been created, they will receive an email
            to the associated email address. This email will provide them with a
            temporary password and direct them to the site, where they will
            create a permanent password and access the portal.
          </li>
        </ul>
      </SectionItem>
      <SectionItem title="Can I delete a user that is no longer with the department?">
        <ul>
          <li>
            The KiwiChat system does not allow you to delete any users from the
            system. Their history will be permanently stored so that their data
            is always accessible.
          </li>
        </ul>
      </SectionItem>
    </div>
  </div>
);

export default AdminSettings;
