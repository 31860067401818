import React, { useMemo } from 'react';
import styles from './EditStaffProfile.module.scss';

import MuiButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal, { ModalTitle } from 'shared/modal';
import Form from 'shared/form';
import CustomButton from 'shared/button';
import { TextInput } from 'shared/inputs';
import FormLoader from 'shared/form-loader';
import Checkbox from './Checkbox';

import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import { useToggler } from 'hooks';

const validation = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  deactivateProfile: yup.bool(),
});

const SubmitButton = ({ loading }) => {
  const { formState } = useFormContext();

  return (
    <CustomButton
      type="submit"
      disabled={!formState.isValid || loading}
      display="primary"
    >
      Save
    </CustomButton>
  );
};

const EditStaffProfile = ({ firstName, lastName, email }) => {
  const [showModal, toggleModal] = useToggler();
  // eslint-disable-next-line no-unused-vars
  const [loading, toggleLoading] = useToggler();

  const initialValues = useMemo(
    () => ({ firstName, lastName, email, deactivateProfile: false }),
    [firstName, lastName, email]
  );

  const handleSubmit = () => {
    console.error('Edit staff not implemented with api yet.');
    toggleModal(false);
  };

  return (
    <>
      <MuiButton classes={{ root: styles.button }} onClick={toggleModal}>
        Edit profile
      </MuiButton>

      <Modal className={styles.modal} open={showModal} onClose={toggleModal}>
        <ModalTitle className={styles.modal__title} text="Edit profile" />
        <p className={styles.modal__subtitle}>Personal info</p>
        <Form
          initialValues={initialValues}
          validation={validation}
          onSubmit={handleSubmit}
        >
          <TextInput
            className={styles.input}
            name="firstName"
            label="First name"
          />
          <TextInput
            className={styles.input}
            name="lastName"
            label="Last name"
          />
          <TextInput
            className={styles.input}
            name="email"
            label="Email"
            helperText="Will be used as username"
          />
          <Checkbox name="deactivateProfile" label="Deactivate profile" />

          <Grid
            direction="row-reverse"
            justifyContent="space-between"
            wrap="nowrap"
            container
          >
            <Grid justifyContent="flex-end" wrap="nowrap" container item>
              <CustomButton
                className={styles.cancelButton}
                onClick={toggleModal}
                display="secondary"
              >
                Cancel
              </CustomButton>
              <SubmitButton loading={loading} />
            </Grid>
            <FormLoader loading={loading} />
          </Grid>
        </Form>
      </Modal>
    </>
  );
};

export default EditStaffProfile;
