import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import TextMessageItem from 'shared/analytics-modal/items-list/message-item/text-message/TextMessageItem';
import ChannelItem from 'shared/analytics-modal/items-list/channel-item';
import InvitationItem from 'shared/analytics-modal/items-list/invitation-item';

import query from 'query-string';

import { Switch, Case, Default } from 'shared/utilities';
import { generatePath, useHistory } from 'react-router';
import { routes } from 'routing';

export default function TypeItem(props) {
  const {
    item,
    handleSetMessageAndChannelSelected,
    currentItemSelected,
    hasChatPanel,
  } = props;
  const history = useHistory();
  const isChannel = item?.__typename === 'ChannelData';
  const isInvitation = item?.__typename === 'Invite';

  const currenOnMessageItemHandler = hasChatPanel
    ? onHasChatPanelClickHandler
    : onNoChatPanelClickHandler;

  function onHasChatPanelClickHandler() {
    if (isChannel) {
      return handleSetMessageAndChannelSelected({
        messageKey: 0,
        channelId: item?.id,
      });
    }
    handleSetMessageAndChannelSelected({
      messageKey: item?.clientKey,
      channelId: item?.channel,
    });
  }

  function onNoChatPanelClickHandler(e) {
    e.stopPropagation();
    history.push(
      `${generatePath(routes.chat, {
        userId: item?.owner_id,
        chatId: item?.channel,
      })}?${query.stringify({
        messageId: item?.clientKey,
      })}`
    );
  }

  return (
    <MessageItemContainer
      onClick={!isInvitation ? currenOnMessageItemHandler : null}
    >
      <Switch>
        <Case
          condition={
            item?.mediaType === 'text' ||
            item?.mediaType === 'image' ||
            item?.mediaType === 'voice' ||
            item?.mediaType === 'video'
          }
        >
          <TextMessageItem
            text={item?.text}
            date={item?.clientKey}
            ownerName={item?.owner_info}
            ownerId={item?.owner_id}
            ownerDisplayId={item?.owner_eid}
            isSelected={currentItemSelected.messageKey === item?.clientKey}
            messageIsImage={item?.mediaType === 'image'}
            messageIsVoice={item?.mediaType === 'voice'}
            messageIsVideo={item?.mediaType === 'video'}
            mediaLocation={item?.mediaLocation}
            {...item}
          />
        </Case>
        <Case condition={isInvitation}>
          <InvitationItem
            createdAt={item?.createdAt}
            sender={item?.sender}
            recipient={item?.recipient}
            status={item?.status}
          />
        </Case>
        <Default>
          <ChannelItem
            id={item?.id}
            initiator={
              item?.members?.memberOne?.__typename === 'InmateData'
                ? item?.members?.memberOne?.id
                : item?.members?.memberTwo?.id
            }
            members={item?.members}
            blockedBy={item?.blockedBy}
            blockedUntil={item?.blockedUntil}
            channelBlockReason={item?.channelBlockReason}
            isChannelBlocked={item?.isChannelBlock}
            isSelected={currentItemSelected.channelId === item?.id}
          />
        </Default>
      </Switch>
    </MessageItemContainer>
  );
}

const MessageItemContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
}));
