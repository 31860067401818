import React from 'react';
import styles from './HooverIcons.module.scss';

import IconButton from '@mui/material/IconButton';
import AddBookmark from 'modules/bookmarks/bookmark-modal';

import { HooverBookmark } from 'assets/icons';
import { useIsItemSaved } from 'hooks';

import { BOOKMARK_TYPE } from 'modules/bookmarks/constants';

const AddBookmarkActionIcon = ( props ) => {
	const { id, channel, mediaType, setShowHoover, clientKey } = props;
	const messageBookmarkId = `${props.id};${props.channel}`;

	const isItemSaved = useIsItemSaved({
		id: messageBookmarkId,
		type: 'message',
	});

	return (
		<AddBookmark
			type={getBookmarkType(mediaType)}
			item={{
				id: id,
				chatId: channel,
				owner_info: props.owner_info,
				ownerId: props.owner_id,
				text: getBookmarkText(mediaType),
				channel: channel,
				clientKey: clientKey,
			}}
			isItemSaved={isItemSaved}
			setShowHoover={setShowHoover}
			Action={( props ) => (
				<IconButton sx={sxIconBtn} {...props}>
					<HooverBookmark
						className={isItemSaved ? styles.filledBookmark : undefined}
						sx={sxIcon}
					/>
				</IconButton>
			)}
		/>
	);
};

export default AddBookmarkActionIcon;

const getBookmarkType = ( mediaType ) => {
	return mediaType === 'image'
		? BOOKMARK_TYPE.PHOTO
		: mediaType === 'video'
			? BOOKMARK_TYPE.VIDEO
			: mediaType === 'audio'
				? BOOKMARK_TYPE.AUDIO
				: BOOKMARK_TYPE.MESSAGE;
};

const getBookmarkText = ( mediaType ) => {
	if (mediaType === 'image') {
		return 'Photo';
	}
	if (mediaType === 'video') {
		return 'Video';
	}
	if (mediaType === 'voice') {
		return 'Audio';
	}
	if (mediaType === 'text') {
		return 'Text Message';
	} else {
		return 'Video Call';
	}
};

const sxIconBtn = () => {
	return {
		marginRight: 1,
	};
};

const sxIcon = () => {
	return {
		height: 16,
		width: 16,
		color: 'customPalette.grey1',
	};
};
