import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import { useParams } from 'react-router';

const ChatsTabContext = createContext({});

const initChatsTabContext = () => {
  const { chatId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    userProfileChats: chats = [],
    setUserProfileChats,
    loadingUserProfileChats,
  } = useUserProfileContext();

  const filterChats = useCallback((term = '', contacts = []) => {
    return contacts.filter((item) => {
      const lowerCaseContactName = `${item?.firstName || ''} ${
        item?.lastName || ''
      }`.toLowerCase();
      const lowerCaseTerm = term?.toLowerCase() || '';
      return lowerCaseContactName.includes(lowerCaseTerm);
    });
  }, []);

  const filteredContacts = useMemo(() => {
    return filterChats(searchTerm, chats).sort((a, b) => {
      return b?.lastMsgClientKey - a?.lastMsgClientKey;
    });
  }, [chats, searchTerm]);

  const [chatSelected, setChatSelected] = useState('');
  const [showContactDetails, setShowContactDetails] = useState(true);

  const contactSelected = chats.find((chat) => chat.channelId === chatSelected);

  useEffect(() => {
    if (chats && !chatId) {
      setChatSelected(filteredContacts[0]?.channelId);
    }
    if (chatId) {
      setChatSelected(chatId);
    }
  }, [chats, filteredContacts]);

  return {
    chats,
    setSearchTerm,
    filteredContacts,
    chatSelected,
    setChatSelected,
    contactSelected,
    showContactDetails,
    setShowContactDetails,
    setUserProfileChats,
    loadingUserProfileChats,
  };
};

const ChatsTabProvider = ({ children, ...props }) => {
  const {
    chats,
    setSearchTerm,
    filteredContacts,
    chatSelected,
    setChatSelected,
    contactSelected,
    showContactDetails,
    setShowContactDetails,
    setUserProfileChats,
    loadingUserProfileChats,
  } = initChatsTabContext(props);

  const contextValue = useMemo(
    () => ({
      setSearchTerm,
      chats,
      filteredContacts,
      chatSelected,
      setChatSelected,
      contactSelected,
      showContactDetails,
      setShowContactDetails,
      setUserProfileChats,
      loadingUserProfileChats,
    }),
    [
      chats,
      setSearchTerm,
      filteredContacts,
      chatSelected,
      setChatSelected,
      contactSelected,
      showContactDetails,
      setShowContactDetails,
      setUserProfileChats,
      loadingUserProfileChats,
    ]
  );

  return (
    <ChatsTabContext.Provider value={contextValue}>
      {children}
    </ChatsTabContext.Provider>
  );
};
const useChatsTabContext = () => {
  const context = useContext(ChatsTabContext);

  return context;
};

export { ChatsTabProvider, useChatsTabContext };
