function pagesArrayGenerator( currentPage, lastPage ) {
	if (lastPage <= 5) return buildArray(2, lastPage - 1);

	if (currentPage < 5 && currentPage >= 0 && lastPage > 5) {
		return [...buildArray(2, 5), '...'];
	}
	if (currentPage === lastPage || lastPage - currentPage <= 4) {
		return ['...', ...buildArray(lastPage - 4, lastPage - 1)];
	}
	return ['...', ...buildArray(currentPage - 2, currentPage + 2), '...'];

};

export default pagesArrayGenerator;

function buildArray( initial, final ) {
	return Array.from({ length: final - initial + 1 }, ( _, index ) => initial + index);
}
