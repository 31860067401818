import * as yup from 'yup';
import { Dialog } from '@mui/material';
import Form from 'shared/form';

export default function SaveElementModalContainer(
	{
		openDialog, setOpenDialog, children, initialValues, handleSubmit, ...props
	},
) {
	const validation = yup.object({
		info: yup.string().trim().required(),
	});
	return (
		<Dialog
			open={openDialog} onClose={setOpenDialog} aria-describedby='save modal' sx={{
			'& .MuiDialog-paper': { borderRadius: '16px', width: 468 },
		}}>
			<Form initialValues={initialValues} validation={validation} onSubmit={handleSubmit}>
				{children}
			</Form>
		</Dialog>
	);
}
