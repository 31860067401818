import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@mui/material';

const PageContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperPage} sx={sxPaper}>
      {children}
    </Paper>
  );
};

export default PageContainer;

const useStyles = makeStyles((theme) => ({
  paperPage: {
    height: 'calc(100% - 75px)',
    overflow: 'hidden',
  },
}));

const sxPaper = () => {
  return { bgcolor: 'customPalette.white' };
};
