import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from './CustomTab.module.scss';

const CustomTabs = ( { value, onChange, tabsList } ) => {
	return (
		<Tabs
			classes={{ indicator: styles.tabs__indicator }}
			value={value}
			onChange={onChange}
		>
			{tabsList.map(( tab, index ) => {
				return (
					<Tab
						key={index}
						classes={{
							root: styles.tabs__item,
							selected: styles['tabs__item--active'],
						}}
						label={tab.label}
						{...getTabProps(index)}
					/>
				);
			})}
		</Tabs>
	);
};

export default CustomTabs;

const getTabProps = ( index ) => ({
	id: `simple-tab-${index}`,
	'aria-controls': `simple-tabpanel-${index}`,
});
