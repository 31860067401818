import React from 'react';
import styles from './CreateSurvey.module.scss';

import MuiButton from '@mui/material/Button';
import Modal, { ModalTitle } from 'shared/modal';
import { SelectInput, SwitchInput } from 'shared/inputs';
import Form from 'shared/form';
import Button from 'shared/button';

import { Plus } from 'assets/icons';

import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useToggler } from 'hooks';
import { generateSearchParams } from 'utils/path';

import { SurveyQuestionType } from 'kiwi-sdk';
import { routes } from 'routing';
import { QUESTION_OPTIONS } from './../constant';

const NAME_LENGTH = 75;

const initialValues = {
  anonymous: false,
  name: '',
  firstQuestion: SurveyQuestionType.RADIO,
};

const validationSchema = yup.object({
  anonymous: yup.boolean(),
  name: yup.string().max(NAME_LENGTH).required(),
  firstQuestion: yup.string(),
});

const TextInput = ({ name, placeholder, label }) => {
  const { register, watch } = useFormContext();
  const value = watch(name);
  const { onChange, ...inputProps } = register(name);

  const handleChange = (event) => {
    if (event.target.value.length <= NAME_LENGTH) {
      onChange(event);
    }
  };

  return (
    <div className={styles.textInput}>
      <label>
        {label}
        <span>*</span>
      </label>
      <input
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        {...inputProps}
      />
    </div>
  );
};

const SubmitButton = ({ onSubmit }) => {
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = useFormContext();

  return (
    <Button
      display="primary"
      type="submit"
      disabled={!isDirty || !isValid}
      onClick={handleSubmit(onSubmit)}
    >
      Create
    </Button>
  );
};

const CreateSurvey = () => {
  const history = useHistory();
  const [modal, toggleModal] = useToggler();

  const handleSubmit = (data) => {
    history.push({
      pathname: routes.surveysCreate,
      search: generateSearchParams(data),
    });
  };

  return (
    <>
      <MuiButton
        classes={{ root: styles.button }}
        startIcon={<Plus />}
        display="secondary"
        onClick={toggleModal}
      >
        New Survey
      </MuiButton>
      <Modal className={styles.modal} open={modal} onClose={toggleModal}>
        <ModalTitle text="New Survey" onClose={toggleModal} />
        <Form
          className={styles.form}
          initialValues={initialValues}
          validation={validationSchema}
        >
          <SwitchInput
            name="anonymous"
            label="Anonymous survey"
            color="primary"
          />
          <TextInput name="name" label="Survey" placeholder="Survey name" />
          <SelectInput
            name="firstQuestion"
            label="First question type"
            options={SurveyQuestionType}
            variant="outlined"
            renderValue={(value) => QUESTION_OPTIONS[value]}
            fullWidth
            outlined
          />
          <div className={styles.form__control}>
            <SubmitButton onSubmit={handleSubmit} />
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSurvey;
