import { useMutation } from '@tanstack/react-query';
import { useGraphql } from 'context';

export default function useMarkAsPrinted() {
	const api = useGraphql();

	function markAsPrintedMutation( payload ) {
		return api.controller.doc.printRequestsAction(payload);
	}

	return useMutation({ mutationFn: markAsPrintedMutation });

}
