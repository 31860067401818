import Typography from '@mui/material/Typography';
import NavigationItem from 'shared/navigation-item';
import { Report } from 'assets/icons';

export default function ReportsComponent() {
  return (
    <NavigationItem>
      <Report />
      <Typography variant='regularGrey3' component='span' ml={1}>
        Reports
      </Typography>
    </NavigationItem>
  );
}