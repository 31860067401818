import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Inmates = (props) => (
  <SvgIcon width={40} height={41} viewBox="0 0 40 41" fill="none" {...props}>
    <path
      d="M28.3327 35.5V32.1667C28.3327 30.3986 27.6303 28.7029 26.3801 27.4526C25.1298 26.2024 23.4341 25.5 21.666 25.5H8.33268C6.56457 25.5 4.86888 26.2024 3.61864 27.4526C2.36839 28.7029 1.66602 30.3986 1.66602 32.1667V35.5"
      stroke="#59585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.0007 18.8333C18.6826 18.8333 21.6673 15.8486 21.6673 12.1667C21.6673 8.48477 18.6826 5.5 15.0007 5.5C11.3188 5.5 8.33398 8.48477 8.33398 12.1667C8.33398 15.8486 11.3188 18.8333 15.0007 18.8333Z"
      stroke="#59585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M38.334 35.5001V32.1668C38.3329 30.6897 37.8412 29.2548 36.9363 28.0873C36.0313 26.9199 34.7642 26.0861 33.334 25.7168"
      stroke="#59585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M26.666 5.7168C28.1 6.08397 29.3711 6.91797 30.2787 8.08731C31.1864 9.25666 31.6791 10.6948 31.6791 12.1751C31.6791 13.6554 31.1864 15.0936 30.2787 16.2629C29.3711 17.4323 28.1 18.2663 26.666 18.6335"
      stroke="#59585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default Inmates;
