import React from 'react';

import { Skeleton } from 'shared/skeleton';

export default function AuthLoader() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm lg:max-w-[450px]">
        <Skeleton amount={2} />
      </div>
    </div>
  );
}
