import InmatesTab from './InmatesTab';
import FnfsTab from './FnfsTab';
import DocStaffTab from './DocStaffTab';
import TextMessagesTab from './TextMessagesTab';
import VoiceMessagesTab from './VoiceMessagesTab';
import ImageMessagesTab from './ImageMessagesTab';
import VideoMessagesTab from './VideoMessagesTab';

export const PromptTabs = [
	InmatesTab,
	FnfsTab,
	TextMessagesTab,
	ImageMessagesTab,
	VoiceMessagesTab,
	// DocStaffTab,
	// VideoMessagesTab,
];

export const PromptTabLabels = [
	{ index: 0, label: 'Inmates' },
	{ index: 1, label: 'F&F' },
	{ index: 2, label: 'Messages' },
	{ index: 3, label: 'Photos' },
	{ index: 6, label: 'Voice Messages' },
	// { index: 2, label: 'DOC' },
	// { index: 5, label: 'Video Messages' },
];
