import React from 'react';

export default function Switch(props) {
  const { children, ...childProps } = props;
  let matchChild = null;
  let defaultCase = null;

  React.Children.forEach(children, (child) => {
    if (!matchChild && child.type === Switch.Case) {
      const { condition } = child.props;
      const conditionResult = Boolean(condition);

      if (conditionResult) {
        matchChild = child;
      }
    } else if (!defaultCase && child.type === Switch.Default) {
      defaultCase = child;
    }
  });

  if (matchChild) {
    return React.cloneElement(matchChild, childProps);
  }
  if (defaultCase) {
    return React.cloneElement(defaultCase, childProps);
  }
  return null;
}

Switch.Case = function ({ children, ...props }) {
  return React.cloneElement(children, props);
};

Switch.Default = function ({ children, ...props }) {
  return React.cloneElement(children, props);
};
