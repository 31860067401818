import { Box } from '@mui/material';
import React, { useRef } from 'react';
import cs from 'classnames';

import styles from './MediaModal.module.scss';
import Grid from '@mui/material/Grid';

import IconButton from '@mui/material/IconButton';
import format from 'date-fns/format';
import Avatar from 'shared/avatar';

import PrintTemplate from './PrintTemplate';
import { useReactToPrint } from 'react-to-print';
import { Print } from 'assets/icons';
import { useIsItemSaved } from 'hooks';
import { BookmarkActionIcon } from 'modules/users-chats/chat-details/print-messages/hoover-icons';
import { formatPhoneNumber, toTitleCase } from 'utils';

const ModalHeader = ( { isVideoCall, ...props } ) => {
	const {

		ownerDisplayId = '',
		lastName,
		firstName,
		openUserPage,
		owner_info,
		createdAt,
		mediaType,
		recipient_info,
		recipientDisplayId,
		owner_id,
		owner_eid,
		src,
	} = props;

	const componentToPrintRef = useRef(null);

	const messageBookmarkId = `${props.id};${props.channel}`;

	const messageCreatedAt = createdAt
		? format(new Date(createdAt), 'MMM d, y, h:mma')
		: 'date not available';

	const isMessageSaved = useIsItemSaved({
		id: messageBookmarkId,
		type: 'message',
	});

	const handlePrint = useReactToPrint({
		content: () => componentToPrintRef.current,
	});

	return (
		<Grid
			classes={{
				root: styles.header,
			}}
			justifyContent='space-between'
			alignItems='center'
			wrap='nowrap'
			container
		>
			<div className={styles.header__info}>
				<Avatar
					className={styles.header__avatar}
					id={owner_id}
					lastName={lastName}
					firstName={firstName}
				/>
				<Box className={styles.header__author} ml={1}>
					<p onClick={openUserPage}>{toTitleCase(owner_info)}</p>
					<p>{formatPhoneNumber(owner_eid)}</p>
				</Box>
				<div className={styles.header__infoDivider} />
				<div className={styles.header__sentBy}>
					<p>Sent:</p>
					<p>{messageCreatedAt}</p>
				</div>
			</div>

			<div className={styles.header__controls}>
				<div className={styles.header__divider} />
				<BookmarkActionIcon
					className={cs(
						styles.header__action,
						isMessageSaved && styles['header__action__filled'],
					)}
					isItemSaved={isMessageSaved}
					mediaType={mediaType}
					{...props}
				/>
				{props.mediaType === 'image' && (
					<>
						<div className={styles.header__divider} />
						<IconButton className={styles.header__action} onClick={handlePrint}>
							<Print />
						</IconButton>
					</>
				)}
			</div>
			<div style={{ display: 'none' }}>
				<PrintTemplate
					templateRef={componentToPrintRef}
					imageSrc={src}
					date={messageCreatedAt}
					owner={owner_info}
					recipient={recipient_info}
					ownerId={owner_eid}
				/>
			</div>
		</Grid>
	);
};
export default ModalHeader;
