import React from 'react';
import cs from 'classnames';
import styles from './InmateAutocomplete.module.scss';

import Chip from '@mui/material/Chip';

import { Inmates, Close } from 'assets/icons';

const Result = ({ value = [], onRemove }) => {
  return (
    <div
      className={cs(styles.result, !value.length && styles['result--empty'])}
    >
      {value.length > 0 ? (
        value.map(({ firstName, lastName, ...inmate }, index) => (
          <Chip
            key={`${inmate.id}-${index}`}
            classes={{
              root: styles.result__item,
              label: styles.result__item__label,
              deleteIcon: styles.result__item__deleteIcon,
            }}
            label={`${firstName} ${lastName}`}
            onDelete={() => onRemove(inmate.id)}
            deleteIcon={<Close />}
          />
        ))
      ) : (
        <>
          <Inmates className={styles.result__icon} />
          <p className={styles.result__text}>No inmates selected yet</p>
          <p className={styles.result__subtext}>Up to 10 inmates</p>
        </>
      )}
    </div>
  );
};

export default Result;
