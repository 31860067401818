import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './AccountInfoTab.module.scss';

import Grid from '@mui/material/Grid';
import { Skeleton } from 'shared/skeleton';
import NoResult from 'shared/no-result';
import InfiniteScroller from 'shared/infinite-scroller';
import BlockedHistoryItem from './BlockedHistoryItem';

import { finalize, of, Subject, switchMap } from 'rxjs';
import { tap, pluck, catchError } from 'rxjs/operators';
import { FnFBlockHistoryQuery, InmateBlockHistoryQuery } from 'kiwi-sdk';
import { useParams } from 'react-router-dom';
import { useToggler } from 'hooks';
import { useGraphql } from 'context';
import { Box } from '@mui/material';
import { TableLoader } from 'shared/loaders';

const AccountInfoTab = () => {
  const { userId } = useParams();
  const id = decodeURIComponent(userId);
  const api = useGraphql();
  const [loadHistory$] = useState(new Subject());

  const nextPage = useRef(undefined);
  const list = useRef();
  const [loading, toggleLoading] = useToggler(true);
  const [blocksHistory, setBlocksHistory] = useState([]);

  function loadBlockedHistory(id) {
    const query =
      id.indexOf('FNF^') === 0
        ? FnFBlockHistoryQuery
        : id.indexOf('I^') === 0
          ? InmateBlockHistoryQuery
          : undefined;

    if (!query) {
      throw new Error(
        'Unknown user type. Checking by Id (has to start from either I^ or FNF^)'
      );
    }
    const observer = api
      .send(query({ id, limit: 30, nextPage: nextPage.current }))
      .pipe(
        catchError((err) => {
          console.error(err);
          return of([]);
        }),
        finalize(() => {
          toggleLoading(false); // Llamar a toggleLoading con valor false cuando se completa la operación
        })
      );
    nextPage.current = false;
    return observer;
  }

  useEffect(() => {
    const sub = loadHistory$
      .pipe(
        tap(() => {
          toggleLoading(true);
        }),
        switchMap(() => loadBlockedHistory(id)),
        tap((res) => {
          nextPage.current = res?.data?.nextPage;
        }),
        pluck('data', 'items')
      )
      .subscribe((history = []) => {
        toggleLoading(false);
        setBlocksHistory((state) => [...state, ...history]);
      });

    loadHistory$.next();

    return () => {
      sub.unsubscribe();
      toggleLoading(false);
    };
  }, [id, loadHistory$, toggleLoading]);

  const handleLoadNext = useCallback(() => {
    if (nextPage.current) {
      loadHistory$.next();
    }
  }, [loadHistory$]);

  console.log('rendering account info tab');
  console.log('blocksHistory is loading', loading);

  return (
    <Grid
      classes={{ root: styles.wrapper }}
      direction="column"
      wrap="nowrap"
      container
    >
      <Grid classes={{ root: styles.header }} container item>
        <Grid xs={3} item>
          Type
        </Grid>
        <Grid xs={4} item>
          Completed by
        </Grid>
        <Grid xs={3} item>
          Block reason
        </Grid>
        <Grid xs={2} item>
          Date
        </Grid>
      </Grid>
      <Grid ref={list} classes={{ root: styles.content }} item>
        {loading && !blocksHistory.length ? (
          <Box
            sx={{
              display: 'flex',
              height: 'calc(100vh - 280px)',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TableLoader />
          </Box>
        ) : !blocksHistory.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 280px)',
            }}
          >
            <NoResult text="There are no Blocked History" />
          </Box>
        ) : (
          <>
            {blocksHistory.map((item, index) => (
              <BlockedHistoryItem key={index} {...item} />
            ))}
            <InfiniteScroller
              containerRef={list}
              onLoadNext={nextPage?.current && handleLoadNext}
              loading={loading}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AccountInfoTab;
