import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PageContainer from 'shared/page-container';
import styled from '@mui/styles/styled';

export default function ChatMetricsLayout( { title, Filters, Charts } ) {
	return (
		<PageContainer>
			<Box sx={Sxs.BoxWrapper}>
				<Box sx={Sxs.FiltersContainer}>
					<Typography variant='bigBoldBlack' component='h2' sx={Sxs.Title}>
						{title}
					</Typography>
					<Filters />
				</Box>
				<Box sx={Sxs.ChartsContainer}>
					<Charts />
				</Box>
			</Box>
		</PageContainer>
	);
}

const Sxs = {
	BoxWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 2,
		height: 'calc(100vh - 102px)',
	},
	FiltersContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingRight: '1.5rem',
	},
	ChartsContainer: {
		display: 'flex',
		flex: 1,
	},
	Title: {
		color: 'customPalette.grey3',
		fontSize: 24,
		fontWeight: 'bold',
		marginY: 1.5,
		marginLeft: 2,
	},
};
