import { memo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UserLink from 'shared/user-link';

import { styled } from '@mui/material/styles';

import Avatar from 'shared/avatar';
import useUserProfileInfo from '../../hooks/useUserProfileInfo';

import { useAvatarPhoto } from 'hooks';
import { toTitleCase } from 'utils';
import { If } from 'shared/utilities';

function ChatMessageOwnerInfo(props) {
  const { owner_id, owner_info, isMessageMine, showOwnerInfo } = props;
  const { data } = useUserProfileInfo(owner_id);
  const [firstName, lastName] = owner_info.split(' ');
  const ownerNameTitle = toTitleCase(owner_info);
  const avatarMediaLocation = data?.photo;
  const { thumbnailSrc: avatarSrc } = useAvatarPhoto(avatarMediaLocation);

  return (
    <If condition={showOwnerInfo}>
      <InfoContainer isMine={isMessageMine}>
        <Avatar
          id={owner_id}
          lastName={lastName}
          firstName={firstName}
          position="chat"
          sxAvatar={Sxs.OwnerAvatar}
          inChatStyle={true}
          src={avatarSrc}
        />
        <UserLink name={ownerNameTitle} id={owner_id} sxLink={Sxs.UserLink} />
      </InfoContainer>
    </If>
  );
}

export default memo(ChatMessageOwnerInfo);

const InfoContainer = styled(Box)(({ theme, isMine }) => ({
  display: 'flex',
  flexDirection: !isMine ? 'row-reverse' : 'row',
  alignItems: 'center',
  margin: theme.spacing(0.5, 0),
}));
const Sxs = {
  OwnerAvatar: {
    width: 24,
    height: 24,
    fontSize: 12,
  },
  UserLink: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 8px',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',
    color: 'customPalette.grey1',
  },
};
