import React, { memo } from 'react';
import styles from './CreateBulletin.module.scss';

import Grid from '@mui/material/Grid';
import { SelectInput } from 'shared/inputs';
import Button from 'shared/button';
import RecipientsInput from './RecipientsInput';

import { useStepperContext } from 'context';

import { RECIPIENTS_TYPE } from '../constants';
import { useFormContext } from 'react-hook-form';

const PrevButton = memo(() => {
  const { onPrevStep } = useStepperContext();

  return (
    <Button
      className={styles.backButton}
      onClick={onPrevStep}
      display="secondary"
    >
      Back
    </Button>
  );
});

const NextButton = memo(() => {
  const { onNextStep } = useStepperContext();
  const { formState } = useFormContext();

  return (
    <Button
      onClick={onNextStep}
      display="primary"
      disabled={!!formState.errors.recipients}
    >
      Next
    </Button>
  );
});

const renderValue = (value) => RECIPIENTS_TYPE[value];

const RecipientsStep = () => {
  const { watch } = useFormContext();
  const recipientsType = watch('recipientsType');

  return (
    <div className={styles.formStep}>
      {recipientsType !== 'ALL' && (
        <>
          <h4 className={styles.recipients__header}>Choose recipients</h4>
          <p className={styles.recipients__tip}>
            You can choose a facility, housing unit, or up to 10 individual
            inmates
          </p>
        </>
      )}
      <SelectInput
        className={styles.recipients__type}
        name="recipientsType"
        label="Send to:"
        options={RECIPIENTS_TYPE}
        renderValue={renderValue}
      />
      <RecipientsInput name="recipients" />
      <Grid
        classes={{ root: styles.actions }}
        justifyContent="flex-end"
        wrap="nowrap"
        columnSpacing="16px"
        container
      >
        <PrevButton />
        <NextButton />
      </Grid>
    </div>
  );
};

export default RecipientsStep;
