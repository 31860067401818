import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphql } from 'context';
import { useAuthContext } from 'modules/auth/context';
import { InvitationFilterTypes } from 'kiwi-sdk/dist/types/analytics/invite';

import { QUERY_KEYS } from 'utils';

function useUserInvitations(args) {
  const { userId, status } = args;
  const api = useGraphql();
  const { agency } = useAuthContext();

  const enabledQuery = Boolean(agency && userId);

  const currentStatusFilter =
    status !== 0
      ? [
          {
            term: InvitationFilterTypes.STATUS,
            value: status,
          },
        ]
      : [
          {
            term: InvitationFilterTypes.STATUS,
            value: 1,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 2,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 3,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 4,
          },
          {
            term: InvitationFilterTypes.STATUS,
            value: 6,
          },
        ];

  function getUserInvitations({ pageParam = null }) {
    return api.controller.analytics.invitationInfoBatch({
      agencyId: agency?.id,
      limit: 100,
      senderId: userId,
      page: pageParam,
      filters: currentStatusFilter,
    });
  }

  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.USER_INVITATIONS_ANALYSIS, userId, status],
    queryFn: getUserInvitations,
    enabled: enabledQuery,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });
}

export default useUserInvitations;
