import DatePicker from './DatePicker';
import { useFormContext } from 'react-hook-form';
import styles from './DatePicker.module.scss';

const DateRangePicker = ({ startLabel, startName, endLabel, endName }) => {
    const { watch } = useFormContext();
    const maxStartDate = watch(endName);
    const minEndDate = watch(startName);

    return (
        <div className={styles.dateRangePicker}>
            <DatePicker 
                label={startLabel}
                name={startName}
                maxDate={maxStartDate ? maxStartDate : new Date()}
                dateInputFormat='MM/dd/yyyy'
                disableMask={false}
            />
            <DatePicker 
                label={endLabel}
                name={endName}
                minDate={minEndDate}
                maxDate={new Date()}
                dateInputFormat='MM/dd/yyyy'
                disableMask={false}
            />
        </div>
    );
};

export default DateRangePicker;