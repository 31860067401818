import { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Switch from '@mui/material/Switch';

import { useToggler } from 'hooks';

const ActionSwitch = ( {
												 label,
												 defaultCheckStatus,
												 mutation,
												 inputModal,
												 handleSubmit,
												 ModalComponent,
												 modalTitle,
												 IconButton,
												 disableAction,
											 } ) => {
	const [isCheck, setIsCheck] = useState(defaultCheckStatus);
	const [openModal, toggleOpenModal] = useToggler();

	const handleChange = () => {
		!isCheck && !inputModal && mutation();
		!isCheck && inputModal && toggleOpenModal(true);
		setIsCheck(!isCheck);
	};

	const onClickHandler = () => {
		inputModal && toggleOpenModal(true);
		inputModal || mutation();
	};

	return (
		<>
			<Grid container direction='row' justifyContent='space-between' alignItems='center' width={'100%'} height={36}>
				<Grid>
					<Typography variant='messageText' component='span'>
						{label}
					</Typography>
				</Grid>
				{IconButton ? (
					<IconButton disable={disableAction} handleClick={onClickHandler} />
				) : (
					<IOSSwitch
						checked={isCheck}
						onChange={handleChange}
						disabled={isCheck || disableAction}
						inputProps={{ 'aria-label': 'controlled' }}
						size='small'
					/>
				)}
			</Grid>
			{
				inputModal ? (
					<ModalComponent
						open={openModal}
						onClose={toggleOpenModal}
						title={modalTitle}
						handleSubmit={handleSubmit}
					/>
				) : null
			}
		</>
	);
};

export default ActionSwitch;

const IOSSwitch = styled(( props ) => (
	<Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(( { theme } ) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

