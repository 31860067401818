import EmptyResult from './EmptyResult';
import Suspense from 'shared/suspense-list';
import ProfileCard from 'modules/users-chats/user-details/profile-tab/ProfileCard';
import CardContentLayout from 'modules/chats-metrics/charts/chart-card/CardContentLayout';
import KiwiPieChart from 'shared/pie-chart';

import {
  useFlaggedMessagesAnalytics,
  useUserProfileContext,
} from 'modules/users-chats/hooks';
import { ChartLoader } from 'shared/loaders';
import { PieChartLegend } from 'shared/chart-legend';
import { FilterTypes } from 'kiwi-sdk/dist/types/analytics/message';
import { ViolationType } from 'kiwi-sdk/dist/types/analytics/messageFilters';
import { COLORS } from './constants';

export default function FlaggedCharts() {
  const {
    userId,
    dateRangeFilter,
    setSelectedOption,
    toggleFlaggedMessagesModal,
    setSelectedAnalyticModalConfig,
  } = useUserProfileContext();
  const { data: queryData = [], isLoading } = useFlaggedMessagesAnalytics(
    userId,
    dateRangeFilter
  );
  const countTotal = queryData.reduce(
    (accumulator, currentObject) => accumulator + currentObject.count,
    0
  );
  const onlyViolationsData = queryData.filter(
    ({ violationType }) => violationType.toLowerCase() !== 'no violation'
  );
  const onlyViolationsDataCount = onlyViolationsData.reduce(
    (accumulator, currentObject) => accumulator + currentObject.count,
    0
  );

  const optionsFormatData = ({ violationType, count }) => ({
    option: violationType,
    count: count,
  });

  const selectViolationFromLegend = (violationType) => {
    setSelectedOption([
      {
        term: FilterTypes.VIOLATION,
        value: ViolationType[violationType.toUpperCase()],
      },
      { term: FilterTypes.USER_ID, value: userId },
    ]);
    setSelectedAnalyticModalConfig({
      modalName: 'Moderation Activity',
      showDateFilters: false,
      showChatPanel: true,
      listRenderItem: 'message',
      initialOptionsData: onlyViolationsData.map(optionsFormatData),
      typesData: ViolationType,
      search: true,
      filters: true,
    });
    toggleFlaggedMessagesModal();
  };

  return (
    <ProfileCard
      cardTitle="Flagged Messages"
      sxCard={{ flex: 1, margin: '10px 15px 20px 24px' }}
    >
      <Suspense
        loading={isLoading}
        total={onlyViolationsDataCount}
        fallback={<ChartLoader />}
        noResultComponent={<EmptyResult />}
      >
        <CardContentLayout
          chartType="pie"
          LegendComponent={() => (
            <PieChartLegend
              legendData={onlyViolationsData}
              nameKey="violationType"
              valueKey="count"
              colorPalette={COLORS}
              clickable={true}
              onClickItem={selectViolationFromLegend}
            />
          )}
          ChartComponent={() => (
            <KiwiPieChart
              chartData={onlyViolationsData}
              legendData={onlyViolationsData}
              chartDataKey="count"
              chartNameKey="violationType"
              innerText="Total"
              innerValue={onlyViolationsDataCount}
              colorPalette={COLORS}
            />
          )}
        />
      </Suspense>
    </ProfileCard>
  );
}
