import { Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'shared/button';
import styles from './UploadFile.module.scss';
import FormLoader from 'shared/form-loader';
import { PdfIcon } from 'assets/icons';
import { useStepperContext } from 'context';
import RecipientsDisplay from 'shared/recipient-accordion';


const PrevButton = ({ loading }) => {
    const { onPrevStep } = useStepperContext();
  
    return (
      <Button
        className={styles.backButton}
        onClick={onPrevStep}
        display="secondary"
        disabled={loading}
      >
        Back
      </Button>
    );
  };

const getStyleForRecipentsDiv = (recipients) => {
  if(recipients.length > 1) {
    return styles.review__reviewDiv__recipientAccordianDiv;
  }
  return styles.review__reviewDiv;
};


function truncateFileName(fullFileName, maxFileNameLength) {
    if (fullFileName) {
      return fullFileName.length > maxFileNameLength
        ? `${fullFileName.substr(0, maxFileNameLength - 1)}...`
        : fullFileName;
    }
    return '';
}


const FileReviewBody = ({ file, recipients, title }) => {
  return (
    <div className={styles.review__body}>
          <div className={getStyleForRecipentsDiv(recipients)}>
            <span className={styles.review__reviewDiv__sectionText}>Recipients:</span>
            <RecipientsDisplay recipients={recipients} />
          </div>
            
          <div className={styles.review__reviewDiv}>
            <span className={styles.review__reviewDiv__sectionText}>Display Name:</span>
            <h3 className={styles.review__title}>{truncateFileName(title, 24)}</h3>  
          </div>
  
          <div className={styles.review__reviewDiv}>
            <span className={styles.review__reviewDiv__sectionText}>Uploaded File:</span>
            <div className={styles.uploadedFile}>
                <PdfIcon />
                <p className={styles.uploadedFile__info}>
                <span>{truncateFileName(file.name.split('.')[0], 12)}</span>
                <span className={styles.uploadedFile__info__ext}>
                    {`.${file.name.split('.')[1]}`}
                </span>
                </p>
            </div>
          </div>
      </div>
  );
};


const FileReviewStep = ({ loading }) => {
    const { getValues } = useFormContext(); 
    const values = getValues();
    const { title, file, recipients } = values;

    
    return (
        <div className={styles.review}>
          <div className={styles.uploadModalDivider__reviewStep}></div>
          <FileReviewBody 
            file={file} 
            recipients={recipients} 
            title={title}
          />
            <Grid
              classes={{ root: styles.actions }}
              direction="row-reverse"
              justifyContent="space-between"
              wrap="nowrap"
              container
            >
              <Grid
                justifyContent="space-between"
                wrap="nowrap"
                columnSpacing="16px"
                container
              >
                
                { loading ? <FormLoader loading={loading} text='Uploading File...' /> :
                  <>
                    <PrevButton loading={loading} />
                    <Button display="primary" type="submit" disabled={loading}>
                      Publish
                    </Button>
                  </>
                } 
              </Grid>
            </Grid>
        </div>
      );
};

export default FileReviewStep;












