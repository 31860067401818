import Box from '@mui/material/Box';

import useUserProfileContext from 'modules/users-chats/hooks/useUserProfileContext';
import PrintRequestsList from './print-request-list';
import ErrorBoundary from 'shared/error-boundary';
import Suspense from 'shared/suspense-list';

import { flattenPages } from 'utils';
import { useInmatePrintRequests } from 'modules/users-chats/hooks';
import { TableLoader } from 'shared/loaders';

const PrintRequestsTab = () => {
	const { userId } = useUserProfileContext();
	const { data = [], isLoading, fetchNextPage } = useInmatePrintRequests(userId);
	const { sxBoxWrapper } = usePrintRequestsTabStyles();
	const allRequests = flattenPages(data);

	return (
		<ErrorBoundary level='section' textVariant='medium' imageSize='medium'>
			<Box sx={sxBoxWrapper}>
				<Suspense
					total={allRequests?.length || 0}
					loading={isLoading}
					fallback={<TableLoader />}
					textNoResults='There are no print requests'>
					<PrintRequestsList
						requests={allRequests}
						loadNextPage={fetchNextPage}
						loading={isLoading}
					/>
				</Suspense>
			</Box>
		</ErrorBoundary>
	);
};

export default PrintRequestsTab;

function usePrintRequestsTabStyles() {
	return {
		sxBoxWrapper: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 'calc(100vh - 170px)',
		},
	};
}

//TODO: adding suspense list to the print requests tab